import React from 'react'
import {Tab,Tabs,TabList,TabPanel} from 'react-tabs'
import 'react-tabs/style/react-tabs.css'
import './custom.css'
export default function CustomTabBar(props) {

  return (
    <Tabs
    className={"pb-3"}
    selectedIndex={props.selectedIndex}
    onSelect={(index)=>{
        props.onSelect(index);
    }}
    >
    <TabList style={props.tabBarstyle}>
        {
            props.rowheading.map((elem,index)=>{
                return <Tab key={index} style={elem.style}>{elem.title}</Tab>;
            })
        }
    </TabList>
    {
        props.panelData.map((elem,index)=>{
            return (
                <TabPanel key={index} >
                    {
                        elem.component
                    }
                </TabPanel>
            )
        })
    }
    </Tabs>
  )
}
