import React, { useEffect, useMemo, useState } from "react";
import CustomPopup from "../../../../commonComponent/CustomPopup";
import { useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import Loader from "../../../../commonComponent/Loader";
import "./Home.module.css";
import axios from "axios";
import { Error_msg } from "../../../../messageFile/ErrorMessages";
import { styled } from "@mui/material/styles";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import FolderIcon from "@mui/icons-material/Folder";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Area,
} from "recharts";
import CustomLineVolume from "../../../../component/Charts/CustomLineVolume";
import { CustomAlchemy } from "./AlchemyConnenction";
import { trace } from "firebase/performance";
import { sendPerfomance } from "../../../..";
import { useNavigate } from "react-router-dom";
import { REACT_APP_FETCH_PARTICULAR_COLLECTION_BASEURL } from "../../../../config/API/Api";

export default function Home() {
  const [showpopup, setshowpopup] = useState(false);
  const mode = useSelector((state) => state.themeType.themeType);
  const token = localStorage.getItem("accessToken");
  const [refresh, setrefresh] = useState(false);
  const [address, setaddress] = useState("");
  const [addresslist, setaddressList] = useState([]);
  const [isloading, setisloading] = useState(false);
  const [dense, setDense] = useState(false);
  const [secondary, setSecondary] = useState(false);
  const [nftList,setnftList]=useState([]);
  const [selectedAddress,setselectedAddress]=useState(localStorage.getItem('userAddress'));
console.log('i ehllo buddy log')

  // address related popup states
  const [showModal, setshowModal] = useState(false);

  const [userId, setuserId] = useState(
localStorage.getItem("userAddress")
  );

  const navigate=useNavigate();

  //commonfunction
  const togglePopup = () => {
    setshowpopup(!showpopup);
  };

  const isValidEthereumAddress = (address) => {
    // Remove the '0x' prefix if it exists
    const cleanedAddress = address.toLowerCase().replace(/^0x/, "");

    // Check if the address matches the expected pattern
    const addressRegex = /^[0-9a-f]{40}$/;
    return addressRegex.test(cleanedAddress);
  };
  const Demo = styled("div")(({ theme }) => ({
    backgroundColor: theme.palette.background.paper,
  }));

  // addNFTAddressREquestedApi
  const addNFTAddressREquestedApi = async (userId, customAddress) => {
    let data = false;
    // const url = "http://localhost:8080/AddNftWallet";
    const url=`${REACT_APP_FETCH_PARTICULAR_COLLECTION_BASEURL}/AddNftWallet`
    const body = { userId, customAddress };
    const t=trace(sendPerfomance,'AddNFTWalletAddressInDBUrl');
    t.start();
    // const body=JSON.stringify({userId:userId,Address:customAddress});
    try {
      await axios
        .post(url, body,{headers:{
          Authorization:token
        }})
        .then((res) => {
          if (res.status === 200) {
            console.log(res);
            data = true;
          } else {
            data = false;
          }
        })
        .catch((err) => {
          toast.error(err.message);
        });
    } catch (error) {
      toast.error("Internal Server Error~");
    }
    t.stop();
    return data;
  };
  // nftaddresslistrequestapi

const nftAddressListRequestedApi = async () => {
  console.log(userId,'this is userid');
  const url= `${REACT_APP_FETCH_PARTICULAR_COLLECTION_BASEURL}/NftAddressList`
  const t=trace(sendPerfomance,'GettingNFTWalletAddressListFromDBUrl');
    t.start();
    try {
      await axios
        .get(url,{headers:{
          Authorization:token
        }})
        .then((res) => {
          if (res.status === 200) {
            setaddressList(res.data ? res.data.customAddress : []);
            setisloading(false);
          }
        })
        .catch((err) => {
          console.log(err);
          setisloading(false);
          // toast.error(err.response.status + " " + err.response.statusText);
        });
    } catch (error) {
      setisloading(false);
      toast.error(Error_msg.internal_server_error);
    }
    t.stop();
};

useEffect(()=>{
 const address= localStorage.getItem('userAddress') 
 if(address===undefined||address===null || address===""){
  navigate('/profile/dashboard')
 }
},[])

useMemo(() => {
    setisloading(true);
    nftAddressListRequestedApi();
}, [refresh]);


useMemo(async()=>{
setisloading(true)
await CustomAlchemy.nft.getNftsForOwner(selectedAddress,{
 pageKey:""
}).then(res=>{
  setisloading(false);
  setshowModal(false);
  setnftList([res.totalCount,res.ownedNfts]);
}).catch((err)=>{
  console.log(err);
  setshowModal(false);
  setisloading(false);
})
  },[selectedAddress])
// All click function here

console.log(selectedAddress,'this is user id')
  const submitAddress = async () => {
    if (isValidEthereumAddress(address) && isValidEthereumAddress(userId)) {
      const fetchedData = await addNFTAddressREquestedApi(userId, address);
      console.log('yh sab fixed hua tha kya',fetchedData,'this si fetchdata');
      console.log('userId', userId)
      console.log('address', address)
      if (fetchedData) {
        toast.success("Address added successfully");
        setrefresh(!refresh);
        setshowpopup(false);
        setaddress("");
      }
    } else {
      toast.warning(`invalid Credentials`);
      setaddress("");
    }
  };

  const handleOpenNft=()=>{
  navigate(`${selectedAddress}/NFTs`);
  }

  //table activity
  const collection = (row) => {
    return (
      <div
        className="pointer"
        onClick={() => {
          navigate("/profile/dashboard/get_nft_details", {
            address: row.address,
          });
        }}
      >
        <p className="mb-0">{row.address}</p>
      </div>
    );
  };

  const columns = [
    {
      name: <h6 className="font-weight-bold">NFT Address</h6>,
      selector: (row, index) => collection(row),
      sortable: true,
      ignoreRowClick: true,
    },
  ];

  const TablePopup = () => {
    const toggle = () => {
      setshowModal(!showModal);
    };

  const handleSwitchAddress=(val)=>{
    setshowpopup(false)

    setselectedAddress(val.address);
  }
    return (
      <CustomPopup
        size="lg"
        showpopup={showModal}
        center={true}
        onclick={toggle}
        classBody="p-0"
        body={
          <div
            className={` ${
              mode === "lightMode" ? "bg-light text-dark" : "bg-dark text-white"
            }`}
          >
            <div className=" py-2 d-flex justify-content-between flex-wrap px-2">
              <h3>Watchlist</h3>
              <button
                className="btn btn-primary"
                onClick={() => {
                  setshowpopup(!showpopup);
                }}
              >
                Add wallet
              </button>
            </div>
            <div>
              <Demo
                className={`${
                  mode === "lightMode"
                    ? "bg-light text-dark"
                    : "bg-dark text-white"
                }`}
              >
                <List dense={dense}>
                  {addresslist.map((elem, index) => {
                    return (
                      <ListItem
                        key={index}
                        onClick={()=>{handleSwitchAddress(elem)}}
                        className="pointer"
                        secondaryAction={
                          <IconButton edge="end" aria-label="delete">
                            <DeleteIcon />
                          </IconButton>
                        }
                      >
                        <ListItemAvatar>
                          <Avatar>
                            <FolderIcon />
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                          primary={elem.address}
                          secondary={secondary ? "Secondary text" : null}
                        />
                      </ListItem>
                    );
                  })}
                </List>
              </Demo>
            </div>
            <div className="d-flex justify-content-between flex-wrap px-2">
              <h3>Recently Viewed</h3>
            </div>
          </div>
        }
        footerBody={<p className="btn-link classFooter"> Clear All</p>}
        classFooter="justify-content-start"
      />
    );
  };

  const Firstrow = () => {
    const obj = (obj) => {
      return (
        <div className="mx-1 p-2">
          <small>{obj.title}</small>
          <h1>{obj.value}</h1>
          <p className="text-danger">{obj.description}</p>
        </div>
      );
    };
    return (
      <div
        className={`row rounded my-2 ${
          mode === "lightMode" ? "bg-light" : "bg-dark"
        }`}
      >
        <div
          className={`col-md-3 col-sm-10 ${
            mode === "darkMode" && "bg-dark"
          } border-right border-white`}
        >
          {obj({
            title: "Net Worth",
            value: "$257.29",
            description: "-2.12% ($5.56)",
          })}
        </div>
        <div className="col-md-9 col-sm-10 d-flex flex-wrap">
          {obj({
            title: "Tokens worth",
            value: "$69.29",
            description: "-2.12% ($+8.56)",
          })}
          {obj({
            title: "NFTs worth",
            value: "$187.82",
            description: "-2.12% ($+8.102)",
          })}
        </div>
      </div>
    );
  };

  const secondRow = () => {
    const data = [
      {
        name: "Page A",
        uv: 4000,
        pv: 2400,
        amt: 2400,
      },
      {
        name: "Page B",
        uv: 3000,
        pv: 1398,
        amt: 2210,
      },
      {
        name: "Page C",
        uv: 2000,
        pv: 9800,
        amt: 2290,
      },
      {
        name: "Page D",
        uv: 2780,
        pv: 3908,
        amt: 2000,
      },
      {
        name: "Page E",
        uv: 1890,
        pv: 4800,
        amt: 2181,
      },
      {
        name: "Page F",
        uv: 2390,
        pv: 3800,
        amt: 2500,
      },
      {
        name: "Page G",
        uv: 3490,
        pv: 4300,
        amt: 2100,
      },
    ];
    return (
      <div className="row">
        <div className={`col-md-8 col-sm-10 `}>
          <div className="row">
            <div
              className={` my-2 col-12 ${
                mode === "lightMode"
                  ? "bg-light text-dark"
                  : "bg-dark text-white"
              }`}
            >
              <CustomLineVolume
                header="Tokens Worth"
                box={[]}
                filters={[
                  {
                    label: "24H",
                    onclick: (val) => {
                      console.log(val, "this is val");
                    },
                  },
                  {
                    label: "7d",
                    onclick: (val) => {
                      console.log(val, "this is val");
                    },
                  },
                  {
                    label: "30d",
                    onclick: (val) => {
                      console.log(val, "this is val");
                    },
                  },
                  {
                    label: "6m",
                    onclick: (val) => {
                      console.log(val, "this is val");
                    },
                  },
                ]}
                data={data}
                xDatakey="name"
              >
                <Area
                  type="monotone"
                  dataKey="uv"
                  stroke="#8884d8"
                  fill="green"
                />
              </CustomLineVolume>
            </div>
            <div
              className={`col-12 p-3 ${
                mode === "lightMode"
                  ? "bg-light text-dark"
                  : "bg-dark text-white"
              }`}
            >
              <div className="d-flex">
                <div
                  className={` m-1 rounded p-2 w-50 ${
                    mode === "lightMode"
                      ? "bg-;light text-dark"
                      : "bg-black text-white"
                  }`}
                >
                  <small className="text-secondary">Dominant Token</small>
                  <div className="d-flex justify-content-between px-2">
                    <p>eth</p>
                    <p>$69.06 (100%)</p>
                  </div>
                </div>
                <div
                  className={` m-1 p-2 w-50 rounded ${
                    mode === "lightMode"
                      ? "bg-;light text-dark"
                      : "bg-black text-white"
                  }`}
                >
                  <small className="text-secondary">Token</small>
                  <p>2</p>
                </div>
              </div>
              <div className="my-2 d-flex justify-content-between">
                <span>
                  <h3>ETHEREUM</h3>
                  <p className="text-secondary">0.0369 ETH • $1,867.26</p>
                </span>
                <span>
                  <h3>$69.06</h3>
                  <p className="text-danger">-0.52% ($0.364)</p>
                </span>
              </div>

              <button className="btn btn-block btn-primary">
                {" "}
                Open tokens
              </button>
            </div>
          </div>
        </div>
        <div className="col-md-4 col-sm-10 ">
          <div
            className={`p-2 my-2 ${
              mode === "lightMode" ? "bg-light text-dark" : "bg-dark text-white"
            }`}
          >
            <h2>NFTs</h2>
            <div className="d-flex my-2">
              <span
                className={` w-100 m-1 p-2 rounded ${
                  mode === "darkMode"
                    ? "bg-black text-white"
                    : "bg-light text-dark"
                }`}
              >
                <small className="text-secondary">Collection</small>
                <h3>3</h3>
              </span>
              <span
                className={` w-100 m-1 p-2 rounded ${
                  mode === "darkMode"
                    ? "bg-black text-white"
                    : "bg-light text-dark"
                }`}
              >
                <small className="text-secondary">NFTs</small>
                <h3>{nftList.length>0?nftList[0]:"0"}</h3>
              </span>
            </div>
            <button className="btn btn-block btn-primary" onClick={handleOpenNft}>Open NFTs</button>


          </div>
        </div>
      </div>
    );
  };
  return (
    <div
      className={`w-100  overflow-auto overflow ${
        mode === "darkMode" ? "bg-black text-white" : "bg-light text-dark"
      }`}
      style={{ maxHeight: "100vh" }}
    >
      {TablePopup()}
      <CustomPopup
        size="lg"
        showpopup={showpopup}
        center={true}
        onclick={togglePopup}
        popHeader={
          <h3>
            <span className="text-secondary">Wallets </span>
            Add Wallet
          </h3>
        }
        body={
          <div className="">
            <div className="row my-2">
              <div className="col mx-1">
                <button className="btn btn-block btn-primary"> Ehtereum</button>
              </div>
              <div className="col">
                <button className="btn btn-block btn-primary ">
                  {" "}
                  BNB chain
                </button>
              </div>
              <div className="col">
                <button className="btn btn-block btn-primary"> Polygon</button>
              </div>
            </div>
            <div className="form-group my-2">
              <label htmlFor="address">Address:</label>
              <input
                type="text"
                value={address}
                onChange={(e) => {
                  setaddress(e.target.value);
                }}
                className="form-control "
                id="email"
                placeholder="Paste wallet address or ENS "
              />
            </div>
            <button
              className="btn btn-primary btn-block text-center d-flex justify-content-center"
              onClick={submitAddress}
            >
              Add address
            </button>

            <div className="row my-2">
              <div className="col">
                <button className="btn btn-block btn-primary"> Metamask</button>
              </div>
              <div className="col">
                <button className="btn btn-block btn-primary ">
                  {" "}
                  Wallet Connnt{" "}
                </button>
              </div>
              <div className="col">
                <button className="btn btn-block btn-primary">
                  {" "}
                  Coinbase Wallet
                </button>
              </div>
            </div>
          </div>
        }
        classBody={
          mode === "lightMode" ? "bg-light text-dark" : "bg-dark text-white"
        }
      />

      <ToastContainer
        position="top-center"
        autoClose={1500}
        closeOnClick
        theme={mode === "lightMode" ? "light" : "dark"}
      />
      {isloading ? (
        <div className="text-center">
          <Loader />
          <h1>Please Wait</h1>
        </div>
      ) : (
        <div className="container mt-2">
          <div className="d-flex">
            <button
              className="btn btn-primary"
              onClick={() => {
                setshowModal(!showModal);
                console.log(showModal);
              }}
            >
              address
            </button>
            <ul className="nav justify-content-end ml-auto">
              <li className="nav-item">
                <a className="nav-link active" href="#">
                  Ehtereum
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#">
                  BNB Chain
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#">
                  Polygon
                </a>
              </li>
            </ul>
          </div>

          {Firstrow()}
          {secondRow()}
     
        </div>
      )}
    </div>
  );
}
