import React, { memo, useEffect, useMemo, useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import "react-accessible-accordion/dist/fancy-example.css";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { FaChevronDown } from "react-icons/fa";
import Loader from "../../commonComponent/Loader";
import './FilterAccordian.css';

function FilterAccordian(props) {
  const mode=useSelector(state=>state.themeType.themeType);
  const {  handleChangeselectedFilter, updateFilterProperties ,clearAssets,handleLikeFilter,handledisLikeFilter,initialpropertiesFilterData,handleSelectedFilter,selectedFilter,likeFilterValue,handlerefreshFilter,dislikeFilterValue,priceFilterValue,handlePriceFilterValue} =
    props;
  const [ispropertiesLoading,setispropertiesLoading]=useState(false);
  const [propertiesFilterData, setpropertiesFilterData] = useState(initialpropertiesFilterData);

  useEffect(()=>{
    setpropertiesFilterData(initialpropertiesFilterData);
  },[initialpropertiesFilterData])

  useMemo(() => {
    handleChangeselectedFilter(selectedFilter);
  }, [selectedFilter]);
  const priceFilter = () => {
    return (
      <div>
        <div className="d-flex align-items-center">
          <input
            type="number"
            value={priceFilterValue?.min}
            onChange={(e) => {handlePriceFilterValue({...priceFilterValue,min:e.target.value})}}
            className="form-control w-100 priceFilterInput"
          />
          <p className="mx-2 mb-0">to</p>
          <input
            type="number"
            value={priceFilterValue?.max}
            onChange={(e) => {handlePriceFilterValue({...priceFilterValue,max:e.target.value})}}
            className="form-control w-100 priceFilterInput"
          />
        </div>
        <div className="d-flex justify-content-around align-items-center mt-4">
          <button className={`priceFilterbtn btn px-4 ${priceFilterValue?.min===""&& priceFilterValue?.max==="" && "bg-secondary text-white"}`} onClick={()=>{
            handlePriceFilterValue({min:'',max:''});
            handlerefreshFilter();
            }} disabled={priceFilterValue?.min===''&&priceFilterValue?.max===''}>Clear</button>
          <button className="priceFilterbtn btn px-4 bg-dark text-white" onClick={()=>{
           if(priceFilterValue?.min===''){
            toast.warn('Please enter min value');
            return 
          }
          if(priceFilterValue?.max===''){
            toast.warn('Please enter Max Value');
            return 
          }
            handlerefreshFilter()}}>apply</button>
        </div>
      </div>
    );
  };
  const LikeFilter=() => {
    return (
      <div>
        <div className="d-flex align-items-center">
          <input
            type="number"
            value={likeFilterValue?.min}
            onChange={(e) => {handleLikeFilter({...likeFilterValue,min:e.target.value})}}
            className="form-control w-100 priceFilterInput"
          />
          <p className="mx-2 mb-0">to</p>
          <input
            type="number"
            value={likeFilterValue?.max}
            onChange={(e) => {handleLikeFilter({...likeFilterValue,max:e.target.value})}}
            className="form-control w-100 priceFilterInput"
          />
        </div>
        <div className="d-flex justify-content-around align-items-center mt-4">
          <button className={`priceFilterbtn btn px-4`} onClick={()=>{
            handleLikeFilter({min:'',max:''});
            handlerefreshFilter();
            }} disabled={likeFilterValue?.min===''&&likeFilterValue?.max===''}>Clear</button>
          <button className="priceFilterbtn btn px-4 bg-dark text-white" onClick={()=>{
            if(likeFilterValue?.min===''){
              toast.warn('Please enter min value');
              return 
            }
            if(likeFilterValue?.max===''){
              toast.warn('Please enter Max Value');
              return 
            }
            handlerefreshFilter();
            }}>apply</button>
        </div>
      </div>
    );
  }
  const DislikeFilter=() => {
    return (
      <div>
        <div className="d-flex align-items-center">
          <input
            type="number"
            value={dislikeFilterValue?.min}
            onChange={(e) => {handledisLikeFilter({...dislikeFilterValue,min:e.target.value})}}
            className="form-control w-100 priceFilterInput"
          />
          <p className="mx-2 mb-0">to</p>
          <input
            type="number"
            value={dislikeFilterValue?.max}
            onChange={(e) => {handledisLikeFilter({...dislikeFilterValue,max:e.target.value})}}
            className="form-control w-100 priceFilterInput"
          />
        </div>
        <div className="d-flex justify-content-around align-items-center mt-4">
          <button className={`priceFilterbtn btn px-4`} onClick={()=>{
            handledisLikeFilter({min:'',max:''});
            handlerefreshFilter()
            }} disabled={dislikeFilterValue?.min===''&&dislikeFilterValue?.max===''}>Clear</button>
          <button className="priceFilterbtn btn px-4 bg-dark text-white" onClick={()=>{
            if(dislikeFilterValue?.min===''){
              toast.warn('Please enter min value');
              return 
            }
            if(dislikeFilterValue?.max===''){
              toast.warn('Please enter Max Value');
              return 
            }
            handlerefreshFilter();
            }}>apply</button>
        </div>
      </div>
    );
  }
  const handlefilterCheck=(elem,item,e)=>{
    if(e.target.checked){
      const existfilter=selectedFilter.find((obj)=>obj.key===elem.traitType);
      if(existfilter===undefined){
        handleSelectedFilter([...selectedFilter,{key:elem.traitType,values:[item.key]}])
      }else{
        console.log('ab isme aaya hoga pakka',existfilter);
        const finaldata=   selectedFilter?.map((object)=>{
          if(object.key===elem.traitType){
           object.values.push(item.key);
          }
          return object
        })
        handleSelectedFilter(finaldata);
      }
      console.log(existfilter,'this is existfilter',selectedFilter)
    }else{
      const finaldata=  selectedFilter?.map((object)=>{
        if(object.key===elem.traitType){
          object.values=object.values.filter((i)=>(i!==item.key))
        }
        return object;
      })
      handleSelectedFilter(finaldata);
    }
    const finaldata=propertiesFilterData?.map((obj)=>{
      if(obj.traitType===elem.traitType){
          obj.trait_values?.map((object)=>{
            if(object.key===item.key){
            object.isChecked=!object.isChecked
            }
          })
      }
      return obj;
    })
    updateFilterProperties(finaldata);
  }
  return (
    <div  >
      <Accordion   style={{padding:"12px 0px",position:"static"}} className={`  ${mode==="lightMode"?"bg-white text-dark":"bg-dark text-white   "}`}>
        <AccordionSummary
          expandIcon={<FaChevronDown
            size={8}
            color={mode === "lightMode" ? "#929292" : "white"}
          />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={`font-16 font-weight-bold inherit ${mode==="lightMode"?"text-dark":" text-white"}`}>Likes</Typography>
        </AccordionSummary>
        <AccordionDetails >
          <Typography>{LikeFilter()}</Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion   style={{padding:"12px 0px",position:"static"}} className={`  ${mode==="lightMode"?"bg-white text-dark":"bg-dark text-white   "}`}>
        <AccordionSummary
          expandIcon={<FaChevronDown
            size={8}
            color={mode === "lightMode" ? "#929292" : "white"}
          />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={`font-16 font-weight-bold inherit ${mode==="lightMode"?"text-dark":" text-white"}`}>Dislikes</Typography>
        </AccordionSummary>
        <AccordionDetails >
          <Typography>{DislikeFilter()}</Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion   style={{padding:"12px 0px"}} className={`  ${mode==="lightMode"?"bg-white text-dark":"bg-dark text-white "}`}>
        <AccordionSummary
          expandIcon={  
          <FaChevronDown
            size={8}
            color={mode === "lightMode" ? "#929292" : "white"}
          />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className="font-16 font-weight-bold inherit">Price</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography >
            {priceFilter()}
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion style={{padding:"12px 0px"}} className={` ${mode==="lightMode"?"bg-white text-dark":"bg-dark text-white"}`}>
        <AccordionSummary
          expandIcon={<FaChevronDown
            size={8}
            color={mode === "lightMode" ? "#929292" : "white"}
          />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography className="font-16 font-weight-bold inherit">Property</Typography>
        </AccordionSummary>
        <AccordionDetails className="p-0">
          <Typography className="p-0">
          {
          ispropertiesLoading?
          <div className="d-flex justify-content-center">
            <Loader  size={50}/>
          </div>:
          propertiesFilterData?.map((elem, index) => (
            <Accordion key={index} className={`${mode==="lightMode"?"bg-white text-dark":'bg-black text-white border-bottom border-right border-white'}`}>
              <AccordionSummary
                expandIcon={
                  <FaChevronDown
                    size={8}
                    color={mode === "lightMode" ? "#929292" : "white"}
                  />
                }
                aria-controls="panel3-content"
                id="panel3-header"
                className="font-15 lh-20 fontweight-500 inherit"
              >
                {elem?.traitType}
              </AccordionSummary>
              <AccordionDetails>
                {
                  elem?.trait_values?.map((item,i)=>(
                    <div key={i} className="my-2 px-2 d-flex justify-content-between my-2">
                      <span >{item?.key}</span>
                      <span className="font-16">
                        {item?.value}
                        <input className="form-input ml-2 pointer " type="checkbox" style={{position:'relative',top:"2px",backgroundColor:"initial"}} checked={item.isChecked} onChange={(e)=>{
                          clearAssets();
                          handlefilterCheck(elem,item,e)
                          }}/>
                      </span>
  
                    </div>
                  ))
                }
              </AccordionDetails>
            </Accordion>
          ))
        }
          </Typography>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}

export default memo(FilterAccordian);
