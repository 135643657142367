import React, { useEffect, useState } from "react";
import { CiSearch } from "react-icons/ci";
import Styles from "./Announcement.module.css";
import RenderList from "./RenderList";
import { useSelector } from "react-redux";
import {Link,useParams} from 'react-router-dom'
const AnnouncementScreen = () => {
  const [windowWidth, setwindowWidth] = useState(window.innerWidth);
  const mode = useSelector((state) => state.themeType.themeType);
  const route=useParams();

  const  leftList=[
    {id:1,label:'Latest announcements'},
    {id:2,label:'Latest events'},
    // {id:3,label:'Deposit/withdrawl suspension/resumption'},
    {id:4,label:'Spot and margin trading'},
    {id:5,label:'Derivatives'},
    {id:6,label:'OKTC'},
    {id:7,label:'Fiat gateway'},
    {id:8,label:'JRN broker'}, 
    {id:9,label:'JRN Pool announcements'}, 
    {id:10,label:'New Token'}, 
    {id:11,label:'Introduction to digital assets'}, 
    {id:12,label:'OKB burn'}, 
    {id:13,label:'API'}, 
    {id:14,label:'Others'}
  ]

  const rightList=[
    {id:1,title:"JRN TR Sui Learn & Earn Event Rules",desc:'Published on Jun 4, 2024 | Updated on Jun 11, 2024'},
    {id:2,title:"JRN TR Sui Learn & Earn Event Rules",desc:'Published on Jun 2, 2024 | Updated on Jun 11, 2024'},
    {id:3,title:"JRN TR Sui Learn & Earn Event Rules",desc:'Published on Jun 10, 2024 | Updated on Jun 15, 2024'},
    {id:4,title:"JRN TR Sui Learn & Earn Event Rules",desc:'Published on Jun 14, 2024 | Updated on Jun 15, 2024'},
    {id:5,title:"JRN TR Sui Learn & Earn Event Rules",desc:'Published on Jun 16, 2024 | Updated on Jun 18, 2024'},
    {id:6,title:"JRN TR Sui Learn & Earn Event Rules",desc:'Published on Jun 17, 2024 | Updated on Jun 19, 2024'},
    {id:7,title:"JRN TR Sui Learn & Earn Event Rules",desc:'Published on Jun 20, 2024 | Updated on Jun 22, 2024'},
    {id:8,title:"JRN TR Sui Learn & Earn Event Rules",desc:'Published on Jun 22, 2024 | Updated on Jun 25, 2024'},
    {id:9,title:"JRN TR Sui Learn & Earn Event Rules",desc:'Published on Jun 25, 2024 | Updated on Jun 26, 2024'},
    // {id:10,title:"JRN TR Sui Learn & Earn Event Rules",desc:'Published on Jun 27, 2024 | Updated on Jun 28, 2024'},
    // {id:11,title:"JRN TR Sui Learn & Earn Event Rules",desc:'Published on Jun 29, 2024 | Updated on Jun 30, 2024'},
    // {id:12,title:"JRN TR Sui Learn & Earn Event Rules",desc:'Published on Jun 30, 2024 | Updated on July 1, 2024'},
    // {id:13,title:"JRN TR Sui Learn & Earn Event Rules",desc:'Published on July 3, 2024 | Updated on July 11, 2024'},
    // {id:14,title:"JRN TR Sui Learn & Earn Event Rules",desc:'Published on July 4, 2024 | Updated on July 11, 2024'}
  ]
  const renderFirstSection = () => {
    return (
      <section
        className="pt-3"
      >
        <div className="row">
          <div className="col-12 col-xl-9">
            <nav aria-label="breadcrumb">
              <ol className={`breadcrumb  pl-0 ${mode==='lightMode'?"bg-white text-black":"bg-black text-white"}`}>
                <li className="breadcrumb-item" style={{color:'#909090'}}>Support center</li>
                <li className={` breadcrumb-item active`} aria-current="page">
                  {route.category===undefined?"Annoucements":<Link to="/announcements" style={{color:'#909090'}}>Announcements</Link>}
                </li>
              { route.category!==undefined&& <li className={` breadcrumb-item ${mode==='lightMode'?"text-black":'text-white'} `} aria-current="page">
                 {route?.category}
                </li>}
              </ol>
            </nav>
          </div>
          <div className="col-12 col-xl-3">
            <div className={`d-flex align-items-center ${Styles.searchContainer} w-100`}>
              <div className={`${Styles.searchIcon}`}>
                <CiSearch size={20} color="black" className="p-0 m-0"/>
              </div>
              <input
                className={`border-0 ml-2 bg-transparent border-none ${Styles.inputField}`}
                type="text"
                placeholder="Search articles"
                style={{border:0}}
              />
            </div>
          </div>
        </div>
        <div className={`my-2 ${mode==='lightMode'?"bg-white text-black":"bg-black text-white"}`}>
            <h1 className="font-44 font-weight-bold ">Announcements</h1>
        </div>
      </section>
    );
  };



  useEffect(() => {
    const handleResize = () => {
      setwindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return <section className={`${mode==='lightMode'?"bg-white":"bg-black"}`} style={{ padding: windowWidth < 1024 ? "0 80px" : "0 140px" }} >
    {renderFirstSection()}
    <RenderList leftList={leftList} mode={mode} rightList={rightList}/>
    </section>;

};

export default AnnouncementScreen;
