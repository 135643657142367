import React, {  useMemo, useState } from "react";
import { Sidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import "./Dashboard.css";
import { Link } from 'react-router-dom';

export default function SideBar(props) {
  const [width, setwidth] = useState(window.innerWidth);
  const [collapse, setcollapse] = useState(false);

  const getSize = () => {
    setwidth(window.innerWidth);
  };
  useMemo(() => {
    window.addEventListener("resize", getSize);
    if (width < 400) {
      setcollapse(true);
    } else {
      setcollapse(false);
    }
  }, [window.innerWidth,props.breakpoint]);

 
  return (
      <Sidebar
        rootStyles={{ backgroundColor: "black" }}
        collapsed={collapse}
        title="Dashboard 1"
      >
        <div className="border-bottom border-dark">
          <h5 className="text-center m-1">DashBoard 1 </h5>
        </div>
        <Menu
          menuItemStyles={{
            button: {
              // the active class will be added automatically by react router
              // so we can use it to style the active menu item
              [`&.active`]: {
                backgroundColor: "#13395e",
                color: "#b6c8d9",
              },
            },
          }}
        >
          <SubMenu label="Portfolio">
            <MenuItem>  Token </MenuItem>
            <MenuItem> NFT Gallery </MenuItem>
            <MenuItem>  POAPs </MenuItem>
            <MenuItem>  NFT financial View </MenuItem>
            <MenuItem>  Usage Stats </MenuItem>
          </SubMenu>
          <MenuItem component={<Link to="/profile/dashboard"/>}> POAPs </MenuItem>
          <MenuItem> NFT financial View </MenuItem>
          <MenuItem> Calendar </MenuItem>
          <MenuItem> Documentation </MenuItem>
          <MenuItem> Calendar </MenuItem>
          <MenuItem> Documentation </MenuItem>
          <MenuItem> Calendar </MenuItem>
        </Menu>
      </Sidebar>
     
  );
}
