import React, { PureComponent } from 'react';
import { useState } from 'react';
import { useCallback } from 'react';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const data = [
  {
    name: 'Page A',
    uv: 4000,
    pv: 2400,
    amt: 2400,
  },
  {
    name: 'Page B',
    uv: 3000,
    pv: 1398,
    amt: 2210,
  },
  {
    name: 'Page C',
    uv: 2000,
    pv: 9800,
    amt: 2290,
  },
  {
    name: 'Page D',
    uv: 2780,
    pv: 3908,
    amt: 2000,
  },
  {
    name: 'Page E',
    uv: 1890,
    pv: 4800,
    amt: 2181,
  },
  {
    name: 'Page F',
    uv: 2390,
    pv: 3800,
    amt: 2500,
  },
  {
    name: 'Page G',
    uv: 3490,
    pv: 4300,
    amt: 2100,
  },
];

export default function CustomLineCharts(props){
    const mode=useSelector(state=>state.themeType.themeType);
    const [width,setwidth]=useState(window.innerWidth-20)

console.log(width,'ht')
    return (
      <ResponsiveContainer width={"99%"} height={320}>
        <LineChart
          width={"100%"}
          height={300}
          data={props.data}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <XAxis dataKey={props.xaxisTitlekey} />
          <YAxis dataKey={props.yaxisTitlekey} />
          <Tooltip
          contentStyle={{backgroundColor:mode==="lightMode"?"black":"white",color:mode==="lightMode"?"white":"black",borderRadius:"10px"}}
          />
          <Legend />
          {
            props.children
          }
        </LineChart>
      </ResponsiveContainer>

    );
}
