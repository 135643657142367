import React, { useEffect, useMemo, useState } from "react";
import "react-multi-carousel/lib/styles.css";
import axios from "axios";
import { sendPerfomance } from "..";
import { trace } from "firebase/performance";
import Loader, { Loader2 } from "./Loader";
import UserImage from "../assets/user.png";
import blueTick from "../assets/blue_Tick.png";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {
  FaChevronRight,
  FaChevronLeft,
  FaLongArrowAltUp,
} from "react-icons/fa";
import { tracer } from "../tracing";
import "./custom.css";
import { useSelector } from "react-redux";
import CustomSelect from "./CustomSelect";
import { useNavigate } from "react-router-dom";
import ReactGA from 'react-ga4';
import styles from './carousel.module.css';
import useWindowWidth from "./useWindowWidth";
import CustomShimmer from "./CustomShimmer";
import NoDataFound from "./NoDataFound";
export default function CustomCarousel(props) {
  const mode = useSelector((state) => state.themeType.themeType);
  const [recentRocketData, setrecentRocketData] = useState([]);
  const [isLoading, setisLoading] = useState(false);
  const [currentFilter, setcurrentFilter] = useState("30d");
  const [currentpage, setcurrentPage] = useState(1);
  const [blockchain, setblockchain] = useState("all");
  const [visible,setvisible]=useState(false);
  const token = localStorage.getItem("accessToken");
  const scwidth=useWindowWidth()
  const navigate=useNavigate();

  const recentRocketApi = async () => {
    let header = {};
    if (token === null || token === undefined || token === "") {
    } else {
      header = {
        Authorization: token,
      };
    }
    const params = {
      timeframe: currentFilter,
      sortBy: "volume",
      sortOrder: "desc",
      page: String(currentpage),
      size: "8",
      blockchain: "all",
    };
    try {
      setisLoading(true);
      const t = trace(sendPerfomance, props.apiTitle);
      const span = tracer.startSpan("recentRocketApi");
      t.start();
      await axios
        .post(props.rocketUrl, params, { headers: header })
        .then((res) => {
          if (res.status === 200) {
            ReactGA.event({
              category: 'api response',
              action: 'hit the api ',
              label: props.rocketUrl,
              nonInteraction:false,
              value:JSON.stringify(res.data)
            })
            console.log("recetnt_Rocket_data======>>>>>>>>>>>>", res.data);
            setrecentRocketData(res.data);
            setisLoading(false);
          }
          span.addEvent("api_response", {
            status: res.status,
            data: res.data,
            url: props.rocketUrl,
          });
        })
        .catch((err) => {
          console.log(err);
          setisLoading(false);
        })
        .finally(() => {
          t.stop();
          setisLoading(false);
          span.end();
        });
    } catch (error) {
      console.log(error, "this is error");
    }
  };
  const optionfilter = [
    { id: 3, label: "30d", value: "30d" },
    { id: 1, label: "24h", value: "24h" },
    { id: 2, label: "7d", value: "7d" },
    { id: 4, label: "60d", value: "60d" },
    { id: 5, label: "90d", value: "90d" },
    // { id: 6, label: "1Y", value: "1Y" },
    // { id: 7, label: "All", value: "All" },
  ];
  useMemo(() => {
    recentRocketApi();
  }, [currentFilter, currentpage]);

  const handleClickonItem = (item) => {
    ReactGA.event({
      category: 'card Item',
      action: 'select the collection',
      label: item?.name,
      nonInteraction:false,
      value:JSON.stringify(item)
    })
    navigate(`/collection/${item?.contract_id}/overview`, {state:{
      userData: item,
      blockchain: item.blockchain,
      image_url:item?.collection_image_url,
      contract_id:item?.contract_id
    }});
  };
  const updatesortingFilter=(val)=>{
    ReactGA.event({
      category: 'select input',
      action: 'select entity',
      label: val,
      nonInteraction:false,
      value:val
    })
    console.log(val)
    setcurrentPage(1);
    setcurrentFilter(val);
  }
  return (
    <section
      className={`recentRocketSection ${
        props.mode === "lightMode"
          ? "text-dark"
          : "text-white border-secondary "
      }`}
      onMouseEnter={()=>{setvisible(true)}}
      onMouseLeave={()=>{setvisible(false)}}
      onTouchStart={()=>{setvisible(true)}}
      onTouchEnd={() => setvisible(false)}
    >
      <div className="row mx-0">
        <div className="col-12  d-flex justify-content-between ">
          <h2
            style={{
              fontSize: "24px",
              lineHeight: "40px",
              fontWeight:'700',
              color:mode==="lightMode"?"black":"white",
              fontFamily:
                "HarmonyOS Sans,PingFang SC,OSPunctuation,Microsoft Yahei,Heiti SC,WenQuanYi Micro Hei,Helvetica Neue,Helvetica,Arial,sans-serif !important",
            }}
          >
            {props?.title}
          </h2>
          <CustomSelect
            options={optionfilter}
            updatevalue={updatesortingFilter}
            mode={mode}
          />
          
        </div>
      </div>
    <div className="w-100" style={{position:"relative"}} >
            {currentpage!==1&&<div
              className="leftArrow "
              onClick={() => {
                ReactGA.event({
                  category: 'previous page icon button',
                  action: 'clicked on the previous page button in recent rocket section',
                  label: currentpage,
                  nonInteraction:false,
                  value:currentpage
                })
                setcurrentPage(currentpage - 1);
              }}
            >
              <FaChevronLeft size={10} color={mode == "lightMode" ? "black" : "white"} />
            </div>}
      <div className={` row ${styles.rocketList} scrollbar mx-2`}>
          {(isLoading?[...Array(5).keys()]:recentRocketData).map((elem, index) => {
              return (
                  <div
                  className={`media my-3 col-lg-3 col-md-4 col-sm-6 ${index>0&&'ml-5'} pointer d-flex align-items-center`}
                  key={index}
                  onClick={() => {
                    handleClickonItem(elem);
                  }}>
                  {isLoading?<CustomShimmer style={{width:"84px",height:'84px'}} className="mr-1 rounded rounded-circle"/>:<img
                    className="mr-1 rounded rounded-circle"
                    src={
                      elem.collection_image_url !== "null"
                        ? elem.collection_image_url
                        : UserImage
                    }
                    alt="Generic placeholder "
                    width={"84px"}
                    height={"84px"}
                  />}
                  <div className="media-body mt-1 ml-2 align-items-center ">
                    <div className="d-flex align-items-center">
                      <p
                        className="mt-0 mb-0 font-weight-bold text-nowrap font-16"
                      >
                        {isLoading?<CustomShimmer className="py-1 font-weight-bold rounded-10" style={{width:'7em'}}/>:elem.collection_name
                          ? elem.collection_name.length > 14
                            ? elem.collection_name.slice(0, 14) + "..."
                            : elem.collection_name
                          : ""}
                      </p>
                     {isLoading?<CustomShimmer className="rounded-10" style={{width:"14px",height:'14px'}} />: <img
                        src={blueTick}
                        alt="..."
                        className="ml-2"
                        loading="lazy"
                        width={"14px"}
                        height={"14px"}
                      />}
                    </div>
                    {isLoading?<CustomShimmer className="py-1 my-1 font-weight-bold rounded-10" style={{width:'5em'}}/>:<div className="d-flex  ">
                      <FaLongArrowAltUp
                        size={13}
                        color="#31bd65"
                        style={{
                          justifyItems: "center",
                          alignContent: "center",
                        }}
                      />
                      <p
                        className=" mb-0"
                        style={{
                          color: "#31bd65",
                          fontSize: "14px",
                          lineHeight: "16px",
                          fontWeight: "bolder",
                        }}
                      >
                        {Number(elem?.volume_growth).toFixed(2)} %
                      </p>
                    </div>}
                      {isLoading?<CustomShimmer className="py-1 font-weight-bold rounded-10" style={{width:'5em'}}/>:<p className="font-14 font-weight-bold">Items:  {elem?.items}</p>}
                  </div>
                    </div>
              );
            })}
      </div>
          {!isLoading&& recentRocketData.length===0?<NoDataFound mode={mode}/>: null}
          {recentRocketData.length > 0  && (
            <div
              className="rightArrow"
              onClick={() => {
                ReactGA.event({
                  category: 'next page link',
                  action: 'clicked on the next page button',
                  label: currentpage,
                  nonInteraction:false,
                  value:currentpage
                })
                setcurrentPage(currentpage + 1);
              }}
            >
              <FaChevronRight
                size={10}
                color={mode === "lightMode" ? "black" : "white"}
              />
            </div>
          )}
    </div>
    </section>
  );
}
