import React from "react";
import { useSelector } from "react-redux";

export default function CustomProgressBar(props) {
const mode=useSelector(state=>state.themeType.themeType);
  return (
    <div
      className={`${props.mainContainerClass} ${
        mode === "lightMode" ? "bg-white text-dark" : "bg-black text-white"
      }`}
    >
      <p>{props.title}</p>
      <div className="progress">
        {props.children}
      </div>
      <div className="d-flex justify-content-between">
        {
            props.bottomobj.map((elem,index)=>{
                return (
                    <div>
                    <p className="text-secondary m-0 p-0" key={index}>{elem.title}</p>
                    <p>{elem.value}</p>
                  </div>
                )
            })
        }
      </div>
    </div>
  );
}
