import React, { useState,useEffect } from "react";
import { useSelector } from "react-redux";
import "./BidcollectionItem.css";
import bannerImg from "../../assets/bannerImg.png";
import { AiOutlineTwitter, AiOutlineShareAlt } from "react-icons/ai";
import { FaMask, FaTelegramPlane, FaFacebook } from "react-icons/fa";
import axios from "axios";
import { toast } from "react-toastify";
import { useMemo } from "react";
import { trace } from "firebase/performance";
import { sendPerfomance } from "../..";

export default function BidcollectionItem() {
  const theme = useSelector((state) => state.themeType.themeType);
  const [readmore, setreadmore] = useState(true);
  const [projectData, setprojectData] = useState([]);
  const [loadingtext,setloadingText]=useState('Please wait! Data is loading');
  const [isloading, setisloading] = useState(true);

  const bannerImage = () => {
    return (
      <div className="d-flex align-items-center flex-column">
        <img className="bannerImage border-bottom " alt="..." src={bannerImg} />
        <img
          className="bannerImageLogo rounded rounded-circle text-center d-block"
          width="120px"
          height={"120px"}
          alt="..."
          src={bannerImg}
        />
      </div>
    );
  };

  const secondSection = () => {
    return (
      <div className="d-flex justify-content-end socialMediaIconContainer">
        <span>
          <AiOutlineTwitter
            size={35}
            className={`mx-3 pointer border ${
              theme === "lightMode" ? "border-dark" : "border-white"
            } rouned rounded-circle p-1 mediaIcon`}
          />
        </span>
        <span>
          <AiOutlineShareAlt
            size={35}
            className={`mx-3 pointer border ${
              theme === "lightMode" ? "border-dark" : "border-white"
            } rouned rounded-circle  p-1 mediaIcon`}
          />
        </span>
        <span>
          <FaMask
            size={35}
            className={`mx-3 pointer border ${
              theme === "lightMode" ? "border-dark" : "border-white"
            } rouned rounded-circle  p-1 mediaIcon`}
          />
        </span>
        <span>
          <FaTelegramPlane
            size={35}
            className={`mx-3 pointer border ${
              theme === "lightMode" ? "border-dark" : "border-white"
            } rouned rounded-circle  p-1 mediaIcon`}
          />
        </span>
        <span>
          <FaFacebook
            size={35}
            className={`mx-3 pointer border ${
              theme === "lightMode" ? "border-dark" : "border-white"
            } rouned rounded-circle p-1 mediaIcon `}
          />
        </span>
      </div>
    );
  };
  const mainHeading = () => {
    const description = ` "This is the first collection of the new Italian project that wants
    to revolutionize the world of Goods/Services payments by eliminating
    all inefficiencies and ferrying it into the world of Stable Coins!
    The intention of the creator in this first work is to give human
    form to the act of custody (hence the name ""Custodiy""). Other
    collections will certainly follow but the first will definitely be a
    ""must have""!`;
    return (
      <div className="row">
        <div className="col d-flex justify-content-center flex-column align-items-center">
          <span className="d-flex align-items-center">
            <h1>Custodiy NFT </h1>
            <img
              className="rounded rounded-circle "
              src="https://www.bybit.com/nft/static/img/icon3.7f6d2a19.svg"
              alt="..."
              width={"84px"}
              height={"84px"}
            />
          </span>
          <p className="text-center">
            Creator: <span style={{ color: "#f7a600" }}>@Custodiy</span>
          </p>
          {description.length > 60 ? (
            readmore ? (
              <p className="w-50">{description.slice(0, 60)}...</p>
            ) : (
              <p className="w-50">{description}</p>
            )
          ) : (
            <p className="w-50">{description}</p>
          )}
          {description.length > 60 && (
            <button
              className={`${
                theme === "lightMode" ? "btn-warning" : "btn-outline-warning"
              } btn`}
              onClick={() => {
                setreadmore(!readmore);
              }}
            >
              {readmore ? "Read more" : "read less"}
            </button>
          )}
        </div>
      </div>
    );
  };

  useMemo(()=>{

  },[window.innerWidth])
  const ratingsecton = () => {
    return (
      <div className="row mt-4">
        <div className="col d-flex flex-wrap justify-content-center">
          <div className="d-flex flex-column justify-content-center align-items-center borderItem p-5 m-1">
            <h1>1.05</h1>
            <p className="text-secondary">Floor Price($)</p>
          </div>

          <div className="d-flex flex-column justify-content-center align-items-center borderItem p-5 m-1">
            <h1>100.00</h1>
            <p className="text-secondary">Highest Traded Price ($)</p>
          </div>

          <div className="d-flex flex-column borderItem justify-content-center align-items-center borderrItem p-5 m-1">
            <h1>4.75K</h1>
            <p className="text-secondary">Total Trading Value ($)</p>
          </div>

          <div className={`d-flex flex-column justify-content-center align-items-center  p-5 m-1 ${window.innerWidth<949 && "borderItem"}`}>
            <h1>5.50 K</h1>
            <p className="text-secondary">Issued Quantity</p>
          </div>
        </div>
      </div>
    );
  };

  const customCard = (elem,index) => {
    return <div key={index} className={`col-10 col-md-3 mx-auto   ${theme==="lightMode"?"":"bg-dark"} pointer mt-4 card p-0 customcard`}>
      <img className="card-img-top border-bottom border-dark" height={""} src={elem.imageUrl?elem.imageUrl:"https://www.bybit.com/bycsi-root/static-nft/nft/thumImg/thum_xSGOABmlZzNaBd2C5ds5ZRURjkWReLxQVOEPjiF9jew.png"} alt="..." style={{objectFit:'cover'}}/>
      <div className="card-body">
        <p className="card-title mb-0">{elem?.title}</p>
        <small className="text-warning2">@Custodiy</small>
        <div className="d-flex justify-content-between">
          <span className="text-secondary">Price</span>
          <span><p className="mb-0">{elem?.highest_trade_price} USDT</p>
          <small className="text-secondary"> $ {elem?.qunatity_issued}</small>
          </span>
        </div>
        <div className="card-overlay d-flex  align-items-end">
          <button className="btn btn-block m-1 btn-warning font-weight-bold">Buy Now</button>
        </div>
      </div>
    </div>;
  };
  const cardcontainer = () => {
    return (
      <div className="container py-5">
        <div className="row">
          {
            projectData.length>0?
            projectData.map((elem,index)=>{
            return customCard(elem,index);
            }):
            (
              <div className="col-6 mx-auto">
                 <h1 className="text-center">{loadingtext}</h1>
              </div>
            )
           
          }
        </div>
      </div>
    );
  };

  //........request api for grabic project card..........
  const requestApigrabicproject = async () => {
    setisloading(true);
    const t=trace(sendPerfomance,'MockUrl2');
    t.start();
    try {
      await axios
        .get("https://run.mocky.io/v3/ce423f02-4f39-4261-99e7-80f58fadb10f")
        .then((res) => {
          if (res.status === 200) {
            setisloading(false);
            console.log(res.data);
            setprojectData(res.data);
          }
        })
        .catch((err) => {
          toast.error("Data not found");
          setloadingText('Data not Found')
          console.log(err);
        });
    } catch (error) {
      console.log(error);
      setloadingText('Data not found')
      setisloading(false);
    }
    t.stop();
  };
  useEffect(() => {
    requestApigrabicproject();
  }, []);



  return (
    <div
      className={`${
        theme === "lightMode" ? "bg-light text-dark" : "text-white bg-black"
      }`}
    >
      <section>{bannerImage()}</section>
      <section>{secondSection()}</section>
      <section>{mainHeading()}</section>
      <section>{ratingsecton()}</section>
      <section>
        <div > 
          <h1 className="text-center m-0 p-0">
            Collection Data
          </h1>
          {cardcontainer()}
        </div>
      </section>
    </div>
  );
}
