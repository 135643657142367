import React, { useEffect, useState } from "react";
import Styles from './Announcement.module.css'
import {Link, useNavigate} from 'react-router-dom'

const RenderList = (props) => {
  const { leftList,mode,rightList } = props;
  const [currentLeftActiveItem,setcurrentLeftActiveItem]=useState(0)
  const navigation=useNavigate();
  return (
    <section className={`${mode==='lightMode'?"bg-white text-black":"bg-black text-white"} pt-5`}>
      <div className="row border-top">
        <div className="d-xl-block d-none col-4 border-right pr-0">
          <div className="list-group" id="list-tab" role="tablist">
            {leftList.map((item, index) => (
              <p
                className={`list-group-item pointer  border-0 m-0 list-group-item-action font-18 ${mode==='lightMode'?'text-black  bg-white':'text-white bg-black'} ${Styles.LeftListItem}  ${(index)===currentLeftActiveItem&&"font-weight-bold"}`}
                onClick={()=>{setcurrentLeftActiveItem(index);navigation(`/announcements/category/${item?.label}`)}}
                key={index}
              >
                {item?.label}
              </p>
            ))}
          </div>
        </div>
        <div className="col">
            {rightList?.map((item,index)=>(
                <div key={index} className="p-2 pointer" onClick={()=>{navigation(`/announcements/${leftList[currentLeftActiveItem]?.label}/${item?.title}`,{state:{
                  item
                }})}}>
                    <h2 className={`font-18 font-weight-bold ${mode==='lightMode'?"text-black":'text-white'}`}>{item?.title}</h2>
                    <p className="font-14" style={{color:'#909090'}}>{item?.desc}</p>
                </div>
            ))}
        </div>
      </div>
    </section>
  );
};

export default RenderList;
