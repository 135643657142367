import axios from "axios";
import { getEnvVariable } from "../../utils/utility/Utility";
export const BaseUrl= getEnvVariable('REACT_APP_FETCH_PARTICULAR_COLLECTION_BASEURL') ;
export const websocketBaseUrl=getEnvVariable('REACT_APP_COLLECTION_WEBSOCKET_URL');
export const testingUrl=getEnvVariable('REACT_APP_TESTING_VAR')
export const REACT_APP_apiKey=getEnvVariable('REACT_APP_apiKey');
export const REACT_APP_authDomain=getEnvVariable('REACT_APP_authDomain')
export const REACT_APP_DATABASEURL=getEnvVariable('REACT_APP_DATABASEURL')
export const REACT_APP_projectId=getEnvVariable('REACT_APP_projectId')
export const REACT_APP_storageBucket=getEnvVariable('REACT_APP_storageBucket');
export const REACT_APP_messagingSenderId=getEnvVariable('REACT_APP_messagingSenderId')
export const REACT_APP_appId=getEnvVariable('REACT_APP_appId')
export const REACT_APP_measurementId=getEnvVariable('REACT_APP_measurementId')
export const REACT_APP_FETCH_PARTICULAR_COLLECTION_BASEURL=getEnvVariable('REACT_APP_FETCH_PARTICULAR_COLLECTION_BASEURL')
export const REACT_APP_NEW_CLOUD_GRAFANA_URL=getEnvVariable('REACT_APP_NEW_CLOUD_GRAFANA_URL');
export const REACT_APP_EMAIL_BASE_URL=getEnvVariable('REACT_APP_EMAIL_BASE_URL')
export const REACT_APP_CHAT_SERVER_URL=getEnvVariable('REACT_APP_CHAT_SERVER_URL')
export const REACT_APP_ALCHEMYAPIKEY=getEnvVariable('REACT_APP_ALCHEMYAPIKEY')
export const REACT_APP_NEW_TEMPO_URL=getEnvVariable('REACT_APP_NEW_TEMPO_URL')
export const REACT_APP_COLLECTION_WEBSOCKET_URL=getEnvVariable('REACT_APP_COLLECTION_WEBSOCKET_URL')
export const REACT_APP_LEADERBOARD_BASE_URL=getEnvVariable('REACT_APP_LEADERBOARD_BASE_URL');
export const REACT_APP_DAPP_BASEURL=getEnvVariable('REACT_APP_DAPP_BASEURL');
const Axios=axios.create({
  baseURL:BaseUrl
})

export const AxiosforIssues=axios.create({
  baseURL:process.env.REACT_APP_ISSUE_BASEURL
})
export default Axios;

export const AxiosForDiscover=axios.create({
  baseURL:REACT_APP_DAPP_BASEURL
})