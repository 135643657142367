import React from 'react';
import { Resource } from '@opentelemetry/resources';
import { SemanticResourceAttributes } from '@opentelemetry/semantic-conventions';
import { WebTracerProvider, SimpleSpanProcessor, BatchSpanProcessor } from '@opentelemetry/sdk-trace-web';
import { OTLPTraceExporter } from '@opentelemetry/exporter-trace-otlp-http';
import { registerInstrumentations } from '@opentelemetry/instrumentation';
import { FetchInstrumentation} from '@opentelemetry/instrumentation-fetch';
import { ConsoleSpanExporter } from '@opentelemetry/sdk-trace-web';
import { REACT_APP_NEW_TEMPO_URL } from './config/API/Api';
const provider = new WebTracerProvider({
  resource: new Resource({
    [SemanticResourceAttributes.SERVICE_NAME]: "Rating-ui",
  }),
});
const consoleExporter = new ConsoleSpanExporter();
const collectorExporter = new OTLPTraceExporter({
  url:REACT_APP_NEW_TEMPO_URL,
  // url:`https://tempo.justratenft.com`,
  // url: 'http://localhost:4318/v1/traces',
  // url: 'http://tempo-distributor:4317/v1/traces',
  headers: {},
});

provider.addSpanProcessor(new BatchSpanProcessor(collectorExporter));
provider.addSpanProcessor(new SimpleSpanProcessor(consoleExporter));
provider.addSpanProcessor(new SimpleSpanProcessor(collectorExporter));

registerInstrumentations({
  instrumentations: [
    new FetchInstrumentation(),
  ],
  tracerProvider: provider,
});

provider.register();

export const tracer=provider.getTracer('jrn');
export default function TraceProvider({ children }) {
  return <>{children}</>;
};

