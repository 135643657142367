import React from "react";
import { useSelector } from "react-redux";
import {
  Radar,
  RadarChart,
  PolarGrid,
  PolarAngleAxis,
  PolarRadiusAxis,
  AreaChart,
  Area,
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Pie,
  PieChart,
} from "recharts";

import {data,redarData} from './chartData'
export default function Charts() {
  const mode = useSelector((state) => state.themeType.themeType);
  const demoUrl = "https://codesandbox.io/s/simple-line-chart-kec3v";
 

  return (
    <div
      className={`${
        mode === "lightMode" ? "bg-light text-dark" : "bg-black text-white"
      }`}
    >
      <div className={`container py-5`}>
        <div className="row">
          <div className="col ">
            <LineChart
              width={500}
              height={400}
              data={data}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="2 2" />
              <XAxis dataKey="year" />
              <YAxis  />
              <Tooltip className="bg-dark" contentStyle={{backgroundColor:mode==="lightMode"?"black":"white",color:mode==="lightMode"?"white":"black",borderRadius:"10px"}} />
              <Legend />
              <Line
                type="monotone"
                dataKey="pv"
                stroke="#8884d8"
                activeDot={{ r: 8 }}
              />
              <Line type="monotone" dataKey="uv" stroke="#82ca9d" />
              <Line type="monotone" dataKey="amt" stroke="yellow" />
            </LineChart>
          </div>
          <div className="col ">
            <AreaChart
              width={500}
              height={400}
              data={data}
              margin={{
                top: 10,
                right: 30,
                left: 0,
                bottom: 0,
              }}
              title="Bar chart"
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="year" />
              <YAxis />
              <Legend/>
              <Tooltip />
              <Area
                type="monotone"
                dataKey="uv"
                stackId="1"
                stroke="#8884d8"
                fill="#8884d8"
              />
              <Area
                type="monotone"
                dataKey="pv"
                stackId="1"
                stroke="#82ca9d"
                fill="#82ca9d"
              />
              <Area
                type="monotone"
                dataKey="amt"
                stackId="1"
                stroke="#ffc658"
                fill="#ffc658"
              />
            </AreaChart>
          </div>
        </div>

        <div className="row">
          <div className="col">
            <h1 className="text-center">radarcenter</h1>
            <ResponsiveContainer width="80%" height="80%">
              <RadarChart
                cx="50%"
                cy="50%"
                outerRadius="80%"
                data={redarData}
                title="helo"
              >
                <PolarGrid />
                <PolarAngleAxis dataKey="subject" />
                <PolarRadiusAxis />
                <Radar
                  name="Mike"
                  dataKey="A"
                  stroke="#8884d8"
                  fill="#8884d8"
                  fillOpacity={0.6}
                />
              </RadarChart>
            </ResponsiveContainer>
          </div>
          <div className="col">
              <PieChart>
                <Pie dataKey="value" data={data} fill="#8884d8" label />
              </PieChart>
          </div>
        </div>
      </div>
    </div>
  );
}
