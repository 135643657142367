import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Styles from "./xplayer.module.css";
import Ball from "./Ball";
import "../../App.css";
import Slider from "react-slick";
import './xplayer.css'
import {Area,ResponsiveContainer,AreaChart,Curve} from "recharts";
import { FaXTwitter,FaMask,FaAppStore } from "react-icons/fa6";
import { FaLinkedinIn,FaGithub,FaEnvelope } from "react-icons/fa";
import boxImage from '../../assets/boxImage.webp'
import cardImage from '../../assets/cardImage.webp'
import web3 from '../../assets/web3.webp'
import polygon from '../../assets/polygon.webp'
import Triangle from '../../assets/Triangle.webp'
import security from '../../assets/security.webp'
import shieldImage from '../../assets/shieldImage.webp'
import Shield2Image from '../../assets/Shield2Image.webp'

const Xplayer = () => {
  const mode = useSelector((state) => state.themeType.themeType);
  const token = localStorage.getItem("accessToken");
  const [windowWidth, setwindowWidth] = useState(window.innerWidth);
  const [mapVisible,setmapVisible]=useState(false);
  const [imageVisible,setimageVisible]=useState(false);
  const roadmapList=[
    {title:'X Layer testnet launch',subTitle:'2023 | Q4',list:['Seamless DApp integration','Sequencer stability test','Performance stress test','Allowing Sepolia assets to bridge to X Layer testnet']},
    {title:'X Layer testnet optimization',subTitle:'2023 | Q4',list:['Decentralized Data Availability Committee (DAC)','Scaling based on EIP-4844','Enhance proof generation time','Iterative testing and optimization']},
    {title:'X Layer mainnet launch',subTitle:'2024 | Q1',list:['Transition from testnet to mainnet','Launch of zkBridge, zkOracle and zkDEX','Deployment of DApps by key partners on X Layer mainnet']},
    {title:'Upgrade infrastructure',subTitle:'2024 | Q2',list:['Improve the performance of the sequencer and zkProver','Align with EIP-4844 and upgrade to ZK-Rollup','Upgrade RPC architecture to Erigon']},
    {title:'Optimize technical architecture',subTitle:'2024 | Q3',list:['Upgrade the sequencer to Erigon','Boost the performance of the sequencer and zkProver','Support all EVM opcodes and precompiled contracts']},
    {title:'Enhance scalability',subTitle:'2024 | Q4',list:['Support native account abstraction','Decentralize the sequencer','Upgrade zkProver’s algorithm to Plonky2']},
  ]
  const iconList=[
    {
      name:"",
      icon:<FaXTwitter size={25} color="white" />
    },
    {
      name:"",
      icon:<FaLinkedinIn size={25} color="white"/>
    },
    {
      name:"",
      icon:<FaMask size={25} color="white"/>
    },
    {
      name:"",
      icon:<FaAppStore size={25} color="white"/>
    },
    {
      name:"",
      icon:<FaGithub size={25} color="white"/>
    },
    {
      name:"",
      icon:<FaEnvelope size={25} color="white"/>
    },
  ]

  const renderFirstSection = () => {
    return (
      <section>
        <div className="row">
          <div className={`col-lg-6 offset-lg-1 d-flex flex-column justify-content-center ${windowWidth<768?"align-items-center":""} col-md-6 col-sm-12  order-2 order-md-1 `} style={{ minHeight: windowWidth >= 768 && "650px" }}>  
            <h1 className={`font-weight-bold  ${windowWidth<768?'text-center':"mt-5"} `} style={{ fontSize: windowWidth<768?'32px': "66px" }}>
              Ignite {windowWidth>768&&<br/>}  your creativity
            </h1>
            <p
              className={windowWidth<768?"w-50":""}
              style={{color: "#ccc",lineHeight: "24px",fontSize: "18px",textOverflow: "ellipsis",textAlign:windowWidth<768&&'center'}}>
              X Layer is a ZK-powered layer 2 network that connects the JRN and
              Ethereum communities to allow anyone to take part in a truly
              global on-chain ecosystem.
            </p>
            <div className="row">
              <div className="col-12 col-lg-6"><button className={`${Styles.ItemBtn} btn m-1 btn-block border-secondary font-weight-bold ${mode === "lightMode" ? "bg-light" : "bg-white"}`}>
                Start building
              </button></div>
              <div className="col-12 col-lg-6"> <button
                className={` ${
                  Styles.ItemBtn
                } btn  m-1 border-secondary btn-block font-weight-bold ${mode === "lightMode" ? "bg-light" : "bg-dark text-white"}`}>
                Bridge to X Layer
              </button></div>
            </div>
          </div>
          <div className={`col-lg-4 offset-lg-1 col-md-6 col-sm-12 align-self-center order-1 order-md-2 ${windowWidth<768?"my-5 d-flex justify-content-center":""}`}>
            <img
              className="img-respoinve img-fluid"
              src={boxImage}
              alt="..."
              width={windowWidth<768&&"242px"}
              height={windowWidth<768&&"242px"}
            />
          </div>
        </div>
      </section>
    );
  };
  const renderSecondSection = () => {
    return (
      <section className={Styles.containerBox}>
        <div className={`row `}>
          <div className=" col-xl-6 col-lg-12 text-center">
            <Ball width={windowWidth / 3+50} ballSize={70} />
          </div>
          <div className=" col-xl-6 col-lg-12">
            <h1
              className={` font-weight-bold ${
                windowWidth < 1200 ? "font-36" : "font-48"
              } ${windowWidth<768?"text-center my-5":"text-left"}`}
            >
              Join the community building on X Layer
            </h1>
            <p
              className={`my-3 ${windowWidth<768?"text-center mb-5":"text-left"}`}
              style={{ color: "#909090", lineHeight: "24px", fontSize: "21px" }}
            >
              Find your favourite DApps on X Layer and kickstart your own
              journey today
            </p>
            <button
              className={`${
                Styles.ItemBtn
              } btn btn-block  border-secondary font-weight-bold mt-2 ${windowWidth<768?"w-75 mx-auto":""} ${
                mode === "lightMode" ? "bg-light" : "bg-white"
              }`}
            >
              Explore X Layer ecosystem
            </button>
          </div>
        </div>
      </section>
    );
  };
  const renderThirdSection = () => {
    return (
      <section>
      <div className="">
            <h2 className={`text-center font-weight-bold ${windowWidth<768?'px-2':''}`} style={{fontSize:windowWidth<768?'30px':'52px'}}>
              Built for all users and builders
            </h2>
            <p
              className="py-2 font-20 text-center "
              style={{ color: "#909090", fontSize: "20px" }}
            >
              Using OKB as the X Layer native token, users can move value
              seamlessly between JRN and X Layer
            </p>
            <div
              className="row"
              style={{ margin: "26px 0px 0px" }}
            >
             { [
                {image:Triangle,title:'EVM compatibility',description:'Enable ZK-powered DApps on X Layer, with 100% EVM compatibility and easy-to-use developer tools for deployment'},
                {image:polygon,title:'Powered by Polygon',description:'A zkEVM layer 2 solution based on Polygon CDK, ensuring near-instant finality, unified liquidity, and independent data availability'},
                {image:security,title:'Trusted network',description:'Securely deploy any EVM smart contracts and onramp your assets from JRN, Ethereum, and other interoperable chains'},
                {image:web3,title:'Portal to Web3',description:'Enter the world of Web3 via JRN Wallet, built with compact infrastructure modules to create innovative DApps'},
              ].map((elem,index)=>(
                <div className="col-sm-6 d-flex align-items-center" key={index} style={{margin:'59px 0px 0px'}}>
                <div className="media">
                  <img
                    className="mr-3"
                    src={elem?.image}
                    alt="..."
                    width={'90px'}
                    height={'90px'}
                  />
                  <div className="media-body">
                    <h5 className="mt-0 font-weight-bold" style={{fontSize:'26px'}}>{elem.title}</h5>
                    <p style={{color:'#909090',fontSize:'16px',margin:'13px 0px 0px',lineHeight:"24px"}}>{elem.description}</p>
                  </div>
                </div>
              </div>
              ))}
              
            </div>
          </div>
      </section>
    );
  };
  const renderFourthSection=()=>{
    return (
      <section style={{marginTop:'150px'}}>
        <div className="row">
          <div className="col-md-6 col-sm-12">
            <h2 className="font-weight-bold" style={{fontSize:windowWidth>768?'120px':'80px',textAlign:windowWidth>768?'left':'center'}}>1%</h2>
            <p className="font-weight-bold" style={{fontSize:windowWidth<768?"24px": '36px',margin:'10px 0px 0px',textAlign:windowWidth>768?'left':'center'}}>Ethereum gas cost</p>
            <p style={{fontSize:windowWidth<768?"16px":'24px',margin:'24px 0px 0px',textAlign:windowWidth>768?'left':'center'}}>Code with ease at 1% of Ethereum’s costs.</p>
            <small style={{fontSize:'12px',margin:'8px 0px 0px',color:"#909090",textAlign:windowWidth>768?'left':'center'}}>*The actual network fees may fluctuate depending on price changes in ETH, OKB, and network congestion level, etc.</small>
          </div>
          <div className="col-md-6 col-sm-12 d-none d-md-block align-self-center">
            <img className="img-responsive img-fluid" src={cardImage} alt="..."/>
          </div> 
        </div>
      </section>
    )
  }
  const renderFifth=()=>{
    return (
      <section style={{marginTop:'150px'}}>
        <div className="row">
          <div className="col-md-6 col-sm-12 d-none d-md-block align-self-center">
            <img className="img-responsive img-fluid" width={'358px'} height={'358px'} src={shieldImage} alt="..."/>
          </div> 
          <div className={`col-md-6 col-sm-12 ${windowWidth<768?"d-flex flex-column justify-content-center align-items-center":""}`}>
            <h3 style={{fontSize:'36px',fontWeight:'bold',textAlign:windowWidth>768?'left':'center'}}>Powered by</h3>
            <h2 className="font-weight-bold" style={{fontSize:windowWidth>1024?'120px':'80px',textAlign:windowWidth>768?'left':'center'}}>JRN</h2>
            <p className="font-weight-bold" style={{fontSize:windowWidth<1024?'12px':'18px',margin:'24px 0px 0px',textAlign:windowWidth>768?'left':'center',color:"#E3E3E3"}}>We built X Layer as a portal to connect JRN products, users, and assets to Web3, and to provide an open ecosystem that anyone can build on.</p>
            <p style={{fontSize:windowWidth<1024?'12px':'18px',margin:'12px 0px 0px',textAlign:windowWidth>768?'left':'center',color:"#E3E3E3"}}>Explore JRN Build, our Web3 developer tool, to make building and reaching JRN users easier.</p>
            <button
                className={`${
                  Styles.ItemBtn
                } btn  border-secondary font-weight-bold  ${
                  mode === "lightMode" ? "bg-light" : "bg-white text-black"
                }`}
                style={{margin:'48px 0px 30px',fontSize:'18px',width: "50%"}}
              >
                Explore JRN Build
              </button>
          </div>
         
        </div>
      </section>
    )
  }
  const renderSixthSection=()=>{
    const settings = {
      dots: false,
      infinite: false,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1,
      arrows:false,
      initialSlide: 0,
      centerMode: false, // Center mode ko false karna hai
      centerPadding: '0px' ,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
            infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            initialSlide: 2
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    };

    const renderListItem=(item,index)=>{
      return(
        <div key={index} style={{overflow:'hidden',}} className={Styles.slideContent} >
          <h3 style={{fontSize:'22px',fontWeight:'bold'}}>{item?.title}</h3>
          <p style={{padding:'4px 0px 65px',color:'#CCCCCC'}}>{item?.subTitle}</p>
          <div className="timeline" >
            <div className="timelineStep"></div>
            <div className="timelineStep"></div>
            <div className="timelineStep"></div>
            <div className="timelineStep"></div>
          </div>
          <ul className="p-0 w-75">
            {item?.list.map((elem,index)=><li  key={index} style={{fontSize:'16px',color:'#CCCCCC'}}>{elem}</li>)}
          </ul>
        </div>
      )
    }
    return (
      <section className="d-flex justify-content-center flex-column " >
        <div style={{margin:"183px 0px 0px 0px"}}>
          <div>
              <h2 className={`text-center m-0 ${Styles.roadMapTitle}`} style={{fontSize:windowWidth<1024?'36px':'48px'}}>Roadmap</h2>
          </div>
          <div className="" style={{marginTop:'60px'}}>
          <div className="slider-container" >
        <Slider {...settings}>
        {roadmapList.map((elem,index)=>renderListItem(elem,index))}
      </Slider>
    </div>
          </div>
        </div>
      </section>
    )
  }
  const renderSeventhSection=()=>{
    const data = [
      {
        name: 'Page A',
        uv: 4000,
        pv: 2400,
        amt: 2400,
      },
      {
        name: 'Page B',
        uv: 3000,
        pv: 1398,
        amt: 2210,
      },
      {
        name: 'Page C',
        uv: 2000,
        pv: 9800,
        amt: 2290,
      },
      {
        name: 'Page D',
        uv: 2780,
        pv: 3908,
        amt: 2000,
      },
      {
        name: 'Page E',
        uv: 1890,
        pv: 4800,
        amt: 2181,
      },
      {
        name: 'Page F',
        uv: 2390,
        pv: 3800,
        amt: 2500,
      },
      {
        name: 'Page G',
        uv: 3490,
        pv: 4300,
        amt: 2100,
      },
    ];
    return (
      <section style={{margin:windowWidth>768?'260px 0px 0px':'70px 0px 0px'}} >
        <div className="mb-4">
          <h2 className="text-center font-weight-bold m-0" style={{fontSize:windowWidth<768?'36px':'52px'}}>Enhanced Security</h2>
        </div>
        <div className="row">
          <div className={`${Styles.cardItem} col-11  col-lg mx-auto`}  style={{backgroundColor:mode==="lightMode"?"":"#121212"}} onMouseEnter={()=>{setmapVisible(true)}} onMouseLeave={()=>{setmapVisible(false)}}>
             <h3 className="font-weight-bold" style={{fontSize:'28px'}}>OKB token</h3>
             <p className="mt-2" style={{lineHeight:'24px',fontSize:'16px',color:'#909090'}}>Functions as the utility token of the JRN    ecosystem,   the native token on X Layer, and can be used to pay transaction fees</p>
             <div style={{  padding:'0px 20px 0px 0px',height:'200px',paddingTop:!mapVisible&&'120px'}}  >
              {mapVisible?
                <ResponsiveContainer width="90%" height={200}>
                  <AreaChart width={200} height={60} data={data} margin={{top: 5,right: 0,left: 0,bottom: 5}}>
                     <Area type="monotone" dataKey="amt" fill="url(#colorUv)" stroke="white" strokeWidth={1}  />
                     <Curve type="linear" dataKey="amt" stroke="white" strokeWidth={2} dot={{ stroke: 'white', strokeWidth: 1, r: 4 }}/>
                     <defs>
                      <linearGradient id="colorUv" x1="0" y1="0" x2="1" y2="0">
                       <stop offset="5%" stopColor="white" stopOpacity={0.1} />
                       <stop offset="95%" stopColor="white " stopOpacity={0.5} />
                     </linearGradient>
                    </defs>
                  </AreaChart>
                </ResponsiveContainer>:null}
             <button
                className={`${Styles.cardBtn} btn ml-4   border-secondary font-weight-bold  ${
                  mode === "lightMode" ? "bg-light" : "bg-white text-black"
                }`}
                style={{fontSize:'18px',position:mapVisible&&'relative'}}
              >
               Get OKB
            </button>
             </div>
          </div>
            <div className={`${Styles.cardItem} col-11 col-lg mx-auto  ${windowWidth<1024?"my-4":"ml-4"}`} onMouseEnter={()=>{setimageVisible(true)}} onMouseLeave={()=>{setimageVisible(false)}}  style={{backgroundColor:mode==="lightMode"?"":"#121212"}}>
        <       h3 className="font-weight-bold" style={{fontSize:'28px'}}>OKLink data platform</h3>
                <p className="mt-2" style={{lineHeight:'24px',fontSize:'16px',color:'#909090'}}>Analyze on-chain data and audit code panoramically on X Layer</p>
                <div className="d-flex justify-content-between" style={{height:windowWidth<1024&&'200px'}}>
                  <div><button className={`btn`} style={{fontWeight:'bold',position:windowWidth>768&&'absolute',bottom:'1rem',borderRadius:'36px',padding:'15px 30px',marginTop:windowWidth<1024&&'120px'}}> Get analysis and audit</button></div>
                  <div> {imageVisible&&<img className="img-responsive" width={'170px'} height={"170px"} src={Shield2Image} alt="..."/>}</div>
                </div>
            </div>
        </div>
      </section>
    )
  }
  const renderEightSection=()=>{
    return (
      <section style={{marginTop:'180px'}}>
        <div className="row mx-auto">
          <div className={`col-12 col-md-6 p-5  m-2 ${Styles.cardItem}`} style={{backgroundColor:mode==="lightMode"?"":"#121212"}}>
          <h3 className="font-weight-bold" style={{fontSize:'28px'}}>Become a part of our community</h3>
          <p className="mt-2" style={{lineHeight:'24px',fontSize:'16px',color:'#909090'}}>Join our ever-growing community of developers and end-users</p> 
          <div className="mt-5 pt-5 d-flex justify-content-between">
              {iconList.map((elem,index)=><div key={index} className={`${Styles.iconItem}`}>{elem?.icon}</div>)}
          </div>
          </div>
          <div className={`col-12 col-md-5 ${windowWidth>768&&'ml-4'} p-5 m-2 ${Styles.cardItem}`} style={{backgroundColor:mode==="lightMode"?"":"#121212"}}>
          <h3 className="font-weight-bold" style={{fontSize:'28px'}}>X Layer branding kit</h3>
          <p className="mt-2" style={{lineHeight:'24px',fontSize:'16px',color:'#909090'}}>Get X Layer official logos and branding guides</p>
          <button style={{padding:' 20px 48px',position:windowWidth>768&&'absolute',bottom:'3rem',color:'#FAFAFA',backgroundColor:'initial',border:'1px solid #404040',borderRadius:'60px'}}>Download branding kit</button>
          </div>
        </div>
        <div className="row subscribeCard mx-2" style={{padding:'36px 24px',marginTop:'16px'}}>
            <div className="col d-flex justify-content-between  ">
              <div>
              <h3 className="font-weight-bold" style={{fontSize:'28px'}}>Get the latest updates</h3>
              <p className="mt-2" style={{lineHeight:'24px',fontSize:'16px',color:'#909090'}}>Be the first to know about the latest updates and launches on X Layer</p>
              </div>
              <div>
              <button style={{padding:' 17px 24px',color:'#FAFAFA',backgroundColor:'initial',border:'1px solid #404040',borderRadius:'60px'}}>Subscribe now</button>
              </div>
            </div>
        </div>
      </section>
    )
  }
  const renderLastSection=()=>{
    return (
      <section style={{padding:'180px 0px'}}>
          <div  className="d-flex justify-content-center align-items-center flex-column">
            <h2 className={`${Styles.roadMapTitle} text-center`} style={{fontSize:windowWidth<768?'28px':'44px'}}>Join us and #BUIDL on #X Layer</h2>
            <button className={`${mode==="lightMode"?"bg-transparent text-dark":"bg-white text-black"}`} style={{marginTop:'36px',borderRadius:'36px',padding:"14px 24px"}}>Start building </button>
          </div>
      </section>
    )
  }
  useEffect(() => {
    const handleResize = () => {
      setwindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);

    // Cleanup function to remove the event listener when component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  // useEffect(() => {
  //   icons.forEach((icon, index) => {
  //     setTimeout(() => {
  //       setDisplayedIcons((prev) => [...prev, icon]);
  //     }, index * 20); // Adjust the delay as needed
  //   });
  // }, [icons]);

  return (
    <section  className={`${
      mode === "lightMode" ? "bg-light text-dark" : "bg-black text-white"
    }`}>
    <section
      style={{
        minHeight: "100vh",
        padding: windowWidth > 1024 ? "0px 150px" : "0px 10px",
      }}
    >
      {renderFirstSection()}
      {renderSecondSection()}
      {renderThirdSection()}
      {renderFourthSection()}
      {renderFifth()}
      {renderSixthSection()} 
      {renderSeventhSection()} 
      {renderEightSection()} 
      {renderLastSection()} 
    </section>
    </section>

  );
};

export default Xplayer;
