import React, { useState } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component';
import { toast } from 'react-toastify';
import dummy from '../../assets/dummy.jpg'
import Styles from './EventList.module.css'
import { useNavigate } from 'react-router-dom';
import global_image from '../../utils/utility/image';

const EventListItem = ({dataList,mode}) => {
const [hasmore,sethasmore]=useState(true);
const [currentpage,setcurrentpage]=useState(1);
const navigate=useNavigate();
const customCard=(item,index)=>{
    return (
      <div onClick={()=>{navigate(`/eventlist/${item?.id}`)}} className={`col-12 col-sm-6 col-md-3 ${Styles.listItem} ${mode === 'lightMode' ? "bg-light text-dark" : 'bg-black text-white'}`} >
    <div className={`my-2 ${Styles.eventListItem}`}>
      <div className="card border-0" style={{ width: 'auto' }}>
        <img className={`card-img-top ${Styles.eventListItem_img}`} loading='lazy' src={dummy} alt="Card image cap" />
        <div className="card-body px-1 pt-1">
          <h5 className={`card-title ${Styles.eventListItem_title}`}>Card title</h5>
          <div className='d-flex align-items-center'>
            <span style={{ color: '#6E6E6E' }} className='font-12 mr-2'>Price</span>
            <span className='font-weight-bold text-success font-16'>Free</span>
          </div>
          <div className={`${Styles.eventListItem_details}`}>
            <div className='d-flex align-items-center justify-content-between my-1'>
              <span style={{ color: '#6E6E6E' }} className='font-12 mr-2'>Highest floor price</span>
              <span className='font-weight-bold font-14'>--SOL</span>
            </div>
            <div className='d-flex align-items-center justify-content-between my-1'>
              <span style={{ color: '#6E6E6E' }} className='font-12 mr-2'>Initial price</span>
              <span className='font-weight-bold font-14'>5 SOL</span>
            </div>
          </div>
          <div className='d-flex justify-content-between align-items-center'>
            <span className='font-12'>04d 23g 20mn</span>
            <span className='border border-secondary rounded px-2 font-12 text-center'>50K</span>
          </div>
        </div>
      </div>
    <div className={`${Styles.overlay_container} `} >
      <img src={global_image.subscriptionImg} alt='...' className='w-50'/>
    </div>
    </div>
  </div> 
    )
}
  return (
    <InfiniteScroll
    dataLength={dataList===undefined?[].length:dataList.length}
    next={() => {
     if(dataList!==undefined){
         if (dataList.length % 10 === 0) {
           setcurrentpage(currentpage + 1);
         } else {
           toast.warn("There is no more Category.");
           sethasmore(false);
         }
     }
    }}
    hasMore={hasmore}
    style={{ overflowX: "hidden" }}
    className=""
  >
    <div className="row">
      {dataList.map((elem, index) => customCard(elem, index))}
    </div>
  </InfiniteScroll>
  )
}

export default EventListItem
