import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { getAnalytics } from "firebase/analytics";
import 'firebase/performance'
import { getPerformance } from 'firebase/performance';
import * as firebase from 'firebase/app';
import { REACT_APP_DATABASEURL, REACT_APP_apiKey, REACT_APP_appId, REACT_APP_authDomain, REACT_APP_measurementId, REACT_APP_messagingSenderId, REACT_APP_projectId, REACT_APP_storageBucket } from './config/API/Api';

const firebaseConfig = {
  apiKey: REACT_APP_apiKey,
  authDomain:REACT_APP_authDomain,
  databaseURL: REACT_APP_DATABASEURL,
  projectId: REACT_APP_projectId,
  storageBucket:REACT_APP_storageBucket,
  messagingSenderId: REACT_APP_messagingSenderId,
  appId: REACT_APP_appId,
  measurementId:REACT_APP_measurementId,
};
const firebaseconnection=firebase.initializeApp(firebaseConfig);
getAnalytics(firebaseconnection);
export const sendPerfomance=getPerformance(firebaseconnection);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);

reportWebVitals();
