import React, {useState} from "react";
import Styles from "./cryptoPedia.module.css";
import { useDispatch } from "react-redux";
import { FaRegArrowAltCircleRight } from "react-icons/fa";
import { FaAngleDown, FaAngleUp } from "react-icons/fa6";

const Cryptopedia = () => {
  const initialList = [
    {
      id: 1,
      label: "What’s JRN Web3 DeFi?",
      desc: "JRN Web3 DeFi is an all-in-one platform to help you make the most of decentralized finance. Use this one-stop hub to manage investments in different DeFi protocols. You can navigate liquidity pools on protocols such as AAVE, Yearn, and Pancake easily.",
      isOpen: false,
    },
    {
      id: 2,
      label: "What protocols do we support?",
      desc: "We only bring you the DeFi protocols with the best returns and safest records.",
      isOpen: false,
    },
    {
      id: 3,
      label: "What are the protocol types?",
      desc: `Stablecoins refers to the investment products of stablecoins you can invest in, such as USDT, USDC, DAI, etc.
Bonus refers to the investment products you can invest in and receive extra rewards distributed by projects on JRN Web3 DeFi.
(Please note that JRN Wallet and its affiliates (“JRN”) have no control on reward distribution. All information displayed by JRN is provided by the Third Party and not representations by JRN. Actual APY, incentives and other service terms may vary at the Third Party’s discretion.)
Single Crypto refers to the investment products you can invest in with a single token. These products are available in staking, lending, and borrowing protocols with the advantage of being free from impermanent losses.
Multiple Crypto refers to the investment products you need to invest in with multiple tokens. These products are available in liquidity pools and liquidity mining protocols. Due to price fluctuations of the underlying assets in the pools, investing in these products may incur impermanent losses, further causing losses on your assets.
Vault is a yield aggregator. After you deposit your tokens, Vault will adopt automated strategies to capture the best earnings path and yield the highest return for you.`,
      isOpen: false,
    }
  ];
  const [optionList, setoptionList] = useState(initialList);

  const dispatch = useDispatch();
  const BannerSection = () => {
    return (
      <div className={`${Styles.bannerContainer}`}>
        <div className="ml-md-5 mt-5">
          <div className="row ml-md-2 mt-md-5 mr-0">
            <div className="col col-md-6 ">
              <div>
                <h1 className={Styles.bannerTitle}>
                  JRN <span>Cryptopedia</span>
                </h1>
                <div className={Styles.bannerDesc}>
                  Explore a wide array of DApps on various blockchain networks
                  through Cryptopedia. Earn exciting rewards and incentives
                  while experiencing the latest in decentralized technology.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  const renderLiveNowSection = () => {
    const card = (item) => {
      return (
        <div className={`col-12 ${Styles.liveCardContainer} p-3`}>
          <div className="d-flex justify-content-between align-items-center my-3">
            <span className={`badge ${Styles.livecardBadge}`}>season 22</span>
            <FaRegArrowAltCircleRight color="grey" size={20} />
          </div>
          <div className="row mx-0">
            <div className="col-12 col-xl-5 pl-0">
              <div className="media mt-4">
                <img
                  className={Styles.cardImage}
                  src="https://www.JRN.com/cdn/nft/42c19251-eda1-4bf4-af29-9095692b88d9.jpg?x-oss-process=image/format,webp/resize,w_300/ignore-error,1"
                  alt="Generic placeholder image"
                />
                <div className="media-body align-self-center">
                  <h5 className="mt-0">Media heading</h5>
                  <div className={Styles.cardDesc}>
                    Cras sit amet nibh libero, in gravida nulla.
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 offset-xl-1 col-xl-6 bg-black rounded-14">
              <div className={Styles.innerCardContainer}>
                <div className="d-flex justify-content-center align-items-center flex-column">
                  <p className="font-14 color909090">Dapps</p>
                  <p className="text-center font-weight-bold font-14">9</p>
                </div>
                <div className="d-flex justify-content-center align-items-center flex-column">
                  <p className="font-14 color909090">Participants</p>
                  <p className="text-center font-weight-bold font-14">81,580</p>
                </div>
                <div className="d-flex justify-content-center align-items-center flex-column">
                  <p className="font-14 color909090">Reward</p>
                  <p className="text-center font-weight-bold font-14 colorBCFF2F">
                    Token % in games assets
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    };

    return (
      <div className={Styles.Livecontainer}>
        <div className="font-32 font-weight-bold my-4">Live Now</div>
        <div className="row mx-0">{[{}, {}, {}].map((o) => card(o))}</div>
      </div>
    );
  };
  const renderEndedSection = () => {
    const Card = ({ item }) => {
      return (
        <div className={`col col-xl-4 col-md-6 col-12 p-0`}>
          <div className={`${Styles.endCardContainer} `}>
            <div className={Styles.EndedtitleCardContainer}>
              {["Season 15", "High reward"].map((o) => (
                <div className="badge mx-2">{o}</div>
              ))}
            </div>
            <div>
              <div className="media">
                <img
                  className="mr-3 rounded rounded-circle border border-white"
                  src="https://www.JRN.com/cdn/nft/files/0ce98dd5-dd9a-425b-a1bd-4d402e0de60a.webp?x-oss-process=image/format,webp/resize,w_300/ignore-error,1"
                  alt="Generic placeholder image"
                  width={'60px'}
                  height={'60px'}
                />
                <div className="media-body ml-3">
                  <h5 className="mt-0"> ZkLink</h5>
                  <p className="color909090">Share $300K rewards</p>
                </div>
              </div>
            </div>
            <div>
              <span className="font-14">620.97K</span><span className="color909090 font-12 ml-2">participant</span>
            </div>
          </div>
        </div>
      );
    };

    return (
      <div className={Styles.EndedContainer}>
        <div className="font-32 font-weight-bold my-4">Live Now</div>
        <div className="row mx-0">
         {[1,1,1,1,1,1,1,1].map(o=><Card item={o}/>)}
        </div>
      </div>
    );
  };
  const renderFaq = () => {
    return (
      <div className={Styles.faqContainer}>
        <div className="py-5">
          <h1 className="text-center py-5 font-32 font-weight-bold">FAQ</h1>
        </div>
        <div className={`${Styles.faqinnerItem}`}>
          {optionList.map((elem, index) => (
            <div
              key={index}
              className={`${Styles.faqListitem}`}
              onClick={() => {
                const filterdata = optionList.map((item) => {
                  if (item.id === elem.id) {
                    item.isOpen = !item.isOpen;
                  }
                  return item;
                });
                console.log(filterdata);
                setoptionList(filterdata);
              }}
            >
              <div
                className="d-flex justify-content-between align-items-center"
                style={{ borderTop: "1px solid #" }}
              >
                <p>{elem.label}</p>
                {elem.isOpen ? (
                  <FaAngleDown size={20} color="#929292" />
                ) : (
                  <FaAngleUp size={20} color="#929292" />
                )}
              </div>
              {elem.isOpen ? <p>{elem.desc}</p> : null}
            </div>
          ))}
        </div>
      </div>
    );
  };

  return (
    <div className={Styles.container}>
      {BannerSection()}
      {renderLiveNowSection()}
      {renderEndedSection()}
      {renderFaq()}
    </div>
  );
};

export default Cryptopedia;
