import { Strophe, $pres, $iq, $msg } from "strophe.js";
import React from "react";
import "strophejs-plugin-muc";
import "strophejs-plugin-roster";
import "strophejs-plugin-mam";

export const getmemberlist = (roomname, userlist = [], members) => {
  const updateuserlist = [];
  if (roomname !== "") {
    for (let i = 0; i < userlist.length; i++) {
      const presentuser = members.find((obj) => {
        return obj.user === userlist[i].user + "@localhost";
      });
      if (!presentuser) {
        updateuserlist.push(userlist[i]);
      }
    }
  } else {
    // alert('roomname is invalid')
  }
  return updateuserlist;
};

// getting member list
export const getGroupmemberlist = (connection, roomname) => {
  const memberingroup = [];
  if (roomname !== "") {
    const iq = $iq({
      from: Strophe.getBareJidFromJid(connection.jid),
      to: roomname,
      type: "get",
    })
      .c("query", { xmlns: "http://jabber.org/protocol/muc#admin" })
      .c("item", { affiliation: "member" })
      .tree();
    connection.sendIQ(
      iq,
      (response) => {
        console.log("response,member", response);
        const users = response.querySelectorAll("item");
        console.log("users is", users);
        if (users.length > 0) {
          for (let i = 0; i < users.length; i++) {
            memberingroup.push({
              user: users[i].getAttribute("jid"),
              type: "member",
            });
          }
        }
      },
      (err) => {
        console.log(err);
      }
    );

    var iq2 = $iq({ type: "get", to: roomname, from: connection.jid })
      .c("query", { xmlns: "http://jabber.org/protocol/muc#admin" })
      .c("item", { affiliation: "owner" });

    connection.sendIQ(
      iq2,
      (response) => {
        console.log("response,owner", response);
        const users = response.querySelectorAll("item");
        console.log("users is", users);
        if (users.length > 0) {
          for (let i = 0; i < users.length; i++) {
            memberingroup.push({
              user: users[i].getAttribute("jid"),
              type: "owner",
            });
          }
        }
      },
      (err) => {
        console.log(err);
      }
    );
    var iq3 = $iq({ type: "get", to: roomname, from: connection.jid })
      .c("query", { xmlns: "http://jabber.org/protocol/muc#admin" })
      .c("item", { affiliation: "admin" });
    connection.sendIQ(
      iq3,
      (response) => {
        console.log("response,admin", response);
        const users = response.querySelectorAll("item");
        console.log("users is", users);
        if (users.length > 0) {
          for (let i = 0; i < users.length; i++) {
            memberingroup.push({
              user: users[i].getAttribute("jid"),
              type: "admin",
            });
          }
        }
      },
      (err) => {
        console.log(err);
      }
    );

    var iq4 = $iq({ type: "get", to: roomname, from: connection.jid })
      .c("query", { xmlns: "http://jabber.org/protocol/muc#admin" })
      .c("item", { affiliation: "none" });
    connection.sendIQ(
      iq4,
      (response) => {
        console.log("response,admin", response);
        const users = response.querySelectorAll("item");
        console.log("none is", users);
        if (users.length > 0) {
          for (let i = 0; i < users.length; i++) {
            memberingroup.push({
              user: users[i].getAttribute("jid"),
              type: "none",
            });
          }
        }
      },
      (err) => {
        console.log(err);
      }
    );
  } else {
  }
  return memberingroup;
};



export const inviteMember = (connection, userjid, roomjid) => {
  console.log("roomjid", roomjid);
  console.log("userjid", userjid);
  const role = "participant";
  const affiliation = "member";
  const sender = Strophe.getBareJidFromJid(connection.jid);

  connection.muc.invite(roomjid, userjid, "want to join your chat");
};
//public room
export const CreateChatRoom = (connection, roomName, loggedUser) => {
  //public group creation
  console.log("public group created");
  const iq = $iq({
    to: `${roomName.replace(/\s/g, "_")}@conference.localhost`,
    type: "set",
    from: loggedUser,
  }).c("query", {
    xmlns: "http://jabber.org/protocol/muc#owner",
  });

  iq.c("x", {
    xmlns: "jabber:x:data",
    type: "submit",
  });

  iq.c("field", { var: "FORM_TYPE", type: "submit" })
    .c("value")
    .t("http://jabber.org/protocol/muc#roomconfig")
    .up()
    .up();

  iq.c("field", { var: "muc#roomconfig_roomname" })
    .c("value")
    .t(roomName)
    .up()
    .up()
    .c("field", {
      var: "muc#roomconfig_persistentroom",
    })
    .c("value")
    .t("1")
    .up()
    .up();

  iq.c("field", {
    var: "muc#roomconfig_publicroom",
  })
    .c("value")
    .t("1")
    .up()
    .up();

  iq.c("field", {
    var: "muc#roomconfig_membersonly",
  })
    .c("value")
    .t("0")
    .up()
    .up();

  iq.c("field", {
    var: "muc#roomconfig_moderatedroom",
  })
    .c("value")
    .t("0")
    .up()
    .up();

  connection.sendIQ(
    iq.tree(),
    function (res) {
      console.log(res, "unan hu");
      return res;
    },
    function (err) {
      return err;
    }
  );
};
//private room
export const createPrivatechatRoom = (connection, roomName, loggedUser) => {
  console.log("privategroup");
  const iq = $iq({
    to: `${roomName.replace(/\s/g, "_")}@conference.localhost`,
    type: "set",
    from: loggedUser,
  }).c("query", {
    xmlns: "http://jabber.org/protocol/muc#owner",
  });

  iq.c("x", {
    xmlns: "jabber:x:data",
    type: "submit",
  });

  iq.c("field", { var: "FORM_TYPE", type: "hidden" })
    .c("value")
    .t("http://jabber.org/protocol/muc#roomconfig")
    .up()
    .up();

  iq.c("field", { var: "muc#roomconfig_roomname" })
    .c("value")
    .t(roomName)
    .up()
    .up();

  iq.c("field", {
    var: "muc#roomconfig_persistentroom",
  })
    .c("value")
    .t("1")
    .up()
    .up();

  iq.c("field", {
    var: "muc#roomconfig_allowinvites",
  })
    .c("value")
    .t("1")
    .up()
    .up();

  iq.c("field", {
    var: "muc#roomconfig_publicroom",
  })
    .c("value")
    .t("0")
    .up()
    .up();

  iq.c("field", {
    var: "muc#roomconfig_whois",
  })
    .c("value")
    .t("moderators")
    .up()
    .c("option", {
      label: "Moderators Only",
    })
    .c("value")
    .t("moderators")
    .up()
    .c("option", {
      label: "Anyone",
    })
    .c("value")
    .t("anyone")
    .up()
    .up();

  iq.c("field", {
    var: "muc#roomconfig_membersonly",
  })
    .c("value")
    .t("1")
    .up()
    .up();

  connection.sendIQ(
    iq.tree(),
    (response) => {
      console.log(response);
    },
    (err) => {
      console.log(err, "this is error");
    }
  );
};

export const acceptInvitation = (elem, connection) => {
  const roomjid = elem.user;
  const joinuser = Strophe.getBareJidFromJid(connection.jid);
  const affiliation = "member";
  const role = "participant";

  const iq = $pres({
    from: connection.jid,
    to: roomjid + "/" + joinuser,
  })
    .c("x", {
      xmlns: "http://jabber.org/protocol/muc#user",
    })
    .c("item", { affiliation: "member", role: "participant" });

  connection.send(iq);

  if (connection !== null && roomjid !== null && joinuser !== null) {
    console.log("we are here");
    connection.muc.join(roomjid, joinuser, function (msg) {
      console.log("msg,is", msg);
      if (msg.getAttribute("type") === "error") {
        console.log("Error joining the MUC room: " + msg);
      } else {
        // Construct the query
        console.log("yahan tak toh aaye hoge");
        var query = $iq({
          from: connection.jid,
          to: roomjid,
          type: "set",
        })
          .c("query", { xmlns: "http://jabber.org/protocol/muc#admin" })
          .c("item", {
            nick: joinuser, // User JID jisko affiliation update karna hai
            affiliation: affiliation,
            role: "participant",
          });

        // Send the query to the MUC room
        connection.sendIQ(
          query,
          function (response) {
            console.log("Room joined " + response);
            if (response.getAttribute("type") === "result") {
              console.log("Affiliation updated successfully.", response);
            } else {
              console.error("Error updating affiliation: " + response);
            }
          },
          (err) => {
            console.log(err, "this is err");
          }
        );
      }
    });
  }
};

export const declineInvitation = (elem, connection) => {
  if (connection) {
    console.log(elem);
    const msg = $msg({
      from: Strophe.getBareJidFromJid(connection.jid),
      to: elem.roomjid,
    })
      .c("x", { xmlns: "http://jabber.org/protocol/muc#user" })
      .c("decline", { to: elem.user })
      .c("reason")
      .t("Sorry, I am too busy right now");
    console.log(msg, "this is message");
    connection.send(msg);
  }
};
