import React, {  useCallback, useEffect,useRef } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import "./Collection.css";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { FaXing, FaMask, FaRegTimesCircle, FaListUl } from "react-icons/fa";
import { LuGrip } from "react-icons/lu";
import { IoIosArrowRoundBack } from "react-icons/io";
import { BsStrava } from "react-icons/bs";
import { TfiWorld } from "react-icons/tfi";
import { CiFilter, CiSearch } from "react-icons/ci";
import { AiOutlineStar } from "react-icons/ai";
import { trace } from "firebase/performance";
import { sendPerfomance } from "../..";
import AnalyticsScreen from "./AnalyticsScreen";
import ActivityScreen from "./ActivityScreen";
import { Loader2 } from "../../commonComponent/Loader";
import CustomPopup from "../../commonComponent/CustomPopup";
import "react-accessible-accordion/dist/fancy-example.css";
import FilterAccordian from "./FilterAccordian";
import { tracer } from "../../tracing";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import CustomSelect from "../../commonComponent/CustomSelect";
import InfiniteScrollinCollectionScreen from "./InfiniteScrollinCollectionScreen";
import { useLocation, useParams } from "react-router-dom";
import { REACT_APP_FETCH_PARTICULAR_COLLECTION_BASEURL } from "../../config/API/Api";
import ReactGA from 'react-ga4';
import Styles from './collection.module.css'
import { thousandsConverter } from "../../utils/utility/Utility";
import _ from 'lodash';

export default function Collection(props) {
  const mode = useSelector((state) => state.themeType.themeType);

  const token = localStorage.getItem("accessToken");
  const {state}=useLocation();
  const { id } = useParams();
  console.log('this si my contrct id',state?.contract_id)
  const userData =state === undefined||state===null? {}: state?.userData;
  const [collectionData, setcollectionData] = useState([]);
  const [isnextLoading, setisnextLoading] = useState(true);
  const [currentPage, setcurrentPage] = useState(1);
  const [currentTabIndex, setcurrentTabIndex] = useState(1);
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [removeFilterdata, setremoveFilterdata] = useState([]);
  const [sortByfilter, setsortbyFilter] = useState("");
  const [windowWidth, setwindowWidth] = useState(window.innerWidth);
  const [filterbtn, setfilterbtn] = useState(false);
  const [isloading, setisloading] = useState(false);
  const [iconColor, seticoncolor] = useState("black");
  const [stopfetching, setstopfetching] = useState(false);
  const [searchInput,setsearchInput]=useState('')
  const [showModal, setshowmodal] = useState(false);
  const desc ="The Bored Ape Yacht Club is a collection of 10,000 unique Bored Ape NFTs— unique digital collectibles living on the Ethereum blockchain. Your Bored Ape doubles as your Yacht Club membership card, and grants access to members-only benefits, the first of which is access to THE BATHROOM, a collaborative graffiti board. Future areas and perks can be unlocked by the community through roadmap activation. Visit www.BoredApeYachtClub.com for more details.";
  const [showdescriptionPopup, setshowdescriptionPopup] = useState(false);
  const cancelTokenSource = useRef(null);

  //filterState
  const [refreshfilter,setrefreshFilter]=useState(false);
  const handlerefreshFilter=()=>setrefreshFilter(!refreshfilter);
  const [likeFilterValue,setlikeFilterValue]=useState({min:'',max:''});
  const [dislikeFilterValue,setdislikeFilterValue]=useState({min:'',max:''});
  const [priceFilterValue,setpriceFilterValue]=useState({min:"",max:""});
  const [ispropertiesLoading,setispropertiesLoading]=useState(false);
  const [propertiesFilterData, setpropertiesFilterData] = useState([]);
  const [selectedFilter, setselectedFilter] = useState([]);

 const updateFilterProperties=(val)=>{setpropertiesFilterData(val)}


  const handleSelectedFilter=(val)=>{setisnextLoading(true);setselectedFilter(val);};
  const handledisLikeFilter=(val)=>{
    setcollectionData([]);
    setdislikeFilterValue(val);
    setcurrentPage(1);
  }
  const handleLikeFilter=(val)=>{
    setcollectionData([]);
    setlikeFilterValue(val);
    setcurrentPage(1);
  }
  const handlePriceFilterValue=(val)=>{
    setcollectionData([]);
    setpriceFilterValue(val);
    setcurrentPage(1);
  }

  const handleChangeselectedFilter = useCallback((d) => {
    setSelectedFilters(d);
  }, []);


  const debouncedFetchData = _.debounce(async (params, header) => {
    if (cancelTokenSource.current) {
      cancelTokenSource.current.cancel("Previous API request canceled");
    }
    cancelTokenSource.current = axios.CancelToken.source();
    const url = `${REACT_APP_FETCH_PARTICULAR_COLLECTION_BASEURL}/collection/filter`;
    try {
      const response = await axios.post(url, params, {
        ...header,
        cancelToken: cancelTokenSource.current.token,
      });
      if (response.status === 200) {
        setcollectionData((prevData) => (params.page === "1" ? response.data : [...prevData, ...response.data]));
        if (response.data.length === 0) {
        }
        setisloading(false)
        setisnextLoading(false);
      }
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log("Request canceled", error.message);
      } else {
        console.error("API Error", error);
      }
    }
  }, 300); // 300ms debounce delay

  const collectionRequestApi = async (isFilter = false, isPriceFilter = false) => {
    let params = {
      collection_id: id,
      page: String(currentPage),
      size: '10',
      sort_order: 'desc',
    };
    if(state!==undefined&&state!==null){
      params.blockchain=state?.blockchain;
    }else{
      params.blockchain=""
    }
    if (sortByfilter) {
      params.sort_by = sortByfilter;
    }
    
    if (selectedFilters.length > 0) {
      params.filters = handlefilterData(selectedFilters);
    }

    if (priceFilterValue?.min!=='' && priceFilterValue?.max!=="") {
      params.price = [priceFilterValue?.min,priceFilterValue?.max];
    }

    if(likeFilterValue.min!=='' && likeFilterValue.max!==''){
      params.likes=[likeFilterValue.min,likeFilterValue.max];
    }

    if(dislikeFilterValue.min!=='' && dislikeFilterValue.max!==''){
      params.dislikes=[dislikeFilterValue?.min,dislikeFilterValue?.max];
    }

    if (searchInput !== '') {
      params.search_field = searchInput;
    }

    if (isFilter) {
      params.page = '1';
      setcurrentPage(1);
    }

    const header = token ? { headers: { Authorization: token } } : null;

    debouncedFetchData(params, header);
  };

  const socialMediaIconList = [
    <BsStrava
      size={35}
      className="mt-2 ml-2 p-2 border rounded rounded-circle socialMediaIcons"
    />,
    <TfiWorld
      size={35}
      className="mt-2 ml-2 p-2 border rounded rounded-circle socialMediaIcons"
    />,
    <FaXing
      size={35}
      className="mt-2 ml-2 p-2 border rounded rounded-circle socialMediaIcons"
    />,
    <FaMask
      size={35}
      className="mt-2 ml-2 p-2 border rounded rounded-circle socialMediaIcons"
    />,
    <AiOutlineStar
      size={35}
      className="mt-2 ml-2 p-2 border rounded rounded-circle socialMediaIcons"
      color={iconColor}
    />,
  ];
  const [headerList,setheaderList]=useState([]);
  const handlefilterData = (arr) => {
    const filterdata = arr.filter((item) => {
      return item.values.length > 0;
    });
    return filterdata;
  };
  //Header list api for fetch colllection details
  const headerListApi=async(url,header)=>{
    let params={};
    if(userData?.blockchain==='null'||userData.blockchain===undefined||userData?.blockchain===''){
      toast.warn("blockchain not found");
      return 
    }else{
      url=url+`?blockchain=${userData?.blockchain}`
    }
    try {
      await axios.get(url,params,header).then((res)=>{
        if(res.status===200){
          setheaderList(res.data);
        }
      }).catch((err)=>{console.log(err)})
    } catch (error) {
      console.log('error', error)
    }
  }

  //properties filter api
  
  const collectionPropertiesApi = async (url) => {
    if(state?.blockchain===undefined||state?.blockchain===null||state?.blockchain===''){
      toast.warn("blockchain not found");
      return
    }
    const header={params:{
      blockchain:state?.blockchain
    }}
    try {
      const t = trace(sendPerfomance, "Collection_Properties_filter_Url");
      const span=tracer.startSpan('Collection_Properties_filter_api');
      t.start();
      setispropertiesLoading(true);
      await axios
        .get(url, header)
        .then((res) => {
          span.setAttribute('method','GET');
          span.addEvent('api_response',{status:res.status,data:res.data,url})
          if (res.status === 200) {
            res.data?.traits.map((elem) => {
              elem.trait_values.forEach((obj, index) => {
                obj.isChecked = false;
              });
            });
            console.log(
              "collection properties url data is=========>>>>>>>",
              res.data?.traits
            );
            setpropertiesFilterData(res.data?.traits);
          }
        })
        .catch((err) => {
          console.log(err);
          span.setStatus({code:'ERROR' , message : err?.message});
        })
        .finally(() => {
          t.stop();
          span.end();
          setispropertiesLoading(false);
        });
    } catch (error) {
      console.log("error", error);
    }
  };

  const rendersearchFilter = () => {
    return (
      <div
        className={`okui-input-box rounded d-flex my-1`}
        style={{
          border: mode === "lightMode" ? "" : "1px solid white",
          width: windowWidth < 1024 ? "100%":'280px',
        }}
      >
        <span className="okui-input-prefix p-1 px-2">
          <CiSearch size={18} color="grey" />
        </span>
        <input
          autoComplete="off"
          name="okd_1702210317205"
          placeholder="Search item ID or traits"
          className="okui-input-input"
          style={{
            textOverflow: "ellipsis",
            color: mode === "lightMode" ? "black" : "white",
          }}
          autoCapitalize="off"
          onChange={(e)=>setsearchInput(e.target.value)}
          value={searchInput}
        />
      </div>
    );
  };

  const rendergridandtablarViewIcon = () => {
    return (
      <div
        className="d-flex mt-1 mr-4"
        style={{
          marginLeft: "12px",
          height: "42px",
          border: "1px solid #ebebeb",
          borderRadius: "8px",
          flexShrink: "0",
          width: "64px",
        }}
      >
        <span className="p-2">
          <FaListUl size={18} />
        </span>
        <span className="p-2">
          <LuGrip size={18} />
        </span>
      </div>
    );
  };

  const secondView = () => {
    const optionfilter = [
      {
        id: 5,
        label: "Select the sort Filter",
        value: "",
        isDisable: true,
      },
      { id: 1, label: "Rank by like", value: "like" },
      { id: 2, label: "Rank by dislike", value: "dislike" },
      { id: 3, label: "Rank by price", value: "price" },
      { id: 4, label: "Rank by rarity rank", value: "rarity_rank" },
    ];
    const updatesortingFilter = (val) => {
      setcollectionData([]);
      setisnextLoading(true)
      setsortbyFilter(val);
    };
    return (
      <>
        <div className="col col-lg-6 col-md-12 col-6  order-1  d-flex  ">
          <button
            className={`ml-2 mr-3  p-2 font-16 filterItem bg-transparent fontweight-500 px-3`}
            onClick={() => {
              ReactGA.event({
                category: 'User Interaction',
                action: 'Pressed on a filter button in collection screen',
                label: 'Filter Button',
                value: 10
              })
              if (windowWidth <= 992) {
                setshowmodal(true);
                setfilterbtn(false);
              } else {
                setfilterbtn(!filterbtn);
              }
            }}
            style={{ color: mode === "lightMode" ? "black" : "white" }}
          >
            {filterbtn ? (
              <IoIosArrowRoundBack
                size={20}
                className={` ${
                  mode === "lightMode" ? "text-dark" : "text-white"
                }`}
                style={{ position: "relative", top: "2px" }}
              />
            ) : (
              <CiFilter
                size={18}
                className={` ${
                  mode === "lightMode" ? "text-dark" : "text-white"
                }`}
                style={{ position: "relative", top: "2px" }}
              />
            )}
            {windowWidth > 992 && <span className="ml-2">Filters</span>}
          </button>
          <div className="mx-2 filterItem d-flex px-2 py-2 justify-content-between align-self-center">
            <div className="animated-div"></div>
            <span className="font-14 font-weight-bold ml-2">Live</span>
          </div>
        </div>
        <div className={`col-lg-3 col-md-6 col-12 order-md-2 order-3 ${mode==='lightMode'?"bg":"bg-black"}`}>{rendersearchFilter()}</div>
        <div className={`col-lg-3 col-md-6 col my-1 order-md-3 order-2 d-flex align-items-center justify-content-between pr-0 ${Styles.sortDropdown}`}>
             <CustomSelect
              options={optionfilter}
              updatevalue={updatesortingFilter}
              mode={mode}
              minwidth={windowWidth < 1024 ? "287px" : "180px"}
              className={windowWidth<1024&&'w-100 mr-2'}
            />
            {windowWidth > 1024 && rendergridandtablarViewIcon()}
        </div>
      </>
    );
  };
  const handlefetchMoreData = () => {
    if (collectionData.length % 10 === 0) {
      setcurrentPage(currentPage + 1);
    }
  };
  const thirdView = () => {
    const handleRemoveFilter = (elem, i) => {
      setcollectionData([]);
      selectedFilters.map((obj) => {
        if (obj.key === i.key) {
          obj.values = obj.values.filter((item) => {
            return item !== elem;
          });
        }
      });
      const val = selectedFilters.filter((elem) => {
        return elem.values.length > 0;
      });
      setremoveFilterdata({ key: i.key, values: elem });
      setSelectedFilters(val);
      setisnextLoading(true);
    };
    const clearAssets = () => {
      setcollectionData([]);
    };
    const renderLeftFilters = () => {
      return (
        <div
          style={{
            maxHeight: "90vh",
            overflow: "auto",
            position: "sticky",
            top: "200px",
            border: mode === "lightMode" && "1px solid #ebebeb",
            borderRadius: "24px",
          }}
          className="scrollbar"
        >
          <FilterAccordian
            mode={mode}
            token={token}
            collectionId={id}
            handleChangeselectedFilter={handleChangeselectedFilter}
            removeFilterdata={removeFilterdata}
            clearAssets={clearAssets}
            handleLikeFilter={handleLikeFilter}
            handledisLikeFilter={handledisLikeFilter}
            initialpropertiesFilterData={propertiesFilterData}
            handleSelectedFilter={handleSelectedFilter}
            selectedFilter={selectedFilter}
            likeFilterValue={likeFilterValue}
            dislikeFilterValue={dislikeFilterValue}
            handlerefreshFilter={handlerefreshFilter}
            priceFilterValue={priceFilterValue}
            handlePriceFilterValue={handlePriceFilterValue}
            updateFilterProperties={updateFilterProperties}
          />
        </div>
      );
    };
    const handleFilterPopup = () => {
      const closeBtn = (
        <button
          className={`close ${
            mode === "lightMode" ? "text-dark" : "text-white"
          }`}
          onClick={() => {
            setshowmodal(false);
          }}
          type="button"
        >
          &times;
        </button>
      );
      return (
        <Modal
          size="lg"
          isOpen={showModal}
          toggle={() => {
            setshowmodal(!showModal);
          }}
          centered={windowWidth > 768 ? true : false}
          backdrop={true}
          style={{
            position: windowWidth < 768 ? "fixed" : "",
            bottom: 0,
            margin: windowWidth <= 768 && 0,
          }}
        >
          <ModalHeader
            toggle={() => {
              setshowmodal(false);
            }}
            close={closeBtn}
            title="Filters"
            className={`font-14 font-weight-bold ${
              mode === "lightMode" ? "bg-light text-dark" : "bg-dark text-white"
            }`}
          >
            Filters
          </ModalHeader>
          <ModalBody
            className=""
            style={{
              backgroundColor: mode === "lightMode" ? "white" : "black",
              color: mode === "lightMode" ? "black" : "white",
            }}
          >
            {renderLeftFilters()}
          </ModalBody>
        </Modal>
      );
    };
    return (
      <>
        <div className="row mx-0 mt-md-0 mt-5">
          {filterbtn && windowWidth > 992 && (
            <div className="col-3 m-0" id="LeftFilters" >
              {renderLeftFilters()}
            </div>
          )}
          <div
            className={`pl-0  pr-0 ${
              windowWidth > 992 ? (filterbtn ? "col col-md-9" : "col") : "col-12"
            } `}
          >
            <div className="d-flex flex-wrap p-0">
              {selectedFilters.map((item, index) =>
                item.values.map((elem, i) => (
                  <p
                    key={`${index}-${i}`}
                    className="text-dark px-3 py-2 font-weight-bold m-1 ml-4"
                    style={{
                      backgroundColor: "rgb(235 233 233)",
                      borderRadius: "8px",
                      fontSize: "14px",
                    }}
                  >
                    {elem}{" "}
                    <span
                      className="ml-2 cursor-pointer "
                      style={{ position: "relative", top: "5px" }}
                      onClick={() => handleRemoveFilter(elem, item)}
                    >
                      <FaRegTimesCircle size={20} />
                    </span>
                  </p>
                ))
              )}
            </div>
            <InfiniteScrollinCollectionScreen
              filterbtn={filterbtn}
              collectionData={collectionData}
              windowWidth={windowWidth}
              handlefetchMoreData={handlefetchMoreData}
              isLoading={isnextLoading}
              currentPage={currentPage}
            />
            {!isnextLoading && collectionData.length === 0 ? (
              <div className="d-flex justify-content-center align-items-center">
                <h2 className="text-center">No Assets Found</h2>
              </div>
            ) : null}
          </div>
        </div>
        {handleFilterPopup()}
      </>
    );
  };

  useEffect(() => {
    collectionRequestApi(false, false);
  }, [currentPage]);

  // Second useEffect - on filter, sort, or search input change
  useEffect(() => {
    collectionRequestApi(true, false);
  }, [selectedFilters, sortByfilter, searchInput]);

  useEffect(() => {
    collectionRequestApi(true, true);
  }, [refreshfilter]);
  useEffect(()=>{
    const url = `${REACT_APP_FETCH_PARTICULAR_COLLECTION_BASEURL}/collection/detail/${state?.contract_id===undefined?id:state?.contract_id}`;
    const Propertiesurl = `${REACT_APP_FETCH_PARTICULAR_COLLECTION_BASEURL}/collection/properties/${id}`;
    if (token === undefined || token === null) {
      collectionPropertiesApi(Propertiesurl)
      headerListApi(url,null)
    } else {
      const header = { headers: { Authorization: token } };
      headerListApi(url,header)
      collectionPropertiesApi(Propertiesurl)
    }
  },[])
  useEffect(() => {
    if(propertiesFilterData!==undefined){
     if(propertiesFilterData.length>0){
       propertiesFilterData?.map((elem, index) => {
         if (elem.traitType === removeFilterdata.key) {
           elem.trait_values?.map((i) => {
             if (i.key === removeFilterdata.values) {
               i.isChecked = !i.isChecked;
             }
           });
         }
       });
       setpropertiesFilterData([...propertiesFilterData]);
      }
    }
   }, [removeFilterdata]);


  const handleDescriptionPopup = () => {
    return (
      <CustomPopup
        onclick={() => {
          setshowdescriptionPopup(false);
        }}
        size="lg"
        showpopup={showdescriptionPopup}
        center={true}
        popHeader="Collection Description"
        classBody="p-0"
        classHeader="font-weight-bold"
        body={<p className="p-3">{desc}</p>}
      />
    );
  };
  const renderFirstSection = () => {
    const populateCard=(key,value)=>{
      return (
        <div className=" m-2">
                  <div className={`${`'border-right border-muted '}`} p-2`} style={{maxHeight:'50px'}}>
                  <p className={`font-16 mb-0 text-center  inherit more-bolder ${mode==='lightMode'?"text-black":"text-white"}`}
                    style={{opacity: "1.5"}}>
                    {key==='null'||key===undefined||key===null?"--":key}
                  </p>
                  <p className="font-12  text-muted1 text-center align-self-center" style={{whiteSpace:'nowrap'}}>{value}</p>
                  </div>
                </div>)
    }
    const renderImageurl=()=>{
      if(userData?.image_url!==undefined){
        return userData?.image_url
      }else if(userData?.collection_image_url!==undefined){
        return userData?.collection_image_url
      }else if(userData?.imageUrl!==undefined){
        return userData?.imageUrl
      }else{
        return ''
      }
    }
    return (
        <div className="row mx-0 pt-4">
          <div className="col-xl-9 col-12">
            <div className="d-flex align-items-center ">
              <img
                src={renderImageurl()}
                width="50px"
                height={"50px"}
                style={{
                  boxShadow: "0 4px 10px rgba(0,0,0,.2)",
                  border: "2px solid white",
                }}
                className="rounded-circle border-white"
              />
              <div className="ml-3">
                <p
                  className="mb-0 font-18 more-bolder"
                  style={{ color: mode === "lightMode" ? "black" : "white" }}
                >
                  {userData?.name}
                  {userData?.collection_name}
                </p>
                <span
                  className={`font-12 ${
                    mode === "lightMode" ? "text-muted1" : "text-white"
                  }`}
                >
                  {userData.description !== undefined
                    ? userData.description.length > 100
                      ? userData.description.slice(0, 100) + "..."
                      : userData.description
                    : ""}
                  {userData.collection_description !== undefined
                    ? userData.collection_description.length > 100
                      ? userData.collection_description.slice(0, 100) + "..."
                      : userData.collection_description
                    : ""}
                  {userData.description !== undefined ? (
                    userData.description.length > 200 ? (
                      <span
                        className="pointer font-14 text-black"
                        onClick={() => {
                          setshowdescriptionPopup(true);
                        }}
                      >
                        More
                      </span>
                    ) : null
                  ) : null}
                </span>
              </div>
            </div>
            <div
              className="d-flex my-3 justify-content-between flex-nowrap overflow-auto scrollbar"
              style={{
                backgroundColor: mode === "lightMode" ? "#f9f9f9" : "black",
                maxWidth:'767px'
              }}>
              {populateCard(thousandsConverter(headerList[0]?.volume),'Total Volume')}
              {populateCard(thousandsConverter(headerList[0]?.floor_price),'Floor price')}
              {populateCard('null','Best Offer')}
              {populateCard('null','Owners')}
              {populateCard('null','Listed')}
              {populateCard('null','Unique owner')}
              {populateCard('null','Royality')}
              {populateCard(thousandsConverter(headerList[0]?.items),'items')}
            </div>
          </div>
          <div className="col-xl-3 col-12">
            <div className="row mx-0">
              <div className="col-xl-12 pl-0 col-md-6  ">
                <div className="d-flex align-items-center ml-1 mr-3">
                  {socialMediaIconList.map((elem, index) => (
                    <span
                      key={index}
                      onMouseEnter={() => {
                        seticoncolor(index === 4 ? "yellow" : "black");
                      }}
                      onMouseLeave={() => {
                        seticoncolor("black");
                      }}
                    >
                      {elem}
                    </span>
                  ))}{" "}
                  1.5K
                </div>
              </div>
              <div className={`col-xl-12 d-flex justify-content-end col-md-6 col-12 pt-xl-5 pt-sm-2 pr-0`}>
                <button className={`ml-auto p-2 px-4  font-14 rounded-32 ${windowWidth<768&&'btn-block'} ${mode === "lightMode"? "btn-black font-weight-bold": "btn-white"}`}>
                  Make Collection offer
                </button>
              </div>
            </div>
          </div>
        </div>
    );
  };
  const renderTabs = () => {
    return [
      { id: 1, label: "Items" },
      { id: 2, label: "Analytics" }
    ].map((elem) => {
      return (
        <span
          onClick={() => {
            setcurrentTabIndex(elem.id);
          }}
          key={elem.id}
          className={`pointer font-18  mr-3`}
          style={{
            paddingBottom: "10px",
            borderBottom: currentTabIndex === elem.id ? "3px solid black" : "",
            fontWeight: "var(500,400)",
            color:
              currentTabIndex === elem.id
                ? mode === "lightMode"
                  ? "black"
                  : "white"
                : "#929292",
            fontFamily: "inherit",
            // fontWeight: "bold",
          }}
        >
          {elem.label}
        </span>
      );
    });
  };

  useEffect(() => {
    const handleResize = () => {
      setwindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup function to remove the event listener when component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <section className={`${mode === "lightMode" ? "bg-white text-dark" : "bg-black text-white"} ${Styles.mainContainer}`}>
      <section style={{ maxWidth: "1250px", margin: "auto" }}>
        {renderFirstSection()}
        <section>
          <div className={`row mt-2 mx-0 ${Styles.tabContainer} ${mode === "lightMode"? "bg-white text-black": "bg-black text-white"}`}>
            <div className="col-12 py-2 border-bottom border-secondary ml-2 pl-0 pb-0">
              {renderTabs()}
            </div>
          </div>
          {currentTabIndex === 1 && (
            <div className={`row my-2 mx-0 ${Styles.secondContainer}  ${mode === "lightMode"? "bg-white text-black": "bg-black text-white"}`}>
              {secondView()}
            </div>
          )}
          {currentTabIndex === 1 && thirdView()}
          {currentTabIndex === 2 && (
            <AnalyticsScreen blockchain={state?.blockchain} userData={userData} />
          )}
        </section>
        {handleDescriptionPopup()}
      </section>
    </section>
  );
}
