import React, { useState, PureComponent,useEffect } from "react";
import { useSelector } from "react-redux";
import {
  ComposedChart,
  Line,
  Area,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

export default function CustomLine_volume(props) {
  const mode = useSelector((state) => state.themeType.themeType);
  const data1 = [
    {
      name: "11/24",
      uv: 4000,
      pv: 2400,
      amt: 2400,
      cnt: 1400,
    },
    {
      name: "11/25",
      uv: 3000,
      pv: 1398,
      amt: 2210,
      cnt: 1000,
    },
    {
      name: "11/26",
      uv: 2000,
      pv: 2800,
      amt: 2290,
      cnt: 1500,
    },
    {
      name: "11/27",
      uv: 2780,
      pv: 3908,
      amt: 2000,
      cnt: 4000,
    },
    {
      name: " 11/28",
      uv: 1890,
      pv: 4800,
      amt: 2181,
      cnt: 700,
    },
    {
      name: " 11/29",
      uv: 2390,
      pv: 3800,
      amt: 2500,
      cnt: 1256,
    },
    {
      name: " 11/30",
      uv: 3490,
      pv: 4300,
      amt: 2100,
      cnt: 5500,
    },
  ];
  useEffect(()=>{

  },[props.data])

  return (
    <ResponsiveContainer
      width="90%"
      // height={400}
      height={330}
      style={{backgroundColor: mode !== "lightMode" ? "black" : "white" }}
    >
      <ComposedChart
        width={"100%"}
        height={300}
        data={props.data ? props.data : data1}
        margin={{
          top: 20,
          right: 20,
          bottom: 20,
          left: 20,
        }}
      >
        <XAxis
          dataKey={props.xaxisTitlekey}
          className="font-14  "
          scale="title"
          tickFormatter={
            props.formatDate === undefined ? null : props.formatDate
          }
          tick={{ fill: mode === "lightMode" ? "gray" : "white", opacity: 0.7 }}
          tickLine={false}
        />
        <YAxis
          dataKey={props.yaxisTitlekey}
          className="font-14 "
          axisLine={false}
          tickLine={false}
          scale="linear"
          tick={{ fill: mode === "lightMode" ? "gray" : "white", opacity: 0.9 }}
          interval={0}
          domain={[0, props.maxYValue]} 
          tickCount={props.maxYValue / 2 + 1}
          tickFormatter={(value) => (value % 2 === 0 ? value : '')}
        />  
        {props.children}
      </ComposedChart>
    </ResponsiveContainer>
  );
}
