import React, { useEffect, useState } from "react";
import moment from "moment";
import {
  Brush,
  Line,
  ComposedChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import { useSelector } from "react-redux";
import { trace } from "firebase/performance";
import { sendPerfomance } from "../..";
import axios from "axios";
import { Switch } from "@mui/material";
import { tracer } from "../../tracing";
import { Loader2 } from "../../commonComponent/Loader";
import { styled } from "@mui/material/styles";
import { toast } from "react-toastify";
import { REACT_APP_FETCH_PARTICULAR_COLLECTION_BASEURL } from "../../config/API/Api";
import './Collection.css'
import AnalyticGraph from "./AnalyticGraph";

export default function AnalyticsScreen(props) {
  const { userData, blockchain } = props;
  const mode = useSelector((state) => state.themeType.themeType);
  const optionfilter = [
    { id: 3, label: "30D", value: "30" },
    { id: 1, label: "24h", value: "1" },
    { id: 2, label: "7D", value: "7" },
    { id: 4, label: "60D", value: "60" },
    { id: 5, label: "90D", value: "90" },
    { id: 6, label: "1Y", value: "1" },
    { id: 7, label: "All", value: "*" },
  ];
  const [currentFilter, setcurrentFilter] = useState(optionfilter[0]?.value);
  const [graphData, setgraphData] = useState({});
  const token = localStorage.getItem("accessToken");
  const [isloading, setisloading] = useState(false);
  const [iswaiting, setiswaiting] = useState("");
  const [maxYValue, setMaxYValue] = useState(10);
  const [activeFilter, setactiveFilter] = useState({
    volume: true,
    like: true,
    dislike: true,
  });

  const formatDate = (dateString) => {
    return moment(dateString)
      .format(currentFilter === "1" ? "HH:mm" : "DD/MM")
      .toString();
  };
  const graphDataapi = async () => {
    let url = REACT_APP_FETCH_PARTICULAR_COLLECTION_BASEURL;
    const params = {
      collection_id: userData?.id,
      timeframe: currentFilter,
      blockchain: blockchain,
    };
    let header = {};
    if (token === null || token === undefined || token === "") {
      url = url + "/collection/series";
    } else {
      url = url + "/collection/series";
      header = {
        Authorization: token,
      };
    }
    try {
      const t = trace(sendPerfomance, "Graph_Data_for_Collection_Url");
      const span = tracer.startSpan("Graph_Data_for_Collection_api");
      t.start();
      setisloading(true);
      await axios
        .post(url, params, { headers: header })
        .then((res) => {
          span.setAttribute("method", "POST");
          span.addEvent("api_response", {
            status: res.status,
            data: res.data,
            url,
          });
          if (res.status === 200) {
            console.log(
              "Graph_Data_for_Collection_Url data is==========>>>",
              res.data
            );
            if (res.data.isgraphData === 1) {
              t.putMetric(
                "Graph_Data_for_Collection_Url_metric",
                res.data.data.length
              );
              const data = Math.max(
                ...res.data.data.map((elem) => Number(elem.likes))
              );
              const dataDislike = Math.max(
                ...res.data.data.map((elem) => Number(elem.dislikes))
              );
              const datavolume = Math.max(
                ...res.data.data.map((elem) => Number(elem.volume))
              );
              const finalmax = Math.max(data, dataDislike, datavolume);
              const buffer = finalmax + 1; // Adjust buffer percentage as needed
              setMaxYValue(buffer);
              setgraphData(res.data);
            } else {
              setgraphData([]);
              setiswaiting("No Record Found");
            }
          }
        })
        .catch((err) => {
          console.log(err);
          setiswaiting("No Record Found");
          span.setStatus({ code: "ERROR", message: err?.message });
          span.end();
        })
        .finally(() => {
          t.stop();
          span.end();
          setisloading(false);
        });
    } catch (error) {
      console.log(error);
      setiswaiting("No Record Found");
    }
  };
  useEffect(() => {
    graphDataapi();
  }, [currentFilter]);

  const renderFilter = () => {
    const filterData = [
      {
        id: 1,
        title: "Volume",
        value: "29.4886",
        color: "#a49cff",
        enableBgColor: "#ebebeb",
        disablebgColor: "rgb(249, 249, 249)",
      },
      {
        id: 2,
        title: "Like",
        value: "11.3082",
        color: "#5fe18f",
        enableBgColor: "#ebebeb",
        disablebgColor: "rgb(249, 249, 249)",
      },
      {
        id: 3,
        title: "Dislike",
        value: "384",
        color: "#6ba6ff",
        enableBgColor: "#ebebeb",
        disablebgColor: "rgb(249, 249, 249)",
      },
    ];
    const checkColorpresence = (item) => {
      if (item.id === 1) {
        if (activeFilter.volume) {
          return true;
        } else {
          return false;
        }
      }
      if (item.id === 2) {
        if (activeFilter.like) {
          return true;
        } else {
          return false;
        }
      }
      if (item.id === 3) {
        if (activeFilter.dislike) {
          return true;
        } else {
          return false;
        }
      }
    };
    const handleClickonItem = (item) => {
      if (item.id === 1) {
        if (activeFilter.volume) {
          if (!activeFilter.like && !activeFilter.dislike) {
            toast.warn("You need to select at least one data set");
            return;
          }
        }
        setactiveFilter({ ...activeFilter, volume: !activeFilter.volume });
      }
      if (item.id === 2) {
        if (activeFilter.like) {
          if (!activeFilter.volume && !activeFilter.dislike) {
            toast.warn("You need to select at least one data set");
            return;
          }
        }
        setactiveFilter({ ...activeFilter, like: !activeFilter.like });
      }
      if (item.id === 3) {
        if (activeFilter.dislike) {
          if (!activeFilter.like && !activeFilter.volume) {
            toast.warn("You need to select at least one data set");
            return;
          }
        }
        setactiveFilter({ ...activeFilter, dislike: !activeFilter.dislike });
      }
    };
    return (
      <div className="container-fluid d-flex overflow-auto scrollbar">
        {filterData.map((elem, index) => (
          <div
            onClick={() => {
              handleClickonItem(elem);
            }}
            key={elem.id}
            className={`m-2  py-3 analyticFilterItem ${
              mode !== "lightMode" && "bg-black border-secondary border"
            }`}
            style={{
              backgroundColor: checkColorpresence(elem)
                ? elem.disablebgColor
                : elem.enableBgColor,
            }}
          >
            <div className="d-flex">
              <div
                style={{
                  width: "8px",
                  height: "8px",
                  backgroundColor: elem.color,
                  borderRadius: "2px",
                }}
                className="mt-1"
              ></div>
              <p
                className="ml-2 mb-0"
                style={{
                  fontWeight:'bold',
                  fontSize: "14px",
                  lineHeight: "16px",
                  opacity: checkColorpresence(elem) ? 1 : "0.5",
                }}
              >
                {elem?.title}
              </p>
            </div>
            {!isloading && (
              <p
                style={{
                  fontSize: "14px",
                  fontWeight: "bold",
                  lineHeight: "16px",
                  margin: "0 8px 0 0",
                }}
              >
                {elem.id === 1 && graphData?.total_volume}
                {elem.id === 2 && graphData?.total_likes}
                {elem.id === 3 && graphData?.total_dislikes}
              </p>
            )}
          </div>
        ))}
      </div>
    );
  };
  const AntSwitch = styled(Switch)(({ theme }) => ({
    width: 28,
    height: 16,
    padding: 0,
    display: "flex",
    "&:active": {
      "& .MuiSwitch-thumb": {
        width: 15,
      },
      "& .MuiSwitch-switchBase.Mui-checked": {
        transform: "translateX(9px)",
      },
    },
    "& .MuiSwitch-switchBase": {
      padding: 2,
      "&.Mui-checked": {
        transform: "translateX(12px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          opacity: 1,
          backgroundColor:
            theme.palette.mode === "dark"
              ? "#177ddc"
              : mode === "lightMode"
              ? "black"
              : "#1890ff",
        },
      },
    },
    "& .MuiSwitch-thumb": {
      boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
      width: 12,
      height: 12,
      borderRadius: 6,
      transition: theme.transitions.create(["width"], {
        duration: 200,
      }),
    },
    "& .MuiSwitch-track": {
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor:
        theme.palette.mode === "dark"
          ? "rgba(255,255,255,.35)"
          : "rgba(0,0,0,.25)",
      boxSizing: "border-box",
    },
  }));

  const switchesfilter = () => {
    return (
      <div className="d-flex ml-auto">
        <div className="d-flex mt-3 mr-2">
          <span
            className="font-14 mr-1"
            style={{ fontWeight: "bold", lineHeight: "16px" }}
          >
            Exclude outliers
          </span>
          <AntSwitch
            defaultChecked
            inputProps={{ "aria-label": "ant design" }}
          />
        </div>
        <div className="d-flex mt-3">
          <span
            className="font-14 mr-1"
            style={{ fontWeight: "bold", lineHeight: "16px" }}
          >
            Exclude wash trades
          </span>
          {/* <Switch /> */}
          <AntSwitch
            defaultChecked
            inputProps={{ "aria-label": "ant design" }}
          />
        </div>
        <div className="form-group ml-3 mt-2">
          <select
            className={`form-control pointer ${
              mode === "lightMode"
                ? "bg-white text-dark"
                : "bg-black text-white"
            }`}
            id="exampleFormControlSelect1"
            onChange={(e) => {
              setcurrentFilter(e.target.value);
            }}
            style={{ height: "30px", fontSize: "14px", maxWidth: "75px" }}
          >
            {optionfilter.map((elem) => {
              return (
                <option
                  key={elem.id}
                  selected={elem.id === 3}
                  value={elem.value}
                  className={`pointer ${
                    mode === "lightMode"
                      ? "bg-white text-dark"
                      : "bg-black text-white"
                  }`}
                >
                  {elem.label}
                </option>
              );
            })}
          </select>
        </div>
      </div>
    );
  };
  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className="custom-tooltip">
          <p className="" style={{ color: "gray" }}>
            {moment(payload[0]?.payload?.time).format("DD/MM/YYYY hh:mm")}
          </p>
          {payload[0] !== undefined && (
            <div className="d-flex align-items-center">
              <div
                className="mr-1"
                style={{
                  backgroundColor: "#a49cff",
                  width: "8px",
                  height: "8px",
                  borderRadius: "12px",
                }}
              ></div>
              <p className="mb-0">
                Volume:{" "}
                <span className="font-weight-bold"> {payload[0].value}</span>
              </p>
            </div>
          )}
          {payload[1] !== undefined && (
            <div className="d-flex align-items-center">
              <div
                className="mr-1"
                style={{
                  backgroundColor: "#5fe18f",
                  width: "8px",
                  height: "8px",
                  borderRadius: "12px",
                }}
              ></div>
              <p className="mb-0">
                Likes:{" "}
                <span className="font-weight-bold"> {payload[1].value}</span>
              </p>
            </div>
          )}
          {payload[2] !== undefined && (
            <div className="d-flex align-items-center">
              <div
                className="mr-1"
                style={{
                  backgroundColor: "#6ba6ff",
                  width: "8px",
                  height: "8px",
                  borderRadius: "12px",
                }}
              ></div>
              <p className="mb-0">
                Dislikes:{" "}
                <span className="font-weight-bold"> {payload[2].value}</span>
              </p>
            </div>
          )}
          {/* <p className="intro">{getIntroOfPage(label)}</p>
          <p className="desc">Anything you want can be displayed here.</p> */}
        </div>
      );
    }

    return null;
  };
  return (
    <div className="analyticMainContainer">
      <div className="row mx-0">
        <div className="col-md-6 col-12 align-self-center"><h5 className="font-16 inherit font-weight-bold">Price and Volume</h5></div>
        <div className={`col-md-6 col-12 switchContainer align-self-center`}>{switchesfilter()}</div>
      </div>
      {renderFilter()}
      <div className="">
        {isloading ? (
          <div
            className="d-flex align-items-center justify-content-center"
            style={{
              minHeight: "400px",
            }}
          >
            <Loader2 size={25} color="black" />{" "}
          </div>
        ) : (
          <div className="row">
            <div className="col-12 px-0">
              <ResponsiveContainer width="100%" height={250} >
                <ComposedChart
                  width={"100%"}
                  height={300}
                  data={graphData?.data}
                  margin={{ top: 20, right: 60, left: 0, bottom: 0 }}
                >
                  <CartesianGrid
                    strokeDasharray="0"
                    horizontal={true}
                    vertical={false}
                  />
                  <XAxis
                    tickFormatter={formatDate}
                    maxYValue={maxYValue}
                    dataKey="time"
                    tick={{
                      fill: mode === "lightMode" ? "gray" : "white",
                      opacity: 0.7,
                      fontSize:12
                    }}
                  />
                  <YAxis
                    axisLine={false}
                    tickLine={false}
                    className="font-14 "
                    scale="linear"
                    tick={{
                      fill: mode === "lightMode" ? "gray" : "white",
                      opacity: 0.9,
                      fontSize:12
                    }}
                    interval={0}
                  />
                  <Brush
                    dataKey="time"
                    height={30}
                    stroke="#aec7fa"
                    tickFormatter={formatDate}
                  />
                  {activeFilter.volume&&<Bar dataKey="volume" fill="#8884d8" barSize={100} />}
                  {activeFilter.like && (
                    <Line
                      type="monotone"
                      dataKey="likes"
                      stroke="#5fe18f"
                      strokeWidth={3}
                      dot={false}
                    />
                  )}
                  {activeFilter.dislike && (
                    <Line
                      type="monotone"
                      dataKey="dislikes"
                      stroke="#6ba6ff"
                      strokeWidth={3}
                      dot={false}
                    />
                  )}
                  <Tooltip
                    contentStyle={{
                      backgroundColor: mode === "lightMode" ? "white" : "black", // Reverse the colors
                      color: mode === "lightMode" ? "black" : "white", // Text color
                      borderRadius: "10px",
                      boxShadow: "none",
                    }}
                    wrapperStyle={{ overflow: "visible" }}
                    position={{ y: 0 }}
                    itemStyle={{
                      color: mode === "lightMode" ? "black" : "white",
                    }}
                    cursor={{ fill: "transparent" }}
                    content={<CustomTooltip />}
                  />
                </ComposedChart>
              </ResponsiveContainer>
            </div>
            {!isloading ? (
              <p
                className="text-center text-black"
                style={{ position: "absolute", left: "45%", top: "50%" }}
              >
                {iswaiting}
              </p>
            ) : null}
          </div>
        )}
      </div>
    </div>
  );
}
