import React from 'react';
import { Drawer, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import InboxIcon from '@mui/icons-material/Inbox';
import MailIcon from '@mui/icons-material/Mail';

export default function Portfolio2({ open, onClose })  {
  return (
    <>
    <Drawer open={open} onClose={onClose}>
      <List>
        <ListItem button>
          <ListItemIcon>
            <InboxIcon />
            <p>heloram</p>
          </ListItemIcon>
          <ListItemText primary="Inbox" />
        </ListItem>
        <ListItem button>
          <ListItemIcon>
            <MailIcon />
          </ListItemIcon>
          <ListItemText primary="Mail" />
        </ListItem>
      </List>
    </Drawer>
    </>
  );
};

