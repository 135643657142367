import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { tracer } from "../../tracing";
import "./SearchCollectionScreen.css";
import Axios, { BaseUrl } from "../../config/API/Api";
import { toast } from "react-toastify";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import userpng from "../../assets/user.png";
import bluetick from "../../assets/blue_Tick.png";
import { trace } from "firebase/performance";
import { sendPerfomance } from "../..";
import OrdinalTabScreen from "./OrdinalTabScreen";
import SearchAssetTabScreen from "./SearchAssetTabScreen";
import BitcoinIcon from "../../assets/bitcoin.png";
import allicon from "../../assets/allIcon.webp";
import etherumIcon from "../../assets/ethereumIcon.webp";
import baseicon from "../../assets/baseIcon.webp";
import polygonIcon from "../../assets/polygonIcon.webp";
import flowIcon from "../../assets/flowIcon.png";
import arbitrumIcon from "../../assets/ArbitrumIcon.png";
import chilizicon from "../../assets/ChilizIcon.jpeg";
import lightlinkicon from "../../assets/LightLinkIcon.jpeg";
import immutablexicon from "../../assets/Immutablexicon.webp";
import Select from "react-select";
import { useNavigate } from "react-router-dom";
import ReactGA from 'react-ga4';
export default function SearchCollectionScreen() {
  const mode = useSelector((state) => state.themeType.themeType);
  const [activeTab, setactiveTab] = useState(1);
  const [blockchainList, setblockchainList] = useState([]);
  const [activeBlockchain, setactiveBlockchain] = useState("");
  const initialSearchValue = useParams();
  const [itemsinordinal, setitemsinordinal] = useState(0);
  const [iteminassets, setiteminassets] = useState(0);
  const [iteminCollection, setiteminCollection] = useState(0);
  const [isOpen, setIsOpen] = useState(false);

  const [filterStatus, setfilterStatus] = useState("1");
  const [windowWidth, setwindowWidth] = useState(window.innerWidth);
  const updateitemsAsset = (val) => {
    setiteminassets(val);
  };
  useCallback(updateitemsAsset, []);

  const [search, setsearch] = useState(
    initialSearchValue.search === undefined ? "" : initialSearchValue.search
  );
  //TabsData
  const [searchCollectionDeatil, setsearchCollectionDeatil] = useState([]);
  const [searchAssetList, setsearchAssetList] = useState([]);
  const [searchOrdinalList, setsearchordinallist] = useState([]);
  const [loading, setloading] = useState(true);
  const navigate=useNavigate();
  const volumeList = [
    { name: "none", key: 1 },
    { name: "Volume", key: 2 },
    { name: "Like", key: 3 },
    { name: "Dislike", key: 4 },
  ];
  const iconList = [
    {
      key: "bitcoin",
      image_url: BitcoinIcon,
    },
    {
      key: "all",
      image_url: allicon,
    },
    {
      key: "ethereum",
      image_url: etherumIcon,
    },
    {
      key: "base",
      image_url: baseicon,
    },
    {
      key: "polygon",
      image_url: polygonIcon,
    },
    {
      key: "flow",
      image_url: flowIcon,
    },
    {
      key: "arbitrum",
      image_url: arbitrumIcon,
    },
    {
      key: "chiliz",
      image_url: chilizicon,
    },
    {
      key: "lightlink",
      image_url: lightlinkicon,
    },
    {
      key: "immutablex",
      image_url: immutablexicon,
    },
  ];
  const returnimagebehalfblockchain = (key, style) => {
    const existkey = iconList.filter((elem) => {
      return elem.key === key;
    });
    if (existkey.length > 0) {
      return (
        <img
          style={style}
          width="16px"
          height={"16px"}
          src={existkey[0].image_url}
          alt="..."
        />
      );
    } else {
      return null;
    }
  };
  const handleTopPagination = () => {
    return (
      <nav aria-label="breadcrumb ">
        <ol className="breadcrumb bg-transparent mb-0 p-0">
          <li className="breadcrumb-item" style={{ color: "red" }}>
            <Link to="/" className="text-muted" onClick={()=>{
                  ReactGA.event({
                    category: 'Navigation',
                    action: 'Click the link',
                    label: 'Dev-admin-ui',
                    nonInteraction:false,
                    value:'/server'
                  })
            }}>
              Dev-admin-ui
            </Link>
          </li>
          <li
            className="breadcrumb-item active  font-16 lh-24 font-weight-bold"
            aria-current="page"
          >
            Search results
          </li>
        </ol>
      </nav>
    );
  };

  const handletabSection = () => {
    return (
      <section
        className="d-flex flex-nowrap border-bottom border-secondary mt-4 pt-2 "
        style={{
          position: "sticky",
          top: "0",
          backgroundColor: mode === "lightMode" ? "white" : "black",
          zIndex: 1,
        }}
      >
        <div
          className={`font-16 tabItem ${
            activeTab === 1 ? "activeTabItem" : "inactiveTabitem"
          }`}
          onClick={() => {
            ReactGA.event({
              category: 'Click',
              action: 'clicked on a collection tab',
              label: activeTab,
              nonInteraction:false,
              value:'/server'
            })
            setactiveTab(1);
          }}
        >
          Collection ({iteminCollection === null ? 0 : iteminCollection})
        </div>
        <div
          className={`tabItem font-16 ${
            activeTab === 2 ? "activeTabItem" : "inactiveTabitem"
          }`}
          onClick={() => {
            ReactGA.event({
              category: 'Click',
              action: 'clicked on a assets tab',
              label: activeTab,
              nonInteraction:false,
              value:'/server'
            })
            setactiveTab(2);
          }}
        >
          Assets (
          {iteminassets === null || iteminassets === "null" ? 0 : iteminassets})
        </div>
        <div
          className={`tabItem font-16 ${
            activeTab === 3 ? "activeTabItem" : "inactiveTabitem"
          }`}
          onClick={() => {
            ReactGA.event({
              category: 'Click',
              action: 'clicked on a ordinals tab',
              label: activeTab,
              nonInteraction:false,
              value:'/server'
            })
            setactiveTab(3);
          }}
        >
          Ordinals ({itemsinordinal === null ? 0 : itemsinordinal})
        </div>
      </section>
    );
  };

  const handleFilterSection = () => {
    const customStyles = {
      option: (provided, state) => ({
        ...provided,
        borderBottom: "1px solid #ccc",
        background: mode === "lightMode" ? "#f5f5f5" : "black",
        color: state.isSelected ?mode==='lightMode'?'black':"#fff" : "#929292", // Selected text color
        // background: state.isSelected ? "#007bff" : "#fff", // Selected background color
      }),
      control: (provided) => ({
        ...provided,
        border: "1px solid #ccc",
        borderRadius: "4px",
        cursor: "pointer",
        height: "40px",
        color: mode === "lightMode" ? "black" : "white",
        background: mode === "lightMode" ? "f5f5f5" : "#1b1b1b",
      }),
      singleValue: (provided) => ({
        ...provided,
        color: mode === "lightMode" ? "black" : "white",
      }),
      indicatorSeparator: () => ({
        display: "none",
      }), // Remove separator
      dropdownIndicator: (provided) => ({
        ...provided,
        fontSize: "1px",
        svg: {
          height: "15px", // Adjust the size of the SVG icon
          width: "15px", // Adjust the size of the SVG icon
        },
        transition: "transform 0.7s", // Add transition for smooth rotation
        transform: isOpen ? "rotate(180deg)" : "rotate(0deg)",
        color: mode === "lightMode" ? "black" : "white",
      }),
      menuList: (base) => ({
        ...base,
        background:mode==="lightMode"?"#f5f5f5":'black',
        color: mode === "lightMode" ? "black" : "white",
        overFlow:'hidden',
        '&::-webkit-scrollbar': {
          width: '5px', // Adjust the width of the scrollbar
        },
        '&::-webkit-scrollbar-thumb': {
          background: mode === "lightMode" ? "#ccc" : "#555", // Adjust the color of the scrollbar thumb
          borderRadius: '5px', // Adjust the border radius of the scrollbar thumb
        },
      }),
    };
    return (
      <section className="d-flex justify-content-between flex-wrap">
        <Select
          options={blockchainList}
          value={blockchainList.find(
            (option) => option.key === activeBlockchain
          )}
          // defaultValue={activeBlockchain}
          onChange={(selectedOption) => {
            ReactGA.event({
              category: 'Select Input',
              action: 'select the block chain ',
              label: selectedOption?.key,
              nonInteraction:false,
              value:selectedOption?.key
            })
            setactiveBlockchain(selectedOption?.key);
          }}
          isSearchable={false}
          isClearable={false}
          getOptionLabel={(option) => (
            <div className="scrollbar">
              <img
                src={`data:image/jpg;base64,${option?.image_url}`}
                alt={option?.name}
                style={{ width: "20px", marginRight: "10px" }}
              />
              {option?.name}
            </div>
          )}
          getOptionValue={(option) => option.key}
          styles={customStyles}
          className=" mt-3 font-14 border-0 scrollbar"
          onMenuOpen={() => setIsOpen(true)} // Set isOpen to true when menu is opened
          onMenuClose={() => setIsOpen(false)}
        />
        <div className={`form-group my-3 `}>
          <select
            className={`form-control filterItem pointer  ${
              mode === "lightMode" ? "bg-white text-dark" : "bg-dark text-white"
            }`}
            id="exampleFormControlSelect1"
            value={filterStatus}
            onChange={(e) => {
              ReactGA.event({
                category: 'filter status',
                action: 'clicked on a filter status',
                label: e.target.value,
                nonInteraction:false,
                value:e.target.value
              })
              setfilterStatus(e.target.value);
            }}
            style={{ fontSize: "14px", lineHeight: "132%", maxWidth: "170px" }}
          >
            {volumeList.map((elem, index) => {
              return (
                <option
                  className={`${
                    mode === "lightMode"
                      ? "bg-white text-dark"
                      : "bg-dark text-white"
                  }`}
                  key={index}
                  // disabled={elem.key===1}
                  value={elem?.key}
                >
                  <img
                    width={"24px"}
                    height={"24px"}
                    src={"../../assets/user.png"}
                    alt="..."
                  />
                  {elem?.name}
                </option>
              );
            })}
          </select>
        </div>
      </section>
    );
  };
  const handleSearchSection = () => {
    return (
      <section className="mt-2 w-25">
        <div className="form-group">
          <input
            type="text"
            className="form-control"
            id="exampleFormControlInput1"
            placeholder="Search"
            value={search}
            onChange={(e) => {
              ReactGA.event({
                category: 'search',
                action: 'write the text on search input',
                label: e.target.value,
                nonInteraction:false,
                value:e.target.value
              })
              setsearch(e.target.value);
              if (e.target.value.length < 3) {
                setiteminCollection(0);
                setiteminassets(0);
                setitemsinordinal(0);
              }
            }}
          />
        </div>
      </section>
    );
  };

  const collectionCardItem = (elem, index) => {
    console.log('thisi selem,',elem)
    return (
      <div
        className="col-sm-12 col-md-6 col-lg-4  rounded pointer "
        key={index}
        onClick={() => {
          ReactGA.event({
            category: 'Clicked',
            action: 'clicked on a collection item',
            label: elem?.name,
            nonInteraction:false,
            value:JSON.stringify(elem)
          })

          navigate(`/collection/${elem?.contract_id}/overview`, {state:{
            userData: elem,
            blockchain: elem.blockchain,
            image_url: elem?.image_url,
          }});
        }}
      >
        <div
          style={{ border: "1px solid #ebebeb", padding: "8px", margin: "5px" }}
        >
          <div className="d-flex">
            <div>
              <img
                className="rounded-circle"
                src={elem.image_url !== undefined ? elem.image_url : userpng}
                width={"46px"}
                height={"46px"}
              />
              {returnimagebehalfblockchain(elem?.blockchain, {
                position: "relative",
                top: "13px",
                right: "12px",
              })}
            </div>
            <div className="ml-1">
              <span className="d-flex align-items-center">
                <p className="mb-0 font-weight-bold  ">
                  {elem.name !== undefined
                    ? elem.name.length > 17
                      ? elem.name.slice(0, 17) + "..."
                      : elem.name
                    : null}
                </p>
                <img
                  src={bluetick}
                  className="ml-2"
                  width={"14px"}
                  height={"14px"}
                />
              </span>
              <span className="d-flex justify-content-between">
                <span className="font-14">383 items</span>
                <span className="font-14 ml-3">133 Owner</span>
              </span>
              <p></p>
            </div>
          </div>

          <div className="d-flex justify-content-around">
            {[1, 1, 1].map((item, index) => (
              <div key={index}>
                <div className="d-flex align-items-center">
                  {returnimagebehalfblockchain(elem?.blockchain, {})}
                  <span className="fontweight-700 lh-16 font-14 ml-1">
                    0.15
                  </span>
                </div>
                <p
                  className={`my-2 font-12 lh-16 ${
                    mode === "lightMode" ? "#929292" : "white"
                  }`}
                >
                  Last Sale
                </p>
              </div>
            ))}
          </div>
          <div className="d-flex flex-nowrap justify-content-between ">
            {elem.assets !== undefined &&
              elem.assets.map((item, index) => (
                <div
                  key={index}
                  className="m-1"
                  style={{ maxWidth: "64px", maxHeight: "64px" }}
                >
                  <img
                    className="img-responsive img-fluid rounded-10"
                    src={
                      item.image_url === undefined ? userpng : item.image_url
                    }
                    alt="..."
                    style={{ width: "64px", height: "64px" }}
                  />
                </div>
              ))}
          </div>
        </div>
      </div>
    );
  };

  const blockchainApi = async () => {
    const span = tracer.startSpan("BlockChainListApi");
    const url = "/blockchainlist";
    try {
      await Axios.get(url)
        .then((res) => {
          if (res.status === 200) {
            if (typeof res.data === "string") {
              toast.warn("invalid data got through blockchain api");
              return;
            }
            if (res.data.length > 0) {
              console.log("blockchainlistdata is", res.data);
              ReactGA.event({
                category: ' blockchain api response',
                action: 'blockchain api',
                label: JSON.stringify(res.data),
                nonInteraction:false,
                value:res.data
              })
              setblockchainList(res.data);
              setactiveBlockchain(res.data[0]?.key);
            }
          }
          span.setAttribute("Method", "POST");
          span.addEvent("api_response", {
            status: res.status,
            url,
            data: res.data,
          });
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          span.end();
        });
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    blockchainApi();
  }, []);
  useEffect(() => {
    const handleResize = () => {
      setwindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup function to remove the event listener when component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const searchCollectionApi = async (obj) => {
    const url = `/search/${obj.type}`;
    let params = {
      search: search,
      size: "10",
      page: "1",
    };
    if (obj.isOrdinal) {
      params.blockchain = "bitcoin";
    }
    if (obj.isblockchainFilter) {
      params.blockchain = activeBlockchain;
    }
    if (obj.type === "collection" && filterStatus !== "1" && !obj.isOrdinal) {
      params.sort_by =
        volumeList[filterStatus - 1].name.toLocaleLowerCase() === "none"
          ? ""
          : volumeList[filterStatus - 1].name.toLocaleLowerCase();
    }
    try {
      const t = trace(
        sendPerfomance,
        obj.type === "collection" ? "Searc_collection_api" : "Search_asset_api"
      );
      const span = tracer.startSpan(
        obj.type === "collection" ? "SearchCollectionApi" : "SearchAssetApi"
      );
      t.start();
      if (obj.type === "collection" && !obj.isOrdinal) {
        setloading(true);
      }
      await Axios.post(url, params)
        .then((res) => {
          if (res.status === 200) {
            ReactGA.event({
              category: 'api response',
              action: 'collection api',
              label: url,
              nonInteraction:false,
              value:res.data
            })
            span.setAttributes({ method: "POST" });
            span.addEvent("api_response", {
              status: res.status,
              url,
              data: res.data,
            });
            t.getAttributes({
              status: res.status,
              url,
              data: res.data,
              payload: params,
            });
            if (obj.type === "collection") {

              if (obj.isOrdinal) {
                setsearchordinallist(res.data?.rows);
                setitemsinordinal(res.data?.count);
              } else {
                setsearchCollectionDeatil(res.data?.rows);
                setiteminCollection(res.data?.count);
              }
            } else {
              setsearchAssetList(res.data?.rows);
              setiteminassets(res.data?.count);
            }
          }
        })
        .catch((err) => {
          console.log(err);
          span.setStatus({ code: "ERROR", message: err?.message });
        })
        .finally(() => {
          t.stop();
          span.end();
          if (obj.type === "collection" && !obj.isOrdinal) {
            setloading(false);
          }
        });
    } catch (error) {
      console.log(error);
    }
  };
  useMemo(() => {
    setsearchCollectionDeatil([]);
    setsearchAssetList([]);
    setsearchordinallist([]);
    if (search.length > 2 && activeTab === 1) {
      searchCollectionApi({
        type: "collection",
        isOrdinal: false,
        isblockchainFilter: true,
      });
      searchCollectionApi({
        type: "asset",
        isOrdinal: false,
        isblockchainFilter: true,
      });

      searchCollectionApi({
        type: "collection",
        isOrdinal: true,
        isblockchainFilter: false,
      });
    }
  }, [search, activeBlockchain, filterStatus]);
  const updateitemcountinordinalTab = (val) => {
    setitemsinordinal(val);
  };
  return (
    <section
      className={` ${
        mode === "lightMode" ? "bg-light text-dark" : "bg-black text-white"
      }`}
      style={{ padding: windowWidth > 1024 ? "2em 8em" : "1em" }}
    >
      {handleTopPagination()}
      {handleSearchSection()}
      {handletabSection()}
      {activeTab === 1 && (
        <section id="CollectionTab">
          {handleFilterSection()}
          <div className="row">
            {loading
              ? Array(10)
                  .fill("")
                  .map((elem, index) => (
                    <div className="col-lg-4 col-sm-6" key={index}>
                      <SkeletonTheme
                        key={index}
                        baseColor="#EBEBEB"
                        highlightColor="#EBEBEB"
                      >
                        <div className="text-right my-3 mr-4">
                          <Skeleton
                            count={1}
                            className="w-75"
                            style={{ height: "40px" }}
                          />
                        </div>
                        <div className="text-center">
                          <Skeleton
                            count={3}
                            inline={true}
                            className=" mr-3"
                            style={{ height: "40px", width: "28%" }}
                          />
                        </div>
                        <div className="">
                          <Skeleton
                            count={5}
                            height={60}
                            width={60}
                            inline
                            className="mx-2"
                          />
                        </div>
                      </SkeletonTheme>
                    </div>
                  ))
              : null}
            {searchCollectionDeatil.map((elem, index) =>
              collectionCardItem(elem, index)
            )}
            <div className="col">
              <h2 className="text-center">
                {searchCollectionDeatil.length === 0 && !loading
                  ? "No Data Available"
                  : null}
              </h2>
            </div>
          </div>
        </section>
      )}
      {activeTab === 2 && (
        <SearchAssetTabScreen
          data={searchAssetList}
          search={search}
          activechain={activeBlockchain}
          updateitem={updateitemsAsset}
          blockchainList={blockchainList}
          windowWidth={windowWidth}
        />
      )}

      {activeTab === 3 && (
        <OrdinalTabScreen
          loading={loading}
          data={searchOrdinalList}
          search={search}
          updateitem={updateitemcountinordinalTab}
          windowWidth={windowWidth}
        />
      )}
    </section>
  );
}
