import React, { useState, useEffect, useRef } from "react";
import "./ordinal.css";
import Axios, { websocketBaseUrl } from "../../../config/API/Api";
import { w3cwebsocket } from "websocket";
import TableDatainOrdinal from "./TableDatainOrdinal";
import { useSelector } from "react-redux";
import { FaChevronRight, FaChevronLeft, FaCheck } from "react-icons/fa";
import Slider from "react-slick";
import { tracer } from "../../../tracing";
import { sendPerfomance } from "../../..";
import { trace } from "firebase/performance";
import CustomSelect from "../../../commonComponent/CustomSelect";
import { useNavigate } from "react-router-dom";
import ReactGA from 'react-ga4';

export default function OrdinalScreen() {
  const mode = useSelector((state) => state.themeType.themeType);
  const [activeTab, setactivetab] = useState(2);
  const [search, setsearch] = useState("");
  const [currentInterval, setcurrentInterval] = useState("30d");
  const [showArrow, setshowArrow] = useState(false);
  const [size, setsize] = useState(50);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const arrowRef = useRef(null);
  const [isLoading, setisloading] = useState(true);
  const [trendingCollectionList, settrendingCollectionList] = useState([]);
  const myDivRef = useRef(null);
  const navigate=useNavigate();
  const initialwsmsg = {
    action: "subscribe",
    channels: [{
        name: "collection_index_like",
        values: ["collection_index", String(size), "1", currentInterval, 'bitcoin'],
      }]};

  const [websocketMessage, setwebsocketmessage] = useState(initialwsmsg);
  const [data,setdata]=useState([]);
  const updatedata=(val)=>setdata(val);
  const socket = new w3cwebsocket(websocketBaseUrl);
  const closeSocket = () => {socket.close()};
  const IntervalsList = [
    { id: 3, label: "30d", value: "30d" },
    { id: 1, label: "24h", value: "24h" },
    { id: 2, label: "7d", value: "7d" },
    { id: 4, label: "60d", value: "60d" },
    { id: 5, label: "90d", value: "90d" },
  ];

  const updateSize = (val) => {
    const newWebsocketmessage={...websocketMessage}
    if(newWebsocketmessage!==undefined){
      newWebsocketmessage.channels.map((o)=>{
        o.values[1]=String(val);
      });
      setwebsocketmessage(newWebsocketmessage)
    }
    setsize(val);
  };

  var settings = {
    dots: false,
    infinite: false,
    slidesToShow: 3,
    arrows: false,
    slidesToScroll: 1,
    autoplay: true,
    initialSlide: 1,
    autoplaySpeed: 1000,
    pauseOnHover: true,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const updateInterval = (val) => {
    setdata([]);
    const newWebsocketmessage={...websocketMessage}
    if(newWebsocketmessage!==undefined){
      newWebsocketmessage.channels.map((o)=>{
        o.values[3]=val;
      });
      setwebsocketmessage(newWebsocketmessage)
    }
  };
  const renderBanner = () => {
    return (
      <div
        className="row bg-black m-0 "
        style={{ padding: windowWidth > 1024 ? "52px 132px 35px" : "30px" }}
      >
        <div className="col-xl-4 col-md-6 col-sm-12  ">
          <p
            className="text-white lh-40 "
            style={{
              fontWeight: "700",
              fontSize: windowWidth > 1200 ? "44px" : "32px",
            }}
          >
            Inscription market
          </p>
          <p
            className={`${windowWidth<1024?"font-12":"font-16"}`}
            style={{ color: "#b3b3b3", fontWeight: "400", lineHeight: "148%" }}
          >
            One-stop hub for trading and creating BRC-20 and Ordinals
            collections <br /> Explore inscriptions on JRN Web3 Marketplace
          </p>
          <div className={`d-flex justify-content-between `} style={{marginTop:"40px"}}>
            <button
              className="border border-secondary  btn-block m-0 bg-white text-dark  text-white "
              style={{ borderRadius: "50px", padding:windowWidth>1024?"14px 24px":"8px 12px", fontSize:windowWidth>1024?"16px":"14px"}}
            >
              Inscribe
            </button>
            <button
              className="border border-secondary  font-weight-bold  btn-block m-0 bg-black text-white ml-3 "
              style={{ borderRadius: "50px", fontSize:windowWidth>1024?"16px":"14px", padding:windowWidth>1024?"14px 24px":"8px 12px"}}
            >
              List Collection
            </button>
          </div>
        </div>
        <div
          className="col-xl-7  offset-xl-1 col-md-6 col-sm-12  d-flex flex-column justify-content-center  my-2"
          onMouseEnter={() => {
            setshowArrow(true);
          }}
          onMouseLeave={() => {
            setshowArrow(false);
          }}
          onTouchStart={()=>{setshowArrow(true)}}
          onTouchEnd={() => setshowArrow(false)}
        >
          {showArrow && (
            <div
              className="carousal_Icon pointer leftArrowCategory"
              onClick={() => {
                console.log(arrowRef.current);
                arrowRef.current.slickPrev();
              }}
              style={{
                backgroundColor: mode === "lightMode" ? "" : "#121212",
              }}
            >
              <FaChevronLeft
                size={15}
                color={mode == "lightMode" ? "black" : "white"}
              />
            </div>
          )}
          <Slider {...settings} ref={(c) => (arrowRef.current = c)}>
            {trendingCollectionList.map((elem, index) => (
              <div
                onClick={() => {
                  navigate(`/collection/${elem?.contract_id}/overview`, {state:{
                    userData: elem,
                    blockchain: elem?.blockchain,
                    image_url:elem?.image_url,
                    contract_id:elem?.contract_id
                  }});
                }}
                className={`pointer card bg-black `}
                style={{
                  borderRadius: "48px",
                  maxHeight:'236px',
                  maxWidth:"204px",
                }}
                key={index}
              >
                <img
                  className="card-img-top m-0 img-fluid img-responsive"
                  src={elem?.image_url}
                  alt="Card image cap"
                  style={{
                    borderRadius: "12px 12px 12px 12px",
                    boxShadow: "24px 24px 10px black",
                    maxHeight:'236px',
                    maxWidth:"204px",
                  }}
                />
                <div
                  className="card-overlay p-2 sliderBody "
                  style={{
                    // backgroundColor: mode === "lightMode" ? "white" : "#202020",
                    // background: "linear",
                    position: "absolute", // Position overlay absolutely to align it as needed
                    top: 0, // Start from top of the parent
                    left: 0, // Align to left of the parent
                    width: "100%", // Full width
                    height: "100%", // Full height
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "end", // Push content to the bottom
                    background:
                      "linear-gradient(to bottom, rgba(0,0,0,0) 70%, rgba(0,0,0,0.75) 90%)", // Gradient background
                  }}
                >
                  <h5
                    className="card-title font-weight-bold my-2 text-white text-center"
                    style={{ fontSize: "16px", lineHeight: "5px" }}
                  >
                    {elem?.name.length>10?elem.name.slice(0,10)+'...':elem.name}
                  </h5>
                  <p
                    className="font-12 mb-0 text-center"
                    style={{ color: "#929292" }}
                  >
                    Floor{" "}
                    <span className="text-white">{elem?.floor_price}</span>
                  </p>
                </div>
              </div>
            ))}
          </Slider>
          {showArrow && (
            <div
              className="carousal_Icon carousal_right_icon pointer rightArrowCategory"
              style={{
                backgroundColor: mode === "lightMode" ? "" : "#121212",
              }}
              onClick={() => {
                arrowRef.current.slickNext();
              }}
            >
              <FaChevronRight
                size={15}
                color={mode == "lightMode" ? "black" : "white"}
              />
            </div>
          )}
        </div>
      </div>
    );
  };
  const updatesortingFilter = (val) => {
    console.log(val);
    setcurrentInterval(val);
    updateInterval(val);
    // setcurrentFilter(val);
  };
  const renderInterval = () => {
    return (
      <div className={`mb-1 scrollbar`} style={{}}>
        <CustomSelect
          options={IntervalsList}
          updatevalue={updatesortingFilter}
          mode={mode}
        />
      </div>
    );
  };
  const renderSearch = () => {
    return (
      <div className="d-flex  w-50 mb-1 ">
        <div className="searchIcon" style={{ width: "24px", height: "24px" }}>
          <svg
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6 text-gray-400"
            style={{
              color: mode === "lightMode" ? "rgb(119 126 144)" : "#505461",
            }}
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M14.906 16.32a8 8 0 1 1 1.414-1.414l5.387 5.387a1 1 0 0 1-1.414 1.414l-5.387-5.387ZM16 10a6 6 0 1 1-12 0 6 6 0 0 1 12 0Z"
              fill="currentColor"
            ></path>
          </svg>
        </div>
        <input
          style={{
            borderRadius: "1px ",
            border: "1px solid black",
            backgroundColor:
              mode === "lightMode" ? "rgb(238 239 239)" : "rgb(43 38 47)",
          }}
          className="navSearchInput w-100 border-0"
          placeholder="Search"
          spellCheck="false"
          value={search}
          onChange={(e) => {
            ReactGA.event({
              category: 'search input ',
              action:'write the text on search input',
              label: e.target?.value,
              nonInteraction:false,
              value:e.target?.value
            })
            setsearch(e.target.value);
          }}
        />
      </div>
    );
  };
  const TopCollectionApi = async () => {
    const span = tracer.startSpan("TopCollectionApi");
    const t = trace(sendPerfomance, "TopCollectionApi");
    const url = `/trendingCollections?blockchain=bitcoin`;
    try {
      t.start();
      setisloading(true);
      await Axios.get(url)
        .then((res) => {
          if (res.status === 200) {
            console.log(
              "this is top trending dta",
              res.data,
              url,
              "this i surl"
            );
            settrendingCollectionList(res.data===undefined||res.data===null?[]:res.data);
          }
          span.setAttribute("Method", "GET");
          span.addEvent("api_response", {
            status: res.status,
            url,
            data: res.data,
          });
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          span.end();
          t.stop();
          setisloading(false);
        });
    } catch (error) {
      console.log(error);
      span.end();
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup function to remove the event listener when component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  useEffect(() => {
    TopCollectionApi();
  }, []);
 

  return (
    <section style={{ overflowX: "hidden" }}>
      {renderBanner()}
      <section>
        <div
          className={`row border-bottom border-secondary mb-0 pb-0 ${
            mode === "lightMode" ? "bg-white text-dark" : "bg-black text-white"
          }`}
        >
          <div
            className={`col-sm-12 col-md-6  px-3 pt-3 pb-0 ${
              windowWidth <= 768 ? "" : "offset-1"
            }`}
          >
            <div className="px-2 pt-3  d-flex flex-nowrap">
              <span
                className={`  font-weight-bold  tab ${
                  activeTab === 1 ? "activeTab" : "inactiveTab"
                }`}
                onClick={() => {
                  ReactGA.event({
                    category: 'clicked on select input ',
                    action:'clicked collection',
                    label:'BRC-20',
                    nonInteraction:false,
                  })
                  setactivetab(1);
                }}
              >
                BRC-20
              </span>
              <span
                className={`ml-3  font-weight-bold  tab ${
                  mode === "lightMode"
                    ? "bg-white text-dark"
                    : "bg-black text-white"
                } ${activeTab === 2 ? "activeTab" : "inactiveTab"}`}
                onClick={() => {
                  ReactGA.event({
                    category: 'clicked on collection tab ',
                    action:'clicked collection',
                    label: 'collection',
                    nonInteraction:false,
                  })
                  setactivetab(2);
                }}
              >
                Collections
              </span>
            </div>
          </div>
          {activeTab === 2 && (
            <div
              className={`col mx-2 ${
                windowWidth < 768
                  ? "justify-content-between"
                  : "justify-content-center"
              } align-items-end d-flex`}
            >
              {renderInterval()}
              {renderSearch()}
            </div>
          )}
        </div>
        {activeTab === 2 && (
          <div
            className={`row ${
              mode === "lightMode" ? "bg-light text-dark" : "bg-dark text-white"
            }`}
          >
            <div
              className={`  ${
                windowWidth > 992 ? "col-10 mx-auto" : "col-11 mx-auto"
              } `}
              ref={myDivRef}
            >
              <TableDatainOrdinal
                mode={mode}
                websocketMessage={websocketMessage}
                closeSocket={closeSocket}
                updateSize={updateSize}
                size={size}
                innerWidth={windowWidth}
                data={data}
                updatedata={updatedata}
              />
            </div>
          </div>
        )}
      </section>
    </section>
  );
}
