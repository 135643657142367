import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

export default function CustomPopup(props) {
  const mode = useSelector((state) => state.themeType.themeType);
  const closeBtn = (
    <button
      className={`close ${mode==="lightMode"?"text-dark":"text-white"}`}
      onClick={() => {
        props.onclick();
      }}
      type="button"
    >
      &times;
    </button>
  );
  return (
    <Modal
      size={props.size}
      isOpen={props.showpopup}
      centered={props.center}
      toggle={() => {
        props.onclick();
      }}
      style={props.modalStyle}
    >
      <ModalHeader
        toggle={() => {
          props.onclick();
        }}
        close={closeBtn}
        title={props.popheaderTitle}
        className={`${props.classHeader} ${
          mode === "lightMode" ? "bg-light text-dark" : "bg-dark text-white"
        }`}
      >
       <span className={`font-weight-bold font-24 ${ mode === "lightMode" ? "bg-light text-black" : "bg-dark text-white"}`}>{props.popHeader}</span>
      </ModalHeader>
      <ModalBody className={`${props.classBody} py-0` }style={{backgroundColor:mode === "lightMode" ? "white" : "black",color:mode === "lightMode" ? "black" : "white"}}>{props.body}</ModalBody>
      <ModalFooter className={`${props.classFooter}  ${
          mode === "lightMode" ? "bg-light text-dark" : "bg-dark text-white"
        }`}>
        {props.footerBody}
      </ModalFooter>
    </Modal>
  );
}
