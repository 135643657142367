import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  useRef,
} from "react";
import { useSelector } from "react-redux";
import "./HomeChat.css";
import {
  AiOutlineUsergroupAdd,
  AiOutlineUndo,
  AiOutlineMore,
  AiOutlineSearch,
} from "react-icons/ai";
import { ToastContainer, toast } from "react-toastify";
import { Strophe, $pres, $iq, $msg } from "strophe.js";
import CustomPopup from "../../commonComponent/CustomPopup";
import loader from "../../assets/loader.gif";
import { AiFillPlusCircle } from "react-icons/ai";
import {
  addUserinChathistory,
  fetchChatHistory,
  getAllRegisterdGroups,
  getpreviouschatusers,
  toSendmessage,
} from "./chatConnection";
import { getallUser } from "./chatApi";
import RenderChat from "./RenderChat";
import { Link, useNavigate } from "react-router-dom";
import {
  CreateChatRoom,
  acceptInvitation,
  createPrivatechatRoom,
  declineInvitation,
  getGroupmemberlist,
  getmemberlist,
  inviteMember,
} from "./GroupChat/CommonCode.js";
import CustomTabs from "../../commonComponent/CustomTabs";
import TabPanel from "@mui/lab/TabPanel";
import RenderGroupChat from "./GroupChat/RenderGroupChat";
import HandleRequest from "./GroupChat/HandleRequest";
import "strophejs-plugin-roster";
import { trace } from "firebase/performance";
import { sendPerfomance } from "../../index.js";
import { REACT_APP_CHAT_SERVER_URL } from "../../config/API/Api.js";

export default function HomeChat() {
  const mode = useSelector((state) => state.themeType.themeType);
  const navigate=useNavigate();
  const [messages, setMessages] = useState([]);
  const [currentUser, setcurrentUser] = useState("");
  const [loggedUser, setloggedUser] = useState("");
  // chat connection credentils
  const [username, setusername] = useState("");
  const [password, setpassword] = useState("");
  const [isdisable, setisdisable] = useState(true);
  const [isloading, setisloading] = useState(false);
  const [showconnectionpopup, setshowconnectionpopup] = useState(true);
  const [showAlluserpopup, setshowalluserpopup] = useState(false);
  const [alluserList, setalluserList] = useState([]);
  const [conn, setconn] = useState(null);
  const [groupTypeinput, setgroupTypeInput] = useState(false);

  //previouscontaclist
  const [previousContact, setpreviousContacts] = useState([]);

  // group creation
  const [showgroupChatpopup, setshowgroupchatpopup] = useState(false);
  const [chatroomName, setchatroomName] = useState("");

  //currentUserWithallmessage
  const [userMessageList, setuserMessagelist] = useState([]);
  const server = new Strophe.Connection(REACT_APP_CHAT_SERVER_URL===undefined?'':REACT_APP_CHAT_SERVER_URL);
  const presentUser = useRef(currentUser);
  //view member popup
  const [showViewmember, setshowviewMember] = useState(false);
  const [groupmemberlist, setgroupmemberlist] = useState([]);

  //tabs
  const [activeTab, setactiveTab] = useState("1");
  const updateActiveTab = (val) => {
    setcurrentUser("");
    setMessages([]);
    setactiveTab(val);
  };

  //
  const [requestTab, setrequestTab] = useState("1");
  const updateRequestTab = (val) => {
    setrequestTab(val);
  };

  const [activeTabonpopup, setactiveTabonpopup] = useState("1");
  const updateActiveTabonpopup = (val) => setactiveTabonpopup(val);

  const [Allgroups, setAllgroups] = useState([]);
  const [currentActiveGroup, setcurrentActiveGroup] = useState("");

  //notification
  const [shownotification, setshownotification] = useState(false);
  const [notificationData, setnotificationData] = useState({
    title: "",
    description: "",
    user: "",
    message: null,
  });

  const adduserclick = (elem) => {
    if (loggedUser === elem.user + "@localhost") {
      toast.warn(
        `you have logged in with ${loggedUser} account. \nChoose another account !`
      );
    } else {
      const sendtoregisteruser = addUserinChathistory(
        conn,
        elem?.user + "@localhost"
      );
      toast.success("user added successfully");
      setshowalluserpopup(false);
    }
  };

  const handleaddgroupselectmember = (elem) => {
    inviteMember(conn, elem.user + "@localhost", currentActiveGroup);
    alert("your Request has sent.");
    const d = alluserList.filter((item) => {
      return item.user !== elem.user;
    });
    setalluserList(d);

    console.log(d);
  };

  // useMemo(() => {
  //   if (conn && !showAlluserpopup) {
  //     getpreviouschatusers(conn, (value) => {
  //       setTimeout(() => {
  //         setpreviousContacts(value);
  //       }, 100);
  //     });
  //   }
  // }, [showAlluserpopup]);

  // useMemo(() => {
  //   setcurrentActiveGroup('')
  //   if (activeTab === "2" || activeTab==="3") {
  //     const groups = getAllRegisterdGroups(conn);
  //     setTimeout(() => {
  //       setAllgroups(groups);
  //     }, 300);
  //   }
  // }, [activeTab, showgroupChatpopup]);

  useMemo(() => {
    setMessages([]);
    const data = getGroupmemberlist(conn, currentActiveGroup);
    // setTimeout(() => {
    //   setgroupmemberlist(data);
    // }, 100);
  }, [currentActiveGroup]);
  const chatConnection = () => {
    const onclick = () => {
      navigate("/");
    };
    function on_connected() {
      server.addHandler(
        (message) => {
          console.log("message", message);
          const invitationRequest = message.querySelector("invite");
          const invitationRequest1 = message.getElementsByTagName("invite");
          const sender = Strophe.getBareJidFromJid(
            message.getAttribute("from")
          );
          console.log(
            invitationRequest1,
            "you get this invitation",
            invitationRequest1.length
          );
          if (invitationRequest1.length > 0) {
            const xElement = message.querySelector("x");
            const jidAttribute = xElement.getAttribute("jid");
            console.log(invitationRequest1[0]);
            console.log(sender, "this is sender");
            console.log(jidAttribute, "thiss is room jid");
            setnotificationData({
              title: `${invitationRequest1[0].innerHTML}.`,
              description: "",
              user: sender,
              roomjid: jidAttribute,
            });
            setshownotification(true);
          }
          const getincomingmsg = message.getAttribute("type");
          if (getincomingmsg === undefined || getincomingmsg !== "groupchat") {
            const receiver = Strophe.getBareJidFromJid(
              message.getAttribute("to")
            );
            const messageBody = message.querySelector("body");

            const a = message.getElementsByTagName("message")[0];
            if (a !== undefined) {
              const sender1 = a.getAttribute("from");
              const receiver1 = a.getAttribute("to");
              const resposibleMesssage =
                message.querySelector("body").textContent;
              const receivedMessage = {
                from: sender1,
                body: resposibleMesssage,
                to: receiver1,
              };
              setMessages((prevHistory) => [...prevHistory, receivedMessage]);
            } else {
              if (messageBody && sender === presentUser.current) {
                const currentmmsg = message.querySelector("body");
                if (currentmmsg) {
                  setMessages((previous) => [
                    ...previous,
                    {
                      fbrom: sender,
                      body: currentmmsg.innerHTML,
                      to: receiver,
                    },
                  ]);
                }
              }
            }
            if (messageBody && !invitationRequest) {
              setpreviousContacts((prevUsers) => {
                const fromIndex = prevUsers.findIndex(
                  (obj) => obj.user === sender && sender !== presentUser.current
                );
                if (fromIndex !== -1) {
                  const objectToMove = prevUsers.splice(fromIndex, 1)[0];
                  prevUsers.unshift(objectToMove);
                } else if (sender !== Strophe.getBareJidFromJid(server.jid)) {
                  const checkResponse = addUserinChathistory(server, sender);
                  if (checkResponse) {
                    const user = prevUsers.filter((elem) => {
                      return elem.user === sender;
                    });
                    if (user.length === 0) {
                      prevUsers.unshift({
                        user: sender,
                        msgCount: 0,
                        color: "black",
                      });
                      toast.success(
                        "you have an message received from unknown."
                      );
                    }
                  }
                }
                return prevUsers.map((user) => {
                  if (
                    user.user === sender &&
                    sender !== presentUser.current &&
                    sender !== Strophe.getBareJidFromJid(server.jid)
                  ) {
                    return { ...user, msgCount: user.msgCount + 1 };
                  } else {
                    return user;
                  }
                });
              });
            }
          }
          return true; // keep alive the message to be retrieve
        },
        null,
        "message"
      );
      server.send($pres().tree());
      return true;
    }

    const handleSubmit = (e) => {
      e.preventDefault();
      setisloading(true)
      const t=trace(sendPerfomance,'CHAT_CONNECTION_REQUEST')
      t.start();
      // server.connect(username, password, (status) => {
      //   if (status === Strophe.Status.CONNECTED) {
      //     t.stop();
      //     const id = Strophe.getBareJidFromJid(server.jid);
      //     setloggedUser(id);
      //     setisloading(false);
      //     alert("connected");
      //     setconn(server);
      //     on_connected();
      //     setshowconnectionpopup(false);
      //     console.log(server,'thsis server')
      //     getpreviouschatusers(server, (value) => {
      //       console.log("kya tum chale 1");
      //       setTimeout(() => {
      //         setpreviousContacts(value);
      //       }, 100);
      //     });
      //   } else if (status === Strophe.Status.AUTHFAIL) {
      //     toast.error("Authentication failed");
      //     navigate("/");
      //     setisloading(false);
      //   } else if (status === Strophe.Status.CONNFAIL) {
      //     toast.error("Connection failed");
      //     navigate("/");
      //     setisloading(false);
      //   } else if (status === Strophe.Status.DISCONNECTED) {
      //     toast.log("Disconnected from XMPP server");
      //     navigate("/");
      //     setisloading(false);
      //   }
      // });
      
    };
    const body = () => {
      return (
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="exampleInputEmail1">UserName:</label>
            <input
              type="text"
              className="form-control"
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
              placeholder="Enter Username"
              value={username}
              onChange={(e) => {
                setusername(e.target.value);
                if (e.target.value.length > 0 && password.length > 0) {
                  setisdisable(false);
                } else {
                  setisdisable(true);
                }
              }}
            />
          </div>
          <div className="form-group">
            <label htmlFor="exampleInputPassword1">password:</label>
            <input
              type="text"
              className="form-control"
              id="exampleInputPassword1"
              placeholder="Enter password"
              value={password}
              onChange={(e) => {
                setpassword(e.target.value);
                if (e.target.value.length > 0 && username.length > 0) {
                  setisdisable(false);
                } else {
                  setisdisable(true);
                }
              }}
            />
          </div>
          <div className="d-flex align-items-center">
            <button
              className={`btn ${isdisable ? "btn-secondary" : "btn-primary"}`}
              disabled={isdisable}
              type="submit"
            >
              {isloading ? (
                <img src={loader} alt="..." width={"24px"} height={"24px"} />
              ) : (
                "Submit"
              )}
            </button>
          </div>
        </form>
      );
    };
    return (
      <CustomPopup
        onclick={onclick}
        size="lg"
        showpopup={showconnectionpopup}
        center={true}
        popHeader="please login for chat"
        classHeader="font-weight-bold"
        body={body()}
      />
    );
  };

  const alluserspopup = () => {
    const body = () => {
      return (
        <div
          className="list-group overflow-auto"
          style={{ maxHeight: "75vh" }}
          id="list-tab"
          role="tablist"
        >
          {alluserList.length === 0 && (
            <div>
              <h3 className="text-center">please wait...</h3>
            </div>
          )}
          {alluserList.map((elem, index) => {
            return (
              <div
                className="d-flex justify-content-between list-group-item list-group-item-action align-items-center pointer "
                key={index}
              >
                <div className="d-flex ">
                  <img
                    src={
                      "https://images.unsplash.com/photo-1568602471122-7832951cc4c5?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTB8fHVzZXJ8ZW58MHx8MHx8fDA%3D&auto=format&fit=crop&w=500&q=60"
                    }
                    alt="..."
                    className="rounded rounded-circle mr-1  "
                    width={"50px"}
                    height={"50px"}
                  />
                  <span className={`text-primary`}>{elem.user}@localhost</span>
                </div>
                {loggedUser !== elem.user + "@localhost" ? (
                  <span
                    className="text-primary "
                    onClick={() => {
                      if (activeTab === "1") {
                        adduserclick(elem);
                      } else {
                        handleaddgroupselectmember(elem);
                      }
                    }}
                  >
                    {activeTab === "1" ? "Add" : "Invite"}
                  </span>
                ) : (
                  <span className="text-primary ">you</span>
                )}
              </div>
            );
          })}
        </div>
      );
    };
    return (
      <CustomPopup
        onclick={() => {
          setshowalluserpopup(false);
        }}
        size="lg"
        showpopup={showAlluserpopup}
        center={true}
        popHeader="All Users"
        classBody="p-0"
        classHeader="font-weight-bold"
        body={body()}
      />
    );
  };

  useMemo(async () => {
    if (currentUser !== "") {
      setpreviousContacts((prevUsers) => {
        return prevUsers.map((user) => {
          console.log(user);
          console.log("currentUser", currentUser);
          if (user.user === currentUser) {
            return { ...user, msgCount: 0 };
          } else {
            return user;
          }
        });
      });
      presentUser.current = currentUser;
      const data = await fetchChatHistory(conn, currentUser);
    }
  }, [currentUser]);

  function emptyListMessage(add) {
    setMessages([]);
  }

  function updateSendmessage(msg) {
    setMessages((previous) => [...previous, msg]);
  }

  const handleCreateGroup = () => {
    console.log(chatroomName, "chat room name");
    if (groupTypeinput) {
      createPrivatechatRoom(conn, chatroomName, loggedUser);
      toast.success("Group create Successfully");
      setchatroomName("");
      setshowgroupchatpopup(false);
    } else {
      const groupchat = CreateChatRoom(conn, chatroomName, loggedUser);
      toast.success("Group create Successfully");
      setchatroomName("");
      setshowgroupchatpopup(false);
    }
  };

  const gropuchatpopup = () => {
    const body = () => {
      return (
        <div
          className={`list-group overflow-auto ${
            mode === "lightMode" ? "bg-light text-dark" : "bg-black text-white"
          }`}
          style={{ maxHeight: "75vh" }}
          id="list-tab"
          role="tablist"
        >
          <div className="form-group p-3 pb-0">
            <label htmlFor="exampleInputPassword1">Group Name:</label>
            <input
              type="text"
              value={chatroomName}
              onChange={(e) => {
                setchatroomName(e.target.value);
              }}
              className="form-control"
              id="exampleInputPassword1"
              placeholder="enter create room name"
            />
          </div>

          <div className="px-4">
            <label htmlFor="groupType">Group Type:</label>
            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                value={groupTypeinput}
                onClick={() => {
                  setgroupTypeInput(!groupTypeinput);
                }}
                id="groupType"
              />
              <label className="form-check-label" htmlFor="groupType">
                Private
              </label>
            </div>

            <button
              disabled={chatroomName.length === 0}
              className={`btn ${
                chatroomName.length === 0 ? "btn-secondary" : "btn-primary"
              } my-2`}
              onClick={handleCreateGroup}
            >
              Submit
            </button>
          </div>
        </div>
      );
    };
    return (
      <CustomPopup
        onclick={() => {
          setshowgroupchatpopup(false);
        }}
        size="lg"
        showpopup={showgroupChatpopup}
        center={true}
        popHeader="Create Group ChatRoom"
        classBody="p-0"
        classHeader="font-weight-bold"
        body={body()}
      />
    );
  };

  const ViewMemberpopup = () => {
    const body = () => {
      return (
        <div
          className="list-group overflow-auto"
          style={{ maxHeight: "75vh" }}
          id="list-tab"
          role="tablist"
        >
          {groupmemberlist.length === 0 && (
            <div>
              <h3 className="text-center">There is no registered member</h3>
            </div>
          )}
          {groupmemberlist.map((elem, index) => {
            return (
              <div
                className="d-flex list-group-item list-group-item-action align-items-center pointer justify-content-between "
                key={index}
                onClick={() => {
                  // adduserclick(elem);
                }}
              >
          <div>
          <img
                  src={
                    "https://images.unsplash.com/photo-1568602471122-7832951cc4c5?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTB8fHVzZXJ8ZW58MHx8MHx8fDA%3D&auto=format&fit=crop&w=500&q=60"
                  }
                  alt="..."
                  className="rounded rounded-circle mr-1  "
                  width={"50px"}
                  height={"50px"}
                />
                <a
                  className={``}
                  data-toggle="list"
                  href="#"
                  role="tab"
                  aria-controls="home"
                >
                  {elem.user}
                </a>
          </div>
          <span className="text-primary">
            {elem?.type==='owner'?elem.type+'/admin':elem.type}
          </span>
              </div>
            );
          })}
        </div>
      );
    };
    return (
      <CustomPopup
        onclick={() => {
          setshowviewMember(false);
        }}
        size="lg"
        showpopup={showViewmember}
        center={true}
        popHeader="Group Member"
        classBody="p-0"
        classHeader="font-weight-bold"
        body={body()}
      />
    );
  };

  const handleViewMember = () => {
    if (currentActiveGroup === "") {
      toast.warn(
        "! you have not select any group or you are not registerd in any group."
      );
    } else {
      setshowviewMember(true);
    }
  };

  const notificationpopup=() => {
    const body = () => {
      return (
        <div
          className="list-group overflow-auto"
          style={{ maxHeight: "75vh" }}
          id="list-tab"
          role="tablist"
        >
        <p className="px-4 w-75 py-2">{notificationData.title}</p>
        </div>
      );
    };
    const footerBody=()=>{
      return <div>
        <button className="btn btn-primary mx-1" onClick={()=>{acceptInvitation(notificationData,conn);setshownotification(false);}}>Accept</button>
        <button className="btn btn-danger" onClick={()=>{declineInvitation(notificationData,conn);setshownotification(false)}}>Decline</button>
      </div>
    }

    return (
      <CustomPopup
        onclick={() => {
          setshownotification(false);
        }}
        size="lg"
        showpopup={shownotification}
        center={true}
        popHeader="Group Invitation"
        classBody="p-0"
        classHeader="font-weight-bold"
        body={body()}
        footerBody={footerBody()}
      />
    );
  };

  return (
    <div
      className={`row ${
        mode === "lightMode" ? "bg-light text-dark" : "bg-black text-white"
      }`}
    >
      <div
        className="col-12 d-flex clearfix"
        style={{ height: "83vh", maxHeight: "83vh" }}
      >
        <div
          className=" border-right border-secondary overflow overflow-auto"
          style={{ width: "30vw" }}
        >
          <div
            className="d-flex justify-content-between sticky-top"
            style={{ backgroundColor: mode === "lightMode" && "#e6e0e0" }}
          >
            <div className="p-2 pb-0 d-flex align-items-center">
              <img
                className="mr-3 rounded rounded-circle"
                src={
                  "https://images.unsplash.com/photo-1552058544-f2b08422138a?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Nnx8cGVyc29ufGVufDB8fDB8fHww&auto=format&fit=crop&w=500&q=60"
                }
                alt="..."
                loading="lazy"
                width={"49px"}
                height={"49px"}
              />
              <h5>{loggedUser}</h5>
            </div>
            <div className="d-flex align-items-center">
              <span
                className="mx-2 pointer"
                onClick={() => {
                  setshowgroupchatpopup(true);
                }}
              >
                <AiOutlineUsergroupAdd size={35} className="pointer" />
              </span>
              <span className="mx-2 pointer">
                <AiOutlineUndo size={35} className="" />
              </span>
              <div className="dropdown">
                <span
                  className="mx-2 pointer dropdown-toggle"
                  id="dropdownMenuButton"
                  data-bs-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <AiOutlineMore size={35} className="" />
                </span>
                <div
                  className="dropdown-menu"
                  aria-labelledby="dropdownMenuButton"
                >
                  <button
                    className="dropdown-item "
                    onClick={() => {
                      window.location.reload();
                    }}
                  >
                    Logout
                  </button>
                </div>
              </div>
            </div>
          </div>
          <CustomTabs
            initialComponent="1"
            tablist={[
              { label: "Chat", value: "1" },
              { label: "PublicGroups", value: "2" },
              { label: "PrivateGroups", value: "3" },
              { label: "Request", value: "4" },
            ]}
            updateTab={updateActiveTab}
            activeTab={activeTab}
            mode={mode}
          >
            <TabPanel value="1" className="p-0">
              <div className="">
                {previousContact.length > 0 ? (
                  previousContact.map((elem, index) => {
                    return (
                      <div
                        className={`border-bottom border-top pointer border-secondary nav-item m-0 p-0 ${
                          currentUser === elem.user
                            ? mode === "lightMode"
                              ? "ActiveUserinLightMode"
                              : "ActiveUserinDarkMode"
                            : ""
                        } `}
                        key={index}
                        onClick={() => {
                          setcurrentUser(elem.user);
                          setMessages([]);
                        }}
                      >
                        <div className="media p-2  pb-0">
                          <img
                            className="mr-3 rounded rounded-circle"
                            src={
                              "https://images.unsplash.com/photo-1552058544-f2b08422138a?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Nnx8cGVyc29ufGVufDB8fDB8fHww&auto=format&fit=crop&w=500&q=60"
                            }
                            alt="..."
                            loading="lazy"
                            width={"49px"}
                            height={"49px"}
                          />
                          <div className="media-body d-flex align-items-center  justify-content-between">
                            <div>
                              <h5 className="mt-0">
                                {elem.user.slice(0, elem.user.indexOf("@"))}
                              </h5>
                              <p className="m-0">last seen today at 5:53 pm</p>
                            </div>
                            {currentUser !== elem.user
                              ? elem.msgCount > 0 && (
                                  <span
                                    className="rounded rounded-circle bg-black  text-white text-center"
                                    style={{ width: "25px", height: "25px" }}
                                  >
                                    {elem?.msgCount}
                                  </span>
                                )
                              : null}
                          </div>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <p>"There is no user"</p>
                )}
              </div>
            </TabPanel>
            <TabPanel value="2" className="p-0">
              <div className="">
                {Allgroups.length > 0 ? (
                  Allgroups.map((elem, index) => {
                    return (
                      elem.type === "public" && (
                        <div
                          className={`border-bottom border-top pointer border-secondary nav-item m-0 p-0 ${
                            currentActiveGroup === elem.jid
                              ? mode === "lightMode"
                                ? "ActiveUserinLightMode"
                                : "ActiveUserinDarkMode"
                              : ""
                          } `}
                          key={index}
                          onClick={(e) => {
                            setcurrentActiveGroup(elem.jid);
                          }}
                        >
                          <div className="media p-2  pb-0">
                            <img
                              className="mr-3 rounded rounded-circle"
                              src={
                                "https://images.unsplash.com/photo-1552058544-f2b08422138a?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Nnx8cGVyc29ufGVufDB8fDB8fHww&auto=format&fit=crop&w=500&q=60"
                              }
                              alt="..."
                              loading="lazy"
                              width={"49px"}
                              height={"49px"}
                            />
                            <div className="media-body d-flex align-items-center  justify-content-between">
                              <div>
                                <h5 className="mt-0">{elem?.name}</h5>
                                <p className="m-0">
                                  last seen today at 5:53 pm
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      )
                    );
                  })
                ) : (
                  <p>"There is no Groups"</p>
                )}
              </div>
            </TabPanel>
            <TabPanel value="3" className="p-0">
              <div className="">
                {Allgroups.length > 0 ? (
                  Allgroups.map((elem, index) => {
                    return (
                      elem.type === "private" && (
                        <div
                          className={`border-bottom border-top pointer border-secondary nav-item m-0 p-0 ${
                            currentActiveGroup === elem.jid
                              ? mode === "lightMode"
                                ? "ActiveUserinLightMode"
                                : "ActiveUserinDarkMode"
                              : ""
                          } `}
                          key={index}
                          onClick={(e) => {
                            setcurrentActiveGroup(elem.jid);
                          }}
                        >
                          <div className="media p-2  pb-0">
                            <img
                              className="mr-3 rounded rounded-circle"
                              src={
                                "https://images.unsplash.com/photo-1552058544-f2b08422138a?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Nnx8cGVyc29ufGVufDB8fDB8fHww&auto=format&fit=crop&w=500&q=60"
                              }
                              alt="..."
                              loading="lazy"
                              width={"49px"}
                              height={"49px"}
                            />
                            <div className="media-body d-flex align-items-center  justify-content-between">
                              <div>
                                <h5 className="mt-0">{elem?.name}</h5>
                                <p className="m-0">
                                  last seen today at 5:53 pm
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      )
                    );
                  })
                ) : (
                  <p>"There is no Groups"</p>
                )}
              </div>
            </TabPanel>
            <TabPanel value="4" className="p-0">
              <CustomTabs
                initialComponent="1"
                tablist={[
                  { label: "New Request", value: "1" },
                  { label: "History", value: "2" },
                ]}
                updateTab={updateRequestTab}
                activeTab={requestTab}
              >
                <TabPanel value="1" className="p-0">
                  <HandleRequest connection={conn} />
                </TabPanel>
              </CustomTabs>
            </TabPanel>
          </CustomTabs>
        </div>
        <div className="w-75">
          {Object.keys(currentUser).length > 0 && activeTab === "1" && (
            <div
              className="d-flex justify-content-between border-bottom border-secondary"
              style={{ backgroundColor: mode === "lightMode" && "#e6e0e0" }}
            >
              <div className="media p-1 pointer ">
                <img
                  className="mr-3 rounded rounded-circle"
                  src={
                    "https://cdn.pixabay.com/photo/2016/08/31/11/54/icon-1633249_640.png"
                  }
                  alt="..."
                  loading="lazy"
                  width={"49px"}
                  height={"49px"}
                />
                <div className="media-body  ">
                  <h5 className="mt-0">
                    {Object.keys(currentUser).length > 0
                      ? currentUser
                      : "unKnown"}
                  </h5>
                  <p className="m-0 text-mute">last seen today at 5:55 pm</p>
                </div>
              </div>
              <div className="d-flex align-items-center">
                <AiOutlineSearch size={35} className="mx-1" />
                <AiOutlineMore size={35} className="mx-1" />
              </div>
            </div>
          )}
          <div className="d-flex mx-3 p-0 ">
            <button
              className="btn btn-primary"
              onClick={() => {
                // history.goBack();
                const a = "ramesh is a good boy";
                console.log(a.replace(/\s/g, "_"));

                // const rosterRemoveIQ = $iq({
                //   type: "set",
                //   id: "ah382g67",
                // })
                //   .c("query", { xmlns: "jabber:iq:roster", ver: "ver34" })
                //   .c("item", { jid: "u4@localhost", subscription: "remove" });
                // conn.sendIQ(rosterRemoveIQ, (response) => {
                //   console.log(response);
                // });
              }}
            >
              Back
            </button>
            {activeTab === "3" && (
              <button
                className="btn btn-warning ml-auto"
                onClick={handleViewMember}
              >
                View Member
              </button>
            )}

            {activeTab === "1" ||
            (activeTab === "3" &&
              groupmemberlist.filter((elem) => {
                return elem.user === loggedUser;
              }).length > 0) ? (
              <AiFillPlusCircle
                size={40}
                className={`addUser ${activeTab === "1" && "ml-auto"}`}
                onClick={async () => {
                  const a = [];
                  const data = await getallUser();
                  console.log('kya yahi tab hai')

                  if (activeTab === "1") {
                    setshowalluserpopup(true);
                    if(data){
                      setalluserList(data);
                    for (let elem in data) {
                      data[elem].message = [];
                      a.push(data[elem]);
                    }
                    setuserMessagelist(a);
                    }
                  } else if (activeTab === "3") {
                    if (currentActiveGroup === "") {
                      toast.warn(
                        "! You have not select any group in list or you are not registered in any group"
                      );
                    } else {
                      const users = getmemberlist(
                        currentActiveGroup,
                        data,
                        groupmemberlist
                      );
                      // setTimeout(() => {
                      //   console.log(users, "this is users");
                      //   setshowalluserpopup(true);
                      //   setalluserList(users);
                      //   for (let elem in users) {
                      //     users[elem].message = [];
                      //     a.push(data[elem]);
                      //   }
                      //   setuserMessagelist(a);
                      // }, 100);
                    }
                  }
                }}
              />
            ) : null}
          </div>
          {Object.keys(currentUser).length === 0 && activeTab === "1" ? (
            <div className="d-flex justify-content-center align-items-center flex-column overflow overflow-auto">
              <h1>Welcome to Xivtech.io Chat room </h1>
            </div>
          ) : (
            <RenderChat
              messages={messages}
              currentUser={currentUser}
              connection={conn}
              mode={mode}
              updateSendmessage={updateSendmessage}
              emptyListMessage={emptyListMessage}
            />
          )}
          {activeTab === "2" && currentActiveGroup !== "" && (
            <RenderGroupChat
              connection={conn}
              mode={mode}
              currentActiveGroup={currentActiveGroup}
              server={server}
              groupmemberlist={groupmemberlist}
            />
          )}

          {activeTab === "3" && currentActiveGroup !== ""
            ? groupmemberlist.filter((elem) => {
                return elem.user === Strophe.getBareJidFromJid(conn.jid);
              }).length > 0 && (
                <RenderGroupChat
                  connection={conn}
                  mode={mode}
                  currentActiveGroup={currentActiveGroup}
                  server={server}
                  groupmemberlist={groupmemberlist}
                />
              )
            : null}
        </div>
      </div>
      {chatConnection()}
      {alluserspopup()}
      {gropuchatpopup()}
      {ViewMemberpopup()}
      {notificationpopup()}
      <ToastContainer
        position="top-center"
        autoClose={3000}
        closeOnClick
        theme={mode === "lightMode" ? "light" : "dark"}
      />
    </div>
  );
}
