export const getEnvVariable = (key) => {
    if (window.env && window.env[key] !== undefined) {
      return window.env[key];
    }

  const localEnv = JSON.parse(localStorage.getItem('env') || '{}');
    if (localEnv[key] !== undefined) {
        return localEnv[key];
    }

    return process.env[key] 
  };
  export const thousandsConverter = (value) => {
    // Handle the case where val is 'null' (string)
    if (value === 'null') {return value}
    // Convert the value to a number
      value = Number(Math.ceil(value));
    // If val is greater than or equal to 1000, convert to thousands and add 'K'
    if (value >= 1e9) {
      // Format as Billion (B)
      return (value / 1e9).toFixed(2) + 'B';
    } else if (value >= 1e6) {
      // Format as Million (M)
      return (value / 1e6).toFixed(2) + 'M';
    } else if (value >= 1e3) {
      // Format as Thousand (K)
      return (value / 1e3).toFixed(2) + 'K';
    } else {
      // Return the number as it is, for values less than 1 thousand
      return value.toString();
    }
  }
  export const checkValueexistornot=(value,number=false)=>{
    if(value===undefined||value===''||value===null||value==='null'){
      return number?0:''
    }else{
      return value;
    }
  }