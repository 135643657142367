import moment from 'moment';
import React, { useEffect,useState} from 'react'
import { useSelector } from 'react-redux';

export default function AssetHistoryScreen(props) {
  const mode = useSelector((state) => state.themeType.themeType);
  const [finalData,setfinalData]=useState([]);
    useEffect(()=>{
        const filterData=props.data[props.tabIndex].data;
        const fdata=filterData[Object.keys(filterData)[0]];
        setfinalData(fdata);
        console.log(filterData,'this is filterdata',fdata);
    },[])

  return (
    <div className={`${
        mode === "lightMode" ? "bg-light text-dark" : "bg-dark text-white"
      } m-0 p-3 border-top border-white `}>
    <h4 className='text-center'>{finalData.length===0&& "No Data Found"}</h4>
    {
        finalData.map((elem=>{
            return (
                <div className='rounded ' style={{border:"2px solid black",marginTop:"10px",padding:"5px"}}>
                  <p className='m-1'><span className='font-weight-bold'>Amount:</span>  {elem?.amount}</p>
                  <p className='m-1'><span className='font-weight-bold'>From:</span>  {elem?.from}</p>
                  <p className='m-1'><span className='font-weight-bold'>To:</span>   {elem?.to}</p>
                   <p className='m-1'><span className='font-weight-bold'>Created By: </span> {moment(elem?.createdAt).format('DD/MM/YYYY hh:mm')}</p>
                </div>
            )
        }))
    }


    </div>
  )
}
