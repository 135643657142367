import React, { useMemo, useState } from "react";
import {RouterProvider, createBrowserRouter } from "react-router-dom";
import SideBar from "./Sidebar";
import { FaAlignJustify } from "react-icons/fa";
import "./Dashboard.css";
import Home from "./DashboardScreen/HomeScreen/Home";
import Error from "./DashboardScreen/HomeScreen/Error";
import NftAddressCollection from './DashboardScreen/HomeScreen/NftAddressCollection'
import { useSelector } from "react-redux";


export default function Index() {
  const mode=useSelector(state=>state.themeType.themeType);
  const [width, setwidth] = useState(window.innerWidth);
  const [breakPoint, setbreakPoint] = useState(false);
  const routes = [
    { path: "/profile/dashboard",  element: <Error mode={mode}/> },
    { path: "/profile/dashboard/:address", element: <Home /> },
    { path: "/profile/dashboard/:address/NFTs", element: <NftAddressCollection backButton={true}/> },
  ];
  const renderRoutes=createBrowserRouter(routes);
  useMemo(() => {
    window.addEventListener("resize", () => {
      setwidth(window.innerWidth);
      if (width < 400) {
        setbreakPoint(true);
      } else {
        setbreakPoint(false);
      }
    });
  }, [window.innerWidth]);
  return (
    <div className="d-flex">
      <div className="d-flex " >
      {!breakPoint &&  <SideBar breakpoint={breakPoint} />}
        {width < 400 && (
          <div
            onClick={() => {
              setbreakPoint(!breakPoint);
            }}  
          >
            <FaAlignJustify size={20} />
          </div>
        )}
      </div>
      <RouterProvider router={renderRoutes}>
      </RouterProvider>
      {/* <Switch>
        <Route exact path={`/profile/dashboard`}>
          <Error mode={mode}/>
        </Route>
        <Route exact path={`/profile/dashboard/:address`}>
         <Home/>
        </Route>

        <Route exact path={`/profile/dashboard/:address/NFTs`}>
          <NftAddressCollection backButton={true}/>
        </Route>
      </Switch> */}
    </div>
  )
}



