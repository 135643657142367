import React from "react";
import { useSelector } from "react-redux";
import { RiBarChartFill } from "react-icons/ri";
import { FaThumbsUp, FaThumbsDown } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { checkValueexistornot } from "../utils/utility/Utility";

export default function CustomCardList(props) {
  const mode = useSelector((state) => state.themeType.themeType);
  const navigate=useNavigate();
  const isloading = true;
  const handleClick = (elem) => {
    navigate(`/assets/${elem?.asset_response[0].contract_id}:${checkValueexistornot(elem?.asset_response[0].token_id)}/overview`,{state:{
      userData: elem?.asset_response[0],
      blockchain:elem?.asset_response[0].blockchain
    }});
  };

  return (
    <div
      className={`d-flex flex-wrap   p-0 ${props.containerclassName}`}
      style={{ maxHeight: "90vh",overflowY:'auto' }}
      id="collectionContainer"
    >
      {props.datalist.map((elem, index) => {
        return (
          <div
            className={`card customCard mr-2 ${
              props.showfilter ? "ml-1" : "ml-3"
            }  mt-2  pointer rounded ${
              mode === "lightMode"
                ? "bg-light"
                : "bg-black border border-secondary"
            }`}
            style={{ width: props.showfilter ? "170px" : "180px" }}
            key={index}
            onClick={() => {
              handleClick(elem);
            }}
          >
            <img
              alt={"..."}
              width={"184x"}
              height={"184px"}
              loading="lazy"
              src={elem?.asset_response[0].image_url} // use normal <img> attributes as props
              style={{ borderRadius: "1em" }}
            />

            <div className="card-body m-0 p-2 pl-3">
              <p
                className="mb-1"
                style={{
                  fontWeight: "bold",
                  fontSize: "14px",
                  lineHeight: "16px",
                }}
              >
                {elem?.asset_response[0]?.name}
              </p>
              <div>
                <p
                  className="mb-0"
                  style={{
                    color: "#929292",
                    fontSize: "12px",
                    lineHeight: "16px",
                  }}
                >
                  Price
                </p>
                <div className="d-flex justify-content-between">
                  <span
                    style={{
                      backgroundColor:
                        mode === "lightMode" ? "#f2f2f2" : "black",
                      fontWeight: "600",
                      color: mode === "lightMode" ? "black" : "white",
                    }}
                  >
                    {" "}
                    <RiBarChartFill size={10} className="mx-2" />
                    {elem?.asset_response[0].price}
                  </span>
                  <span
                    style={{
                      backgroundColor:
                        mode === "lightMode" ? "#f2f2f2" : "black",
                      fontWeight: "600",
                      color: mode === "lightMode" ? "black" : "white",
                    }}
                  >
                    {" "}
                    <FaThumbsUp size={10} className="mx-2" />
                    {elem?.asset_response[0].like}
                  </span>
                  <span
                    style={{
                      backgroundColor:
                        mode === "lightMode" ? "#f2f2f2" : "black",
                      fontWeight: "600",
                      color: mode === "lightMode" ? "black" : "white",
                    }}
                  >
                    {" "}
                    <FaThumbsDown size={10} className="mx-2" />
                    {elem?.asset_response[0].dislike}
                  </span>
                  <span
                    style={{
                      backgroundColor:
                        mode === "lightMode" ? "#f2f2f2" : "black",
                      fontWeight: "600",
                      color: mode === "lightMode" ? "black" : "white",
                    }}
                  >
                    {" "}
                    <RiBarChartFill size={10} className="mx-2" />
                    {elem?.asset_response[0].volume}
                  </span>
                </div>
              </div>
            </div>
          </div>
        );
      })}
     
      {/* {props.isloading &&
        Array.from({ length: 10 }).map((_, index) => (
          <div key={index} className="card">
            <Skeleton height="184px" width="184px" />
            <div className="card-body">
              <Skeleton count={2} />
            </div>
          </div>
        ))}
      {props.isloading &&
        props.currentpage > 1 &&
        Array.from({ length: 10 }).map((_, index) => (
          <div key={index} className="card">
            <Skeleton height="184px" width="184px" />
            <div className="card-body">
              <Skeleton count={2} />
            </div>
          </div>
        ))} */}
    </div>
  );
}
