import jwt_decode from "jwt-decode";

export const decodeJWT = (token) => {
    var decoded = jwt_decode(token);
    console.log(decoded)
    return decoded;

  };
 export default function handleCookie(name, value, daysToExpire) {
    const expirationDate = new Date();
    expirationDate.setDate(expirationDate.getDate() + daysToExpire);

    const cookieValue = `${name}=${encodeURIComponent(value)}; expires=${expirationDate.toUTCString()}; path=/`;

    document.cookie = cookieValue;
}

