import React,{useState} from "react";
import Select from "react-select";
import { FaCheck } from "react-icons/fa";
import ReactGA from 'react-ga4';
import { useSelector } from "react-redux";
export default function CustomSelect(props) {
 const {options,indicatorStyle,updatevalue,minwidth,className}=props;
 const mode = useSelector((state) => state.themeType.themeType);
 const [selectedValue, setSelectedValue] = useState(options!==undefined?options[0].value:'');
 const [isOpen, setIsOpen] = useState(false);

 const customStyles = {
    option: (provided, state) => ({
      ...provided,
      borderBottom: "1px solid #ccc",
      background:mode==="lightMode"?"#f5f5f5":'black'
      // color: state.isSelected ? "#fff" : "#333", // Selected text color
      // background: state.isSelected ? "#007bff" : "#fff", // Selected background color
    }),
    control: (provided) => ({
      ...provided,
      border: "1px solid #1b1b1b",
      borderRadius: "4px",
      cursor: "pointer",
      minWidth:minwidth,
      height: "40px",
      color:mode==="lightMode"?'black':"white",
      background:mode==="lightMode"?"f5f5f5":'#1b1b1b'
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "#black", // Selected text color
      fontFamily:'inherit',
      fontSize:'13px',
    }),
    indicatorSeparator: () => ({ display: "none" }), // Remove separator
    dropdownIndicator: (provided) => ({
      ...provided,
      // color:mode==="lightMode"?"black":"white", // Dropdown icon color
      fontSize: "1px",
      svg: {
        height: "15px", // Adjust the size of the SVG icon
        width: "15px", // Adjust the size of the SVG icon
      },
      transition: 'transform 0.7s', // Add transition for smooth rotation
      transform: isOpen ? 'rotate(180deg)' : 'rotate(0deg)', 
    }),
    menuList: (base) => ({
      ...base,
      background:mode==="lightMode"?"#f5f5f5":'black',
      overFlow:'hidden',
      minWidth:minwidth,
      "::-webkit-scrollbar": {
        width: "4px",
        height: "0px",
      },
      "::-webkit-scrollbar-track": {
        background: "#f1f1f1",
      },
      "::-webkit-scrollbar-thumb": {
        background: "#888",
      },
      "::-webkit-scrollbar-thumb:hover": {
        background: "#555",
      },
    }),
  };
  const CustomOption = ({ innerProps, innerRef, data }) => (
    <div
      {...innerProps}
      ref={innerRef}
      className="d-flex justify-content-between  pointer-cursor "
      style={{padding:'14px 16px',minWidth:minwidth,}}
    >
      <span className="mr-2" style={{fontFamily:'inherit',fontWeight:'500',cursor:'pointer',fontSize:"14px"}}>{data.label}</span>
      {selectedValue === data.value && (
        <FaCheck size={20} color={mode==="lightMode"?"black":'white' }style={{ float: "right" }} />
      )}
    </div>
  );
 return (
    <Select
      options={options}
      components={{ Option: CustomOption }} // Use custom option component
      value={options.find((option) => option.value === selectedValue)}
      onChange={(selectedOption) =>{
        ReactGA.event({
          category: 'clicked on select input ',
          action:'clicked collection',
          label: selectedOption?.value,
          nonInteraction:false,
          value:selectedOption?.value
        })
         setSelectedValue(selectedOption.value)
         updatevalue(selectedOption.value);
        }}
      styles={customStyles} // Apply custom styles
      className={`scrollbar ${className}`}
      onMenuOpen={() => setIsOpen(true)} // Set isOpen to true when menu is opened
      onMenuClose={() => setIsOpen(false)}
      classNamePrefix="select"
    />
  );
}
