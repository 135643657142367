import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import Loader, { Loader2 } from "./Loader";
import { useSelector } from "react-redux";
import { FaChevronRight, FaChevronLeft } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { checkValueexistornot } from "../utils/utility/Utility";
import "./custom.css"
import CustomShimmer from "./CustomShimmer";
export default function CustomCarousel2(props) {
  const mode = useSelector((state) => state.themeType.themeType);
  const navigate=useNavigate();
  const {isLoading,data}=props;
  const shimmerStyle={
    width:'80px',
    height:"35px",
    borderRadius:"1rem"
  }
  return (
  <div className="py-5">
      <div className="text-center my-2">
        <span className="font-weight-bold font-24">{props.title}</span>
      </div>
    <div className="">
      {<div className="d-flex align-items-center justify-space-between">
           <div className="carousal_Icon pointer arrowleftIcon" onClick={props.handlePrevious} style={{ opacity: props.currentPage === 1 ? "0.1" : "0.9" }}>
            <FaChevronLeft size={12} color={mode==="lightMode"?'black':"black"} />
          </div>
            <div className="row overflow-auto scrollbar flex-nowrap w-100" style={{ overflowX: "auto", whiteSpace: "nowrap" }}>
            {(isLoading?[...Array(5).keys()]:data).map((item, index) => {
                return (
                  <div key={index} className="col-lg-3 col-md-4 col-sm-6 d-inline-block" style={{float:'none'}}>
                      <div
                    className="card mx-1"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      navigate(`/assets/${item?.contract_id}:${checkValueexistornot(item?.token_id)}/overview`, {state:{
                        userData: item,
                        blockchain:item?.blockchain
                      }});
                    }}
                  >
                    <div className="more-card-container">
                       { isLoading?<CustomShimmer className="w-100" style={{...{},height:"214px"}}/>:<img
                          className="card-img-top similarAsset"
                          src={item.image_url}
                          alt="Card image cap "
                          loading="lazy"
                        />}
                    </div>
                    <div
                      className={`card-body p-2 ${
                        mode === "lightMode"
                          ? "bg-light text-dark"
                          : "bg-dark text-white"
                      }`}
                    >
                      <p className="text-secondary mb-1 font-14">{isLoading?<CustomShimmer className="w-75"  style={{}}/>: item.title}</p>
                      <h5 className="card-title font-weight-bold">
                        {isLoading?<CustomShimmer  className="w-100" style={{}}/>:item.name !== undefined
                          ? item.name.length > 15
                            ? item.name.slice(0, 15) + "..."
                            : item.name
                          : null}
                      </h5>
                      <div className="d-flex justify-content-between align-items-center">
                      {isLoading?<CustomShimmer style={shimmerStyle}/>:<div className="mx-1">
                          <span className="text-secondary d-block font-12">
                            Price
                          </span>
                          <span className="font-12">{item.price} ETH</span>
                        </div>}
                        {isLoading?<CustomShimmer style={shimmerStyle}/>:<div className="mx-1">
                          <span className="text-secondary d-block font-12">
                            Highest bid
                          </span>
                          <span className="font-12">No bid yet</span>
                        </div>}
                      </div>
                    </div>
                  </div>
                  </div>
                );
              })}
            </div>
             <div
          className="carousal_Icon carousal_right_icon pointer arrowRightIcon"
          onClick={props.handleNext}
        >
          <FaChevronRight size={12} color={mode==="lightMode"?'black':"black"}/>
             </div>
        </div>}
      <div className="d-flex flex-column align-items-center">
        {!isLoading && data.length == 0 && (
          <p className="text-center my-1 font-weight-bold">No data Found</p>
        )}
      </div>
    </div>
  </div>
    
  );
}
