import React, { useState, useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useSelector } from "react-redux";
import InfiniteScroll from "react-infinite-scroll-component";
import Axios, { BaseUrl } from "../../config/API/Api";
import { Loader2 } from "../../commonComponent/Loader";
import CustomSelect from "../../commonComponent/CustomSelect";
import Select from "react-select";
import { tracer } from "../../tracing";
import axios from "axios";
import { toast } from "react-toastify";
import {
  FaRegStar,
  FaCheck,
} from "react-icons/fa";
export default function LeaderBoardScreen() {
  const [windowWidth, setwindowWidth] = useState(window.innerWidth);
  const mode = useSelector((state) => state.themeType.themeType);
  const token = localStorage.getItem("accessToken");
  const [page, setpage] = useState(1);
  const [size, setsize] = useState(50);
  const [rowperPage, setrowperPage] = useState(50);
  const [loading, setisloading] = useState(true);
  const [duration, setduration] = useState("1");
  const [activeChain, setactiveChain] = useState("all");
  const [sortBy, setsortBy] = useState("volume");
  const [order, setorder] = useState("desc");
  const [isOpen, setIsOpen] = useState(false);
  const [chainlist, setchainlist] = useState([]);
  const rowperpageoption = [
    { id: 1, label: "50 Items", value: "50" },
    { id: 2, label: "80 Items", value: "80" },
    { id: 3, label: "100 Items", value: "100" },
    { id: 4, label: "120 Items", value: "120" },
    { id: 5, label: "150 Items", value: "150" },
  ];
  const intervalList = [
    { id: 1, label: "1 Day", value: "1" },
    { id: 2, label: "1 Week", value: "7" },
    { id: 3, label: "1 Month", value: "30" },
  ];
  const sortByOptions = [
    { id: 1, label: "Volume", value: "volume" },
    { id: 2, label: "Likes", value: "likes" },
    { id: 3, label: "DisLikes", value: "dislikes" },
  ];
  const updateSortBy = (val) => {
    setdata([]);
    setsortBy(val);
  };
  const updaterowPerpage = (val) => {
    setdata([]);
    setrowperPage(Number(val));
  };
  const updateorder = (val) => {
    setdata([]);
    setorder(val);
  };
  const sortOrderOptions = [
    { id: 2, label: "Descending", value: "desc" },
    { id: 1, label: "ascending", value: "asc" },
  ];
  const updateduration = (val) => {
    setduration(val);
    setdata([]);
  };
  const [data, setdata] = useState([]);

  const sectionTablescreen = () => {
    return (
      <div className="row">
        <div className="col" id="leaderBoardTable">
          <TableContainer
            component={Paper}
            className="scrollbar "
            style={{ boxShadow: "none" }}
          >
            <Table
              sx={{ minWidth: 650 }}
              aria-label="simple table"
              style={{ borderWidth: "0px" }}
              className={`${
                mode === "lightMode"
                  ? "bg-white text-dark"
                  : "bg-dark text-white"
              }`}
            >
              <TableHead className="">
                <TableRow>
                  <TableCell
                    className={`py-1 pr-0 tablerowsTitle ${
                      mode === "lightMode" ? "" : "text-white"
                    }`}
                  >
                    #
                  </TableCell>
                  <TableCell
                    align="left"
                    className={`py-1 pl-0 tablerowsTitlewithoutAlign  ${
                      mode === "lightMode" ? "" : "text-white"
                    }`}
                  >
                    Users
                  </TableCell>
                  {windowWidth > 768 && (
                    <TableCell
                      align="center"
                      className={`py-1 tablerowsTitle ${
                        mode === "lightMode" ? "" : "text-white"
                      }`}
                    >
                      Likes
                    </TableCell>
                  )}
                  {windowWidth > 768 && (
                    <TableCell
                      align="center"
                      className={`py-1 tablerowsTitle ${
                        mode === "lightMode" ? "" : "text-white"
                      }`}
                    >
                      Dislikes
                    </TableCell>
                  )}
                  <TableCell
                    align="center"
                    className={`py-1 tablerowsTitle ${
                      mode === "lightMode" ? "" : "text-white"
                    }`}
                  >
                    Volume
                  </TableCell>
                  {/* 
                  {windowWidth > 768 && (
                    <TableCell
                      align="center"
                      className={`py-1 tablerowsTitle ${
                        mode === "lightMode" ? "" : "text-white"
                      }`}
                    >
                      % Change
                    </TableCell>
                  )}
                  {windowWidth > 768 && (
                    <TableCell
                      align="center"
                      className={`py-1 tablerowsTitle ${
                        mode === "lightMode" ? "" : "text-white"
                      }`}
                    >
                      Floor price&nbsp;
                    </TableCell>
                  )}
                  {windowWidth > 768 && (
                    <TableCell
                      align="center"
                      className={`py-1 tablerowsTitle ${
                        mode === "lightMode" ? "" : "text-white"
                      }`}
                    >
                      Sales&nbsp;
                    </TableCell>
                  )} */}
                  <TableCell
                    align="center"
                    className={`py-1 ${
                      mode === "lightMode" ? "text-dark" : "text-white"
                    }`}
                  ></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.length > 0 &&
                  data.map((row, index) => (
                    <TableRow
                      key={index}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      <TableCell
                        component="th"
                        scope="row"
                        className={`py-4 px-0 hideBorder tablerowsData font-weight-bold text-center ${
                          mode === "lightMode" ? "text-dark" : "text-white"
                        }`}
                        style={{ width: "50px" }}
                      >
                        {index + 1}
                      </TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        className={`pointer tablerowsData hideBorder px-0 ${mode==='lightMode'?"text-black":"text-white"}`}
                        onClick={() => {
                          //   handleClick(row);
                        }}
                        style={{ width: "150px", }}
                      >
                        {/* <span>
                          <img
                            src={
                              row.imageUrl !== "null" ? row.imageUrl : Userpng
                            }
                            alt="..."
                            width={"40px"}
                            height={"40px"}
                            style={{
                              // maxHeight: "72px",
                              borderRadius: "1rem",
                            }}
                            className="rounded rouned-circle"
                          />
                          &nbsp;&nbsp;&nbsp;&nbsp;
                        </span>
                        <span
                          className={`hideborder tablerowsData ${
                            mode === "lightMode" ? "text-dark" : "text-white"
                          }`}
                        >
                          {row?.name !== undefined
                            ? (
                                windowWidth < 768
                                  ? row?.name.length < 5
                                  : row?.name.length < 8
                              )
                              ? row?.name
                              : row?.name.slice(0, windowWidth < 768 ? 5 : 8) +
                                " ..."
                            : ""}
                        </span> */}
                        {row?.user_id}
                      </TableCell>
                      {windowWidth > 768 && (
                        <TableCell
                          align="center"
                          className={` hideBorder tablerowsData  text-center  ${
                            mode === "lightMode" ? "text-dark" : "text-white"
                          }`}
                        >
                          {Number(row?.likes).toFixed(2)}
                        </TableCell>
                      )}
                      {windowWidth > 768 && (
                        <TableCell
                          align="center"
                          className={` hideBorder tablerowsData  text-center  ${
                            mode === "lightMode" ? "text-dark" : "text-white"
                          }`}
                        >
                          {Number(row?.dislikes).toFixed(2)}
                        </TableCell>
                      )}
                      <TableCell
                        align="center"
                        className={` hideBorder tablerowsData  text-center  ${
                          mode === "lightMode" ? "text-dark" : "text-white"
                        }`}
                        style={{ width: "50px" }}
                      >
                        {Number(row?.volume).toFixed(2)}
                      </TableCell>
                      {/* {windowWidth > 768 && (
                        <TableCell
                          align="center"
                          className={`text-success tablerowsData hideBorder  ${
                            mode === "lightMode" ? "text-success" : "text-white"
                          }`}
                        >
                          {Number(row?.percentChangeVolumes).toFixed(2)} %
                        </TableCell>
                      )}
                      {windowWidth > 768 && (
                        <TableCell
                          align="center"
                          className={`hideBorder tablerowsData  ${
                            mode === "lightMode" ? "text-dark" : "text-white"
                          }`}
                        >
                          {Number(row?.floorPrice).toFixed(2)}
                        </TableCell>
                      )}
                      {windowWidth > 768 && (
                        <TableCell
                          align="center"
                          className={`hideBorder tablerowsData  ${
                            mode === "lightMode" ? "text-dark" : "text-white"
                          }`}
                        >
                          null
                        </TableCell>
                      )} */}
                      <TableCell
                        align="center"
                        className={`hideBorder font-weight-bold ${
                          mode === "lightMode" ? "text-dark" : "text-white"
                        }`}
                        style={{ width: "50px" }}
                      >
                        <FaRegStar size={20} className="fontweight-700" />
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
    );
  };
  const filterSection = () => {
    const customStyles = {
      option: (provided, state) => ({
        ...provided,
        borderBottom: "0px solid #ccc",
        color: state.isSelected ? "#fff" : "#333", // Selected text color
        background: mode === "lightMode" ? "" : "#1b1b1b",
        padding: "10px",
      }),
      control: (provided) => ({
        ...provided,
        border: mode === "lightMode" ? "1px solid #ccc" : "0px",
        borderRadius: "4px",
        cursor: "pointer",
        height: "45px",
        minWidth: "152px",
        position: "relative",
        bottom: "1px",
        margin: "4px",
        backgroundColor: mode === "lightMode" ? "#f6f6f6" : "#1b1b1b",
      }),
      singleValue: (provided) => ({
        ...provided,
        color: mode === "lightMode" ? "#333" : "white", // Selected text color
      }),
      indicatorSeparator: () => ({ display: "none" }), // Remove separator
      dropdownIndicator: (provided) => ({
        ...provided,
        color: mode === "lightMode" ? "black" : "white", // Dropdown icon color
        fontSize: "10px",
        transition: "transform 0.7s", // Add transition for smooth rotation
        transform: isOpen ? "rotate(180deg)" : "rotate(0deg)",
      }),
      menuList: (base) => ({
        ...base,
        padding: 0,
        "::-webkit-scrollbar": {
          width: "4px",
          height: "0px",
        },
        "::-webkit-scrollbar-track": {
          background: "#f1f1f1",
        },
        "::-webkit-scrollbar-thumb": {
          background: "#888",
        },
        "::-webkit-scrollbar-thumb:hover": {
          background: "#555",
        },
      }),
      tickIcon: {
        marginRight: "5px",
      },
    };
    return (
      <div className="row ">
        <div className="col-xl-3 col-md-6 col-sm-6 align-self-center">
          <div className="m-1">
          <CustomSelect
            options={intervalList}
            updatevalue={updateduration}
            minwidth="auto"
          />
          </div>
        </div>
        <div className="col-xl-3 col-md-6 col-sm-6 align-self-center">
        <div className="m-1">
          <CustomSelect
            options={sortByOptions}
            updatevalue={updateSortBy}
            minwidth="auto"
          />
         </div>
        </div>
        <div className="col-xl-3 col-md-6 col-sm-6 align-self-center">
        <div className="m-1">
          <CustomSelect
            options={sortOrderOptions}
            updatevalue={updateorder}
            minwidth="auto"
          />
          </div>
        </div>
        <div className="col-xl-3 col-md-6 col-sm-6 col-6">
        <div className="m-1">
          <Select
            options={chainlist}
            value={chainlist.find((option) => option.key === activeChain)}
            onChange={(selectedOption) => {
              setactiveChain(selectedOption?.key);
              setdata([]);
            }}
            isSearchable={false}
            isClearable={false}
            getOptionLabel={(option) => (
              <div className="scrollbar border-0 d-flex justify-content-between align-items-center">
                <span>
                  <img
                    src={`data:image/jpg;base64,${option?.image_url}`}
                    alt={option?.name}
                    style={{ width: "20px", marginRight: "10px" }}
                  />
                  {option?.name}
                </span>
              </div>
            )}
            getOptionValue={(option) => option.key}
            styles={customStyles}
            className="font-14 border-0 scrollbar w-100"
            onMenuOpen={() => setIsOpen(true)}
            onMenuClose={() => setIsOpen(false)}
            // Customizing the Option component to display a tick mark if the option is selected
            components={{
              Option: ({ children, isSelected, ...rest }) => (
                <div
                  className={`d-flex justify-content-between align-items-center px-2 py-3 ${
                    isSelected ? "selected" : ""
                  }`}
                  style={{
                    backgroundColor:
                      mode === "lightMode" ? "#f6f6f6" : "#1b1b1b",
                  }}
                  {...rest}
                  onClick={() => {
                    rest.selectOption(rest.data);
                  }}
                >
                  {children}
                  {isSelected && (
                    <FaCheck
                      size={15}
                      className="font-weight-bold"
                      color={mode === "lightMode" ? "black" : "white"}
                    />
                  )}
                </div>
              ),
            }}
          />
        </div>
        </div>
      </div>
    );
  };
  const handlefetchMoreData = () => {
    if (data.length % rowperPage === 0) {
      setpage(page + 1);
      getRatingUserRankAPI(true);
    }
  };

  // ============>>>>API's<<<<===============
  const getRatingUserRankAPI = async (condition = false) => {
    const url = `/user/rating/rank`;
    const data = {
      size: String(size),
      page: String(page),
      blockchain: activeChain,
      duration,
      sort_by: sortBy,
      sort_order: order,
    };
    try {
      setisloading(true);
      await Axios.post(url, data, { headers: { token: token } })
        .then((res) => {
          if (res.status === 200) {
            if (condition) {
              setdata(...data, ...res.data);
            } else {
              setdata(res.data);
            }
          }
        })
        .catch((err) => console.log("err", err))
        .finally(() => {
          setisloading(false);
        });
    } catch (error) {
      console.log("error", error);
    }
  };
  const blockchainApi = async () => {
    const span = tracer.startSpan("BlockChainListApi");
    const url = BaseUrl + "/blockchainlist";
    try {
      await axios
        .get(url)
        .then((res) => {
          if (res.status === 200) {
            if (typeof res.data === "string") {
              toast.warn("invalid data got through blockchain api");
              return;
            }
            if (res.data.length > 0) {
              setchainlist(res.data);
              setactiveChain(res.data[0].key);
            }
          }
          span.setAttribute("Method", "POST");
          span.addEvent("api_response", {
            status: res.status,
            url,
            data: res.data,
          });
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          span.end();
        });
    } catch (error) {
      console.log(error);
    }
  };

  // LIFE CYCLES METHODS (EVENT HANDLING)
  useEffect(() => {
    blockchainApi();
    const handleResize = () => {
      setwindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  useEffect(() => {
    getRatingUserRankAPI(false);
  }, [activeChain, duration, order, sortBy, size]);

  return (
    <InfiniteScroll
      dataLength={data.length}
      next={() => {
        handlefetchMoreData();
      }}
      hasMore={true}
      style={{ overflowX: "hidden", minHeight: "80vh",backgroundColor:mode==='lightMode'?'white':'black',color:mode==='lightMode'?'black':'white' }}
      scrollableTarget="leaderBoardTable"
    >
      <div className="container-fluid">
        {filterSection()}
        {sectionTablescreen()}
        {loading ? (
          <div className="d-flex justify-content-center align-items-center">
            <Loader2
              color={mode === "lightMode" ? "black" : "white"}
              size={20}
            />
          </div>
        ) : data.length === 0 ? (
          <div className="d-flex justify-content-center align-items-cenetr">
            <h5
              className={`text-center ${
                mode === "lightMode" ? "text-dark" : "text-white"
              }`}
            >
              No Data Found
            </h5>
          </div>
        ) : null}
        <div className=" d-flex justify-content-end">
          <CustomSelect
            options={rowperpageoption}
            updatevalue={updaterowPerpage}
            minwidth="auto"
          />
        </div>
      </div>
      {/* <DataTable socket={socket} convertBinaryToText={convertBinaryToText}/> */}
    </InfiniteScroll>
  );
}
