import { Strophe, $iq, $msg } from "strophe.js";

export const toSendmessage = (
  connection,
  address,
  msg,
  changemessage,
) => {
  if (connection) {
    const message1 = new Strophe.Builder("message", {
      to: address,
      type: "chat",
    })
      .c("body")
      .t(msg)
      .tree();
      changemessage();
      connection.send(message1);
    // fetchChatHistory(connection,address)
  }
};

export const fetchChatHistory = async (connection, address) => {
  const allmessages = [];
  if (connection) {
    const iq = $iq({
      type: "set",
    })
      .c("query", {
        xmlns: "urn:xmpp:mam:2",
      })
      .c("x", {
        xmlns: "jabber:x:data",
        type: "submit",
      })
      .c("field", {
        var: "FORM_TYPE",
        type: "hidden",
      })
      .c("value")
      .t("urn:xmpp:mam:2")
      .up()
      .up()
      .c("field", {
        var: "with",
      })
      .c("value")
      .t(`${address}`)
    connection.sendIQ(iq);
    connection.addHandler(
      (response) => {
        var messages = response.getElementsByTagName("forwarded");
        // console.log(messages,'this is undefined');
        for (var i = 0; i < messages.length; i++) {
          var message = messages[i].getElementsByTagName("message")[0];
          var body = message.getElementsByTagName("body")[0].textContent;
          var from = message.getAttribute("from");
          var to = message.getAttribute("to");
          allmessages.push({ from, body, to });
        }
        return true; // Return true to keep the handler active
      },
      null,
      "message"
    );
  }
  return allmessages;
};

export const addUserinChathistory=(server,jid)=>{
  let data;
  if(!jid && !server){
    return false
  }
  const rosterAddIQ = $iq({
    type: 'set',
    id: 'b2gs90j5',
  }).c('query', { xmlns: 'jabber:iq:roster', ver: 'ver42' })
    .c('item', { jid: jid, subscription: 'both' });
    server.sendIQ(rosterAddIQ, (response)=>{
    data=response;
    });
    return true;
}


export const getpreviouschatusers=(server,callback)=>{
  const allusers=[];
  const rosterGetIQAfterReconnection = $iq({
    type: 'get',
    id: 'r2xa7gf9',
  }).c('query', { xmlns: 'jabber:iq:roster', ver: 'ver42' });
  
  if(server!==null){
    server.sendIQ(rosterGetIQAfterReconnection, (response)=>{
      const users=response.getElementsByTagName('item');
      const LoggedinUserJid=Strophe.getBareJidFromJid(server.jid);
      for (const jid in users) {
        if (users.hasOwnProperty(jid)) {
          const user = Strophe.getBareJidFromJid(
            users[jid].getAttribute('jid')
          );
          if(user!==LoggedinUserJid){
            allusers.push({user,msgCount:0,color:"black"});
          }
        }
      }
    });
  }
  callback(allusers);
}

const getmemberofGroups=(connection,groupJid,loggeduser)=>{
  const loggeduseringroup=[];
  const iq=$iq({
    from:loggeduser,
    to:groupJid,
    type:'get'
  }).c('query',{xmlns:"http://jabber.org/protocol/muc#admin"})
  .c('item',{affiliation:'member'})
  .tree()
  connection.sendIQ(iq,(response)=>{
    const from= response.getAttribute("from");
    const checkmember=response.querySelectorAll('item');
    if(checkmember.length>0 && checkmember!==undefined){
      for(let i=0;i<checkmember.length;i++){
        const user=checkmember[i].getAttribute('jid')===loggeduser;
        if(user){
          loggeduseringroup.push({group:from})
        }
      }
    }
  },err=>{return err});

}

export const getAllRegisterdGroups=(connection)=>{
  const loggeduser=Strophe.getBareJidFromJid(connection.jid);

  const chatRoomsList = [];

  const iq = $iq({
    to: 'conference.localhost', // Apne XMPP conference service domain ke saath replace karein
    type: 'get',
    from:connection.jid
  })
  .c('query', {
    xmlns: 'http://jabber.org/protocol/disco#items',
  }).up()
 

  connection.sendIQ(iq,(response)=>{
    console.log(response,'this si')
    const items = response.querySelectorAll('item');
    
    items.forEach((item) => {
      const jid = item.getAttribute('jid');
      const name = item.getAttribute('name');
      const checkIq=$iq({
        type: 'get',
        to: jid,
        from:connection.jid
      }).c('query', {
        xmlns: 'http://jabber.org/protocol/disco#info',
      });
      connection.sendIQ(checkIq,res=>{
        if(res){
          const checkType=res.querySelectorAll('feature');
          if(checkType.length>0){
            for(let elem in checkType){
              const a=typeof(checkType[elem])=== 'object' && checkType[elem].getAttribute('var');
              if(a==="muc_public"){
                chatRoomsList.push({
                  jid,
                  name,
                  type:'public'
                });
              }
              if(a === "muc_hidden"){
                chatRoomsList.push({
                  jid,
                  name,
                  type:'private'
                });
              }
            }
          }
          
        }
      },err=>{console.log(err)});




   getmemberofGroups(connection,jid,loggeduser);
   
    });
  })

    return chatRoomsList;
}