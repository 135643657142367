import React,{useState,useEffect} from "react";
import { AxiosforIssues } from "../../config/API/Api";
import { toast } from "react-toastify";
import './support.css'
import { Loader2 } from "../../commonComponent/Loader";
export default function SupportScreen() {
  const [categoryList, setcategoryList] = useState([]);
  const [email, setemail] = useState("");
  const [description, setdescription] = useState("");
  const [subject, setsubject] = useState("");
  const [issueType,setissueType]=useState('');
  const [isloading,setisloading]=useState(false);
  const getissueCategory = async () => {
    const url = "/category/issues";
    try {
      await AxiosforIssues.get(url)
        .then((res) => {
          if (res.status === 200) {
            console.log("res.data", res.data?.categoy);
            setcategoryList(res.data?.category);
          }
        })
        .catch((err) => {
          console.log("err", err);
        })
        .finally(() => {
          console.log('done');
        });
    } catch (error) {
      console.log("error", error);
    }
  };


  const handleFormSubmit = (e) => {
    e.preventDefault();
    if (!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(email)) {
      toast.warning("Invalid Email");
      return;
    }

    if (issueType == "") {
      toast.warning("Please Select Issue Type");
      return;
    }
    if (subject == "") {
      toast.warning("Subject is mandatory");
      return;
    }
    if (description == "") {
      toast.warning("Description is mandatory");
      return;
    }
    console.log("email", email);
    console.log("description", description);
    console.log("description", subject);

    raiseIssueAPI();
  };
  const raiseIssueAPI = async () => {
    const url = "/issue";
    const data = {
      email_address: email,
      issue: issueType,
      description,
      subject,
    };
    try {
      setisloading(true);
      await AxiosforIssues.post(url, data)
        .then((res) => {
          if (res.status === 200) {
            console.log("res.data", res.data);
            resetForm();
            toast.success("Issue Create successfully");
          }
        })
        .catch((err) => {
          console.log("err", err);
        })
        .finally(() => {
          setisloading(false);
        });
    } catch (error) {
      console.log("error", error);
    }
  };
  useEffect(()=>{
    getissueCategory()
  },[])
  const resetForm=()=>{
    setemail("");
    setdescription('');
    setsubject('');
    setissueType("");
  }
  return (
    <section>
      <div className="row m-4">
        <div className="col col-md-9 mx-auto">
        <h2 className="font-weight-bold">Raise a Ticket</h2>
        <form onSubmit={handleFormSubmit}>
        <div className="form-group">
          <label htmlFor="exampleFormControlInput1" className=" label ">Enter your Email address <span style={{color:'red',position:"relative",top:"5px"}}>*</span></label>
          <input
            type="email"
            className="form-control input"
            id="exampleFormControlInput1"
            placeholder="name@example.com"
            value={email}
            onChange={(e) => {
              setemail(e.target.value);
            }}
          />
        </div>
      
        <div className="form-group">
          <label htmlFor="exampleFormControlSelect1" className="label">What can we help you with? <span style={{color:'red',position:"relative",top:"5px"}}>*</span></label>
          <select placeholder="Select" className="form-control input" id="exampleFormControlSelect1" value={issueType} onChange={(e)=>{setissueType(e.target.value)}}>
          <option value={''}  disabled>Select...</option>
            {
              categoryList.map((elem,index)=>(
                <option value={elem?.key} key={index}>{elem?.value}</option>
              ))
            }
          </select>
        </div>
        <div className="form-group">
          <label htmlFor="subject" className="label">Subject <span style={{color:'red',position:"relative",top:"5px"}}>*</span></label>
          <input
            type="text"
            className="form-control input"
            id="subject"
            value={subject}
            onChange={(e) => {
              setsubject(e.target.value);
            }}
            placeholder="Enter Subject"
          />
        </div>
        <div className="form-group">

          <label htmlFor="exampleFormControlTextarea1" className="label"> Describe your issue <span style={{color:'red',position:"relative",top:"5px"}}>*</span></label><br/>
          <small className="font-12 " style={{color:"#434350"}}>In order to best help, please enter as many details as you can. This could also include all troubleshooting steps you’ve already taken</small>
          <textarea
            className="form-control input"
            id="exampleFormControlTextarea1"
            placeholder="Enter Description"
            rows="3"
            value={description}
            onChange={(e) => {
              setdescription(e.target.value);
            }}
          ></textarea>
        </div>
        <button type="submit" disabled={isloading} id="submit" className="btn btn-primary ">
          {isloading?<Loader2 size={20} className="text-center" color="white"/>:"Submit"}
        </button>
      </form>
        </div>
      </div>
    </section>
  );
}
