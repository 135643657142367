import React, { useEffect,useState } from "react";
import { AxiosForDiscover, REACT_APP_DAPP_BASEURL } from "../../../config/API/Api";
import Styles from  '../Discover.module.css'
import { thousandsConverter } from "../../../utils/utility/Utility";
import { tracer } from "../../../tracing";

const Favourites = ({sectionList,updateList}) => {
  const [defiList, setdefiList] = useState([]);
  const [nftlist, setnftlist] = useState([]);
  console.log('sectionList', sectionList)
  console.log('updateList', updateList)
  const renderFirstSection = () => {
    return (
      <div className="row mt-4">
        <div className="col">
          <h1 className="font-weight-bold font-40">Favourites</h1>
          <div className="d-flex justify-content-center align-items-center my-5 flex-column">
            <img src="https://www.JRN.com/cdn/assets/imgs/244/8116C4E2EAE7F209.png?x-oss-process=image/format,webp/resize,w_200,h_200,type_6/ignore-error,1" className="img-fluid" alt="..." width={"100px"} height={'100px'}/>
            <p className="font-weight-bold"> No record found</p>
          </div>
        </div>
      </div>
    );
  };
  const card = (title,key) => {
    return (
      <div className={`col-md-6 col-12 ${Styles.favouriteCard}`}>
        <div className="d-flex justify-content-between align-items-center">
          <p className="font-16 font-weight-bold">{title}</p>
          <p
            className="font-14 text-secondary pointer"
            onClick={() => {
              if(key==='defi'){
                const findItem=sectionList?.find(o=>o?.tab_key==='defi');
                if(findItem){
                    updateList(findItem)
                }
              }else{
                const findItem=sectionList?.find(o=>o?.tab_key==='nftmarket');
                if(findItem){
                    updateList(findItem)
                }
              }
            }}
          >
            More{">"}
          </p>
        </div>
        <div>
          <table className="table table-borderless">
            <thead>
              <tr>
                <th scope="col" className="font-12 text-secondary">DApp</th>
                <th scope="col" className="font-12 text-secondary">TVL</th>
                <th scope="col" className="font-12 text-secondary">Change</th>
              </tr>
            </thead>
            <tbody>
               {key==='defi'?defiList.map((elem,index)=>(  <tr key={index}>
                <td onClick={()=>{window.open(elem?.deep_link,"_target")}} className="pointer"><img src={elem?.logo} alt="..." width="28px" height="28px"/><span className="fontweight-500 ml-2 font-15">{elem?.name}</span></td>
                <td className="fontweight-500">{thousandsConverter(elem?.tvl)}</td>
                <td className={`${elem?.tvl_change>=0?'text-success':'text-danger'} font-14`}>{Math.round(elem?.tvl_change).toFixed(2)} %</td>
              </tr>)):nftlist?.map((elem,index)=><tr key={index}>
              <td onClick={()=>{window.open(elem?.deep_link,"_target")}} className="pointer"><img src={elem?.logo} alt="..." width="28px" height="28px"/><span className="fontweight-500 ml-2 font-14">{elem?.name}</span></td>
              <td>{thousandsConverter(elem?.tvl)}</td>
              <td className={`${elem?.tvl_change>=0?'text-success':'text-danger'}`}>{Math.round(elem?.tvl_change).toFixed(2)} %</td>
              </tr>)} 
            </tbody>
          </table>
        </div>
      </div>
    );
  };
  const renderSecondSection = () => {
    return (
      <div className="mt-5">
        <div className="d-flex align-items-center">
          <img
            src="https://www.JRN.com/cdn/assets/imgs/237/492BE5FFBEFE76DC.png"
            alt=".."
            width={"10px"}
            height="10px"
          />
          <p className="mb-0">Hot dApps</p>
        </div>
        <div className="row">
            {card('Defi','defi')}
            {card('NFT markets','nft')}
        </div>
      </div>
    );
  };

  //api
  const renderSectionCardListApi = async (title) => {
    const span = tracer.startSpan("Dapps left side List Api");
    const params = { section: title, limit: 5, pageNumber: 1 };
    try {
      span.setAttribute("request.payload", JSON.stringify(params));
      span.setAttribute("http.method", "GET");
      span.setAttribute("http.url", REACT_APP_DAPP_BASEURL + '/dapp/section');
      await AxiosForDiscover.get("/dapp/section", { params })
        .then((res) => {
          span.setAttribute("http.status_code", res.status);
          if (res.status === 200) {
            if (title === "defi") {
              setdefiList(res.data);
            } else {
              setnftlist(res.data);
            }
            span.setAttribute("api.success", true);
            span.addEvent("api_data_processed", {
              processed_data_length: res.data.length,
            });
            span.addEvent("api_response_data", {
              data: JSON.stringify(res.data),
            });
          }else{
            span.setAttribute("api.success", false);
          }
        })
        .catch((err) => {
          span.recordException(err);
          span.setAttribute("api.success", false);
          span.addEvent("api_error", {
            error_message: err.message,
            error_stack: err.stack,
          });
        }).finally(()=>span.end());
    } catch (error) {
      console.log("error", error);
      span.recordException(error);
      span.setAttribute("api.success", false);
      span.addEvent("api_exception", {
        exception_message: error.message,
        exception_stack: error.stack,
      });
      span.end();
    }
  };
  useEffect(() => {
    renderSectionCardListApi("defi");
    renderSectionCardListApi("nftmarket");
  }, []);
  return <div className="container-fluid">
    {renderFirstSection()}
    {renderSecondSection()}
  </div>;
};

export default Favourites;
