import React, { memo, useEffect, useMemo } from "react";
import { useState } from "react";
import ScrollableFeed from "react-scrollable-feed";
import { Strophe,$msg,$iq,$pres } from "strophe.js";
import {
  AiOutlineSend,
} from "react-icons/ai";
import moment from 'moment'
import '../HomeChat.css'
function RenderGroupChat(props) {
const [messages,setMessages]=useState([])
const {connection,mode,currentActiveGroup,server}=props
const [messageInput,setMessageInput]=useState('');


const handleMessage = (response) => {
  console.log(response,'this is message reponse');
 const checkmessageType=response.getAttribute('type');
 const checkResponsehistory=response.getElementsByTagName('delay')
 var senderUsername = Strophe.getResourceFromJid(response.getAttribute('from'));
 const checkResponsehistorymsg=response.getElementsByTagName('body')
const declineRequestnotification=response.getElementsByTagName('decline');
const reason=response.getElementsByTagName('reason');
console.log('decline',declineRequestnotification);

if(declineRequestnotification.length>0 ){
  console.log(reason,'this is reason')
  console.log('Strophe.',Strophe.getBareJidFromJid(declineRequestnotification[0].getAttribute('from')))
  const msg={user:senderUsername,type:'receive',message:`Notification==>: ${Strophe.getBareJidFromJid(declineRequestnotification[0].getAttribute('from'))} has declined the joining request`,time:moment(new Date()).format('hh:mm A')}
  setMessages((pre)=>{
    return [...pre,msg];
   });
}
 if(checkmessageType==="groupchat"){
 if(checkResponsehistory.length>0 && checkResponsehistory ){
  const gettime=checkResponsehistory[0].getAttribute('stamp');
  const msg={type:senderUsername+"@localhost"===Strophe.getBareJidFromJid(connection.jid)?"send":'receive',message:checkResponsehistorymsg[0]?.innerHTML,time:gettime!==undefined? moment(gettime).format('hh:mm A'):'',user:senderUsername};
 setMessages((pre)=>{
  return [...pre,msg];
 });
 }else if(checkResponsehistorymsg.length>0 && senderUsername+"@localhost"!==Strophe.getBareJidFromJid(connection.jid) ){
  const msg={type:'receive',message:checkResponsehistorymsg[0]?.innerHTML,time:moment(new Date()).format('hh:mm A'),user:senderUsername};
  setMessages((pre)=>{
    return [...pre,msg];
   });
 }
 }
  return true;
};

useEffect(()=>{
  if (connection) {
    connection.addHandler(handleMessage, null, 'message');
    connection.send($pres().tree());

    connection.addHandler(function (presence) {
      const from = presence.getAttribute("from");
      const type = presence.getAttribute("type");
      
      if (type === "unavailable") {
        console.log(from + " is offline");
      } else {
        console.log(from + " is online");
      }
      return true;
    }, null, "presence");

    connection.addHandler(function(roster){console.log('this is roster')},null,'roster');
  }
},[])


useMemo(()=>{
  const iq=$pres({
    from:connection.jid,
    id:'n13mt3l',
    to:`${currentActiveGroup}/${connection.jid.slice(0,connection.jid.indexOf('@'))}`
  }).c('x',{
    xmlns:'http://jabber.org/protocol/muc'
  }).c('history',{since:'1970-01-01T00:00:00Z'})
connection.sendIQ(iq,response=>{
const checkmessageType=response.getAttribute('type');

},err=>console.log('err', err))

},[currentActiveGroup])


const handleSubmit=(e)=>{
  e.preventDefault();

const message = $msg({
  from: Strophe.getBareJidFromJid(connection.jid),
  to:currentActiveGroup,
  type: 'groupchat',
}).c('body').t(messageInput);

// Send the message
connection.send(message);
setMessages([...messages,{user:Strophe.getBareJidFromJid(connection.jid),type:'send',message:messageInput,time: moment(new Date()).format('hh:mm A')}])
setMessageInput('');
}

useMemo(()=>{
setMessages([]);   
},[currentActiveGroup])

  return (
    <>
    <div
      id="chat-container"
      className="mt-3 overflow-auto"
      style={{ maxHeight: "60vh" }}
    >
      {/* {messages.length === 0 && <h1 className="text-center">Start the chat</h1>} */}
      <ScrollableFeed className="chat-container">
        {messages.map((elem, index) => {
          return (
            <div
              className={`text my-5 mx-2  ${
                elem.type === `send`
                  ? "text-right"
                  : "text-left"
              }  `}
              key={index}
            >
              <p
                className={`bg-dark p-3`}
                style={{
                  borderRadius: "12px",
                  backgroundColor:
                    mode === "lightMode" ? "#80858b73" : "#797d8161",
                  color: "white",
                  maxWidth:'70%',
                  display:'inline'
                }}
              >
           <span  className="Incominguser">{elem.user}</span>
                <span>
                {elem?.message}
                </span>
              <span className="incomingtime">{elem.time}</span>
              </p>
            </div>
          );
        })}
      </ScrollableFeed>
    </div>
            <div
              className="footer position-absolute  "
              style={{ bottom: 0, width: "70%" }}
            >
              <form
                className="form-inline my-2 my-lg-0 p-1 mx-auto "
                onSubmit={handleSubmit}
              >
                <input
                  className="form-control mr-sm-2"
                  type="text"
                  style={{ width: "92%" }}
                  placeholder="Enter your message"
                  onChange={(e) => {
                    setMessageInput(e.target.value);
                  }}
                  value={messageInput}
                />
                <button
                  className={`btn ${
                    messageInput === ""
                      ? "btn-outline-secondary"
                      : "btn-outline-success"
                  } my-2 my-sm-0 `}
                  type="submit"
                  disabled={messageInput === ""}
                >
                  <AiOutlineSend size={20} />
                </button>
              </form>
            </div>
    </>
  );
}
export default memo(RenderGroupChat);