import React, { useState, useRef,useEffect } from "react";
import Styles from "./Discover.module.css";
import { FaLongArrowAltRight,FaTelegramPlane } from "react-icons/fa";
import { Loader2 } from "../../commonComponent/Loader";
import { useNavigate } from "react-router-dom";
import { AxiosForDiscover, REACT_APP_DAPP_BASEURL } from "../../config/API/Api";
import { FaXTwitter } from "react-icons/fa6";
import { CiStar } from "react-icons/ci";
import { tracer } from "../../tracing";

const RightDetailsScreen = ({ mode,  header }) => {
  const [activeTab, setactiveTab] = useState(0);
  const [loading,setloading]=useState(true);
  const [cardData,setcardData]=useState([]);
  const [cryptoDataList,setcryptoDataList]=useState([]);
  const sectionsRefs = useRef([]);
  const navigate = useNavigate();
  const handleTabClick = (index) => {
    setactiveTab(index);
    sectionsRefs.current[index].scrollIntoView({
      behavior: "smooth",
      inline: "nearest",
    });
  };

  const renderFirstSection = () => {
    return (
      <div className="row">
        <div className="col-md-8 col-12 my-4">
          <h1 className="font-weight-bold font-40">Explore Dapps</h1>
          <p className="font-16" style={{ color: "#929292" }}>{header?.description}</p>
        </div>
        <div
          className={`col-md-4 col-12 pointer ${Styles.coverImg} ${
            mode === "lightMode" ? "" : "border border-secondary"
          }`}
        >
          <p
            className="font-14 font-weight-bold text-white  w-75"
          >
            Invest in Compound via JRN DeFi and share the 300K ARB and 40K OP
            bonus pool!
          </p>
        </div>
      </div>
    );
  };

  const renderCard = (obj = {}, index, className,title="") => {
    return (
      <div
        className={`${className}`}
        key={index}
      >
          <div
            className={`p-3 ${Styles.cardData} ${
              mode === "lightMode"
                ? Styles.cardDatainLight
                : Styles.cardDatainDark
            }`}
          >
          <div  onClick={() => {
          if(title==='cryptopedia'){
            navigate('/discover/cryptopedia');
          }else{
            if(obj?.link!==undefined){
              window.open(obj?.link, "_blank");
            }
          }
        }}>
              <div className="media align-items-center">
                <img
                  className="mr-3 rounded rounded-circle"
                  src={obj?.image}
                  alt="Generic placeholder image"
                  width='44px'
                  height='44px'
                />
                <div className={`media-body align-self-center overflow-auto ${mode==='lightMode'?"text-black":"text-white"}`}>
                  <p className="mt-0 font-16 mb-0">{obj?.title?.length>15?obj?.title?.slice(0,15)+'...':obj?.title}</p>
                  <div className={`scrollbar ${Styles.badge_card_container} `}>
                    {title!=='cryptopedia'&&<span className={`badge m-1 font-12 ${Styles.badgeCard_recommended_info}`}>{obj?.recommend_info}</span>}
                    {title==='cryptopedia'?<span
                      className="font-12 px-3 py-1"
                      style={{ backgroundColor: "#BCFF2F", borderRadius: "18px" }}>
                      {obj.season ? obj.season : "season 20"}
                    </span>:obj?.category?.map((o,i)=><span className={`badge m-1 font-12 ${Styles.badgeCard}`} key={i}>{o}</span>)}
                    
                  </div>
                </div>
              </div>
              <div className={`my-2`}>
                <p className={`font-12 ${Styles.description} ${mode==='lightMode'?"text-black":"text-white"}`}>
                  {obj?.description?.length > 80
                    ? obj?.description?.slice(0, 80) + "..."
                    : obj?.description}
                </p>
              </div>
          </div>
            {title==='cryptopedia'? <div className="d-flex justify-content-between align-items-center">
              <p className={`font-12 ${Styles.description} ${mode==='lightMode'?"text-black":"text-white"}`}>
                <span className="font-weight-bold ">
                  {obj?.apps ? obj.apps : 3}
                </span>
                 {" "}Dapps
              </p>
              <p className={`font-12 ${Styles.description} ${mode==='lightMode'?"text-black":"text-white"}`}>
                <span className="font-weight-bold ">
                  {obj?.participants ? obj.participants : "81219"}
                </span>
                 {" "}participants
              </p>
            </div>:<div className="d-flex justify-content-between align-items-center">
                <div><FaXTwitter size={18} color={mode==='lightMode'?'black':'white'} className={mode==='lightMode'?Styles.socialMediaIcons:Styles.socialMediaIconsInDarkMode} onClick={()=>{if(obj?.twitter_link){window.open(obj?.twitter_link,'_blank')}}}/>{obj?.telegram_link!==undefined&&obj.telegram_link!==null&& obj?.telegram_link!==''&&<FaTelegramPlane size={18} color={mode==='lightMode'?'black':'white'} className={Styles.socialMediaIcons} onClick={()=>{if(obj?.telegram_link){window.open(obj?.telegram_link,'_blank')}}}/>}</div>
              <CiStar size={18} className={Styles.StartInCard} color={mode==='lightMode'?'black':'white'} />
            </div>}
          </div>
        </div>
    );
  };

  const renderSectionSection = () => {
    return (
      <div>
        <div className="d-flex justify-content-between align-items-center">
          <p className="font-20 font-weight-bold">Cryptopedia</p>
          <a
            href="https://www.JRN.com/web3/discover/cryptopedia"
            target="blank"
          >
            <FaLongArrowAltRight
              size={20}
              color={mode === "lightMode" ? "black" : "white"}
            />
          </a>
        </div>
        <div className="row">
          {cryptoDataList?.length===0&&<p className="font-16 text-center font-weight-bold">No Data found</p>}
          {cryptoDataList?.map((elem,index)=>elem?.status==='enable'&&renderCard(elem,index,'col-lg-4 col-md-6 col-12 p-2','cryptopedia'))}
        </div>
      </div>
    );
  };

  const renderTablist = () => {
    return (
      <div className={`${Styles.tablistContainer} ${mode==='lightMode'?"bg-white":"bg-black"} `}>
        <div
          className={`d-flex my-2 align-items-center scrollbar`}
          style={{ overflowX: "auto", whiteSpace: "nowrap" }}
        >
          {cardData?.map((elem, index) => (
              <button className={`py-2 mx-2 border-0 ${activeTab === index?Styles.activetab:''}  ${mode==='lightMode'?Styles.tabItem:Styles.tabItemInDarkmode}`}
                style={{borderRadius: "34px",backgroundColor:activeTab === index ? "black" : "#0000000A",color: activeTab === index ? "white" : "#909090",minWidth: "150px",whiteSpace: "nowrap"}}
                key={index}
                onClick={() => handleTabClick(index)}
              >
                {elem?.tab_key}
              </button>
            ))}
        </div>
      </div>
    );
  };

  const renderNextSection = (list, title, index = 1) => {
    return (
      <div
        className="my-4"
        key={index}
        id={`section${index}`}
        ref={(el) => (sectionsRefs.current[index] = el)}
      >
        <div className="my-2">
          <p className="font-20 font-weight-bold clas mb-0">{title}</p>
        </div>
        <div className="row">
          {list?.dapps?.map((elem, index) => renderCard(elem, index, "col-xl-4 col-lg-3 col-sm-6 col-12 p-2",title))}
        </div>
      </div>
    );
  };

  //api section
  const getDataforAsection = async () => {
    const span = tracer.startSpan("Dapps Featured Card List Api");
    const url='/dapp/featured/card'
    try {
      span.setAttribute("http.method", "GET");
      span.setAttribute("http.url", REACT_APP_DAPP_BASEURL + url);
      await AxiosForDiscover.get(url).then((res) => {
        span.setAttribute("http.status_code", res.status);
          if (res.status === 200) {
            setcardData(res.data);
            span.setAttribute("api.success", true);
            span.addEvent("api_data_processed", {
              processed_data_length: res.data.length,
            });
            span.addEvent("api_response_data", {
              data: JSON.stringify(res.data),
            });
          }else{
            span.setAttribute("api.success", false);
          }
        })
        .catch((err) =>{
          span.recordException(err);
          span.setAttribute("api.success", false);
          span.addEvent("api_error", {
            error_message: err.message,
            error_stack: err.stack,
          });
        }).finally(()=>{setloading(false);span.end()});
    } catch (error) {
        span.recordException(error);
        span.setAttribute("api.success", false);
        span.addEvent("api_exception", {
          exception_message: error.message,
          exception_stack: error.stack,
        });
        span.end();
    }
  };

  const getCardList = async () => {
    const span = tracer.startSpan("Dapps Cryptocard Api");
    const url=`/dapp/cryptopedia`;
    try {
      setloading(true);
      span.setAttribute("http.method", "GET");
      span.setAttribute("http.url", REACT_APP_DAPP_BASEURL +url);
      await AxiosForDiscover.get(url)
        .then((res) => {
          span.setAttribute("http.status_code", res.status);
          if (res.status === 200) {
            setcryptoDataList(res.data);
            span.setAttribute("api.success", true);
            span.addEvent("api_data_processed", {
              processed_data_length: res.data.length,
            });
            span.addEvent("api_response_data", {
              data: JSON.stringify(res.data),
            });
          }else{
            span.setAttribute("api.success", false);
          }
        })
        .catch((err) =>{
          span.recordException(err);
          span.setAttribute("api.success", false);
          span.addEvent("api_error", {
            error_message: err.message,
            error_stack: err.stack,
          });
        }).finally(()=>{setloading(false);span.end()});
    } catch (error) {
      span.recordException(error);
      span.setAttribute("api.success", false);
      span.addEvent("api_exception", {
        exception_message: error.message,
        exception_stack: error.stack,
      });
      span.end();
    }
  };
  useEffect(() => {
    getCardList();
    getDataforAsection()
  }, []);



  return (
    <section className="m-4 p-2">
      {renderFirstSection()}
      {loading ? (
        <div className="d-flex align-items-center justify-content-center ">
          <Loader2 size={25} color={mode === "lightMode" ? "black" : "white"} />
        </div>
      ) : (
        <div>
          {renderSectionSection()}
          {renderTablist()}
          {cardData
            ?.map((item, index) =>
              renderNextSection(item, item?.tab_key, index)
            )}
        </div>
      )}
    </section>
  );
};

export default RightDetailsScreen;
