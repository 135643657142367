import React from "react";
import { useSelector } from "react-redux";
import { FaXing, FaLinkedinIn } from "react-icons/fa";
import { IoIosSend } from "react-icons/io";
import { AiOutlineLink } from "react-icons/ai";
import { TiSocialFacebook } from "react-icons/ti";
import "./privcaypolicy.css";

export default function Terms_and_Condition() {
  const mode = useSelector((state) => state.themeType.themeType);
  console.log(mode);

  const socialMediaIcon = (size) => {
    const iconList = [
      {
        label: "faxing",
        component: <FaXing size={size}  color={mode==="lightmode"?"black":'black'} />,
      },
      {
        label: "fb",
        component: <TiSocialFacebook size={size} color={mode==='lightMode'?'black':'black'} />,
      },
      {
        label: "linkedin",
        component: <FaLinkedinIn size={size} color={mode==='lightMode'?'black':'black'} />,
      },
      {
        label: "send",
        component: <IoIosSend size={size} color={mode==='lightMode'?'black':'black'} />,
      },
      {
        label: "link",
        component: <AiOutlineLink size={size} color={mode==='lightMode'?'black':'black'} />,
      },
    ];
    return (
      <div className="d-flex flex-wrap  ">
        {iconList.map((elem) => (
          <span
            className="socialmediaIcon rounded-circle "
            style={{ backgroundColor: "#f9f9f9" }}
          >
            {elem.component}
          </span>
        ))}
      </div>
    );
  };
  return (
    <div
      className={` ${
        mode !== "lightMode" ? "bg-black text-white" : "bg-white text-dark"
      }`}
    >
      <div className="row mx-1 mb-5 ">
        <div className="col-11 offset-1 my-3">
          <h1 id="privacyTitle" className={` my-5 ${mode==="lightMode"?"text-dark":'text-white'}`}>
          Terms of Service
          </h1>
          <span id="privacyUpdateTime" className="text-muted">
            Published on May 30 | 2023 Updated on Jan 26 | 2024 26 min read
          </span>
          {socialMediaIcon(15)}
        </div>

        <div className="col-9 offset-1 mr-auto" id="termsContent">
    
            <p className="terms_para">
              <strong>Last Updated: January 17, 2024</strong>
            </p>
            <p className="terms_para">
              Thank you for visiting{" "}
              <a href="https://jrn.com/" target="_blank">
                JRN.com
              </a>
              . By visiting, accessing, or using{" "}
              <a href="https://jrn.com/" target="_blank">
                JRN.com
              </a>{" "}
              and associated application program interface or mobile
              applications (“JRN Platform”), you (“User”, “user”, “You” or
              “you”) consent to the Terms of Service (these “Terms”), so please
              read them carefully.
            </p>
            <p className="terms_para">
              The Terms constitute the agreement and understanding regarding the
              use of any or all of the Services, and any manner of accessing
              them, between you and the following service providers (“we,” “us”
              or “our”) depending on your residency and date of registration:
            </p>
            <p className="terms_para">
              <strong>OKCoin Europe LTD</strong>, a Malta limited liability
              company (“<strong>OKC EU</strong>”), which operates under the JRN
              brand, for Users who are residents of one of our approved
              operating locations within the European Economic Area. The Terms
              of Service for such users can be found{" "}
              <a
                href="https://support.okcoin.com/hc/en-us/articles/360059514492-Terms-of-Service"
                target="_blank"
              >
                here
              </a>
              ;
            </p>
            <p className="terms_para">
              <strong>JRN Bahamas FinTech Company Limited</strong>, a Bahamas
              registered company (“<strong>JRN Bahamas</strong>”), for Users who
              are residents of Mexico and who registered between November 16,
              2022 and August 28, 2023, and institutional Users who are
              registered on or after August 29, 2023;
            </p>
            <p className="terms_para">
              <strong>JRN Hong Kong FinTech Company Limited</strong>, a Hong
              Kong registered company (“<strong>JRN HK</strong>”), for Users who
              are residents of Hong Kong and who registered on or after May 15,
              2023;
            </p>
            <p className="terms_para">
              <strong>JRN Serviços Digitais Ltda.,</strong> a Brazil limited
              liability company (“<strong>JRN Brazil</strong>”), for Users who
              are residents of Brazil and who registered on or after June 15,
              2023;
            </p>
            <p className="terms_para">
              <strong>JRN SG Pte. Ltd.,</strong> a Singapore limited liability
              company (“<strong>JRN Singapore</strong>”), for Users who are
              residents of Singapore and who registered on or after October 13,
              2023;
            </p>
            <p className="terms_para">
              <strong>Aux Cayes FinTech Co. Ltd</strong>., a Seychelles
              registered company (“<strong>Aux Cayes</strong>”), for all other
              Users eligible to access and use JRN’s Services.
            </p>
            <p className="terms_para">
              Where the term “JRN” is used in these Terms, it shall mean either
              OKC EU, JRN Bahamas, JRN HK, JRN Brazil, JRN Singapore, or Aux
              Cayes as the case may be for their respective Users.
            </p>
            <h2 className="terms_heading"  id="1-preamble">1 PREAMBLE</h2>
            <p className="terms_para">
              1.1 By clicking on the “Create Account” button or by visiting the
              JRN Platform, we may provide you with access and utility through
              our trading platform via software, API (application program
              interface), technologies, products and/or functionalities. A
              description of the Services provided by us is contained in Clause
              4 of these Terms. A list of defined terms and their meaning is
              contained in Clause 22 of these Terms.
            </p>
            <p className="terms_para">
              1.2 As described in these Terms, you agree to be legally bound by
              these Terms and all terms incorporated by reference. If you do not
              agree to be bound by these Terms, do not access or use the
              Services. Certain clauses in these Terms will be different
              depending on whether you are a User of OKC EU, JRN Bahamas, JRN
              HK, JRN Brazil, JRN Singapore, or Aux Cayes. Where this is the
              case, the relevant Clause will set out which User the Clause
              applies to.
            </p>
            <p className="terms_para">
              1.3 To the fullest extent permissible pursuant to Applicable Laws
              and Regulations and in accordance with Clause 16, we reserve the
              right to change or modify the Terms, and any policy or guideline
              of the JRN Platform, at any time and at our sole discretion.
            </p>
            <p className="terms_para">
              1.4 To the fullest extent permissible pursuant to Applicable Laws
              and Regulations, you agree to the Terms in effect when you access
              or use the platform, you must stop using our Services. We
              encourage you to frequently review the Terms to ensure that you
              understand the terms and conditions that apply to your access to,
              and use of, the Services. If you have any questions regarding the
              use of the JRN Platform, please go to{" "}
              <a href="/help" target="_blank">
                Support Center
              </a>
              .
            </p>
            <p className="terms_para">
              1.5 The Terms and any terms expressly incorporated herein apply to
              your access to, and use of, any services provided by us. The Terms
              take precedence over and do not alter in any way the terms or
              conditions of any other agreement you may have with us for
              products, services or otherwise. If you are using the Services on
              behalf of a legal entity, you represent and warrant that (a) such
              legal entity is duly organized and validly existing under the
              applicable laws of the jurisdiction of its organization and (b)
              you are authorized to accept the Terms on such entity’s behalf and
              that such entity agrees to be responsible to us if you violate the
              Terms.
            </p>
            <p className="terms_para">
              1.6 You should read the Terms, and any document referred to in
              them very carefully. If there is anything that you do not
              understand in the Terms and any document referred to herein, you
              should discuss this matter with us and seek the necessary
              clarification.
            </p>
            <p className="terms_para">
              1.7 Any formal communication with you will be undertaken through
              electronic mail unless otherwise instructed. Documents will be
              sent to you by electronic mail and you should seek to send any
              documents to us by the same means. Please visit{" "}
              <a href="/help" target="_blank">
                Support Center
              </a>{" "}
              for assistance.{" "}
            </p>
            <p className="terms_para">
              1.8 Any formal communication with you will, unless otherwise
              agreed, be made in the English language. In the event of any
              discrepancy between the English language version of the Terms or
              any such communications and any translation of the Terms or such
              communications (as applicable) in a foreign language, the
              respective English version shall prevail.
            </p>
            <h2 className="terms_heading"  id="2-eligibility-and-prohibition-of-using-our-services--business">
              2 ELIGIBILITY AND PROHIBITION OF USING OUR SERVICES &amp; BUSINESS
            </h2>
            <p className="terms_para">
              2.1 The Services are intended solely for Users who are Natural
              Persons aged 18 or older. If you are a Natural Person, by
              accessing or using our Services, you represent and warrant that
              you are at least 18 years old. If you are registering to use the
              Service(s) on behalf of a legal entity (e.g. corporate or
              institutional customers), you represent and warrant that such
              legal entity is duly organized and validly existing under the
              applicable laws of the jurisdiction of its organization; you are
              duly authorized by such legal entity to act on its behalf, such
              legal entity agrees to be responsible to us if you violate these
              Terms, and you have not previously been suspended or removed from
              the JRN Platform or Services. You also represent and warrant that
              you are not on and that you will not transact with anyone on any
              trade or economic sanctions lists of any Competent Authority,
              which includes without limitation: Terrorism and terrorist
              financing - Islamic State in Iraq and the Levant (Da’esh),
              Al-Qaida, the Taliban, and associated individuals, groups,
              undertakings, and entities, any individual or entity designated by
              the United Arab Emirates ("Local Terrorism List"); and the
              financing of proliferation of weapons of mass destruction (WMDs) -
              Democratic People’s Republic of Korea: nuclear-related, other
              weapons of mass destruction-related, and ballistic missile-related
              programs; Islamic Republic of Iran: nuclear program; and other
              sanction lists that may be promulgated by a Competent Authority,
              including but not limited to the United Nations Security Council,
              European Union, the Monetary Authority of Singapore, Hong Kong
              Monetary Authority, Hong Kong Customs and Excise Department, or
              Office of Foreign Asset Control. You agree to provide written
              certification of your compliance of this Clause 2.1 as soon as
              reasonably practicable when requested by us.
            </p>
            <p className="terms_para">
              2.2 Note that we may not make all of the Services available in all
              markets and jurisdictions, and may restrict or prohibit use of all
              or a portion of the Services from Restricted Locations, which at
              this time include Hong Kong (specifically regarding our
              derivatives-related Services), Belgium, Cuba, France and its
              overseas territories including Guadeloupe, French Guiana,
              Martinique, Mayotte, La Réunion, Saint Barthélemy, Saint-Martin,
              Saint-Pierre-et-Miquelon, French Southern and Antarctic Lands,
              French Polynesia, Wallis and Futuna, and New Caledonia, Iran,
              Japan, North Korea, Crimea, Malaysia, Singapore (specifically
              regarding our Margin and derivatives related Services), Syria,
              United States of America including all U.S.A. territories like
              Puerto Rico, American Samoa, Guam, Northern Mariana Island, and
              the US Virgin Islands (St. Croix, St. John and St. Thomas), the
              Bahamas, Canada, the Netherlands, the United Kingdom (specifically
              regarding our derivatives-related Services for retail users),
              Ireland, Bangladesh, Bolivia, Donetsk, Luhansk and Malta. The
              content of the Terms shall not be excluded from the laws of the
              country or region under which the user belongs. As a result, if
              you do not meet these eligibility requirements, do not use our
              Services. Residents of Belgium, France, Ireland, Japan, the
              Bahamas and the Netherlands are not permitted to open new accounts
              at JRN or access the Services if they have not yet opened an
              account.
            </p>
            <h2 className="terms_heading"  id="3-prohibited-businesses">3 PROHIBITED BUSINESSES</h2>
            <p className="terms_para">
              3.1 Any use of our Services in connection with any of the
              following categories of activities or businesses is prohibited
              (“Prohibited Businesses”), and we reserve the right at all times
              to monitor your transactions or accounts that are related to any
              of the following Prohibited Businesses:
              <br />
              (a) unlicensed money service businesses, including but not limited
              to payment services providers, the sale of money orders or
              cashier’s checks or any money transmitter activities;
              <br />
              (b) prohibiting any business relationships with banks or financial
              institutions that do not maintain a physical presence in any
              country (i.e., a prohibited “Shell Bank'') or that have financial
              activities or services that do not comply with, or would cause OKC
              EU, JRN Bahamas, JRN HK, JRN Brazil, JRN Singapore, or Aux Cayes,
              as applicable, to not be in compliance with any laws, regulations,
              or other legal authority applicable to us or applicable to the
              regulated financial services or activities in question.
              <br />
              (c) adult content and services, including but not limited to any
              types of pornography and other obscene materials (including
              literature, imagery and other media), sites offering any
              sexually-related services such as prostitution, escorts, pay-per
              view, and adult live chat features;
              <br />
              (d) deceptive marketing and false advertising services;
              <br />
              (e) religious and/or spiritual organizations;
              <br />
              (f) unlicensed sale of weapons of any kind, including but not
              limited to firearms, ammunition, knives, explosives, or related
              accessories;
              <br />
              (g) certain regulated products and services, including but not
              limited to marijuana dispensaries and related businesses; sale of
              tobacco, e-cigarettes, and e-liquid; online prescription or
              pharmaceutical services; age restricted goods or services; and
              toxic, flammable, and radioactive materials;
              <br />
              (h) pseudo-pharmaceuticals - companies manufacturing and or
              selling untested or unapproved pharmaceuticals;
              <br />
              (i) drugs and drug paraphernalia, including but not limited to,
              sale of narcotics, controlled substances, and any equipment
              designed for making or using drugs, such as bongs, vaporizers and
              hookahs
              <br />
              (j) gambling activities, including but not limited to sports
              betting, casino games, horse racing, dog racing, lotteries, games
              of chance, sweepstakes, games of skill that may be classified as
              gambling (i.e. poker), or other activities that facilitate any of
              the foregoing;
              <br />
              (k) money-laundering, fraud, terrorist financing, or any other
              type of financial crimes;
              <br />
              (l) any sort of Ponzi scheme, pyramid scheme, or multi-level
              marketing program;
              <br />
              (m) goods or services that infringe or violate any copyright,
              trademark, or proprietary rights under the laws of any
              jurisdiction;
              <br />
              (n) layaway systems, or annuities;
              <br />
              (o) counterfeit or unauthorized goods, including but not limited
              to the sale or resale of fake or “novelty” IDs and the sale of
              goods or services that are illegally imported or exported or which
              are stolen;
              <br />
              (p) wash trading, front-running, insider trading, market
              manipulation or other forms of market-based fraud or deceit;
              <br />
              (q) purchasing goods of any type from hidden service markets or
              “Darknet” markets, or any other service or website that acts as a
              marketplace for illegal goods (even though such marketplace might
              also sell legal goods);
              <br />
              (r) any other matters, goods, or services that from time to time
              we deem to be unacceptable or of high risk, and which, for
              example, may be restricted by our and your bank or payment
              partners;
              <br />
              (s) any other unlawful activities which would, in our sole
              discretion, violate, or assist in violation of, any law, statute,
              ordinance, or regulation, sanctions programs administered in the
              countries where we conduct business, or which would involve
              proceeds of any unlawful activities;
              <br />
              (t) Shell Banks or financial institutions that have customers that
              are Shell Banks;
              <br />
              (u) entities with bearer share ownership;
              <br />
              (v) defense industry, firearms &amp; munitions manufacturers;
              <br />
              (w) nuclear energy;
              <br />
              (x) restricted financial services, including but not limited to
              credit repair, debt settlement, refinance, bail bonds, collections
              agencies; or
              <br />
              (y) transactions or business involving ivory and protected
              species.
            </p>
            <p className="terms_para">
              3.2 In the event that we learn or reasonably suspect, in our sole
              discretion, that your account is or may be associated with any of
              the Prohibited Businesses as set forth above, we will consider it
              to be a violation of these Terms and may suspend or terminate your
              account, and/or block transactions or freeze your funds
              immediately without notice, and we reserve the right to report any
              such suspected or actual Prohibited Businesses to the law
              enforcement authorities.
            </p>
            <h2 className="terms_heading"  id="4-description-of-our-services">
              4 DESCRIPTION OF OUR SERVICES
            </h2>
            <p className="terms_para">
              4.1 We provide an online trading platform for Users to hold and
              trade Digital Assets (“Digital Assets” as defined below, and also
              known as virtual financial assets, cryptographic tokens, digital
              tokens and/or cryptographic currency) and derivatives linked to
              Digital Assets or indices thereof. Users may request the
              withdrawal of their Digital Assets at any time, subject to the
              limitations as stated in the Terms.{" "}
            </p>
            <p className="terms_para">
              4.2 We provide the User with trading services including:
              <br />
              (a) an order matching platform that automatically, and according
              to pre-established criteria, matches Users’ trades with open
              orders from other Users in respect of Digital Assets or Digital
              Asset linked products;
              <br />
              (b) Spot Services that permit you to trade one type of Digital
              Asset for another type of Digital Asset with another User through
              an order-matching engine. You will not be able to predetermine or
              undertake a trade with a predetermined user. Similarly, an order
              may be partially filled or may be filled by multiple matching
              orders arising from different users;
              <br />
              (c) Additional Services that permit you to trade derivative
              products linked to Digital Assets or indices composed of them with
              other users. These trades are via an order matching platform that
              automatically, and according to pre-established criteria, matches
              users’ trades with open orders from other users. You will not be
              able to predetermine or undertake a trade with a pre-determined
              user.
            </p>
            <p className="terms_para">
              4.3 To the extent permitted by Applicable Laws and Regulations,
              Margin is permitted, at our discretion, to be employed when using
              our Services. The parameters of Margin and leverage permitted for
              each of these products are set and may be varied by us in
              accordance with the actual market conditions at our discretion and
              depend upon the type of service, type of asset, and amount of
              collateral used in the transaction. Eligible users may select the
              amount of Margin or leverage they desire to use within those
              parameters (subject to it being offered by us). When utilizing
              Margin, users acknowledge, in all cases, that use of margin and
              other leverage products is high-risk that may result in a loss
              larger than the funds in their accounts and which may require
              liquidation of their assets, as specified by the terms governing
              Margin for each product. The specific leverage amounts permitted
              for each product can be found at the following links for
              indicative purposes only:
              <br />
              (a){" "}
              <a href="/help/ii-introduction-of-margin" target="_blank">
                Spot Margin
              </a>
              ; (b){" "}
              <a
                href="/help/ii-what-is-margin-in-perpetual-swaps"
                target="_blank"
              >
                Perpetual Swap Margin
              </a>
              ; (c){" "}
              <a
                href="/help/ii-what-is-margin-in-futures-contracts"
                target="_blank"
              >
                Futures Contract Margin
              </a>
              ; (d){" "}
              <a href="/help/ii-option-margin" target="_blank">
                Option Margin
              </a>
            </p>
            <p className="terms_para">
              4.4 Other Services include, but are not limited to:
              <br />
              (a) P2P – a Peer to Peer matching service, allowing users to
              exchange their Fiat Currency and Digital Assets with each other.
              Users are able to buy and sell Digital Assets from each other
              without the need of a centralized trusted third party such as an
              exchange;
              <br />
              (b) Margin Lending – a lending service allowing users to
              borrow/lend Digital Assets to one another and earn rewards; and
              <br />
              (c) Digital Wallet – a digital wallet, maintained by us on each
              user’s behalf, enabling each user to store any Digital Assets that
              are traded via the exchange services, or any P2P Digital Assets
              that are deposited or acquired by you through the P2P platform
              which allows users to buy and sell digital assets directly between
              users, not directly with JRN.
            </p>
            <p className="terms_para">
              4.5 Not every Service offered by JRN is addressed in these Terms.
              Aside from the core spot trading and other Services addressed
              herein, additional Services are subject to other agreements
              specific to those Services on the JRN Platform. Additionally,
              certain Services mentioned in these Terms are subject to
              additional terms on the JRN Platform. For the terms governing
              those services, please refer to the “FAQs” section of the Website,
              available{" "}
              <a href="/help/category/faq" target="_blank">
                here
              </a>
              .
            </p>
            <p className="terms_para">
              4.6 In general, our Services facilitate transactions that match
              users with other users. In certain limited circumstances, JRN's
              Related Parties may trade on the JRN Platform or use other
              Services on a proprietary basis, as principal, or on behalf of
              others. Such trading activity may include, but is not limited to,
              buying or selling Digital Assets or derivatives, placing Orders to
              buy or sell Digital Assets or derivatives, acting as a market
              maker for certain Digital Assets or derivatives, and trading as a
              principal in Digital Asset transactions or derivatives. Orders
              submitted and trades executed by JRN's Related Parties are not
              distinguished from other trades on the JRN Platform. JRN's Related
              Parties thus may act as a counterparty to any trade executed by
              you or other Users. The trading activity of JRN's Related Parties,
              as described herein, may include JRN's Related Parties engaging in
              transactions with multiple counterparties, including other Users,
              where interests may differ. JRN and its Related Parties have no
              duty to act solely on your behalf and undertakes no responsibility
              to do so. You agree and understand that JRN's Related Parties may
              use the JRN Platform and other Services. You agree not to object
              to or otherwise bring a claim against JRN and its Related Parties
              related to any transaction conducted by JRN and its Related
              Parties.
            </p>
            <p className="terms_para">
              4.7 By accepting the Terms, you expressly agree to the pooling of
              your Digital Assets with the Digital Assets of other users.
              Digital assets of users are not protected by deposit protection or
              deposit insurance scheme. In the case of an irreconcilable
              shortfall, you may not receive some or any of your deposited
              assets or funds.
            </p>
            <p className="terms_para">
              4.8 You represent and warrant that you are the ultimate and
              effective legal and beneficial owner of any Digital Assets
              transferred to your account or wallet on the JRN Platform, that
              you shall not act as nominee or trustee for any other person and
              that you shall not transfer, assign, pledge, charge or otherwise
              create any security interest or encumbrance whatsoever over such
              Digital Assets.
            </p>
            <p className="terms_para">
              4.9 We shall not be obliged to accept orders unless sufficient
              Digital Assets are available on the account for (a) the settlement
              of the relevant order, (b) the provision of Margin as may be
              required in respect of a relevant transaction in derivatives, and
              (c) the payment of any related charges and expenses as applicable.
            </p>
            <p className="terms_para">
              4.10 While we have made every effort to ensure the accuracy of the
              information on our JRN Platform, the information and content on
              the JRN Platform is subject to change without notice and is
              provided for the sole purpose of assisting users to make
              independent decisions. We have taken reasonable measures to ensure
              the accuracy of the information on the JRN Platform; however, we
              do not guarantee the accuracy, suitability, reliability,
              completeness, performance and/or fitness for purpose of the
              content of any Services or products available through the JRN
              Platform, and will not accept liability for any loss or damage
              that may arise directly or indirectly from the content or your
              inability to access the JRN Platform, for any delay in or failure
              of the transmission or the receipt of any instruction or
              notifications sent through our platform. We will not have any
              liability for the use or interpretation of such information.
            </p>
            <p className="terms_para">
              4.11 Users shall prepare devices and bear costs as follows: 1)
              internet-connected device, including but not limited to computer
              or other internet-connected terminals; and 2) internet-accessing
              costs, including but not limited to internet fees, rental charges
              for internet-connected equipment, cellular data fees, etc.
            </p>
            <p className="terms_para">
              4.12 You hereby authorize us to send you communications relating
              to your Account via email, SMS, mobile notification, or mailing
              address. You may also opt-out of certain communications by
              changing relevant settings on the JRN Platform.
            </p>
            <p className="terms_para">
              4.13 You acknowledge and consent that the Services are provided by
              us according to their current technological capacity and other
              conditions. While we have made every effort to ensure continuity
              and security of the Services, we are unable to completely foresee
              and hedge legal, technological and other risks including but not
              limited to force majeure, virus, hacker attack, system
              instability, flaws in third-party services, acts of government, or
              government agency etc. that may result in service interruption,
              data loss and other losses and risks.
            </p>
            <p className="terms_para">
              4.14 When the JRN Platform is unable to operate properly because
              of the following circumstances and the user is unable to access
              the Services or place or cancel an order, we assume no liability
              for damages. These circumstances include, but are not limited to:
              <br />
              (a) system downtime during maintenance as announced by the JRN
              Platform;
              <br />
              (b) telecom or networking equipment issues;
              <br />
              (c) typhoon, earthquake, tsunami, flood, power failure, war,
              terrorist attacks, and other force majeure factors;
              <br />
              (d) any other issues, including hacker attacks, computer virus
              intrusion or attack, website or backend maintenance and upgrade,
              banking related issues, government regulation or mandates,
              freezing order imposed by any Competent Authority and any other
              third party issues; and
              <br />
              (e) damages to users or other third parties caused by third
              parties.
            </p>
            <p className="terms_para">
              4.15 In relation to the Services provided by JRN on the JRN
              Platform:
              <br />
              (a) we reserve the right to process, cancel, correct, clawback,
              and/or reverse, any Digital Asset transaction or transfers or
              cancel abnormal transaction results in our sole discretion, even
              after funds may have been debited from your account(s) upon the
              occurrence of abnormal transactions, market interruption and other
              abnormal conditions caused by , arising from, or related to system
              failure, platform system bug(s), network failure, distributed
              denial of service attacks (DDos) and other hacker attacks and
              other unexpected factors; or in response to a subpoena, court
              order, or other government order; or if we suspect the transaction
              may: involve money laundering, terrorist financing, fraud, or any
              other type of financial crime; be erroneous; or relate to a
              prohibited use in accordance with these Terms. For Futures, P2P,
              and Margin Lending, we reserve the right to rollback all the
              transactions of a certain period of time as described in the
              respective user agreement. In such instances, JRN will reverse the
              transaction and debit the corresponding Digital Assets from your
              account(s) to recover the Losses arising from such transaction,
              and we are under no obligation to reinstate any purchase or sale
              order at the same price or on the same terms as the canceled
              transaction; and/or
              <br />
              (b) we strictly prohibit unfair trading behaviors. JRN reserves
              the right to enact, under its sole discretion, and take control
              over your account, if you: (i) are involved in price manipulation,
              insider dealing, market manipulation, market distortion or any
              other malicious wrongdoings to the market; (ii) might be harming
              other users or JRN by exploiting the Service’s vulnerabilities or
              by other unreasonable means; (iii) are involved in any other
              activities that JRN deems harmful to the overall well-being of the
              market.
            </p>
            <p className="terms_para">
              We also reserve the right to take actions, including but not
              limited to, close your accounts, limit trading, halt trading,
              cancel transactions, rollback transactions and return the relevant
              Digital Assets to any complainant so as to eliminate any adverse
              effects to the overall well-being of the market. In no event shall
              we be liable to you for any loss incurred when such action against
              you happens.
            </p>
            <p className="terms_para">
              4.16 We shall not ask for any password or private keys from our
              users, nor shall we ask users to transmit any funds or Digital
              Assets, as applicable. Accordingly, we shall not be responsible
              for any losses caused by your transmittal of funds or Digital
              Assets, as applicable.
            </p>
            <p className="terms_para">
              4.17 Users acknowledge that we may delist any Digital Asset from
              the JRN Platform at our sole discretion.
            </p>
            <p className="terms_para">
              4.18 With regard to abnormal transaction handling, while using the
              Services, you agree to and acknowledge the possibility of
              discontinuity and disruption of the Services due to connectivity
              problems of the Digital Asset networks or other force majeure.
              Users shall make sure to provide only correct information. We do
              not assume any responsibility for any losses caused by the
              aforementioned situations due to you providing incorrect
              information that results in our inability to reach out and to
              explain to you the handling procedures.
            </p>
            <p className="terms_para">
              4.19 We have the right to know the purpose and background of the
              users who use our products or services. Users should provide
              comprehensive and accurate information as required. If we have
              reasonable grounds to suspect that the User has provided false
              information, we are entitled to restrict the User from the use of
              some or all of Services temporarily or permanently.
            </p>
            <p className="terms_para">
              4.20 Any Digital Asset you purchase using a debit or credit card
              will be credited to your Account and our obligation in this regard
              will be deemed complete when the Digital Asset purchased by you is
              reflected in your Account. We are not responsible for any losses
              that may occur after that point. You are solely responsible for
              the management of those Digital Assets once credited.
            </p>
            <h2 className="terms_heading"  id="5-trading-on-the-jrn-platform">
              5 TRADING ON THE JRN PLATFORM
            </h2>
            <p className="terms_para">
              5.1 A User may buy or sell a Digital Asset by placing an
              instruction on the JRN Platform (an “Order”) in one (1) Digital
              Asset into another Digital Asset.
            </p>
            <p className="terms_para">
              5.2 Users should verify all transaction information prior to
              submitting an Order. Users acknowledge and agree that it is the
              sole responsibility of the User to ensure the validity and
              accuracy of an Order, including the details of the recipient of
              any Assets. JRN shall not be liable for the consequences of the
              User not providing valid and accurate information when placing an
              Order.
            </p>
            <p className="terms_para">
              5.3 The JRN Platform is accessible twenty-four (24) hours a day,
              seven (7) days a week and three hundred and sixty-five (365) days
              a year, except for any period during which scheduled maintenance
              or upgrades are being carried out, or in the event that the JRN
              Platform encounters an interruption or outage that is unexpected
              or beyond its control.
            </p>
            <p className="terms_para">
              5.4 JRN may, at its sole discretion and without prior notice close
              a User’s Account, or suspend a User’s access or use of the JRN
              Platform or the Services, refuse any Order submitted, or impose
              limits on trading amounts on the JRN Platform, if JRN deems that a
              User may have contravened Applicable Rules and Regulations.
            </p>
            <p className="terms_para">
              5.5 A User may only place an Order using the JRN Platform if the
              User’s Account contains sufficient Assets to cover the Order and
              any associated fees. If the Account does not contain sufficient
              Assets to complete an Order on the JRN Platform, the User will not
              be able to complete such Order on the JRN Platform.
            </p>
            <p className="terms_para">
              5.6 When a User places an Order, that quantity of the relevant
              Asset (including any applicable Fees) is placed on hold in the
              Account until the Order fills, expires, or is canceled. Any
              unfilled portion of an Order will remain on hold until it fills,
              expires, or is canceled.
            </p>
            <p className="terms_para">
              5.7 A User may withdraw or cancel an Order after it is submitted,
              as long as such Order has not been executed pending confirmation
              by the relevant Digital Asset network. Digital Assets which are
              the subject of a pending Trade shall not be reflected in a User’s
              Account, and shall therefore not be available for the User to
              trade. Users understand and agree that:
              <br />
              (a) Trades are irreversible once Orders are executed; and
              <br />
              (b) while JRN may, at its sole discretion, reverse a Trade under
              certain extraordinary conditions (including but not limited to
              breaches of the Applicable Laws and Regulations or Financial
              Crime), a User does not have a right to a reversal of a Trade.
            </p>
            <p className="terms_para">
              5.8 JRN will send a confirmation to the User by email, a Notice,
              or any other means of communication as provided for under these
              Terms, every time a User places an Order, cancels an Order, and
              when that Order is partially or completely filled.
            </p>
            <p className="terms_para">
              5.9 By entering into these Terms, Users acknowledge and agree
              that:
              <br />
              (a) by entering into a Trade on the JRN Platform, a User is
              instructing JRN to carry out the Trade at the buy price or sell
              price quoted on the JRN Platform on the basis of which the User
              placed the Order, and the User agrees to pay any associated fees.
              The price of a Trade shall be determined to be the price agreed
              between the User and the other party to that Trade, and JRN shall
              not be a party to such Trade or responsible for setting or
              negotiating the price of a Trade, except as set out specifically
              in these Terms. Users shall not claim against JRN regarding such
              Trades. JRN does not guarantee the completion of any attempted
              sale or purchase of Digital Assets;
              <br />
              (b) JRN carries out Trades based on price-time priority;
              <br />
              (c) they are subject to the same price-time priority as any other
              Users of the JRN Platform;
              <br />
              (d) Orders submitted through the API are subject to the same
              price-time priority as Orders received from the JRN Platform;
              <br />
              (e) all filled Orders are settled immediately by debiting and
              crediting the relevant balances of Assets in both parties’
              Accounts. Users will receive immediate notification of their
              fill(s) via the JRN Platform and/or via email or any other agreed
              means of communication and they can be viewed in a User’s trading
              history;
              <br />
              (f) APIs enable a User to stream live prices into their own user
              interface as well as to perform the same Trade or to place the
              same Order that is available to the User on the JRN Platform;
              <br />
              (g) For Users of JRN Singapore specifically, the counterparty to
              every User’s Trade shall be JRN Singapore.
              <br />
              (h) in order to maintain a fair and orderly JRN Platform, JRN may
              restrict the number of Orders allowed through the API and monitor
              messages through the API in order to:
              <br /> (i)protect the API against denial of service attacks;
              <br /> (ii)prevent the abuse of an order book; and
              <br /> (iii)improve the overall trading experience on the JRN
              Platform.
            </p>
            <p className="terms_para">
              5.10 JRN may limit the number of Orders placed through a User’s
              Account.
            </p>
            <p className="terms_para">
              5.11 Use of the API will be for legitimate trading purposes and
              any repeated violation of Order rate limits will result in JRN
              suspending or closing the User’s account in accordance with these
              Terms.
            </p>
            <p className="terms_para">
              5.12 The transaction history provided on the JRN Platform is the
              true and accurate record of a User’s transactions. It is a User’s
              responsibility to remain up to date on its transaction history and
              any Notices with respect to the User’s Account.
            </p>
            <p className="terms_para">
              5.13 Users understand and agree that there may be a delay in
              executing a Trade on the JRN Platform as a result of JRN’s secure
              trading protocols which require Digital Asset private keys to be
              stored securely using a combination of online and offline storage
              for added security.
            </p>
            <h3 id="scheduled-downtime">Scheduled downtime</h3>
            <p className="terms_para">
              5.14 The User agrees and understands that part of or all of the
              Services may be periodically unavailable during scheduled
              maintenance.
            </p>
            <p className="terms_para">
              5.15 Downtime may be communicated in advance to the User via a
              Notice published on the JRN Platform or by other means.
            </p>
            <h3 id="suspension-of-services">Suspension of Services</h3>
            <p className="terms_para">
              5.16 The User acknowledges and agrees that JRN shall have, at
              JRN’s sole discretion, the right to suspend all or part of the
              Services, or the User’s access to all or part of the Services,
              whenever JRN determines in JRN’s sole discretion that:
              <br />
              (a) the User may have breached these Terms; or
              <br />
              (b) the proper functioning of the JRN Platform is in jeopardy.
            </p>
            <p className="terms_para">
              5.17 For the purposes of Clause 5.16(b), the proper functioning of
              the JRN Platform will be in jeopardy in the following
              circumstances:
              <br />
              (a) when a computer or telecommunications network ceases to
              operate or function as a result of an accident;
              <br />
              (b) in the event of a Force Majeure Event which affects the
              provision of the Services;
              <br />
              (c) when JRN’s assets or the User’s Assets, or the JRN Platform,
              are the subject of an attempted or actual malicious attack which
              may, among other things, result in the theft or loss of such
              assets;
              <br />
              (d) when an event affects the proper functioning of critical
              systems relied upon by the JRN Platform, including the pricing
              system or any other system necessary for the provision of the
              Services;
              <br />
              (e) when JRN suspects unauthorized use of the JRN Platform, or a
              breach of these Terms or Applicable Laws and Regulations;
              <br />
              (f) when JRN determines that it is necessary, in its sole
              discretion, to conduct investigations in light of its requirements
              under Applicable Laws and Regulations or to ensure the proper
              functioning of the JRN Platform;
              <br />
              (g) when JRN decides to cease handling a Digital Asset based on
              the results of a Hard Fork that causes a chain split or changes to
              a Digital Asset;
              <br />
              (h) when JRN deems that the provision of the Services cannot
              continue due to changes in Applicable Laws and Regulations, social
              conditions, or factors outside JRN’s control, including but not
              limited to when a Digital Asset is no longer deemed an “Acceptable
              Digital Asset” by the Competent Authorities; or
              <br />
              (i) when for any other reason, JRN determines in its sole
              discretion that the suspension is necessary.
            </p>
            <h2 className="terms_heading"  id="6-risk-disclosure">6 RISK DISCLOSURE</h2>
            <h3 id="introduction">Introduction</h3>
            <p className="terms_para">
              6.1 This Clause 6 provides Users with information about the risks
              associated with trading in Digital Assets and any other Services
              provided by JRN. Applicants and Users should read this Risk
              Statement carefully before applying to open an Account and
              executing Trades. This Risk Statement should be read in
              conjunction with the{" "}
              <a href="/help/risk-compliance-disclosure" target="_blank">
                Risk and Compliance Disclosure
              </a>
              .{" "}
            </p>
            <p className="terms_para">
              6.2 This Clause 6 is not intended to disclose or discuss in detail
              all of the risks associated with trading or holding Digital Assets
              or the use of the Services provided by JRN. This Risk Statement is
              not exhaustive and only outlines the general nature of the risks
              involved. Users should ensure that their decisions are made on a
              well-informed basis, and they should undertake their own
              assessment as to the suitability of trading in Digital Assets in
              the light of their experience, objectives, financial resources and
              their specific needs and requirements.
            </p>
            <p className="terms_para">
              6.3 By opening an Account and undertaking Trades, a User is deemed
              to have reviewed, understood and accepted the risks associated
              with the Services provided by JRN.
            </p>
            <p className="terms_para">
              6.4 The market for Digital Assets is very new and rapidly
              developing and the risks of trading such assets are not fully
              understood. As such, JRN may update this Risk Statement
              periodically, as notified to Users from time to time, to list
              additional risks in transacting in Digital Assets, but accepts no
              liability whatsoever in the event that any risk is not addressed
              in the risk statement.
            </p>
            <p className="terms_para">
              6.5 Users acknowledge and agree that it is the User’s
              responsibility to stay up to date with the updated Risk Statement
              published by JRN from time to time.
            </p>
            <h3 id="overview-of-risks">Overview of risks</h3>
            <p className="terms_para">
              6.6 This Risk Statement addresses the following risks associated
              with transacting in Digital Assets in respect of the following
              matters:
              <br />
              (a) the nature of Digital Assets;
              <br />
              (b) value of Digital Assets and the risk of loss;
              <br />
              (c) past performance;
              <br />
              (d) suitability;
              <br />
              (e) availability of Digital Assets.
              <br />
              (f) currency risk;
              <br />
              (g) risk of Financial Crime and cybercrime;
              <br />
              (h) technology risk;
              <br />
              (i) legal risk;
              <br />
              (j) regulatory risk;
              <br />
              (k) stop risk; and
              <br />
              (l) Margin, gearing and leverage.
            </p>
            <h3 id="nature-of-digital-assets">Nature of Digital Assets</h3>
            <p className="terms_para">
              6.7 Digital Assets are not legal tender and are not backed by any
              government and may not be backed by any physical assets.
            </p>
            <p className="terms_para">
              6.8 There is no assurance that a Person who accepts Digital Assets
              as a form of payment or a store of value will continue to do so in
              the future.
            </p>
            <p className="terms_para">
              6.9 Digital Assets are generally considered a high-risk asset
              class. You must therefore exercise prudent judgment when trading
              Digital Assets (as well as any other assets).
            </p>
            <p className="terms_para">
              6.10 The nature of Digital Assets may be very complex, and their
              terms, features, and/or risks may not be readily or fully
              understood due to the complex structure, novelty and reliance on
              technological features.
            </p>
            <h3 id="value-of-digital-assets-and-the-risk-of-loss">
              Value of Digital Assets and the risk of loss
            </h3>
            <p className="terms_para">
              6.11 Investing in Digital Assets carries a high level of risk and,
              as such, may not be suitable for all investors. A User should not
              transact in Digital Assets if they are seeking a regular or low
              risk return on their invested capital. Unlike traditional
              investment products, Digital Assets do not generally have any
              underlying or intrinsic asset value or any assets supporting their
              price.
            </p>
            <p className="terms_para">
              6.12 Trading in Digital Assets is highly speculative and involves
              significant risk. There is limited or no fundamental reasoning
              behind the pricing of Digital Assets which may be subject to
              irrational and uncontrollable market forces. Whether the future
              price for a Digital Asset will increase or decrease is
              unpredictable and purely speculative. Likewise, there is a risk
              that a Digital Asset may not have any value whatsoever. Digital
              Assets are therefore traded at the User's own risk.
            </p>
            <p className="terms_para">
              6.13 Digital Asset values are highly volatile and can fluctuate
              substantially in comparison to Fiat Currencies or traditional
              investment products. As a result, market conditions can change
              significantly in a very short period of time. Furthermore, the
              value of a Digital Asset may never recover if there is no interest
              or development in the Digital Asset. A User is therefore at risk
              of losing all or a substantial portion of the value of its Digital
              Assets. Such losses can occur in a very short period of time.
            </p>
            <p className="terms_para">
              6.14 The use of instructions in relation to Orders may not
              necessarily limit losses to the expressed amount and market
              conditions may make it impossible to Fill an Order or to obtain
              the limit price.
            </p>
            <p className="terms_para">
              6.15 A User understands that they should be able to bear the risk
              of potential losses up to and even beyond the full amount of their
              invested capital and Users should only invest capital that they
              can afford to lose without impacting their standard of living and
              proportionate to his or her income. A User should cease trading in
              Digital Assets if their personal situation no longer permits.
            </p>
            <p className="terms_para">
              6.16 Trading in Digital Assets is not appropriate for Persons
              with:
              <br />
              (a) limited resources;
              <br />
              (b) limited investment experience; and/or
              <br />
              (c) a low risk appetite to investment or trading losses.
            </p>
            <p className="terms_para">
              6.17 A User should ensure that they have an understanding of the
              economic and other types of risks involved in trading Digital
              Assets. A User should seek professional advice where appropriate.
            </p>
            <p className="terms_para">
              6.18 Before opening an Account, a User is deemed to have evaluated
              and confirmed that they understand the risks involved.
            </p>
            <h3 id="past-performance">Past performance</h3>
            <p className="terms_para">
              6.19 The value of any Digital Asset may decrease as well as
              increase. Trading in Digital Assets may be susceptible to
              irrational market forces. Any data on the past performance of a
              Digital Asset does not guarantee, and may not be a reliable
              indicator of, future performance.
            </p>
            <h3 id="suitability">Suitability</h3>
            <p className="terms_para">
              6.20 JRN provides an execution-only service and does not advise on
              the merits of any particular Trade, trading risk or associated tax
              consequences, and JRN does not provide any other financial,
              investment or legal advice in connection with the Services.
            </p>
            <p className="terms_para">
              6.21 JRN acts as a platform for the trade execution of Orders and
              the custody of Digital Assets. As detailed in Clause 4.6, JRN and
              its Related Parties may engage in certain trading activities.
            </p>
            <p className="terms_para">
              6.22 JRN is therefore not responsible for assessing whether:
              <br />
              (a)the Services are suitable for a User; or
              <br />
              (b)any Trade is suitable for a User's needs.
            </p>
            <p className="terms_para">
              6.23 Any information provided on the JRN Platform is for
              information purposes only and is not, nor intended to be,
              financial advice, investment advice, financial advisory services,
              a trading recommendation or any other advice. JRN does not warrant
              the accuracy, completeness or usefulness of such information and
              such information should not be considered as an offer to buy or
              sell a Digital Asset. A User is solely responsible for determining
              whether any Trade is appropriate.
            </p>
            <p className="terms_para">
              6.24 By opening an Account, a User accepts the risk of trading in
              Digital Assets. In entering into any Trade, a User represents that
              they have been, are, and will be solely responsible for making
              their own independent appraisal and investigation into the risks
              of each Trade and the underlying Digital Assets.
            </p>
            <p className="terms_para">
              6.25 By utilising JRN’s services, each User represents that they
              have sufficient knowledge, market sophistication, professional
              advice and experience to make their own evaluation of the merits
              and risks of any Trade or any underlying Digital Asset prior to
              opening an Account.
            </p>
            <p className="terms_para">
              6.26 Each User must ensure that they seek professional advice, if
              necessary, taking into account their investment objectives, level
              of experience and risk tolerance.
            </p>
            <p className="terms_para">
              6.27 Each User should be fully aware of the specific
              characteristics and risks relating to the particular Digital Asset
              that the User is planning to trade.
            </p>
            <h3 id="availability-of-digital-assets">
              Availability of Digital Assets
            </h3>
            <p className="terms_para">
              6.28 The ability for a User to purchase Digital Assets using the
              JRN Platform may be dependent on the existence of a counterparty
              willing to sell the same Digital Asset (and vice versa). This is
              outside of JRN’s control. JRN makes no guarantee as to the timing
              or availability of the ability to purchase or sell Digital Assets
              via the JRN Platform.
            </p>
            <p className="terms_para">
              6.29 The value of Digital Assets may be derived from the continued
              willingness of Users to trade Digital Assets for Digital Assets,
              which may result in the potential for permanent and total loss of
              value of a particular Digital Asset should the market for it
              disappear. Thinly traded or illiquid markets have an increased
              potential risk of loss as a result of the enhanced volatility and
              a User may not be able to establish or liquidate positions in a
              Digital Asset when desired, at favourable prices or at all.
            </p>
            <p className="terms_para">
              6.30 A Digital Asset may change or otherwise cease to operate as
              expected due to changes made to or using its underlying
              technology, including by way of cyber-attack. Such changes may
              dilute the value of a User's Digital Asset position and/or
              distribute the value of a User's Digital Asset position to another
              Digital Asset.
            </p>
            <p className="terms_para">
              6.31 A "fork" refers to an upgrade in the code of a blockchain
              network, which may, for example, occur as a result of
              disagreements between stakeholders as to an update to a Digital
              Asset's protocol or from a deliberate effort to revert the
              blockchain history to a point prior to a cyber-attack. A Hard Fork
              may result in a permanent split from a previous version of the
              blockchain and will effectively create a new Digital Asset. The
              occurrence of a fork is outside of JRN’s control. The supply of
              Digital Assets as a result of a fork or similar changes to a
              Digital Asset's protocols and JRN’s ability to provide Services
              for the new Digital Asset which arises as a result may depend on
              third party providers which are outside of JRN’s control. JRN does
              not own or control any of the protocols that are used in
              connection with Digital Assets and their related networks.
              Accordingly, JRN is not liable in respect of such protocols or any
              change in the value of any Digital Asset (whether as a result of a
              fork or any similar change to a Digital Asset's Protocol or
              otherwise), and JRN makes no guarantees regarding the security,
              functionality, or availability of such protocols.
            </p>
            <p className="terms_para">
              6.32 A User is deemed to accept all risks associated with the use
              of the Services, including, but not limited to, the failure of
              hardware, software, and internet connections.
            </p>
            <p className="terms_para">
              6.33 There is a risk that you my experience losses due to the
              inability to sell or convert Digital Assets into a preferred
              alternative asset immediately or where conversion is possible but
              at a loss. Such liquidity risk for a Digital Asset may be caused
              by many reasons, including but not limited to the absence of
              buyers, limited buy/sell activity, or underdeveloped secondary
              markets.
            </p>
            <h3 id="currency--conversion-risks">
              Currency &amp; conversion risks
            </h3>
            <p className="terms_para">
              6.34 Trades may be executed in a currency different to the
              currency that the User deposited in its Account. The User should
              be aware of the risk of currency fluctuation and any risks related
              to it. A movement in an exchange rate may have a positive or
              adverse effect on the gain or loss achieved from any Trade.
            </p>
            <p className="terms_para">
              6.35 If you convert Digital Assets following the execution of an
              order or where an order is denominated in a particular Digital
              Asset other than your primary reference asset, there is a risk
              that, if the markets move against your position, then upon
              maturity or any earlier, the net proceeds may be significantly
              less than the initial amount in your primary reference asset, and
              any income or gains may be entirely negated.
            </p>
            <h3 id="custody-risk">Custody risk</h3>
            <p className="terms_para">
              6.36 JRN may hold Fiat Currencies and Digital Assets with third
              parties. However, the Digital Assets JRN holds are not “deposits”
              nor are they intended to be held as any other regulated product or
              service under Applicable Laws.
            </p>
            <p className="terms_para">
              6.37 In certain circumstances permitted by the Applicable Laws and
              Regulations or market practice of the relevant jurisdiction JRN
              may register or record a User’s Account in the name of the
              custodian or under JRN’s name. If the Accounts are held in the
              name of the custodian or JRN’s name, such assets may not be
              segregated from JRN’s assets and, in the event of a default by the
              custodian or JRN, may not be as well protected from claims of the
              creditors of the custodian or JRN’s creditors as would be the case
              if the User’s client assets had been segregated from the assets of
              the custodian or JRN’s assets. For Users of JRN Singapore
              specifically, Users’ assets are held in one or more segregated
              custodial accounts on trust for the benefit of the Users.{" "}
            </p>
            <p className="terms_para">
              6.38 In the event of the insolvency or any other analogous
              proceedings of a third party holding a User’s Fiat Currencies
              and/or Digital Assets, JRN may only have an unsecured claim
              against the third party on the behalf of a User and a User may be
              exposed to the risk that the Fiat Currencies, Digital Assets or
              any other property received by JRN from the third party is
              insufficient to satisfy the User’s claim and the claims of all
              other relevant Users.
            </p>
            <p className="terms_para">
              6.39 If JRN deposits a User’s Fiat Currencies and/or Digital
              Assets with a third party, such Fiat Currencies and/or Digital
              Assets may be pooled with those belonging to other Users. In such
              circumstances, a User’s individual client entitlements may not be
              separately identifiable by separate certificates, other physical
              documents of title or equivalent electronic records and, in the
              event of an irreconcilable shortfall after JRN’s insolvency, any
              Users whose assets have been pooled may share in that shortfall in
              proportion to their original assets in the pool. Any entitlements
              or other benefits arising in respect of pooled assets will be
              allocated pro rata to each User whose assets are so pooled.
            </p>
            <p className="terms_para">
              6.40 Fiat Currencies and/or Digital Assets may be held by a third
              party appointed in good faith by JRN, or by JRN’s nominees or
              sub-custodians. Such third parties are not under the control of
              JRN, and JRN accepts no liability for any default of any nature by
              such third parties and, in the event of any such default, a User
              may suffer total or partial loss in respect of the User’s Account.
              The extent to which a User may recover its Fiat Currencies and/or
              Digital Assets in jurisdictions may be governed by specific
              legislation or local rules.
            </p>
            <h3 id="risks-of-trading-on-the-jrn-platform">
              Risks of Trading on the JRN Platform
            </h3>
            <p className="terms_para">
              6.41 In comparison to other types of assets, including Fiat
              Currencies and securities, any Digital Asset transactions may not
              be subject to a right of claim under any investor compensation
              fund established by any government or regulatory authority;
              furthermore, Digital Assets held in an account on JRN may not be
              protected deposits, and may not be protected by any deposit
              protection scheme in any relevant jurisdiction. Thus, Digital
              Assets may have a reduced level and type of protection compared to
              fiat currencies, securities, and other asset classes and types.
            </p>
            <p className="terms_para">
              6.42 During the course of using our Services, you may be subject
              to various fees. Prior to making any transactions, you must obtain
              details and a full understanding of all commissions, fees, and
              costs for which you may be liable. If any of these fees are not
              clear to you, you must request clarification of what fees will be
              applicable in specific monetary terms before using the Services or
              entering into any transactions.
            </p>
            <p className="terms_para">
              6.43 Providing any other person access to your account(s) involves
              risk. You must take all necessary steps to ensure that any person
              you provide access to are appropriate and legal. You must also
              adopt controls and protocols relating to your account as you see
              fit in order to monitor the activities of such persons in order to
              ensure that they remain appropriate and legal in their capacity.
            </p>
            <p className="terms_para">
              6.44 Please note that Digital Assets received or held by JRN may
              be subject to other Applicable Laws of international jurisdictions
              beyond your country of residence and/or citizenship.
            </p>
            <p className="terms_para">
              6.45 There are substantial risks when allowing another person to
              trade or operate your account you have with us, and it is possible
              that any instructions you provide are not properly authorized or
              executed. You accept all risks of such operation and fully and
              irrevocably release JRN from any and all liability arising out of
              or in connection with all the aforementioned.
            </p>
            <p className="terms_para">
              6.46 Digital Asset transactions are irreversible. Thus, accidental
              or fraudulent transactions with respect to Digital Assets may not
              be recoverable. You must therefore exercise caution when making
              any Digital Asset transfers and are solely liable for any losses
              that may arise.
            </p>
            <p className="terms_para">
              6.47 An order made on JRN’s platform is binding upon completion of
              the steps described in these Terms. An order will not be reserved
              unless otherwise provided in these Terms. There is a risk that the
              final binding order does not occur simultaneously along with your
              instructions. Thus, you may suffer losses due to the fact that an
              order is not carried out at the desired time. In particular,
              contingent orders such as options or perpetual swaps may not limit
              your losses to the intended amounts due to the volatility of
              market conditions.
            </p>
            <p className="terms_para">
              6.48 There is a genuine risk that unauthorized third parties may
              access your account(s) and make transactions without your
              knowledge or authorization, whether by obtaining control over a
              device or account you use or by other methods.
            </p>
            <p className="terms_para">
              6.49 JRN is not obliged to provide any adaptations, enhancements,
              and/or modifications to the materials and information provided on
              the JRN Platform. This means, for example, that you may have an
              out-of-date version of the JRN app, and new features may not be
              incorporated to the version of the app you use. It is your
              responsibility to ensure you update and download applicable
              updates and versions.
            </p>
            <h3 id="risk-of-financial-crime-and-cyber-crime">
              Risk of Financial Crime and cyber crime
            </h3>
            <p className="terms_para">
              6.50 The nature of Digital Assets means that they may be exposed
              to an increased risk of Financial Crime or cyber-attack, which
              includes but is not limited to malware, hacking, phishing, double
              spending, smurfing, spoofing, sybil attacks, social engineering,
              majority-mining, consensus-based, or other mining attacks,
              misinformation campaigns, distributed denial of services, and
              forks. The transfer of Digital Assets into a digital wallet
              exposes the Digital Asset to the risk of loss from, amongst other
              things, security breaches from cyber-attacks that hack and steal
              Digital Assets.
            </p>
            <p className="terms_para">
              6.51 There is limited, or in some cases no, mechanism for the
              recovery of lost or stolen Digital Assets.
            </p>
            <p className="terms_para">
              6.52 You may store your Digital Assets in hot wallets, but these
              hot wallets may be at risk of hacking, cyber-attacks, or any of
              the risks mentioned in paragraph 6.51. Disruptions ,theft, cyber
              attacks and hacks of Digital Asset trading platforms and theft of
              Digital Assets unfortunately are very common. Victims may face
              extreme difficulty recovering losses from hackers, trading
              platforms, or other related industry organization and/or
              individuals. This could result in significant losses and other
              adverse impacts that may materially affect your interests.
            </p>
            <h3 id="technology-risk">Technology risk</h3>
            <p className="terms_para">
              6.53 Understanding Digital Assets requires advanced technical
              knowledge. Digital Assets are often described in exceedingly
              technical language that requires a comprehensive understanding of
              applied cryptography and computer science in order to appreciate
              inherent risks. The listing of a Digital Asset on the JRN Platform
              does not indicate approval or disapproval by JRN of the underlying
              technology regarding that Digital Asset and should not be used as
              a substitute for each User's understanding of the risks associated
              to that Digital Asset.
            </p>
            <p className="terms_para">
              6.54 The software protocols that underlie Digital Assets are
              typically open source projects, which means that:
              <br />
              (a) the development and control of such Digital Assets is outside
              of JRN’s control; and
              <br />
              (b) such software protocols are subject to sudden and dramatic
              changes that might have a significant impact on the availability,
              usability or value of a specific Digital Asset.
            </p>
            <p className="terms_para">
              6.55 The nature of Digital Assets means that technological
              difficulties experienced by JRN may prevent a User's access to, or
              use of, its Digital Assets. JRN may experience sophisticated
              cyber-attacks, unexpected surges in activity or other operational
              or technical difficulties that may cause interruptions to the
              Services, or may result in Digital Assets being lost and/or
              stolen. A User should understand that the Services may experience
              operational issues that lead to delays. By using the Services, a
              User agrees to accept the risk of transaction failure resulting
              from unanticipated or heightened technical difficulties, including
              those resulting from sophisticated attacks.
            </p>
            <p className="terms_para">
              6.56 Digital Assets are dependent upon new technologies, including
              distributed ledger technologies for the purposes of, amongst other
              things, anonymity, irreversibility of transactions, accidental
              transactions, transaction recording and settlement.
            </p>
            <p className="terms_para">
              6.57 Digital Asset transactions cannot be reversed. Once a User
              sends a Digital Asset to a wallet address outside of the JRN
              Platform, there is a risk that the User may lose access to the
              Digital Asset indefinitely. For example, a wallet address may have
              been entered incorrectly and the true owner of the wallet address
              may never be discovered, or a wallet address may belong to an
              entity that will not return the User's Digital Assets or which
              will only do so following action on the part of the User, such as
              verification of their identity.
            </p>
            <p className="terms_para">
              6.58 A User may lose the User’s private key for external wallet
              addresses in certain circumstances, including but not limited to
              the following:
              <br />
              (a) if the private key has been backed up on paper and
              subsequently lost or stolen; or
              <br />
              (b) if the private key has been hacked or stolen.
            </p>
            <p className="terms_para">
              6.59 The person in possession of a private key for an external
              wallet address will own the Digital Assets.
            </p>
            <p className="terms_para">
              6.60 The use of the JRN Platform on a jailbroken device may
              compromise security and lead to fraudulent transactions. You may
              suffer losses as well as a termination of the Services.
            </p>
            <p className="terms_para">
              6.61 Digital Assets and the JRN Platform depend on the internet
              and other technology (including various communication methods and
              mediums). However, the public nature of the internet means that
              parts or the entire internet may be unreliable or unavailable at
              any given time. Furthermore, interruption, delay, corruption or
              loss of data, the loss of confidentiality or privacy through the
              course of data transmission, or malware transmission may occur
              when transmitting data via the internet and/or other technology.
              The above may result in your transactions not being executed
              according to your instruction at the requested time, or not
              executed at all.
            </p>
            <p className="terms_para">
              6.62 There is no technology that is completely secure or safe. You
              should therefore exercise caution when using any technology.
            </p>
            <p className="terms_para">
              6.63 The internet as well as other electronic media (including but
              not limited to electronic devices, services by third-party telecom
              service providers such as mobile phones or other handheld trading
              devices or interactive voice response systems) are an inherently
              unreliable form of communication, and such unreliability may be
              beyond JRN’s control.
            </p>
            <p className="terms_para">
              6.64 Any information (including any document or data) transmitted,
              or communication or transactions made, over the internet or
              through other electronic media (including but not limited to
              electronic devices, services by third-party telecom service
              providers such as mobile phones or other handheld trading devices
              or interactive voice response systems) may be subject to
              interruption, transmission blackout, delayed transmission due to
              data volume, internet traffic, market volatility or incorrect data
              transmission (including incorrect price quotation), or pause
              and/or delay of price data feed due to the public nature of the
              internet or other electronic media.
            </p>
            <h3 id="legal--market-risks">Legal &amp; market risks</h3>
            <p className="terms_para">
              6.65 A User is responsible for complying with the Applicable Laws
              and Regulations and is solely responsible for determining which
              laws may apply to its transactions, including any applicable tax
              laws. A User is solely responsible for reporting and paying any
              taxes arising from its use of the Services.
            </p>
            <p className="terms_para">
              6.66 Due to the relatively recent development of Digital Assets,
              banks and other Competent Authorities have different views as to
              the risks associated with funds generated from the buying and
              selling of Digital Assets or may have a very low tolerance for
              risk. Accordingly, a User's bank may freeze its account or reject
              any incoming funds if the User’s bank concludes that such funds
              are the result of buying or selling Digital Assets.
            </p>
            <p className="terms_para">
              6.67 JRN may suspend a User's access to its Account. In such
              circumstances, the User will not be able to make deposits or
              withdrawals or trade. JRN may also suspend a User's ability to
              transact on the JRN Platform, in which case it will not be able to
              undertake the sale or purchase of a Digital Asset but it will be
              permitted to make deposits or withdrawals from its Account.
            </p>
            <p className="terms_para">
              6.68 You may suffer losses as a result of value depreciation of a
              Digital Asset you paid as a result of controls imposed by a
              Competent Authority. Repayment or payment of any amounts due to
              you may be delayed or even prevented by controls or other actions
              imposed by government or regulatory authorities over Digital
              Assets and/or their ecosystem that these Competent Authorities
              control or regulate.
            </p>
            <p className="terms_para">
              6.69 Digital Assets may, either because of their inherent design
              or through network events, not be a fixed supply. If and when
              additional Digital Assets are created, the particular Digital
              Asset’s price may decline due to inflationary effects of adding
              additional Digital Assets to the total available amount of assets
              in the market.
            </p>
            <p className="terms_para">
              6.70 At any point in time, one or more persons may directly or
              indirectly control significant portions of the total supply of any
              particular Digital Asset. These persons are often referred to
              colloquially as “whales”. Whether acting individually or
              collectively, these whales may have significant impact, and may be
              able to influence or cause significant market events that may have
              a detrimental effect on price, value or functionality of Digital
              Assets. Furthermore, these whales or other network participants
              and users may make decisions that are not in your best interest as
              an investor of Digital Assets.
            </p>
            <h3 id="regulatory-risk">Regulatory risk</h3>
            <p className="terms_para">
              6.71 Digital Assets activities conducted in certain jurisdictions
              may be unregulated or subject to limited regulation.
            </p>
            <p className="terms_para">
              6.72 Any regulatory changes or actions by the Competent
              Authorities or any authorities that are not connected to JRN may
              adversely affect the use, transfer, exchange and value of a
              Digital Asset.
            </p>
            <p className="terms_para">
              6.73 The domestic government of a User may make it illegal for the
              User to trade Digital Assets.
            </p>
            <h3 id="stop-loss">Stop Loss</h3>
            <p className="terms_para">
              6.74 A Stop Order is an offer to deal if our quote becomes less
              favourable to you. A Stop Order is generally placed to provide
              some risk protection, for example in the event of your Trade
              moving into loss, and can be used to either open or close a Trade.
              Each Stop Order has a specific stop level, set by you. Your Stop
              Order will be triggered if our bid price (in the case of an order
              to sell) or our offer price (in the case of an order to buy) moves
              against you to a point that is at or beyond the level specified by
              you. Notwithstanding the specific stop level set by you, we may be
              required by Applicable Laws and Regulations to close or part close
              a Trade prior to your Stop Order being triggered.
            </p>
            <p className="terms_para">
              6.75 You agree and understand that Stop Orders will not
              necessarily limit your losses to the intended amounts, since
              market conditions may make it impossible to execute such Orders at
              a price close to your intended stop price or at all. In extremely
              volatile markets, the price of a Digital Asset may fall rapidly
              through your Stop Order price and you may suffer losses well in
              excess of the amount realised had your Stop Order been executed at
              the Stop Order price.
            </p>
            <h3 id="margin-gearing-and-leverage">
              Margin, Gearing and Leverage
            </h3>
            <p className="terms_para">
              6.76 Before you are allowed to enter into a Contract with us, you
              will generally be required to deposit money with us – this is
              called the Margin requirement. This Margin requirement will
              usually be a proportion of the overall Contract value. This means
              that you will be using ‘leverage’ or ‘gearing’ and this can work
              for or against you. A small price movement in your favor can
              result in a high return on the margin requirement placed for the
              Contract, but a small movement against you may result in
              substantial losses.
            </p>
            <p className="terms_para">
              6.77 At all times that you have open positions, you must ensure
              that your account balance, taking account of running profits and
              losses, is equal to at least the total Margin requirement that we
              require you to have deposited with us. Therefore, if our price
              moves against you, you may need to provide us with significant
              additional funds immediately to meet your margin requirement and
              maintain your open positions. If you do not do this, we will be
              entitled, in our absolute discretion, to close one or more or all
              of your positions in any Digital Asset you hold. You will be
              responsible for any losses incurred as a result.
            </p>
            <p className="terms_para">
              6.78 The need to monitor your positions is of greater importance
              when you have entered into Contracts with us because of the effect
              of gearing. Gearing magnifies the rate at which profits or losses
              can be incurred and, as a result, it is important that you monitor
              your positions closely.
            </p>
            <p className="terms_para">
              6.79 We are not under any obligation to keep you informed of your
              account balance and Margin required. (i.e. to make a 'Margin
              call') However, if we do so, the Margin call may be made by
              telephone call, post, email, text message or through your JRN
              Platform.
            </p>
            <p className="terms_para">
              6.80 We will be entitled, at any time, and at short notice, to
              increase or decrease the Margin required from you on open Trades.
              You agree that, regardless of the normal way in which you and we
              communicate, we will be entitled to notify you of a change to
              Margin levels or the credit arrangements for your account by any
              of the following means: telephone, email, text message, via our
              JRN Platform or by posting notice of the change on our JRN
              Platform. Any increase in Margin levels will be due and payable
              immediately on our demand. We will only increase Margin
              requirements or change the credit arrangements for your account
              where we, in our absolute discretion, reasonably consider it to be
              necessary, including but not limited to, in response to or in
              anticipation of:
              <br />
              (a) a change in the volatility and/or liquidity in the Digital
              Asset market;
              <br />
              (b) economic news;
              <br />
              (c) a Digital Asset is rumored to being suspended from trading on
              another Digital Asset trading platform;
              <br />
              (d) you change your dealing pattern with us; or
              <br />
              (e) any change to the Applicable Laws and Regulations.
            </p>
            <p className="terms_para">
              6.81 Subject to Applicable Laws and Regulations, for certain
              categories of clients we may not be able to accept the payment of
              margin to be made by credit card or by any other credit
              arrangement made with third parties.
            </p>
            <p className="terms_para">
              6.82 Please note that in certain instances we may trigger a
              socialised clawback mechanism to take a portion of your gain to
              cover any shortfall. The socialised clawback mechanism is
              triggered when JRN’s insurance funds are not able to cover a
              User’s losses. In these instances, JRN will use its socialised
              clawback mechanism to clawback net profits from other Users to
              cover shortfalls. More information on the socialised clawback
              mechanism can be found{" "}
              <a
                href="/help/upgrade-on-liquidated-futures-position-price-calculation-and-price-change-strategy-for-liquidated-futures-positions"
                target="_blank"
              >
                here
              </a>
              .
            </p>
            <p className="terms_para">
              6.83 In the event of a potential loophole in the trading rules or
              platform system failure or a loophole that unjustly enriches the
              User, we will contact the User to recover the gains. The User must
              fully and effectively cooperate; otherwise, we will take any and
              all actions, including, but not limited to restrictions on account
              transactions, freezing your account(s) or funds in your account(s)
              funds, debit assets from your account(s) to recover gains that
              unjustly enrich the User, prosecution of the User in a court with
              right of jurisdiction and any other recourse measures. In the
              event that the User does not cooperate, the User will also bear
              any and all recourse costs.
            </p>
            <p className="terms_para">
              6.84 Under certain circumstances or situations, it may be
              difficult or even impossible to liquidate a position in Digital
              Assets. Certain events that occur on the network may occur rapidly
              and affect the ability to conduct transactions on JRN’s platform.
              Information relating to these network events may be difficult to
              predict or ascertain beforehand and may be subject to limited
              oversight by any third party who may be capable of intervening in
              order to stabilize the network.
            </p>
            <h3 id="other-potential-risks">Other Potential Risks</h3>
            <p className="terms_para">
              6.85 Website and internet pages, investor relations releases, oral
              or written outlooks, presentations, audio and video recordings of
              events, and other publications from websites may contain
              optimistic, forward-looking statements that reflect JRN and its
              management’s current views with respect to prospective projects
              and events. Certain words, including but not limited to,
              “anticipate,” “assume”, “believe”, “estimate”, “expect”, “intend”,
              “may”, “plan”, “project”, and “should”, as well as other
              expressions that often identify forward looking statements. These
              statements are subject to risks, uncertainties, and changes due to
              many factors, including but not limited to: changes in Digital
              Asset and Fiat Currency exchange rates, interest rates, and
              commodity prices; the introduction of new and/or competing
              technology and ideas; increased market incentives; and a decline
              in demand for current Services or other products.
            </p>
            <h2 className="terms_heading"  id="7-your-jrn-account">7 YOUR JRN ACCOUNT</h2>
            <p className="terms_para">
              7.1 Account Registration and Identity Verification: in order to
              use any of the Services, you are required to provide accurate
              information for all fields on the registration page to verify your
              identity, along with affirming these Terms. By registering an
              account with JRN, You agree and represent that You will use the
              Account only for yourself, and not on behalf of any third party,
              unless approved by JRN. You agree to provide us with authentic
              information at registration and on an ongoing basis for the
              purposes of identity verification and the detection of money
              laundering, terrorist financing, fraud, or any other financial
              crime, including without limitation a copy of your government
              issued photo ID and evidence of residency such as a utility bill
              or bank statement. If any such information changes, it is your
              obligation to update such information as soon as possible.
            </p>
            <p className="terms_para">
              7.2 Protecting your Account: you agree to not enable anyone to use
              or direct your account, and to update us of any information change
              or if your account has been compromised. You are responsible for
              keeping, protecting and safeguarding any keys, certificates,
              passwords, access codes, user IDs, API Key or other credentials
              and login information (collectively "Passwords") that have been
              provided to you or that are generated in connection with your use
              of the Services. If you lose your Passwords, you may not be able
              to access your account. You agree to notify us immediately of any
              unauthorized use of your Passwords. We will not be responsible for
              any liabilities, losses, or damages of any kind, whether direct or
              indirect, arising out of the unauthorized use of your Passwords.
              You understand and agree that if you authorize any third party or
              third-party platform to use the Passwords information of your
              account, the third party or third-party platform will know your
              account information (including without limitation, information in
              relation to your assets, transactions, operating authority for
              trading and account information, etc.), and your account will be
              exposed to certain risks and may suffer loss or damage. You agree
              to be solely responsible for any loss or damage caused by you
              authorizing any third party or third-party platform to use your
              Passwords information, and any loss or damage caused to you by the
              third party or third-party platform using your account Passwords
              information. We will not be liable for any compensation or other
              legal liabilities for the loss or damage caused by the
              above-mentioned circumstances. We provide an online trading
              platform, and to avoid potential security risks, the login
              password, administration password and any other passwords shall
              not be set as the same. Relevant responsibilities shall be borne
              by the users. Once the user is registered, and becomes a user of
              the JRN Platform, he or she will get the username (user mailbox)
              and password, and is responsible for all activities and events
              with this username and password after entering the system, and
              bear all the legal liability directly or indirectly caused by the
              language and behaviors of the use of this username.
            </p>
            <p className="terms_para">
              7.3 Password Recovery: users who lose their passwords can reset it
              after being verified through their registered email addresses or
              phone numbers. You shall immediately report to us if any
              unauthorized operations or security breaches are found.
            </p>
            <p className="terms_para">
              7.4 Account Balance: if your account shows a credit balance, you
              may request us to return available Digital Assets. However, we may
              at our discretion elect to withhold (or if applicable, deduct)
              your request to withdraw (in whole or in part) Digital Assets to
              you if:
              <br />
              (a) your open derivatives transactions show notional losses;
              <br />
              (b) we consider that additional Digital Assets may be required to
              meet any current or future margin requirement on open derivative
              positions due to underlying market conditions;
              <br />
              (c) you have any actual or contingent liability towards us under
              the Terms; and/or
              <br />
              (d) we reasonably determine that there is an unresolved dispute
              between us in connection with the Terms.
            </p>
            <p className="terms_para">
              7.5 Account Closure: you may request JRN to close your account at
              any time. Closing an account will not affect any rights and
              obligations incurred prior to the date of account closure. Prior
              to JRN closing your account, you may be required to either cancel
              or complete all open orders and, in accordance with the provisions
              of these Terms, provide transfer instructions of where to transfer
              any Fiat Currency and/or Digital Assets remaining in your account.
              You are responsible for any fees, costs, expenses, charges, or
              obligations (including, but not limited to, attorney and court
              fees or transfer costs of Fiat Currency or Digital Assets)
              associated with the closing of your account. In the event that the
              costs of closing your account exceed the value in your account,
              you will be responsible for reimbursing us. You may not close any
              of your accounts to avoid paying any fees otherwise due or to
              avoid any examination related to our AML Program. JRN reserves its
              absolute right to accept or reject an account closure request at
              its sole discretion.
            </p>
            <p className="terms_para">
              7.6 Account Suspension: JRN has the right at any time to suspend
              Your Account(s). Also, you agree that we can freeze/lock the funds
              and assets in Your Account(s) and suspend part of or all of your
              access to the JRN Platform, if we suspect, at our sole discretion,
              any of the following:
              <br />
              (a) You/ Your Account(s) are or have been operated in violation of
              any terms and conditions of these Terms and any other policies,
              rules, regulations, user agreements, or programs of JRN, including
              but not limited to the JRN AML Program;
              <br />
              (b) Your Account has a balance that needs to be reconciled for any
              reason;
              <br />
              (c) an unauthorized person is attempting to gain access to Your
              Account;
              <br />
              (d) you are using your credentials or other account information in
              an unauthorized or inappropriate manner;
              <br />
              (e) for reasons relating to Clause 12 of these Terms;
              <br />
              (f) for reasons relating to investigations under Clause 7.7 of
              these Terms; or
              <br />
              (g) an Event of Default (as defined below in Clause 22.6 of these
              Terms) has occurred.
            </p>
            <p className="terms_para">
              Also, we may require you to submit additional information and
              complete other verification or attestation actions if there is
              need to conduct Enhanced Due Diligence on You/ Your Account(s).
            </p>
            <p className="terms_para">
              7.7 Investigation: JRN shall investigate and take action(s)
              against You/ Your Account(s), including suspending, freezing
              and/or seizing Your Account(s), in the event that we suspect or
              otherwise have cause to believe, at our sole discretion, any of
              the following:
              <br />
              (a) You/ Your Account(s) are the subject of any investigation by
              law enforcement, government bodies, warrants, subpoenas, court
              orders or other similar procedures and processes in any relevant
              jurisdiction;
              <br />
              (b) You/ Your Account(s) are the subject of any pending and/or
              current litigation in any applicable jurisdiction;
              <br />
              (c) You/ Your Account(s) are in violation or have been involved in
              a violation of any Applicable Laws or Regulations; or
              <br />
              (d) You/ Your Account(s) are involved in suspicious, illegal or
              fraudulent activities.
            </p>
            <p className="terms_para">
              7.8 Account Termination: JRN has the right to terminate Your
              Account(s) at any time and for any reason, and at our sole
              discretion, including those in Clause 11. You further agree and
              understand that we have the right to take any and all necessary
              and appropriate actions pursuant to these Terms, including but not
              limited to recovering reasonable costs, and in accordance with any
              Applicable Laws and Regulations.
            </p>
            <p className="terms_para">
              7.9 Effect of Suspension or Termination: JRN shall continue to
              maintain custody of all Digital Assets, funds, and information in
              or relating to such Accounts, which may be seized and turned over
              to any third parties, including but not limited to the Competent
              Authorities, in the event that Your Account(s) was suspended or
              terminated pursuant to the reasons set out in Clause 7 of these
              Terms.
            </p>
            <p className="terms_para">
              7.10 If Your Account(s) is terminated, subject to Clause 7.9 and
              any ongoing investigations and considerations, we will return your
              funds, less the value of any trading fee discounts, rebates,
              costs, expenses and/or damages that we are entitled to pursuant to
              these Terms and applicable laws and regulations in any applicable
              jurisdiction(s). If Your Account is not, or is no longer subject
              to an investigation, court order, subpoena or similar legal
              procedure in any applicable jurisdiction(s), you authorize us to
              return your funds (less any trading fee discounts, rebates, costs,
              expenses and/or damages to which we are entitled) to any bank
              account linked to your account, unless otherwise required by
              applicable laws and regulations. If there is any Digital Asset
              balance remaining in Your Account(s), you agree to provide us with
              a Digital Asset address upon receiving written notice from us, so
              that we can return the remaining Digital Assets to you.
            </p>
            <p className="terms_para">
              7.11 In addition to the foregoing, and to the fullest extent
              permitted under Applicable Laws and Regulations, the event that an
              Event of Default occurs,
              <br />
              (a) any delivery, payment or other obligation owed by you to any
              party, including but not limited to JRN, pursuant to activities
              involving Your Account(s) shall mature, accrue or otherwise be
              accelerated so as to require performance thereof on the date such
              Event of Default ("Accelerated Obligations"); and
              <br />
              (b) without prejudice to such other rights that may accrue to JRN
              under these Terms or otherwise, JRN shall be entitled to take all
              actions deemed necessary in its sole discretion to protect its
              interests and discharge the Accelerated Obligations, including but
              not limited to closing any open trading positions, liquidating
              assets in Your Account to repay any amounts and other liquidatable
              obligations owed to JRN, and transferring assets to third parties
              in accordance with court orders.
            </p>
            <h2 className="terms_heading"  id="8-users-rights-and-limitations-to-license-use">
              8 USERS’ RIGHTS AND LIMITATIONS TO LICENSE USE
            </h2>
            <p className="terms_para">
              8.1 We grant you a limited, non-exclusive, non-transferable
              license, subject to the Terms, to access and use our JRN Platform
              and Services, solely for approved purposes as permitted by us. You
              agree that you will not copy, transmit, distribute, sell, license,
              reverse engineer, modify, publish, or participate in the transfer
              or sale of, create derivative works from, or in any other way,
              exploit any of our products and Services.
            </p>
            <p className="terms_para">
              8.2 You agree that:
              <br />
              (a) all rights, title and interest in the Services and associated
              software, Website and technology, including all intellectual
              property rights therein, are and shall remain with the Parties;
              <br />
              (b) no right or interest in the Services is conveyed other than
              the limited licenses granted herein;
              <br />
              (c) the Services are protected by the copyright and other
              intellectual property laws;
              <br />
              (d) all rights not expressly granted in these Terms are reserved.
              <br />
              (e) We have the right to inquire, freeze or deduct the items and
              accounts of the user in accordance with the requirements of any
              applicable judicial organizations, administrative organizations
              and military organizations, including but not limited to public
              security organizations, procuratorial organizations, courts,
              customs, tax authorities and so on.
            </p>
            <h2 className="terms_heading"  id="9-user-obligations">9 USER OBLIGATIONS</h2>
            <p className="terms_para">9.1 You shall not register multiple accounts for any purpose.</p>
            <p className="terms_para">9.2 You may not use another user’s accounts.</p>
            <p className="terms_para">
              9.3 You must not use this JRN Platform or the Services in any way
              that causes, or may cause, damage to this JRN Platform or
              impairment of the availability or accessibility of this JRN
              Platform; in connection with any illegal fund for payment and
              settlement including, but not limited to, payment by illegal means
              like fictitious transactions, false price, transaction refund and
              so on; illegal cash-out activity via entity bank settlement
              accounts; illegal fund transfer from the bank settlement account
              of an entity to an individual account; illegal check cash-out
              activity; or for any unlawful, illegal, fraudulent or harmful
              purpose or activity under Applicable Laws and Regulations. You
              agree to indemnify and hold Aux Cayes, JRN HK, JRN Brazil, JRN
              Bahamas, JRN Singapore, OKC EU and their officers, directors,
              shareholders, predecessors, successors in interest, employees,
              agents, subsidiaries and affiliates, harmless from any demands,
              loss, liability, claims or expenses (including attorneys’ fees)
              made by any third party against Aux Cayes, JRN HK, JRN Brazil, JRN
              Bahamas, JRN Singapore, OKC EU or its affiliates due to or arising
              out of or in connection with You’ use of this JRN Platform or
              Services.
            </p>
            <p className="terms_para">
              9.4 You are prohibited in any form from utilizing the Website to
              engage in illegal activities. Without the authorization or
              permission of JRN, you shall not use any of the commercial
              activities in the name of this Website or in any form of the
              Website as a place, platform or medium for engaging in commercial
              activities.
            </p>
            <p className="terms_para">
              9.5 You shall comply with all laws and regulations, and bear the
              responsibility and legal consequences of any their own actions
              involving the Website and Services. In addition, you shall not
              infringe the legitimate rights and interests of any third party.
              Additionally, if JRN suffered losses as a result of your actions,
              JRN has the right to recover losses from you via legal actions or
              other means.
            </p>
            <p className="terms_para">
              9.6 You shall bear any loss as a result of your actions, including
              but not limited to:
              <br />
              (a) “fat finger” input or instructions errors, including price,
              quantity, and/or timing (market vs. limit order specification)
              errors;
              <br />
              (b) mistiming or mis-submission of trade instructions;
              <br />
              (c) forgetting or disclosing your password;
              <br />
              (d) computer or network issues, including any hacks or virus
              issues related to your computer or network (or the network you are
              utilizing);
              <br />
              (e) transfer-in or withdrawal of Digital Assets or Fiat currencies
              to or from the wrong account;
              <br />
              (f) executing instructions provided to you by a third-party; or
              <br />
              (g) third parties accessing and using your account for any reason.
            </p>
            <p className="terms_para">
              9.7 It is your sole responsibility to ensure that all account
              information is verified before making any transfers so that
              digital assets or fiat currencies are transferred into the correct
              account. If you transfer digital assets or fiat currencies to a
              wrong account and such account is controlled by us, we will have
              the sole discretion to reject the transaction and return to you
              the relevant amount of digital assets or fiat currencies, less
              applicable fees.
            </p>
            <p className="terms_para">
              9.8 If you violate any obligation above, JRN has the right to take
              all necessary measures directly, including but not limited to
              deleting the content posted by you, canceling the customer star
              rating and honor, freezing your balance, pausing or seizing your
              Account and illegal gains, and prosecution.
            </p>
            <h2 className="terms_heading"  id="10-fees">10 FEES</h2>
            <p className="terms_para">
              10.1 Fees may be incurred for part of the Services provided by us
              to you. By using our Services, you agree to incur these fees.
            </p>
            <p className="terms_para">
              10.2 JRN has the right to set and modify its fee structure at its
              discretion. We may also start charging fees on free services. Any
              fee changes shall be announced in advance.
            </p>
            <p className="terms_para">
              10.3 If you do not agree with any fees charged, you may stop using
              one or more of the Services immediately. You are still responsible
              for any fees incurred up to the date of termination of the
              Services.
            </p>
            <p className="terms_para">
              10.4 Unless otherwise stated or agreed, you agree that we have the
              right to automatically and without prior notice deduct the
              above-mentioned service fees directly from the assets of your
              account.
            </p>
            <p className="terms_para">
              10.5 If you do not pay in full and on time, we reserve the right
              to interrupt, suspend or terminate your account.
            </p>
            <h2 className="terms_heading"  id="11-change-interruption-termination-and-discontinuance-of-services">
              11 CHANGE, INTERRUPTION, TERMINATION, AND DISCONTINUANCE OF
              SERVICES
            </h2>
            <p className="terms_para">
              11.1 Service Change and Interruption: we may change the Services
              and/or may also interrupt, suspend or terminate the Services at
              any time with or without prior notice.
            </p>
            <p className="terms_para">
              11.2 Service Discontinuance and Termination: we reserve the right,
              at our sole discretion, to discontinue or terminate the services
              provided to you without notice, temporarily or permanently,
              including, but not limited to, in the following cases:
              <br />
              (a) if the personal information you have provided is not true,
              complete, accurate, or is inconsistent with the information
              provided at the time of registration and you have failed to
              provide reasonable proof (please remember, according to the
              Applicable Laws and Regulations you should submit true, complete
              and accurate information);
              <br />
              (b) if You violate the Applicable Laws and Regulations;
              <br />
              (c) if You/ Your Account(s) violate any terms and conditions of
              these Terms, and any other policies, rules, regulations, user
              agreements, or programs of JRN, including but not limited to the
              JRN AML Program;
              <br />
              (d) if required by any provisions of Applicable Laws and
              Regulations, or by the relevant Competent Authorities;
              <br />
              (e) for reasons relating to investigations under Clause 7.7 of
              these Terms; or
              <br />
              (f) for security reasons, or other necessary circumstances, in
              order to protect Your Account(s) from unauthorised access, among
              other things, as determined at our sole discretion.
            </p>
            <h2 className="terms_heading"  id="12-unclaimed-property">12 UNCLAIMED PROPERTY</h2>
            <h3 id="obligations-of-jrn">Obligations of JRN</h3>
            <p className="terms_para">
              If you have any assets held by JRN in your Account and JRN is
              unable to contact you and has no record of your use of the
              Services for a period of time exceeding not less than twelve (12)
              months or, in certain jurisdictions, the period of time required
              by applicable laws or regulations in relevant jurisdiction(s), JRN
              may be required to report these funds as unclaimed property in
              accordance with the Applicable Laws and Regulations. JRN will use
              reasonable endeavors to locate you using the contact information
              available to JRN.
            </p>
            <p className="terms_para">
              12.1 If JRN is unable to locate you, JRN reserves the right to:
              <br />
              (a) close your Account and terminate these Terms; and
              <br />
              (b) deliver all assets as unclaimed property as it may be directed
              by the Competent Authorities or any court of competent
              jurisdiction.
            </p>
            <p className="terms_para">
              12.2 If we receive any document(s) confirming your death or other
              information leading JRN to believe that you have died, JRN
              reserves the right to freeze your Account.
            </p>
            <p className="terms_para">
              12.3 Your Account will be unfrozen either:
              <br />
              (a) when your designated fiduciary has opened a new Account, as
              further described in clause 12.5 below, and the entirety of your
              Account has been transferred to such new account, or
              <br />
              (b) JRN has received proof in a form satisfactory to us that you
              have not died.
            </p>
            <p className="terms_para">
              12.4 If JRN has reason to believe you may have died but does not
              have proof of your death in a form satisfactory to JRN, you
              authorize JRN to make enquiries, whether directly or through third
              parties, that we consider necessary to ascertain whether you have
              died. Upon receipt by JRN of proof satisfactory to JRN that you
              have died, the fiduciary you have designated in a valid will or
              similar testamentary document will be required to open a new
              Account.
            </p>
            <p className="terms_para">
              12.5 If you have not designated a fiduciary, then JRN reserves the
              right to treat as your fiduciary any person entitled to inherit
              your Account, as determined by JRN upon receipt and review of the
              documentation that JRN, in its sole and absolute discretion, deems
              necessary or appropriate, including (but not limited to) a will, a
              living trust or other similar documentation, or an order
              designating a fiduciary from a court having competent jurisdiction
              over your estate.
            </p>
            <p className="terms_para">
              12.6 In the event JRN determines, in its sole and absolute
              discretion, that there is uncertainty regarding the validity of
              the fiduciary designation, JRN reserves the right to require an
              order resolving such issue from a court of competent jurisdiction
              before taking any action relating to your Account.
            </p>
            <h3 id="expenses">Expenses</h3>
            <p className="terms_para">
              12.7 JRN shall be entitled to deduct from any Fiat Currency all
              costs and expenses incurred in connection with this Clause 12.
            </p>
            <h2 className="terms_heading"  id="13-compliance-with-your-local-laws-and-taxes">
              13 COMPLIANCE WITH YOUR LOCAL LAWS AND TAXES
            </h2>
            <p className="terms_para">
              13.1 It is your sole responsibility to determine whether, and to
              what extent, any taxes apply to any transactions you conduct
              through the Services, and to withhold, collect, report and remit
              the correct amounts of taxes to the appropriate tax authorities.
              You agree that we do not provide legal or tax advice and are not
              responsible for determining whether taxes apply to your
              transactions, or for collecting, reporting, withholding or
              remitting any taxes arising from any transactions. It is highly
              advisable that you consult your lawyer/ professional tax adviser
              to determine your obligations and your tax status in your home
              jurisdiction.
            </p>
            <p className="terms_para">
              13.2 Additionally, you agree to comply with all relevant laws of
              the Seychelles, the Bahamas, Brazil, Hong Kong, Malta and
              Singapore, and any relevant laws and regulations in any other
              applicable jurisdiction(s). Regarding the prevention of terrorist
              financing and anti-money laundering (“AML”), and also compliance
              with laws and regulations in any applicable jurisdiction(s) more
              generally, we will work with the Competent Authorities and local,
              regional, national and multi-national authorities around the world
              as required, and may provide any information associated with You
              to such authorities. We may also access and disclose information
              we stored associated with You to external third parties if, in our
              sole discretion, we believe doing so is required or appropriate
              to: comply with relevant law enforcement or national security
              requests and legal procedure in any applicable jurisdiction(s),
              such as a court order or a subpoena; protect your, our, or others’
              rights, property, or safety; enforce our applicable service terms,
              user agreement, trading rules, operational rules, policies or any
              other agreements; collects amounts owed to us; or assist with an
              investigation or prosecution of suspected or actual illegal
              activity.
            </p>
            <p className="terms_para">
              13.3 When using our Services, you acknowledge, represent and
              undertake and agree that all your actions on and off this JRN
              Platform are made in a legal and proper manner and your sources of
              Digital Assets and fiat currency are not from illegal activities.
              If in our sole discretion we believe that You are in breach of the
              above representation and undertaking, we may discretionarily or in
              coordination with local law enforcement authorities seize, freeze,
              restrict or close-out Your Account(s) and any fiat currency and
              Digital Assets held on your Account(s).
            </p>
            <h2 className="terms_heading"  id="14-privacy-policy">14 PRIVACY POLICY</h2>
            <p className="terms_para">
              14.1 Please refer to our{" "}
              <a href="/help/privacy-policy-statement" target="_blank">
                Privacy Policy Statement
              </a>{" "}
              for information about how we collect, use, and share your
              information.
            </p>
            <h2 className="terms_heading"  id="15-limitation-of-liability-warranties-and-indemnification">
              15 LIMITATION OF LIABILITY, WARRANTIES AND INDEMNIFICATION
            </h2>
            <h3 id="exclusion-of-liability">Exclusion of liability</h3>
            <p className="terms_para">
              15.1 Neither JRN nor any of its Related Parties shall be liable
              for any Loss arising, directly or indirectly, from:
              <br />
              (a) any dispute between you and one or more other Users;
              <br />
              (b) any Losses arising from your breach of these Terms;
              <br />
              (c) any Losses arising from your violation of any Applicable Laws
              and Regulations; or
              <br />
              (d) any act or omission by a third party with access to your
              Account,
            </p>
            <p className="terms_para">
              and you hereby release and waive any right to claim any such Loss
              from JRN and/or its Related Parties.
            </p>
            <h3 id="limitation-of-liability">Limitation of liability</h3>
            <p className="terms_para">
              15.2 To the fullest extent permitted by the Applicable Laws and
              Regulations, the total aggregate liability of JRN for Losses that
              the User may suffer arising out of, or in connection with the
              information or material provided by or on behalf of JRN and its
              Related Parties on the JRN Platform shall be limited to a maximum
              aggregate value of USD100.
            </p>
            <p className="terms_para">
              15.3 To the fullest extent permitted by the Applicable Laws and
              Regulations, the total aggregate liability of JRN for Losses,
              arising out of an individual claims or series of claims, incurred
              by:
              <br />
              (a) an Applicant arising out of, or in connection with an
              application;
              <br />
              (b) You arising out of, or in connection with:
              <br />
              (i) the Services;
              <br />
              (ii) any performance or non-performance of the Services;
              <br />
              (iiiany information or material provided by, or on behalf of, JRN
              and/or its Related Parties (other than information or materials
              referred to in Clause 15.3); or
              <br />
              (iv)any other breach of these Terms,{" "}
            </p>
            <p className="terms_para">
              shall be limited in aggregate to the lower of:
              <br />
              (a) USD 100; or
              <br />
              (b) the total fees paid by the User to JRN in respect of the
              Services during the preceding twelve (12) month period (the
              “Cap”).
            </p>
            <p className="terms_para">
              15.4 Where a claim arises in relation to a specific Trade, the
              total liability to the User, of JRN, its shareholders, affiliates
              or services providers, and their respective officers, directors,
              employees, agents or representatives shall be limited to the
              purchase / sale amount of the Trade in dispute by reference to the
              price for such Trade at the time it took place or ought reasonably
              to have taken place and in any case subject to the Cap.
            </p>
            <p className="terms_para">
              15.5 In no event shall JRN and/or JRN’s Related Parties be liable
              to you for any Indirect Losses.
            </p>
            <p className="terms_para">
              15.6 Nothing in these Terms shall limit JRN and/or JRN’s Related
              Parties liability to you resulting from JRN’s:
              <br />
              (a) fraud or fraudulent misrepresentation;
              <br />
              (b) deliberate misconduct; or
              <br />
              (c) for death or personal injury resulting from their actions.
            </p>
            <p className="terms_para">
              15.7 Any exclusions or limitations of liability contained in this
              Clause 15 shall not limit JRN’s liability which cannot be excluded
              or limited under the relevant Applicable Laws and Regulations.
            </p>
            <h3 id="force-majeure-events">Force Majeure Events</h3>
            <p className="terms_para">
              15.8 Neither party shall be liable for any delay or failure to
              perform any of their obligations under these Terms caused by a
              Force Majeure Event.
            </p>
            <h3 id="indemnification">Indemnification</h3>
            <p className="terms_para">
              15.9 To the fullest extent permitted by the Applicable Laws and
              Regulations, you agree to indemnify, hold harmless and promptly
              defend JRN and JRN’s affiliates and associates (including our and
              their respective agents (if any) and the shareholders, directors,
              officers and employees of JRN and JRN’s affiliates and associates)
              (each an “<strong>Indemnified Party</strong>”) from and against
              any and all losses arising from or in connection with any
              third-party claims, losses, liabilities, damages, judgments,
              penalties, fines, costs and expenses of whatever kind (including
              professional fees and reasonable attorney’s fees) which may be
              suffered or incurred by any of the Indemnified Party arising out
              of or related to:
              <br />
              (a) your (or any of the User’s Authorised Representatives’) use of
              or conduct in connection with the Account or the Services;
              <br />
              (b) your failure to perform any of your obligations under these
              Terms;
              <br />
              (c) a breach by you and/or JRN’s enforcement of the Terms
              connected to the breach;
              <br />
              (d) your violation of any Applicable Laws and Regulations;
              <br />
              (e) your violation of the rights of any third party;
              <br />
              (f) any third party claim related to your use of the Service(s);
              <br />
              (g) any false, inaccurate, misleading or deceptive information
              provided by you to JRN in the course of JRN providing you with the
              Services under these Terms;
              <br />
              (h) any investigation, claim, suit, action or other proceeding
              against us relating to or arising out of your use of the
              Service(s) by a governmental authority or regulatory or
              self-regulatory agency or organization in any applicable
              jurisdiction(s); or
              <br />
              (i) any breach by the User of any of the representations,
              warranties and undertakings made by the User to us under these
              Terms.
            </p>
            <p className="terms_para">
              15.10 If you are obligated to indemnify JRN or any of its Related
              Parties pursuant to this Clause 15.10, JRN will have the right, in
              its sole discretion, to control any action or proceeding and to
              determine whether it wishes to settle and, if so, on what terms.
            </p>
            <h3 id="disclaimer-of-warranties">Disclaimer of warranties</h3>
            <p className="terms_para">
              15.11 The Services and any information or materials provided by
              JRN are provided on an “as is” and “as available” basis. JRN
              expressly disclaims and the User waives any and all warranties of
              any kind, whether express or implied, including, without
              limitation:
              <br />
              (a) implied warranties of merchantability, fitness for a
              particular purpose, title and/or non-infringement; and
              <br />
              (b) warranties arising from the course of performance, course of
              dealing or usage in trade.
            </p>
            <p className="terms_para">
              15.12 Without limiting Clause 15.11, the User acknowledges that:
              <br />
              (a) JRN does not represent or warrant that the Services, any
              interface used to access the Services, the Website or any
              information or materials provided or made available by JRN are
              accurate, complete, reliable, current, error-free, free of viruses
              or other harmful components, or any other defects; and
              <br />
              (b) JRN does not guarantee that any Order will be executed,
              accepted, recorded or remain open.
            </p>
            <p className="terms_para">
              15.13 Except for the express statements set forth in these Terms,
              you hereby acknowledge and agree that you have not relied upon any
              other statement or understanding, whether written or oral, with
              respect to its use and access by you of the JRN Platform and/or
              the Services.
            </p>
            <p className="terms_para">
              15.14 You hereby understand and agree that JRN will not be liable
              for any Losses arising out of or relating to:
              <br />
              (a) any inaccuracy, defect or omission of any pricing information
              relating to a Digital Asset;
              <br />
              (b) interruption in any such data;
              <br />
              (c) any error or delay in the transmission of such information;
              and
              <br />
              (d) any damages incurred by another User’s actions, omissions or
              violation of these Terms.
            </p>
            <p className="terms_para">
              15.15 JRN cannot ensure or warrant the security or confidentiality
              of information transmitted to JRN or received from JRN through
              electronic means, which includes the internet, wireless
              connection, email, phone, and SMS, since JRN has no way of
              ensuring that the transmission of such information is protected at
              all times. If you have reason to believe that your data is no
              longer secure, you should contact JRN at the email address,
              mailing address or telephone number published on the JRN Platform
              or as notified to the User from time to time.
            </p>
            <h2 className="terms_heading"  id="16-variation">16 VARIATION</h2>
            <p className="terms_para">
              16.1 JRN has the right to amend, replace, supplement or delete
              (each, an Amendment) these Terms or any part of these Terms.
            </p>
            <p className="terms_para">
              16.2 JRN may provide Users with notice of an Amendment by posting
              the revised Terms and changing the "Last updated" date at the top
              of the Terms, or by emailing Users at their provided email
              addresses, or by any other means as determined by us at our sole
              discretion. Any Amendments will be effective immediately upon
              posting the revisions to the JRN Platform or at the instant that
              we transmit the information. These Amendments will apply at that
              instant to all then current and subsequent uses or Services.
            </p>
            <p className="terms_para">
              16.3 If a User objects to an Amendment, the sole remedy for the
              User shall be to stop using the Services and close its Account.
              JRN has the right to:
              <br />
              (a) terminate this User Agreement with the User with immediate
              effect, in accordance with these Terms; and
              <br />
              (b) suspend the User’s access and use of the Services.
            </p>
            <h2 className="terms_heading"  id="17-transfer-of-business">17 TRANSFER OF BUSINESS</h2>
            <p className="terms_para">
              17.1 In the event of a transfer of the whole of part of the
              business of JRN to any third party, the User consents to the
              transfer of the business relationship between the User and JRN,
              which includes but is not limited to the User’s:
              <br />
              (a) Account;
              <br />
              (b) Assets;
              <br />
              (c) liabilities; and
              <br />
              (d) Protected Data.
            </p>
            <p className="terms_para">
              17.2 In the event that JRN is acquired by or merged with a third
              party entity, JRN reserves the right to transfer or assign the
              information collected from Users as part of the acquisition,
              merger, sale or other change of control.
            </p>
            <h2 className="terms_heading"  id="18-disclaimer">18 DISCLAIMER</h2>
            <p className="terms_para">
              18.1 Service Interruption: based on the special nature of the
              Internet, we do not guarantee that the Service will not be
              interrupted. The timeliness and security of the Service are also
              not guaranteed, and we do not bear the responsibility for any
              Service interruption which is not caused by us.
            </p>
            <p className="terms_para">
              18.2 Safety of the Network: we try to provide a safe network
              environment to Users; however, we do not guarantee that the
              Website or its servers are free of viruses or other potentially
              harmful factors. Therefore, the user should use the industry’s
              recognized software to check and kill any virus in the files
              downloaded from the Website.
            </p>
            <p className="terms_para">
              18.3 User Information: we are not responsible for the failure of
              preservation, modification, deletion or storage of the information
              provided by the user. Nor will we be liable for the typographical
              errors, negligence, etc. not intentionally caused by us. We have
              the right but no obligation to improve or correct any omission,
              error of any part of this Website.
            </p>
            <p className="terms_para">
              18.4 Accuracy, Completeness, and Reliability of the Website:
              unless we have expressly agreed in writing, we shall not guarantee
              the accuracy, completeness, reliability of any content, such as,
              but not limited to, advertising on the platform in any manner on
              this Website. We are not responsible for any products, services,
              information or materials purchased or obtained by the user
              according to the content information on this Website. The user
              bears the risk of using the content of this Website.
            </p>
            <p className="terms_para">
              18.5 User Opinion: the comments published by users of the Website
              are only on behalf of the users’ personal point of view. It does
              not mean that this Website agrees with their views or confirms
              their description. This Website does not bear any legal
              responsibility caused by any user comments.
            </p>
            <p className="terms_para">
              18.6 Announcements: in regards to notices issued to the users, we
              will deliver these notices through a formal page announcement,
              station letter, e-mail, customer service phone call, SMS or
              regular mail delivery. We do not bear any legal responsibility for
              any winning, discount activities or information which are
              delivered by other channels.
            </p>
            <p className="terms_para">
              18.7 Charges, Commissions, Transactions and Other Fees: we have
              the right to adjust the recharge, commission, transaction and
              other fees according to market conditions, and terminate any
              promotional efforts early.
            </p>
            <h2 className="terms_heading"  id="19-governing-law">19 GOVERNING LAW</h2>
            <p className="terms_para">
              19.1 The Terms, your use of the Services, any claim, counterclaim
              or dispute of any kind or nature whatsoever arising out of the
              Terms, directly or indirectly, shall be governed by, and construed
              in accordance with the laws of England and Wales without regard to
              the principles of conflicts of laws thereof.
            </p>
            <h2 className="terms_heading"  id="20-jurisdiction-and-dispute-resolution">
              20 JURISDICTION AND DISPUTE RESOLUTION
            </h2>
            <p className="terms_para">
              20.1 You acknowledge and agree that in the event of any dispute,
              controversy, difference or claim, including the existence,
              validity, interpretation, performance, breach or termination of
              the Terms or any dispute arising out of or relating to the Terms
              (the “Dispute”), the parties shall first refer the Dispute to
              proceedings at the Hong Kong International Arbitration Centre (the
              “HKIAC”) in accordance with HKIAC’s Mediation Rules in force for
              the time.
            </p>
            <p className="terms_para">
              20.2 If the Dispute has not been settled upon the signing of a
              settlement agreement within ninety (90) days following the filing
              of a request for mediation set forth in Clause 20.1, such Dispute
              shall be referred to and finally resolved by arbitration
              administered by the HKIAC under the HKIAC Administered Arbitration
              Rules in force when the Notice of Arbitration is submitted. The
              law of this arbitration clause shall be the laws of the Hong Kong
              Special Administrative Region of People’s Republic of China (“Hong
              Kong”).
            </p>
            <p className="terms_para">
              20.3 You agree that the seat of arbitration shall be Hong Kong.
              The number of arbitrators shall be three (3). We shall appoint one
              (1) arbitrator and you shall appoint one (1) arbitrator. The third
              arbitrator shall be appointed by the Chairman of the HKIAC. Such
              arbitrators shall be freely selected, and the parties shall not be
              limited in their selection to any prescribed list. The arbitration
              proceedings shall be conducted in English.
            </p>
            <p className="terms_para">
              20.4 You agree that we shall not be required to give general
              discovery of documents, but may be required only to produce
              specific, identified documents which are relevant and material to
              the outcome of the Dispute.
            </p>
            <p className="terms_para">
              20.5 Any arbitral award shall be final and binding upon the
              parties hereto and shall be enforceable in any competent court
              which has jurisdiction.
            </p>
            <h2 className="terms_heading"  id="21-separability-complaints-and-miscellaneous">
              21 SEPARABILITY, COMPLAINTS AND MISCELLANEOUS
            </h2>
            <p className="terms_para">
              21.1 Separability: if any provision of these Terms is deemed to be
              unlawful, invalid or unenforceable for any reason, such provision
              shall be deemed to be divisible and shall not affect the legal
              effect of any other provision.
            </p>
            <p className="terms_para">
              21.2 Complaints: if you have any complaints, feedback, or
              questions, please contact our Customer Service at{" "}
              <a href="/help" target="_blank">
                Support Center
              </a>
              . When you contact us, please provide us with your name and email
              address and any other information we may need to identify you, and
              the transaction on which you have feedback, questions, or
              complaints.
            </p>
            <p className="terms_para">
              21.3 Miscellaneous: these Terms set forth the complete
              understanding of the parties with respect to the subject matter
              hereof and supersedes all prior understandings and communications
              relating thereto. No term or condition of any other document
              provided to us which is different from, inconsistent with, or in
              addition to the terms and conditions set forth herein will be
              binding upon the Parties. You represent and warrant that all
              information disclosed to us in connection with these Terms is
              true, accurate, and complete.
            </p>
            <h2 className="terms_heading"  id="22-definitions-and-interpretation">
              22 DEFINITIONS AND INTERPRETATION
            </h2>
            <h3 id="interpretation">Interpretation</h3>
            <p className="terms_para">
              22.1 In these Terms and unless otherwise specified, references to:
              <br />
              (a) “party” means either JRN or a User, and “parties” means both
              JRN and a User;
              <br />
              (b) “writing” includes any record capable of being published,
              whether or not in electronic form;
              <br />
              (c) a “person”, whether capitalised or not, shall include a
              natural or legal person, as the context requires;
              <br />
              (d) “Clauses” and “Schedules” are to the clauses and schedules of
              these Terms respectively;
              <br />
              (e) “including”, “include” and “not limited to” or any similar
              expression in any phrase shall be construed as illustrative only
              and shall not limit the words preceding that term;
              <br />
              (f) a “day”, “week”, “month”, “year” are to be construed by
              reference to the Gregorian calendar;
              <br />
              (g) an act or course of conduct shall include positive acts and
              omissions;
              <br />
              (h) terms importing the singular include the plural and vice
              versa; and
              <br />
              (i) terms defined in the Applicable Laws and Regulations have the
              same meaning as in those Applicable Laws and Regulations.
            </p>
            <p className="terms_para">
              22.2 Where a word or expression is given a particular meaning,
              other parts of speech and grammatical forms of that word or
              expression have a corresponding meaning.
            </p>
            <p className="terms_para">
              22.3 The table of contents and the headings of Clauses and
              Schedules are provided for convenience only and shall not affect
              the interpretation of these Terms.
            </p>
            <p className="terms_para">
              22.4 Unless otherwise defined in these Terms or unless the context
              requires otherwise, terms defined in the Applicable Laws and
              Regulations have the same meaning as in the Applicable Laws and
              Regulations.
            </p>
            <p className="terms_para">
              22.5 Any translation of these Terms or other documents is provided
              for convenience only and may not accurately represent the
              information contained in the original English. In the event of
              inconsistency, the English language version of these Terms or
              other documents shall prevail.
            </p>
            <h3 id="definitions">Definitions</h3>
            <p className="terms_para">
              22.6 The words in the table shall have the following meanings
              attributed to them:
            </p>
            <div className="table-wrap">
              <table
                className={`table table-bordered ${mode==="lightMode"?"":"table-dark"} table-hover`}
                style={{ tableLayout: "fixed", width: 'auto '}}
              >
                <tbody>
                  <tr>
                    <td className={mode==="lightMode"?"tg-yla0":"tg-yla0-dark"}>
                      <br />
                      Account{" "}
                    </td>
                    <td className="tg-cly1">
                      <br />
                      <br />
                      <br />
                      Account opened and maintained with JRN for the User to use
                      the Services and to trade on the JRN Platform, including
                      any Digital Asset sub-account that reflects a User’s
                      Digital Asset balance. In the context of these Terms,
                      "Your Account" means your Account and/or any Account for
                      which you have control or access to, or if you are a
                      representative, attorney or for which you hold a similar
                      authority or role.
                    </td>
                  </tr>
                  <tr>
                    <td className={mode==="lightMode"?"tg-yla0":"tg-yla0-dark"}>
                      <br />
                      Additional Services{" "}
                    </td>
                    <td className="tg-cly1">
                      <br />
                      <a href="/help/jrn-futures-trading-user-agreement">
                        Futures
                      </a>
                      , Options, Index Tracker and Margin Lending offered by Aux
                      Cayes FinTech Co. Ltd., a Seychelles registered company
                    </td>
                  </tr>
                  <tr>
                    <td className={mode==="lightMode"?"tg-yla0":"tg-yla0-dark"}>
                      <br />
                      AML Program{" "}
                    </td>
                    <td className="tg-cly1">
                      <br />
                      Program to reasonably prevent money laundering and
                      terrorist financing through a risk-based, multi-layer
                      control system.{" "}
                    </td>
                  </tr>
                  <tr>
                    <td className={mode==="lightMode"?"tg-yla0":"tg-yla0-dark"}>
                      <br />
                      API{" "}
                    </td>
                    <td className="tg-cly1">
                      <br />
                      Application Programming Interface which is a connectivity
                      function that allows a User to operate its Account on the
                      JRN Platform.{" "}
                    </td>
                  </tr>
                  <tr>
                    <td className={mode==="lightMode"?"tg-yla0":"tg-yla0-dark"}>
                      <br />
                      Applicable Laws and Regulations
                    </td>
                    <td className="tg-cly1">
                      <br />
                      <br />
                      <br />
                      The laws, regulations and rules of any Competent Authority
                      that apply to JRN, the JRN Platform and/or JRN Users, as
                      enacted, replaced, amended, extended, consolidated,
                      substituted and/or re-enacted from time to time in any
                      relevant jurisdiction(s).
                    </td>
                  </tr>
                  <tr>
                    <td className={mode==="lightMode"?"tg-yla0":"tg-yla0-dark"}>
                      <br />
                      Applicant{" "}
                    </td>
                    <td className="tg-cly1">
                      <br />
                      Person who makes an application to open an Account with
                      JRN to trade on the JRN Platform.{" "}
                    </td>
                  </tr>
                  <tr>
                    <td className={mode==="lightMode"?"tg-yla0":"tg-yla0-dark"}>
                      <br />
                      Asset{" "}
                    </td>
                    <td className="tg-cly1">
                      <br />
                      Digital Asset or Fiat Currency{" "}
                    </td>
                  </tr>
                  <tr>
                    <td className={mode==="lightMode"?"tg-yla0":"tg-yla0-dark"}>
                      <br />
                      Competent Authorities{" "}
                    </td>
                    <td className="tg-cly1">
                      <br />
                      <br />
                      <br />
                      Any governmental, regulatory, judicial, law enforcement or
                      public authority, whether in Bahamas, Brazil, Hong Kong,
                      Malta, Singapore, the Seychelles or any relevant
                      jurisdictions, which have jurisdiction over the activities
                      of JRN, the JRN Platform or any Users.
                    </td>
                  </tr>
                  <tr>
                    <td className={mode==="lightMode"?"tg-yla0":"tg-yla0-dark"}>
                      <br />
                      Contract{" "}
                    </td>
                    <td className="tg-cly1">
                      <br />
                      Contract For Differences
                    </td>
                  </tr>
                  <tr>
                    <td className={mode==="lightMode"?"tg-yla0":"tg-yla0-dark"}>
                      <br />
                      Customer Service{" "}
                    </td>
                    <td className="tg-cly1">
                      <br />
                      Team that provides timely responses and resolutions for
                      our customers and prospective customers concerning
                      questions, feedback, or complaints{" "}
                    </td>
                  </tr>
                  <tr>
                    <td className={mode==="lightMode"?"tg-yla0":"tg-yla0-dark"}>
                      <br />
                      Darknet{" "}
                    </td>
                    <td className="tg-cly1">
                      <br />
                      Hidden service markets or any other service or website
                      that acts as a marketplace for illegal goods{" "}
                    </td>
                  </tr>
                  <tr>
                    <td className={mode==="lightMode"?"tg-yla0":"tg-yla0-dark"}>
                      <br />
                      Digital Assets{" "}
                    </td>
                    <td className="tg-cly1">
                      <br />
                      Any digital asset which is based on the cryptographic
                      protocol of a computer network that may be (i) centralized
                      or decentralized, (ii) closed or open-source, and (iii)
                      used as a medium of exchange and/or store of value.
                      Furthermore, a Digital Asset is expressed as a unit;
                      capable of being transferred, stored, and traded on a
                      peer-to-peer basis with or without conditions or
                      limitations; and approved by JRN for use in connection
                      with the Exchange from time to time. For the avoidance of
                      doubt, any Digital Asset that: (x) is transferred on any
                      additional layer on top of a blockchain relating to
                      another Digital Asset or any side chain; or (y) is a
                      derivative of another Digital Asset, has enhanced features
                      or functionality that supplements or interacts with
                      another Digital Asset; is to be treated as a distinct
                      Digital Asset from such other Digital Asset, and its use
                      in connection with the Services will be subject to JRN’s
                      approval. Digital Assets also include NFTs and other
                      digital collectibles.
                    </td>
                  </tr>
                  <tr>
                    <td className={mode==="lightMode"?"tg-yla0":"tg-yla0-dark"}>
                      <br />
                      Enhanced Due Diligence{" "}
                    </td>
                    <td className="tg-cly1">
                      <br />
                      Program whereby Users who have been designated as high
                      risk undergo enhanced scrutiny and diligence.{" "}
                    </td>
                  </tr>
                  <tr>
                    <td className={mode==="lightMode"?"tg-yla0":"tg-yla0-dark"}>
                      <br />
                      Event of Default{" "}
                    </td>
                    <td className="tg-cly1">
                      <br />
                      Any of the following events:
                      <br />
                      <br />
                      (a) you stop or suspend payment of any of your debts,
                      loans, line of credit, facility or any equivalence, or is
                      unable to, or admits your inability to, pay your debts,
                      loans, line of credit, facility or any equivalence as they
                      fall due;
                      <br />
                      <br />
                      (b) you commence negotiations, or enter into any
                      composition, compromise, assignment or arrangement, with
                      one or more of your creditors with a view to rescheduling
                      any of your indebtedness (because of actual or anticipated
                      financial difficulties);
                      <br />
                      <br />
                      (c) a moratorium is declared in respect of any of your
                      indebtedness;
                      <br />
                      <br />
                      (d) any action, proceedings, procedure or step is taken in
                      relation to:
                      <br />
                      <br />
                      (i) your indebtedness, insolvency, bankruptcy, judicial
                      management, winding up, dissolution, administration or
                      reorganisation (using a voluntary arrangement, scheme of
                      arrangement or otherwise), as may be applicable (each, an
                      "Insolvency Event");
                      <br />
                      <br /> (ii) the suspension of payments or a moratorium in
                      preparation, anticipation or respect of any Insolvency
                      Event;
                      <br />
                      <br /> (iii) a composition, compromise, assignment or
                      arrangement with any of your creditors;
                      <br />
                      <br /> (iv) the appointment of a liquidator, receiver,
                      administrative receiver, trustee, judicial manager,
                      administrator, compulsory manager or other similar officer
                      over you or any of your assets (or if applicable,
                      business); or
                      <br />
                      <br />
                      (v) the protection of your creditors' rights.
                      <br />
                      <br />
                      (e) the value of your assets is less than the value of
                      your liabilities (taking into account contingent and
                      prospective liabilities);
                      <br />
                      <br />
                      (f) you breached any provision in these Terms;
                      <br />
                      <br />
                      (g) if you are not a natural person, you cease or threaten
                      to cease your business;
                      <br />
                      <br />
                      (h) you become unable to legally perform or comply with
                      any obligation under these Terms;
                      <br />
                      <br />
                      (i) a distress, attachment, execution, expropriation,
                      sequestration or other analogous legal process is levied,
                      enforced or sued out on, or against, your assets and is
                      not discharged or stayed within 21 days;
                      <br />
                      <br />
                      (j) any security in respect of indebtedness on or over
                      your assets ("Security") becomes enforceable;
                      <br />
                      <br />
                      (k) any Security, which may include but is not limited to
                      a Margin:
                      <br />
                      <br />
                      (i) ceases to be in full force and effect; or
                      <br />
                      <br /> (ii) is alleged by the relevant secured creditor to
                      be ineffective;
                      <br />
                      <br />
                      (l) you repudiate, rescind or show an intention to
                      repudiate or rescind any financing agreement which you are
                      a borrowing party to; and/or
                      <br />
                      <br />
                      (m) any event occurring in relation to you that is
                      analogous to those set out in the foregoing provisions
                      under this definition in any jurisdiction.{" "}
                    </td>
                  </tr>
                  <tr>
                    <td className={mode==="lightMode"?"tg-yla0":"tg-yla0-dark"}>
                      <br />
                      Fiat Currency{" "}
                    </td>
                    <td className="tg-cly1">
                      <br />
                      Government-issued currency that is designated as legal
                      tender in its country of issuance through government
                      decree, regulation or law.{" "}
                    </td>
                  </tr>
                  <tr>
                    <td className={mode==="lightMode"?"tg-yla0":"tg-yla0-dark"}>
                      <br />
                      Fill{" "}
                    </td>
                    <td className="tg-cly1">
                      <br />
                      Complete or partial matching of two Orders on the JRN
                      Platform which results in a Trade and Filling or Filled
                      shall be construed accordingly.{" "}
                    </td>
                  </tr>
                  <tr>
                    <td className={mode==="lightMode"?"tg-yla0":"tg-yla0-dark"}>
                      <br />
                      Financial Crime{" "}
                    </td>
                    <td className="tg-cly1">
                      <br />
                      <br />
                      <br />
                      Money laundering, terrorist financing, evasion of economic
                      sanctions, tax evasion, bribery and corruption, including
                      but not limited to the crimes of money laundering and
                      terrorist financing, and behaviour which may amount to
                      "Market Abuse", as defined under Applicable the Laws and
                      Regulations as may be applicable to OKC EU, JRN Bahamas,
                      JRN HK, JRN Brazil, JRN Singapore, or Aux Cayes as the
                      case may be and as amended from time to time.
                    </td>
                  </tr>
                  <tr>
                    <td className={mode==="lightMode"?"tg-yla0":"tg-yla0-dark"}>
                      <br />
                      Force Majeure Event{" "}
                    </td>
                    <td className="tg-cly1">
                      <br />
                      Event beyond the reasonable control of JRN or a User which
                      prevents or substantially hinders JRN or the User from
                      complying with any of their obligations, including but not
                      limited to:
                      <br />
                      <br />
                      (a)acts of God, fire, storm, tempest, explosion, flood,
                      earthquake, subsidence or any other natural physical
                      disasters;
                      <br />
                      <br />
                      (b)acts of war, terrorism, riots, civil commotion,
                      military action, insurrections, rebellions or revolutions
                      and any other similar acts;
                      <br />
                      <br />
                      (c)pandemics or epidemics;
                      <br />
                      <br />
                      (d)failures or interruptions in power, communication
                      channels, hardware, software, Internet connections and
                      information systems;
                      <br />
                      <br />
                      (e)acts of state or government, political interference,
                      sanctions and embargos
                      <br />
                      <br />
                      (f) any other similar acts or events, disruptions, errors,
                      distortions or delays,
                      <br />
                      <br />
                      provided such events are not the result of a party's
                      fraud, gross negligence or wilful default.{" "}
                    </td>
                  </tr>
                  <tr>
                    <td className={mode==="lightMode"?"tg-yla0":"tg-yla0-dark"}>
                      <br />
                      Futures{" "}
                    </td>
                    <td className="tg-cly1">
                      <br />
                      Legal contracts to buy or sell cryptocurrency at a later
                      date{" "}
                    </td>
                  </tr>
                  <tr>
                    <td className={mode==="lightMode"?"tg-yla0":"tg-yla0-dark"}>
                      <br />
                      Hard Fork{" "}
                    </td>
                    <td className="tg-cly1">
                      <br />
                      Occurs when a blockchain protocol is radically changed
                      such that it becomes incompatible with older versions.
                      Parties taking part in transactions on the old blockchain
                      must upgrade to the new one in order to continue
                      validating transactions. However, the parties that do not
                      upgrade may continue to support and validate transactions
                      on the older blockchain protocol separately. A Hard Fork
                      can be planned, or unplanned (contentious).{" "}
                    </td>
                  </tr>
                  <tr>
                    <td className={mode==="lightMode"?"tg-yla0":"tg-yla0-dark"}>
                      <br />
                      Indirect Losses{" "}
                    </td>
                    <td className="tg-cly1">
                      <br />
                      Any loss related in any way to special, indirect,
                      incidental, exemplary, punitive, multiple or consequential
                      Loss including Loss of business or opportunity, customers
                      or contracts, Loss of overheads, management or staff time,
                      Loss of anticipated profits or revenue or other financial
                      benefit, Loss of use of hardware or software, Loss of data
                      or information or corruption of data (including Loss
                      arising out of or relating to any inaccuracy, defect or
                      omission of digital currency price data; any error or
                      delay in the transmission of such data; and / or any
                      interruption in any such data), or Loss from business
                      disruption, Loss of reputation or goodwill, regardless of
                      whether:
                      <br />
                      <br />
                      <br />
                      <br />
                      (a)a Party has been advised of the possibility or
                      likelihood of recovery of such Loss; or
                      <br />
                      <br />
                      (b)arising under contract, tort, strict liability or any
                      other theory of law.{" "}
                    </td>
                  </tr>
                  <tr>
                    <td className={mode==="lightMode"?"tg-yla0":"tg-yla0-dark"}>
                      <br />
                      Losses{" "}
                    </td>
                    <td className="tg-cly1">
                      <br />
                      Any claim, demand, action, proceeding, liability, expense
                      (including legal and professional expenses), cost, charge,
                      injury, damages, fine, penalty or diminution of value.{" "}
                    </td>
                  </tr>
                  <tr>
                    <td className={mode==="lightMode"?"tg-yla0":"tg-yla0-dark"}>
                      <br />
                      Margin{" "}
                    </td>
                    <td className="tg-cly1">
                      <br />
                      Amount of money you are required to pay in order to open
                      and maintain a Trade as described in Clause 1 above{" "}
                    </td>
                  </tr>
                  <tr>
                    <td className={mode==="lightMode"?"tg-yla0":"tg-yla0-dark"}>
                      <br />
                      Margin Lending{" "}
                    </td>
                    <td className="tg-cly1">
                      <br />
                      Peer to Peer lending service allowing users to borrow/lend
                      Digital Assets to one another and earn rewards{" "}
                    </td>
                  </tr>
                  <tr>
                    <td className={mode==="lightMode"?"tg-yla0":"tg-yla0-dark"}>
                      <br />
                      Natural Person{" "}
                    </td>
                    <td className="tg-cly1">
                      <br />
                      Any individual and their estate and personal
                      representatives.{" "}
                    </td>
                  </tr>
                  <tr>
                    <td className={mode==="lightMode"?"tg-yla0":"tg-yla0-dark"}>
                      <br />
                      Notice{" "}
                    </td>
                    <td className="tg-cly1">
                      <br />A binding communication issued by JRN to the Users,
                      informing the Users of specific matters of relevance with
                      respect to the proper functioning of the JRN Platform and
                      the User’s use of the Services, including but not limited
                      to changes to the policies, fees or any other relevant
                      matter, as may be required from time to time.{" "}
                    </td>
                  </tr>
                  <tr>
                    <td className={mode==="lightMode"?"tg-yla0":"tg-yla0-dark"}>
                      <br />
                      Notice of Arbitration{" "}
                    </td>
                    <td className="tg-cly1">
                      <br />
                      Document that signifies a party’s intent to submit a
                      dispute to one or more arbitrators who make a binding
                      decision on the dispute{" "}
                    </td>
                  </tr>
                  <tr>
                    <td className={mode==="lightMode"?"tg-yla0":"tg-yla0-dark"}>
                      <br />
                      JRN{" "}
                    </td>
                    <td className="tg-cly1">
                      <br />
                      <br />
                      <br />
                      Either OKC EU, JRN Bahamas, JRN HK, JRN Brazil, JRN
                      Singapore, or Aux Cayes as the case may be for their
                      respective Users.
                    </td>
                  </tr>
                  <tr>
                    <td className={mode==="lightMode"?"tg-yla0":"tg-yla0-dark"}>
                      <br />
                      JRN Platform{" "}
                    </td>
                    <td className="tg-cly1">
                      <br />
                      The online platform accessed either at jrn.com
                      (http://jrn.com/), or via the API or the mobile
                      application that is operated by JRN.{" "}
                    </td>
                  </tr>
                  <tr>
                    <td className={mode==="lightMode"?"tg-yla0":"tg-yla0-dark"}>
                      <br />
                      Order{" "}
                    </td>
                    <td className="tg-cly1">
                      <br />
                      An instruction in relation to a Trade.{" "}
                    </td>
                  </tr>
                  <tr>
                    <td className={mode==="lightMode"?"tg-yla0":"tg-yla0-dark"}>
                      <br />
                      P2P
                    </td>
                    <td className="tg-cly1">
                      <br />
                      Peer to Peer matching service, allowing users to exchange
                      their Fiat Currency and Digital Assets with each other.{" "}
                    </td>
                  </tr>
                  <tr>
                    <td className={mode==="lightMode"?"tg-yla0":"tg-yla0-dark"}>
                      <br />
                      Prohibited Business{" "}
                    </td>
                    <td className="tg-cly1">
                      <br />
                      Prohibited businesses set out in Clause 3.{" "}
                    </td>
                  </tr>
                  <tr>
                    <td className={mode==="lightMode"?"tg-yla0":"tg-yla0-dark"}>
                      <br />
                      Related Parties{" "}
                    </td>
                    <td className="tg-cly1">
                      <br />
                      <br />
                      <br />
                      Any companies which are affiliate companies, subsidiary
                      companies, sister companies, associated companies, or
                      related companies of JRN.
                    </td>
                  </tr>
                  <tr>
                    <td className={mode==="lightMode"?"tg-yla0":"tg-yla0-dark"}>
                      <br />
                      Restricted Locations{" "}
                    </td>
                    <td className="tg-cly1">
                      <br />
                      Locations in which JRN offers no or only limited services,
                      as indicated in Clause 2.2 and may be updated from time to
                      time according to JRN.{" "}
                    </td>
                  </tr>
                  <tr>
                    <td className={mode==="lightMode"?"tg-yla0":"tg-yla0-dark"}>
                      <br />
                      Risk Statement{" "}
                    </td>
                    <td className="tg-cly1">
                      <br />
                      Statement made by JRN to the Users in relation to the
                      risks involved in trading on the JRN Platform as set out
                      in Clause 6{" "}
                    </td>
                  </tr>
                  <tr>
                    <td className={mode==="lightMode"?"tg-yla0":"tg-yla0-dark"}>
                      <br />
                      Services{" "}
                    </td>
                    <td className="tg-cly1">
                      <br />
                      Services provided by JRN to its Users under these Terms,
                      including trading on the JRN Platform and the custody of
                      Users’ Digital Assets.{" "}
                    </td>
                  </tr>
                  <tr>
                    <td className={mode==="lightMode"?"tg-yla0":"tg-yla0-dark"}>
                      <br />
                      Spot Services{" "}
                    </td>
                    <td className="tg-cly1">
                      <br />
                      Spot transactions in Digital Assets through (a){" "}
                      <a href="/help/jrn-p2p-user-agreement" target="_blank">
                        P2P (Peer-to-Peer)
                      </a>{" "}
                      or (b) Centralised exchange platform offered by JRN{" "}
                    </td>
                  </tr>
                  <tr>
                    <td className={mode==="lightMode"?"tg-yla0":"tg-yla0-dark"}>
                      <br />
                      Stop Order{" "}
                    </td>
                    <td className="tg-cly1">
                      <br />
                      Refers to the stop loss function as described in Clause
                      6.74{" "}
                    </td>
                  </tr>
                  <tr>
                    <td className={mode==="lightMode"?"tg-yla0":"tg-yla0-dark"}>
                      <br />
                      Terms{" "}
                    </td>
                    <td className="tg-cly1">
                      <br />
                      These Terms of Service and all schedules, product details,
                      any ancillary documents referred to herein and any
                      amendments thereto. For the avoidance of doubt, this
                      agreement supersedes and replaces any previous customer
                      agreement in force between you and us{" "}
                    </td>
                  </tr>
                  <tr>
                    <td className={mode==="lightMode"?"tg-yla0":"tg-yla0-dark"}>
                      <br />
                      Trade{" "}
                    </td>
                    <td className="tg-cly1">
                      <br />
                      Transaction on the JRN Platform which results from the
                      Fill of two Orders{" "}
                    </td>
                  </tr>
                  <tr>
                    <td className={mode==="lightMode"?"tg-yla0":"tg-yla0-dark"}>
                      <br />
                      User{" "}
                    </td>
                    <td className="tg-cly1">
                      <br />A Natural Person or legal entity (e.g. corporate or
                      institutional customers) who uses the JRN Platform and to
                      whom JRN provides its Services pursuant to these Terms,
                      and who has agreed to these Terms. For the avoidance of
                      doubt, Users may include Related Parties who engage in
                      trading activity as described in Clause 4.6.{" "}
                    </td>
                  </tr>
                  <tr></tr>
                </tbody>
              </table>
            </div>
        </div>
      </div>
    </div>
  );
}
