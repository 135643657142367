import React, { useEffect,useState } from "react";
import "../../App.css";
import "./mainFooter.css";
import { useLocation, useNavigate} from "react-router-dom";
import { useSelector } from "react-redux";
import { FaTwitter, FaMask } from "react-icons/fa";
import { MdPrivacyTip } from "react-icons/md";

export default function Footer() {
  const mode = useSelector((state) => state.themeType.themeType);
  const navigate=useNavigate();

  const linklist = [
    { label: "Contact Us", route: "/contact" },
    { label: "Docs", route: "/docs" },
    { label: "Support", route: "/support" },
    { label: "Brand", route: "/brand" },
    { label: "Terms", route: "/terms_and_condition" },
    { label: "Privacy", route: "/privacy-policy-statement" },
  ];

  const socialMediaIcons = (size) => [
    <FaTwitter key="twitter" size={size} color="black"  className="mx-4 my-3"/>,
    <FaMask key="mask" size={size} color="black" className="mx-4 my-3"/>,
    <MdPrivacyTip key="shield" size={size} color="black" className="mx-4 my-3" />,
  ];

  const renderLinkIconList = () =>
    linklist.map((elem, index) => (
      <span
        key={index}
        className="mx-2 text-muted pointer"
        onClick={() => {
          if (elem.route === "/terms_and_condition" || elem.route === "/privacy-policy-statement") {
            window.scrollTo(0, 0);
          }
          navigate(elem.route)
        }}
      >
        {elem.label}
      </span>
    ));

  return (
    <footer
      className="footer mt-4 border-top border-secondary m-0"
      style={{
        backgroundColor: mode === "lightMode" ? "white" : "#121212",
        padding: "4rem",
      }}
    >
      <div className="container-fluid mx-5">
        <div className="row">
          <div className="col">
            <div className="d-flex align-items-center">
              <span style={{ letterSpacing: "5px", fontWeight: "bold", fontSize: "25px" }}>JRN</span>
              <span className="text-muted">© 2021 - 2024 JRN</span>
            </div>
          </div>
          <div className="col align-items-end d-flex flex-column">
            <div className="d-flex flex-wrap">{socialMediaIcons(20)}</div>
            <div className="d-flex">{renderLinkIconList()}</div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export const  MaybeShowFooter =({children})=>{
const location=useLocation();
const [shwoFooter,setshowFooter]=useState(true);
useEffect(()=>{
if(location.pathname.includes('collection')){
  setshowFooter(false);
}else{
  setshowFooter(true);
}
},[location.pathname])
return shwoFooter&&children;
}

