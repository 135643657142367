// src/FallingBallsCanvas.js
import React, { useEffect, useRef } from 'react';

const Ball = (props) => {
  const canvasRef = useRef(null);
  const balls = [];
  const icons = ['😀', '🚀', '🌟', '⚽', '🎸', '🍎', '🚗', '🏠', '📚', '🎉'];

  useEffect(() => {
    const canvas = canvasRef.current;
    const context = canvas.getContext('2d');
    const ballSize = props.ballSize;
    const gravity = 0.2;
    const friction = 0.99;

    const createBall = (x, y, icon) => {
      return { x, y, dx: Math.random() * 2 - 1, dy: Math.random() * 2 - 1, icon };
    };

    const initializeBalls = () => {
      for (let i = 0; i < icons.length; i++) {
        const x = Math.random() * (canvas.width - ballSize) + ballSize / 2;
        const y = Math.random() * (canvas.height - ballSize) + ballSize / 2;
        balls.push(createBall(x, y, icons[i]));
      }
    };

    const drawBall = (ball) => {
      context.beginPath();
      context.arc(ball.x, ball.y, ballSize / 2, 0, Math.PI * 2);
      context.fillStyle = 'white';
      context.fill();
      context.closePath();

      context.font = `${ballSize / 2}px Arial`;
      context.textAlign = 'center';
      context.textBaseline = 'middle';
      context.fillText(ball.icon, ball.x, ball.y);
    };

    const updateBall = (ball) => {
      // Apply gravity
      ball.dy += gravity;

      // Apply friction
      ball.dx *= friction;
      ball.dy *= friction;

      // Update position
      ball.x += ball.dx;
      ball.y += ball.dy;

      // Handle collisions with walls
      if (ball.x + ballSize / 2 > canvas.width || ball.x - ballSize / 2 < 0) {
        ball.dx = -ball.dx;
      }
      if (ball.y + ballSize / 2 > canvas.height || ball.y - ballSize / 2 < 0) {
        ball.dy = -ball.dy;
      }

      // Prevent balls from sticking to the walls
      if (ball.x + ballSize / 2 > canvas.width) ball.x = canvas.width - ballSize / 2;
      if (ball.x - ballSize / 2 < 0) ball.x = ballSize / 2;
      if (ball.y + ballSize / 2 > canvas.height) ball.y = canvas.height - ballSize / 2;
      if (ball.y - ballSize / 2 < 0) ball.y = ballSize / 2;

      // Handle collisions with other balls
      for (let i = 0; i < balls.length; i++) {
        const otherBall = balls[i];
        if (ball === otherBall) continue;

        const dx = ball.x - otherBall.x;
        const dy = ball.y - otherBall.y;
        const distance = Math.sqrt(dx * dx + dy * dy);
        if (distance < ballSize) {
          // Resolve overlap
          const overlap = (ballSize - distance) / 2;
          const angle = Math.atan2(dy, dx);
          const sin = Math.sin(angle);
          const cos = Math.cos(angle);

          // Move each ball away from the other by half the overlap distance
          ball.x += overlap * cos;
          ball.y += overlap * sin;
          otherBall.x -= overlap * cos;
          otherBall.y -= overlap * sin;

          // Exchange velocities
          const tempDx = ball.dx;
          const tempDy = ball.dy;
          ball.dx = otherBall.dx;
          ball.dy = otherBall.dy;
          otherBall.dx = tempDx;
          otherBall.dy = tempDy;

          // Reduce shaking by damping velocities
          ball.dx *= 0.9;
          ball.dy *= 0.9;
          otherBall.dx *= 0.9;
          otherBall.dy *= 0.9;
        }
      }
    };

    const update = () => {
      context.clearRect(0, 0, canvas.width, canvas.height);
      balls.forEach((ball) => {
        updateBall(ball);
        drawBall(ball);
      });
      requestAnimationFrame(update);
    };

    initializeBalls();
    requestAnimationFrame(update);
  }, []);

  return <canvas ref={canvasRef} width={props.width} height={225} />;
};

export default Ball;
