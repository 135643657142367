import React,{useState,useEffect} from 'react'

export default function CustomClock(props) {
  const {updateTokenExpired}=props
    const [time, setTime] = useState(props.time);
    const [minute, setMinute] = useState(props.minute);
    useEffect(() => {
        const interval = setInterval(() => {
          if (minute > 0) {
            setMinute(minute - 1);
          } else if (time > 0) {
            setMinute(59);
            setTime(time - 1);
          } else {
            clearInterval(interval);
          }
          if(time===0&&minute===0){
            updateTokenExpired(true);
          }else{
            updateTokenExpired(false);
          }
        }, 1000);
    
        return () => {
          clearInterval(interval);
        };
      }, [time, minute]);
  return (
        time===0 && minute === 0 ?
        <p className='my-2'>Code expired, please request a new code.</p>:
        <p className={`mb-0 my-2 ${props.className}`} style={props.style}>{time<10&&0}{time}:{minute<10&&0}{minute}</p>
  )
}
