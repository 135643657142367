import React,{useState,useEffect} from 'react'
import { useSelector } from 'react-redux';
import Styles from './Download.module.css'
import { SlScreenDesktop } from "react-icons/sl";
import { CiMobile4 } from "react-icons/ci";
import Tooltip from 'rc-tooltip';
import './download.css'
import MobileImage from '../../assets/MobileImage.webp'
import ThreeMobileImage from '../../assets/ThreeMobileImage.webp'
import chrome from '../../assets/chrome.webp'
import brave from '../../assets/brave.webp'
import explore from '../../assets/explore.webp'
import walletImage from '../../assets/walletImage.webp'
import apple from '../../assets/apple.webp'
import windowImage from '../../assets/window.webp'
import playStore from '../../assets/playStore.webp'
import GraphChartImage from '../../assets/GraphChartImage.webp'
import QrCodeImage from '../../assets/qrcode.jpg'
// import 'rc-tooltip/assets/bootstrap.css';
const DownloadScreen = () => {
  const mode = useSelector((state) => state.themeType.themeType);
  const token = localStorage.getItem("accessToken");
  const [windowWidth, setwindowWidth] = useState(window.innerWidth);

  const renderFirstSection=()=>{
    return (
        <section>
            <div className='row'>
                <div className={`col-12 col-lg-4 offset-xl-1 pr-0   ${windowWidth<1034?"d-flex flex-column align-items-center  justify-content-center":""}`}>
                    <div className='' style={{marginTop:"120px"}}>
                        <h1 className={`${Styles.title}`} style={{fontSize:windowWidth<768?"40px":"50px"}}>Your gateway to <span className={Styles.exchangeTitle}>crypto & Web3</span></h1>
                        <p className={`${windowWidth<1034?'text-center':''}`} style={{fontSize:'20px',lineHeight:"24px",marginTop:'20px',color:'#E3E3E3'}}>Crypto trading & Web3 - made easy for you</p>
                    </div>
                   {windowWidth>768&&renderPopoverBtn()}

                </div>
                {
                    windowWidth>1034?
                <div className='col '>
                    <img src={MobileImage} alt='...' className='img-fluid img-responsive' height={'450px'}/>
                </div>:null
                }
            </div>
        </section>
    )
  }
  const renderSecondScreen=()=>{
    const renderItem=(elem,index)=>{
        return <a key={index} href={elem?.label==='Chrome'?'https://chromewebstore.google.com/detail/JRN-wallet/mcohilncbfahbmgdjkbpemcciiolgcge':elem?.label==='Brave'?'https://chrome.google.com/webstore/detail/JRN-wallet/mcohilncbfahbmgdjkbpemcciiolgcge':'https://microsoftedge.microsoft.com/addons/detail/JRN-wallet/pbpjkcldjiffchgbbndmhojiacbgflha'}target='blank' className='d-flex justify-content-between ml-2 align-items-center py-2 px-4 bg-transparent'  style={{border:'1px solid white',borderRadius:'36px',cursor:'pointer'}}>
            <div>{elem?.icon}</div>

            <div><span style={{color:'#B0B0B0',fontSize:'12px'}}>Download for</span><br/><span style={{fontSize:'16px',color:mode==='lightMode'?"black":'white'}}>{elem?.label}</span></div>
        </a>
    }
    return (
        <section style={{backgroundColor:mode==='lightMode'?"white":'#121212',marginTop:'5rem'}}>
            <div className='row' style={{paddingTop:'40px'}}>
                <div className='col-7 offset-1'>
                    <h2 className={`${Styles.extension}`}>Safeguard your <span className={Styles.exchangeTitle}>on-chain</span>  assets</h2>
                    <p className={Styles.extension_description}>Explore 80+ networks and manage crypto assets seamlessly via a self-custodial Wallet Extension</p>
                    <div className='d-flex align-items-center mt-5'>
                        {[{label:'Chrome',icon:<img width={'30px'} height={"30px"} src={chrome} alt='...'/>},{label:'Brave',icon:<img width={'30px'} height={"30px"} src={brave} alt='...'/>},{label:'Edge',icon:<img width={'30px'} height={"30px"} src={explore} alt='...'/>}].map((elem,index)=>renderItem(elem,index))}
                    </div>
                </div>
                <div className='col-4'>
                    <img src={walletImage} className='img-fluid img-responsive' style={{maxWidth:'300px',maxHeight:"291px"}} alt='...'/>
                </div>
            </div>
        </section>
    )
  }

  const renderThirdSection=()=>{
    return (
        <section>
            <div className='row'>
                <div className='col-12 col-md-5 offset-md-1 order-2 order-lg-1 mx-auto text-center'>
                    <div style={{marginTop:windowWidth<1034?'60px':'160px',marginBottom:windowWidth<1034?'60px':'160px'}}>
                        <img src={ThreeMobileImage} alt='...' className='img-fluid img-responsive' width={windowWidth<1034&&"523px"} height={windowWidth<1034&&"438"}/>
                    </div>
                </div>
                {windowWidth>768&&<div className='col-12 col-md-6 d-md-block d-none  d-flex flex-column justify-content-center align-items-center  order-1 order-lg-2'>
                    <div style={{marginTop:windowWidth>1034?'100px':'30px'}} >
                    <h2 className={`${Styles.one_app_title} ${windowWidth<1034?"text-center font-32":""} `}>One app {windowWidth>1034&&<br/>} Unlimited possibilities</h2>
                    <p className={`${Styles.one_app_subtitle} ${windowWidth<1034?"text-center":""}`} >Download the JRN app to trade crypto on the go. Gain {windowWidth>1034&&<br/>}access to margin and  derivative trading, NFT {windowWidth>1034&&<br/>}marketplace, Web3 Wallet and more!</p>
                    </div>
                </div>
                }
            </div>
        </section>
    )
  }
  const renderfourthSection=()=>{
    return (
        <section>
            <div className='row' style={{paddingBottom:'180px'}}>
                <div className='col-12 col-md-5 offset-md-1 d-flex flex-column justify-content-center align-items-center'>
                    <div style={{marginTop:'100px'}}>
                    <h2 className={`${Styles.one_app_title}`}>Powerful platform <br/>
                    Trade like a pro</h2>
                    <p className={Styles.one_app_subtitle}>Trade crypto like a pro with our crypto trading platform on your desktop. Experience the fastest transactions and our powerful API on Window or MacOS today.</p>
                    </div>
                </div>
                <div className='col-12 col-md-6 '>
                    <div style={{marginTop:'160px'}}>
                        <img src={GraphChartImage} alt='...' className='img-fluid img-responsive' style={{maxWidth:"500px",maxHeight:"392px"}}/>
                    </div>
                </div>
            </div>
        </section>
    )
  }

  const TooltipContent = ({ type }) => (
    <div className="m-0 p-0 bg-white d-flex justify-content-between" style={{backgroundColor:'white'}}>
        {
            type===1&&
        <div className='align-self-center'>
            <img src={QrCodeImage} alt='...' width="100px" height={"100px"}/>
        </div>
        }
        <div className='align-self-center'>
            <a href={type===1?'https://apps.apple.com/us/app/JRN-buy-bitcoin-btc-crypto/id1327268470':'https://static.tyhgkj.cn/upgradeapp/JRN.dmg' } target={type===1?'blank':""} style={{borderRadius:"28px"}} className='bg-transparent font-weight-bold border-secondary btn-block  px-4 py-2'><img className='mr-2' src={apple} alt='...' width={'18px'} height={"18px"}/> {type===2?'Mac OS':'App Store'}</a>
            <a href={type===2?'https://static.tyhgkj.cn/upgradeapp/JRN_Setup.exe':'https://play.google.com/store/apps/details?id=com.okinc.okex.gp'} target={type===2?
            '':'blank' }style={{borderRadius:"28px"}} className='bg-transparent font-weight-bold border-secondary btn-block  px-4 py-2'><img className='mr-2' src= {type===2?windowImage:playStore }alt='...' width={'18px'} height={"18px"}/>{type===2?'Windows':'Google Play'}</a>
        </div>
    </div>
);
  const renderbtnWithoutpopover=()=>{
    return (
        <div className='w-100 pb-5'>
            <a className={`${Styles.headerBtn2} btn-block btn  ${mode === "lightMode" ? 'bg-light text-dark' : 'bg-white text-black'}`} href='https://static.tyhgkj.cn/upgradeapp/JRN-android.apk' target='blank'>
                    <CiMobile4 size={25} color={'black'} />
                    <span className='ml-2' >Download app</span>
                </a>
                <a href='https://play.google.com/store/apps/details?id=com.okinc.okex.gp' target='blank' className={`${Styles.headerBtn2} btn btn-block mt-2 pb-2 w ${mode === "lightMode" ? 'bg-light text-dark' : 'bg-white text-black'}`}>
                <img className='mr-2' src= {"https://www.JRN.com/cdn/assets/imgs/231/4AF58DB34892590C.png?x-oss-process=image/format,webp" }alt='...' width={'18px'} height={"18px"}/>{'Google Play'}
                </a>
        </div>
    )
  }
  const renderPopoverBtn = () => {
    return (
        <div className='d-flex justify-content-between  w-100' style={{ marginTop: '36px' }}>
            <Tooltip
                placement="top"
                trigger={['hover']}
                overlay={<TooltipContent type={1}   />}
                overlayStyle={{  padding: 0,margin:0,backgroundColor:'white'  }} 
                overlayClassName={'m-0 p-0'}
            >
                <button className={`${Styles.headerBtn} btn-block btn  ${mode === "lightMode" ? 'bg-light text-dark' : 'bg-white text-black'}`}>
                    <CiMobile4 size={25} color={'black'} />
                    <span className='ml-2'>Download app</span>
                </button>
            </Tooltip>

            <Tooltip
                placement="top"
                trigger={['hover']}
                overlay={<TooltipContent type={2} />}
                overlayStyle={{ backgroundColor: 'white', border: 'none' }} 
            >
                <button className={`${Styles.headerBtn} ml-2 btn btn-block  m-0 w ${mode === "lightMode" ? 'bg-light text-dark' : 'bg-white text-black'}`}>
                    <SlScreenDesktop size={25} color={'black'} />
                    <span className='ml-2'>Download Desktop</span>
                </button>
            </Tooltip>
              
        </div>
    );
};
  // LIFE CYCLE METHOD (EVENT HANDLING)
  useEffect(() => {
    const handleResize = () => {
      setwindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);

    // Cleanup function to remove the event listener when component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <section style={{padding:windowWidth<1034?'0px 24px':'0px 135px',minHeight:'100vh'}}  className={`${mode === "lightMode" ? "bg-light text-dark" : "bg-black text-white"}`}>
        {renderFirstSection()}
        {windowWidth<1034?null:renderSecondScreen()}
        {renderThirdSection()}
        {windowWidth>768&& renderfourthSection()}
        {windowWidth<=768&& renderbtnWithoutpopover()}
      
    </section>
  )
}

export default DownloadScreen
