import React from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { FaThumbsUp, FaThumbsDown } from "react-icons/fa";
import { RiBarChartFill } from "react-icons/ri";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Styles from './collection.module.css'
import { checkValueexistornot } from "../../utils/utility/Utility";
import CustomShimmer from "../../commonComponent/CustomShimmer";


export default function InfiniteScrollinCollectionScreen(props) {
 const mode = useSelector((state) => state.themeType.themeType);
 const navigate=useNavigate();
 const {isLoading,currentPage}=props;



 const defaultShimmer=()=>{
  return (
    <div className="row mx-0 my-2">
        {[...Array(10).keys()].map((elem,i)=><div key={i} class={`${
        props.filterbtn ? "col-xl-3" : "col-xl-2"
      } col-lg-3 col-md-4 col-6 px-0 border-0`} style={{}}>
        <div className=" m-2 rounded-12" style={{border:"0.5px solid grey"}}>
        <CustomShimmer className="card-img-top cardImage rounded-12" style={{minHeight:"200px",borderTopRightRadius:"12px",borderTopLeftRadius:'12px'}} alt="Card image cap"/>
        <div class="card-body p-1">
        <CustomShimmer  alt="Card image cap" style={{width:"10em",height:"20px"}} />
        <CustomShimmer className="rounded-12" style={{width:"5em",height:"20px"}}/>
        <div className="d-flex align-items-center justify-content-between">
          <CustomShimmer  className="rounded-12"  style={{width:"2em",height:"20px"}}/>
          <CustomShimmer  className="rounded-12"  style={{width:"2em",height:"20px"}}/>
          <CustomShimmer  className="rounded-12"  style={{width:"2em",height:"20px"}}/>
          <CustomShimmer  className="rounded-12"  style={{width:"2em",height:"20px"}}/>
        </div>
        </div>
          </div>
      </div>)}
    </div>
  )
 }

  return (
    <InfiniteScroll
      dataLength={props.collectionData.length}
      next={() => {
        props.handlefetchMoreData();
      }}
      hasMore={true}
      loader={isLoading?currentPage>1?defaultShimmer():"":""}
      style={{ overflowX: "hidden" }}
    >
      <div className="row mx-0" id="CollectionBox">
        {(isLoading?[...Array(10).keys()]:props.collectionData).map((elem, index) => {
          return (
            <div
              key={index}
              className={`${
                props.filterbtn ? "col-xl-3" : "col-xl-2"
              } col-lg-3 col-md-4 col-6 px-0`}
            >
              <div className="mx-2">
              <div
                className={`card customCard  ${
                  props.filterbtn && props.windowWidth <= 992 ? "ml-1" : ""
                }  mt-2  pointer rounded ${
                  mode === "lightMode"
                    ? ""
                    : "bg-black border border-secondary"
                }`}
                style={{ overflow:'hidden'}}
                key={index}
                onClick={() => {
                 navigate(
                   `/assets/${elem?.blockchain==='bitcoin'?elem?.incription_id:elem?.contract_id}:${checkValueexistornot(elem?.token_id)}/overview`,
                    {state:{
                      userData: elem,
                      blockchain: elem?.blockchain,
                    }}
                  );
                }}
              >
                <div className={Styles.cardImageContainer}>
               {isLoading?<CustomShimmer className="img-fluid img-responsive cardImage" style={{minHeight:'200px'}} />: <img
                  alt={"..."}
                  src={elem.image_url} 
                  loading="lazy"
                  className="img-fluid img-responsive cardImage"
                />}
                </div>
                <div className="card-body m-0 p-2 pl-3">
                  <p
                    className="mb-1"
                    style={{
                      fontWeight: "bold",
                      fontSize: "14px",
                      lineHeight: "16px",
                    }}
                  >
                    {isLoading?<CustomShimmer className="rounded-12" style={{width:"5em",height:"20px"}}/>:elem?.name.length > 16
                      ? elem.name.slice(0, 16) + "..."
                      : elem?.name}
                  </p>
                  <div>
                    <p
                      className="mb-0"
                      style={{
                        color: "#929292",
                        fontSize: "12px",
                        lineHeight: "16px",
                      }}
                    >
                      {isLoading?<CustomShimmer className="rounded-12" style={{width:"5em",height:"20px"}}/>:"Price"}
                    </p>
                    <div className="d-flex justify-content-between">
                      {isLoading?<CustomShimmer className="rounded-12" style={{width:"2em",height:"20px"}}/>:<span
                        style={{
                          backgroundColor:
                            mode === "lightMode" ? "" : "black",
                          fontWeight: "600",
                          color: mode === "lightMode" ? "black" : "white",
                          textAlign:'center'
                        }}
                      >
                        <RiBarChartFill size={10} className="mx-2" />
                        { Number(checkValueexistornot(elem?.price,true)).toFixed(2)}
                      </span>}
                      {isLoading?<CustomShimmer className="rounded-12" style={{width:"2em",height:"20px"}}/>:<span
                        style={{
                          backgroundColor:
                            mode === "lightMode" ? "" : "black",
                          fontWeight: "600",
                          color: mode === "lightMode" ? "black" : "white",
                          textAlign:'center'
                        }}
                      >
                        <FaThumbsUp size={10} className="mx-2" />
                        {Math.round(elem?.like).toFixed(2)}
                      </span>}
                     {isLoading?<CustomShimmer className="rounded-12" style={{width:"2em",height:"20px"}}/>: <span
                        style={{
                          backgroundColor:
                            mode === "lightMode" ? "" : "black",
                          fontWeight: "600",
                          color: mode === "lightMode" ? "black" : "white",
                          textAlign:'center'
                        }}
                      >
                        <FaThumbsDown size={10} className="mx-2" />
                        {elem?.dislike}
                      </span>}
                     {isLoading?<CustomShimmer className="rounded-12" style={{width:"2em",height:"20px"}}/>: <span
                        style={{
                          backgroundColor:
                            mode === "lightMode" ? "" : "black",
                          fontWeight: "600",
                          color: mode === "lightMode" ? "black" : "white",
                          textAlign:'center'
                        }}
                      >
                        <RiBarChartFill size={10} className="mx-2" />
                        {" "}
                        {elem?.volume}
                      </span>}
                    </div>
                  </div>
                </div>
              </div>
              </div>
            </div>
          );
        })}
      </div>
    </InfiniteScroll>
  );
}
