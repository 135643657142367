import React, { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { FaRegStar } from "react-icons/fa";
import Axios from "../../config/API/Api";
import { tracer } from "../../tracing";
import { trace } from "firebase/performance";
import { sendPerfomance } from "../..";
import InfiniteScroll from "react-infinite-scroll-component";
import Loader from "../../commonComponent/Loader";
import { useNavigate } from "react-router-dom";
import ReactGA from 'react-ga4';

export default function OrdinalTabScreen(props) {
  const { loading, search, updateitem, windowWidth } = props;
  const mode = useSelector((state) => state.themeType.themeType);
  const [isloading, setisloading] = useState(loading);
  const [currentPage, setcurrentPage] = useState(1);
  const navigate=useNavigate();
  const [data, setdata] = useState(props.data === undefined ? [] : props.data);
  const columnList = [
    "Tick",
    "Volume",
    "Price",
    "Num of sales",
    "Holders",
    "Progress%",
  ];
  const searchcollectionapi = async (page, isnext) => {
    const url = `/search/collection`;
    let params = {
      search: search,
      size: "10",
      page: String(page),
      blockchain: "bitcoin",
    };
    try {
      const t = trace(sendPerfomance, "Search_collection_api");
      const span = tracer.startSpan("SearchCollectionApi");
      t.start();
      setisloading(true);
      await Axios.post(url, params)
        .then((res) => {
          if (res.status === 200) {
            span.setAttributes({ method: "POST" });
            span.addEvent("api_response", {
              status: res.status,
              url,
              data: res.data,
            });
            t.getAttributes({
              status: res.status,
              url,
              data: res.data,
              payload: params,
            });
            if (isnext) {
              setdata([...data, ...res.data.rows]);
            } else {
              setdata(res.data.rows);
            }
            updateitem(res.data?.count);
          }
        })
        .catch((err) => {
          console.log(err);
          span.setStatus({ code: "ERROR", message: err?.message });
        })
        .finally(() => {
          t.stop();
          span.end();
          setisloading(false);
        });
    } catch (error) {
      console.log(error);
    }
  };
  useMemo(() => {
    setdata([]);
    setcurrentPage(1);
    if (search?.length > 2) {
      searchcollectionapi(currentPage, false);
    }
  }, [search]);

  return (
    <section>
      <div className="mt-2 d-flex justify-content-center">
        {!isloading && data.length === 0 ? (
          <h1 className="text-center">No data Available</h1>
        ) : null}
        {isloading ? <Loader size={25} /> : null}
      </div>
      <InfiniteScroll
        dataLength={data.length}
        hasMore={true}
        next={() => {
          if (data.length % 10 === 0 && currentPage > 1) {
            setcurrentPage(() => {
              return currentPage + 1;
            });
            searchcollectionapi(currentPage + 1, true);
          }
        }}
      >
        {data.length !== 0 && (
          <section>
            <table
              className={`table table-borderless ${
                mode === "lightMode" ? "" : "table-dark bg-black"
              }`}
            >
              <thead className="position-sticky">
                <tr>
                  {(windowWidth <= 1024
                    ? ["Tick", "Volume", "Price", "Num of sales"]
                    : columnList
                  ).map((elem, index) => (
                    <th scope="col" key={index} className="tablerowTitle" style={{textAlign:elem==="Tick"?'left':"center"}}>
                      {elem}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody className="table-borderless">
                {data !== undefined ?
                  data.map((elem, index) => (
                    <tr key={index}>
                      <th scope="row">
                        <div
                          className="d-flex align-items-center pointer"
                          onClick={() => {
                            navigate(
                              `/collection/${elem?.contract_id}/overview`,
                              {state:{
                                userData: elem,
                                blockchain: elem?.blockchain,
                                image_url: elem?.image_url,
                                contract_id:elem?.contract_id
                              }}
                            );
                          }}
                        >
                          <img
                            src={elem?.image_url}
                            width={"32px"}
                            height={"32px"}
                          />
                          <span className="mx-2 font-18 font-weight-bold lh-24">
                            {elem?.name}
                          </span>
                          <span
                            style={{
                              backgroundColor: "#ebebebb3",
                              borderRadius: "12px",
                            }}
                            className="px-2 font-12"
                          >
                            {elem?.title}
                          </span>
                        </div>
                      </th>
                      <td>
                        <span className="d-flex font-14  justify-content-center" >
                          <img
                            className="mr-1"
                            width={"16px"}
                            height={"16px"}
                            src={elem?.icon_image}
                          />
                          {elem?.volume}
                        </span>
                        <span
                          className="mt-1 text-center"
                          style={{
                            fontSize: "12px",
                            color: mode === "lightMode" ? "#929292" : "#929292",
                          }}
                        >
                          {elem?.volume_count}
                        </span>
                      </td>
                      <td className="text-center">
                        <span className="font-14  ">
                          {elem.price}
                        </span>
                        <br />
                        <span className="font-12 text-muted1 ">
                          {elem?.price_count}
                        </span>
                      </td>
                      <td className="font-14 fontweight-700 align-items-center text-center">
                        {elem?.num_of_sales}
                      </td>
                      {windowWidth > 1024 && (
                        <td className="font-14 fontweight-700 text-center">
                          {elem?.Holders}
                        </td>
                      )}
                      {windowWidth > 1024 && (
                        <td className="font-14 fontweight-700 text-right">
                          {elem?.progress}%
                          <div
                            className="bg-danger"
                            style={{
                              borderBottom: `4px solid green`,
                              width: `${elem?.progress}%`,
                            }}
                          ></div>
                        </td>
                      )}

                      {windowWidth > 1024 && (
                        <td className="fontweight-700 ">
                          <FaRegStar size={25} className="fontweight-700" />
                        </td>
                      )}
                    </tr>
                  )):null}
              </tbody>
            </table>
          </section>
        )}
      </InfiniteScroll>
    </section>
  );
}
