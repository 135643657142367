import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { useSelector } from "react-redux";
const CustomShimmer = (props) => {
    const {className,style}=props
  const mode = useSelector((state) => state.themeType.themeType);

  return (
    <SkeletonTheme
      baseColor={mode === "darkMode" && "#202020"}
      highlightColor={mode === "darkMode" && "#444"}
    >
      <Skeleton className={className} style={{minWidth:'10px',minHeight:'36px',...style}} {...props} />
    </SkeletonTheme>
  );
};

export default CustomShimmer;
