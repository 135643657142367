import React,{useState} from "react";
import Switch from "@mui/material/Switch";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import {FaRegStar} from "react-icons/fa";
import Styles from './Defi.module.css'
import { useNavigate } from "react-router-dom";
const TabScreen = ({ currentTab,mode,windowWidth }) => {
  const label = { inputProps: { "aria-label": "Color switch demo" } };
  const [data,setdata]=useState(Array.from({length:10}));
  const navigate=useNavigate();
  return (
    <div >
        <div className="d-flex align-items-center">
        <Switch {...label} defaultChecked />
        <p className="ml-2 font-18 mb-0">Signal Only</p>
        </div>

      <div className="row">
        <div className="col" id="CollectionTable">
          <TableContainer
            component={Paper}
            className="scrollbar "
            style={{ boxShadow: "none" }}
          >
            <Table
              // sx={{ minWidth: 650 }}
              aria-label="simple table"
              style={{ borderWidth: "0px" }}
              className={`${
                mode === "lightMode"
                  ? "bg-white text-dark"
                  : "bg-dark text-white"
              }`}
            >
              <TableHead className="">
                <TableRow>
                 {windowWidth>768&& <TableCell
                    className={`py-1 pr-0 tablerowsTitle ${
                      mode === "lightMode" ? "" : "text-white"
                    }`}
                  >
                    #
                  </TableCell>}
                  <TableCell
                    align="left"
                    className={`py-1 pl-0 tablerowsTitlewithoutAlign  ${
                      mode === "lightMode" ? "" : "text-white"
                    }`}
                  >
                    {windowWidth>768?"Investment":'All networks'}
                  </TableCell>
                  {windowWidth > 768 && (
                    <TableCell
                      align="center"
                      className={`py-1 tablerowsTitle ${
                        mode === "lightMode" ? "" : "text-white"
                      }`}
                    >
                      Network
                    </TableCell>
                  )}
                  {windowWidth > 768 && (
                    <TableCell
                      align="center"
                      className={`py-1 tablerowsTitle ${
                        mode === "lightMode" ? "" : "text-white"
                      }`}
                    >
                      TVL
                    </TableCell>
                  )}
                  <TableCell
                    align="center"
                    className={`py-1 tablerowsTitle ${
                      mode === "lightMode" ? "" : "text-white"
                    }`}
                  >
                    {windowWidth>768?'APY':'APY/APR to low'}
                  </TableCell>

                    <TableCell
                      align="center"
                      className={`py-1 tablerowsTitle ${
                        mode === "lightMode" ? "" : "text-white"
                      }`}
                    >
                      {windowWidth>768?'ACTION':'Signle Only'}
                    </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.length > 0 &&
                  data.map((row, index) => (
                    <TableRow
                      key={index}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      <TableCell
                        component="th"
                        scope="row"
                        className={`py-4 px-0 hideBorder tablerowsData font-weight-bold text-center ${
                          mode === "lightMode" ? "text-dark" : "text-white"
                        }`}
                        style={{ width: "50px" }}
                      >
                       {index+1}
                      </TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        className="pointer tablerowsData hideBorder px-0 "
                        onClick={() => {
                          navigate(`/defi/details/1`)
                        }}
                        style={{ width: "250px" }}
                      >
                        <div className="d-flex align-items-center">
                        <span>
                          <img
                            src={'https://www.JRN.com/cdn/wallet/logo/USDC.png?x-oss-process=image%2Fresize%2Cw_64%2Ch_64&x-oss-process=image/format,webp/ignore-error,1'}
                            alt="..."
                            width={"40px"}
                            height={"40px"}
                            style={{
                              // maxHeight: "72px",
                              borderRadius: "1rem",
                            }}
                            className="rounded rouned-circle"
                          />
                          &nbsp;&nbsp;&nbsp;&nbsp;
                        </span>
                        <div>
                        <p
                          className={`hideborder tablerowsData mb-0 ${
                            mode === "lightMode" ? "text-dark" : "text-white"
                          }`}
                        >
                         USDT
                        </p>
                        <small>WePiggy</small>
                        </div>
                        </div>
                      </TableCell>
                      <TableCell
                        align="center"
                        className={` hideBorder tablerowsData  text-center  ${
                          mode === "lightMode" ? "text-dark" : "text-white"
                        }`}
                        style={{ width: "50px" }}
                      >
                       <img src="https://www.JRN.com/cdn/wallet/logo/arb_9000.png?x-oss-process=image/format,webp" alt="" width={'24px'} height={'24px'} loading="lazy"/>
                      </TableCell>
                       {windowWidth>768&& <TableCell
                          align="center"
                          className={`text-success tablerowsData hideBorder  ${
                            mode === "lightMode" ? "text-success" : "text-white"
                          }`}
                        >
                          $171.52 K %
                        </TableCell>}
                        {windowWidth>768&&<TableCell
                          align="center"
                          className={`hideBorder tablerowsData  ${
                            mode === "lightMode" ? "" : "text-white"
                          }`}
                          style={{color:'#31BD65'}}
                        >
                          21.42 %
                        </TableCell>}
                        {windowWidth>768&&<TableCell
                        align="center"
                        className={`hideBorder font-weight-bold`}
                        style={{ width: "50px" }}
                      >
                        <button className={`${Styles.tableBtn}`}>Invest</button>
                      </TableCell>}
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>


    </div>
  );
};

export default TabScreen;
