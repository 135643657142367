import ReactGA from 'react-ga4';
const MEASUREMENT_ID =process.env.REACT_APP_measurementId;
ReactGA.initialize(MEASUREMENT_ID, { debug_mode: true });
export const initGA = () => {
    ReactGA.send("pageview");
};

export const trackButtonClick = (category,action,label ,nonInteraction=false,value=5,dimension1='') => {
    ReactGA.event({category,action,label,nonInteraction,value,dimension1});
  };

export const GoogleTrackEvent=(category,action,label,nonInteraction=false,value)=>{ReactGA.event({category,action,label,nonInteraction,value})};

export const GoogletrackSet=(user_id,user_properties={})=>{ReactGA.set({user_id,user_properties});}
  