import React, { useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import "react-tabs/style/react-tabs.css";
import "firebase/performance";
import ResultData from "./ResultData";
import "./Home.css";
import { trace } from "firebase/performance";
import { sendPerfomance } from "../..";
import axios from "axios";
import CustomCarousel from "../../commonComponent/CustomCarousel";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { toast } from "react-toastify";
import bluetick from "../../assets/blue_Tick.png";
import { FaChevronRight, FaChevronLeft, FaCheck } from "react-icons/fa";
import { tracer } from "../../tracing";
import Axios, { BaseUrl, REACT_APP_FETCH_PARTICULAR_COLLECTION_BASEURL, testingUrl } from "../../config/API/Api";
import  { Loader2 } from "../../commonComponent/Loader";
import Slider from "react-slick";
import UserImage from "../../assets/user.png";
import Select from "react-select";
import { MdOutlineChevronRight, MdOutlineChevronLeft } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import CookiesConsent from "../CookiesConsent/CookiesConsent";
import Cookies from 'js-cookie';
import ReactGA from 'react-ga4';
import { initGA, trackButtonClick } from "../../utils/utility/GAnalytics";
import styles from './Home.module.css'
import CustomShimmer from "../../commonComponent/CustomShimmer";
import { checkValueexistornot } from "../../utils/utility/Utility";
import NoDataFound from "../../commonComponent/NoDataFound";
export default function HomeScreen() {
  const token = localStorage.getItem("accessToken");
  const mode = useSelector((state) => state.themeType.themeType);
  const [tabIndex, setTabIndex] = useState(0);
  const [isCategoryArrowVisible, setisCategoryArrowVisible] = useState(false);
  const [iscollectionArrowVisible, setisCollectionArrowVisible] =
    useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [data, setdata] = useState([]);
  const navigate=useNavigate()
  const [chainlist, setchainlist] = useState([]);
  const [activeChain, setactivechain] = useState("all");
  const [bannerdata, setbannerdata] = useState([]);
  const [TrandingCollection, settrandingCollection] = useState([]);
  const [windowWidth, setwindowWidth] = useState(window.innerWidth);
  const [istrandingCollectionloading, setistrandingcollectionloading] =
    useState(false);
  const [currentTopcollectionblockchain, setcurrentTopcollectionblockchain] =
    useState("all");
  const [iscategorylistLoading, setiscategorylistLoading] = useState(true);
  const [categoryList, setcategoryList] = useState([]);
  const [currentcategoryPage, setcurrentcategoryPage] = useState(1);
  const [showCookiePopup,setshowCookiePopup]=useState(false);
  const arrowRef = useRef(null);
  const arrowRef2 = useRef(null);
  const [ispanelListLoading,setispanelListLoading]=useState(true);
  console.log('window.env is =====>>>>',window.env);
  // INTERVAL STATE
  const intervalList = [
    { id: 1, label: "1h", value: "1H" },
    { id: 2, label: "24h", value: "24H" },
    { id: 3, label: "7d", value: "7D" },
    { id: 4, label: "30d", value: "30D" },
  ];
  const [activeInterval, setactiveInterval] = useState(4);
  const initialWebsocketMessage={
    action: "subscribe",
    channels: [{
        name: "collection_index_like",
        values: ["collection_index", "10", "1", intervalList[activeInterval-1]?.label, currentTopcollectionblockchain],
      }]};
  const [webSocketMessage, setwebSocketMessage] = useState(initialWebsocketMessage);
  const updateSelectCollection=(selectCollection,choice,name)=>{
    const newWebsocketmessage={...webSocketMessage}
    if(newWebsocketmessage!==undefined){
      newWebsocketmessage.channels.map((o)=>{
        o.name=name;
        o.values=[selectCollection, "10",choice, intervalList[activeInterval-1]?.label, currentTopcollectionblockchain]
      });
      setwebSocketMessage(newWebsocketmessage)
    }};

  const updatewebsocketmessage = (val) => {
    setwebSocketMessage(val);
  };
  const updatetabIndex = (val) => {
    setTabIndex(val);
  };
 
  const AutomaticSliderSection = () => {
    var settings = {
      dots: false,
      infinite: true,
      slidesToShow: 4,
      arrows: false,
      slidesToScroll: 1,
      autoplay: true,
      initialSlide: 1,
      autoplaySpeed: 1000,
      pauseOnHover: true,
      responsive: [
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
            initialSlide: 1,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            initialSlide: 1,
          },
        }
      ],
    };

    return (
      <div className="automatic-slider-section ">
        <div className="slider-content my-5 mx-4">
          {" "}
          <h2
            className={`slider-title ${
              windowWidth < 992 ? "font-32" : "font-48"
            }`}
            style={{ color: mode === "lightMode" ? "#141416" : "#FCFCFD" }}
          >
            Buy, sell, and lend your NFTs
          </h2>
          <p className={`slider-description`}>
            Best place to liquify your illiquidity
          </p>
        </div>
        <div className="slider-container">
          {windowWidth > 992 && (
            <div
              onClick={() => {
                if(arrowRef2.current){
                  arrowRef2.current.slickPrev();
                }
              }}
              className={`rounded rounded-circle pointer ${
                mode === "lightMode" ? "bg-white" : "bg-dark"
              }`}
              style={{
                width: "30px",
                height: "30px",
                border: "1px solid white",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                position: "absolute",
                top: "60%",
                zIndex: 1,
                left: "2px",
              }}
            >
              <MdOutlineChevronLeft
                size={20}
                className="text-center"
                color={mode === "lightMode" ? "black" : "white"}
              />
            </div>
          )}
          <Slider {...settings} ref={(c) => (arrowRef2.current = c)}>
            {(ispanelListLoading?[...Array(5).keys()]:bannerdata).map((elem, index) => (
              <div className="slide-item" key={index}>
                <div
                  className={`image-container`}
                  onClick={() => {
                    trackButtonClick('User Interaction','Click',"user click on panellist item on homeScreen.")
                    navigate(`/collection/${elem?.id}/overview`, {state:{
                      userData: elem,
                      blockchain: elem?.blockchain,
                      image_url:elem.image_url === "null" ? UserImage : elem.image_url,
                      contract_id:elem?.id
                    }});
                  }}
                >
                  <div className={styles.cardImageContainer}>
                  {ispanelListLoading?<CustomShimmer className={`${styles.panelListCard} img-fluid`} />:<img
                    src={elem?.image_url}
                    alt="Your Image"
                    loading="lazy"
                    className={`${styles.panelListCard} img-fluid`}
                  />}
                  </div>
                  <div className={`hover-overlay`}>
                    <div className={`overlay-content`}>
                      <span className="d-flex">
                        <p className={`mb-0 font-weight-bold ${windowWidth<768?'font-12':'font-16'}`}>
                          {elem.name !== undefined
                            ? elem.name.length > 10
                              ? elem.name.slice(0, 10) + "..."
                              : elem.name
                            : null}
                        </p>
                        <img
                          src={bluetick}
                          alt="..."
                          width={"24px"}
                          height={"24px"}
                        />
                      </span>
                      {!ispanelListLoading&&<p className={`font-weight-bold ${windowWidth<768?'font-12':'font-16'}`}>Floor: {Number(checkValueexistornot(elem?.floor_price,true)).toPrecision(3)}</p>}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </Slider>
          {windowWidth > 992 && (
            <div
              onClick={() => {
                if(arrowRef2.current){
                  arrowRef2.current.slickNext();
                }
              }}
              className={`rounded rounded-circle ${
                mode === "lightMode" ? "bg-white" : "bg-dark"
              }`}
              style={{
                width: "30px",
                height: "30px",
                border: "1px solid white",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                position: "absolute",
                top: "60%",
                zIndex: 1,
                right: "2px",
              }}
            >
              <MdOutlineChevronRight
                size={20}
                className="text-center"
                color={mode === "lightMode" ? "black" : "white"}
              />
            </div>
          )}
        </div>
      </div>
    );
  };

  const SliderbannerApi = async () => {
    const url = BaseUrl + "/panellist";
    const data = {
      blockchain: "all",
    };
    const span = tracer.startSpan("PanelListApi");
    try {
      setispanelListLoading(true)
      await axios
        .post(url, data)
        .then((res) => {
          if (res.status === 200) {
            console.log("this is panel api", res.data);
            setbannerdata(res.data);
          }
          span.setAttribute("Method", "POST");
          span.addEvent("api_response", {
            status: res.status,
            url,
            data: res.data,
          });
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          span.end();
          setispanelListLoading(false)
        });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    SliderbannerApi();
    blockchainApi();
    const cookieConsent = Cookies.get('cookieConsent');
    if (!cookieConsent) {
      setshowCookiePopup(true);
    }else if(cookieConsent==='accepted'){
      initGA();
      setshowCookiePopup(false);
    }
    ReactGA.send("pageview", { page_path: window.location.pathname + window.location.search });
  }, []);

  const updateInterval = (val) => {
    const newWebsocketmessage={...webSocketMessage}
    if(newWebsocketmessage!==undefined){
      newWebsocketmessage.channels.map((o)=>{
        o.values[3]=intervalList[val-1].label
      });
    setwebSocketMessage(newWebsocketmessage)}
    setactiveInterval(val);
  };
  const updateblockChain = (val) => {
    const newWebsocketmessage={...webSocketMessage}
    if(newWebsocketmessage!==undefined){
      newWebsocketmessage.channels.map((o)=>o.values[4]=val);
      setwebSocketMessage(newWebsocketmessage)
    }
    setactivechain(val);
  };
  const topCollectionSection = () => {
    const customStyles = {
      option: (provided, state) => ({
        ...provided,
        borderBottom: "0px solid #ccc",
        color: state.isSelected ? "#fff" : "#333", // Selected text color
        background: mode === "lightMode" ? "" : "#1b1b1b",
        padding: "10px",
      }),
      control: (provided) => ({
        ...provided,
        border: mode === "lightMode" ? "1px solid #ccc" : "0px",
        borderRadius: "4px",
        cursor: "pointer",
        height: "45px",
        position: "relative",
        bottom: "9px",
        margin: "0 0 0 25px",
        backgroundColor: mode === "lightMode" ? "#f6f6f6" : "#1b1b1b",
      }),
      singleValue: (provided) => ({
        ...provided,
        color: mode === "lightMode" ? "#333" : "white", // Selected text color
      }),
      indicatorSeparator: () => ({ display: "none" }), // Remove separator
      dropdownIndicator: (provided) => ({
        ...provided,
        color: mode === "lightMode" ? "black" : "white", // Dropdown icon color
        fontSize: "10px",
        transition: "transform 0.7s", // Add transition for smooth rotation
        transform: isOpen ? "rotate(180deg)" : "rotate(0deg)",
      }),
      menuList: (base) => ({
        ...base,
        padding: 0,
        "::-webkit-scrollbar": {
          width: "4px",
          height: "0px",
        },
        "::-webkit-scrollbar-track": {
          background: "#f1f1f1",
        },
        "::-webkit-scrollbar-thumb": {
          background: "#888",
        },
        "::-webkit-scrollbar-thumb:hover": {
          background: "#555",
        },
      }),
      tickIcon: {
        marginRight: "5px",
      },
    };
    return (
      <section
        className={` mt-2 py-4 rounded-12 mx-2 ${
          mode === "lightMode" ? "bg-white text-black" : "bg-dark  text-white"
        }`}
        onMouseEnter={() => {
          setisCollectionArrowVisible(true);
        }}
        onMouseLeave={() => {
          setisCollectionArrowVisible(false);
        }}
        onTouchStart={() => {
          setisCollectionArrowVisible(true);
        }}
        onTouchEnd={() => setisCollectionArrowVisible(false)}
      >
        <div className="row m-0">
          <div className="col  justify-content-between mx-auto my-2 d-flex p-0">
            <h5 className={`font-weight-bold ml-2 text-nowrap align-self-center ${windowWidth<768?"font-22":'font-24'}`}
            >
              Top Collections
            </h5>
            <Select
              options={chainlist}
              value={chainlist.find(
                (option) => option.key === currentTopcollectionblockchain
              )}
              onChange={(selectedOption) => {
                ReactGA.event({
                  category: 'Select input',
                  action: 'clicked on the select input',
                  label: selectedOption?.key,
                  nonInteraction:false,
                  value: selectedOption?.key
                })
                setcurrentTopcollectionblockchain(selectedOption?.key);
                // updateblockChain(selectedOption?.key);
              }}
              isSearchable={false}
              isClearable={false}
              getOptionLabel={(option) => (
                <div className="scrollbar border-0 d-flex justify-content-between align-items-center">
                  <span>
                    <img
                      src={`data:image/jpg;base64,${option?.image_url}`}
                      alt={option?.name}
                      style={{ width: "20px", marginRight: "10px" }}
                    />
                    {option?.name}
                  </span>
                </div>
              )}
              getOptionValue={(option) => option.key}
              styles={customStyles}
              className="mt-3 font-14 border-0 scrollbar"
              onMenuOpen={() => setIsOpen(true)}
              onMenuClose={() => setIsOpen(false)}
              // Customizing the Option component to display a tick mark if the option is selected
              components={{
                Option: ({ children, isSelected, ...rest }) => (
                  <div
                    className={`d-flex justify-content-between align-items-center px-2 py-3 ${
                      isSelected ? "selected" : ""
                    }`}
                    style={{
                      backgroundColor:
                        mode === "lightMode" ? "#f6f6f6" : "#1b1b1b",
                    }}
                    {...rest}
                    onClick={() => {
                      rest.selectOption(rest.data);
                    }}
                  >
                    {children}
                    {isSelected && (
                      <FaCheck
                        size={15}
                        className="font-weight-bold"
                        color={mode === "lightMode" ? "black" : "white"}
                      />
                    )}
                  </div>
                ),
              }}
            />
          </div>
        </div>
        <div className="row mx-0 d-flex flex-nowrap align-items-center  scrollbar overflow-auto">
                {(istrandingCollectionloading?[...Array(10).keys()]:TrandingCollection
                ).map((elem, index) => {
                  return (
                    // <div className="">
                    <div
                    className={`pointer col col-lg-3 col-md-4 col-sm-6 ${
                      mode === "lightMode"
                        ? "bg-white text-dark"
                        : "bg-black text-white"
                    }`}
                      onClick={() => {
                        ReactGA.event({
                          category: 'clicked on a top collection item',
                          action: 'card item',
                          label: elem?.name,
                          nonInteraction:false,
                          value:JSON.stringify(elem)
                        })
                        trackButtonClick('User Interaction','Click',"user click on trending collection item.")
                        navigate(
                          `/collection/${elem?.contract_id}/overview`,
                          {state:{
                            userData: elem,
                            blockchain: elem.blockchain,
                            contract_id:elem?.contract_id
                          }}
                        );
                       }}
                      style={{
                        borderRadius: "12px",
                        maxWidth: "220px",
                        maxHeight: "273px",
                      }}
                      key={index}
                    >
                      <div className={`${styles.CategoriesImageContainer} `}>
                        {istrandingCollectionloading?<CustomShimmer className={`card-img-top ${styles.CategoriesImage} m-0`} style={{height:'185px'}}/> :<img
                          className={`card-img-top ${styles.CategoriesImage} m-0`}
                          src={elem?.image_url}
                          alt="Card image cap"
                          height={"185px"}
                        />}
                      </div>
                      <div
                        className="card-body  p-2 topCollectionbody"
                        style={{
                          borderRadius:'0 0 15px 15px',
                          backgroundColor:
                            mode === "lightMode" ? "white" : "#202020",
                        }}
                      >
                        <h5
                          className="card-title font-weight-bold my-2 font-16 d-flex "
                        >
                          {istrandingCollectionloading?<CustomShimmer className="rounded-10" style={{width:'100px'}} />:elem.name
                            ? elem.name.length > 15
                              ? elem.name.slice(0, 15) + "..."
                              : elem.name
                            : null}
                        </h5>
                        <p className=" d-flex font-weight-bold justify-content-between align-items-center">{istrandingCollectionloading?<CustomShimmer className="rounded-10" style={{width:'50px'}} />:<span>Count</span>}{istrandingCollectionloading?<CustomShimmer className="rounded-10" style={{width:'50px'}} />:elem?.count}</p>
                      </div>
                    </div>
                    // </div>
                  );
                })}
        </div>
              {!istrandingCollectionloading&&TrandingCollection.length===0&&<div className="d-flex justify-content-center align-items-center"><NoDataFound mode={mode}/></div>}
      </section>
    );
  };
  const apiforNftCategory = async () => {
    const span = tracer.startSpan("BrowseNFTCategoryListAPI");
    const t = trace(sendPerfomance, "BrowseNFTCategoryListAPI");
    const url = BaseUrl + "/category";
    const params = {
      size: 5,
      page: currentcategoryPage,
    };
    try {
      t.start();
      setiscategorylistLoading(true);
      await axios
        .get(url, { params })
        .then((res) => {
          if (res.status === 200) {
            console.log("this is blockchain lst", res.data);
            if (typeof res.data === "string") {
              toast.warn("invalid data got through blockchain api");
              return;
            }
            console.log("response data is", res.data);
            if (res.data.length > 0) {
              setcategoryList(res.data);
            }
          }
          span.setAttribute("Method", "POST");
          span.addEvent("api_response", {
            status: res.status,
            url,
            data: res.data,
          });
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          span.end();
          t.stop();
          setiscategorylistLoading(false);
        });
    } catch (error) {
      setiscategorylistLoading(false);

      console.log(error);
    }
  };
  const browseNFTcategorySection = () => {
    const customresponsive = {
      superLargeDesktop: {
        breakpoint: { max: 4000, min: 3000 },
        items: 5,
        partialVisibilityGutter: 50,
      },
      desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 5,
        partialVisibilityGutter: 50,
      },
      tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 3,
        partialVisibilityGutter: 50,
      },
      mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 2,
        partialVisibilityGutter: 50,
      },
    };
    return (
      <section
        style={{ borderRadius: "12px" }}
        className={` mt-2 py-4   ${
          mode === "lightMode" ? "bg-white text-black" : "bg-dark text-white"
        }`}
        onMouseEnter={() => {
          setisCategoryArrowVisible(true);
        }}
        onMouseLeave={() => {
          setisCategoryArrowVisible(false);
        }}
        onTouchStart={() => {
          setisCategoryArrowVisible(true);
        }}
        onTouchEnd={() => setisCategoryArrowVisible(false)}
      >
        <div className="row m-0">
          <div className="col-12 mx-auto px-0">
            <h3
              className="font-weight-bold ml-2"
              style={{
                fontSize: "24px",
                lineHeight: "132%",
                fontFamily:
                  "HarmonyOS Sans,PingFang SC,OSPunctuation,Microsoft Yahei,Heiti SC,WenQuanYi Micro Hei,Helvetica Neue,Helvetica,Arial,sans-serif !important",
              }}
            >
              Browse NFTs by category
            </h3>
          </div>
          <div className="col-12 mx-auto px-0 ">
            {
              <div className="">
                {currentcategoryPage !== 1 && (isCategoryArrowVisible||windowWidth<768) && (
                  <div
                    className="carousal_Icon pointer leftArrowCategory"
                    onClick={() => {
                      if (currentcategoryPage === 1) {
                        toast.warn("You are already at 1st page.");
                      } else {
                        setcurrentcategoryPage(currentcategoryPage - 1);
                        trackButtonClick('User Interaction','Click',"user click on previous page")
                      }
                    }}
                    style={{
                      opacity: currentcategoryPage === 1 ? "0.1" : "0.9",
                      backgroundColor: mode === "lightMode" ? "" : "#121212",
                    }}
                  >
                    <FaChevronLeft
                      size={15}
                      color={mode == "lightMode" ? "black" : "white"}
                    />
                  </div>
                )}
                <Carousel
                  additionalTransfrom={0}
                  swipeable={true}
                  draggable={true}
                  showDots={false}
                  responsive={customresponsive}
                  infinite={false}
                  arrows={true}
                  autoPlaySpeed={1000}
                  keyBoardControl={true}
                  customTransition="all .1"
                  transitionDuration={500}
                  containerclassName="carousel-container"
                  slidesToSlide={5}
                  removeArrowOnDeviceType={["tablet", "mobile"]}
                  dotListclassName="custom-dot-list-style"
                  itemclassName="carousel-item-padding-40-px"
                >
                  {(iscategorylistLoading?[...Array(5).keys()]:categoryList).map(
                    (elem, index) => {
                      return (
                        <div
                          onClick={() => {
                          trackButtonClick('User Interaction','Click',"user click on category Item")
                            navigate(`/category/${elem?.key}`, {state:{
                              item: elem,
                            }});
                          }}
                          className={`pointer card mx-2 ${
                            mode === "lightMode"
                              ? "bg-white text-dark"
                              : "bg-black text-white"
                          }`}
                          style={{ borderRadius: "12px", maxWidth: "220px" }}
                          key={index}
                        >
                          {iscategorylistLoading? <CustomShimmer className={`card-img-top ${styles.CategoriesImage}`} style={{heigth:'180px'}}/>: <img
                            className={`card-img-top ${styles.CategoriesImage}`}
                            src={elem?.value}
                            alt="Card image cap"
                            height={"180px"}
                          />}
                          <div
                            className="card-body  p-2"
                            style={{
                              backgroundColor:
                                mode === "lightMode" ? "white" : "#202020",
                            }}
                          >
                            <h5
                              className="card-title font-weight-bold my-2"
                              style={{ fontSize: "16px", lineHeight: "20px" }}
                            >
                              {iscategorylistLoading?<CustomShimmer className="rounded-10" style={{width:'121px'}} />:elem?.key}
                            </h5>
                          </div>
                        </div>
                      );
                    }
                  )}
                </Carousel>
                {(isCategoryArrowVisible||windowWidth<768) && (
                  <div
                    className="carousal_Icon carousal_right_icon pointer rightArrowCategory"
                    style={{
                      backgroundColor: mode === "lightMode" ? "" : "#121212",
                    }}
                    onClick={() => {
                      if (categoryList.length === 5) {
                        trackButtonClick('User Interaction','Click',"user click on next page of category section on homepage")
                        setcurrentcategoryPage(currentcategoryPage + 1);
                      } else {
                        toast.warn("No more Categories on next Page.");
                      }
                    }}
                  >
                    <FaChevronRight
                      size={15}
                      color={mode == "lightMode" ? "black" : "white"}
                    />
                  </div>
                )}
              </div>
            }
            {!iscategorylistLoading && categoryList.length == 0 && (
              <NoDataFound mode={mode}/>
            )}
          </div>
        </div>
      </section>
    );
  };
 
  useMemo(() => {
    apiforNftCategory();
  }, [currentcategoryPage]);

  const blockchainApi = async () => {
    const span = tracer.startSpan("BlockChainListApi");
    const url = BaseUrl + "/blockchainlist";
    try {
      await axios
        .get(url)
        .then((res) => {
          if (res.status === 200) {
            if (typeof res.data === "string") {
              toast.warn("invalid data got through blockchain api");
              return;
            }
            if (res.data.length > 0) {
              // const data=res.data.map((elem)=>elem.name);
              setwebSocketMessage(previous=>{
                previous.channels.map((o)=>o.values[4]=res.data[0]?.key);
                return previous;
              })
              setchainlist(res.data);
              setcurrentTopcollectionblockchain(res.data[0].key);
            }
          }
          span.setAttribute("Method", "POST");
          span.addEvent("api_response", {
            status: res.status,
            url,
            data: res.data,
          });
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          span.end();
        });
    } catch (error) {
      console.log(error);
    }
  };

  const TopCollectionApi = async () => {
    const span = tracer.startSpan("TopCollectionApi");
    const t = trace(sendPerfomance, "TopCollectionApi");
    const url = `/trendingCollections?blockchain=${currentTopcollectionblockchain}`;
    try {
      t.start();
      setistrandingcollectionloading(true);
      await Axios.get(url)
        .then((res) => {
          if (res.status === 200&&res.data!==undefined) {
            settrandingCollection(res.data);
          }
          span.setAttribute("Method", "GET");
          span.addEvent("api_response", {
            status: res.status,
            url,
            data: res.data,
          });
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          span.end();
          t.stop();
          setistrandingcollectionloading(false);
        });
    } catch (error) {
      console.log(error);
      span.end();
    }
  };
  useMemo(() => {
    TopCollectionApi();
  }, [currentTopcollectionblockchain]);

  useEffect(() => {
    const handleResize = () => {setwindowWidth(window.innerWidth)};
    window.addEventListener("resize", handleResize);
    return () => {window.removeEventListener("resize", handleResize)};
  }, []);
  
  return (
    <section
      className={` ${
        mode === "lightMode"
          ? " text-dark bg-white"
          : " text-white  from-blue-bg"
      }   `}
      style={{
        backgroundColor: mode === "lightMode" ? "white" : "#121212",
        padding: windowWidth > 992 && "0 100px",
      }}
    >
      <div className="row m-0">
        <div className="col-12">{AutomaticSliderSection()}</div>
      </div>
      <div className={`scrollbar ${mode === "lightMode" ? "bg-white" : "bg-dark"}}]`} style={{ overflowX: "auto" }}>
        <ResultData
          data={data}
          currentIndex={tabIndex}
          websocketMessage={webSocketMessage}
          activeInterval={activeInterval}
          intervalList={intervalList}
          updateInterval={updateInterval}
          updatewebsocketmessage={updatewebsocketmessage}
          updatetabIndex={updatetabIndex}
          chainlist={chainlist}
          activeChain={activeChain}
          windowWidth={windowWidth}
          updateblockChain={updateblockChain}
          updateSelectCollection={updateSelectCollection}
        />
      </div>
      <CustomCarousel
        title="Recent rockets"
        rocketUrl={
          token === null || token === undefined || token === ""
            ? REACT_APP_FETCH_PARTICULAR_COLLECTION_BASEURL +
              "/recentrockets"
            : REACT_APP_FETCH_PARTICULAR_COLLECTION_BASEURL +
              "/recentrockets"
        }
        apiTitle="RECENT_ROCKET_API"
        mode={mode}
        blockchain={webSocketMessage}
      />
      {topCollectionSection()}
      {browseNFTcategorySection()}
      {showCookiePopup&&<CookiesConsent/>}
    </section>
  );
}
