import CustomSidebar from "../../component/customsidebar/CustomSidebar";
import MainLayout from "../../MainLayout";
import AnnouncementScreen from "../../Screens/Announcement/AnnouncementScreen";
import DetailsPage from "../../Screens/Announcement/DetailsPage";
import AssetScreen from "../../Screens/AssetScreen/AssetScreen";
import Bidcollection from "../../Screens/BiddingCollection/Bidcollection";
import BidcollectionItem from "../../Screens/BiddingCollection/BidcollectionItem";
import CategoryCollection from "../../Screens/CategoryCollection/CategoryCollection";
import Groupchat from "../../Screens/Chatscreen/GroupChat/Groupchat";
import HomeChat from "../../Screens/Chatscreen/HomeChat";
import Collection from "../../Screens/Collection/Collection";
import Index from "../../Screens/DashBoard1/Index";
import Cryptopedia from "../../Screens/Discover/cryptopedia/Cryptopedia";
import DiscoverScreen from "../../Screens/Discover/DiscoverScreen";
import DownloadScreen from "../../Screens/Download/DownloadScreen";
import Charts from "../../Screens/Dynamic-Charts/Charts";
import EventListScreen from "../../Screens/EventList/EventListScreen";
import ListItemDetailsScreen from "../../Screens/EventList/ListItemDetailsScreen";
import DataScreen from "../../Screens/HomeScreen/DataScreen";
import HomeScreen from "../../Screens/HomeScreen/HomeScreen";
import OrdinalScreen from "../../Screens/HomeScreen/ordinalScreen/OrdinalScreen";
import ViewAllCollection from "../../Screens/HomeScreen/ViewAllCollection";
import DummyScreen from "../../Screens/LeaderBoard/DummyScreen";
import LeaderBoardScreen from "../../Screens/LeaderBoard/LeaderBoardScreen";
import SearchCollectionScreen from "../../Screens/MoreSearchCollection/SearchCollectionScreen";
import Portfolio from "../../Screens/Portfolio/Portfolio";
import Portfolio2 from "../../Screens/Portfolio/Portfolio2";
import PrivacypolicyScreen from "../../Screens/privacy and policy and terms-condition/PrivacypolicyScreen";
import Terms_and_Condition from "../../Screens/privacy and policy and terms-condition/Terms_and_Condition";
import Signup from "../../Screens/Signup/Signup";
import SupportScreen from "../../Screens/supportScreen/SupportScreen";
import DefiScreen from "../../Screens/Web3/DefiScreen";
import DetailsItemScreen from "../../Screens/Web3/DetailsItemScreen";
import Xplayer from "../../Screens/xplayer/Xplayer";
const handleDrawerClose=()=>{console.log('close')}
export const routes = [{
  path:'/', 
  element: <MainLayout />,
  children:[
  { path: "/",  element: <HomeScreen /> },
  { path: "/leaderBoard", element: <LeaderBoardScreen /> },
  { path: "/leaderBoard2", element: <DummyScreen /> },
  { path: "/search/:search", element: <SearchCollectionScreen /> },
  { path: "/ordinals/nft", element: <OrdinalScreen /> },
  { path: "/ViewAllCollection", element: <ViewAllCollection /> },
  { path: "/signup", element: <Signup /> },
  { path: "/data", element: <DataScreen /> },
  { path: "/collection/:id/overView", element: <Collection /> },
  // { path: "/assets/:id/:token_id?/overView", element: <AssetScreen /> },
  { path: "/assets/:contract_id/overView", element: <AssetScreen /> },
  { path: "/profile/dashboard", element: <Index /> },
  { path: "/charts", element: <Charts /> },
  { path: "/portfolio", element: <Portfolio/> },
  { path: "/portfolio2", element: <Portfolio2 open={true} onClose={handleDrawerClose} /> },
  { path: "/logout", element: <CustomSidebar /> },
  { path: "/chat", element: <HomeChat /> },
  { path: "/bidCollection", element: <Bidcollection /> },
  { path: "/bidCollection/item", element: <BidcollectionItem /> },
  { path: "/groupchat", element: <Groupchat /> },
  { path: "/privacy-policy-statement", element: <PrivacypolicyScreen /> },
  { path: "/terms_and_condition", element: <Terms_and_Condition /> },
  { path: "/support", element: <SupportScreen /> },
  { path: "/xplayer", element: <Xplayer /> },
  { path: "/category/:name", element: <CategoryCollection /> },
  { path: "/download", element: <DownloadScreen /> },
  { path: "/eventlist", element: <EventListScreen /> },
  { path: "/eventlist/:category", element: <ListItemDetailsScreen />},
  { path: "/announcements", element: <AnnouncementScreen /> },
  { path: "/announcements/category/:category", element: <AnnouncementScreen /> },
  { path: "/announcements/:category/:categoryChild", element: <DetailsPage /> },
  { path: "/discover", element: <DiscoverScreen /> },
  { path: "/discover/cryptopedia", element: <Cryptopedia /> },
  { path: "/defi", element: <DefiScreen /> },
  { path: "/defi/details/:id", element: <DetailsItemScreen /> }
    ]
}
];