import React from "react";
import { useState } from "react";
import ScrollableFeed from "react-scrollable-feed";
import {
  AiOutlineSend,
} from "react-icons/ai";
import { toSendmessage } from "./chatConnection";
export default function RenderChat(props) {
  const {messages,currentUser,connection,mode,updateSendmessage}=props
const [messageInput,setMessageInput]=useState('');



const handleSubmit=(e)=>{
  e.preventDefault();
  const changemessage = () => {
    const msg={ body: messageInput, to: currentUser  }
    updateSendmessage(msg);
    setMessageInput("");
  };
toSendmessage(connection,currentUser,messageInput,changemessage)
}

  return (
    <>
    <div
      id="chat-container"
      className="mt-3 overflow-auto"
      style={{ maxHeight: "60vh" }}
    >
      {messages.length === 0 && <h1 className="text-center">Start the chat</h1>}
      <ScrollableFeed className="chat-container">
        {messages.map((elem, index) => {
          return (
            <div
              className={`text my-5 mx-2  ${
                elem.to === `${currentUser}`
                  ? "text-right"
                  : "text-left"
              }  `}
              key={index}
            >
              <p
                className={`bg-dark p-3`}
                style={{
                  borderRadius: "12px",
                  backgroundColor:
                    mode === "lightMode" ? "#80858b73" : "#797d8161",
                  color: "white",
                  maxWidth:'70%',
                  display:'inline'
                }}
              >
                <span>
                {elem?.body.toString()}

                </span>
              </p>
            </div>
          );
        })}
      </ScrollableFeed>
    </div>
    {Object.keys(currentUser).length > 0 && (
            <div
              className="footer position-absolute  "
              style={{ bottom: 0, width: "70%" }}
            >
              <form
                className="form-inline my-2 my-lg-0 p-1 mx-auto "
                onSubmit={handleSubmit}
              >
                <input
                  className="form-control mr-sm-2"
                  type="text"
                  style={{ width: "92%" }}
                  placeholder="Enter your message"
                  onChange={(e) => {
                    setMessageInput(e.target.value);
                  }}
                  value={messageInput}
                />
                <button
                  className={`btn ${
                    messageInput === ""
                      ? "btn-outline-secondary"
                      : "btn-outline-success"
                  } my-2 my-sm-0 `}
                  type="submit"
                  disabled={messageInput === ""}
                >
                  <AiOutlineSend size={20} />
                </button>
              </form>
            </div>
          )}
    </>
  );
}
