import React, { useState, useEffect, cloneElement } from "react";
import { useSelector } from "react-redux";
import RightDetailsScreen from "./RightDetailsScreen";
import { IoChevronDown, IoChevronUp } from "react-icons/io5";
import  { AxiosForDiscover, REACT_APP_DAPP_BASEURL } from "../../config/API/Api";
import Styles from "./Discover.module.css";
import DetailScreen from "./LeftSideOptionDetailsPage/DetailScreen";
import Favourites from "./Favourites/Favourites";
import { tracer } from "../../tracing";
const DiscoverScreen = () => {
  const mode = useSelector((state) => state.themeType.themeType);
  const [windowWidth, setwindowWidth] = useState(window.innerWidth);
  const [loading,setloading]=useState(true);
  const [isDetailLoading,setisDetailLoading]=useState(true);
  const [cardData, setcardData] = useState([]);
  const [page,setpage]=useState(0)
  const [hasMore,sethasMore]=useState(true);
  const [sectionCardList,setsectionCardList]=useState([]);
  const initialLeftList = [
    { uuid: 1,tab_key:"featured",section_title:"Featured",description:"Explore DApps across 80+ blockchains, spanning DeFi, DEX, GameFi, NFTs, and more. With our regularly updated listings, discover a Web3 adventure that evolves uniquely for you."},
    { uuid: 2,tab_key:"favorites",section_title:"Favorites",description:""},
  ];
  const [leftList,setleftList]=useState([])
  const [selectedSideOption,setselectedSideOption]=useState(initialLeftList[0]);
  const updateSelectedOption=(val)=>{setselectedSideOption(val)};
  const updatePage=()=>{setpage(page+1);}
  const initialList = [
    {
      id: 1,
      label: "What can I do with Discover in the JRN Wallet?",
      desc: "The Discover serves as a centralized hub for various decentralized applications. It offers users a one-stop destination for accessing a plethora of DApps, covering popular DeFi projects, blockchain gaming, decentralized exchanges (DEX), NFT platforms, informative resources, and relevant tools.",
      isOpen: false,
    },
    {
      id: 2,
      label: "What are popular DApps?",
      desc: "Popular DApps include the decentralized exchange Uniswap, the liquid staking platform Lido, and the NFT marketplace OpenSea.",
      isOpen: false,
    },
  ];
  const [devList, setdevList] = useState(initialList);

  const renderLastSection = () => {
    return (
      <div className="mt-4">
        <h1 className="font-weight-bold text-center font-32 mb-5">
          Discover with JRN
        </h1>
        <ul className="list-group list-group-flush d-flex flex-column justify-content-center align-items-center">
          {devList.map((elem, index) => (
            <div
              key={index}
              className={`list-group-item list-group-item-action py-4 my-2  ${Styles.faq_list_item} ${
                mode === "lightMode"
                  ? "bg-white text-black"
                  : "bg-black text-white"
              }`}
              onClick={() => {
                setdevList((previous) =>
                  previous.map((i) => {
                    if (i.id === elem.id) {
                      i.isOpen = !i.isOpen;
                    }
                    return i;
                  })
                );
              }}
            >
              <div className="d-flex justify-content-between">
               <span className="font-18">{elem.label}</span>
                {elem.isOpen ? (
                  <IoChevronUp size={20} color="black" />
                ) : (
                  <IoChevronDown size={20} color="black" />
                )}
              </div>
              {elem.isOpen && (
                <p className="font-14 w-75 my-4 text-secondary">{elem.desc}</p>
              )}

              {elem.isOpen && (
                <p
                  className={`font-14 font-weight-bold ${
                    mode === "lightMode" ? "text-black" : "text-white"
                  }`}
                >
                  Learn More {">"}
                </p>
              )}
            </div>
          ))}
        </ul>
      </div>
    );
  };

  //API
  const rendersectionListApi=async()=>{
    const span = tracer.startSpan("Dapps Section List Api");
    const url='/dapp/section/list';
    try {
        setloading(true);
        span.setAttribute("http.method", "GET");
        span.setAttribute("http.url", REACT_APP_DAPP_BASEURL + url);
        await AxiosForDiscover.get(url).then(res=>{
          span.setAttribute("http.status_code", res.status);
            if(res.status===200){
              span.addEvent("api_response_received", {
                data_length: res.data.length,
              });
              if(res.data.length>0){
                const filterdata=res.data?.reduce((acc,item)=>{
                  const filter=acc?.filter((o)=>o.tab_key===item?.tab_key).length;
                  if(filter===0){
                    acc.push(item);
                  }
                  return acc
                },[])
                setleftList([...initialLeftList,...filterdata]);
                // span.setAttribute("Method", "GET");
                span.setAttribute("api.success", true);
                span.addEvent("api_data_processed", {
                  processed_data_length: res.data.length,
                });
                span.addEvent("api_response_data", {
                  data: JSON.stringify(res.data),
                });
              }
            }else{
              span.setAttribute("api.success", false);
            }
        }).catch((err)=>{
          span.recordException(err);
          span.setAttribute("api.success", false);
          span.addEvent("api_error", {
            error_message: err.message,
            error_stack: err.stack,
          });
        }).finally(()=>{setloading(false);span.end();})
    } catch (error) {
      span.recordException(error);
      span.setAttribute("api.success", false);
      span.addEvent("api_exception", {
        exception_message: error.message,
        exception_stack: error.stack,
      });
      span.end();
        console.log('error', error)
    }
  }
  
  //Life cycle method
  useEffect(() => {
    rendersectionListApi();
    window.scrollTo(0,0);
    const handleResize = () => {
      setwindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const renderSectionCardListApi=async()=>{
    const span = tracer.startSpan("Dapps left side List Api");
    if(selectedSideOption?.tab_key===undefined||selectedSideOption?.tab_key===null){
        return
    }
    const params={section:selectedSideOption?.tab_key,limit:10,pageNumber:page}
    try {
      span.setAttribute("request.payload", JSON.stringify(params));
      span.setAttribute("http.method", "GET");
      span.setAttribute("http.url", REACT_APP_DAPP_BASEURL + '/dapp/section');
        await AxiosForDiscover.get('/dapp/section',{params}).then(res=>{
          span.setAttribute("http.status_code", res.status);
            if(res.status===200){
              span.addEvent("api_response_received", {
                data_length: res.data.length,
              });
              setsectionCardList([...sectionCardList,...res.data]);
              if(res.data.length===0){
                console.log(res.data.length,res.data,'yh chala ki nahi')
                sethasMore(false);
              }
              span.setAttribute("api.success", true);
              span.addEvent("api_data_processed", {
                processed_data_length: res.data.length,
              });
              span.addEvent("api_response_data", {
                data: JSON.stringify(res.data),
              });
            }else{
              span.setAttribute("api.success", false);
            }
        }).catch((err)=>{console.log('err', err);
          span.recordException(err);
          span.setAttribute("api.success", false);
          span.addEvent("api_error", {
            error_message: err.message,
            error_stack: err.stack,
          });
        }).finally(()=>span.end())
    } catch (error) {
        console.log('error', error)
        span.recordException(error);
      span.setAttribute("api.success", false);
      span.addEvent("api_exception", {
        exception_message: error.message,
        exception_stack: error.stack,
      });
      span.end();
    }
  }

  useEffect(()=>{
    if(selectedSideOption.uuid!==1&&selectedSideOption.uuid!==2){
      renderSectionCardListApi();
    }
  },[selectedSideOption,page])
  return (
    <section
      className={`${windowWidth > 1024 && ""} ${
        mode === "lightMode" ? "bg-white text-black" : "bg-black text-white"
      }`}
      style={{ padding: windowWidth > 1200 && " 0px 130px" }}
    >
      <div className="row">
        <div className="col-xl-2 ">
          <div className={`${Styles.tablistContainer}`} style={{ height: windowWidth < 1200 && "15vh" }}>
            <ul
              className={`list-group list-group-flush border-0 scrollbar  pt-1 pointer ${
                windowWidth <= 1200 ? "d-flex flex-row" : ""
              } ${
                mode === "lightMode"
                  ? "bg-white text-black"
                  : "bg-black text-white"
              }`}
              style={{ whiteSpace: "nowrap" ,overflowX:'scroll'}}
            >
              {leftList?.map((elem,index) => (
                <li
                  className={`list-group-item border-0 ${elem?.uuid===selectedSideOption?.uuid?'font-weight-bold':''}  list-group-item-action py-3 ${
                    mode === "lightMode" ? "text-black" : "bg-black text-white"
                  }`}
                  key={index}
                  onClick={()=>{setsectionCardList([]);setpage(0);sethasMore(true);setselectedSideOption(elem)}}
                >
                  {elem.section_title}
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className="col-xl-10 col-12">
          {selectedSideOption?.uuid===1 ?<RightDetailsScreen mode={mode} cardData={cardData} header={selectedSideOption}  loading={loading}/>:selectedSideOption?.uuid===2?<Favourites sectionList={leftList} updateList={updateSelectedOption} />:
          <DetailScreen header={selectedSideOption} list={sectionCardList} updatepage={updatePage} hasMore={hasMore}/>
          }
        </div>
      </div>

      <div className="row">
        <div className="col">{renderLastSection()}</div>
      </div>
    </section>
  );
};

export default DiscoverScreen;
