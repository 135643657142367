import React, { useState, Suspense, lazy, useMemo, useEffect, useCallback } from "react";
import "./AssetScreen.css";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { useSelector } from "react-redux";
import Loader, { Loader2 } from "../../commonComponent/Loader";
import { RiBarChartFill } from "react-icons/ri";
import { useParams } from "react-router-dom";
import { trace } from "firebase/performance";
import { sendPerfomance } from "../..";
import CustomTabBar from "../../commonComponent/CustomTabBar";
import AssetHistoryScreen from "./AssetHistoryScreen";
import {
  ComposedChart,
  Line,
  Area,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Brush,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import moment from "moment";
import { tracer } from "../../tracing";
import { IoCartOutline } from "react-icons/io5";
import { CiStar } from "react-icons/ci";
import bluetick from "../../assets/blue_Tick.png";
import { FiRefreshCw } from "react-icons/fi";
import Axios, { REACT_APP_FETCH_PARTICULAR_COLLECTION_BASEURL } from "../../config/API/Api";
import CustomSelect from "../../commonComponent/CustomSelect";
import { useLocation, useNavigate } from "react-router-dom";
import CustomCarousel2 from "../../commonComponent/CustomCarousel2";
export default function AssetScreen() {
  const mode = useSelector((state) => state.themeType.themeType);
  const token = localStorage.getItem("accessToken");
  const [assetData, setassetData] = useState({});
  const [isloading, setisloading] = useState(true);
  const [showTextmore, settextmore] = useState(false);
  const [historyassetData, sethistoryassetData] = useState([]);
  const [isLoadingHistoryurl, setisLoadingHistoryurl] = useState(false);
  const [showTab, setshowTab] = useState(3);
  const [historyTabIndex, sethistorytabIndex] = useState(0);
  //SIMILAR ASSET STATE
  const [moreAssetData, setmoreAssetData] = useState([]);
  const [currentPagesimilar, setcurrentPagesimilar] = useState(1);
  const [issimilarLoading, setissimilarLoading] = useState(false);
  //traits state
  const [traitList, settraitlist] = useState([]);
  const [istraitsLoading, setistraitsLoading] = useState(false);

  //DIFFERENT ASSET STATE
  const [differentAssetData, setdifferentAssetData] = useState([]);
  const [currentPagedifferent, setcurrentPagedifferent] = useState(1);
  const [isdifferentLoading, setisdifferentLoading] = useState(true);
  const [windowWidth,setwindowWidth]=useState(window.innerWidth)
  const [windowHeight,setwindowheight]=useState(window.scrollY);

  const [currentgraphFilter, setcurrentgraphFilter] = useState("*");
  const [graphsData, setgraphsData] = useState([]);
  const [iswaiting, setiswaiting] = useState("Please Wait....");
  const [isgraphLoading,setisgraphLoading]=useState(true);
  const navigate=useNavigate();
  const {state}=useLocation();
  const currentBlockchain=state===undefined||state===null?"":state.blockchain;
  const { contract_id } = useParams();
  const [id,token_id]=contract_id.split(':');
  const collectionData=state?.userData;

  console.log('id', id)
  console.log('token_id', token_id)
  // dynmic container
  const topsectioninnerContainer = () => {
    return (
      <div style={{ maxHeight: "607px", minHeight: "30px" }}>
        <p className="text-center">No record found</p>
      </div>
    );
  };

  
  const assetTraitsApi = async () => {
    const url = `/traits/list`;
    const data = {
      traits_to_fetch: "30",
      blockchain: currentBlockchain,
    };
    const t = trace(sendPerfomance, "AssetTraitsApi");
    const span = tracer.startSpan("AssetTraitsApi");
    t.start();
    try {
      setistraitsLoading(true);
      await Axios.post(url, data)
        .then((res) => {
          if (res.status === 200) {
            settraitlist(res.data);
          }
          span.setAttributes({ method: "POST" });
          span.addEvent("api_response", {
            status: res.status,
            data: res.data,
            url,
          });
        })
        .catch((err) => {
          console.log(err);
          span.addEvent("ERROR_CODE", {
            status: err,
            message: err?.message,
          });
        })
        .finally(() => {
          setistraitsLoading(false);
          t.stop();
        });
    } catch (error) {
      console.log(error);
    }
  };

  const getTabs = (data) => {
    if (data.length === 0) {
      return [];
    }
    let finalarr = [];
    for (let elem in data) {
      let a = {
        title: Object.keys(data[elem].data)[0],
      };
      finalarr.push(a);
    }
    return finalarr;
  };

  const renderAssetHistoryScreen = () => {
    return isLoadingHistoryurl ? (
      <div className="text-center">
        <Loader2 size="48" color={mode==="lightMode"?'black':'white'} />
      </div>
    ) : (
      <div>
        {historyassetData.length === 0 ? (
          <h1 className="text-center font-14 mt-4">No History found</h1>
        ) : (
          <div className="mt-3">
            <div>
              <CustomTabBar
                selectedIndex={historyTabIndex}
                onSelect={(index) => {
                  sethistorytabIndex(index);
                }}
                // tabBarstyle={styles.tabBar}
                rowheading={getTabs(historyassetData)}
                panelData={[
                  {
                    component: (
                      <AssetHistoryScreen
                        data={historyassetData}
                        tabIndex={historyTabIndex}
                      />
                    ),
                  },
                  {
                    component: (
                      <AssetHistoryScreen
                        data={historyassetData}
                        tabIndex={historyTabIndex}
                      />
                    ),
                  },
                  {
                    component: (
                      <AssetHistoryScreen
                        data={historyassetData}
                        tabIndex={historyTabIndex}
                      />
                    ),
                  },
                  {
                    component: (
                      <AssetHistoryScreen
                        data={historyassetData}
                        tabIndex={historyTabIndex}
                      />
                    ),
                  },
                ]}
              />
            </div>
          </div>
        )}
      </div>
    );
  };
  useEffect(() => {
    const handleResize = () => {
      setwindowWidth(window.innerWidth);
    };
    const handleScrolling=()=>{
      setwindowheight(window.scrollY)
    }

    window.addEventListener("resize", handleResize);
    window.addEventListener('scroll', handleScrolling);

    // Cleanup function to remove the event listener when component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
      window.removeEventListener("scroll", handleScrolling);
    };
  }, []);
  const TopSection = () => {
    const optionfilter = [
      { id: 6, label: "All Times", value: "*" },
      { id: 1, label: "Last 1 hours", value: "1" },
      { id: 2, label: "Last 7 Days", value: "7" },
      { id: 3, label: "Last 30 Days", value: "30" },
      { id: 4, label: "Last 60 Days", value: "60" },
      { id: 5, label: "Last 90 Days", value: "90" },
    ];
    const renderAssetImage=()=>{
      return (
        <div className="col-lg-4  col-sm-12 mt-5 p-0" >
        <div className={`product-container`} style={{top:windowHeight>15?"10em":'5em'}}>
          <img
            src={assetData?.image_url}
            alt="Product"
            loading="lazy"
            className={`img product-image ${windowHeight>15?"scaling-image":""}  img-fluid img-responsive`}
          />
        </div>
      </div>
      )
    }
    const updateinterval=(val)=>{
      setgraphsData([]);
      setcurrentgraphFilter(val);
    }
    const formatDate = (dateString) => {
      return moment(dateString).format("DD/MM").toString();
    };
    return (
      <div
        className={`row container-fluid  ${
          mode === "lightMode" ? "bg-light text-dark" : "bg-dark text-white"
        }`}
      >
        {windowWidth<=992&&renderAssetImage()}
        <div className="col-lg-7  col-sm-12 leftTopSection mt-5  ">
          <div className="row">
            <div className="col-12 m-0">
              <div className="d-flex justify-content-between">
                <p
                  className="font-16"
                  style={{ fontFamily: "inherit" ,color:'#205FEC',textOverflow:'ellipsis'}}
                >
                  {assetData?.name}
                  <img
                    src={bluetick}
                    width={"16px"}
                    className="ml-2"
                    height={"16px"}
                  />
                </p>
                <span className="">
                  <CiStar
                    size={20}
                    color={"#929292"}
                    style={{ position: "relative", top: "5px", opacity: 0.8 }}
                  />
                  {assetData?.like}
                  <span className="border border-dark ml-3 pointer p-1">
                    <FiRefreshCw size={12} />
                  </span>
                </span>
              </div>
              <h1
                style={{
                  fontWeight: "700",
                  fontSize:"36px",
                  color: mode === "lightMode" ? "#1d2023" : "white",
                }}
              >
                {assetData?.name}
              </h1>
              <span
                className="badge rounded p-2 px-3"
                style={{
                  backgroundColor: mode === "lightMode" ? "#e4e1e1" : "black",
                }}
              >
                <RiBarChartFill size={10} />
                7,200
              </span>
              <span
                className="badge rounded p-2 px-3 mx-1"
                style={{
                  backgroundColor: mode === "lightMode" ? "#e4e1e1" : "black",
                }}
              >
                <RiBarChartFill size={10} />
                8,284
              </span>
            </div>
            <div className="col-12 m-0 my-5">
              <p className="mb-0" style={{ fontFamily: "inherit" }}>
                Best Offer
              </p>
              <div>
                <span className="badge p-0 ">
                  <img
                    style={{ marginBottom: "14px" }}
                    src="https://www.JRN.com/cdn/nft/5185152b-e812-4a8d-b706-5d02b39fadf3.png?x-oss-process=image/format,webp"
                    alt="..."
                    width={"24px"}
                    height={"24px"}
                  />
                </span>
                <span
                  style={{
                    color: mode === "lightMode" ? "#1d2023" : "white",
                    fontSize: "28px",
                    fontWeight: "700",
                  }}
                >
                  {assetData?.price==='null'?'':assetData?.price} WETH
                </span>
                <span
                  className="nft-price-info-usd-price"
                  style={{
                    color: "#929292",
                    fontSize: "14px",
                    lineHeight: "16px",
                    marginLeft: "8px",
                  }}
                >
                  ($70,357.51)
                </span>
              </div>
              <div className={`col-12 d-flex ${windowWidth<=768&&"justify-content-between"} align-items-center`}>
                <button
                  className="MakeOfferButton py-2 "
                  style={{ width: "164px", height: "48px" }}
                >
                  {" "}
                  Buy
                </button>
                <button
                  className="MakeOfferButton bg-white  text-dark   mx-2 py-2 "
                  style={{
                    border: "1px solid #dbdbdb",
                    width: "164px",
                    height: "48px",
                    borderRadius: "48px",
                  }}
                >
                  {" "}
                  Make Offer
                </button>
                <button
                  className="btn rounded-circle  bg-white  "
                  style={{ border: "1px solid #dbdbdb" }}
                >
                  <IoCartOutline size={25} className="rounded-circle" />
                </button>
              </div>
            </div>
            <div className="col-12 mt-5 ">
              <h2 className="font-weight-bold font-24">Price history</h2>
              <div className="d-flex justify-content-between">
                <div
                  className="form-group  rounded"
                  style={{ maxWidth: "80px" }}
                >
                  <CustomSelect
                    options={optionfilter}
                    updatevalue={updateinterval}
                    minwidth="150px"
                    />
                </div>
                <span className="text-secondary font-12">
                  Average price:{" "}
                  <span
                    className={`fontweight-700 ${
                      mode === "lightMode" ? "text-dark" : "text-white"
                    }`}
                  >
                    29.5454
                  </span>
                </span>
              </div>
              <div style={{ width: "100%" ,position:'relative',right:"30px"}}>
                {
                  isgraphLoading?<div className="d-flex justify-content-center align-items-center">
                    <Loader2 size={20} color={mode==="lightMode"?"black":'white'}/>
                  </div>:graphsData.length===0?<p className={`text-center ${mode==="lightMode"?"text-dark":'text-white'}`}>No Record Found</p>:null
                }
                <ResponsiveContainer width="100%" height={200}>
                  <ComposedChart
                    data={graphsData}
                    >
                    <CartesianGrid
                      strokeDasharray="3 3"
                      horizontal={true}
                      vertical={false}
                    />
                     <XAxis dataKey='time' className="font-12" scale="title" tickFormatter={formatDate} tick={{ fill: mode === "lightMode" ? "gray" : "white", opacity: 0.7 }} tickLine={false} axisLine={false} dot={false}/>
                    <YAxis dataKey={"volume"} tick={{ fill: mode === "lightMode" ? "gray" : "white", opacity: 0.7 }} className="font-12" axisLine={false}  tickLine={false}/>
                    <Tooltip />
                    <Legend />
                    <Area type="monotone" dataKey="dislikes" stroke="blue" fill="url(#colorUv)" strokeWidth={2} />
                    <Area type="monotone" dataKey="likes" id="stopage" stroke="#8884d8" fill="url(#colorUv)" strokeWidth={2} />
                    <defs>
                      <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                       <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8} />
                       <stop offset="95%" stopColor="#8884d8" stopOpacity={0} />
                     </linearGradient>
                    </defs>
                  </ComposedChart>
                </ResponsiveContainer>
              </div>
            </div>
            <div className="col-12 mt-2">
              <div
                className="pb-2"
                style={{ borderBottom: "1px solid #dbdbdb" }}
              >
                {[
                  { name: "Listing", value: 1 },
                  { name: "Offers", value: 2 },
                  { name: "History", value: 3 },
                ].map((elem) => (
                  <span
                    key={elem.value}
                    onClick={() => {
                      setshowTab(elem.value);
                    }}
                    className="mx-2 font-16 py-2 pointer"
                    style={{
                      borderBottom:
                        showTab === elem.value ? "2px solid black" : "",
                      color: showTab === elem.value ? "black" : "#929292",
                    }}
                  >
                    {elem.name}
                  </span>
                ))}
              </div>
              {showTab === 3 && renderAssetHistoryScreen()}
            </div>
            <div className="col-12 mt-2 ">
              <h2 className="font-24 font-weight-bold">Details</h2>
              <div className="d-flex justify-content-between">
                <div className="">
                  <p
                    className={`mb-1 muteColor font-14 ${
                      mode === "lightMode" ? "text-secondary" : "text-white"
                    }`}
                  >
                    Chain
                  </p>
                  <p
                    className={`mb-1 font-14 ${
                      mode === "lightMode" ? "text-secondary" : "text-white"
                    }`}
                    style={{ color: "#35475A" }}
                  >
                    {currentBlockchain}
                  </p>
                </div>
                <div className=" ">
                  <p
                    className={`mb-1 muteColor font-14 ${
                      mode === "lightMode" ? "text-secondary" : "text-white"
                    }`}
                  >
                    Contract address
                  </p>
                  <p
                    className={`mb-1 font-14 text-truncate ${
                      mode === "lightMode" ? "text-secondary" : "text-white"
                    }`}
                    style={{ color: "#35475A" }}
                  >
                    {windowWidth<=768?assetData?.contract_id!==undefined&&assetData?.contract_id.slice(0,15)+"...":assetData?.contract_id}
                  </p>
                </div>
              </div>
              <div className="d-flex justify-content-between">
                <div className="">
                  <p
                    className={`mb-1 muteColor font-14 ${
                      mode === "lightMode" ? "text-secondary" : "text-white"
                    }`}
                  >
                    Token ID
                  </p>
                  <p
                    className={`mb-1 font-14 ${
                      mode === "lightMode" ? "text-secondary" : "text-white"
                    }`}
                    style={{ color: "#35475A" }}
                  >
                    {assetData?.token_id}
                  </p>
                </div>
                <div className=" ">
                  <p
                    className={`mb-1 muteColor font-14 ${
                      mode === "lightMode" ? "text-secondary" : "text-white"
                    }`}
                  >
                    Token standard
                  </p>
                  <p
                    className={`mb-1 font-14 ${
                      mode === "lightMode" ? "text-secondary" : "text-white"
                    }`}
                    style={{ color: "#35475A" }}
                  >
                    {assetData?.token_id}
                  </p>
                </div>
              </div>
            </div>

            {renderProperties()}
            {renderAbout()}
          </div>
        </div>
       {windowWidth>992 && renderAssetImage()}
      </div>
    );
  };

  // For requesting API

  const requestingAssetApi = async (url, header) => {
    try {
      const t = trace(sendPerfomance, "Assets_Url");
      const span = tracer.startSpan("Asset_api");
      setisloading(true);
      t.start();
      await axios
        .get(url, header)
        .then((res) => {
          span.setAttribute("method", "GET");
          span.addEvent("api_response", {
            status: res.status,
            data: res.data,
            url,
          });
          if (res.status === 200) {
            setassetData(res.data.length>0?res.data[0]:{});
            setisloading(false);
          }
        })
        .catch((err) => {
          navigate("/");
          toast.error(err.message);
          setisloading(false);
          span.setStatus({ code: "ERROR", message: err?.message });
          span.end();
        })
        .finally(() => {
          t.stop();
          span.end();
        });
    } catch (error) {
      setisloading(false);
      toast.error(error.message);
      navigate("/");
    }
  };

  const similarAssetapi = async (url, header) => {
    const params = {
      asset_id: `${id}${currentBlockchain!=='bitcoin'?`:${token_id}`:''}`,
      page: `${currentPagesimilar < 0 ? 0 : currentPagesimilar}`,
      size: "4",
      blockchain: currentBlockchain,
    };
    try {
      const t = trace(sendPerfomance, "Similary_more_assets_Url");
      const span = tracer.startSpan("Similary_more_assets_Api");
      t.start();
      setissimilarLoading(true);
      await axios
        .post(url, params, header)
        .then((res) => {
          span.setAttribute("method", "POST");
          span.addEvent("api_response", {
            status: res.status,
            data: res.data,
            url,
          });
          if (res.status === 200) {
            console.log(
              "Similary_more_assets_Url data is========>>> ",
              res.data
            );
            setmoreAssetData(res.data);
          }
          setissimilarLoading(false);
        })
        .catch((err) => {
          console.log(err, "this is err");
          span.setStatus({ code: "ERROR", message: err?.message });
          span.end();
          setissimilarLoading(false);
        })
        .finally(() => {
          t.stop();
          span.end();
          setissimilarLoading(false);
        });
    } catch (error) {
      console.log("error is", error);
    }
  };
  const differentAssetapi = async (url, header) => {
    const params = {
      asset_id: `${id}${currentBlockchain!=='bitcoin'?`:${token_id}`:''}`,
      page: `${currentPagedifferent < 0 ? 0 : currentPagedifferent}`,
      size: "4",
      blockchain: currentBlockchain
    };
    try {
      const t = trace(sendPerfomance, "Different_more_assets_Url");
      const span = tracer.startSpan("Different_more_assets_api");
      t.start();
      setisdifferentLoading(true);
      await axios
        .post(url, params, header)
        .then((res) => {
          span.setAttribute("method", "POST");
          span.addEvent("api_response", {
            status: res.status,
            data: res.data,
            url,
          });
          if (res.status === 200) {
            console.log(
              "different_more_assets_Url data is========>>> ",
              res.data
            );
            setdifferentAssetData(res.data);
          }
          setisdifferentLoading(false);
        })
        .catch((err) => {
          console.log(err, "this is err");
          span.setStatus({ code: "ERROR", message: err?.message });
          span.end();
          setisdifferentLoading(false);
        })
        .finally(() => {
          t.stop();
          span.end();
          setisdifferentLoading(false);
        });
    } catch (error) {
      console.log("error is", error);
    }
  };

  const graphdataapi = async (url, header) => {
    const params = {
      asset_id:collectionData?.asset_id,
      timeframe: currentgraphFilter,
      blockchain: currentBlockchain,
    };
    try {
      const t = trace(sendPerfomance, "Asset_GraphData_Url");
      const span = tracer.startSpan("Asset_GraphData_Url");
      t.start();
      setisgraphLoading(true);
      await axios
        .post(url, params, header)
        .then((res) => {
          span.setAttribute("method", "POST");
          span.addEvent("api_response", {
            status: res.status,
            data: res.data,
            url,
          });
          if (res.status === 200) {
            if (res.data.isgraphData === 1) {
              setgraphsData(res.data.data);
            } else {
              setgraphsData([]);
              setiswaiting("No Record Found");
            }
          }
        })
        .catch((err) => {
          console.log(err);
          span.setStatus({ code: "ERROR", message: err?.message });
          span.end();
          setiswaiting("No Record Found");
        })
        .finally(() => {
          t.stop();
          span.end();
        setisgraphLoading(false);

        });
    } catch (error) {
      setiswaiting("No Record Found");
      console.log(error);
    }
  };

  useEffect(() => {
    if (token === undefined || token === null) {
      const moreasseturl = `${REACT_APP_FETCH_PARTICULAR_COLLECTION_BASEURL}/asset/similar`;
      const differentAssetUrl = `${REACT_APP_FETCH_PARTICULAR_COLLECTION_BASEURL}/asset/more`;
      similarAssetapi(moreasseturl, null);
      differentAssetapi(differentAssetUrl, null);
    } else {
      const moreasseturl = `${REACT_APP_FETCH_PARTICULAR_COLLECTION_BASEURL}/asset/similar`;
      const header = { headers: { Authorization: token } };
      const differentAssetUrl = `${REACT_APP_FETCH_PARTICULAR_COLLECTION_BASEURL}/asset/more`;
      differentAssetapi(differentAssetUrl, header);
      similarAssetapi(moreasseturl, header);
    }
  }, [currentPagesimilar, id,token_id]);


  useEffect(() => {
    if (token === undefined || token === null) {
      const url = `${REACT_APP_FETCH_PARTICULAR_COLLECTION_BASEURL}/asset/${id}${currentBlockchain!=='bitcoin'?':'+token_id:''}`;
      const header = { params: { blockchain: currentBlockchain} };
      requestingAssetApi(url, header);
    } else {
      const url = `${REACT_APP_FETCH_PARTICULAR_COLLECTION_BASEURL}/asset/${id}${currentBlockchain!=='bitcoin'?':'+token_id:''}`;
      const header = {
        headers: { Authorization: token },
        params: { blockchain: currentBlockchain },
      };
      requestingAssetApi(url, header);
    }
  }, [id,token_id]);

  const renderProperties = () => {
    return (
      <div className="col-12 my-5">
        <h2 className="font-24 font-weight-bold">Traits</h2>
        <div>
          <ul
            className="list-group overflow-auto scrollbar"
            style={{ maxHeight: "250px" }}
          >
            {assetData!==undefined?assetData.traits!==undefined?assetData.traits.map((elem, index) => (
              <li
                key={index}
                className={`list-group-item list-group-item-action rounded-10 my-1 d-flex justify-content-between ${mode==="lightMode"?"bg-light text-dark":"bg-dark text-white border-bottom border-white"}`}
              >
                <span className="text-muted1 font-weight-bold font-14 text-capitalise">
                  {elem?.key}
                </span>
                <span className="text-secondary font-weight-bold font-14">
                  {elem?.value}
                </span>
              </li>
            )):null:null}
          </ul>
        </div>
      </div>
    );
  };
  const renderAbout = () => {
    console.log(assetData,'this is assetdata')
    return (
      <div className="col-12 mt-2">
        <h2 className="font-weight-bold font-24 mb-1 mt-3">About</h2>
        <p
          className="font-18 font-weight-bold mb-0"
          style={{ color: "#1D2023" }}
        >
          {assetData?.name}
          <img className="ml-2" width={"24px"} height={"24px"} src={bluetick} />
        </p>
        <span className="font-14 muteColor">
          {assetData?.description!==undefined&&assetData?.description.length>500?assetData?.description?.slice(0,500)+"...":assetData?.description}
        </span>
      </div>
    );
  };

  useEffect(() => {
    if (token === undefined || token === null) {
      const graphdataurl = `${REACT_APP_FETCH_PARTICULAR_COLLECTION_BASEURL}/asset/series`;
      graphdataapi(graphdataurl, null);
    } else {
      const header = { headers: { Authorization: token } };
      const graphdataurl = `${REACT_APP_FETCH_PARTICULAR_COLLECTION_BASEURL}/asset/series`;
      graphdataapi(graphdataurl, header);
    }
  }, [currentgraphFilter]);
  useEffect(() => {
    assetTraitsApi();
  }, []);

  const handleNextSimilar = () => {
    setcurrentPagesimilar(currentPagesimilar + 1);
  };
  const handlepreviousSimilar = () => {
    if (currentPagesimilar === 1) {
      return;
    } else {
      setcurrentPagesimilar(currentPagesimilar - 1);
    }
  };
  const handleNextdifferent = () => {
    setcurrentPagedifferent(currentPagedifferent + 1);
  };
  const handlepreviousdifferent = () => {
    if (currentPagedifferent === 1) {
      return;
    } else {
      setcurrentPagedifferent(currentPagedifferent - 1);
    }
  };

  return (
    <div className={`${mode === "lightMode" ? "bg-light text-dark" : "bg-dark text-white"}`}>
      <ToastContainer
        position="top-center"
        autoClose={3000}
        closeOnClick
        theme={mode === "lightMode" ? "light" : "dark"}
      />
      {/* {isloading ? (
        <div className="text-center d-flex justify-content-center align-items-center" style={{height:'100vh'}}>
        <Loader2 size="48" color={mode==="lightMode"?'black':'white'} />
        </div>
      ) : ( */}
        <>
          <div className={`${windowWidth<=768?'':"container"}`}>
            {TopSection()}
              <CustomCarousel2
                title="More from this collection"
                data={moreAssetData}
                handleNext={handleNextSimilar}
                handlePrevious={handlepreviousSimilar}
                currentPage={currentPagesimilar}
                isLoading={issimilarLoading}
                windowWidth={windowWidth}
              />
              <CustomCarousel2
                title="Different Assets from this collection"
                data={differentAssetData}
                handleNext={handleNextdifferent}
                handlePrevious={handlepreviousdifferent}
                currentPage={currentPagedifferent}
                isLoading={isdifferentLoading}
                windowWidth={windowWidth}
              />
          </div>
        </>
      {/* )} */}
    </div>
  );
}
