import React, { useState, useEffect, useRef } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useSelector } from "react-redux";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import * as protobuf from "protobufjs";
import pako from "pako";
import protofile from "../../commonComponent/myProto.protobuf";
import { w3cwebsocket } from "websocket";
import "./ViewAllCollection.css";
import InfiniteScroll from "react-infinite-scroll-component";
import Userpng from "../../assets/user.png";
import firstRank from "../../assets/firstrank.png";
import secondRank from "../../assets/second.png";
import thirdRank from "../../assets/third.png";
import {
  FaChevronLeft,
  FaChevronRight,
  FaRegStar,
  FaCheck,
} from "react-icons/fa";
import Select from "react-select";
import { trace } from "firebase/performance";
import { sendPerfomance } from "../..";
import CustomSelect from "../../commonComponent/CustomSelect";
import { Loader2 } from "../../commonComponent/Loader";
import { tracer } from "../../tracing";
import Axios, {
  BaseUrl,
  REACT_APP_COLLECTION_WEBSOCKET_URL,
} from "../../config/API/Api";
import Slider from "react-slick";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import axios from "axios";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { checkValueexistornot } from "../../utils/utility/Utility";
import CustomShimmer from "../../commonComponent/CustomShimmer";
export default function ViewAllCollection() {
  const mode = useSelector((state) => state.themeType.themeType);
  const [windowWidth, setwindowWidth] = useState(window.innerWidth);
  const [type, settype] = useState(true);
  const [choice, setchoice] = useState(true);
  const navigate = useNavigate();
  const [data, setdata] = useState([]);
  const socketRef = useRef(null);
  const intervalList = [
    { id: 1, label: "1h", value: "1h" },
    { id: 2, label: "24h", value: "24h" },
    { id: 3, label: "7d", value: "7d" },
    { id: 4, label: "30d", value: "30d" },
  ];
  const [activeInterval, setactiveInterval] = useState("30d");

  const [loading, setisloading] = useState(true);
  const [chainlist, setchainlist] = useState([]);
  const [activeChain, setactivechain] = useState("all");
  const initialwsmessage = {
    action: "subscribe",
    channels: [
      {
        name: "collection_index_like",
        values: ["collection_index", "50", "1", activeInterval, activeChain],
      },
    ],
  };
  const [websocketMessage, setwebsocketmessage] = useState(initialwsmessage);
  //slider states
  const [isSliderLoading, setisSliderLoading] = useState(true);
  const [showArrow, setshowArrow] = useState(false);
  const [TrandingCollection, settrandingCollection] = useState([]);
  const arrowRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [hasmore, sethasmore] = useState(true);
  // const socket = new w3cwebsocket(REACT_APP_COLLECTION_WEBSOCKET_URL);
  //COMMON LOGICS
  const handlechoice = (event, newAlignment) => {
    setisloading(true);
    setdata([]);
    if (event.target.value === "topLiked") {
      const newMessage = { ...websocketMessage };
      if (newMessage.channels[0].values[0] === "collection_index") {
        newMessage.channels[0].name = "collection_index_like";
      } else {
        newMessage.channels[0].name = "asset_index_like";
      }
      newMessage.channels[0].values[2] = "1";
      setwebsocketmessage(newMessage);
      setchoice(true);
    } else {
      const newMessage = { ...websocketMessage };
      if (newMessage.channels[0].values[0] === "collection_index") {
        newMessage.channels[0].name = "collection_index_dislike";
      } else {
        newMessage.channels[0].name = "asset_index_dislike";
      }
      newMessage.channels[0].values[2] = "0";
      setwebsocketmessage(newMessage);
      setchoice(false);
    }
  };

  const updatesortingFilter = (val) => {
    if (val !== activeInterval) {
      setisloading(true);
      setdata([]);
      const newMessage = { ...websocketMessage };
      newMessage.channels[0].values[3] = val;
      setwebsocketmessage(newMessage);
    }
  };

  const convertBinaryToText = async (compressedData) => {
    try {
      const root = await protobuf.load(protofile);
      const VotingResponse = root.lookupType("voting.VotingResponseNew");
      let offset = 0;
      const binaryData = pako.inflate(new Uint8Array(compressedData), {
        to: "buffer",
      });
      let count = 0;
      while (offset < binaryData.length) {
        const messageLength = new DataView(
          new Uint8Array(binaryData.slice(offset, offset + 4)).buffer
        ).getUint32(0, true);
        offset += 4;
        count += 1;

        const protobufMessage = new Uint8Array(
          binaryData.slice(offset, offset + messageLength)
        );
        try {
          // const decodedMessage = VotingResponse.decode(protobufMessage).toJSON();
          const decodedMessage = VotingResponse.toObject(
            VotingResponse.decode(protobufMessage),
            { defaults: true }
          );
          if (decodedMessage?.data?.length < 50) {
            sethasmore(false);
          }
          setdata(decodedMessage?.data);
        } catch (error) {
          console.error("Error decoding binary data:", error);
        }
        offset += messageLength;
      }
    } catch (err) {
      console.error("Error loading schema file or decoding data:", err);
    }
  };
  const handleClick = (row) => {
    if (type) {
      navigate(`/collection/${row.contractId}/overview`, {
        state: {
          userData: row,
          blockchain: row?.blockchain,
          contract_id:row?.contractId
        },
      });
    } else {
      navigate(`/assets/${row?.contractId}/${checkValueexistornot(row?.tokenId)}/overview`, {
        state: {
          userData: row,
          blockchain: row?.blockchain,
          contract_id:row?.contractId
        },
      });
    }
  };
  //COMMON LAYOUTS

  const handlefetchMoreData = () => {
    setisloading(true);
    if (data.length % 50 === 0) {
      const newMessage = { ...websocketMessage };
      newMessage.channels.map((o) => (o.values[1] = String(data.length + 50)));
      setwebsocketmessage(newMessage);
    }
  };

  //SCREEN LAYOUTS
  const FirstSection = () => {
    return (
      <section>
        <h1 className="HeaderTitle font-weight-bold">
          NFT collection rankings
        </h1>
        <p
          className="HeaderDescription"
          style={{ color: mode === "darkMode" && "white" }}
        >
          JRN NFT rankings supply the latest and wide-ranging data of NFTs on
          blockchains. We help you gauge project performance and evaluate market
          trends. Check out the market highlights today!
        </p>
      </section>
    );
  };
  const topCollectionSliderSection = () => {
    var settings2 = {
      dots: false,
      infinite: false,
      speed: 500,
      slidesToShow: 4,
      slidesToScroll: 1,
      initialSlide: 1,
      arrows:false,
      centerMode: false,
      variableWidth: false,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            initialSlide: 2
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    }
    return (
      <section
        className={` mt-2 py-4  mx-2 rounded-12   ${
          mode === "lightMode" ? "bg-white text-black" : "bg-dark  text-white"
        }`}
        onMouseEnter={() => {
          if (windowWidth > 1024) {
            setshowArrow(true);
          }
        }}
        onMouseLeave={() => {
          if (windowWidth > 1024) {
            setshowArrow(false);
          }
        }}
        onTouchStart={() => {
          if (windowWidth > 1024) {
            setshowArrow(true);
          }
        }}
        onTouchEnd={() => {
          if (windowWidth > 1024) {
            setshowArrow(false);
          }
        }}
      >
        <div className="row m-0">
          {isSliderLoading ? (
            <div className="col-12 mx-auto d-flex justify-content-center">
              <Loader2
                size={30}
                color={mode === "lightMode" ? "black" : "white"}
              />
            </div>
          ) : (
            <div className="col-12 px-0">
              {showArrow && (
                <div
                  className="carousal_Icon pointer leftArrowCategory"
                  onClick={() => {
                    arrowRef.current.slickPrev();
                  }}
                  style={{
                    backgroundColor: mode === "lightMode" ? "" : "#121212",
                  }}
                >
                  <FaChevronLeft
                    size={15}
                    color={mode == "lightMode" ? "black" : "white"}
                  />
                </div>
              )}
              <div className={`slider-container`}>
                <Slider
                  {...settings2}
                  swipe={true}
                  ref={(c) => (arrowRef.current = c)}
                >
                  {(TrandingCollection === undefined
                    ? []
                    : TrandingCollection
                  ).map((elem, index) => {
                    return (
                      <div
                        key={index}
                        className="sliderCardConatiner"
                        onClick={() => {
                          navigate(`/collection/${elem?.contract_id}/overview`, {
                            state: {
                              userData: elem,
                              blockchain: elem.blockchain,
                              contract_id:elem?.contract_id
                            },
                          });
                        }}
                      >
                        <div className="media px-4 pt-4 pb-3">
                          <img
                            className="mr-3 sliderImage"
                            src={elem?.image_url}
                            alt="Generic placeholder image"
                            onError={(e) => (e.target.src = Userpng)}
                          />
                          <div className="media-body">
                            <h5 className="mt-0 cardTtitle">
                              {elem.name !== undefined
                                ? elem.name.length > 8
                                  ? elem.name.slice(0, 8) + "..."
                                  : elem.name
                                : ""}
                            </h5>
                            <div className="d-flex justify-content-between align-items-center">
                              <div>
                                <p
                                  className={`font-12 mb-0`}
                                  style={{ color: "#6E6E6E" }}
                                >
                                  Floor
                                </p>
                                <p
                                  className={`font-12 mb-0 font-weight-bold  ${
                                    mode === "lightMode"
                                      ? "text-dark"
                                      : "text-white"
                                  }`}
                                  style={{ color: "#6E6E6E" }}
                                >
                                  {Number(elem?.floor_price).toFixed(2)}
                                </p>
                              </div>
                              <div>
                                <p
                                  className={`font-12 mb-0`}
                                  style={{ color: "#6E6E6E" }}
                                >
                                  Volume
                                </p>
                                <p
                                  className={`font-12 mb-0 font-weight-bold ${
                                    mode === "lightMode"
                                      ? "text-dark"
                                      : "text-white"
                                  }`}
                                  style={{ color: "#6E6E6E" }}
                                >
                                  {Number(elem?.volume).toFixed(2)}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <button
                          disabled={true}
                          className={`btn-block cardBtn ${
                            mode === "lightMode"
                              ? "bg-black text-white"
                              : "text-dark bg-white"
                          }`}
                        >
                          Volume: {Number(elem?.floor_price).toFixed(2)}%
                        </button>
                      </div>
                    );
                  })}
                </Slider>
              </div>
              {showArrow && (
                <div
                  className="carousal_Icon carousal_right_icon pointer rightArrowCategory"
                  style={{
                    backgroundColor: mode === "lightMode" ? "" : "#121212",
                  }}
                  onClick={() => {arrowRef.current.slickNext()}}
                >
                  <FaChevronRight
                    size={15}
                    color={mode == "lightMode" ? "black" : "white"}
                  />
                </div>
              )}
            </div>
          )}
        </div>
      </section>
    );
  };
  const filterSection = () => {
    const handleInterval = (e) => {
      setisloading(true);
      setdata([]);
      const newMessage = { ...websocketMessage };
      newMessage.channels[0].values[3] = e.target.value;
      setwebsocketmessage(newMessage);
      setactiveInterval(e.target.value);
    };
    const customStyles = {
      option: (provided, state) => ({
        ...provided,
        borderBottom: "0px solid #ccc",
        color: state.isSelected ? "#fff" : "#333", // Selected text color
        background: mode === "lightMode" ? "" : "#1b1b1b",
        padding: "10px",
      }),
      control: (provided) => ({
        ...provided,
        border: mode === "lightMode" ? "1px solid #ccc" : "0px",
        borderRadius: "4px",
        cursor: "pointer",
        height: "45px",
        minWidth: "152px",
        position: "relative",
        bottom: "1px",
        margin: "4px",
        backgroundColor: mode === "lightMode" ? "#f6f6f6" : "#1b1b1b",
      }),
      singleValue: (provided) => ({
        ...provided,
        color: mode === "lightMode" ? "#333" : "white", // Selected text color
      }),
      indicatorSeparator: () => ({ display: "none" }), // Remove separator
      dropdownIndicator: (provided) => ({
        ...provided,
        color: mode === "lightMode" ? "black" : "white", // Dropdown icon color
        fontSize: "10px",
        transition: "transform 0.7s", // Add transition for smooth rotation
        transform: isOpen ? "rotate(180deg)" : "rotate(0deg)",
      }),
      menuList: (base) => ({
        ...base,
        padding: 0,
        "::-webkit-scrollbar": {
          width: "4px",
          height: "0px",
        },
        "::-webkit-scrollbar-track": {
          background: "#f1f1f1",
        },
        "::-webkit-scrollbar-thumb": {
          background: "#888",
        },
        "::-webkit-scrollbar-thumb:hover": {
          background: "#555",
        },
      }),
      tickIcon: {
        marginRight: "5px",
      },
    };
    return (
      <div className="row ">
        <div className="col m-1">
            <div className="d-flex align-items-center pt-2">
              <span
                className={`pointer pb-1 ${
                  type
                    ? mode === "lightMode"
                      ? "underlineblackthemMode"
                      : "underlinewhitethemode"
                    : "slidertab"
                } mx-2 `}
                onClick={() => {
                  setisloading(true);
                  setdata([]);
                  const newMessage = { ...websocketMessage };
                  if (newMessage.channels[0].values[2] === "1") {
                    newMessage.channels[0].name = "collection_index_like";
                  } else {
                    newMessage.channels[0].name = "collection_index_dislike";
                  }
                  newMessage.channels[0].values[0] = "collection_index";
                  setwebsocketmessage(newMessage);
                  settype(true);
                }}
              >
                Collections
              </span>
              <span
                className={`pointer mx-2 pb-1 ${
                  type
                    ? "slidertab"
                    : mode === "lightMode"
                    ? "underlineblackthemMode"
                    : "underlinewhitethemode"
                } `}
                onClick={(e) => {
                  setisloading(true);
                  setdata([]);
                  const newMessage = { ...websocketMessage };
                  if (newMessage.channels[0].values[2] === "1") {
                    newMessage.channels[0].name = "asset_index_like";
                  } else {
                    newMessage.channels[0].name = "asset_index_dislike";
                  }
                  newMessage.channels[0].values[0] = "asset_index";
                  setwebsocketmessage(newMessage);
                  settype(false);
                }}
              >
                Assets
              </span>
             </div>
        </div>
        <div className="col m-1">
          <ToggleButtonGroup
            color="primary"
            value={"web"}
            exclusive
            onChange={handlechoice}
            aria-label="Platform"
            className="  tabSliderContainer rounded-12  w-100 my-1"
            style={{  maxHeight: "48px" }}
          >
            <ToggleButton
              value="topLiked"
              className={`tabsliderbuttoninActive  w-50 mr-1 ${
                choice
                  ? "tabsliderbutton"
                  : mode === "lightMode"
                  ? "text-dark"
                  : "text-white"
              }   py-2 text-capitalize text-nowrap`}
            >
              Top Liked
            </ToggleButton>
            <ToggleButton
              value="topDisliked"
              className={`tabsliderbuttoninActive text-nowrap py-2 w-50 ${
                !choice
                  ? "tabsliderbutton"
                  : mode === "lightMode"
                  ? "text-dark"
                  : "text-white"
              } text-capitalize`}
            >
              Top Disliked
            </ToggleButton>
          </ToggleButtonGroup>
        </div>
        <div className="col d-flex align-items-center m-1">
          {windowWidth >= 992 && (
            <div
              className={`d-flex align-items-center ${
                windowWidth < 992 ? "" : "ml-auto"
              }`}
            >
              <ToggleButtonGroup
                color="primary"
                value={"web"}
                exclusive
                onChange={handleInterval}
                aria-label="Platform"
                className="ml-3  tabSliderContainer "
                style={{ borderRadius: "12px", maxHeight: "48px" }}
              >
                {intervalList.map((elem, index) => {
                  return (
                    <ToggleButton
                      key={index}
                      value={elem.value}
                      className={`tabsliderbuttoninActive mr-1 px-4 ${
                        activeInterval === elem.value
                          ? "tabsliderbutton"
                          : mode === "lightMode"
                          ? "text-dark"
                          : "text-white"
                      }  p-2 text-capitalize`}
                    >
                      {elem.value}
                    </ToggleButton>
                  );
                })}
              </ToggleButtonGroup>
            </div>
          )}
          {windowWidth < 992 && (
            <div className="w-100">
              <CustomSelect
                options={intervalList}
                updatevalue={updatesortingFilter}
                mode={mode}
              />
            </div>
          )}
        </div>
        <div className="col d-flex align-items-center m-1">
          <Select
            options={chainlist}
            value={chainlist.find((option) => option.key === activeChain)}
            onChange={(selectedOption) => {
              setactivechain(selectedOption?.key);
              setdata([]);
              const newMessage = { ...websocketMessage };
              newMessage.channels[0].values[4] = selectedOption?.key;
              setwebsocketmessage(newMessage);
            }}
            isSearchable={false}
            isClearable={false}
            getOptionLabel={(option) => (
              <div className="scrollbar border-0 d-flex justify-content-between align-items-center">
                <span>
                  <img
                    src={`data:image/jpg;base64,${option?.image_url}`}
                    alt={option?.name}
                    style={{ width: "20px", marginRight: "10px" }}
                  />
                  {option?.name}
                </span>
              </div>
            )}
            getOptionValue={(option) => option.key}
            styles={customStyles}
            className="font-14 border-0 scrollbar w-100"
            onMenuOpen={() => setIsOpen(true)}
            onMenuClose={() => setIsOpen(false)}
            // Customizing the Option component to display a tick mark if the option is selected
            components={{
              Option: ({ children, isSelected, ...rest }) => (
                <div
                  className={`d-flex justify-content-between align-items-center px-2 py-3 ${
                    isSelected ? "selected" : ""
                  }`}
                  style={{
                    backgroundColor:
                      mode === "lightMode" ? "#f6f6f6" : "#1b1b1b",
                  }}
                  {...rest}
                  onClick={() => {
                    rest.selectOption(rest.data);
                  }}
                >
                  {children}
                  {isSelected && (
                    <FaCheck
                      size={15}
                      className="font-weight-bold"
                      color={mode === "lightMode" ? "black" : "white"}
                    />
                  )}
                </div>
              ),
            }}
          />
        </div>
      </div>
    );
  };
  const sectionTablescreen = () => {
    return (
      <div className="row">
        <div className="col" id="CollectionTable">
          <TableContainer
            component={Paper}
            className="scrollbar "
            style={{ boxShadow: "none" }}
          >
            <Table
              sx={{ minWidth: 650 }}
              aria-label="simple table"
              style={{ borderWidth: "0px" }}
              className={`${
                mode === "lightMode"
                  ? "bg-white text-dark"
                  : "bg-dark text-white"
              }`}
            >
              <TableHead className="">
                <TableRow>
                  <TableCell
                    className={`py-1 pr-0 tablerowsTitle ${
                      mode === "lightMode" ? "" : "text-white"
                    }`}
                  >
                    #
                  </TableCell>
                  <TableCell
                    align="left"
                    className={`py-1 pl-0 tablerowsTitlewithoutAlign  ${
                      mode === "lightMode" ? "" : "text-white"
                    }`}
                  >
                    Collection
                  </TableCell>
                  {windowWidth > 768 && (
                    <TableCell
                      align="center"
                      className={`py-1 tablerowsTitle ${
                        mode === "lightMode" ? "" : "text-white"
                      }`}
                    >
                      Likes
                    </TableCell>
                  )}
                  {windowWidth > 768 && (
                    <TableCell
                      align="center"
                      className={`py-1 tablerowsTitle ${
                        mode === "lightMode" ? "" : "text-white"
                      }`}
                    >
                      Dislikes
                    </TableCell>
                  )}
                  <TableCell
                    align="center"
                    className={`py-1 tablerowsTitle ${
                      mode === "lightMode" ? "" : "text-white"
                    }`}
                  >
                    Volume
                  </TableCell>

                  {windowWidth > 768 && (
                    <TableCell
                      align="center"
                      className={`py-1 tablerowsTitle ${
                        mode === "lightMode" ? "" : "text-white"
                      }`}
                    >
                      % Change
                    </TableCell>
                  )}
                  {windowWidth > 768 && (
                    <TableCell
                      align="center"
                      className={`py-1 tablerowsTitle ${
                        mode === "lightMode" ? "" : "text-white"
                      }`}
                    >
                      Floor price&nbsp;
                    </TableCell>
                  )}
                  {windowWidth > 768 && (
                    <TableCell
                      align="center"
                      className={`py-1 tablerowsTitle ${
                        mode === "lightMode" ? "" : "text-white"
                      }`}
                    >
                      Sales&nbsp;
                    </TableCell>
                  )}
                  <TableCell
                    align="center"
                    className={`py-1 ${
                      mode === "lightMode" ? "text-dark" : "text-white"
                    }`}
                  ></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {(loading?[...Array(10).keys()]:data).map((row, index) => (
                    <TableRow
                      key={index}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      <TableCell
                        component="th"
                        scope="row"
                        className={`py-4 px-0 hideBorder tablerowsData font-weight-bold text-center ${
                          mode === "lightMode" ? "text-dark" : "text-white"
                        }`}
                        style={{ width: "50px" }}
                      >
                        {loading?<CustomShimmer style={{width:'24px',height:'24px',borderRadius: "1rem"}}/>:index === 0 || index === 1 || index === 2 ? (
                          <img
                            width="24px"
                            height={"24px"}
                            src={
                              index === 0
                                ? firstRank
                                : index === 1
                                ? secondRank
                                : thirdRank
                            }
                          />
                        ) : (
                          index + 1
                        )}
                      </TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        className="pointer tablerowsData hideBorder px-0 d-flex align-items-center"
                        onClick={() => {
                          handleClick(row);
                        }}
                        style={{ width: "150px" }}
                      >
                        <span>
                          {loading?<CustomShimmer style={{width:'40px',height:'40px',borderRadius: "1rem"}}/>:<img
                            src={
                              row.imageUrl !== "null" ? row.imageUrl : Userpng
                            }
                            alt="..."
                            width={"40px"}
                            height={"40px"}
                            style={{
                              borderRadius: "1rem",
                            }}
                            className="rounded rouned-circle"
                          />}
                          &nbsp;&nbsp;&nbsp;&nbsp;
                        </span>
                        <span
                          className={`hideborder tablerowsData ${
                            mode === "lightMode" ? "text-dark" : "text-white"
                          }`}
                        >
                          {loading?<CustomShimmer style={{width:'80px',height:"25px",borderRadius: "1rem"}}/>:row?.name !== undefined
                            ? (
                                windowWidth < 768
                                  ? row?.name.length < 5
                                  : row?.name.length < 8
                              )
                              ? row?.name
                              : row?.name.slice(0, windowWidth < 768 ? 5 : 8) +
                                " ..."
                            : ""}
                        </span>
                      </TableCell>
                      {windowWidth > 768 && (
                        <TableCell
                          align="center"
                          className={` hideBorder tablerowsData  text-center  ${
                            mode === "lightMode" ? "text-dark" : "text-white"
                          }`}
                        >
                          {loading?<CustomShimmer style={{width:'80px',height:"25px",borderRadius: "1rem"}}/>:Number(row?.like).toFixed(2)}
                        </TableCell>
                      )}
                      {windowWidth > 768 && (
                        <TableCell
                          align="center"
                          className={` hideBorder tablerowsData  text-center  ${
                            mode === "lightMode" ? "text-dark" : "text-white"
                          }`}
                        >
                          {loading?<CustomShimmer style={{width:'80px',height:"25px",borderRadius: "1rem"}}/>:Number(row?.dislike).toFixed(2)}
                        </TableCell>
                      )}
                      <TableCell
                        align="center"
                        className={` hideBorder tablerowsData  text-center  ${
                          mode === "lightMode" ? "text-dark" : "text-white"
                        }`}
                        style={{ width: "50px" }}
                      >
                        {loading?<CustomShimmer style={{width:'80px',height:"25px",borderRadius: "1rem"}}/>:Number(row?.volume).toFixed(2)}
                      </TableCell>
                      {windowWidth > 768 && (
                        <TableCell
                          align="center"
                          className={`text-success tablerowsData hideBorder  ${
                            mode === "lightMode" ? "text-success" : "text-white"
                          }`}
                        >
                          {loading?<CustomShimmer style={{width:'80px',height:"25px",borderRadius: "1rem"}}/>:Number(row?.percentChangeVolumes).toFixed(2)+" %"} 
                        </TableCell>
                      )}
                      {windowWidth > 768 && (
                        <TableCell
                          align="center"
                          className={`hideBorder tablerowsData  ${
                            mode === "lightMode" ? "text-dark" : "text-white"
                          }`}
                        >
                          {loading?<CustomShimmer style={{width:'80px',height:"25px",borderRadius: "1rem"}}/>:Number(row?.floorPrice).toFixed(2)}
                        </TableCell>
                      )}
                      {windowWidth > 768 && (
                        <TableCell
                          align="center"
                          className={`hideBorder tablerowsData  ${
                            mode === "lightMode" ? "text-dark" : "text-white"
                          }`}
                        >
                          {loading?<CustomShimmer style={{width:'80px',height:"25px",borderRadius: "1rem"}}/>:row?.sales}
                        </TableCell>
                      )}
                      <TableCell
                        align="center"
                        className={`hideBorder font-weight-bold ${
                          mode === "lightMode" ? "text-dark" : "text-white"
                        }`}
                        style={{ width: "50px" }}
                      >
                       {loading?<CustomShimmer style={{width:'80px',height:"25px",borderRadius: "1rem"}}/>:<FaRegStar size={20} className="fontweight-700" />}
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
    );
  };

  //API SECTION
  const TopCollectionApi = async () => {
    const span = tracer.startSpan("TopCollectionApi");
    const t = trace(sendPerfomance, "TopCollectionApi");
    const url = `/trendingCollections?blockchain=all`;
    try {
      t.start();
      setisSliderLoading(true);
      await Axios.get(url)
        .then((res) => {
          if (res.status === 200) {
            settrandingCollection(res.data);
          }
          span.setAttribute("Method", "GET");
          span.addEvent("api_response", {
            status: res.status,
            url,
            data: res.data,
          });
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          span.end();
          t.stop();
          setisSliderLoading(false);
        });
    } catch (error) {
      console.log(error);
      span.end();
    }
  };
  const blockchainApi = async () => {
    const span = tracer.startSpan("BlockChainListApi");
    const url = BaseUrl + "/blockchainlist";
    try {
      await axios
        .get(url)
        .then((res) => {
          if (res.status === 200) {
            if (typeof res.data === "string") {
              toast.warn("invalid data got through blockchain api");
              return;
            }
            if (res.data.length > 0) {
              setchainlist(res.data);
              setactivechain(res.data[0].key);
            }
          }
          span.setAttribute("Method", "POST");
          span.addEvent("api_response", {
            status: res.status,
            url,
            data: res.data,
          });
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          span.end();
        });
    } catch (error) {
      console.log(error);
    }
  };

  //EVENT HANDLING

  useEffect(() => {
    const handleResize = () => {
      setwindowWidth(window.innerWidth);
      if (window.innerWidth < 992) {
        setshowArrow(true);
      }
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    TopCollectionApi();
    blockchainApi();
  }, []);
  useEffect(() => {
    if (!socketRef.current) {
      socketRef.current = new w3cwebsocket(REACT_APP_COLLECTION_WEBSOCKET_URL);

      const t = trace(sendPerfomance, "custom_Trace_name");
      t.start();

      socketRef.current.onopen = () => {
        setisloading(true);
        socketRef.current.send(JSON.stringify(websocketMessage));
      };

      socketRef.current.onmessage = (event) => {
        const message = JSON.parse(event.data);
        convertBinaryToText(message);
        setisloading(false);
      };

      socketRef.current.onerror = (error) => {
        console.error("WebSocket error:", error);
      };

      t.stop();
      t.putAttribute("page", "/");

      return () => {
        if (socketRef.current) {
          socketRef.current.close();
          socketRef.current = null;
        }
      };
    }
  }, [websocketMessage]);

  return (
    <section
      style={{ padding: windowWidth > 1200 ? "48px 137px" : "0 20px" }}
      className={` container-fluid ${mode === "lightMode" ? "bg-white text-dark" : "bg-dark text-white"}`}>
      {FirstSection()}
      {topCollectionSliderSection()}
      {filterSection()}
      <InfiniteScroll
        dataLength={data.length}
        next={() => {
          if (data.length % 50 === 0) {
            handlefetchMoreData();
          }
        }}
        hasMore={hasmore}
        style={{ overflowX: "hidden" }}
      >
        {sectionTablescreen()}
      </InfiniteScroll>
      <div className="row">
        <div className="col">
          {!loading && data.length === 0 && (
            <h5 className="text-center">No Record Found</h5>
          )}
        </div>
      </div>
    </section>
  );
}
