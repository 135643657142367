export const data = [
    {
      year: "2000",
      uv: 4000,
      pv: 2400,
      amt: 5000,
    },
    {
      year: "2002",
      uv: 3000,
      pv: 1398,
      amt: 2210,
    },
    {
      year: "2004",
      uv: 2000,
      pv: 9800,
      amt: 7000,
    },
    {
      year: "2006",
      uv: 2780,
      pv: 3908,
      amt: 2000,
    },
    {
      year: "2008",
      uv: 1890,
      pv: 4800,
      amt: 7500,
    },
    {
      year: "2010",
      uv: 2390,
      pv: 3800,
      amt: 2500,
    },
    {
      year: "2012",
      uv: 3490,
      pv: 4300,
      amt: 2100,
    },
  ];
 export const redarData = [
    {
      subject: "Math",
      A: 120,
      B: 110,
      fullMark: 150,
    },
    {
      subject: "Chinese",
      A: 98,
      B: 130,
      fullMark: 150,
    },
    {
      subject: "English",
      A: 86,
      B: 130,
      fullMark: 150,
    },
    {
      subject: "Geography",
      A: 99,
      B: 100,
      fullMark: 150,
    },
    {
      subject: "Physics",
      A: 85,
      B: 90,
      fullMark: 150,
    },
    {
      subject: "History",
      A: 65,
      B: 85,
      fullMark: 150,
    },
  ];