import React from "react";
import { useSelector } from "react-redux";
import { FaXing, FaLinkedinIn } from "react-icons/fa";
import { IoIosSend } from "react-icons/io";
import { AiOutlineLink } from "react-icons/ai";
import { TiSocialFacebook } from "react-icons/ti";
import "./privcaypolicy.css";

export default function PrivacypolicyScreen() {
  const mode = useSelector((state) => state.themeType.themeType);
  console.log(mode);

  const socialMediaIcon = (size) => {
    const iconList = [
      {
        label: "faxing",
        component: <FaXing size={size}  color={'black'} />,
      },
      {
        label: "fb",
        component: <TiSocialFacebook size={size} color={'black'} />,
      },
      {
        label: "linkedin",
        component: <FaLinkedinIn size={size} color={'black'} />,
      },
      {
        label: "send",
        component: <IoIosSend size={size} color={'black'} />,
      },
      {
        label: "link",
        component: <AiOutlineLink size={size} color={'black'} />,
      },
    ];
    return (
      <div className="d-flex flex-wrap  ">
        {iconList.map((elem,index) => (
          <span
            className="socialmediaIcon rounded-circle "
            style={{ backgroundColor: "#f9f9f9" }}
            key={index}
          >
            {elem.component}
          </span>
        ))}
      </div>
    );
  };

  return (
    <div
      className={`${
        mode !== "lightMode" ? "bg-black text-white" : "bg-white text-dark"
      }`}
    >
      <div className="row mx-1 mb-5 ">
        <div className="col-11 offset-1 my-3">
          <h1 id="privacyTitle" className="my-5">
            Privacy Policy Statement
          </h1>
          <span id="privacyUpdateTime" className="text-muted">
            Published on May 30 | 2023 Updated on Jan 26 | 2024 26 min read
          </span>
          {socialMediaIcon(19)}
        </div>

        <div className="col-9 offset-1 mr-auto" id="privayContent"  >
          <p className="font-weight-bold my-4 ">
            Last Updated: 15 December 2023
          </p>
          <p className="mt-3 mb-5">
            <strong>1. INTRODUCTION</strong>
          </p>

          <p>
            Thank you for visiting{" "}
            <a href="http://jrn.com/" target="_blank" className="font-weight-bold text-dark">
              JRN.com
            </a>
            . The relevant JRN entity as a/the data controller, provides this
            Privacy Policy Statement to describe our practices regarding the
            collection, storage, use, disclosure and other processing of
            Personal Data (defined below). By visiting, accessing, or using{" "}
            <a href="http://jrn.com/" target="_blank" className="font-weight-bold text-dark">
              JRN.com
            </a>{" "}
            and associated application program interfaces or mobile applications
            (the “<strong>JRN Platform</strong>”), you (a) acknowledge that you
            have the right, capacity and authority to accept this Privacy Policy
            Statement (the “<strong>Privacy Policy</strong>”); (b) acknowledge
            that you have read and understand this Privacy Policy and (c)
            consent to the policies and practices outlined in this Privacy
            Policy. So please read them carefully to understand what we do.
          </p>
          <p>
            This Privacy Policy explains what data we collect, why we collect
            it, how such data is used and stored, how such data may be shared by
            us, rights you may have, and how you can contact us about our
            privacy practices. If you do not wish your Personal Data (as defined
            below) to be used in the ways described in this Privacy Policy, you
            shall, shall not use this JRN Platform, technologies, products
            and/or functionalities offered by the JRN Platform (collectively,
            the “<strong>Services</strong>”).
          </p>

          <p>
            <strong>2. CONTROLLER AND CONTACT DETAILS</strong>
          </p>
          <p>
            The JRN group is made up of different legal entities, details of
            which can be found in the below table (“<strong>JRN</strong>”, “
            <strong>we</strong>”, “<strong>us</strong>” or “
            <strong>ours</strong>”). JRN acts as a controller of your personal
            data where we determine how and why personal data can be used, and
            the JRN entity is the primary controller of your personal data. This
            Privacy Policy does not apply where we act as a processor or service
            provider to another controller (including our customers).
          </p>

            <table className="table">
              <thead>
                <tr>
                  <th scope="col">Relevant JRN entity</th>
                  <th scope="col">Who you are</th>
                  <th scope="col">Relevant DPO Email</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>JRN Bahamas FinTech Company Limited </td>
                  <td>
                    A user who is a resident of Mexico and who registered
                    between November 16, 2022 and August 28, 2023, or an
                    institutional user who is registered on or after August 29,
                    2023
                  </td>
                  <td>dpo@jrn.com</td>
                </tr>
                <tr>
                  <td>JRN Hong Kong FinTech Company Limited</td>
                  <td>
                    A user who is a resident of Hong Kong and who registered on
                    or after May 15, 2023
                  </td>
                  <td>dpohk@jrn.com</td>
                </tr>
                <tr>
                  <td>JRN Serviços Digitais Ltda.</td>
                  <td>
                    A user who is a resident of Brazil and who registered on or
                    after June 15, 2023
                  </td>
                  <td>dpo@jrn.com</td>
                </tr>
                <tr>
                  <td>JRN SG Pte. Ltd.</td>
                  <td>
                    A user who is a resident of Singapore or Brunei and who
                    registered on or after October 13, 2023
                  </td>
                  <td>dposg@jrn.com</td>
                </tr>
                <tr>
                  <td>Aux Cayes FinTech Co. Ltd.</td>
                  <td>
                    A user who does not fall under any of the above categories
                  </td>
                  <td>dposc@jrn.com</td>
                </tr>
              </tbody>
            </table>
          <p>
            <strong>3. DEFINITIONS</strong>
          </p>
            <table className="table">
              <tbody>
                <tr>
                  <td>Data Protection Officer</td>
                  <td>
                    means the relevant JRN Data Protection Officer responsible
                    for data protection and that may be contacted at the
                    relevant email address set out in Section 2 above.
                  </td>
                </tr>
              </tbody>
              <tbody>
                <tr>
                  <td>Digital Asset</td>
                  <td>
                    means a digital asset (also called a “virtual financial
                    asset”, “convertible virtual currency”, “cryptocurrency”,
                    “virtual currency”, “digital currency”, “digital commodity”,
                    or “digital payment token”), such as bitcoin or ether, which
                    is based on the cryptographic protocol of a computer network
                    that may be (i) centralized or decentralized, (ii) closed or
                    open-source, and (iii) used as a medium of exchange and/or
                    store of value.
                  </td>
                </tr>
                <tr>
                  <td>Personal Data</td>
                  <td>
                    means any information relating to an identified natural
                    person (a “Data Subject”), or one who can be identified
                    directly or indirectly by way of linking data, using
                    identifiers such as name, voice, picture, identification
                    number, online identifier, geographic location, or one or
                    more special features that express the physical,
                    psychological, economic, cultural or social identity of such
                    person. It does not include data where the identity has been
                    removed (anonymous data). This includes Sensitive
                    Information.
                  </td>
                </tr>
                <tr>
                  <td>Sensitive Information</td>
                  <td>
                    means any data that directly or indirectly reveals a natural
                    person's family, racial origin, political or philosophical
                    opinions, religious beliefs, criminal records, biometric
                    data, or any data related to the health of such person, such
                    as his/her physical, psychological, mental, genetic or
                    sexual condition, including information related to health
                    care services provided thereto that reveals his/her health
                    status.
                  </td>
                </tr>
              </tbody>
            </table>
          <p>
            <strong>
              4. WHAT PERSONAL DATA WE COLLECT AND HOLD, AND HOW WE COLLECT IT
            </strong>
          </p>
          <p>
            JRN collects, processes, and stores Personal Data via your use of
            the Service or where you have given your consent. This Personal Data
            may include contact details, copies of identification documentation
            provided by you or derived from publicly accessible databases, your
            government identification number as well as information relating to
            your device or internet service (such as an IP address and a MAC
            number).
          </p>
          <p>
            To understand how JRN protects the data it collects from its users,
            please see Section 13 (“Information Security”) below.
          </p>
          <p>
            We collect information you provide during the JRN Platform
            on-boarding process, which may be a completed, incomplete, or
            abandoned process. We also collect Personal Data when you
            communicate with us through customer support, subscribe to marketing
            communications, correspond with us by phone, email, or other
            communication channels, or when you conduct a transaction on the JRN
            Platform. We may actively or automatically collect, use, store, or
            transfer your Personal Data, which may include, without limitation,
            the following:{" "}
          </p>
          <ul>
            <li>
              Personal identification information such as name, email, phone
              number, nationality, date of birth, address, and government
              identification information;
            </li>
            <li>
              Institutional details such as corporate legal name, corporate
              registration information, government identification number, proof
              of identity and legal existence, address, business description,
              and beneficial owner information;
            </li>
            <li>
              Commercial information such as data related to transactions
              conducted on the JRN Platform;
            </li>
            <li>
              Financial information such as credit/debit card numbers and bank
              account information;
            </li>
            <li>
              Correspondence Information such as communication with our Customer
              Support team and response to user survey;
            </li>
            <li>
              Information required by regulatory agencies such as state and
              federal licensing authorities and consumer protection agencies;
              and
            </li>
            <li>
              Other identifiers such as device fingerprint data, IP address, and
              geolocation information.
            </li>
          </ul>
          <p>
            We may also collect Personal Data about you from third parties, such
            as electronic verification services, referrers, marketing agencies
            or liquidity providers. If so, we will take reasonable steps to
            ensure that they are made aware of applicable privacy laws. We may
            also use third parties to analyze traffic on our website, which may
            involve the use of cookies (additional information on Cookie Usage
            is set out in Section 13 below). Information collected through such
            analysis is not anonymous.
          </p>
          <p>
            We will not collect Sensitive Information about you without your
            consent, unless an exemption or exception applies. These exemptions
            or exceptions include if the collection is required or authorized by
            law, or if it is necessary to take appropriate action in relation to
            suspected unlawful activity or serious misconduct.
          </p>
          <p>
            If the Personal Data we request is not provided by you, we may not
            be able to provide you with the benefit of our Services or meet your
            needs appropriately. Accordingly, we do not give you the option of
            dealing with us anonymously or under a pseudonym.
          </p>
          <p>
            Furthermore, we conduct business and collect Personal Data from
            individuals and entities located in various jurisdictions in
            accordance with data protection laws. Where applicable, we are
            required to protect Personal Data processed in such jurisdictions in
            accordance with the applicable data protection laws. To understand
            more about how we protect the data collected from individuals and
            entities located within such jurisdictions, please see Sections 18
            to 20 below.
          </p>
          <p>
            <strong>5. UNSOLICITED PERSONAL DATA</strong>
          </p>
          <p>
            We may receive unsolicited Personal Data about you. We destroy or
            de-identify all unsolicited Personal Data we receive, unless it is
            relevant to the purposes stated in this Privacy Policy for
            collecting Personal Data. We may retain additional information we
            receive about you if it is combined with other information we are
            required or entitled to collect. If we do this, we will retain the
            information in the same way we hold your other Personal Data.
          </p>
          <p>
            <strong>6. WHO WE COLLECT PERSONAL DATA ABOUT</strong>
          </p>
          <p>
            The Personal Data we may collect, and hold includes (but is not
            limited to) Personal Data about users, potential users, service
            providers or suppliers of the JRN Platform or our Services,
            prospective employees, employees and contractors and other third
            parties with whom we come into contact.
          </p>
          <p>
            <strong>7. HOW WE USE YOUR PERSONAL DATA</strong>
          </p>
          <p>
            JRN uses Personal Data to administer, deliver, improve, and
            personalize the Services for you and to comply with our legal and
            regulatory obligations. We also may use such data to communicate
            with you in relation to other products or services offered by JRN
            and/or its partners to consider any concerns or complaints you may
            have.
          </p>
          <p>
            Sensitive Information will be used and disclosed for the purpose for
            which it was provided (or a directly related secondary purpose),
            unless you explicitly consent otherwise, or an exemption under law
            applies. Below are specific ways in which we may process your
            Personal Data:
          </p>
          <ul>
            <li>
              <p>
                Provide you with our services. We use your Personal Data to
                provide you with our Services pursuant to our terms of service.
                For example, we need to know certain financial information to
                conduct fiat transfers into and out of your account.{" "}
              </p>
            </li>
            <li>
              <p>
                Detect and prevent fraud. Your Personal Data is used to detect
                and prevent fraud.
              </p>
            </li>
            <li>
              <p>
                Protect the security of our Services. We process your Personal
                Data, such as information about your device and activity, to
                maintain the security of your account and the JRN Platform.
              </p>
            </li>
            <li>
              <p>
                User/customer support. We process your Personal Data when you
                contact our Customer Support team to help us address your
                question.
              </p>
            </li>
            <li>
              <p>
                Enhance our Services. We process your Personal Data to
                understand how our Services are being used to improve our
                Services and develop new Services.
              </p>
            </li>
            <li>
              <p>
                Product marketing. We process your Personal Data to identify our
                Services that we believe may be of interest to you. We may
                contact you about them. For more information, refer to Section
                12 (Communications) below.{" "}
              </p>
            </li>
            <li>
              <p>
                Consent. We may use your Personal Data for additional purposes
                with your consent.
              </p>
            </li>
            <li>
              <p>
                Other business purposes. We may use your Personal Data for other
                reasonably expected business purposes as permitted by law or
                when required to comply with our legal obligations.
              </p>
            </li>
          </ul>
          <p>
            <strong>Processing Personal Data Without Consent</strong>
          </p>
          <p>
            If you do not provide us with consent to process your Personal Data,
            we may still process your Personal Data under one of the following
            bases:
          </p>
          <ul>
            <li>
              <p>
                Public interest. We will process your Personal Data without your
                consent where it is necessary to protect the public interest.
              </p>
            </li>
            <li>
              <p>
                Legal Proceedings. We will process your Personal Data without
                your consent where it is necessary to initiate or defend legal
                proceedings or in relation to judicial or security procedures.
              </p>
            </li>
            <li>
              <p>
                Protection of your interests. We will process your Personal Data
                without your consent where it is necessary to protect your
                interests.
              </p>
            </li>
            <li>
              <p>
                Performance of a contract. We will process your Personal Data
                without your consent where it is necessary for the performance
                of a contract to which you are a party or to take steps at your
                request before entering into such a contract; we use this basis
                for provision of our Services.
              </p>
            </li>
            <li>
              <p>
                Compliance with a legal obligation. We will process your
                Personal Data without your consent where we need to comply with
                a legal obligation we are subject to.
              </p>
            </li>
          </ul>
          <p>
            <strong>To Whom We Might Disclose Personal Data</strong>
          </p>
          <p>JRN may disclose Personal Data to:</p>
          <ul>
            <li>
              <p>
                Members of our corporate group, which includes our subsidiaries,
                holding companies and companies under common control including
                their respective contractors, affiliates, employees or
                representatives.{" "}
              </p>
            </li>
            <li>
              <p>
                Our service providers and other third parties which assist us in
                providing Services to you and/or as required or permitted by law
                or professional standards including, for example, payment
                processing, customer support, data analytics, information
                technology, data processing, network infrastructure, storage and
                tax reporting.{" "}
              </p>
            </li>
            <li>
              <p>
                Entities in connection with corporate transactions involving
                JRN, including any financing, acquisition or dissolution
                proceedings which involve disclosing a certain portion or all of
                our business or assets.
              </p>
            </li>
            <li>
              <p>
                Government entities or other parties to legal process, including
                law enforcement agencies and authorities, officers, regulators
                or other third parties to comply with any law, court order,
                subpoena or government request.
              </p>
            </li>
            <li>
              <p>
                Professional advisors, including legal, accounting or other
                consulting services for purposes of audits or to comply with our
                legal obligations.
              </p>
            </li>
          </ul>
          <p>
            Other than as disclosed in this Privacy Policy, JRN does not share
            your Personal Data with any other third parties unless required to
            do so by law or legal reporting obligations. The JRN Platform may
            contain links to other third-party websites where their own privacy
            policies may apply and JRN is not responsible for the privacy
            policies of such third-party websites.
          </p>
          <p>
            If we disclose your Personal Data to service providers that perform
            business activities for us, they may only use your Personal Data for
            the specific purpose for which we supply it. We will take reasonable
            steps to ensure that all contractual arrangements with third parties
            adequately address compliance with applicable privacy laws.
            Additionally, we have implemented standards to prevent money
            laundering, terrorist financing and circumventing trade and economic
            sanctions. These standards require us to undertake due diligence on
            our users in order to be compliant with applicable laws and
            regulations. This may include the use of third-party data and
            service providers which will cross-reference your Personal Data for
            identity verification, fraud detection and prevention, transaction
            monitoring, credit verification and security threat detection.
          </p>
          <p>
            <strong>8. HOW WE STORE YOUR PERSONAL DATA</strong>
          </p>
          <p>
            We recognize the importance of securing the Personal Data of our
            users. We take steps to ensure your Personal Data is protected from
            misuse, interference or loss, and unauthorized access, modification
            or disclosure. Your Personal Data is generally stored in our or our
            affiliates ‘computer databases and/or with third party storage
            providers. In relation to information that is held on our computer
            database, we apply data security guidelines to ensure that your
            Personal Data is managed securely.
          </p>
          <p>
            For more information, please refer to Section 14 (Information
            Security) below.
          </p>
          <p>
            The data that we collect from you may be transferred to, and stored
            at, a destination outside of the country of your residence. It may
            also be processed by staff operating outside of your residence who
            work for us or for one of our service providers. By submitting your
            Personal Data, you expressly consent to this transfer, storing or
            processing, except users located in certain jurisdictions, as
            detailed in Sections 18 to 20 below.
          </p>
          <p>
            We retain your Personal Data for as long as is reasonably necessary
            to provide services to you, for our legitimate business purposes,
            and to comply with our legal and regulatory obligations. If you
            close your account on the JRN Platform, we will continue to retain
            your Personal Data as necessary to comply without legal and
            regulatory obligations. For example, we are subject to certain
            anti-money laundering laws which require us to retain records we
            used to comply with our client identification and due diligence
            obligations for an additional period after our business relationship
            with you has ended.
          </p>
          <p>
            <strong>9. SENDING INFORMATION TO OTHER COUNTRIES</strong>
          </p>
          <p>
            <strong>9. SENDING INFORMATION TO OTHER COUNTRIES</strong>
          </p>
          <p>
            We may transfer Personal Data that we maintain about you to
            recipients in countries other than the country in which the Personal
            Data was originally collected. Those other countries may have data
            protection or privacy rules that are different from those of your
            country. However, we will take measures to e
            <p>
              Subject to exceptions set out in the law, you have the right to
              obtain a copy of your Personal Data upon request and ascertain
              whether the information we hold about you is accurate and
              up-to-date. We will provide access within 30 days of your request.
              If we refuse to provide the information, we will provide reasons
              for the refusal. We will require identity verification and
              specification of what information is required before providing you
              with access. If any of your Personal Data is inaccurate, you may
              request to update your information. Where we are satisfied that
              the request to update the information is accurate, we will take
              reasonable steps to correct the information within 30 days, unless
              you agree otherwise. You may also request to delete your Personal
              Data, with the exception that we may refuse your deletion request
              in certain circumstances, such as compliance with law or legal
              purposes. For data access, correction, or deletion requests, or to
              request withdrawal of your previously provided consent, please
              send an email with your request to the relevant email address set
              out in Section 2 above with the subject “DATA INQUIRY REQUEST”.
            </p>
            <p>
              <strong>11. CHILDRENS’ PERSONAL DATA</strong>
            </p>
            nsure that any such transfers comply with applicable data protection
            laws and that your Personal Data remains protected to the standards
            described in this Privacy Policy. In certain circumstances, courts,
            law enforcement agencies, regulatory agencies or security
            authorities in those other countries may be entitled to access your
            Personal Data. By communicating electronically with JRN, you
            acknowledge and agree to your Personal Data being processed in this
            way.
          </p>
          <p>
            <strong>
              10. ACCESS, CORRECTION, AND DELETION OF YOUR PERSONAL DATA
            </strong>
          </p>
          <p>
            Subject to exceptions set out in the law, you have the right to
            obtain a copy of your Personal Data upon request and ascertain
            whether the information we hold about you is accurate and
            up-to-date. We will provide access within 30 days of your request.
            If we refuse to provide the information, we will provide reasons for
            the refusal. We will require identity verification and specification
            of what information is required before providing you with access. If
            any of your Personal Data is inaccurate, you may request to update
            your information. Where we are satisfied that the request to update
            the information is accurate, we will take reasonable steps to
            correct the information within 30 days, unless you agree otherwise.
            You may also request to delete your Personal Data, with the
            exception that we may refuse your deletion request in certain
            circumstances, such as compliance with law or legal purposes. For
            data access, correction, or deletion requests, or to request
            withdrawal of your previously provided consent, please send an email
            with your request to the relevant email address set out in Section 2
            above with the subject “DATA INQUIRY REQUEST”.
          </p>
          <p>
            <strong>11. CHILDRENS’ PERSONAL DATA</strong>
          </p>
          <p>
            JRN does not knowingly offer services to or collect the Personal
            Data of anyone under the age of 18. If we learn that we have
            collected Personal Data of anyone under the age of 18, we will
            promptly delete it from our systems. If you are aware of anyone
            under the age of 18 using our Services, please notify us so we can
            take prompt action to prevent access to our Services.
          </p>
          <p>
            <strong>12. COMMUNICATIONS</strong>
          </p>
          <p>
            We may only use Personal Data we collect from you for the purposes
            of direct marketing without your consent if the Personal Data does
            not include Sensitive Information, you would reasonably expect us to
            use or disclose the information for the purpose of direct marketing,
            we provide a simple way of opting out of direct marketing and you
            have not requested to opt out of receiving direct marketing from us.
          </p>
          <p>
            If we collect Personal Data about you from a third party, we will
            only use that information for the purposes of direct marketing if
            you have consented (or it is impracticable to obtain your consent)
            and we will provide a simple means by which you can easily request
            not to receive direct marketing communications from us. We will draw
            your attention to the fact you may make such a request in our direct
            marketing communications.
          </p>
          <p>
            We may communicate company news, promotions, and information
            relating to our products and services provided by JRN. We may share
            Personal Data with third parties to help us with our marketing and
            promotional projects or sending marketing communications.
          </p>
          <p>
            Users can opt out from these marketing communications at any time by
            following the unsubscribe link within any marketing communications
            sent to you or by contacting our Customer Service at{" "}
            <a href="/help">Support Center</a>.
          </p>
          <p>
            For Services related communications, such as surveys, policy/terms
            updates and operational notifications, you will not be able to opt
            out of receiving such information.
          </p>
          <p>
            <strong>13. COOKIE USAGE</strong>
          </p>
          <p>
            While you access our website, i.e.,{" "}
            <a href="http://www.jrn.com/" target="_blank">
              www.jrn.com
            </a>
            , we may use the industry practice of placing a small amount of data
            that will be saved by your browser (“<strong>Cookies</strong>”).
            This information can be placed on your computer or other devices
            used to visit our Site. We use Cookies to enhance your experience of
            using our Site. The information is used to identify users, remember
            user preferences and allow users to complete tasks without having to
            re-enter information when browsing from one webpage to another or
            when re-visiting our website at a later date. We also use Cookies to
            collect and analyze website usage data, related to user use and
            patterns. This data is used to improve our website and enhance users
            ‘experience. We may also use the information collected to ensure
            compliance with our regulatory and Anti-Money Laundering (“
            <strong>AML</strong>”) requirements, and to ensure your account
            security has not been compromised by detecting irregular,
            suspicious, or potentially fraudulent account activities.
          </p>
          <p>
            Set up, you can set your browser to block or alert you about these
            Cookies, but this may affect the functionality of the Services or
            your user experience. Session Cookies are added when a user starts
            to browse our website or interacts with a specific feature and are
            deleted when the browser is closed. Persistent Cookies are added
            when a user starts to browse our website or interacts with a
            specific feature but may remain stored on your device until a
            certain termination date is reached.
          </p>
          <p>
            <strong>14. INFORMATION SECURITY</strong>
          </p>
          <p>
            We have put in place appropriate information security measures to
            prevent your Personal Data from being accidentally lost, accessed,
            altered, disclosed, used or destroyed in an unauthorized way (or
            other similar risks). We take various measures to ensure information
            security, including encryption of the JRN Platform communications;
            required two-factor authentication for all sessions; periodic review
            of our Personal Data collection, storage, and processing practices;
            and restricted access to your Personal Data on a need-to-know basis
            for our employees and vendors who are subject to strict contractual
            confidentiality obligations.
          </p>
          <p>
            If you have any questions about information security or report any
            security issues, please contact us by sending an email to the
            relevant email address set out in Section 2 above with the subject
            “INFORMATION SECURITY REQUEST”.
          </p>
          <p>
            <strong>
              15. CONTACTING JRN ABOUT PRIVACY QUESTIONS OR CONCERNS
            </strong>
          </p>
          <p>
            If you have any questions about this Privacy Policy or the use of
            your Personal Data, please contact us by sending an email to the
            relevant email address set out in Section 2 above with the subject
            “PRIVACY REQUEST”.
          </p>
          <p>
            When handling requests to exercise your privacy rights, we check the
            identity of the requesting party to ensure that he or she is the
            person legally entitled to make such a request. While we endeavor to
            respond to these requests free of charge, should your request be
            repetitive or unduly onerous, we reserve the right to charge you a
            reasonable fee (if applicable) for compliance with your request. To
            exercise your privacy rights, please send an email with your request
            to the relevant email address set out in Section 2 above with the
            subject “DATA INQUIRY REQUEST”.
          </p>
          <p>
            <strong>16. CHANGES TO OUR PRIVACY POLICY</strong>
          </p>
          <p>
            We may update this Privacy Policy at any time by posting the amended
            version on the JRN Platform including the effective date of the
            amended version, so please check frequently to see if there are any
            updates and changes. Your continued access to or use of the JRN
            Platform and/or the Services constitutes your acknowledgment and
            acceptance of such changes to this Privacy Policy.
          </p>
          <p>
            <strong>17. LANGUAGES</strong>
          </p>
          <p>
            This Privacy Policy may be posted in different languages. If there
            are any discrepancies, the English version shall prevail.
          </p>
          <p>
            <strong>
              18. ADDITIONAL INFORMATION FOR PERSONS SUBJECT TO EU DATA
              PROTECTION LAWS
            </strong>
          </p>
          <p>
            For users who are located in the EEA or other locations subject to
            EU data protection laws (collectively, “
            <strong>European Residents</strong>”), we adhere to relevant and
            applicable EU data protection laws and provide European Residents
            with the following additional information. For the purposes of this
            section, “<strong>Personal Data</strong>” has the meaning provided
            in the General Data Protection Regulation (EU) 2016/679 (GDPR).
          </p>
          <p>
            <strong>A. Legal Basis For Processing Personal Data.</strong> We
            process Personal Data subject to the GDPR on one or more of the
            following legal bases:
          </p>
          <ul>
            <li>
              <p>
                To comply with legal obligations and regulations. To comply with
                applicable laws, including “know your customer” obligations
                based on applicable anti-money laundering and anti-terrorism
                requirements, financial crime and fraud prevention, suspicious
                activity reporting, responding to requests from public
                authorities, complying with economic and trade sanctions
                requirements, performing customer due diligence, performing
                audit and risk assessments, preparing tax reports, fulfilling
                our retention obligations and handling legal claims.
              </p>
            </li>
            <li>
              <p>
                To comply with contractual obligations. To comply with our
                contractual obligations to you under our terms of service,
                including to provide you with our services and customer support
                services, and to optimize and enhance the JRN Platform.
              </p>
            </li>
            <li>
              <p>
                Consent. To provide and market our services to you based on your
                consent. You may withdraw your consent at any time without
                affecting the lawfulness of processing based on consent before
                consent is withdrawn.
              </p>
            </li>
            <li>
              <p>
                Legitimate interest. To monitor the usage of the JRN Platform,
                fraud prevention, network and information security, conduct
                automated and manual security checks of our Services, to engage
                in direct marketing activities and to protect your rights. When
                we process your personal data for our legitimate interests, we
                consider and balance any potential impact on you and your rights
                under data protection laws.
              </p>
            </li>
          </ul>
          <p>
            <strong>B. European Privacy Rights.</strong> European Residents have
            the following rights under the GDPR with respect to their Personal
            Data, subject to certain exceptions provided under the law. We will
            advise you in our response to your request where we are relying on
            such exemptions. You should include adequate information to identify
            yourself and other relevant information that will reasonably assist
            us in fulfilling your request.
          </p>
          <ul>
            <li>
              <p>
                Right to Access and Rectification. You may submit a request that
                JRN disclose the Personal Data we process about you and correct
                any inaccurate Personal Data.
              </p>
            </li>
            <li>
              <p>
                Right to withdraw consent. Where we have relied upon your
                consent to process your Personal Data, you have the right to
                withdraw that consent. This does not affect the lawfulness of
                processing based on your consent until withdrawal.
              </p>
            </li>
            <li>
              <p>
                Right to Erasure. You may submit a request that JRN delete the
                Personal Data that we have about you.
              </p>
            </li>
            <li>
              <p>
                Right to Restriction of Processing and Right to Object. You have
                the right to restrict or object to our processing of your
                Personal Data under certain circumstances.
              </p>
            </li>
            <li>
              <p>
                Right to Data Portability. You have the right to receive the
                Personal Data you have provided to us in an electronic format
                and to transmit the Personal Data to another data controller.
              </p>
            </li>
            <li>
              <p>
                Right to complain. You may lodge a complaint with a data
                protection supervisory authority.
              </p>
            </li>
          </ul>
          <p>
            <strong>C. Auto decision-making.</strong> We may engage in automated
            decision-making for purposes of risk and fraud detection. When we
            do, we implement suitable measures to safeguard your rights and
            freedoms and legitimate interests, including the right to obtain
            human intervention, to express your point of view and to contest the
            decision.
          </p>
          <p>
            <strong>D. Transfers of Personal Data out of the EEA.</strong> JRN
            enters into appropriate data processing agreements and standard
            contractual clauses approved by the European Commission for the
            transfer of data. We may transfer Personal Data from the EEA to
            countries outside of the EEA where the transfers are necessary to
            satisfy our obligations to you under our terms of service (including
            providing you with our Services and to optimize and enhance the JRN
            Platform) and where you have consented to the transfer of your
            Personal Data to a third country.
          </p>
          <p>
            <strong>19. INFORMATION SECURITY</strong>
          </p>
          <p>
            For users who are located in Singapore, we adhere to the PDPA and
            provide Singapore residents with the following additional
            information. For the purposes of this section, “
            <strong>Personal Data</strong>” has the meaning provided in section
            2 of the PDPA.{" "}
          </p>
          <p>
            <strong>A. Transfers of Personal Data out of Singapore.</strong> If
            your personal data has been processed in Singapore, prior to
            transferring such personal data from Singapore to a jurisdiction or
            territory outside Singapore, JRN will generally take appropriate
            steps to ensure that the recipient of the personal data is bound by
            legally enforceable obligations to provide to the transferred
            personal data a standard of protection that is at least comparable
            to the protection under the PDPA (“Comparable Standard”). To this
            end, JRN will ensure that at least one of the following measures is
            implemented:
          </p>
          <ul>
            <li>
              <p>
                you consented to such transfer after you have been given a
                reasonable summary in writing of the extent to which your
                personal data to be transferred will be protected to a
                Comparable Standard (including but not limited to this Privacy
                Policy);
              </p>
            </li>
            <li>
              <p>
                the overseas recipient of such personal data is bound by law,
                contract, binding corporate rules or any other legally binding
                instrument to protect the transferred personal data to a
                Comparable Standard; and/or
              </p>
            </li>
            <li>
              <p>
                the overseas recipient of such personal data holds a valid
                certification under the Asia Pacific Economic Cooperation Cross
                Border Privacy Rules (“APEC CBPR”) System or the Asia Pacific
                Economic Cooperation Privacy Recognition for Processors (“APEC
                PRP”) System.
              </p>
            </li>
          </ul>
          <p>
            Where the above measures are not feasible, JRN may still proceed
            with the transfer of your personal data from Singapore to a
            recipient outside of Singapore if:
          </p>
          <ul>
            <li>
              <p>
                the transfer is necessary for a use or disclosure that is in
                your vital interests or in the national interest and JRN has
                taken reasonable steps to ensure that the personal data will not
                be used or disclosed by the recipient for any other purpose;
                and/or
              </p>
            </li>
            <li>
              <p>
                the transfer is reasonably necessary for the conclusion or
                performance of a contractual obligation between you and JRN.
              </p>
            </li>
          </ul>
          <p>
            <strong>B. Security of your Personal Data.</strong> If we have
            credible grounds to believe that a data breach has occurred, we will
            take steps to assess whether the data breach is notifiable under the
            PDPA. Once we assess that a data breach is a notifiable data breach,
            we will notify the Personal Data Protection Commission of Singapore
            and you as soon as it is practicable. If we share your personal data
            with our third party service providers, we will require them to
            process it strictly in accordance with our instructions or as
            otherwise required by the PDPA.
          </p>
          <p>
            <strong>20. INFORMATION SECURITY</strong>
          </p>
          <p>
            For users who are located in Hong Kong, we adhere to the PDPO and
            provide Hong Kong residents with the following additional
            information. For the purposes of this section, “
            <strong>Personal Data</strong>” has the meaning provided in section
            2 of the PDPO.{" "}
          </p>
          <p>
            <strong>A. Transfers of Personal Data out of Hong Kong.</strong> JRN
            may transfer your Personal Data from Hong Kong to a jurisdiction or
            territory outside Hong Kong on any of the following grounds:
          </p>
          <ul>
            <li>
              <p>
                JRN has reasonable grounds for believing that there is in force
                in that jurisdiction or territory any law which is substantially
                similar to, or serves the same purposes as the PDPO;
              </p>
            </li>
            <li>
              <p>
                you have consented in writing to such transfer (which you hereby
                grant if you have not previously done so);
              </p>
            </li>
            <li>
              <p>
                JRN has reasonable grounds for believing that, in all the
                circumstances of the&nbsp;case:
              </p>
            </li>
          </ul>
          <p>
            (a) such transfer is for the avoidance or mitigation of adverse
            action against you;
          </p>
          <p>
            (b) it is not practicable to obtain your written consent to such
            transfer; and
          </p>
          <p>
            (c) if it was practicable to obtain such consent, you would give it;
          </p>
          <ul>
            <li>
              your Personal Data is exempt from data protection principle 3 (as
              defined in Schedule 1 to the PDPO) by virtue of an exemption
              under&nbsp;Part 8 of the PDPO.
            </li>
          </ul>
          <p>
            JRN has taken all reasonable precautions and exercised all due
            diligence to ensure that your Personal Data will not, in that place,
            be collected, held, processed or used in any manner which, if that
            place were Hong Kong, would be a contravention of a requirement
            under the PDPO.
          </p>
        </div>
      </div>
    </div>
  );
}
