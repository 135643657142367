import React from 'react'
import { Chip, Stack } from "@mui/material";


export default function CustomFilter(props) {

  return (
    <Stack direction="row" spacing={1} className="d-flex flex-wrap">
    {
        props.filters.map((elem,index)=>{
            return (
                <Chip
                key={index}
                label={elem.label}
                onClick={() => {
                  elem.onclick(elem.label);
                }}
                className={`mr-1 ${
                  props.mode === "lightMode" ? "text-dark" : "text-white"
                }`}
              />
            )
        })
    }
</Stack>
  )
}
