import React,{useEffect} from 'react'
import './App.css';
import { createBrowserRouter, createRoutesFromChildren, matchRoutes, RouterProvider, Routes, useLocation, useNavigationType, useRoutes } from 'react-router-dom';
import { Provider } from 'react-redux';
import projectStore from "./store/Store";
import 'react-loading-skeleton/dist/skeleton.css'
import TraceProvider from './tracing';
import { getWebInstrumentations, initializeFaro, LogLevel, ReactIntegration, ReactRouterVersion, WebVitalsInstrumentation, withFaroRouterInstrumentation } from '@grafana/faro-react';
import { TracingInstrumentation } from '@grafana/faro-web-tracing';
import { routes } from './config/Routes/Routes';
import { REACT_APP_NEW_CLOUD_GRAFANA_URL } from './config/API/Api';
function App() {
 
  const reactRouter = createBrowserRouter(routes);
  const instrumentedRouter = withFaroRouterInstrumentation(reactRouter);
  const initialRegistrationforTraces=()=>{
    initializeFaro({
      url:REACT_APP_NEW_CLOUD_GRAFANA_URL,
      app: {name: 'jrn',version:"0.1.0"},
      instrumentations: [
        // mandatory, overwriting the instrumentations array would cause the default instrumentations to be omitted
        ...getWebInstrumentations({
          captureConsole: true,
          captureConsoleDisabledLevels: [
            LogLevel.DEBUG,
            LogLevel.INFO,
            LogLevel.LOG,
          ],
          enablePerformanceInstrumentation: true,
        }),
        new TracingInstrumentation(),
        new ReactIntegration({
          router: {
            version: ReactRouterVersion.V6,
            dependencies: {
              createRoutesFromChildren,
              matchRoutes,
              Routes,
              useLocation,
              useNavigationType,
            },
          },
        }),
        
      ],
      // transports: [new ConsoleTransport()],
      logging: {          
        level: 'debug',
      },
    });
  }
  useEffect(()=>{
    initialRegistrationforTraces();
    },[])
  return (
    <TraceProvider>
    <Provider store={projectStore}>
      <RouterProvider router={instrumentedRouter}>
      </RouterProvider>
    </Provider>
  </TraceProvider>
  );
}

export default App;
