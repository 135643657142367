import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Link, useParams, useLocation } from "react-router-dom";
import { FaTwitter, FaMask } from "react-icons/fa";
import { FaWordpressSimple, FaFacebookF } from "react-icons/fa6";
import { MdPrivacyTip } from "react-icons/md";
const DetailsPage = () => {
  const mode = useSelector((state) => state.themeType.themeType);
  const [windowWidth, setwindowWidth] = useState(window.innerWidth);
  const socialMediaIcons = (size) => [
    <FaWordpressSimple
      key="FaWordpressSimple"
      size={size}
      color="black"
      className="mx-4 my-3"
    />,
    <FaFacebookF
      key="FaFacebookF"
      size={size}
      color="black"
      className="mx-4 my-3"
    />,
    <FaTwitter key="twitter" size={size} color="black" className="mx-4 my-3" />,
    <FaMask key="mask" size={size} color="black" className="mx-4 my-3" />,
    <MdPrivacyTip
      key="shield"
      size={size}
      color="black"
      className="mx-4 my-3"
    />,
  ];
  const route = useParams();
  const navigation = useLocation();
  const { item } = navigation.state;
  const renderFirstSection = () => {
    return (
      <section className="pt-3">
        <div className="row">
          <div className="col">
            <nav aria-label="breadcrumb">
              <ol
                className={`breadcrumb  pl-0 ${
                  mode === "lightMode"
                    ? "bg-white text-black"
                    : "bg-black text-white"
                }`}
              >
                <li className="breadcrumb-item" style={{ color: "#909090" }}>
                  Support center
                </li>
                <li className={` breadcrumb-item active`} aria-current="page">
                  {route.category === undefined ? (
                    "Annoucements"
                  ) : (
                    <Link to="/announcements" style={{ color: "#909090" }}>
                      Announcements
                    </Link>
                  )}
                </li>
                {route.category !== undefined && (
                  <li
                    className={` breadcrumb-item ${
                      mode === "lightMode" ? "text-black" : "text-white"
                    } `}
                    aria-current="page"
                  >
                    {route?.category}
                  </li>
                )}
                {route.categoryChild !== undefined && (
                  <li
                    className={` breadcrumb-item ${
                      mode === "lightMode" ? "text-black" : "text-white"
                    } `}
                    aria-current="page"
                  >
                    {route?.categoryChild}
                  </li>
                )}
              </ol>
            </nav>
          </div>
        </div>
        <div
          className={`my-2 ${
            mode === "lightMode" ? "bg-white text-black" : "bg-black text-white"
          }`}
        >
          <h1 className="font-44 font-weight-bold ">{item?.title}</h1>
          <p className="font-16 text-secondary my-4">{item?.desc}</p>
        </div>
      </section>
    );
  };
  const renderdescription = () => {
    return (
      <div className="py-5 w-75">
        <p>
          Due to the EM wallet upgrade, deposits and withdrawals for EM on JRN
          will be suspended from 8:00 am UTC on Feb. 15, 2023 until the upgrade
          is complete.
        </p>
        <p>
          After the upgrade is complete, the new recharge address starts with
          "0x", and the address starting with "EM" is no longer supported.
        </p>
        <p>We apologize for any inconvenience caused during this period.</p>
        <p>
          Thank you for your continued support and we assure you of our best
          services at all times. JRN
        </p>
        <p>Feb. 15, 2023 (UTC)</p>
      </div>
    );
  };
  useEffect(() => {
    const handleResize = () => {
      setwindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <section
      className={`${mode === "lightMode" ? "bg-white" : "bg-black"}`}
      style={{ padding: windowWidth < 1024 ? "0 80px" : "0 140px" }}
    >
      {renderFirstSection()}
      <div className="d-flex flex-wrap">{socialMediaIcons(17)}</div>
      {renderdescription()}
    </section>
  );
};

export default DetailsPage;
