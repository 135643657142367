import React from 'react';
import {
  ComposedChart,
  Line,
  Area,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';

const data = [
  {
    name: 'Page A',
    uv: 590,
    pv: 800,
    amt: 1400,
  },
  {
    name: 'Page B',
    uv: 868,
    pv: 967,
    amt: 1506,
  },
  {
    name: 'Page C',
    uv: 1397,
    pv: 1098,
    amt: 989,
  },
  {
    name: 'Page D',
    uv: 1480,
    pv: 1200,
    amt: 1228,
  },
  {
    name: 'Page E',
    uv: 1520,
    pv: 1108,
    amt: 1100,
  },
  {
    name: 'Page F',
    uv: 1400,
    pv: 680,
    amt: 1700,
  },
];

export default function CustomComposeChart(props) {
    console.log(props.data,'this is data')
    return (
        <ResponsiveContainer width="100%" height={props.chartContainerHeight}>
        <ComposedChart
          layout={props.layout}
          width={props.chartWidth}
          data={props.data===undefined||props.data===null?data:props.data}
          margin={{
            top: 20,
            right: 20,
            bottom: 20,
            left: 20,
          }}
          barCategoryGap={1}
          barGap={1}
        >
          <XAxis type={props.xtype} dataKey={props.xDatakey} />
          <YAxis dataKey={props.yDatakey} type={props.yType} scale={props.scale===undefined?"band":props.scale} />
          <Tooltip />
          <Bar dataKey="value" barSize={20} fill="#413ea0" className='pointer'  />
        </ComposedChart>
      </ResponsiveContainer>
    );
  
}

