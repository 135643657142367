import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {  useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import Ctabs from "./Ctabs";
import { Pie, PieChart, Line, Bar ,Legend} from "recharts";
// import Moralis from 'moralis';
import ReactSpeedometer from "react-d3-speedometer";
import CustomProgressBar from "../../component/Charts/CustomProgressBar";
import { Chip, Stack } from "@mui/material";
import CustomLineCharts from "../../component/Charts/CustomLineCharts";
import CustomLine_volume from "../../component/Charts/CustomLine_volume";
import CustomLineVolume from "../../component/Charts/CustomLineVolume";
import CustomFilter from "../../component/Charts/CustomFilter";
import CustomPiechart from "../../component/Charts/CustomPiechart";
import CustomComposeChart from "../../component/Charts/CustomComposeChart";

export default function Portfolio() {
  const mode = useSelector((state) => state.themeType.themeType);
  const [value, setValue] = useState("one");
  const navigate=useNavigate();
  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token || token === "") {
      navigate("/");
      console.log("helo bhai");
    }
  }, []);

  const handleChange = (newvalue) => {
    setValue(newvalue);
  };

  const data = [
    {
      name: "Page A",
      uv: 4000,
      pv: 2400,
      amt: 2400,
      cnt: 10000,
    },
    {
      name: "Page B",
      uv: 3000,
      pv: 1398,
      amt: 2210,
      cnt: 8000,
    },
    {
      name: "Page C",
      uv: 2000,
      pv: 9800,
      amt: 2290,
      cnt: 7500,
    },
    {
      name: "Page D",
      uv: 2780,
      pv: 3908,
      amt: 2000,
      cnt: 6500,
    },
    {
      name: "Page E",
      uv: 1890,
      pv: 4800,
      amt: 2181,
      cnt: 5500,
    },
    {
      name: "Page F",
      uv: 2390,
      pv: 3800,
      amt: 2500,
      cnt: 6600,
    },
    {
      name: "Page G",
      uv: 3490,
      pv: 4300,
      amt: 2100,
      cnt: 7200,
    },
    {
      name: "Page G",
      uv: 3490,
      pv: 4300,
      amt: 2100,
      cnt: 5000,
    },
    {
      name: "Page G",
      uv: 3490,
      pv: 4300,
      amt: 2100,
      cnt: 4800,
    },
    {
      name: "Page G",
      uv: 3490,
      pv: 4300,
      amt: 2100,
      cnt: 6900,
    },
    {
      name: "Page G",
      uv: 3490,
      pv: 4300,
      amt: 2100,
      cnt: 7700,
    },
  ];

  const Compo = () => {
    return (
      <div>
        <div className="row border border-white rounded ">
          <div
            className={`col-12 d-flex flex-column justify-content-center py-4 ${
              mode === "lightMode" ? "bg-white text-dark" : "bg-dark text-white"
            }`}
          >
            <h6>Barometer</h6>
            <div className="row d-flex justify-content-between">
              <div
                className={`col-sm-10 col-md-4 ml-1  ${
                  mode === "lightMode"
                    ? "bg-white text-dark"
                    : "bg-black text-white"
                } p-2`}
              >
                <p className={`text-secondary`}>Market Sentiment</p>
                <div className="d-flex flex-wrap justify-content-between align-items-center">
                  <div style={{ width: "200px", height: "200px" }}>
                    <ReactSpeedometer
                      maxValue={100}
                      value={36}
                      ringWidth={10}
                      needleColor="red"
                      startColor="green"
                      segments={2}
                      segmentColors={["#5177FF", "#e8ac4a", "#ed6852"]}
                      endColor="blue"
                    />
                  </div>
                  <h2 className="">36</h2>
                </div>
              </div>

              <CustomProgressBar
                mainContainerclassName="col-sm-10 col-md-3 mx-1   p-2 justify-content-between d-flex flex-column"
                title="Traders(24H)"
                bottomobj={[
                  { title: "Buyers", value: "5,935" },
                  { title: "Sellers", value: "6,365" },
                ]}
              >
                <div
                  className="progress-bar bg-success"
                  style={{ width: "25%" }}
                >
                  25%
                </div>
                <div
                  className="progress-bar bg-transparent"
                  style={{ width: "3%" }}
                ></div>
                <div
                  className="progress-bar bg-danger"
                  style={{ width: "72%" }}
                >
                  75%
                </div>
              </CustomProgressBar>
              <CustomProgressBar
                mainContainerclassName="col-sm-10 mr-1 col-md-4 p-2 justify-content-between d-flex flex-column"
                title="30D Profit & Loss"
                bottomobj={[
                  { title: "😊", value: "60,035" },
                  { title: "😥", value: "432,514" },
                ]}
              >
                <div
                  className="progress-bar bg-success"
                  style={{ width: "25%" }}
                >
                  25%
                </div>
                <div
                  className="progress-bar bg-transparent"
                  style={{ width: "3%" }}
                ></div>
                <div
                  className="progress-bar bg-danger"
                  style={{ width: "72%" }}
                >
                  75%
                </div>
              </CustomProgressBar>
            </div>
          </div>
        </div>
        <SecondCompo />
        <ThirdCompo />
        <FourthCompo />
        <FifthCompo />
        <SixthCompo/>
      </div>
    );
  };

  const SecondCompo = () => {
    return (
      <div
        className={`row my-4 py-4 ${
          mode === "lightMode" ? "bg-white text-dark" : "bg-dark text-white"
        } `}
      >
        <div className="col">
          <div className="d-flex justify-content-between">
            <div>
              <p>Blue Chip Index</p>
            </div>

            <Stack direction="row" spacing={1} className="d-flex flex-wrap">
              <Chip
                label="24H"
                onClick={() => {
                  console.log("hello abhi");
                }}
                className={`mr-1 ${
                  mode === "lightMode" ? "text-dark" : "text-white"
                }`}
              />
              <Chip
                label="7D"
                variant="outlined"
                onClick={() => {
                  console.log("prince");
                }}
                className={`mr-1 ${
                  mode === "lightMode" ? "text-dark" : "text-white"
                }`}
              />
              <Chip
                label="30D"
                variant="outlined"
                onClick={() => {
                  console.log("prince");
                }}
                className={`mr-1 ${
                  mode === "lightMode" ? "text-dark" : "text-white"
                }`}
              />
              <Chip
                label="3M"
                variant="outlined"
                onClick={() => {
                  console.log("prince");
                }}
                className={`mr-1 ${
                  mode === "lightMode" ? "text-dark" : "text-white"
                }`}
              />
              <Chip
                label="1Y"
                variant="outlined"
                onClick={() => {
                  console.log("prince");
                }}
                className={`mr-1 ${
                  mode === "lightMode" ? "text-dark" : "text-white"
                }`}
              />
              <Chip
                label="All"
                variant="outlined"
                onClick={() => {
                  console.log("prince");
                }}
                className={`mr-1 ${
                  mode === "lightMode" ? "text-dark" : "text-white"
                }`}
              />
            </Stack>
          </div>
          <div>
            <CustomLineCharts xaxisTitlekey="name" data={data}>
              <Line
                type="monotone"
                dataKey="pv"
                stroke="#8884d8"
                activeDot={{ r: 8 }}
              />
            </CustomLineCharts>
          </div>
        </div>
      </div>
    );
  };

  const ThirdCompo = () => {
    return (
      <div
        className={`row my-4 py-4 ${
          mode === "lightMode" ? "bg-white text-dark" : "bg-dark text-white"
        } `}
      >
        <div className="col">
          <div className="d-flex justify-content-between">
            <div>
              <p>Market Cap & Volume</p>
              <div className="d-flex flex-wrap">
                <span
                  className={`rounded mx-2 p-2 ${
                    mode === "lightMode"
                      ? "bg-light text-dark"
                      : "bg-black text-white"
                  }`}
                >
                  <p className="text-secondary">Market Cap</p>
                  <p className="m-0 p-0">
                    3.77M{" "}
                    <span className="text-danger font-weight-bold m-1">
                      -2.79%
                    </span>
                  </p>
                </span>
                <span
                  className={`rounded mx-2 p-2 ${
                    mode === "lightMode"
                      ? "bg-light text-dark"
                      : "bg-black text-white"
                  }`}
                >
                  <p className="text-secondary">Volumne (7D)</p>
                  <p className="m-0 p-0">
                    84.33K{" "}
                    <span className="text-danger font-weight-bold m-1">
                      -23.58%
                    </span>
                  </p>
                </span>
              </div>
            </div>

            <Stack direction="row" spacing={1} className="d-flex flex-wrap">
              <Chip
                label="24H"
                onClick={() => {
                  console.log("hello abhi");
                }}
                className={`mr-1 ${
                  mode === "lightMode" ? "text-dark" : "text-white"
                }`}
              />
              <Chip
                label="7D"
                variant="outlined"
                onClick={() => {
                  console.log("prince");
                }}
                className={`mr-1 ${
                  mode === "lightMode" ? "text-dark" : "text-white"
                }`}
              />
              <Chip
                label="30D"
                variant="outlined"
                onClick={() => {
                  console.log("prince");
                }}
                className={`mr-1 ${
                  mode === "lightMode" ? "text-dark" : "text-white"
                }`}
              />
              <Chip
                label="3M"
                variant="outlined"
                onClick={() => {
                  console.log("prince");
                }}
                className={`mr-1 ${
                  mode === "lightMode" ? "text-dark" : "text-white"
                }`}
              />
              <Chip
                label="1Y"
                variant="outlined"
                onClick={() => {
                  console.log("prince");
                }}
                className={`mr-1 ${
                  mode === "lightMode" ? "text-dark" : "text-white"
                }`}
              />
              <Chip
                label="All"
                variant="outlined"
                onClick={() => {
                  console.log("prince");
                }}
                className={`mr-1 ${
                  mode === "lightMode" ? "text-dark" : "text-white"
                }`}
              />
            </Stack>
          </div>
          <div>
            <CustomLine_volume xaxisTitlekey="name">
              <Bar
                dataKey="uv"
                barSize={20}
                fill="#413ea0"
                className="pointer"
              />
              <Bar dataKey="cnt" barSize={20} fill="#blue" />
              <Line type="monotone" dataKey="pv" stroke="#ff7300" />
            </CustomLine_volume>
          </div>
        </div>
      </div>
    );
  };

  const FourthCompo = () => {
    return (
      <CustomLineVolume
        header="Holders & Traders"
        box={[
          {
            title: "Holders",
            value: "4,517,532",
            value1: "+0.14%",
            value1Class: "text-success",
          },
          {
            title: "Traders (7D)",
            value: "50,948",
            value1: "-13.14%",
            value1Class: "text-danger",
          },
          {
            title: "Buyers",
            value: "4,517,532",
            value1: "+0.14%",
            value1Class: "text-success",
          },
          {
            title: "Sellers",
            value: "17,532",
            value1: "-0.14%",
            value1Class: "text-danger",
          },
        ]}
        filters={[
          {
            label: "24H",
            onclick: (val) => {
              console.log("value is ", val);
            },
          },
          {
            label: "30D",
            onclick: (val) => {
              console.log("value is ", val);
            },
          },
          {
            label: "6M",
            onclick: (val) => {
              console.log("value is ", val);
            },
          },
          {
            label: "1Y",
            onclick: (val) => {
              console.log("value is ", val);
            },
          },
        ]}
        data={data}
        xaxisTitlekey="name"
        xDatakey="name"
      >
        <Bar dataKey="uv" barSize={20} fill="#2276fc" />
        <Bar dataKey="amt" barSize={20} fill="#5fd5ec" />
        <Line type="monotone" dataKey="pv" stroke="#ff7300" />
        <Line type="monotone" dataKey="cnt" stroke="yellow" />
      </CustomLineVolume>
    );
  };
  const FifthCompo = () => {
    return (
      <CustomLineVolume
        header="Category Market Cap"
        box={[]}
        filters={[
          {
            label: "Market Cap",
            onclick: (val) => {
              console.log("value is ", val);
            },
          },
          {
            label: "Volume",
            onclick: (val) => {
              console.log("value is ", val);
            },
          },
          {
            label: "Liquidity",
            onclick: (val) => {
              console.log("value is ", val);
            },
          },
        ]}
        data={data}
        xaxisTitlekey="name"
        xDatakey="name"
      >
        <Bar dataKey="uv" barSize={20} fill="#2276fc" className="pointer" />
      </CustomLineVolume>
    );
  };

  const SixthCompo = () => {
    const data = [
      { name: 'A1', value: 100 },
      { name: 'A2', value: 300 },
      { name: 'B1', value: 100 },
      { name: 'B2', value: 80 },
      { name: 'B3', value: 40 },
      { name: 'B4', value: 30 },
      { name: 'B5', value: 50 },
      { name: 'C1', value: 100 },
      { name: 'C2', value: 200 },
      { name: 'D1', value: 150 },
      { name: 'D2', value: 50 },
    ];


    const chartData=[
      {name:"CryptoPunks",value:100,amt:"0"},
      {name:"Bored Ape",value:200,amt:"100k"},
      {name:"Async Blue",value:150,amt:"200k"},
      {name:"Azuki",value:120,amt:'300k'},
      {name:"Mutent ape",value:170,amt:'400k'},
      {name:"Art Blocks",value:120,amt:'500k'},
      {name:"The Captain",value:80,amt:'600k'},
      {name:"DeathBatt",value:90,amt:'700k'},
    ]


    return (
      <div className="row d-flex justify-content-center">
        <div
          className={`col-sm-10 col-md-5 m-2 rounded ${
            mode === "lightMode" ? "bg-light text-dark" : "bg-dark text-white"
          }`}
        >
          <div className="d-flex justify-content-between m-2 flex-wrap">
            <p>Collection Distribution</p>
            <CustomFilter
            mode={mode}
            filters={[
              {
                label:"Market Cap",
                onclick:(val)=>{
                  console.log(val,'this is value you pressed');
                }
              },
              {
                label:"Volume",
                onclick:(val)=>{console.log(val,'thiis is val you chlicked')}
              }
            ]}
            />
          </div>

          <div >
            <CustomPiechart
            data={data}
            />
          </div>
          


        </div>
        <div className={`col-sm-10 col-md-6 m-2 rounded ${
            mode === "lightMode" ? "bg-light text-dark" : "bg-dark text-white"
          }`}>
          <div className="d-flex justify-content-between pt-2">
           <h2>Top Collection</h2>
           <CustomFilter 
           filters={[
            {
              label:"Market Cap",
              onclick:(val)=>{console.log(val,'this is bal')}
            },
            {
              label:"Volume",
              onclick:(val)=>{console.log(val,'this is bal')}
            },
           ]}
           />
          </div>
          <div>
            <CustomComposeChart
            chartContainerHeight={300}
            layout="vertical"
            chartWidth={"95%"}
            chartHeight={400}
            data={chartData}
            xtype="category"
            xDatakey="value"
            xScale=""
            yType="category"
            yDatakey="name"
            Yscale="band"
            mode={mode}
            >

            </CustomComposeChart>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div
      className={`${
        mode === "lightMode" ? "bg-light text-dark" : "bg-black text-white"
      }`}
    >
      <ToastContainer
        position="top-center"
        autoClose={3000}
        closeOnClick
        theme={mode === "lightMode" ? "light" : "dark"}
      />
      <div className="">
        <div className="mx-2 container">
          <h1>Market Overview</h1>
          <p>
            Real time global NFT market data. We have included{" "}
            <span className="text-primary">5,919</span> collections and{" "}
            <span className="text-primary">44,918,481</span> NFTs in total.
          </p>
        </div>

        <div className="row mx-2 ">
          <div className="col">
            <Ctabs component={Compo} />
          </div>
        </div>
      </div>
    </div>
  );
}
