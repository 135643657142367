import { toast } from "react-toastify";
import detectEthereumProvider from "@metamask/detect-provider";
import axios from 'axios'
import { ethers } from "ethers";
import { trace } from "firebase/performance";
import { sendPerfomance } from "../..";
import { tracer } from "../../tracing";
import { REACT_APP_EMAIL_BASE_URL } from "../../config/API/Api";
  

export const VerifySignature=async(user)=>{
console.log(user,'this is user')
let parseData={};
const data={
  address:"",
  session:"",
  signature:""
}
let message=""
try {
  const provider = await detectEthereumProvider();
  const url=REACT_APP_EMAIL_BASE_URL+'metamasklogin'
  const Confirmurl=REACT_APP_EMAIL_BASE_URL+'confirmMetamasklogin'
  if (provider) {
    if(user===""||user===undefined){
      const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
      const account = accounts[0];
      data.address=account;
      const t=trace(sendPerfomance,'Metamask_Login');
      const performance=window.performance;
      performance.mark('metamask_login_mesurement_start');
      try {
        const span=tracer.startSpan('Metamask_Login_API')
        t.start()
        await axios.post(url,{address:account}).then((res)=>{span.addEvent('api_response',{status:res.status,data:res.data,url})
         if(res.status===200){
          data.session=res.data?.Session;
          message=res.data?.nonce;
         }
        }).catch((err)=>{
          toast.error(err.response.data.message);
          span.setStatus({code:'ERROR' , message : err?.message});
          span.end()
        }).finally(()=>{
          performance.mark("metamask_login_mesurement_stop");
          performance.measure('Metamask_Login','metamask_login_mesurement_start','metamask_login_mesurement_stop')
          t.putAttribute('page','/metamask-Login');
          t.stop();
          span.end();
        })
      } catch (error) {
        console.log(error,'this iserror 2')
        toast.error(error.message)
      }

    }else{

      data.address=user;
  
      try {
      const t=trace(sendPerfomance,'Metamask_Login');
      const span=tracer.startSpan('Metamask_Login_API')
      t.start()
        await axios.post(url,{
          address:user
         }).then((res)=>{
         if(res.status===200){
          span.addEvent('api_response',{
            status:res.status,data:res.data,url
          })
          data.session=res.data?.Session;
          message=res.data?.nonce;
         }
        }).catch((err)=>{
          toast.error(err.response.data.message);
          span.setStatus({code:'ERROR' , message : err?.message});
          span.end()
        }).finally(()=>{
          t.stop();
          span.end()
        })
      } catch (error) {
        console.log(error,'this iserror 2')
        toast.error(error.message)
      }
    }




 

    console.log(message,'this is message')
    if(message!==""){
      const signature = await window.ethereum.request({
        method: 'personal_sign',
        params: [ethers.hexlify(ethers.toUtf8Bytes(message)), user],
      });
      if(signature!==""){
        data.signature=`${signature}`;

        console.log(signature,'this s signature',data);
        try {
        const t=trace(sendPerfomance,'MetamaskConfirmRequest')
        const span=tracer.startSpan('MetamaskConfirmRequest_api')
        t.start();
          await axios.post(Confirmurl,data).then(async(res)=>{
            span.addEvent('api_response',{status:res.status,data:res.data,url:Confirmurl})
            if(res.status===200){

              console.log('this is verify data',res.data?.data.AuthenticationResult.AccessToken,'this is response') 
              console.log(data,'this is data');
              localStorage.setItem('accessToken',res.data?.data.AuthenticationResult.AccessToken);
              localStorage.setItem('userAddress',data.address);
              toast.success(res.data?.message);
              parseData=res.data;
            }
          }).catch((err)=>{
            toast.error(err.message);
            span.setStatus({code:'ERROR' , message : err?.message});
            span.end()
          }).finally(()=>{
            t.stop();
            span.end();
          })
        } catch (error) {
          toast.error(error.message)
        }

      }else{
        toast.warning('we did\'nt get the signature');
        return 0;
      }
    }
    
  } else {
    toast.warning('Metamask not found! please install MetaMask');
  }
} catch (error) {
  toast.error(error.message);
  console.error('Error generating signature:', error);
}
return parseData;
}


export const metamaskregisteruser=async(user)=>{
  let parseData={};
  const data={
    address:"",
    session:"",
    signature:""
  }
  let message=""
  const registerurl=REACT_APP_EMAIL_BASE_URL+'metamaskregister'
  const verfiySignatureUrl=REACT_APP_EMAIL_BASE_URL+'confirmMetamaskRegister'
  try {
    const provider = await detectEthereumProvider();
    if (provider) {
      data.address=user;
      try {
        const t=trace(sendPerfomance,'MetamaskRegisterRequest')
        const span=tracer.startSpan('MetamaskRegisterRequest_api')
        t.start();
        await axios.post(registerurl,{
          address:user
         }).then((res)=>{
          span.addEvent('api_response',{status:res.status,data:res.data,url:registerurl})
         if(res.status===200){
          data.session=res.data?.Session;
          message=res.data?.nonce;
         }
        }).catch((err)=>{
          toast.error(err.response.data.message);
          span.setStatus({code:'ERROR' , message : err?.message});
          span.end()
        }).finally(()=>{
          t.stop();
          span.end();
        })
      } catch (error) {
        toast.error(error.message)
      }
  
      // console.log(message,'this is message')
      if(message!==""){
        const signature = await window.ethereum.request({
          method: 'personal_sign',
          params: [ethers.hexlify(ethers.toUtf8Bytes(message)), user],
        });

        if(signature!==""){
          data.signature=`${signature}`;
          try {
            const t=trace(sendPerfomance,'MetamaskRegisterVerifyRequest')
            const span=tracer.startSpan('MetamaskRegisterVerifyRequest_api')
            t.start();
            await axios.post(verfiySignatureUrl,data).then(async(res)=>{
              if(res.status===200){
                span.addEvent('api_response',{status:res.status,data:res.data,url:verfiySignatureUrl});
                localStorage.setItem('userAddress',res.data.data.address);
                toast.success(res.data?.message);
                parseData=res.data;
              }
            }).catch((err)=>{
              toast.error(err.message);
              span.setStatus({code:'ERROR' , message : err?.message});
             span.end()
            }).finally(()=>{
              t.stop()
              span.end();
            })
          } catch (error) {
            toast.error(error.message)
          }
  
        }else{
          toast.warning('we did\'nt get the signature');
          return 0;
        }
      }
      
    } else {
      toast.warning('Metamask not found! please install MetaMask');
    }
  } catch (error) {
    toast.error(error.message);
    console.error('Error generating signature:', error);
  }
  return parseData;
  }
  
  