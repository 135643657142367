import { darkthemeactionType,lightthemeactionType ,changeuserIdType,change_eth_balanceType, collection_filterType} from "../ActionType";
import { initialState } from "../../store/AllState";

export const themeReducer=(state=initialState,action)=>{

    switch (action.type){
        case darkthemeactionType: return {
            ...state,themeType:"darkMode"
        }
        case lightthemeactionType: return {
            ...state,themeType:"lightMode"
        }

        case changeuserIdType: return {
            ...state,userId:action.payload
        }

        default: return state
    }
}

export const paymentwork=(state=initialState,action)=>{
    switch(action.type){
        case changeuserIdType: {
            return {
                ...state,userId:action.payload
            }
        }
        default: return state;
    }
}

export const usersData=(state=initialState,action)=>{
    switch(action.type){
        case change_eth_balanceType:return {
        ...state,eth_balance:action.payload
        }
        default: return state;
    }
}
export const Collection_Filter=(state=initialState,action)=>{
    switch(action.type){
        case collection_filterType:return {
        ...state,collectionFilter:action.payload
        }
        default: return state;
    }
}

export const userAddress=(state=initialState,action)=>{
return {...state,userAddress:action.payload};
}