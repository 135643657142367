import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { FiChevronDown } from "react-icons/fi";
import { Link } from "react-router-dom";
import { trackButtonClick } from '../utils/utility/GAnalytics';

export default function CustomMuiAccordian(props) {
    const {isExpended,title,mode,innerItem,handleClose}=props
  return (
      <Accordion defaultExpanded={isExpended} className='bg-transparent nav-md-link panelItem w-100 inner-link-in-mobile' >
        <AccordionSummary
          expandIcon={<FiChevronDown size={20} color={mode==='lightMode'?"#797979":"white"}/>}
          aria-controls="panel1-content"
          id="panel1-header"
          className='pl-0 bg-transparent my-0'
        >
          <Typography  className={`my-0 navaBar-links nav-link font-weight-bold mobile-link-text ${mode==='lightMode'?"nav-link-color":"text-white"}`}>{title}</Typography>
        </AccordionSummary>
        <AccordionDetails className='pr-0 py-0'>
            {innerItem.map((elem,index)=>(
                <Typography className='inner-link-in-mobile' key={index}>
                     <Link  className={`navaBar-links nav-link ${mode==='lightMode'?"nav-link-color":"inner-mobile-link-text"}`} to={elem.navigate_to!==undefined?elem?.navigate_to:'/'} onClick={()=>{trackButtonClick('Navigation','Nav bar link clicked','Leader board');
                        if(handleClose!==undefined){
                          handleClose(false);
                        }
                     }}
                    >{elem?.title}</Link>
                </Typography>
            ))}
        </AccordionDetails>
      </Accordion>
  );
}
