import React from "react";
import {
  PieChart,
  Pie,
  Sector,
  Cell,
  ResponsiveContainer,
  Legend,
} from "recharts";
export default function CustomPiechart(props) {
  const RADIAN = Math.PI / 180;
  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    index,
  }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text
        x={x}
        y={y}
        fill="white"
        textAnchor={x > cx ? "start" : "end"}
        dominantBaseline="central"
      >
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    );
  };
  const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042"];

  const RenderLegend = (props) => {
    const { payload } = props;

    console.log("hello", payload, "this is ");

    return (
      <ul className="legend " >
        {payload.map((entry, index) => (
          <li
            key={`item-${index}`}
            style={{ display: "flex", alignItems: "center" }}
          >
            <span
              style={{
                backgroundColor: entry.color,
                width: "10px",
                height: "10px",
                marginRight: "5px",
              }}
            ></span>
            <span>{entry.value}</span>
          </li>
        ))}
      </ul>
    );
  };

  return (
    <ResponsiveContainer width={"99%"} height={300}>
      <PieChart width={"95%"} height={200}
      margin={{
        top: 5,
        right: 30,
        left: 20,
        bottom: 5,
      }}
      >
        <Pie
          data={props.data}
          cx="50%"
          cy="50%"
          innerRadius={40}
          outerRadius={100}
          labelLine={false}
          label={renderCustomizedLabel}
          
        >
          {props.data.map((elem, index) => {
            return (
              <Cell
                dataKey={elem.value}
                key={index}
                fill={COLORS[index % COLORS.length]}
              />
            );
          })}
        </Pie>
        <Legend
          layout={window.innerWidth<400?"horizontal":"vertical"}
          align={window.innerWidth<400?"center":"right"}
          verticalAlign="bottom"
          // content={<RenderLegend payload={props.data} />}
        />
      </PieChart>
    </ResponsiveContainer>
  );
}
