import React, { useState, useEffect, useRef } from "react";
import Styles from "./Defi.module.css";
import ManImage from "../../assets/manImage.png";
import Slider from "react-slick";
import { FaAngleRight, FaAngleLeft } from "react-icons/fa6";
import fireImage from "../../assets/fireicon.png";
import "./defi.css";
import { useSelector } from "react-redux";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import axios from "axios";
import { toast } from "react-toastify";
import Select from "react-select";
import { tracer } from "../../tracing";
import { CiSearch } from "react-icons/ci";
import { BaseUrl } from "../../config/API/Api";
import {
  FaCheck,
} from "react-icons/fa";
import TabScreen from "./TabScreen";
import { FaAngleDown, FaAngleUp } from "react-icons/fa6";
const DefiScreen = () => {
  const [windowWidth, setwindowWidth] = useState(window.innerWidth);
  const mode = useSelector((state) => state.themeType.themeType);
  const [chainlist, setchainlist] = useState([]);
  const [activeChain, setactivechain] = useState("all");
  const [currentTab, setcurrentTab] = useState(2);
  const [isOpen, setIsOpen] = useState(false);
  const arrowRef = useRef(null);
  const trendingList = [
    {
      id: 1,
      iconUrl:
        "https://www.JRN.com/cdn/web3/invest/hot/compoundarbop.png?x-oss-process=image/format,webp/ignore-error,1",
      label: "Join Compound Bonus, get ARB or OP bonus!",
    },
    {
      id: 2,
      iconUrl:
        "https://www.JRN.com/cdn/web3/invest/hot/pendlelogo.png?x-oss-process=image/format,webp/ignore-error,1",
      label: "Join Compound Bonus, get ARB or OP bonus!",
    },
    {
      id: 3,
      iconUrl:
        "https://www.JRN.com/cdn/web3/invest/hot/zircuitlogos2.png?x-oss-process=image/format,webp/ignore-error,1",
      label: "Join Compound Bonus, get ARB or OP bonus!",
    },
    {
      id: 4,
      iconUrl:
        "https://www.JRN.com/cdn/web3/invest/hot/compoundarbop.png?x-oss-process=image/format,webp/ignore-error,1",
      label: "Join Compound Bonus, get ARB or OP bonus!",
    },
    {
      id: 5,
      iconUrl:
        "https://www.JRN.com/cdn/web3/invest/hot/compoundarbop.png?x-oss-process=image/format,webp/ignore-error,1",
      label: "Join Compound Bonus, get ARB or OP bonus!",
    },
  ];
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      borderBottom: "0px solid #ccc",
      color: state.isSelected ? "#fff" : "#333", // Selected text color
      background: mode === "lightMode" ? "" : "#1b1b1b",
      padding: "10px",
    }),
    control: (provided) => ({
      ...provided,
      border: mode === "lightMode" ? "1px solid #ccc" : "0px",
      borderRadius: "4px",
      cursor: "pointer",
      height: "45px",
      minWidth: "152px",
      position: "relative",
      bottom: "1px",
      margin: "4px",
      backgroundColor: mode === "lightMode" ? "#f6f6f6" : "#1b1b1b",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: mode === "lightMode" ? "#333" : "white", // Selected text color
    }),
    indicatorSeparator: () => ({ display: "none" }), // Remove separator
    dropdownIndicator: (provided) => ({
      ...provided,
      color: mode === "lightMode" ? "black" : "white", // Dropdown icon color
      fontSize: "10px",
      transition: "transform 0.7s", // Add transition for smooth rotation
      transform: isOpen ? "rotate(180deg)" : "rotate(0deg)",
    }),
    menuList: (base) => ({
      ...base,
      padding: 0,
      "::-webkit-scrollbar": {
        width: "4px",
        height: "0px",
      },
      "::-webkit-scrollbar-track": {
        background: "#f1f1f1",
      },
      "::-webkit-scrollbar-thumb": {
        background: "#888",
      },
      "::-webkit-scrollbar-thumb:hover": {
        background: "#555",
      },
    }),
    tickIcon: {
      marginRight: "5px",
    },
  };
  const initialList = [
    {
      id: 1,
      label: "What’s JRN Web3 DeFi?",
      desc: "JRN Web3 DeFi is an all-in-one platform to help you make the most of decentralized finance. Use this one-stop hub to manage investments in different DeFi protocols. You can navigate liquidity pools on protocols such as AAVE, Yearn, and Pancake easily.",
      isOpen: false,
    },
    {
      id: 2,
      label: "What protocols do we support?",
      desc: "We only bring you the DeFi protocols with the best returns and safest records.",
      isOpen: false,
    },
    {
      id: 3,
      label: "What are the protocol types?",
      desc: `Stablecoins refers to the investment products of stablecoins you can invest in, such as USDT, USDC, DAI, etc.
Bonus refers to the investment products you can invest in and receive extra rewards distributed by projects on JRN Web3 DeFi.
(Please note that JRN Wallet and its affiliates (“JRN”) have no control on reward distribution. All information displayed by JRN is provided by the Third Party and not representations by JRN. Actual APY, incentives and other service terms may vary at the Third Party’s discretion.)
Single Crypto refers to the investment products you can invest in with a single token. These products are available in staking, lending, and borrowing protocols with the advantage of being free from impermanent losses.
Multiple Crypto refers to the investment products you need to invest in with multiple tokens. These products are available in liquidity pools and liquidity mining protocols. Due to price fluctuations of the underlying assets in the pools, investing in these products may incur impermanent losses, further causing losses on your assets.
Vault is a yield aggregator. After you deposit your tokens, Vault will adopt automated strategies to capture the best earnings path and yield the highest return for you.`,
      isOpen: false,
    },
    {
      id: 4,
      label: "What are the advantages of JRN Web3 DeFi?",
      desc: "No need to switch between protocols or make multiple transactions, as you can do everything in one go via JRN Web3 DeFi. Just deposit or claim with any available tokens. JRN Web3 DeFi makes DeFi a cakewalk for you. You can also easily track your DeFi positions across DeFi platforms. Manage the investment whenever you want.",
      isOpen: false,
    },
    {
      id: 5,
      label: "How secure is JRN Web3 DeFi?",
      desc: "JRN Web3 DeFi smart contract has been audited by several parties. We'll never access your assets without your permission. You own your assets all along your investment journey.",
      isOpen: false,
    },
    {
      id: 6,
      label: "What do you need to pay to use JRN Web3 DeFi?",
      desc: "No extra fees. You only need to pay gas fees to complete the transaction on different networks. JRN Web3 DeFi can save gas fees for you as you enjoy staking tokens in the liquidity pool.",
      isOpen: false,
    },
    {
      id: 7,
      label: "How do I manage my investment portfolio?",
      desc: "In JRN Web3 DeFi, you can redeem your assets and claim rewards in the DeFi dashboard anytime. The dashboard also helps you track the current status of your portfolio.",
      isOpen: false,
    },
    {
      id: 8,
      label: "How does JRN Web3 DeFi manage the risk?",
      desc: "Although JRN Web3 DeFi tries its best to keep your assets safe, there are risks when you interact with third-party smart contracts. All DeFi protocols involve risks. JRN Web3 DeFi doesn't take responsibility for contract vulnerabilities, hacking incidents, or extreme fluctuations.",
      isOpen: false,
    },
    {
      id: 9,
      label: "What are the potential risks of DeFi?",
      desc: `Here are the risks that might occur to DeFi users:
Technical Risks: where smart contracts could be hacked, or bugs could be exploited.
Liquidity Risks: where protocols like Compound could run out of liquidity. 
Admin Key Risks: where the master private key for the protocol could be compromised.

It'd be helpful if you did your research before participating in any DeFi protocols. JRN Web3 DeFi isn't responsible for any token loss caused by those reasons.`,
      isOpen: false,
    },
  ];
  const [optionList, setoptionList] = useState(initialList);

  useEffect(() => {
    const handleResize = () => {
      setwindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const renderFirstSection = () => {
    return (
        <div className={`${Styles.header} row mx-0`}  style={{padding:windowWidth>1041?'0 116px':'50px 24px' }}>
          <div
            className={`${Styles.header} ${
              windowWidth > 1041 ? "col-6" : "col-12"
            }  d-flex flex-column justify-content-center align-items-center`}
          >
            <h1 className={`${Styles.headerTitle} ${windowWidth>1041?'font-60 ':'font-32 text-center'}  mb-5`}>
              A powerful Web3 yield aggregator
            </h1>
            <p className={`${Styles.headerDesc} ${windowWidth>1041?'font-16 ':'font-12 text-center'} `}>
              Maximize earnings with top-performing DeFi products curated from
              multiple blockchain protocols
            </p>
          </div>
          {windowWidth > 1041 && (
            <div
              className={`col-6  ${Styles.header} ${Styles.rightHeader} `}
              style={{
                backgroundImage:
                  'url("https://www.JRN.com/cdn/assets/imgs/236/5AE4195D5BB09555.png?x-oss-process=image/format,webp/ignore-error,1")',
                backgroundSize: "cover",
              }}
            >
              <img
                src={ManImage}
                className="img-fluid img-responsive"
                alt="..."
                style={{ maxHeight: "467px" }}
              />
            </div>
          )}
        </div>
    );
  };

  const renderSecondSection = () => {
    var settings = {
      dots: false,
      infinite: false,
      arrows: false,
      speed: 500,
      slidesToShow: 4,
      slidesToScroll: 4,
      initialSlide: 0,
      responsive: [
        {
          breakpoint: 1270,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
          },
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };
    return (
      <div
        className={`${windowWidth > 1024 && ""} ${
          mode === "lightMode" ? "bg-white text-black" : "bg-black text-white"
        }`}
      >
        <div className="d-flex align-items-center  pt-5 py-3">
          <img src={fireImage} alt="..." width={"32px"} height={"32px"} />
          <p className="font-32 ml-2 font-weight-bold mb-0">Trending</p>
        </div>
        <div className="slider-container bg-transparent">
          <Slider
            {...settings}
            swipe={true}
            ref={(c) => (arrowRef.current = c)}
          >
            {trendingList.map((elem, index) => (
              <div
                key={index}
                className={`card ${
                  windowWidth > 1120 && Styles.fixed_width_card
                }`}
              >
                <img
                  className="card-img-top m-0"
                  src="https://www.JRN.com/cdn/assets/imgs/2311/ABFB5DD271FFDB0C.png?x-oss-process=image/format,webp/ignore-error,1"
                  alt="Card image cap"
                  loading="lazy"
                  width={"309px"}
                  height={"334px"}
                />
                <div className="card-img-overlay d-flex flex-column justify-content-center align-items-center">
                  <img
                    src={elem.iconUrl}
                    alt="..."
                    style={{
                      objectFit: "contain",
                      width: "109px",
                      height: "48px",
                    }}
                  />
                  <h5
                    className={`${Styles.cardTitle} text-center`}
                    style={{ paddingTop: "24px", paddingBottom: "40px" }}
                  >
                    {elem.label}
                  </h5>
                  <button className={`btn btn-block ${Styles.cardBtn} w-75`}>
                    Learn More
                  </button>
                </div>
              </div>
            ))}
          </Slider>
          <div
            className="d-flex justify-content-between"
            style={{ position: "relative", bottom: "200px", zIndex: 1 }}
          >
            <button
              onClick={() => {
                arrowRef.current.slickPrev();
              }}
              style={{
                width: "48px",
                height: "48px",
                borderRadius: "48px",
                position: "relative",
                left: "-20px",
              }}
              className="rounded rounded-circle pointer"
            >
              <FaAngleLeft size={17} color="black" />
            </button>
            <button
              onClick={() => {
                arrowRef.current.slickNext();
              }}
              style={{ width: "48px", height: "48px", borderRadius: "48px" }}
              className="rounded rounded-circle pointer"
            >
              <FaAngleRight size={17} color="black" />
            </button>
          </div>
        </div>
      </div>
    );
  };

  const renderThirdSection = () => {
    const tablist = [
      "Stablecoins",
      "Points",
      "Bonus",
      "Signle crypto",
      "MultiCrypto",
    ];
    const renderTabs = () => {
      return (
        <div
          className={`${
            mode === "lightMode" ? "bg-white text-dark" : "bg-black text-white"
          } ${Styles.tabContainer} d-flex scrollbar`}
          style={{ whiteSpace: "nowrap" ,overflowX:'scroll',overflowY:'hidden'}}
        >
              {tablist.map((o, index) => (
                <span
                  key={index}
                  onClick={() => {
                    setcurrentTab(index + 1);
                  }}
                  className={`mr-3 pointer font-weight-bold font-22 ${
                    index + 1 === currentTab &&
                    (mode === "lightMode"
                      ? Styles.tabIteminlight
                      : Styles.tabItemindark)
                  } ${Styles.tabItem} `}
                  style={{
                    fontWeight: "400",
                    color: index + 1 === currentTab ? "black" : "#909090",
                    fontSize: windowWidth<1024?"17px": "22px",
                    fontFamily: "inherit",
                    padding:'10px 0',
                    borderBottom:index + 1 === currentTab&&'3px solid black'
                  }}
                >
                  {o}
                </span>
              ))}
        </div>
      );
    };

    const renderSearch = () => {
      return (
        <div className="d-flex align-items-center">
          <span
            className={`${Styles.searchIcon} text-center d-flex align-items-center justify-content-center`}
          >
            <CiSearch
              size={20}
              color={mode === "lightMode" ? "black" : "white"}
            />
          </span>
          <input type="text" className={`${Styles.searchInput} form-control`} />
        </div>
      );
    };
    return (
      <div className="">
        <div className="">
          <h4 className="font-32 font-weight-bold">Explore</h4>
        </div>
        <div
          className=" d-flex my-2 align-items-center  flex-wrap"
          style={{ borderBottom: "1px solid #ebebeb" }}
        >
          {renderTabs()}
          {windowWidth>1024&&<div className="ml-2">
            <Select
              options={chainlist}
              value={chainlist.find((option) => option.key === activeChain)}
              onChange={(selectedOption) => {
                setactivechain(selectedOption?.key);
              }}
              isSearchable={false}
              isClearable={false}
              getOptionLabel={(option) => (
                <div className="scrollbar border-0 d-flex justify-content-between align-items-center">
                  <span>
                    <img
                      src={`data:image/jpg;base64,${option?.image_url}`}
                      alt={option?.name}
                      style={{ width: "20px", marginRight: "10px" }}
                    />
                    {option?.name}
                  </span>
                </div>
              )}
              getOptionValue={(option) => option.key}
              styles={customStyles}
              className="font-14 border-0 scrollbar w-100"
              onMenuOpen={() => setIsOpen(true)}
              onMenuClose={() => setIsOpen(false)}
              // Customizing the Option component to display a tick mark if the option is selected
              components={{
                Option: ({ children, isSelected, ...rest }) => (
                  <div
                    className={`d-flex justify-content-between align-items-center px-2 py-3 ${
                      isSelected ? "selected" : ""
                    }`}
                    style={{
                      backgroundColor:
                        mode === "lightMode" ? "#f6f6f6" : "#1b1b1b",
                    }}
                    {...rest}
                    onClick={() => {
                      rest.selectOption(rest.data);
                    }}
                  >
                    {children}
                    {isSelected && (
                      <FaCheck
                        size={15}
                        className="font-weight-bold"
                        color={mode === "lightMode" ? "black" : "white"}
                      />
                    )}
                  </div>
                ),
              }}
            />
          </div>}
          {windowWidth>1150&&renderSearch()}
        </div>
        <TabScreen
          currentTab={tablist[currentTab - 1]}
          windowWidth={windowWidth}
          mode={mode}
        />
      </div>
    );
  };

  const renderFourthSection = () => {
    return (
      <div className="">
        <div className="py-5">
          <h1 className="text-center py-5 font-32 font-weight-bold">
            Learn about DeFi
          </h1>
        </div>
        <div className="d-flex flex-column align-items-center">
          {optionList.map((elem, index) => (
            <div
              key={index}
              className={`${windowWidth < 768 ? "py-3 w-100" : "py-5 w-75"}`}
              style={{
                borderBottom: "1px solid #ebebeb",
                borderTop: "1px solid #ebebeb",
              }}
              onClick={() => {
                const filterdata = optionList.map((item) => {
                  if (item.id === elem.id) {
                    item.isOpen = !item.isOpen;
                  }
                  return item;
                });
                console.log(filterdata);
                setoptionList(filterdata);
              }}
            >
              <div
                className="d-flex justify-content-between align-items-center"
                style={{ borderTop: "1px solid #" }}
              >
                <p>{elem.label}</p>
                {elem.isOpen ? (
                  <FaAngleDown size={20} color="black" />
                ) : (
                  <FaAngleUp size={20} color="black" />
                )}
              </div>
              {elem.isOpen ? <p>{elem.desc}</p> : null}
            </div>
          ))}
        </div>
      </div>
    );
  };

  //api
  const blockchainApi = async () => {
    const span = tracer.startSpan("BlockChainListApi");
    const url = BaseUrl + "/blockchainlist";
    try {
      await axios
        .get(url)
        .then((res) => {
          if (res.status === 200) {
            if (typeof res.data === "string") {
              toast.warn("invalid data got through blockchain api");
              return;
            }
            if (res.data.length > 0) {
              setchainlist(res.data);
              setactivechain(res.data[0].key);
            }
          }
          span.setAttribute("Method", "POST");
          span.addEvent("api_response", {
            status: res.status,
            url,
            data: res.data,
          });
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          span.end();
        });
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    blockchainApi();
  }, []);
  return (
    <div className={""} style={{ minHeight: "100vh"}}>
      {renderFirstSection()}
      <div style={{padding:windowWidth>1041?'0px 116px':'0px 24px' }} className={`${mode==='lightMode'?'bg-white text-black':'bg-black text-white'}`}>
      {renderSecondSection()}
      {renderThirdSection()}
      {renderFourthSection()}
      </div>
    </div>
  );
};

export default DefiScreen;
