import React from "react";
import { FaRegTimesCircle ,FaThumbsUp, FaThumbsDown} from "react-icons/fa";
import { Loader2 } from "../../commonComponent/Loader";
import { RiBarChartFill } from "react-icons/ri";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { checkValueexistornot } from "../../utils/utility/Utility";
export default function RenderAssetItem(props) {
  const mode = useSelector((state) => state.themeType.themeType);
  const {updateSelectedfilter,updatePropertiesdata,propertiesData,isloading}=props;
  const selectedFilter=props.selectedFilter===undefined?[]:props.selectedFilter;
  const navigate=useNavigate();
  const renderSelectedproperties = () => {
    return (
      <div className="d-flex flex-wrap">
    {selectedFilter!==undefined? selectedFilter.length > 0 &&
          selectedFilter.map((item, index) => {
            return item.values!==undefined&&item.values.map((elem,index) => {
              return (
                <p
                  key={index}
                  className="text-dark px-3 py-2 font-weight-bold m-1"
                  style={{
                    backgroundColor: "rgb(235 233 233)",
                    borderRadius: "8px",
                    // maxHeight:'32px',
                    // margin:' 0 16px 12px 0',
                    fontSize: "14px",
                  }}
                >
                  {elem}{" "}
                  <span
                    className="ml-2 cursor-pointer "
                    style={{ position: "relative", top: "5px" }}
                    onClick={() => {
                      removeselectedFilter(item, elem);
                    }}
                  >
                    <FaRegTimesCircle size={20} />
                  </span>
                </p>
              );
            });
          }):null}
      </div>
    );
  };
  const removeselectedFilter=(item,value)=>{
    updatePropertiesdata(propertiesData.map((object)=>{
      if(object.traitType===item.key){
        object.trait_values.forEach(arr=>{
          if(arr.key===value){
            arr.isChecked=false
          }
        })
      }
      return object;
    }));
    updateSelectedfilter(selectedFilter.map((object)=>{
      if(object.key===item.key){
        object.values=object.values.filter(i=>i!==value);
      }
      return object;
    }));
  }
  const handleClick = (elem) => {
    navigate(`/assets/${elem?.contract_id}/${checkValueexistornot(elem?.token_id)}/overview`, {state:{
      userData: elem,
      blockchain:elem?.blockchain
    }});
  };
  return (
    <div className="col p-0">
      {renderSelectedproperties()}
      {
        !isloading && props.datalist?.length===0?<p className="text-center font-weight-bold mt-5 font-24">No Asset Found</p>:null
      }
          <div
      className={`d-flex flex-wrap   p-0 ${props.containerclassName}`}
      style={{ maxHeight: "90vh",overflowY:'auto' }}
      id="collectionContainer"
    >
      {props.datalist.map((elem, index) => {
        return (
          <div
            className={`card customCard mr-2 ${
              props.showfilter ? "ml-1" : "ml-3"
            }  mt-2  pointer rounded ${
              mode === "lightMode"
                ? "bg-light"
                : "bg-black border border-secondary"
            }`}
            style={{ width: props.showfilter ? "170px" : "180px" }}
            key={index}
            onClick={() => {
              handleClick(elem);
            }}
          >
            <img
              alt={"..."}
              width={"184x"}
              loading="lazy"
              height={"184px"}
              src={elem?.image_url} 
              style={{ borderRadius: "1em" }}
            />

            <div className="card-body m-0 p-2 pl-3">
              <p
                className="mb-1"
                style={{
                  fontWeight: "bold",
                  fontSize: "14px",
                  lineHeight: "16px",
                }}
              >
                {elem?.name}
              </p>
              <div>
                <p
                  className="mb-0"
                  style={{
                    color: "#929292",
                    fontSize: "12px",
                    lineHeight: "16px",
                  }}
                >
                  Price
                </p>
                <div className="d-flex justify-content-between">
                  <span
                    style={{
                      backgroundColor:
                        mode === "lightMode" ? "#f2f2f2" : "black",
                      fontWeight: "600",
                      color: mode === "lightMode" ? "black" : "white",
                    }}
                  >
                    {" "}
                    <RiBarChartFill size={10} className="mx-2" />
                    {elem?.price}
                  </span>
                  <span
                    style={{
                      backgroundColor:
                        mode === "lightMode" ? "#f2f2f2" : "black",
                      fontWeight: "600",
                      color: mode === "lightMode" ? "black" : "white",
                    }}
                  >
                    {" "}
                    <FaThumbsUp size={10} className="mx-2" />
                    {elem?.like}
                  </span>
                  <span
                    style={{
                      backgroundColor:
                        mode === "lightMode" ? "#f2f2f2" : "black",
                      fontWeight: "600",
                      color: mode === "lightMode" ? "black" : "white",
                    }}
                  >
                    {" "}
                    <FaThumbsDown size={10} className="mx-2" />
                    {elem?.dislike}
                  </span>
                  <span
                    style={{
                      backgroundColor:
                        mode === "lightMode" ? "#f2f2f2" : "black",
                      fontWeight: "600",
                      color: mode === "lightMode" ? "black" : "white",
                    }}
                  >
                    {" "}
                    <RiBarChartFill size={10} className="mx-2" />
                    {elem?.volume}
                  </span>
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </div>

      {/* <CustomCardList
        datalist={props.datalist}
        isloading={props.isloading}
        onclick={props.EachCollectionhandleBuynow}
        showfilter={props.showfilter}
        currentpage={1}
      /> */}
      {
        isloading && <div style={{marginBottom:"10px",position:'relative'}} className="d-flex justify-content-center align-items-center ">
          <Loader2 size={20} color="black"/>
        </div>
      }
    </div>
  );
}
