import React,{useState,useEffect} from "react";
import Styles from  './EventList.module.css'
import { useSelector } from "react-redux";
import EventListItem from "./EventListItem";

const EventListScreen = () => {
const [windowWidth,setwindowWidth]=useState(window.innerWidth);
const mode = useSelector((state) => state.themeType.themeType);
const tablist=[{id:1,label:'All',value:'ALL'},{id:2,label:'Allowlist & Airdrop',value:'ALLOWLIST'},{id:3,label:'Ordinal',value:'ORDINAL'},{id:4,label:'NFT Pass',value:'NFT'}]
const badgeList=[{id:1,label:'All',value:'ALL'},{id:2,label:'UpComing',value:'UPCOMING'},{id:3,label:'Live',value:'LIVE'},{id:4,label:'Ended',value:'Ended'}]
const [currentTab,setcurrentTab]=useState(2);
const [currentinterval,setcurrentinterval]=useState(1);

const renderFirstSection=()=>{
    return (
        <div className={`${mode==='lightMode'?'bg-white text-dark':"bg-black text-white"}`} style={{padding:windowWidth>1024?"79px 150px 76px":"20px 50px 30px"}}>
            <div className="row">
            <div className={`col ${Styles.firstSection_container}`}>
                <p className={`m-0 ${Styles.firstSection_title}`}>Drops</p>
                <p className={`${Styles.firstSection_desc} pr-5 font-14`} style={{width:"60%",color:'#3D3D3D'}}>JRN Drops is your gateway to exclusive NFT launches on JRN NFT Market. Engage in activities like allowlisting, airdrops, minting, and subscriptions. Explore first-class collectibles and never miss out.</p>
            </div>
            </div>  
        </div>
        
    )
}

const renderTabs=()=>{
    return (
        <div className={`${mode==='lightMode'?'bg-white text-dark':"bg-black text-white"} ${Styles.tabContainer} py-3 ` }>
            <div className="d-flex justify-content-between flex-wrap py-2"  style={{padding:windowWidth>1024?"79px 150px 76px":"20px 50px 30px",borderBottom:'1px solid #ebebeb'}}>
            <div className="d-flex align-items-center my-2">
                {tablist.map((o)=><span key={o.id} onClick={()=>{setcurrentTab(o.id);}}  className={`mr-3 pointer ${o.id===currentTab&&(mode==='lightMode'?Styles.tabIteminlight:Styles.tabItemindark)} ${Styles.tabItem} `} style={{fontWeight:'400',color:o.id===currentTab?'black':'#909090',fontSize:"18px",fontFamily:'inherit'}} >{o.label}</span>)}
            </div>
            <div className="d-flex align-items-center my-2">
                {badgeList.map((o)=>(<span key={o.id} onClick={()=>{setcurrentinterval(o.id);}} style={{backgroundColor:o.id===currentinterval?"black":"whitesmoke",color:o.id===currentinterval?"white":"#999999"}} className={`badge mr-2 p-2 pointer ${mode==='lightMode'?'':"boreder-secondaro"}`}>{o.label}</span>))}
            </div>
            </div>
        </div>
    )
}


  useEffect(() => {
    const handleResize = () => {
      setwindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return( 
  <div  >
        {renderFirstSection()}
        {renderTabs()}
        <div style={{padding:windowWidth>1024?"64px 135px 76px":"20px 50px 30px"}} className={`${mode==='lightMode'?'':'bg-black text-white'}`}>
        <EventListItem dataList={[
            {isVisible:false,id:1},
            {isVisible:false,id:2},
            {isVisible:false,id:3},
            {isVisible:false,id:4},
            {isVisible:false,id:5},
            {isVisible:false,id:6},
            {isVisible:false,id:7}
        ]} mode={mode} />
        </div>
  </div>);
};

export default EventListScreen;
