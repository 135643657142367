import React, { useEffect } from 'react'

export default function HandleRequest(props) {
    const {connection}=props;

useEffect(()=>{
if(connection){
    connection.addHandler((response)=>{
        console.log(response)
    },null,'presence');
}
},[])
  return (
    <div>HandleRequest</div>
  )
}
