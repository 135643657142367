import React from 'react'
import { RotatingLines,Oval,TailSpin } from 'react-loader-spinner'

export default function Loader(props) {
  return (
    <RotatingLines
        strokeColor="grey"
        strokeWidth="5"
        animationDuration="0.75"
        width={props.size}
        visible={true}
      />
  )
}

export  const  Loader2=(props)=> {
  return(<TailSpin
    visible={true}
    // height="80"
    width={props.size}
    color={props.color}
    ariaLabel="tail-spin-loading"
    radius="1"
    wrapperStyle={props.style}
    wrapperClass={props.className}
    {...props}
    />)
}


export function CustomovalLoader(props){
  
return (
    <Oval
  height={props.height}
  width={props.width}
  color={props.color?props.color:'black'}
  visible={props.visible}
  ariaLabel='oval-loading'
  strokeWidth={2}
  strokeWidthSecondary={2}
/>
)

}
