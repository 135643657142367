import React, { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import {AiOutlineCaretLeft} from 'react-icons/ai';
import CustomSearch from "../../../../commonComponent/CustomSearch";
import CustomCardList from "../../../../commonComponent/CustomCardList";
import { CustomAlchemy } from "./AlchemyConnenction";
import { toast } from "react-toastify";
import axios from "axios";
import { useNavigate ,useParams} from "react-router-dom";
export default function NftAddressCollection() {
  const mode = useSelector((state) => state.themeType.themeType);
  const [collectionList,setcollectionList]=useState([]);
  const [searchInput,setsearchInput]=useState('');
  const [showFilter,setshowfilter]=useState(false);
  const [isLoading,setisLoading]=useState(false);
  const [totalPagekey,settotalpagekey]=useState([]);
  const [nextpageKey,setnextpageKey]=useState('');
  const [currentPage,setcurrentPage]=useState(1);
  const [activelastpage,setactivelastpage]=useState(false);
  const [changes,setchanges]=useState(false);
  const navigate=useNavigate();
  const {address}=useParams();
console.log('nextpageKey', nextpageKey)
console.log('currentPage', currentPage)
console.log('totalPagekey', totalPagekey)
  //collection List
  const alchemyConnection=(address)=>{
    setisLoading(true);
    CustomAlchemy.nft
      .getNftsForOwner(address,{
        pageKey:nextpageKey
      })
      .then((res)=>{
        console.log(res)
        if(res){
          setisLoading(false);
          if(res.pageKey===undefined){
            setactivelastpage(true);
          }else{
            setactivelastpage(false);
            setcurrentPage(currentPage+1);
            setnextpageKey(res.pageKey);
            if(!totalPagekey.includes(res.pageKey)){
              totalPagekey.push(res.pageKey);
            }else{
              setnextpageKey('')
            }
          }
          // setpagekey(res.pageKey);
          for(let elem of res.ownedNfts){
            console.log(elem,'this is elem')
            elem.image_url=elem?.media[0]?.thumbnail
            elem.name=elem.title;
          }
          setcollectionList(res.ownedNfts);
        }
      }).catch((err)=>{
        setisLoading(false);
        console.log(err);
      })
    }

   


  useMemo(()=>{
    if(address!==undefined||address!==null||address!==""){
    alchemyConnection(address);
    // console.log(address,'thisi s')
    }else{
      toast('Address is invalid');
    }
  },[changes])

  const onchange=(value)=>{
    setsearchInput(value);
  }


//api for selling nft
const nftSellApi=async(obj)=>{
  try {
    await axios.post(`http://localhost:8080/api/sell/${obj.tokenId}`,{price:1},{params:{
      tokenId:obj.tokenId
    }}).then((res)=>{
      console.log(res,'this is resonse')
    }).catch((err)=>{console.log(err)});
  } catch (error) {
    console.log(error)
  }

}


  // collection view 

  const LeftAccording = (props) => {
    return (
      <div className="accordion w-100 h-80" id="accordionExample">
        {props.header.map((elem, index) => {
          return (
            <div className="card w-100" key={index}>
              <div
                className={`card-header p-3 pointer ${
                  mode === "lightMode" ? "bg-light" : "bg-dark"
                }`}
                id={`header${elem.id}`}
              >
                <div
                  className={`cursor-pointer d-flex justify-content-between align-items-center mx-2 btn-link ${
                    elem.dafault ? "" : "collapsed"
                  }`}
                  data-toggle="collapse"
                  data-target={`#collapse${elem.id}`}
                  aria-expanded={elem.dafault ? true : false}
                  aria-controls={`collapse${elem.id}`}
                >
                  <p
                    className={` accordian-header mb-0 ${
                      mode === "lightMode" ? "text-dark" : "text-white"
                    }`}
                  >
                    {elem.title}
                  </p>
                </div>
              </div>

              <div
                id={`collapse${elem.id}`}
                className="collapse show"
                aria-labelledby={`header${elem.id}`}
                data-parent="#accordionExample"
              >
                <div
                  className={`card-body d-flex flex-wrap ${
                    mode === "lightMode" ? "b" : "bg-dark text-white"
                  }`}
                >
                  {elem.bodyData}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  const Collection=()=>{
    const First=()=>{
      return (
        <h2>helo</h2>
      )
    }
    const option = [
      {
        title: "Price",
        bodyData: First,
        default: false,
        id: "Two",
      },]



    const handleclick=(value)=>{
      console.log('value', value)
      nftSellApi(value);

    }


    return (
      <div className="row p-2">
      {showFilter ? (
        <div className="col-3 ">
          <div >
            {showFilter && <LeftAccording header={option} />}
          </div>
        </div>
      ) : null}

      <div className={`col p-0 ${showFilter ? "col-9" : ""}`}>
      <CustomCardList
      datalist={collectionList}
      isloading={isLoading}
      onclick={handleclick}
      />
      </div>
    </div>
    )
  }



  const handlePrevious=()=>{
    setchanges(!changes);
    setcurrentPage(currentPage-1);
    setnextpageKey(totalPagekey[currentPage-1]);
  }
  return (
    <div
      className={` px-2 pt-1 w-100 ${
        mode === "darkMode" ? "bg-black text-white" : "bg-light text-dark"
      }`}
    >
     {/* {
      isLoading &&  <div className="d-flex justify-content-center">
      <Loader size="96"/>
      <h1>Please wait</h1>
    </div>
     } */}
      <div className="d-flex justify-content-start m-1">
        <button className="btn btn-primary d-flex justify-content-center align-items-center" onClick={()=>{navigate('/profile/dashboard')}}><AiOutlineCaretLeft size={15} className=""/>Back</button>
      </div>

      <div className="d-flex flex-wrap  justify-content-around">
      <button className="btn btn-primary m-1  px-3" onClick={()=>{setshowfilter(!showFilter)}}>Filter</button>
      <CustomSearch className="w-75" value={searchInput} onchange={onchange}/>
      <button className="btn btn-primary m-1 px-3 " >Select option</button>
      </div>

      {Collection()}

      <div className="d-flex flex-wrap justify-content-between w-75 mx-auto mb-1">
      <button className={`btn ${nextpageKey===""?'btn-secondary disabled':"btn-primary"}`} disabled={nextpageKey===""} onClick={handlePrevious} >prev</button>
      <button className={`btn ${activelastpage?'btn-secondary disabled':'btn-primary'}`} disabled={activelastpage} onClick={()=>{setchanges(!changes);}}>next</button>
      </div>

       
    </div>
  );
}
