import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  useParams,
} from "react-router-dom";
import blueTick from "../../assets/blue_Tick.png";
import InfiniteScroll from "react-infinite-scroll-component";
import { tracer } from "../../tracing";
import { trace } from "firebase/performance";
import { sendPerfomance } from "../..";
import Axios, { BaseUrl } from "../../config/API/Api";
import { toast } from "react-toastify";
import { Loader2 } from "../../commonComponent/Loader";
import UserImage from "../../assets/user.png";
import CustomPopup from "../../commonComponent/CustomPopup";
import CustomSelect from "../../commonComponent/CustomSelect";
import Select from "react-select";
import axios from "axios";
import { FaCheck } from "react-icons/fa";
import { useLocation, useNavigate } from "react-router-dom";
import ReactGA from 'react-ga4';

export default function CategoryCollection() {
  const mode = useSelector((state) => state.themeType.themeType);
  const route = useParams();
  const navigate=useNavigate();
  const {state}=useLocation();
  const [currentpage, setcurrentpage] = useState(1);
  const [dataList, setdataList] = useState([]);
  const [totalCount, settotalCount] = useState(0);
  const [isNextLoading, setisnexLoading] = useState(true);
  const [windowWidth, setwindowWidth] = useState(window.innerWidth);
  const [hasmore, sethasmore] = useState(true);
  const [show, setshow] = useState(false);
  const [chainlist, setchainlist] = useState([]);
  const [activeChain, setactiveChain] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const object = state?.item;
  const customCard = (elem, index) => {
    return (
      <div className="col-xl-3 col-lg-4 col-md-4 col-12 my-2">
        <div
          key={index}
          className="card categoryCard  p-0"
          style={{ borderRadius: "12px" }}
          onClick={() => {
            console.log("this is elem", elem);
            ReactGA.event({
              category: 'Category Card',
              action: 'Pressed on a Category Item on Category screen',
              label: elem?.name,
              nonInteraction:false,
              value:elem
            })
            navigate(`/collection/${elem?.contract_id}/overview`, {state:{
              userData: elem,
              blockchain: elem?.blockchain,
              contract_id:elem?.contract_id,
              image_url:
                elem?.image_url === "null" ? UserImage : elem?.image_url,
            }});
          }}
        >
          <img
            className="card-img-top img-fluid img-responsive"
            src={
              elem?.image_url !== "null"
                ? elem?.image_url
                : "https://www.JRN.com/cdn/nft/files/collection/48332-background-v2.png?x-oss-process=image/resize,w_572,type_6/ignore-error,1"
            }
            alt="Card image cap"
            style={{
              maxHeight: "140px",
              minHeight: "140px",
              borderRadius: "12px 12px 0 0",
              objectFit: "cover",
              aspectRatio: "3/2",
            }}
          />

          <img
            style={{
              position: "absolute",
              left:windowWidth<768?"45%":"40%",
              top: "32%",
              border: `4px solid ${mode === "lightMode" ? "black" : "white"}`,
            }}
            src={elem?.image_url}
            alt="..."
            width={"60px"}
            height={"60px"}
            className="rounded-circle"
            onError={(e) => {
              e.target.src = UserImage;
            }}
          />
          <div
            className={`card-body  mt-4  d-flex justify-content-center flex-column ${
              mode === "lightMode" ? "bg-white text-dark" : "bg-dark text-white"
            }`}
            style={{ maxHeight: "146px", minHeight: "146px" }}
          >
            <span
              style={{ fontSize: "14px" }}
              className="text-center font-weight-bold mt-2"
            >
              {elem?.name} <img src={blueTick} width={"16px"} height={"16px"} />
            </span>
            <span className="font-14 font-weight-bold text-center ">
              {elem?.floor_price}
            </span>
            <span className="text-center margin-top-16 font-12 w-75 mx-auto">
              {elem.description !== undefined
                ? elem.description.length > 70
                  ? elem.description.slice(0, 70) + "..."
                  : elem.description
                : null}
            </span>
          </div>
        </div>
      </div>
    );
  };
  console.log(route, "this si route");
  //API'S
  const apiforcollectionCategories = async () => {
    const span = tracer.startSpan(
      "CCollectionategoryListthroughCategoryNameAPI"
    );
    const t = trace(
      sendPerfomance,
      "CCollectionategoryListthroughCategoryNameAPI"
    );
    const url = `/collection/category/${route?.name}`;
    const params = {
      size: 10,
      page: currentpage,
      blockchain: activeChain,
    };
    try {
      t.start();
      setisnexLoading(true);
      await Axios.get(url, { params })
        .then((res) => {
          if (res.status === 200) {
            console.log("this is blockchain lst", res.data);
            if (typeof res.data === "string") {
              toast.warn("invalid data got through blockchain api");
              return;
            }
            console.log("response data is", res.data);
            settotalCount(res.data?.total_count);
            setdataList((previous) => {
              return [...previous, ...res.data?.body];
            });
          }
          span.setAttribute("Method", "POST");
          span.addEvent("api_response", {
            status: res.status,
            url,
            data: res.data,
          });
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          span.end();
          t.stop();
          setisnexLoading(false);
        });
    } catch (error) {
      console.log(error);
    }
  };
  const blockchainApi = async () => {
    const span = tracer.startSpan("BlockChainListApi");
    const url = BaseUrl + "/blockchainlist";
    try {
      await axios
        .get(url)
        .then((res) => {
          if (res.status === 200) {
            console.log("this is blockchain lst", res.data);
            if (typeof res.data === "string") {
              toast.warn("invalid data got through blockchain api");
              return;
            }
            if (res.data.length > 0) {
              // const data=res.data.map((elem)=>elem.name);
              setchainlist(res.data);
              setactiveChain(res.data[0]?.key);
            }
          }
          span.setAttribute("Method", "POST");
          span.addEvent("api_response", {
            status: res.status,
            url,
            data: res.data,
          });
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          span.end();
        });
    } catch (error) {
      console.log(error);
    }
  };

  //popup
  const renderDescriptionpopup = () => {
    const handleClick = () => {
      console.log("helo guys");
      setshow(false);
    };
    const body = () => {
      return (
        <p
          className={`mb-0 ${
            mode === "lightMode" ? "text-muted1" : "text-white"
          } font-14`}
        >
          NFT is a revolutionary, digital form of art that uses blockchain
          technology to ensure authenticity and ownership. NFT art allows
          artists to monetize their work by selling unique assets, while
          collectors gain exclusive ownership of them. NFT art is also a popular
          choice for fundraising, with auctions raising millions of dollars.
          Check out JRN’s collections for the latest and greatest NFT art
        </p>
      );
    };
    return (
      <CustomPopup
        onclick={handleClick}
        size="lg"
        showpopup={show}
        center={true}
        popHeader="Overview"
        body={body()}
        modalStyle={{maxWidth:'748px'}}
      />
    );
  };
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      borderBottom: "0px solid #ccc",
      color: state.isSelected ? "#fff" : "#333", // Selected text color
      background: mode === "lightMode" ? "" : "#1b1b1b",
      padding: "10px",
    }),
    control: (provided) => ({
      ...provided,
      border: mode === "lightMode" ? "1px solid #ccc" : "0px",
      borderRadius: "4px",
      cursor: "pointer",
      height: "45px",
      minWidth: "152px",
      position: "relative",
      bottom: "1px",
      margin: "4px",
      backgroundColor: mode === "lightMode" ? "#f6f6f6" : "#1b1b1b",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: mode === "lightMode" ? "#333" : "white", // Selected text color
    }),
    indicatorSeparator: () => ({ display: "none" }), // Remove separator
    dropdownIndicator: (provided) => ({
      ...provided,
      color: mode === "lightMode" ? "black" : "white", // Dropdown icon color
      fontSize: "10px",
      transition: "transform 0.7s", // Add transition for smooth rotation
      transform: isOpen ? "rotate(180deg)" : "rotate(0deg)",
    }),
    menuList: (base) => ({
      ...base,
      padding: 0,
      "::-webkit-scrollbar": {
        width: "4px",
        height: "0px",
      },
      "::-webkit-scrollbar-track": {
        background: "#f1f1f1",
      },
      "::-webkit-scrollbar-thumb": {
        background: "#888",
      },
      "::-webkit-scrollbar-thumb:hover": {
        background: "#555",
      },
    }),
    tickIcon: {
      marginRight: "5px",
    },
  };
  const blockChainDropdown = () => {
    return (
      <Select
        options={chainlist}
        value={chainlist.find((option) => option.key === activeChain)}
        onChange={(selectedOption) => {
          ReactGA.event({
            category: 'Select Input',
            action: 'Select the option of blockchain in Category screen',
            label: selectedOption?.key,
            nonInteraction:false,
            value:selectedOption?.key
          })
          setdataList([]);
          setcurrentpage(1);
          setactiveChain(selectedOption?.key);
        }}
        isSearchable={false}
        isClearable={false}
        getOptionLabel={(option) => (
          <div className="scrollbar border-0 d-flex justify-content-between align-items-center">
            <span>
              <img
                src={`data:image/jpg;base64,${option?.image_url}`}
                alt={option?.name}
                style={{ width: "20px", marginRight: "10px" }}
              />
              {option?.name}
            </span>
          </div>
        )}
        getOptionValue={(option) => option.key}
        styles={customStyles}
        className="font-14 border-0 scrollbar w-100"
        onMenuOpen={() =>{ setIsOpen(true) ; ReactGA.event({
            category: 'Open the Select Input',
            action: 'Open the select Input of blockchain in Category screen',
            label: 'open',
            nonInteraction:false,
            value:1
          })}}
        onMenuClose={() => {setIsOpen(false) ; ReactGA.event({
            category: 'Close the Select input',
            action: 'Open the select Input of blockchain in Category screen',
            label: 'close',
            nonInteraction:false,
            value:2
          })}}
        // Customizing the Option component to display a tick mark if the option is selected
        components={{
          Option: ({ children, isSelected, ...rest }) => (
            <div
              className={`d-flex justify-content-between align-items-center px-2 py-3 ${
                isSelected ? "selected" : ""
              }`}
              style={{
                backgroundColor: mode === "lightMode" ? "#f6f6f6" : "#1b1b1b",
              }}
              {...rest}
              onClick={() => {
                rest.selectOption(rest.data);
              }}
            >
              {children}
              {isSelected && (
                <FaCheck
                  size={15}
                  className="font-weight-bold"
                  color={mode === "lightMode" ? "black" : "white"}
                />
              )}
            </div>
          ),
        }}
      />
    );
  };
  // EVENT HANdling
  useEffect(() => {
    blockchainApi();
  }, []);
  useEffect(() => {
    if (activeChain !== "") {
      apiforcollectionCategories();
    }
  }, [currentpage, activeChain]);

  useEffect(() => {
    const handleResize = () => {
      setwindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <section
      className={` ${
        mode === "lightMode" ? "bg-white text-dark" : "bg-dark text-white"
      }`}
    >
      <section className={`p-0 m-0 ${mode === "lightMode" ? "bg-white" : "bg-dark"}`}>
        <div
          className="d-flex m-0 p-0 justify-content-center align-items-center"
          style={{
            height: "200px",
            background: `url(${
              object !== undefined ? object?.value : ""
            }) no-repeat center`,
            backgroundSize: "cover",
          }}
        >
          <h1
            className={`font-weight-bold display-3 text-capitalize ${
              mode === "lightMode" ? "text-dark" : "text-dark"
            }`}
          >
            {route?.name}
          </h1>
        </div>
        <div className="w-100 d-flex align-items-center justify-content-center mt-3 mb-5">
          <p
            className={`${windowWidth < 1024 ? "w-75" : "w-50"}`}
            style={{
              fontSize: "12px",
              padding: "0 120px",
              color: mode === "lightMode" ? "#929292" : "white",
            }}
          >
            Blockchain technology is now being used to verify the authenticity
            and ownership of unique digital assets like trading cards and
            jerseys. With sports NFTs, athletes and teams can easily.. {"   "}
            <span
              className="font-14 font-weight-700 text-black pointer"
              onClick={() => {
                setshow(true);
              }}
            >
              More
            </span>
          </p>
        </div>
      </section>

      <section style={{ padding: windowWidth < 1025 ? "0 12px" :windowWidth<1270?windowWidth<1100?windowWidth<1050?'0 15px':'0 100px':"0 154px":'0 135.5px' }}>
        <div className="row">
          {/* <div className="col-m">
          </div> */}
          <div className="col d-flex justify-content-between align-items-center">
            <span className="d-flex font-weight-bold text-black">
              {totalCount}&nbsp;
              <span
                style={{ color: mode === "lightMode" ? "#6E6E6E" : "white" }}
              >
                {"   Collection"}
              </span>
            </span>
            <div>{blockChainDropdown()}</div>
          </div>
        </div>
        <div>
          <InfiniteScroll
            dataLength={dataList.length}
            next={() => {
              console.log("kya yh chala");
              if (dataList.length % 10 === 0) {
                setcurrentpage(currentpage + 1);
              } else {
                toast.warn("There is no more Category.");
                sethasmore(false);
              }
            }}
            hasMore={hasmore}
            style={{ overflowX: "hidden" }}
            className=""
          >
            <div className="row">
              {dataList.map((elem, index) => customCard(elem, index))}
            </div>
          </InfiniteScroll>
          {isNextLoading && (
            <div className="d-flex justify-content-center align-items-center">
              <Loader2 size={20} color="black" />
            </div>
          )}
        </div>
      </section>
      <section id="popup" >{renderDescriptionpopup()}</section>
    </section>
  );
}
