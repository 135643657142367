import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
export default function Error({ mode }) {
const [address, setaddress] = useState("");
const navigate=useNavigate();
console.log('idhar aaaya ki anhi')
  useEffect(() => {
    const address = localStorage.getItem("userAddress");
    console.log(address,'this is address')
    if (address !== undefined || address !== null || address!=="") {
      // define login on which address you want to navigate.
      console.log('helo')
      navigate(`/profile/dashboard/${localStorage.getItem('userAddress')}`)
    }
  }, []); 
  return (
    <div
      className={`${
        mode === "lightMode" ? "bg-light text-dark" : "bg-black text-white"
      } w-100 px-3`}
    >
      <div>
        <h1 className="display-4 font-weight-bold">Portfolio Tracker</h1>
        <p>Check your assets, recent dapp usage, view your NFTs and more.</p>
      </div>
      <div className="d-flex flex-wrap">
        <button className="btn btn-outline btn-primary">Ethereum</button>
        <button className="btn btn-outline btn-primary mx-2">BNB Chain</button>
        <button className="btn btn-outline btn-primary">Polygon</button>
      </div>

      <div className="w-50 mt-4">
        <div className="form-group">
          <input
            type="text"
            className="form-control"
            value={address}
            onChange={(e) => {
              setaddress(e.target.value);
            }}
            id="exampleInputPassword1"
            placeholder="Paste Wallet Address or ENS"
          />
        </div>
        <button className="btn-block btn btn-primary">open Wallet</button>
        <div className="d-flex flex-wrap">
          <button className="btn btn-primary m-1 ">MetaMask</button>
          <button className="btn btn-primary m-1 ">WalletConnect</button>
          <button className="btn btn-primary m-1 ">Coinbase Wallet</button>
        </div>
      </div>
      <br/>
      <p className="">Don't have a wallet <span className="btn-link ml-2">View a demo</span></p>
    </div>
  );
}
