import axios from "axios";
import { toast } from "react-toastify";

export const getallUser = async (obj) => {
  const url="http://k8s-uatejabb-ejbservi-2781f178bf-49b39afea9263853.elb.us-west-2.amazonaws.com:5443/api/registered_users";
  const totaluser=[]
  // const url = "https://run.mocky.io/v3/a3852337-404d-4f3e-a612-b182e1394bed";
  try {
    const response = await axios.post(url,{
      host:'k8s-uatejabb-ejbservi-2781f178bf-49b39afea9263853.elb.us-west-2.amazonaws.com'
    },{
      headers:{
        Authorization:'Bearer ymj3Hab4tuUHsqIh7wT3N5WKtPp3ptqM'
      },
    });
    if (response.status === 200) {
     const data=await response.data;
     for(let elem in data){
      totaluser.push({user:data[elem],msgCount:0,color:'black'});
     }
     return totaluser;
    } else {
      toast.warn("Data not found");
    }
  } catch (error) {
    console.log(error);
    toast.error("we got error");
  }
};
