import React,{useState,useEffect} from "react";
import Styles from "./Details.module.css";
import Select from "react-select";
import InfiniteScroll from "react-infinite-scroll-component";
import { FaXTwitter } from "react-icons/fa6";
import { FaCheck ,FaTelegramPlane} from "react-icons/fa";
import { CiStar } from "react-icons/ci";
import { useSelector } from "react-redux";
import { AxiosForDiscover, REACT_APP_DAPP_BASEURL } from "../../../config/API/Api";
import { Loader2 } from "../../../commonComponent/Loader";
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Popper from '@mui/material/Popper';
import CheckIcon from '@mui/icons-material/Check';
import { ListItemText, MenuItem, ListItemIcon } from '@mui/material';
import { thousandsConverter } from "../../../utils/utility/Utility";
import { tracer } from "../../../tracing";
const DetailScreen = ({ header ,list,updatepage,hasMore}) => {
  const mode = useSelector((state) => state.themeType.themeType);
  const [activeChain,setactivechain]=useState(null);
  const [isLoading,setisloading]=useState(true);
  const [isOpen,setIsOpen]=useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [sectionCardList,setsectionCardList]=useState([]);
  const [blockchainList,setblockchainList]=useState([])
  const [displayedOption,setDisplayedOptions]=useState([]);
  const [page,setpage]=useState(0);
  const sortFilterOption = [
    { unique_key: '1', name: 'TVL: high to low',value:1  },
    { unique_key: '2', name: '24h TVL change: high to low',value:2 },
    { unique_key: '3', name: '7D TVL change: high to low',value:3 },
  ];
  const [selectSortOption,setselectSortOption]=useState(sortFilterOption[0]);

  const volumeContainTab=['dex','nftmarket','bridge']
  const uaw_contain=['game']
  
  const handlesortChange = (event, newValue) => {
    setselectSortOption(newValue);
  };
 
  const renderFirstSection = () => {
    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col my-4">
            <h1 className={Styles.headerTitle}>{header?.section_title}</h1>
            <p className={Styles.headerDesc}>{header?.description}</p>
          </div>
        </div>
      </div>
    );
  };
  const renderDropdown=(list,chain,handleActiveChange,showImage=true)=>{
    return (
      <Autocomplete
                options={list}
                value={chain}
                getOptionLabel={(option) => option.name}
                isOptionEqualToValue={(option, value) => option.unique_key === value.unique_key}
                onChange={handleActiveChange}
                renderInput={(params) => (
                    <TextField {...params} variant="outlined" placeholder="Select Option"
                    sx={{
                        '& .MuiOutlinedInput-root': {
                        border:'1px solid white',
                          borderRadius: '16px', // Example: Custom border radius
                        },
                        '& .MuiInputLabel-root': {
                          color: 'green',fontWeight:'bold'
                        },
                        '& .MuiInputBase-input': {
                        fontSize: '14px', // Custom font size for input text
                        fontWeight:'bold',
                        color:mode==='darkMode'&&'white'

                         },
                      }}
                    />
                )}
                renderOption={(props, option, { selected }) => (
                    <MenuItem {...props} key={option.unique_key} className={Styles.dropdownOptionContainer} sx={{fontSize:'14px',fontWeight:'bold'}}>
                   {showImage&& <ListItemIcon>
                        <img
                        src={option?.logo}
                        alt={option.name}
                        width="20px"
                        height="20px"
                        style={{ marginRight: 8 }}
                        loading="lazy"
                        />
                    </ListItemIcon>}
                    <p>{option?.name}</p>
                    {selected && <FaCheck size={20} color="black" style={{ marginLeft: 'auto' }} />}
                    </MenuItem>
                )}
                />
    )
  }
 
  const renderSecondSection = () => {
    const handleActiveChange=(event,newValue)=>{
        setactivechain(newValue);
        if(newValue===null){
            setDisplayedOptions(displayedOption);
        }
    }
    return (
      <div className="container-fluid ">
        <div className="row">
          <div className="col-md-3 col-5">
            {renderDropdown(blockchainList,activeChain,handleActiveChange,true)}
          </div>
          <div className="col-md-3 col-5 offset-md-6 offset-2">
            {renderDropdown(sortFilterOption,selectSortOption,handlesortChange,false)}
          </div>
        </div>
      </div>
    );
  };
  const Card = (elem) => {
    const obj=elem?.obj
    return (
      <div className="col-md-6 col-12 p-2">
        <div className={Styles.cardContainer}>
          <div  onClick={() => {
          if(obj?.deep_link!==undefined){
            window.open(obj?.deep_link, "_blank");
          }
        }}>
              <div className="d-flex align-items-center justify-content-between">
              <div className={`d-flex align-items-center  ${Styles.badgeContainer} scrollbar `}>
                  <img width={'44px'} height={'44px'} src={obj?.logo} loading="lazy" alt="..."/>
                  <div className={`ml-3`}>
                      <h5 className="font-14 fontweight-500 mb-2 align-self-center">{obj?.name}</h5>
                      <div className={`d-flex align-items-center flex-wrap ${Styles.badgeContainer} scrollbar`}>
                          {(obj?.recommend_info!==''||obj?.recommend_info!==null)&&<span className={`badge m-1 font-12 ${Styles.badgeCard_recommended_info}`}>{obj?.recommend_info}</span>}
                          {obj?.tags_name_list?.map((o,i)=><span key={i} className={`badge ${Styles.badgeCard} m-1`}>{o}</span>)}
                      </div>
                  </div>
                </div>
                <div className="d-flex align-items-center justify-content-end">
                  {volumeContainTab.find(o=>o.toLocaleLowerCase()===header?.tab_key?.toLocaleLowerCase())? <div className="mx-2">
                      <p className="mb-1 font-12 text-secondary text-center">Volume</p>
                      <p className="mb-1 font-14  text-center">{thousandsConverter(obj?.volume)}</p>
                  </div>: <div className="mx-2">
                      <p className="mb-1 font-12 text-secondary text-center">TVL</p>
                      <p className="mb-1 font-14  text-center">{thousandsConverter(obj?.tvl)}</p>
                  </div>}
                  {volumeContainTab.find(o=>o.toLocaleLowerCase()===header?.tab_key?.toLocaleLowerCase())? <div className="mx-2">
                      <p className="mb-1 font-12 text-secondary text-center">Change</p>
                      <p className={`mb-1 font-14 ${obj?.volume_change>=0?'text-success':'text-danger'} text-center`}>{Math.round(obj?.volume_change).toFixed(2)}%</p>
                  </div>: <div className="mx-2">
                      <p className="mb-1 font-12 text-secondary text-center">Change</p>
                      <p className={`mb-1 font-14 ${obj?.tvl_change>=0?'text-success':'text-danger'} text-center`}>{Math.round(obj?.tvl_change).toFixed(2)}%</p>
                  </div>}
                 
                </div>
              </div>
                <div className={`mb-1 ${Styles.description}`} >
                  <p className="mb-2 font-12 text-secondary">{obj?.short_describe?.length>80?obj?.short_describe?.slice(0,80)+"...":obj?.short_describe}</p>
                </div>
          </div>
          <div className="d-flex align-items-center justify-content-between">
          <div><FaXTwitter size={18} color={mode==='lightMode'?'black':'white'} className={Styles.socialMediaIcons} onClick={()=>{if(obj?.twitter_link){window.open(obj?.twitter_link,'_blank')}}}/>{obj?.telegram_link!==undefined&&obj.telegram_link!==null&&obj.telegram_link!==''&&<FaTelegramPlane size={18} color={mode==='lightMode'?'black':'white'} className={Styles.socialMediaIcons} onClick={()=>{if(obj?.telegram_link){window.open(obj?.telegram_link,'_blank')}}}/>}</div>
            <CiStar size={15} color="black" className={Styles.star}/>
          </div>
        </div>
      </div>
    );
  };
  //api section
  const blockchainApi=async()=>{
    const span = tracer.startSpan("Dapps left side List Api");
    if(header?.tab_key===undefined||header?.tab_key===null){
        return
    }
    const params={key:header?.tab_key}
    try {
        setisloading(true);
        setactivechain(null);
        span.setAttribute("request.payload", JSON.stringify(params));
        span.setAttribute("http.method", "GET");
        span.setAttribute("http.url", REACT_APP_DAPP_BASEURL + '/dapp/chain/list');
        await AxiosForDiscover.get('/dapp/chain/list',{params}).then(res=>{
          span.setAttribute("http.status_code", res.status);
            if(res.status===200){
              span.setAttribute("api.success", true);
              span.addEvent("api_data_processed", {
                processed_data_length: res.data.length,
              });
              span.addEvent("api_response_data", {
                data: JSON.stringify(res.data),
              });
                const data=[...new Map(res.data.map(item => [item.name, item])).values()]
                .sort((a, b) => a.name.localeCompare(b.name));
                const finalData=data.map((o,i)=>{return {...o,unique_key:i}})
                console.log(finalData.length,finalData);
                setblockchainList(finalData)
                if(finalData!==undefined){
                    if(finalData.length>200){
                        const displayeddata=finalData.slice(0,200);
                        console.log('displayeddata', displayeddata)
                        setactivechain(displayeddata[0])
                        setDisplayedOptions(displayeddata)
                    }else{
                        setactivechain(data[0])
                        setDisplayedOptions(data);
                    }
                }
            }else{
              span.setAttribute("api.success", false);
            }
        }).catch((err)=>{ span.recordException(err);
          span.setAttribute("api.success", false);
          span.addEvent("api_error", {
            error_message: err.message,
            error_stack: err.stack,
          });}).finally(()=>{setisloading(false);span.end();})
    } catch (error) {
        console.log('error', error)
        span.recordException(error);
        span.setAttribute("api.success", false);
        span.addEvent("api_exception", {
          exception_message: error.message,
          exception_stack: error.stack,
        });
        span.end();
    }
  }
  

  useEffect(()=>{
    if(header?.uuid!==1&&header?.uuid!==2){
      blockchainApi();
    }
  },[header])
  
  return (
    <div>
      <div id="scrollableDiv" className={`${Styles.cardListContainer} scrollbar`}>
        {renderFirstSection()}
        {renderSecondSection()}
        <InfiniteScroll
            dataLength={list!==undefined&&list?.length}
            next={()=>{ console.log('helo');if(updatepage!==undefined){updatepage()}}}
            hasMore={hasMore}
            scrollableTarget="scrollableDiv"
            loader={<div className="d-flex align-items-center justify-content-center ">
              <Loader2 size={25} color={mode === "lightMode" ? "black" : "white"} />
          </div>}
            endMessage={!hasMore&&<p className="font-16 font-weight-bold text-center">No more data</p>}
        >
            <div className="row mx-0 mt-2" >
              {list!==undefined&&list?.map((elem,index)=>elem?.status==='enable'&&<Card obj={elem} key={index}/>)}
            </div>
        </InfiniteScroll>
      </div>
    </div>
  );
};

export default DetailScreen;
