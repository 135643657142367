import React, { useEffect, useMemo, useState } from 'react';
import moment from 'moment';

export default function DataScreen() {
  const [time, setTime] = useState(0);
  const [minute, setMinute] = useState(50);

  useEffect(() => {
    const interval = setInterval(() => {
      if (minute > 0) {
        setMinute(minute - 1);
      } else if (time > 0) {
        setMinute(59);
        setTime(time - 1);
      } else {
        clearInterval(interval);
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [time, minute]);

  console.log(`${time}:${minute}`);

  return (
    <div>
      <p>{time}:{minute < 10 ? `0${minute}` : minute}</p>
    </div>
  );
}
