import React, { memo, useState } from "react";
import { useSelector } from "react-redux";
import Switch from "@mui/material/Switch";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import Styles from "./Cookies.module.css";
import { FaPlus, FaMinus } from "react-icons/fa6";
import { FaChevronLeft, FaFilter } from "react-icons/fa";
import Cookies from 'js-cookie'
import { initGA } from "../../utils/utility/GAnalytics";

const CookiesConsent = (props) => {
  const [show, setshow] = useState(true);
  const mode = useSelector((state) => state.themeType.themeType);
  const [showCookiesDetails, setshowCookiesDetails] = useState(false);
  const initialList = [
    {
      id: 1,
      label: "Targeting Cookies",
      description:
        "These cookies may be set through our site by our advertising partners. They may be used by those companies to build a profile of your interests and show you relevant adverts on other sites. They do not store directly personal information, but are based on uniquely identifying your browser and internet device. If you do not allow these cookies, you will experience less targeted advertising.",
      isOpen: false,
    },
    {
      id: 2,
      label: "Performance Cookies",
      description:
        "These cookies may be set through our site by our advertising partners. They may be used by those companies to build a profile of your interests and show you relevant adverts on other sites. They do not store directly personal information, but are based on uniquely identifying your browser and internet device. If you do not allow these cookies, you will experience less targeted advertising.",
      isOpen: false,
    },
    {
      id: 3,
      label: "Functional Cookies",
      description:
        "These cookies may be set through our site by our advertising partners. They may be used by those companies to build a profile of your interests and show you relevant adverts on other sites. They do not store directly personal information, but are based on uniquely identifying your browser and internet device. If you do not allow these cookies, you will experience less targeted advertising.",
      isOpen: false,
    },
    {
      id: 4,
      label: "Strictly Necessary Cookies",
      description:
        "These cookies may be set through our site by our advertising partners. They may be used by those companies to build a profile of your interests and show you relevant adverts on other sites. They do not store directly personal information, but are based on uniquely identifying your browser and internet device. If you do not allow these cookies, you will experience less targeted advertising.",
      isOpen: false,
    },
  ];
  const [preferenceList, setpreferenceList] = useState(initialList);
  const label = { inputProps: { "aria-label": "Switch demo" } };
  const closeBtn = (
    <button
      className={`close ${mode === "lightMode" ? "text-dark" : "text-white"}`}
      onClick={() => {
        setshow(false);
      }}
      type="button"
    >
      &times;
    </button>
  );
  const [search, setsearch] = useState("");
  const initialcookiesList = [
    {
      id: 1,
      label: "First Party Cookies",
      isOpen: false,
      details: [
        {
          name: "_ym_d",
          host: "JRN.com",
          duration: "364 Days",
          type: "First Party",
          category: "Targeting Cookies",
        },
        {
          name: "Nameamp_xxxxxx",
          host: "static.JRN.com",
          duration: "Session",
          type: "First Party",
          category: "Supports Amplitude product analytics.",
        },
      ],
    },
    {
      id: 2,
      label: ".facebook.com",
      isOpen: false,
      details: [
        {
          name: "_ym_d",
          host: "JRN.com",
          duration: "364 Days",
          type: "First Party",
          category: "Targeting Cookies",
        },
        {
          name: "Nameamp_xxxxxx",
          host: "static.JRN.com",
          duration: "Session",
          type: "First Party",
          category: "Supports Amplitude product analytics.",
        },
      ],
    },
    {
      id: 3,
      label: "static.coinall.ltd",
      isOpen: false,
      details: [
        {
          name: "_ym_d",
          host: "JRN.com",
          duration: "364 Days",
          type: "First Party",
          category: "Targeting Cookies",
        },
        {
          name: "Nameamp_xxxxxx",
          host: "static.JRN.com",
          duration: "Session",
          type: "First Party",
          category: "Supports Amplitude product analytics.",
        },
      ],
    },
  ];
  const [cookiesList, setcookiesList] = useState(initialcookiesList);
  const handleAllowAll=()=>{
    Cookies.set('cookieConsent', 'accepted', { expires: 365 });
    initGA();
    setshow(false);
  }
  return (
    <Modal  centered={true} isOpen={show} className={`${Styles.fullscreenModal} `}      
    modalClassName={Styles.modalDialog} >
      <ModalHeader
        close={closeBtn}
        title={props.popheaderTitle}
        className={`${props.classHeader} border-bottom  border-secondary ${
          mode === "lightMode" ? "bg-light text-dark" : "bg-dark text-white"
        } `}
      >
        <h1 className="font-weight-bold">JRN</h1>
      </ModalHeader>
      <ModalBody
        className={`${props.classBody} py-0 px-4 ${Styles.modal_body} scrollbar overflow-auto`}
        style={{
          backgroundColor: mode === "lightMode" ? "white" : "black",
          color: mode === "lightMode" ? "black" : "white",
        }}
      >
        {!showCookiesDetails ? (
          <div>
            <p className="font-weight-bold font-16">
              Privacy Preference Center
            </p>
            <p className="font-12 mb-0">
              When you visit any website, it may store or retrieve information
              on your browser, mostly in the form of cookies. This information
              might be about you, your preferences or your device and is mostly
              used to make the site work as you expect it to. The information
              does not usually directly identify you, but it can give you a more
              personalized web experience. Because we respect your right to
              privacy, you can choose not to allow some types of cookies. Click
              on the different category headings to find out more and change our
              default settings. However, blocking some types of cookies may
              impact your experience of the site and the services we are able to
              offer.
              <br />
              <a href="www.google.com" target="blank">
                More information
              </a>
            </p>
            <button className="btn bg-black text-white  my-4  px-4 py-2 font-weight-bold" onClick={handleAllowAll}>
              Allow All
            </button>
            <div>
              <p className="font-16 font-weight-bold ml-2">
                Manage Consent Preferences
              </p>
              <ul className="list-group">
                {preferenceList.map((elem, index) => (
                  <li
                    key={index}
                    className={`list-group-item p-2 rounded m-0 ${
                      elem.isOpen && "border border-dark"
                    }`}
                  >
                    <div className=" d-flex justify-content-between align-items-center">
                      <div className="d-flex align-items-center">
                        <span
                          onClick={() => {
                            setpreferenceList((previous) =>
                              previous.map((item) => {
                                if (item.id === elem.id) {
                                  item.isOpen = !item.isOpen;
                                } else {
                                  item.isOpen = false;
                                }
                                return item;
                              })
                            );
                          }}
                          className="pointer"
                        >
                          {elem.isOpen ? (
                            <FaMinus size={20} color="black" />
                          ) : (
                            <FaPlus size={20} color="black" />
                          )}
                        </span>
                        <p className="mb-0 font-14 font-weight-bold ml-2">
                          {elem.label}
                        </p>
                      </div>
                      {index === 3 ? (
                        <span className="text-primary font-weight-bold">
                          Always active{" "}
                        </span>
                      ) : (
                        <Switch {...label} />
                      )}
                    </div>
                    {elem.isOpen ? (
                      <p className={Styles.description}>{elem.description}</p>
                    ) : null}
                    {elem.isOpen ? (
                      <p
                        className={"text-primary font-14 pointer ml-3"}
                        onClick={() => setshowCookiesDetails(true)}
                      >
                        Cookies Details
                      </p>
                    ) : null}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        ) : (
          <div>
            <div className="d-flex align-items-center my-2">
              <span
                onClick={() => setshowCookiesDetails(false)}
                className="pointer"
              >
                <FaChevronLeft size={15} color="black" />
              </span>
              <p className="ml-3 font-16 font-weight-bold mb-1">Cookies List</p>
            </div>
            <div>
              <input
                style={{
                  borderRadius: "100px",
                  border: "1px solid white",
                  width: "27em",
                  backgroundColor:
                    mode === "lightMode" ? "rgb(238 239 239)" : "black",
                }}
                className={` navSearchInput ${
                  mode === "lightMode" ? "text-dark" : "text-white"
                }`}
                placeholder="Search"
                spellCheck="false"
                value={search}
                onChange={(e) => {
                  setsearch(e.target.value);
                }}
                onFocus={() => {
                  // setisshowsuggestCollection(true);
                }}
                onBlur={() => {
                  console.log("kya yh chala");
                }}
              />
              <span
                className="rounded rounded-circle bg-primary ml-2 pointer"
                style={{ padding: "14px" }}
              >
                <FaFilter size={15} color="white" className="p-0" />
              </span>
            </div>
            <div>
              {cookiesList.map((elem, index) => (
                <div className={`mt-1 ${
                  elem.isOpen && "border border-dark"
                }`}>
                  <div
                    key={index}
                    style={{ borderBottom: "1px solid #e2e2e2" }}
                    className={`p-3 my-3 d-flex align-items-center pointer  border-bottom  `}
                    onClick={() => {
                      setcookiesList((previous) =>
                        previous.map((item) => {
                          if (item.id === elem.id) {
                            item.isOpen = !item.isOpen;
                          }
                          return item;
                        })
                      );
                    }}
                  >
                    <span>
                      {elem.isOpen ? (
                        <FaMinus
                          className={mode === "lightMode" ? "black" : "white"}
                        />
                      ) : (
                        <FaPlus
                          className={mode === "lightMode" ? "black" : "white"}
                        />
                      )}
                    </span>
                    <div className="ml-4">
                      <p className="font-16 font-weight-bold mb-0">
                        {elem.label}
                      </p>
                      <p className="font-16 text-primary mb-0">View Cookies</p>
                    </div>
                  </div>
                  {elem.isOpen && (
                    <div>
                      {elem.details.map((item, index) => (
                        <div className="w-75 m-3" key={index}>
                          <div className="d-flex justify-content-between align-items-center">
                            <p className="font-12 my-2">Name</p>
                            <p className="font-12 my-2">{item.name}</p>
                          </div>
                          <div className="d-flex justify-content-between align-items-center">
                            <span className="font-12 my-2">Host</span>
                            <span className="font-12 my-2">{item.host}</span>
                          </div>
                          <div className="d-flex justify-content-between align-items-center">
                            <span className="font-12 my-2">Duration</span>
                            <span className="font-12 my-2">
                              {item.duration}
                            </span>
                          </div>
                          <div className="d-flex justify-content-between align-items-center">
                            <span className="font-12 my-2">Type</span>
                            <span className="font-12 my-2">{item.type}</span>
                          </div>
                          <div className="d-flex justify-content-between align-items-center">
                            <span className="font-12 my-2">Category</span>
                            <span className="font-12 my-2">
                              {item.category}
                            </span>
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        )}
      </ModalBody>
      <ModalFooter
        className={`${props.classFooter} border-top border-secondary d-flex flex-wrap align-items-center ${
          mode === "lightMode" ? "bg-light text-dark" : "bg-dark text-white"
        }`}
      >
        <button
          className="btn bg-black text-white font-12 font-weight-bold m-1 py-2 px-3"
          onClick={() => {
            Cookies.set('cookieConsent', 'rejected', { expires: 365 });
            setshow(false);
          }}
        >
          Reject All
        </button>
        <button className="btn bg-black text-white font-12 font-weight-bold m-1 py-2 px-3" onClick={()=>{
            Cookies.set('cookieConsent', 'accepted', { expires: 365 });
            initGA();
            setshow(false);
        }}>
          Confirm My Choices
        </button>
      </ModalFooter>
    </Modal>
  );
};

export default memo(CookiesConsent);
