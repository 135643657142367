import React, { useEffect, useRef, useState } from "react";
import { trace } from "firebase/performance";
import { sendPerfomance } from "../../..";
import { FaRegStar } from "react-icons/fa";
import InfiniteScroll from "react-infinite-scroll-component";
import { Loader2 } from "../../../commonComponent/Loader";
import { useNavigate } from "react-router-dom";
import pako from "pako";
import protofile from "../../../commonComponent/myProto.protobuf";
import { w3cwebsocket } from "websocket";
import * as protobuf from "protobufjs";
import { websocketBaseUrl } from "../../../config/API/Api";
import ReactGA from 'react-ga4';


export default function TableDatainOrdinal(props) {
  const {
    websocketMessage,
    mode,
    updateSize,
    size,
    innerWidth,
    data,
    updatedata
  } = props;
  const [isloading, setisloading] = useState(true);
  const socketRef = useRef(null);
  const [hasMore,sethasMore]=useState(true);
  const navigate=useNavigate();
   const convertBinaryToText = async (compressedData) => {
    try {
      const root = await protobuf.load(protofile);
      const VotingResponse = root.lookupType("voting.VotingResponseNew");
      let offset = 0;
      const binaryData = pako.inflate(new Uint8Array(compressedData), {
        to: "buffer",
      });
      let count = 0;
      while (offset < binaryData.length) {
        const messageLength = new DataView(
          new Uint8Array(binaryData.slice(offset, offset + 4)).buffer
        ).getUint32(0, true);
        offset += 4;
        count += 1;

        const protobufMessage = new Uint8Array(
          binaryData.slice(offset, offset + messageLength)
        );
        try {
          const decodedMessage = VotingResponse.toObject(
            VotingResponse.decode(protobufMessage),
            { defaults: true }
          );
          updatedata(decodedMessage?.data);
        } catch (error) {
          console.error("Error decoding binary data:", error);
        }
        offset += messageLength;
      }
    } catch (err) {
      console.error("Error loading schema file or decoding data:", err);
    }
  };

  useEffect(() => {
    if (!socketRef.current) {
      socketRef.current = new w3cwebsocket(websocketBaseUrl);
      const t = trace(sendPerfomance, "custom_Trace_name");
      t.start();

      socketRef.current.onopen = () => {
        setisloading(true);
        socketRef.current.send(JSON.stringify(websocketMessage));
      };

      socketRef.current.onmessage = (event) => {
        const message = JSON.parse(event.data);
        convertBinaryToText(message);
        setisloading(false);
      };

      socketRef.current.onerror = (error) => {
        console.error("WebSocket error:", error);
      };
      t.stop();
      t.putAttribute("page", "/");

      return () => {
        if (socketRef.current) {
          socketRef.current.close();
          socketRef.current = null;
        }
      };
    }
  }, [websocketMessage]);
  const handleClick = (row) => {
    console.log(row);
    ReactGA.event({
      category: 'clicked on a collection in ordinal collection ',
      action:'clicked collection',
      label: row?.name,
      nonInteraction:false,
      value:JSON.stringify(row)
    })
    navigate(`/collection/${row.contractId}/overview`, {state:{
      userData: row,
      blockchain: row?.blockchain,
      image_url: row?.imageUrl,
    }});
  };
  return (
    <InfiniteScroll
      hasMore={hasMore}
      dataLength={data === undefined ? [].length : data.length}
      next={() => {
        if (data.length % 50 === 0 && !isloading) {
          setisloading(true);
          updateSize(size + 20);
        }else{
          sethasMore(false);
        }
      }}
      style={{ overflowX: "hidden", innerWidth: "100%" }}
    >
      <div id="tableContainer">
        <table className="table table-hover">
          <thead>
            <tr style={{ opacity: 0.7 }}>
              <th
                scope="col"
                style={{
                  fontSize: "14px",
                  color: "#BDBDBD",
                  textAlign: "center",
                }}
              >
                Tick
              </th>
              {innerWidth > 768 && (
                <th
                  scope="col"
                  style={{
                    fontSize: "14px",
                    color: "#BDBDBD",
                    textAlign: "center",
                  }}
                >
                  Volume
                </th>
              )}
              <th
                scope="col"
                style={{
                  fontSize: "14px",
                  color: "#BDBDBD",
                  textAlign: "center",
                }}
              >
                Price
              </th>
              {innerWidth > 1024 && (
                <th
                  scope="col"
                  style={{
                    fontSize: "14px",
                    color: "#BDBDBD",
                    textAlign: "center",
                  }}
                >
                  Market Cap
                </th>
              )}
              <th
                scope="col"
                style={{
                  fontSize: "14px",
                  color: "#BDBDBD",
                  textAlign: "center",
                }}
              >
                Change
              </th>
              {innerWidth > 1024 && (
                <th
                  scope="col"
                  style={{
                    fontSize: "14px",
                    color: "#BDBDBD",
                    textAlign: "center",
                  }}
                >
                  Num of Sales
                </th>
              )}
              {innerWidth > 1024 && (
                <th
                  scope="col"
                  style={{
                    fontSize: "14px",
                    color: "#BDBDBD",
                    textAlign: "center",
                  }}
                >
                  Holders
                </th>
              )}
              {innerWidth > 1024 ? (
                <th
                  scope="col"
                  style={{
                    fontSize: "14px",
                    color: "#BDBDBD",
                    textAlign: "center",
                  }}
                >
                  ---
                </th>
              ) : null}
            </tr>
          </thead>
          <tbody>
            {data.map((elem, index) => (
              <tr
                key={index}
                onClick={() => {
                  handleClick(elem);
                }}
              >
                <td className="d-flex align-items-center">
                  <p className="mb-0 ml-2 font-14 mr-2">{index + 1}</p>
                  <img
                    className="rounded-circle"
                    src={elem?.imageUrl}
                    width="32px"
                    height="32px"
                    alt="..."
                  />
                  <p className="mb-0 ml-2  font-weight-bold " style={{fontSize:innerWidth>768?"18px":'16px'}}>
                    {
                      elem.name!==undefined?
                      innerWidth>768?
                      elem.name.length>10?elem.name.slice(0,10)+'...':elem.name
                      :
                      elem.name.length>4?elem.name.slice(0,4)+'...':elem.name
                      :null
                    }
                  </p>
                </td>
                {innerWidth > 768 && (
                  <td className="font-14  text-center">{elem?.volume}</td>
                )}
                <td className="font-14 text-center">
                  {Number(elem?.floorPrice)?.toFixed(2)}
                </td>
                {innerWidth > 1024 && (
                  <td className="font-14 text-center">{elem?.ownedBy}</td>
                )}
                <td
                  className="font-14 text-center"
                  style={{ color: "#31BD65", fontWeight: "600" }}
                >
                  {Number(elem?.percentChangeVolumes)?.toFixed(2)}%
                </td>
                {innerWidth > 768 && (
                  <td className="font-14 text-center">{elem?.sales}</td>
                )}
                {innerWidth > 1024 && (
                  <td className="font-14 text-center">{elem?.ownedBy}</td>
                )}
                {innerWidth > 1024 && (
                  <td className="text-center">
                    <FaRegStar
                      size={20}
                      color={mode === "lightMode" ? "black" : "white"}
                    />
                  </td>
                )}
              </tr>
            ))}
          </tbody>
        </table>
        {data.length === 0 && !isloading && (
          <h4 className="text-center">No Data Found</h4>
        )}
        {isloading && <div className="d-flex justify-content-center align-items-center"><Loader2 size={25} color={mode==="lightMode"?"black":'white'}/></div>}
      </div>
    </InfiniteScroll>
  );
}
