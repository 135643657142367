import React from 'react'
import { useSelector } from 'react-redux';
import {
    ComposedChart,
    Line,
    Area,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer,
    BarChart
  } from 'recharts';
  import { Chip, Stack } from "@mui/material";

export default function CustomLineVolume(props) {
const mode=useSelector(state=>state.themeType.themeType);
const data1 = [
    {
      name: "Page A",
      uv: 4000,
      pv: 2400,
      amt: 2400,
    },
    {
      name: "Page B",
      uv: 3000,
      pv: 1398,
      amt: 2210,
    },
    {
      name: "Page C",
      uv: 2000,
      pv: 9800,
      amt: 2290,
    },
    {
      name: "Page D",
      uv: 2780,
      pv: 3908,
      amt: 2000,
    },
    {
      name: "Page E",
      uv: 1890,
      pv: 4800,
      amt: 2181,
    },
    {
      name: "Page F",
      uv: 2390,
      pv: 3800,
      amt: 2500,
    },
    {
      name: "Page G",
      uv: 3490,
      pv: 4300,
      amt: 2100,
    },
    {
      name: "Page G",
      uv: 3490,
      pv: 4300,
      amt: 2100,
    },
    {
      name: "Page G",
      uv: 3490,
      pv: 4300,
      amt: 2100,
    },
    {
      name: "Page G",
      uv: 3490,
      pv: 4300,
      amt: 2100,
    },
    {
      name: "Page G",
      uv: 3490,
      pv: 4300,
      amt: 2100,
    },
  ];



  return (
    <div
    className={`row my-4 py-4 ${
      mode === "lightMode" ? "bg-white text-dark" : "bg-dark text-white"
    } `}
  >
    <div className="col">
      <div className="d-flex justify-content-between mb-2">
        <div>
          <p>{props.header}</p>
          <div className="d-flex flex-wrap">
            {
                props.box.map((elem,index)=>{
                    return (
                        <span
                        key={index}
                        className={`rounded mx-2 p-2 ${
                          mode === "lightMode"
                            ? "bg-light text-dark"
                            : "bg-black text-white"
                        }`}
                      >
                        <p className="text-secondary">{elem.title}</p>
                        <p className="m-0 p-0">
                          {elem.value}{" "}
                          <span className={`${elem.value1Class} font-weight-bold m-1`}>
                            {elem.value1}
                          </span>
                        </p>
                      </span>
                    )
                })
            }
          </div>
        </div>

        <Stack direction="row" spacing={1} className="d-flex flex-wrap">
            {
                props.filters.map((elem,index)=>{
                    return (
                        <Chip
                        key={index}
                        label={elem.label}
                        onClick={() => {
                          elem.onclick(elem.label);
                        }}
                        className={`mr-1 ${
                          mode === "lightMode" ? "text-dark" : "text-white"
                        }`}
                      />
                    )
                })
            }
        </Stack>
      </div>
      <div >
      <ResponsiveContainer width="99%" height={250}>
        <ComposedChart width={"100%"} height={200} data={props.data} 
        margin={{
          top: 20,
          right: 20,
          bottom: 20,
          left: 20,
        }}
        barGap={10}
        >y
        <XAxis dataKey={props.xDatakey}/>
        <YAxis dataKey={props.yDatakey}/>
        <Legend/>
        <Tooltip
         contentStyle={{backgroundColor:mode==="lightMode"?"black":"white",color:mode==="lightMode"?"white":"black",borderRadius:"10px"}}
        />
          {props.children}
        </ComposedChart>
      </ResponsiveContainer>
      </div>
    </div>
  </div>
  )
}
