import React, { useEffect, useState } from 'react'
import 'strophejs-plugin-muc';
import { Strophe,$msg,$iq,$pres } from 'strophe.js';
import 'strophejs-plugin-roster'
import 'strophejs-plugin-mam'; 
import axios from 'axios';
import { REACT_APP_CHAT_SERVER_URL } from '../../../config/API/Api';

export default function Groupchat() {
  const server =REACT_APP_CHAT_SERVER_URL!==undefined? new Strophe.Connection(REACT_APP_CHAT_SERVER_URL):"";
  const [connection,setconnection]=useState(null);

  useEffect(()=>{
   if(server!==""||server!==undefined){
    server.connect('u1@localhost', 'password', (status) => {
      if (status === Strophe.Status.CONNECTED) {
        alert("connected");
        setconnection(server);
        server.addHandler((res)=>{
          console.log(res,'this is requesnt')
          return true
        },null,"message")
  
        
        server.addHandler((res)=>{
          console.log(res)
          return true
        },null,"presence")
        
        server.send($pres().tree());
      }
    });
   }
  },[])
  const handleClick=()=>{
      const iq=$iq({
    from:'u1@localhost',
    to:'pg1@conference.localhost',
    type:'get'
  }).c('query',{xmlns:"http://jabber.org/protocol/disco#items"})
connection.sendIQ(iq,res=>console.log('res', res),err=>console.log('err', err))
  }

  const handlegetroom=()=>{
  const data={
    name:"pg5",
    service:"conference.localhost"
  }
try {
  axios.post('http://localhost:5443/api/get_room_affiliations',data).then((res)=>{console.log(res)}).catch(err=>{console.log(err)});
} catch (error) {
  console.log(error)
}





  }






  return (
    <div>
      <button className='btn btn-primary' onClick={handlegetroom}>Get Chat History</button>
      <button className='btn btn-primary' onClick={handleClick}>Get Chat History</button>








    </div>
  )
}
