import React,{useState,useEffect} from "react";
import bannerImg from '../../assets/bannerImage.jpeg'
import subscriptionImg from '../../assets/subscriptionImg.webp'
import { useSelector } from "react-redux";
import Styles from './listItemDetails.module.css'
import styles from './EventList.module.css'
import { FaBeer ,FaAngleDown,FaAngleUp } from "react-icons/fa";
import { LuDownload } from "react-icons/lu";
import fireImg from '../../assets/fireicon.png';
import { MdChevronRight } from "react-icons/md";
import dummy from '../../assets/dummy.jpg'

const ListItemDetailsScreen = ({ navigation }) => {
  const [windowWidth, setwindowWidth] = useState(window.innerWidth);
  const mode = useSelector((state) => state.themeType.themeType);
  const initialTweetitems=[
    {   
        id:1,
        title:'Follow @Layer3xyz on X',
        isvisible:true,
        button:[
            {title:'Follow',onclick:()=>{}},
            {title:'Verify',onclick:()=>{}}
        ]
    },
    {   
        id:2,
        title:'Repost the tweet of @Layer3xyz',
        isvisible:true,
        button:[
            {title:'Retweet',onclick:()=>{}},
            {title:'Verify',onclick:()=>{}}
        ]
    },
    {   
        id:3,
        title:'Follow @Layer3xyz on X',
        isvisible:true,
        button:[
            {title:'Like',onclick:()=>{}},
            {title:'Verify',onclick:()=>{}}
        ]
    },
    {   
        id:4,
        title:'Follow @Layer3xyz on X',
        isvisible:true,
        button:[
            {title:'Like',onclick:()=>{}},
            {title:'Verify',onclick:()=>{}}
        ]
    },
]
  const [tweetItems,setTweetItem]=useState(initialTweetitems)
  useEffect(() => {
    const handleResize = () => {
      setwindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  //LEFT SIDE SCREEN
  const renderLFirstSection=()=>{
    return(
        <div >
            <img className={` ${Styles.banner_img} img-responsive`} src={bannerImg} alt="..." height={'208px'}/>
        </div>
    )
  }
  const renderLSecondSection=()=>{
    return (
        <div className="my-3">
            <img src={subscriptionImg} alt="..." height={'24px'} width="130px"/>
        </div>
    )
  }

  const renderLThirdSection=()=>{
    return (
        <div className="d-flex justify-content-between align-items-center">
            <div className="d-flex align-items-center">
                <span className="" style={{fontWeight:'600'}}>06/27/2024 ~ 07/03/2024</span>
                <span className="px-4 font-12 ml-2 " style={{backgroundColor:'#bcff2f', borderRadius:'18px'}}>Live</span>
            </div>
            <div className="d-flex align-items-center">
                <span className={`pointer mr-3 px-2 py-1  ${Styles.icon_img}`}>
                    <FaBeer size={15} />
                </span>
                <span className={`pointer  px-2 py-1 ${Styles.icon_img}`}>
                    <LuDownload size={15} />
                </span>
            </div>

        </div>
    )
  }

  const renderLFourthSection=()=>{
    return (
        <section className="">
            <div className="d-flex align-items-center">
                <img src={fireImg} alt="..." width={'24px'} height={'24px'}/>
                <h2 className="font-weight-bold ml-2 mb-0" style={{fontWeight:'28px'}}>Layer3 NFT quest perks</h2>
            </div>
            <div className="my-3">
                <h4 className="font-weight-bold font-20">Complete JRN Web3 tasks</h4>
            </div>
            <div className={`px-2 align-items-center d-flex justify-content-between py-4 ${Styles.event_container}`}>
                <h5 className="font-weight-bold font-16 px-3 m-0">Hold ≥ 0.001 ETH during the event</h5>
                <MdChevronRight size={20} />
            </div>
        </section>
    )
  }

  const renderLFifthSection=()=>{
    
    return (
        <section className="mt-5 mb-5">
            <div className="d-flex align-items-center justify-content-between">
                <span><h4 className="font-20 font-weight-bold">Complete X tasks</h4></span>
                <span className="px-4 border-secondary border" style={{borderRadius:'18px'}}>Connect</span>
            </div>
            <div className=" my-1">
                {
                   tweetItems.map((o,index)=>(
                       <div className={`${Styles.event_container} my-2 py-3 px-3 pointer`} key={index} onClick={()=>{setTweetItem((previous)=>{
                       return previous.map((i)=>{
                            if(i.id===o.id){
                                i.isvisible=!i.isvisible
                            }
                            return i;
                        })
                       })}} >
                        <div className="d-flex align-items-center justify-content-between">
                          <h5 className="font-weight-bold font-16">{o.title}</h5>
                          {
                            o.isvisible?<FaAngleDown size={15} />:<FaAngleUp size={15} />
                          }
                          
                        </div>
                          {o.isvisible&&o.button?.map((i,ind)=><button className={` bg-black text-white ${mode==='lightMode'?"":"border border-secondary"} ${Styles.tweet_button}`} key={ind} onClick={i.onclick} >{i.title}</button>)}
                      </div>))

                }
            </div>
        </section>
    )
  }

  const renderLSixthSection=()=>{
    return (
        <section className="my-5">
            <h5 className="font-weight-bold">About this event</h5>
            <p className={`${Styles.description}`}>Dive into the Layer3 × JRN: Genesis Quest Collection, exclusively on JRN Drops. This limited-edition NFT series grants holders unique perks including early access to exclusive Layer3 quests and premium rewards.</p>
        </section>
    )
  }

  //RIGHT SIDE SCREEN

  const renderRFirstSection=()=>{
    return (
        <section>
            <div>
            <div className="media">
                <img  src={bannerImg} alt="Generic placeholder image" width={"84px"} height={'84px'} className="mr-3 rounded rounded-circle"/>
                <div className="media-body">
                    <h5 className="mt-0 font-weight-bold">Layer3</h5>
                    <div className="d-flex align-items-center my-2">
                        {[1,1,1].map((o,index)=> <span className={`mr-1 rounded rounded-circle  p-2 d-flex justify-content-center align-items-center ${Styles.icon_img}`} style={{boxShadow:"0px 0px 5px 0px rgb(216, 213, 213)"}} key={index}>
                        <FaBeer size={15} />
                        </span>)}
                    </div>
                </div>
            </div>
            </div>
        </section>
    )
  }

  const renderRSecondSection=()=>{
    return (
        <section className="my-5">
            <p className="font-14 text-center mb-2" style={{color:'#3D3D3D',fontWeight:'500'}}>Subscription ends in</p>
            <div className="d-flex justify-content-between align-items-center px-4">
                <div className="text-center">
                    <div style={{boxShadow:"0px 0px 5px 0px rgb(216, 213, 213)",fontSize:'28px',borderRadius:'18px',backgroundColor:'#fafafa'}} className=" p-2 px-3"><p className="mb-0  font-weight-bold">04</p></div>
                    <span className="font-12 ">D</span>
                </div>
                <div className="text-center">
                    <div style={{boxShadow:"0px 0px 5px 0px rgb(216, 213, 213)",fontSize:'28px',borderRadius:'18px',backgroundColor:'#fafafa'}} className=" p-2 px-3"><p className="mb-0  font-weight-bold">04</p></div>
                    <span className="font-12 ">h</span>
                </div>
                <div className="text-center">
                    <div style={{boxShadow:"0px 0px 5px 0px rgb(216, 213, 213)",fontSize:'28px',borderRadius:'18px',backgroundColor:'#fafafa'}} className=" p-2 px-3"><p className="mb-0  font-weight-bold">04</p></div>
                    <span className="font-12 ">m</span>
                </div>
                <div className="text-center">
                    <div style={{boxShadow:"0px 0px 5px 0px rgb(216, 213, 213)",fontSize:'28px',borderRadius:'18px',backgroundColor:'#fafafa'}} className=" p-2 px-3"><p className="mb-0  font-weight-bold">04</p></div>
                    <span className="font-12 ">s</span>
                </div>
            </div>
            <div className="my-3">
                <button className={`btn btn-block py-3 ${mode==='lightMode'?"":"border border-secondary"} bg-black text-white`} style={{borderRadius:'28px',fontWeight:'600'}}>Subscribe</button>
            </div>
            <div className={`d-flex justify-content-between align-items-center my-3 ${Styles.icon_img} ${Styles.event_container} py-3 px-2` }>
                <div><p className="mb-0 font-weight-bold font-14 pl-1">Participants (39,739)</p></div>
                <div className="d-flex align-items-center">
                    <span style={{backgroundColor:'red'}} className={`${Styles.colorBall}`}></span>
                    <span style={{backgroundColor:'yellow'}} className={`${Styles.colorBall}`}></span>
                    <span style={{backgroundColor:'green'}} className={`${Styles.colorBall}`}></span>
                    <MdChevronRight size={20} />
                </div>
            </div>
        </section>
    )
  }

  const renderRThirdSection=()=>{
    return (
        <section>
            <div className="d-flex align-items-center my-3">
                <img src={fireImg} className="mr-2" width={'28px'} height={'28px'} />
                <h5 className="font-weight-bold font-24 mb-0">Rewards</h5>
            </div>
            <p className="font-16 font-weight-bold">Complete tasks to claim rewards</p>
            {customCard()}
        </section>
    )
  }
  const customCard=(item,index)=>{
    return (
      <div onClick={()=>{}} className={`w-100 ${styles.listItem} ${mode === 'lightMode' ? "bg-light text-dark" : 'bg-black text-white'}`} >
    <div className={`my-2 ${styles.eventListItem}`}>
      <div className="card border-0" style={{ width: 'auto' }}>
        <img className={`card-img-top `} loading='lazy' src={dummy} alt="Card image cap" />
        <div className="card-body px-1 pt-1">
          <h5 className={`card-title font-28 font-weight-bold`}>Layer3 × JRN Genesis NFT</h5>
          <div className='d-flex align-items-center'>
            <span className='font-weight-bold text-success font-20'>Free</span>
          </div>
          <div className={``}>
            <div className='d-flex align-items-center justify-content-between my-3'>
              <span style={{ color: '#6E6E6E' }} className='font-16 mr-2'>Highest floor price</span>
              <span className='font-weight-bold font-14'>--SOL</span>
            </div>
            <div className='d-flex align-items-center justify-content-between my-3'>
              <span style={{ color: '#6E6E6E' }} className='font-16 mr-2'>Initial price</span>
              <span className='font-weight-bold font-14'>5 SOL</span>
            </div>
          </div>
          <div className='d-flex justify-content-between align-items-center'>
            <button className={`btn btn-block ${Styles.event_container}`}> View on Market Place</button>
          </div>
        </div>
      </div>
    </div>
  </div> 
    )
}


  return (
    <section style={{padding: windowWidth > 768 ? "0px 135px 0px" : "0px 50px 0px"}} className={`${mode === "lightMode" ? "" : "bg-black text-white"}`}>
        <div className="row">
            <div className="col-12 col-xl-8 " id="leftSection">
                <div className="pl-2 pr-5 py-5 overflow-auto scrollbar  mr-4" style={{maxHeight:"100vh",borderRight:windowWidth>1200&&'1px solid #dbdbdb'}}>
                    {renderLFirstSection()}
                    {renderLSecondSection()}
                    {renderLThirdSection()}
                    {renderLFourthSection()}
                    {renderLFifthSection()}
                    {renderLFirstSection()}
                    {renderLSixthSection()}
                </div>
            </div>
            <div className="col-12 col-xl-4" id="rightSection">
                <section className="pl-2 py-5 overflow-auto scrollbar" style={{maxHeight:"100vh"}}>
                    {renderRFirstSection()}
                    {renderRSecondSection()}
                    {renderRThirdSection()}
                    {renderLSixthSection()}
                </section>
            </div>
        </div>
    </section>
  );
};

export default ListItemDetailsScreen;
