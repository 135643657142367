import React, { useState, useEffect, useMemo } from "react";
import "rc-tooltip/assets/bootstrap.css";
import "./Nav.css";
import Tooltip from "rc-tooltip";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { ActionCreator } from "../../redux/Actions/ActionCreator";
import { toast } from "react-toastify";
import {
  VerifySignature,
  metamaskregisteruser,
} from "../Metamaskwallet/Metamask";
import { FaArrowRight, FaRegEnvelope } from "react-icons/fa";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import CustomPopup from "../../commonComponent/CustomPopup";
import Loader, { CustomovalLoader } from "../../commonComponent/Loader";
import { decodeJWT } from "./CustomFunction";
import axios from "axios";
import loader from "../../assets/loader.gif";
import { CustomDropDownComponent } from "../../commonComponent/CustomDropdown";
import { trace } from "firebase/performance";
import { sendPerfomance } from "../..";
import { IoMoonOutline } from "react-icons/io5";
import { MdOutlineMoreHoriz } from "react-icons/md";
import { CiWallet } from "react-icons/ci";
import { FiSun, FiSearch } from "react-icons/fi";
import { IoMdClose } from "react-icons/io";
import loginwalletImage from "../../assets/login-wallet-popup.webp";
import OtpInput from "react-otp-input";
import metamaskIcon from "../../assets/metamask-icon.png";
import CustomClock from "../../commonComponent/CustomClock";
import { tracer } from "../../tracing";
import bluetick from "../../assets/blue_Tick.png";
import Axios, { REACT_APP_EMAIL_BASE_URL } from "../../config/API/Api";
import fireicon from "../../assets/fireicon.png";
import BitcoinIcon from "../../assets/bitcoin.png";
import allicon from "../../assets/allIcon.webp";
import etherumIcon from "../../assets/ethereumIcon.webp";
import baseicon from "../../assets/baseIcon.webp";
import polygonIcon from "../../assets/polygonIcon.webp";
import flowIcon from "../../assets/flowIcon.png";
import arbitrumIcon from "../../assets/ArbitrumIcon.png";
import chilizicon from "../../assets/ChilizIcon.jpeg";
import lightlinkicon from "../../assets/LightLinkIcon.jpeg";
import immutablexicon from "../../assets/Immutablexicon.webp";
import { GoogleTrackEvent, trackButtonClick } from "../../utils/utility/GAnalytics";
import { checkValueexistornot } from "../../utils/utility/Utility";
import CustomMuiAccordian from "../../commonComponent/CustomMuiAccordian";
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
export default function Nav() {
  // const [data, setdata] = useState({ address: "", Balance: null });
  const themeMode = useSelector((state) => state.themeType.themeType);
  const [showpopup, setshowpopup] = useState(false);
  const [connection, setConnection] = useState(false);
  const [email, setemail] = useState("");
  const [showsearchIcon, setshowsearchIcon] = useState(false);
  const [showNavbar, setShowNavbar] = useState(false);
  const updateshowNavbar=(val)=>{setShowNavbar(val)}
  const [otp, setotp] = useState("");
  const [showotp, setshowotp] = useState(false);
  const [sessionKey, setsessionKey] = useState("");

  const [showemailpopup, setshowemailpopup] = useState(false);
  const [isotploading, setisotploading] = useState(false);

  const [loginData, setloginData] = useState(null);
  const navigate=useNavigate();
  const [search, setsearch] = useState("");
  //Login popup credential
  const [showLoginpopup, setshowloginpopup] = useState(false);
  const [showloginotp, setshowloginotp] = useState(false);
  const [username, setusername] = useState("");
  const [loginotp, setloginotp] = useState("");
  const [isloading, setisloading] = useState(false);
  const [isdisable, setisdisable] = useState(true);
  const [loginSessionKey, setloginsessionKey] = useState("");
  const [currentMetamskUser, setcurrentMetamaskuser] = useState("");
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  //treding collection state
  const [TrandingCollection, settrandingCollection] = useState([]);
  const [istrandingCollectionloading, setistrandingcollectionloading] =
    useState(false);
  const [isfocusedonsearchpopup, setisfocusedonsearchpopup] = useState(false);
  //wallet popup
  const [isshowwalletpopup, setisshowwalletpopup] = useState(false);
  const [isshowsuggestCollection, setisshowsuggestCollection] = useState(false);
  const [searchCollectionDeatil, setsearchCollectionDeatil] = useState([]);
  const [searchAssetList, setsearchAssetList] = useState([]);
  const [searchOrdinalList, setsearchordinallist] = useState([]);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [currentLink,setcurrentLink]=useState(null)
  const [isSearchCollectionLoading, setisSearchCollectionLoading] =
    useState(false);
  const iconList = [
    {
      key: "bitcoin",
      image_url: BitcoinIcon,
    },
    {
      key: "all",
      image_url: allicon,
    },
    {
      key: "ethereum",
      image_url: etherumIcon,
    },
    {
      key: "base",
      image_url: baseicon,
    },
    {
      key: "polygon",
      image_url: polygonIcon,
    },
    {
      key: "flow",
      image_url: flowIcon,
    },
    {
      key: "arbitrum",
      image_url: arbitrumIcon,
    },
    {
      key: "chiliz",
      image_url: chilizicon,
    },
    {
      key: "lightlink",
      image_url: lightlinkicon,
    },
    {
      key: "immutablex",
      image_url: immutablexicon,
    },
  ];
  const handleNavbarToggle = () => {
    setShowNavbar(!showNavbar);
  };
  const returnimagebehalfblockchain = (key, style) => {
    const existkey = iconList.filter((elem) => {
      return elem.key === key;
    });
    if (existkey.length > 0) {
      return (
        <img
          style={style}
          width="16px"
          height={"16px"}
          src={existkey[0].image_url}
          alt="..."
        />
      );
    } else {
      return null;
    }
  };

  const dispatch = useDispatch();

  //stopwatch
  const [istokenExpired, setistokeExpired] = useState(false);
  const updateTokenExpired = (val) => {
    setistokeExpired(val);
    if (val) {
      setotp("");
    }
  };
  const toolTipmessage = (msg) => {
    return (
      <div className="bg-dark text-white  rounded">
        <p>{msg}</p>
      </div>
    );
  };
  async function fetchAccount() {
    try {
      const accounts = await window.ethereum.request({
        method: "eth_requestAccounts",
      });
      const account = accounts[0];
      setcurrentMetamaskuser(account);
      window.ethereum.on("accountsChanged", (accounts) => {
        const newAccount = accounts[0];
        setcurrentMetamaskuser(newAccount);
      });
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    TopCollectionApi();
    const add = localStorage.getItem("userAddress");
    if (add === null || add === undefined || add === "") {
      setConnection(false);
    } else {
      setConnection(true);
    }
  }, []);

  const CustomTooltip = (props) => {
    return (
      <Tooltip
        placement={props.placement}
        overlay={toolTipmessage(props.msg)}
        overlayStyle={{ width: "auto" }}
      >
        {props.isIcon ? (
          props.header
        ) : (
          <p
            style={{ fontSize: "12px" }}
            className={`${
              themeMode === "lightMode" ? "text-dark" : "text-white"
            }`}
          >
            {props.header}
          </p>
        )}
      </Tooltip>
    );
  };

  const handleThemeMode = () => {
    if (themeMode === "lightMode") {
      dispatch(ActionCreator.dark_theme_mode({ type: "dark_theme_mode" }));
    } else {
      dispatch(ActionCreator.light_theme_mode({ type: "light_theme_mode" }));
    }
  };

  const handleLogout = () => {
    localStorage.removeItem("accessToken");
    localStorage.setItem("userAddress", "");
    setConnection(false);
    navigate('/')
    dispatch(ActionCreator.change_eth_balance(""));
  };

  const loggedinuser = () => {
    return (
      <CustomDropDownComponent
        items={[
          {
            label: "profile",
            onclick: (val) =>navigate("/profile/dashboard"),
          },
          { label: "Message", onclick: (val) => console.log(val) },
          { label: "Logout", onclick: (val) => handleLogout() },
        ]}
      />
    );
  };

  const handleconnection = async () => {
    // const cookie=new Cookies();
    console.log("yaha aaya kai nahi");
    setshowpopup(false);
    let b = await VerifySignature(currentMetamskUser);
    if (Object.keys(b).length !== 0) {
      localStorage.setItem("token", b.data.AuthenticationResult?.AccessToken);
      // Cookies.set('token', b.data.AuthenticationResult?.AccessToken,{expires: currentDate + 3000})
      // dispatch(ActionCreator.change_eth_balance(0));
      setConnection(true);
    } else {
      setConnection(false);
    }
  };
  const closeBtn = (
    <button
      className="close"
      onClick={() => {
        setshowpopup(!showpopup);
        setshowotp(false);
      }}
      type="button"
    >
      &times;
    </button>
  );
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup function to remove the event listener when component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // SendOtpRequestApi
  const sendOtpRequestApi = async () => {
    let data = null;
    const url = REACT_APP_EMAIL_BASE_URL + "emaillogin";
    const body = JSON.stringify({ email });
    const headers = {
      "x-api-key": "HGFygcctFy5fQk2xB1RrY7Y0tOqmBxGv2PO7LLOD",
    };
    const t = trace(sendPerfomance, "EmailLoginUrl");
    const span = tracer.startSpan("EmailLoginApi");
    t.start();
    try {
      await axios
        .post(url, body, { headers })
        .then((res) => {
          span.addEvent("api_response", {
            status: res.status,
            data: res.data,
            url,
          });
          if (res.status === 200) {
            data = res.data;
            setisotploading(false);
          }
        })
        .catch((error) => {
          toast.error(error.message);
          setisotploading(false);
          span.setStatus({ code: "ERROR", message: error?.message });
          span.end();
        })
        .finally(() => {
          span.end();
        });
    } catch (error) {
      toast.error(error.message);
      setisotploading(false);
    }
    t.stop();
    return data;
  };

  // LoginRequestApi
  const LoginRequestApi = async (sessionkey = "") => {
    let data = null;
    if (sessionkey === "") {
      toast.error("Sessiontoken is invalid");
    } else {
      const url = REACT_APP_EMAIL_BASE_URL + "confirmemaillogin";
      const headers = {
        "x-api-key": "HGFygcctFy5fQk2xB1RrY7Y0tOqmBxGv2PO7LLOD",
      };
      const body = JSON.stringify({
        email: email,
        session: sessionkey,
        answer: otp,
      });
      const t = trace(sendPerfomance, "ConfirmEmailLoginUrl");
      const span = tracer.startSpan("ConfirmEmailLoginApi");
      t.start();
      try {
        await axios
          .post(url, body, { headers })
          .then((res) => {
            span.addEvent("api_response", {
              status: res.status,
              data: res.data,
              url,
            });
            if (res.status === 200) {
              data = res.data;
              setotp("");
            }
          })
          .catch((err) => {
            span.setStatus({ code: "ERROR", message: err?.message });
            span.end();
            setshowotp(true);
            if (err.response.status === 406) {
              toast.error(err.message + " OTP is invalid please check");
              console.log(err.response.status);
            } else {
              toast.error(err.response.data.message);
            }
          })
          .finally(() => {
            span.end();
          });
      } catch (error) {
        toast.error(error.message);
        setshowotp(false);
        setotp("");
        console.log("error", error);
      }
      t.stop();
    }

    return data;
  };

  // on wallet login
  const handlesendOtp = async () => {
    setisotploading(true);
    if (/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(email)) {
      const data = await sendOtpRequestApi();
      if (data) {
        console.log(data, "this is what we are getting");
        setsessionKey(data?.Session);
        setshowotp(true);
        setisotploading(false);
      } else {
        setshowotp(false);
        setisotploading(false);
      }
    } else {
      toast.warning("Invalid Email");
      setisotploading(false);
    }
  };

  const handleLogin = async () => {
    if (otp.length === 0) {
      toast.warning("Please Enter OTP");
      return;
    }
    if (otp.length > 6 || otp.length < 6) {
      toast.warning("otp should be 6 digit");
      setisotploading(false);
    } else {
      setisotploading(true);
      const finaldata = await LoginRequestApi(sessionKey);
      if (!finaldata || finaldata === null || finaldata === undefined) {
        setisotploading(false);
        setotp("");
      } else {
        setotp("");
        toast.success("User Logged in Successfully");
        setisotploading(false);
        localStorage.setItem(
          "token",
          finaldata.AuthenticationResult.AccessToken
        );
        setConnection(true);
        setshowemailpopup(false);
        setemail("");
        console.log(finaldata, "thisis data");
        const decodeData = decodeJWT(
          finaldata.AuthenticationResult.AccessToken
        );
        if (decodeData || decodeData !== null || decodeData !== undefined) {
          setloginData(decodeData);
        } else {
          setloginData(null);
        }
      }
    }
  };

  const toggleEmailpopup = () => setshowemailpopup(!showemailpopup);

  //=>>>>>>>>>>>>>>>>>>>>>REQUESTED API FOR LOGIN===>>>>>>>
  const requestedAPIregisterUser = async () => {
    const url = REACT_APP_EMAIL_BASE_URL + "emaillogin";
    setisloading(true);
    const t = trace(sendPerfomance, "RegisterEmailLoginUrl");
    const span = tracer.startSpan("RegisterEmailLoginAPI");
    t.start();
    try {
      await axios
        .post(url, { email: username })
        .then((res) => {
          span.addEvent("api_response", {
            status: res.status,
            data: res.data,
            url,
          });
          console.log(res);
          if (res.status === 200) {
            setshowloginotp(true);
            setisloading(false);
            toast.success("confirm the user");
            setloginsessionKey(res.data?.Session);
          }
        })
        .catch((err) => {
          toast.warning(err.response.data?.message);
          setisloading(false);
          span.setStatus({ code: "ERROR", message: err?.message });
          span.end();
        })
        .finally(() => {
          span.end();
        });
    } catch (error) {
      console.log(error);
      setisloading(false);
    }
    t.stop();
  };
  //=>>>>>>>>>>>>>>>>>>>>>REQUESTED API FOR VALIDATE USER ===>>>>>>>
  const requestedAPIvalidateUser = async () => {
    const url = REACT_APP_EMAIL_BASE_URL + "confirmemaillogin";
    setisloading(true);
    const t = trace(sendPerfomance, "RegisterConfirmEmailLoginUrl");
    const span = tracer.startSpan("RegisterConfirmEmailLoginapi");
    t.start();
    try {
      await axios
        .post(url, { email: username, token: loginSessionKey, code: otp })
        .then((res) => {
          console.log(res);
          span.addEvent("api_response", {
            status: res.status,
            data: res.data,
            url,
          });
          if (res.status === 200) {
            // Cookies.set('accessToken',res.data.AuthenticationResult?.AccessToken,{expires:30/(24*60*60),secure:true,sameSite:"strict"});
            localStorage.setItem(
              "accessToken",
              res.data.AuthenticationResult?.AccessToken
            );
            setisloading(false);
            setusername("");
            setloginotp(0);
            setotp("");
            setshowloginotp(false);
            setshowloginpopup(false);
            setConnection(true);
            toast.success("user Login successfully");
            setisdisable(true);
            setisshowwalletpopup(false);
          }
        })
        .catch((err) => {
          toast.warning(err.response.data?.message);
          setshowotp(false);
          setisloading(false);
          span.setStatus({ code: "ERROR", message: err?.message });
          span.end();
        })
        .finally(() => {
          span.end();
        });
    } catch (error) {
      console.log(error);
      setisloading(false);
    }
    t.stop();
  };

  const handleSubmit = () => {
    requestedAPIregisterUser();
  };
  const loginPopup = () => {
    const onclick = () => {
      console.log("hello, How are you");
      setshowloginpopup(false);
      setshowotp(false);
    };
    const body = () => {
      return (
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="exampleInputEmail1">UserName:</label>
            <input
              type="email"
              className="form-control"
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
              placeholder="Enter Username"
              value={username}
              onChange={(e) => {
                setusername(e.target.value);
                if (emailRegex.test(e.target.value)) {
                  setisdisable(false);
                } else {
                  setisdisable(true);
                }
              }}
            />
          </div>
          {showloginotp && (
            <div className="form-group">
              <label htmlFor="exampleInputPassword1">OTP:</label>
              <input
                type="number"
                minLength={6}
                maxLength={6}
                className="form-control"
                id="exampleInputPassword1"
                placeholder="Enter OTP"
                value={loginotp}
                onChange={(e) => {
                  setloginotp(e.target.value);
                }}
              />
            </div>
          )}
          <div className="d-flex align-items-center">
            <button
              className={`btn ${isdisable ? "btn-secondary" : "btn-primary"}`}
              disabled={isdisable}
              type="submit"
            >
              {isloading ? (
                <img src={loader} alt="..." width={"24px"} height={"24px"} />
              ) : showloginotp ? (
                "Submit"
              ) : (
                "Verifyd"
              )}
            </button>
            <Link
              className="mx-2"
              to="/signup"
              onClick={() => {
                setshowloginpopup(false);
                trackButtonClick('Navigation','Nav bar link clicked','Create an Account');
              }}
            >
              Create an Account?
            </Link>
          </div>
        </form>
      );
    };
    return (
      <CustomPopup
        onclick={onclick}
        size="lg"
        showpopup={showLoginpopup}
        center={true}
        popHeader="Log In"
        classHeader="font-weight-bold"
        body={body()}
      />
    );
  };

  const handleRegisteruser = async () => {
    setshowpopup(false);
    let b = await metamaskregisteruser(currentMetamskUser);
    if (Object.keys(b).length !== 0) {
      localStorage.setItem("token", b.data.AuthenticationResult?.AccessToken);
      dispatch(ActionCreator.change_eth_balance(0));
      setConnection(true);
    } else {
      setConnection(false);
    }
  };

  useMemo(() => {
    console.log("thisis  otp", otp);
    if (otp.length === 6) {
      console.log("yh chal");
      requestedAPIvalidateUser();
    }
  }, [otp]);

  const searchCollectionModal = () => {
    const mapItem = (elem, type, index) => {
      return (
        <div
          key={index}
          onMouseEnter={() => {
            setisfocusedonsearchpopup(true);
          }}
          onMouseLeave={() => {
            setisfocusedonsearchpopup(false);
          }}
          onClick={() => {
            console.log('elem', elem)
            if(elem.contract_id==="null"||elem.contract_id===undefined||elem.contract_id===null){
              toast.warn('Haveing null Id');
            }else{
              GoogleTrackEvent('Navigation','Click on the navbar collection search modal',JSON.stringify(elem),false);
              navigate(`/collection/${elem?.contract_id}/overview`,{state:{
                userData: elem,
                blockchain: elem?.blockchain,
                contract_id:elem?.contract_id
              }});
            }
            setisshowsuggestCollection(false);
            setisfocusedonsearchpopup(false);
          }}
          style={{ padding: "12px 16px" }}
          className={`${
            themeMode === "lightMode"
              ? "searchCollectionIteminLight"
              : "searchCollectionIteminDark"
          } my-3 pointer d-flex justify-content-between `}
        >
          <div className="d-flex align-items-center ">
            <div>
              <img
                width={"36px"}
                height={"36px"}
                className="rounded-circle"
                alt="..."
                src={elem?.image_url}
              />
              {returnimagebehalfblockchain(elem?.blockchain, {
                position: "relative",
                top: "10px",
                right: "10px",
              })}
            </div>
            <div>
              <p className="mb-0 ml-2 font-weight-bold">
                {elem.name !== undefined
                  ? elem.name.length > 30
                    ? elem.name.slice(0, 30) + "..."
                    : elem.name
                  : null}
              </p>

              <p
                className="mb-0 ml-2 font-weight-bold
              "
              >
                {elem?.count}{" "}
                <span className="text-secondary font-14 font-weight-bold">
                  items
                </span>
              </p>
            </div>
          </div>
          <div className="d-flex align-items-center font-weight-bold">
            {returnimagebehalfblockchain(elem?.blockchain, {
              marginRight: "2px",
            })}
            <span className="">{Number(checkValueexistornot(elem?.floor_price,true)).toFixed(2)}</span>
          </div>
        </div>
      );
    };
    return (
      <section
        style={{
          position: "absolute",
          zIndex: 100,
          // left:windowWidth>768&&"47em",
          maxWidth: "438px",
          paddingTop: "16px",
          paddingBottom: "9px",
          overflow:'auto',
          // height:'40vh',
          maxHeight:windowWidth>1024?'70vh':"60vh"
        }}
        className={`${
          windowWidth <= 768 ? "w-100" : "w-50 showinwebpopup"
        } mt-2   bradius-24 scrollbar smoothScrolling ${
          themeMode === "lightMode"
            ? "bg-light text-dark"
            : "bg-black text-white "
        }`}
      >
        <div
          className=" m-0 p-0"
          // style={{ maxHeight: "70vh" }}
        >
          {search.length < 3 && (
            <div
              className="d-flex justify-content-between"
              style={{ margin: "0px 16px 2px" }}
            >
              <span className="searchcollectionmodalRowtitle">
                <img src={fireicon} alt="..." width={"18px"} height={"18px"} />{" "}
                Trending Collection
              </span>
              <span className="searchcollectionmodalRowtitle">Floor</span>
            </div>
          )}
          {istrandingCollectionloading && search.length < 3 ? (
            <div className="d-flex justify-content-center">
              <Loader size={25} />
            </div>
          ) : null}

          {!istrandingCollectionloading &&
            search.length < 3 &&
            TrandingCollection !== undefined &&
            TrandingCollection.map((elem, index) =>
              mapItem(elem, "collection", index)
            )}

          {!isSearchCollectionLoading && search.length > 2 && (
            <div
              className="d-flex justify-content-between "
              style={{ margin: "0px 16px 2px" }}
            >
              <span className="searchcollectionmodalRowtitle">Collection</span>
              <span className="searchcollectionmodalRowtitle">Floor</span>
            </div>
          )}
          {searchCollectionDeatil !== undefined
            ? searchCollectionDeatil.length === 0 &&
              search.length > 2 &&
              !isSearchCollectionLoading && (
                <div className="d-flex justify-content-center">
                  <p className="mb-0 text-center">No Data Found</p>
                </div>
              )
            : null}
          {searchCollectionDeatil !== undefined ? (
            isSearchCollectionLoading ? (
              <div className="d-flex justify-content-center">
                <Loader size={25} />
              </div>
            ) : null
          ) : null}
          {searchCollectionDeatil !== undefined &&
            searchCollectionDeatil.map((elem, index) =>
              mapItem(elem, "collection", index)
            )}
          {searchAssetList.length !== 0 &&
          searchAssetList !== undefined &&
          search.length > 2 ? (
            <div
              className="d-flex justify-content-between pt-2 "
              style={{
                margin: "0px 16px 2px",
                borderTop:
                  themeMode === "lightMode"
                    ? "1px solid black"
                    : "1px solid white",
              }}
            >
              <span className="searchcollectionmodalRowtitle">Assets</span>
              <span className="searchcollectionmodalRowtitle">Floor</span>
            </div>
          ) : null}
          {searchAssetList !== undefined &&
            search.length > 2 &&
            searchAssetList.map((elem, index) => (
              <div
                onMouseEnter={() => {
                  setisfocusedonsearchpopup(true);
                }}
                onMouseLeave={() => {
                  setisfocusedonsearchpopup(false);
                }}
                onClick={() => {
                  navigate(`/assets/${elem?.contract_id}/${checkValueexistornot(elem?.token_id)}/overview`, {state:{
                    userData: elem,
                    blockchain: elem?.blockchain,
                    websocketMessage: JSON.stringify({
                      blockchain: elem?.blockchain,
                    }),
                  }});
                  setisshowsuggestCollection(false);
                  setisfocusedonsearchpopup(false);
                }}
                style={{ padding: "12px 16px" }}
                className={`${
                  themeMode === "lightMode"
                    ? "searchCollectionIteminLight"
                    : "searchCollectionIteminDark"
                } my-3 pointer d-flex justify-content-between `}
              >
                <div className="d-flex align-items-center ">
                  <img
                    width={"36px"}
                    height={"36px"}
                    className="rounded"
                    alt="..."
                    src={elem?.image_url}
                  />
                  {returnimagebehalfblockchain(elem?.blockchain, {
                    position: "relative",
                    top: "10px",
                    right: "10px",
                  })}
                  <div>
                    <p className="mb-0 ml-2">
                      {elem?.name !== undefined
                        ? elem.name.length > 30
                          ? elem.description.slice(0, 30) + "..."
                          : elem.name
                        : null}
                    </p>
                    <p
                      className="mb-0 ml-2"
                      style={{
                        color: themeMode === "lightMode" ? "black" : "#929292",
                      }}
                    >
                      {elem?.description !== undefined
                        ? elem.description.length > 30
                          ? elem.description.slice(0, 30) + "..."
                          : elem.description
                        : null}
                      <img
                        src={bluetick}
                        alt="..."
                        className="ml-2"
                        width={"14px"}
                        height={"14px"}
                      />
                    </p>
                  </div>
                </div>
                <div className="d-flex align-items-center">
                  {returnimagebehalfblockchain(elem?.blockchain, {
                    marginRight: "2px",
                  })}
                  <span className="">{elem?.floor_price}</span>
                </div>
              </div>
            ))}
          {searchOrdinalList.length !== 0 &&
          searchOrdinalList !== undefined &&
          search.length > 2 ? (
            <div
              className="d-flex justify-content-between pt-2 "
              style={{
                margin: "0px 16px 2px",
                borderTop:
                  themeMode === "lightMode"
                    ? "1px solid black"
                    : "1px solid white",
              }}
            >
              <span className="searchcollectionmodalRowtitle">Ordinals</span>
              <span className="searchcollectionmodalRowtitle">Floor</span>
            </div>
          ) : null}
          {searchOrdinalList !== undefined &&
            search.length > 2 &&
            searchOrdinalList.map((elem, index) =>
              mapItem(elem, "ordinals", index)
            )}
        </div>

        <div
          className="d-flex justify-content-center  "
          style={{ padding: "12px 0px 20px"}}
        >
          <button
            className="btn bradius-24 "
            style={{
              border:
                themeMode === "lightMode"
                  ? "1px solid gray"
                  : "1px solid #404040",
              backgroundColor:
                themeMode === "lightMode" ? "#eeeeee" : "#121212 ",
              color: themeMode === "lightMode" ? "black" : "white",
              minWidth: "192px",
              fontSize: "12px",
            }}
            disabled={isSearchCollectionLoading || search.length < 3}
            onMouseEnter={() => {
              setisfocusedonsearchpopup(true);
            }}
            onMouseLeave={() => {
              setisfocusedonsearchpopup(false);
            }}
            onClick={() => {
              navigate(`/search/${search}`);
              setisshowsuggestCollection(false);
              setisfocusedonsearchpopup(false);
            }}
          >
            Show all results
          </button>
        </div>
      </section>
    );
  };

  const searchCollectionApi = async (obj) => {
    const url = `/search/${obj.type}`;
    let params = {
      search: search,
      size: "6",
      page: "1",
    };
    if (obj.isOrdinal) {
      params.blockchain = "bitcoin";
    }
    try {
      const t = trace(
        sendPerfomance,
        obj.type === "collection" ? "Searc_collection_api" : "Search_asset_api"
      );
      const span = tracer.startSpan(
        obj.type === "collection" ? "SearchCollectionApi" : "SearchAssetApi"
      );
      t.start();
      setisSearchCollectionLoading(true);
      GoogleTrackEvent('hit the api','hit the collection api in navbar',url,false,url);
      await Axios.post(url, params)
        .then((res) => {
          if (res.status === 200) {
            span.setAttributes({ method: "POST" });
            span.addEvent("api_response", {
              status: res.status,
              url,
              data: res.data,
            });
            t.getAttributes({
              status: res.status,
              url,
              data: res.data,
              payload: params,
            });
            if (obj.type === "collection") {
              GoogleTrackEvent('Search collection api response',JSON.stringify(res.data),'Search',false,'get the api response');
              if (obj.isOrdinal) {
                setsearchordinallist(res.data?.rows);
              } else {
                setsearchCollectionDeatil(res.data?.rows);
              }
            } else {
              console.log("kyu isme nahi aaya kya", res.data);
              setsearchAssetList(res.data?.rows);
            }
            console.log("this is collection details data", res.data);
          }
        })
        .catch((err) => {
          console.log(err);
          span.setStatus({ code: "ERROR", message: err?.message });
        })
        .finally(() => {
          t.stop();
          span.end();
          setisSearchCollectionLoading(false);
        });
    } catch (error) {
      console.log(error);
    }
  };
  useMemo(() => {
    setsearchCollectionDeatil([]);
    setsearchAssetList([]);
    setsearchordinallist([]);
    if (search.length > 2) {
      searchCollectionApi({
        type: "asset",
        isOrdinal: false,
      });
      searchCollectionApi({
        type: "collection",
        isOrdinal: false,
      });

      searchCollectionApi({
        type: "collection",
        isOrdinal: true,
      });
      setisshowsuggestCollection(true);
    }
  }, [search]);

  const TopCollectionApi = async () => {
    const span = tracer.startSpan("TopCollectionApi");
    const t = trace(sendPerfomance, "TopCollectionApi");
    const url = `/trendingCollections?blockchain=all`;
    try {
      t.start();
      setistrandingcollectionloading(true);
      await Axios.get(url)
        .then((res) => {
          if (res.status === 200) {
            console.log("this is top trending dta", res.data);
            settrandingCollection(res.data);
          }
          span.setAttribute("Method", "GET");
          span.addEvent("api_response", {
            status: res.status,
            url,
            data: res.data,
          });
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          span.end();
          t.stop();
          setistrandingcollectionloading(false);
        });
    } catch (error) {
      console.log(error);
      span.end();
    }
  };
  const renderSearch = () => {
    return (
      <div className={`d-flex mr-3 rounded-circle `} style={{ height: "48px" }}>
        <div className="searchIcon" style={{ width: "24px", height: "24px" }}>
          <svg
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6 text-gray-400"
            style={{
              color: themeMode === "lightMode" ? "rgb(119 126 144)" : "#505461",
            }}
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M14.906 16.32a8 8 0 1 1 1.414-1.414l5.387 5.387a1 1 0 0 1-1.414 1.414l-5.387-5.387ZM16 10a6 6 0 1 1-12 0 6 6 0 0 1 12 0Z"
              fill="currentColor"
            ></path>
          </svg>
        </div>
        <div>
          <input
            style={{
              borderRadius: "100px",
              border: "1px solid white",
              width: "27em",
              backgroundColor:
                themeMode === "lightMode" ? "rgb(238 239 239)" : "black",
            }}
            className={` navSearchInput ${
              themeMode === "lightMode" ? "text-dark" : "text-white"
            }`}
            placeholder="Search"
            spellCheck="false"
            value={search}
            onChange={(e) => {
              GoogleTrackEvent('Search Input','Search',e.target.value,false,'search input');
              setsearch(e.target.value);
            }}
            onFocus={() => {
              setisshowsuggestCollection(true);
            }}
            onBlur={() => {
              console.log("kya yh chala");
              if (!isfocusedonsearchpopup) {
                setisshowsuggestCollection(false);
              }
            }}
          />
          {isshowsuggestCollection ? searchCollectionModal() : null}
        </div>
      </div>
    );
  };
  return (
    <>
      <nav
        className={` navbar navbar-expand-lg sticky-top py-3   ${
          themeMode === "lightMode"
            ? "navbarBorder bg-light navbar-light"
            : "navbar-dark"
        } navbar-default `}
      >
        <Link className="navbar-brand" to="/" onClick={()=>{trackButtonClick('Navigation','Nav bar link clicked','JRN')}}>
          <h4 className="font-weight-bold mt-1">JRN</h4>
        </Link>
        <div className={`collapse navbar-collapse ${showNavbar ? 'show' : ''}`} style={{height:showNavbar&&'100vh'}} >
          {
            showNavbar&&  <span
            onClick={() => {
              setShowNavbar(false);
            }}
            style={{
              cursor: "pointer",
              width: "36px",
              height: "36px",
              position: "absolute",
              right: "1em",
              top: "1em",
              padding: "4px",
              opacity: "0.5",
              // backgroundColor: "black",
              borderRadius: "50%",
            }}
          >
            <IoMdClose
              size={20}
              color={themeMode==="lightMode"?"black":"white"}
              style={{ position: "relative", top: "4px", left: "5px" }}
            />
          </span>
          }
          
          <ul className="navbar-nav">
            <li className="nav-item dropdown link-in-mobile">
              <Link
                className={`navaBar-links mobile-link-text nav-link ${themeMode==='lightMode'?'nav-link-color':"text-white"}`}
                to="/discover"
                onClick={()=>{trackButtonClick('Navigation','Nav bar link clicked','collection');setShowNavbar(false);}}
              >
                Collection
              </Link>
            </li>
            <li className="nav-item dropdown link-in-mobile">
              <Link
                className={`navaBar-links nav-link mobile-link-text ${themeMode==='lightMode'?'nav-link-color':"text-white"}`}
                to="/ordinals/nft"
                onClick={()=>{trackButtonClick('Navigation','Nav bar link clicked','Ordinal');setShowNavbar(false);}}
              >
                Ordinals
              </Link>
            </li>
            <li className="nav-item dropdown link-in-mobile">
              <Link
                className={`navaBar-links nav-link mobile-link-text ${themeMode==='lightMode'?'nav-link-color':"text-white"}`}
                to="/leaderBoard"
                onClick={()=>{trackButtonClick('Navigation','Nav bar link clicked','Leader board');setShowNavbar(false);}}
              >
                Leader Board
              </Link>
            </li>
                
            <li className="nav-md-link link-in-mobile">
                <CustomMuiAccordian 
                mode={themeMode} 
                title="Discover"
                innerItem={[
                  {title:'Explore DApps',navigate_to:"/discover"},
                  {title:'Xplayer',navigate_to:"/xplayer"},
                  {title:'Announcements',navigate_to:"/announcements"},
                  {title:'Download',navigate_to:"/download"},
                  {title:'Eventlist',navigate_to:"/eventlist"},
                ]}
                handleClose={updateshowNavbar}
                />
            </li>
          </ul>
          <div className="d-none d-md-block">
            <FormControl sx={{ m: 1, minWidth: 120 }}>
              <Select
                value={currentLink}
                onChange={(e)=>{setcurrentLink(e.target.value); navigate(`/${e.target.value}`)}}
                displayEmpty
                inputProps={{ 'aria-label': 'Without label' }}
                sx={{
                  '& .MuiSelect-select': {
                    padding: '8px', // Adjust padding to reduce height
                  },
                }}
              >
                <MenuItem value={'xplayer'}>Xplayer</MenuItem>
                <MenuItem value={'announcements'}>Announcements</MenuItem>
                <MenuItem value={'download'}>Download</MenuItem>
                <MenuItem value={'eventlist'}>Eventlist</MenuItem>
              </Select>
          </FormControl>
          </div>
        </div>
        {
          !showNavbar&&
            <div className="d-flex  justify-content-between align-items-center">
              {windowWidth > 768 ? renderSearch() : null}
              <span
                className="mx-2 font-weight-bold pointer"
                onClick={handleThemeMode}
              >
                {themeMode !== "lightMode" ? (
                  <FiSun size={22} color="rgb(119 126 144)" />
                ) : (
                  <IoMoonOutline size={22} color="rgb(119 126 144)" />
                )}
              </span>
              <div
                className="mx-4 font-weight-bold "
                style={{ width: "40px", height: "40px" }}
              >
                <span
                  className=" walleticon p-2 pointer"
                  onClick={() => {
                    setisshowwalletpopup(true);
                  }}
                >
                  <CiWallet
                    size={22}
                    color="rgb(119 126 144)"
                    style={{ position: "relative", top: "5px" }}
                  />
                </span>
              </div>
              <button
                className="navbar-toggler"
                type="button"
                onClick={handleNavbarToggle}
              >
                <span className="navbar-toggler-icon"></span>
              </button>
            </div>
        }
      </nav>
      {windowWidth <= 768 ? (
        <nav
          className={`${
            themeMode === "lightMode"
              ? "bg-light text-dark"
              : "bg-dark text-white"
          }`}
        >
          <div
            className={`d-flex ${
              !showsearchIcon
                ? "justify-content-end"
                : "justify-content-between"
            } p-2 align-items-center`}
          >
            {showsearchIcon ? (
              renderSearch()
            ) : (
              <FiSearch
                size={25}
                color={themeMode === "lightMode" ? "black" : "white"}
                className="mr-2 pointer"
                onClick={() => {
                  setshowsearchIcon(true);
                }}
              />
            )}
            {/* {showsearchIcon && <span className={`pointer mr-2 ${themeMode === "lightMode" ? "text-dark" : "text-white"}`} onClick={()=>{
                setshowsearchIcon(false)
              }} >Clear</span>} */}
            <MdOutlineMoreHoriz
              className="mr-2"
              size={25}
              color={themeMode === "lightMode" ? "black" : "white"}
            />
          </div>
        </nav>
      ) : null}

      <Modal
        size="lg"
        isOpen={showpopup}
        centered
        toggle={() => {
          setshowpopup(!showpopup);
          setshowotp(false);
        }}
      >
        <ModalHeader
          toggle={() => {
            setshowpopup(!showpopup);
            setshowotp(false);
          }}
          close={closeBtn}
        >
          <p>Connect Your Account</p>
        </ModalHeader>
        <ModalBody className="p-0">
          <div className="list-group" id="list-tab" role="tablist">
            <button
              type="button "
              className=" btn  list-group-item list-group-item-action"
              onClick={handleRegisteruser}
            >
              Register With wallet
            </button>
            <button
              type="button "
              className=" btn  list-group-item list-group-item-action"
              onClick={handleconnection}
            >
              Login with wallet
            </button>
            <button
              type="button"
              className=" btn  list-group-item list-group-item-action"
              onClick={() => {
                console.log("i will implement you on tomorrow.");
                setshowemailpopup(true);
                setshowpopup(false);
              }}
            >
              Wallet
            </button>
          </div>
        </ModalBody>
      </Modal>
      <Modal
        size="lg"
        isOpen={isshowwalletpopup}
        className={
          windowWidth < 1120 ? (windowWidth < 580 ? "" : "w-50") : "w-25"
        }
        centered
        style={{ borderRadius: "2em" }}
      >
        <ModalBody
          className="p-0 "
          style={{
            borderRadius: "2em",
            color: themeMode === "lightMode" ? "black" : "white",
          }}
        >
          <span
            onClick={() => {
              setisshowwalletpopup(false);
              setshowloginotp(false);
              setusername("");
              setisdisable(true);
            }}
            style={{
              cursor: "pointer",
              width: "36px",
              height: "36px",
              position: "absolute",
              right: "1em",
              top: "1.4em",
              padding: "4px",
              opacity: "0.5",
              backgroundColor: "black",
              borderRadius: "50%",
            }}
          >
            <IoMdClose
              size={20}
              color="white"
              style={{ position: "relative", top: "4px", left: "5px" }}
            />
          </span>
          <section
            className="p-0 pb-3 m-0"
            style={{
              borderRadius: "2em 2em 0 0 ",
              backgroundColor:
                themeMode === "lightMode" ? "#f6f6f6" : "rgb(27 27 27)",
            }}
          >
            <div style={{ borderRadius: "2em" }}>
              <img
                src={loginwalletImage}
                className="img-fluid"
                style={{ borderRadius: "2em 2em 2em 2em" }}
                alt="..."
              />
            </div>
          </section>

          {!showloginotp ? (
            <section className="mainContainer flex-column" id="emailSection">
              <section
                className="p-0 pb-3 m-0"
                style={{
                  backgroundColor:
                    themeMode === "lightMode" ? "#f6f6f6" : "rgb(27 27 27)",
                }}
              >
                <div className="" style={{ padding: "5px 24px" }}>
                  <h2
                    style={{
                      lineHeight: "28px",
                      fontSize: "20px",
                      fontWeight: "bold",
                    }}
                  >
                    Connect to JRN
                  </h2>
                </div>
                <div className="px-2 ">
                  <div className="form-group mx-sm-3 d-flex">
                    <input
                      type="email"
                      className="form-control emailInput"
                      id="inputPassword2"
                      placeholder="Email"
                      value={username}
                      onChange={(e) => {
                        setusername(e.target.value);
                        if (emailRegex.test(e.target.value)) {
                          setisdisable(false);
                        } else {
                          setisdisable(true);
                        }
                      }}
                    />
                    <button
                      disabled={isdisable}
                      className={`btn ${
                        isdisable ? "btn-secondary" : "btn-primary"
                      } mx-2 `}
                      onClick={requestedAPIregisterUser}
                    >
                      {isloading ? (
                        <Loader size={20} />
                      ) : (
                        <FaArrowRight
                          size={20}
                          style={{ position: "relative", top: "5px" }}
                        />
                      )}
                    </button>
                  </div>
                  <p className="py-4 mx-3 mb-0 description_title ">
                    By continuing you agree to our{" "}
                    <span style={{ color: "rgb(91 91 91)" }}>Terms</span> &{" "}
                    <span
                      style={{
                        color: "rgb(91 91 91)",
                        paddingLeft: "text-center",
                      }}
                    >
                      Privacy Policy{" "}
                    </span>
                    and Privy's{" "}
                    <span style={{ color: "rgb(91 91 91)" }}>Terms</span>.
                  </p>
                  <div></div>
                </div>
              </section>
              <div
                style={{
                  opacity: "0.8",
                  fontSize: "12px",
                  backgroundColor: "",
                  textAlign: "center",
                  position: "absolute",
                  zIndex: 1,
                  left: "50%",
                  top: "59%",
                }}
                className="text-center"
              >
                OR
              </div>
              <section
                style={{
                  borderRadius: "0 0 1.8em 1.8em",
                  backgroundColor:
                    themeMode === "lightMode"
                      ? "rgb(255 255 255)"
                      : "rgb(37 37 37)",
                }}
              >
                <div className="list-group" style={{ padding: "0px 8px" }}>
                  {[
                    {
                      id: 1,
                      image: { metamaskIcon },
                      label: "MetaMask",
                      tag: "Popular",
                    },
                    {
                      id: 2,
                      image:
                        "https://static.opensea.io/logos/walletlink-alternative.png",
                      label: "Coinbase Wallet",
                      tag: "",
                    },
                    {
                      id: 3,
                      image:
                        "https://static.opensea.io/logos/walletconnect-alternative.png",
                      label: "Wallet Connect",
                      tag: "",
                    },
                  ].map((elem) => (
                    <span
                      className=" list-group-item p-4 border-0 list-group-item-action d-flex justify-content-between pointer "
                      style={{
                        padding: "8px",
                        borderRadius: "2em",
                        color: themeMode === "lightMode" ? "black" : "white",
                        backgroundColor:
                          themeMode === "lightMode" ? "white" : "rgb(37 37 37)",
                      }}
                      key={elem.id}
                      onClick={() => {
                        if (elem.id === 1) {
                          if (typeof window.ethereum !== "undefined") {
                            console.log("MetaMask is installed");
                            fetchAccount();
                            window.ethereum.on(
                              "accountsChanged",
                              (accounts) => {
                                const newAccount = accounts[0];
                                setcurrentMetamaskuser(newAccount);
                              }
                            );
                            return () => {
                              window.ethereum.removeAllListeners(
                                "accountsChanged"
                              );
                            };
                          } else {
                            toast.warn("MetaMask is not installed");
                          }
                          handleconnection();
                        }
                      }}
                    >
                      <span>
                        <img
                          src={elem.id === 1 ? metamaskIcon : elem.image}
                          alt="..."
                          className="mr-2"
                          width="30px"
                          height={"30px"}
                        />
                        {elem.label}
                      </span>
                      <span
                        style={{
                          backgroundColor:
                            themeMode === "lightMode"
                              ? "rgb(246 246 246)"
                              : "rgb(37 37 37)",
                        }}
                      >
                        {elem.tag}
                      </span>
                    </span>
                  ))}
                </div>
              </section>
            </section>
          ) : (
            <section className="p-3" id="otpSection">
              <div className="mb-3">
                <h2
                  style={{
                    lineHeight: "26px",
                    fontSize: "18px",
                    marginTop: "1rem",
                    fontWeight: "bolder",
                  }}
                >
                  Enter login code
                </h2>
              </div>

              <div
                className="p-3 d-flex flex-column justify-content-center align-items-center"
                style={{
                  borderRadius: "12px",
                  backgroundColor: "rgb(237 237 237)",
                }}
              >
                <div>
                  <FaRegEnvelope size={40} />
                </div>
                <div>
                  <p className="text-center">
                    A one-time authentication code has been sent to
                    <span className="font-weight-bold"> {username} </span>
                  </p>
                </div>
                <div>
                  <OtpInput
                    value={otp}
                    onChange={setotp}
                    numInputs={6}
                    renderSeparator={<span>-</span>}
                    inputStyle={{
                      minWidth: "40px",
                      minHeight: "40px",
                      borderWidth: 0,
                      borderRadius: "12px",
                    }}
                    renderInput={(props) => (
                      <input
                        type="number"
                        disabled={istokenExpired}
                        {...props}
                      />
                    )}
                  />
                </div>
                <div>
                  <CustomClock
                    time={10}
                    minute={60}
                    className="text-center"
                    updateTokenExpired={updateTokenExpired}
                  />
                </div>
              </div>

              <div>
                <button
                  className="btn btn-block my-2 py-2 font-weight-bold mx-auto"
                  style={{
                    borderRadius: "12px",
                    backgroundColor: "rgb(237 237 237)",
                  }}
                >
                  Resend Code
                </button>
              </div>
            </section>
          )}
        </ModalBody>
      </Modal>
      <CustomPopup
        size="lg"
        showpopup={showemailpopup}
        center={true}
        onclick={toggleEmailpopup}
        popHeader="Connect with your Wallet"
        body={
          <div className=" ">
            <div className="form-group ">
              <label htmlFor="email">Email:</label>
              <input
                type="email"
                value={email}
                disabled={showotp ? true : false}
                onChange={(e) => {
                  setemail(e.target.value);
                }}
                minLength={6}
                className="form-control disable"
                id="email"
                placeholder="Enter your Email"
              />
            </div>
            {showotp && (
              <div className="form-group ">
                <label htmlFor="otp">OTP:</label>
                <input
                  type="number"
                  value={otp}
                  onChange={(e) => {
                    setotp(e.target.value);
                  }}
                  maxLength={6}
                  minLength={6}
                  className="form-control"
                  id="otp"
                  placeholder="Enter your otp"
                />
              </div>
            )}

            <button
              className="btn btn-primary btn-block text-center d-flex justify-content-center"
              onClick={showotp ? handleLogin : handlesendOtp}
            >
              {isotploading ? (
                <CustomovalLoader
                  height={20}
                  // width={30}
                  visible={isotploading}
                />
              ) : showotp ? (
                "Login"
              ) : (
                "Send Otp"
              )}
            </button>
          </div>
        }
      />
      {loginPopup()}
    </>
  );
}
