import React from 'react'
import { useSelector } from 'react-redux'
import { BiSearch } from "react-icons/bi";


export default function CustomSearch(props) {
    const mode=useSelector(state=>state.themeType.themeType);
  return (
    <div
    className={`input-group  mx-2 ${
      mode === "lightMode" ? "bg-light text-muted" : "bg-black text-white"
    } ${props.className}`}
  >
    <div className="input-group-prepend">
      <span className={`input-group-text bg-dark `}>
        <BiSearch size={20} color="white" />
      </span>
    </div>
    <input
      type="text"
      className={`form-control ${
        mode === "lightMode" ? "bg-light text-muted" : "bg-black text-white"
      }`}
      placeholder="Search..."
      value={props.value}
      onChange={(e)=>{props.onchange(e.target.value)}}
    />
  </div>
  )
}


