import React,{useEffect} from 'react'
import Nav from './component/Navbar/Nav'
import { Outlet, useLocation } from 'react-router-dom'
import Footer, { MaybeShowFooter } from './component/Footer/Footer'
import ReactGA from 'react-ga4';
import { trackButtonClick } from './utils/utility/GAnalytics';

const MainLayout = () => {
  const location=useLocation();
  const handleRouteChange=()=>{
    ReactGA.event('page_view', {
      page_path: window.location.pathname,           // Path of the page
      page_title: window.location.pathname,      // Title of the page
      time_spent: 45,                // Custom parameter (e.g., time spent on the page in seconds)
      screen_name:'Collection screen'
    });
  } 
  const trackScroll = () => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY; // 
      const scrollPercentage = Math.round((scrollPosition / (document.body.scrollHeight - window.innerHeight)) * 100);
  
      ReactGA.event({
        category: 'Engagement',
        action: 'Scroll',
        label: `Scrolled ${scrollPercentage}%`,
      });
    };
  
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  };
  useEffect(() => {
    handleRouteChange();
  }, [location]);
  useEffect(()=>{
    trackScroll()
    document.addEventListener('click', (event) => {
      const title=event.target;
      if(title.tagName==='SPAN'||title.tagName==='P'){
        trackButtonClick('Engaged','Clicked',title.innerText,1,false,'xhr','Btn clikcked');
      }
      if (event.target.matches('button, .button-class')) { 
        const buttonText = event.target.innerText || event.target.value || 'Unknown Button';
        trackButtonClick('Engaged','Clicked',buttonText,1,false,'xhr','Btn clikcked');
      }
      const mediaDiv = event.target;
      if (mediaDiv) {
        if (mediaDiv.getAttribute('data-custom') === 'expectedValue') {
          const paragraph = mediaDiv.querySelector('div.media-body p');
          if (paragraph) {
            const paragraphText = paragraph.innerText;
            console.log('Paragraph Text:', paragraphText);
            ReactGA.event({
              category: 'Engagement',
              action: 'ParagraphClick',
              label: paragraphText,
              nonInteraction: false,
            });
          }
        }}
    });
  },[])

  return (
    <div>
      <Nav/>
      <Outlet/>
      <MaybeShowFooter>
        <Footer />
      </MaybeShowFooter>
    </div>
  )
}

export default MainLayout
