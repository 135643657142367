import React, { useState, useEffect, useRef } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useSelector } from "react-redux";
import InfiniteScroll from "react-infinite-scroll-component";
import Axios, { BaseUrl, REACT_APP_LEADERBOARD_BASE_URL } from "../../config/API/Api";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { Loader2 } from "../../commonComponent/Loader";
import CustomSelect from "../../commonComponent/CustomSelect";
import Select from "react-select";
import { tracer } from "../../tracing";
import axios from "axios";
import { toast } from "react-toastify";
import {
  FaChevronLeft,
  FaChevronRight,
  FaRegStar,
  FaCheck,
} from "react-icons/fa";
import { w3cwebsocket } from "websocket";
import { trace } from "firebase/performance";
import { sendPerfomance } from "../..";
import * as bincode from "protobufjs";
import pako from "pako";
import protofile from "./decode.protobuf";
export default function DummyScreen() {
  const [windowWidth, setwindowWidth] = useState(window.innerWidth);
  const mode = useSelector((state) => state.themeType.themeType);
  const token = localStorage.getItem("accessToken");
  const [page, setpage] = useState(1);
  const [size, setsize] = useState(50);
  const [rowperPage, setrowperPage] = useState(50);
  const [loading, setisloading] = useState(true);
  const [duration, setduration] = useState("1");
  const [activeChain, setactiveChain] = useState("");
  const [sortBy, setsortBy] = useState("volume");
  const [order, setorder] = useState("desc");
  const [isOpen, setIsOpen] = useState(false);
  const [chainlist, setchainlist] = useState([]);
  const [dataList, setdataList] = useState([]);
  const socket = new w3cwebsocket(REACT_APP_LEADERBOARD_BASE_URL);
  const [refresh, setrefresh] = useState(false);
  const [blockChainList, setblockChainList] = useState([
    { id: 1, label: "Please Select Blockchain", value: "" },
  ]);
  const socketRef = useRef(null);
  const webSocketMessage = {
    action: "subscribe",
    channels: [
      {
        name: "rating",
        topics: ["all"],
      },
      {
        name: "status",
      },
    ],
  };

  const rowperpageoption = [
    { id: 1, label: "50 Items", value: "50" },
    { id: 2, label: "80 Items", value: "80" },
    { id: 3, label: "100 Items", value: "100" },
    { id: 4, label: "120 Items", value: "120" },
    { id: 5, label: "150 Items", value: "150" },
  ];
  const intervalList = [
    { id: 1, label: "1 Day", value: "1" },
    { id: 2, label: "1 Week", value: "7" },
    { id: 3, label: "1 Month", value: "30" },
  ];
  const sortByOptions = [
    { id: 1, label: "Volume", value: "volume" },
    { id: 2, label: "Likes", value: "likes" },
    { id: 3, label: "DisLikes", value: "dislikes" },
  ];

  const updaterowPerpage = (val) => {
    setactiveChain = val;
  };
  //CUSTOM COMPONENT
  const rendDerTable = () => {
    return dataList.map((o, index) => (
      <tr>
        <th scope="row">{index + 1}</th>
        <td>{o?.userId}</td>
        <td>{o?.assetId}</td>
        <td>{o?.traceId}</td>
        <td>{o?.blockchain}</td>
      </tr>
    ));
  };

  // ============>>>>API's<<<<===============

  //DECODE WEBSOCKET DATAA
  const handleblockChain = (value) => {
    if (value !== undefined && value !== null) {
      console.log("yahana aaya");
      let arr = [];
      value?.blockchains.map((item, index) => {
        arr.push({ id: index + 1, label: item, value: item });
      });
      setblockChainList(arr);
    }
  };
  const convertBinaryToText = async (compressedData) => {
    let finalData = [];
    try {
      const root = await bincode.load(protofile);
      //   const VotingResponse = root.lookupType("voting.VotingResponse");
      const WsMessageResponse = root.lookupType("voting.DetailsMessageProto");
      const VotingResponse = root.lookupType("voting.StatusResponseProto");
      let offset = 0;
      const binaryData = pako.inflate(new Uint8Array(compressedData), {
        to: "buffer",
      });
      let count = 0;
      while (offset < binaryData.length) {
        const messageLength = new DataView(
          new Uint8Array(binaryData.slice(offset, offset + 4)).buffer
        ).getUint32(0, true);
        offset += 4;
        count += 1;

        const protobufMessage = new Uint8Array(
          binaryData.slice(offset, offset + messageLength)
        );
        let decodedMessage = null;

        try {
          decodedMessage = WsMessageResponse.toObject(
            WsMessageResponse.decode(protobufMessage),
            { defaults: true }
          );
          console.log("Decoded as WsMessageResponse:greate", decodedMessage);
          if (decodedMessage?.type === "status") {
            handleblockChain(decodedMessage);
          } else if(decodedMessage?.type === "rating"){
            setdataList((previous)=>{
              return [...previous,decodedMessage.details];
            })
          }
          setisloading(false);
        } catch (error) {
          setisloading(false);
          console.error("Error decoding binary data:", error);
        }
        // If decoding as VotingResponse failed, try WsMessageResponse
        if (!decodedMessage) {
          try {
            decodedMessage = VotingResponse.toObject(
              VotingResponse.decode(protobufMessage),
              { defaults: true }
            );
            console.log("Decoded as Status MessageResponse:", decodedMessage);
            if (decodedMessage?.type === "status") {
              handleblockChain(decodedMessage);
            }else if(decodedMessage?.type === "rating"){
              setdataList((previous)=>{
                return [...previous,decodedMessage.details];
              })
            }
            setisloading(false);
          } catch (error) {
            setisloading(false);
            // console.error('Error decoding binary data as both types:', error);
          }
        }
        offset += messageLength;
        // setdataList([...dataList,...finalData]);
      }
    } catch (err) {
      console.error("Error loading schema file or decoding data:", err);
    }
  };

  // LIFE CYCLES METHODS (EVENT HANDLING)
  useEffect(() => {
    const handleResize = () => {
      setwindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  useEffect(() => {
    if (!socketRef.current) {
      socketRef.current = new w3cwebsocket(
        REACT_APP_LEADERBOARD_BASE_URL
      );
      socketRef.current.onopen = () => {
        socketRef.current.send(JSON.stringify(webSocketMessage));
      };
      socketRef.current.onmessage = (event) => {
        const message = JSON.parse(event.data);
        console.log("first", message);
        convertBinaryToText(message);
      };

      socketRef.current.onerror = (error) => {
        console.error("WebSocket error:", error);
      };
      return () => {
        if (socketRef.current) {
          socketRef.current.close();
          socketRef.current = null;
        }
      };
    }
  }, []);

  return (
    <section style={{ minHeight: "100vh" }}>
      <div className="container-fluid">
        {/* {filterSection()} */}
        {/* {sectionTablescreen()} */}
        <div className=" d-flex justify-content-end">
          <CustomSelect
            options={blockChainList}
            updatevalue={updaterowPerpage}
            minwidth="auto"
          />
        </div>
        <div className="row">
          <div className="col">
            <table className="table table-hover">
              <thead>
                <tr>
                  <th scope="col">S.No</th>
                  <th scope="col">User ID</th>
                  <th scope="col">Asset ID</th>
                  <th scope="col">Trace ID</th>
                  <th scope="col">Blockchain</th>
                </tr>
              </thead>
             
                {loading ? (
                  <div className="d-flex justify-content-center align-items-center" style={{position:'absolute',left:"45%",marginTop:"0"}} >
                    <Loader2
                      color={mode === "lightMode" ? "black" : "white"}
                      size={20}
                    />
                  </div>
                ) : dataList.length === 0 ? (
                  <div className="d-flex justify-content-center align-items-center">
                    <h5
                      className={`text-center ${
                        mode === "lightMode" ? "text-dark" : "text-white"
                      }`}
                      style={{position:'absolute',left:"45%",marginTop:"10%"}}
                    >
                      No Data Found
                    </h5>
                  </div>
                ) : (
                  <tbody>
                  {rendDerTable()}
                 </tbody>
                )}
            </table>
          </div>
        </div>
      </div>
    </section>
  );
}
