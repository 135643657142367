import {darkthemeactionType,lightthemeactionType,changeuserIdType,change_user_addressType,change_eth_balanceType,collection_filterType} from '../ActionType'
export const dark_theme_mode=()=>{
    return {
      type:darkthemeactionType
    }
  }
export const light_theme_mode=()=>{
    return {
      type:lightthemeactionType  
    }
  }
export const change_userId=(id="")=>{
    return {
      type:changeuserIdType,
      payload:id
    }
  }
export const change_user_address=(address)=>{
    return {
      type:change_user_addressType,
      payload:address
    }
  }

export const change_eth_balance=(balance)=>{
  return {
    type:change_eth_balanceType,
    payload:balance
  }
}
export const collection_filter_action=(payload)=>{
  return {
    type:collection_filterType,
    payload:payload
  }
}