import React, { useEffect, useMemo, useState,useRef } from "react";
import Userpng from "../../assets/user.png";
import { useSelector } from "react-redux";
import { trace } from "firebase/performance";
import { sendPerfomance } from "../..";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import * as protobuf from "protobufjs";
import pako from "pako";
import protofile from "../../commonComponent/myProto.protobuf";
import { w3cwebsocket } from "websocket";
import { FaCheck } from "react-icons/fa";
import Select from "react-select";
import CustomSelect from "../../commonComponent/CustomSelect";
import { useNavigate } from "react-router-dom";
import { websocketBaseUrl } from "../../config/API/Api";
import { trackButtonClick } from "../../utils/utility/GAnalytics";
import ReactGA from 'react-ga4';
import { checkValueexistornot } from "../../utils/utility/Utility";
import CustomShimmer from '../../commonComponent/CustomShimmer'
export default function ResultData({
  websocketMessage,
  activeInterval,
  intervalList,
  updateInterval,
  chainlist,
  updateblockChain,
  activeChain,
  windowWidth,
  updatewebsocketmessage,
  updateSelectCollection
}) {
  const mode = useSelector((state) => state.themeType.themeType);
  const [alignment, setAlignment] = React.useState("web");
  const [selectCollection, setselectCollection] = useState(true);
  const [selectchoice, setselectChoice] = useState(true);
  const [data, setdata] = useState([]);
  const [loading, setisloading] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const socketRef = useRef(null);
  console.log('data', data)

  const navigate=useNavigate()
  const handleClick = (row) => {
    if (selectCollection) {
      ReactGA.event({
        category: 'navigation',
        action: 'Click the collection',
        label: row?.name,
        nonInteraction:false,
        value:'/server'
      })
      trackButtonClick('USER INTERACTION','Click','user select the collection item in collection table on homepage');
      navigate(`/collection/${row?.contractId}/overview`, {state:{
        userData: row,
        blockchain: row?.blockchain,
        image_url:row?.imageUrl,
        contract_id:row?.contractId
      }});
    } else {
      ReactGA.event({
        category: 'Navigation',
        action: 'Click the assets',
        label: row?.name,
        nonInteraction:false,
        value:'/server'
      })
      trackButtonClick('USER INTERACTION','Click','user select the asset item in asset table on homepage');
      navigate(`/assets/${row?.contractId}/${checkValueexistornot(row?.tokenId)}/overview`, {state:{
        userData: row,
        blockchain: row?.blockchain,
        contract_id:row?.contractId
      }});
    }
  };

  const convertBinaryToText = async (compressedData,selectCollection,selectchoice) => {
    try {
      const root = await protobuf.load(protofile);
      const VotingResponse = root.lookupType("voting.VotingResponseNew");
      let offset = 0;
      const binaryData = pako.inflate(new Uint8Array(compressedData), {
        to: "buffer",
      });
      let count = 0;
      while (offset < binaryData.length) {
        const messageLength = new DataView(
          new Uint8Array(binaryData.slice(offset, offset + 4)).buffer
        ).getUint32(0, true);
        offset += 4;
        count += 1;

        const protobufMessage = new Uint8Array(
          binaryData.slice(offset, offset + messageLength)
        );
        try {
          const decodedMessage = VotingResponse.toObject(
            VotingResponse.decode(protobufMessage),
            { defaults: true }
          );
          setdata(decodedMessage?.data);
        } catch (error) {
          console.error("Error decoding binary data:", error);
        }
        offset += messageLength;
      }
    } catch (err) {
      console.error("Error loading schema file or decoding data:", err);
    }
  };
  useEffect(() => {
    if (!socketRef.current) {
      socketRef.current = new w3cwebsocket(websocketBaseUrl);
      const t = trace(sendPerfomance, "custom_Trace_name");
      t.start();

      socketRef.current.onopen = () => {
        setisloading(true);
        socketRef.current.send(JSON.stringify(websocketMessage));
      };

      socketRef.current.onmessage = (event) => {
        const message = JSON.parse(event.data);
        convertBinaryToText(message,selectCollection,selectchoice);
        setisloading(false);
      };

      socketRef.current.onerror = (error) => {
        console.error("WebSocket error:", error);
      };

      t.stop();
      t.putAttribute("page", "/");

      return () => {
        if (socketRef.current) {
          socketRef.current.close();
          socketRef.current = null;
        }
      };
    }
  }, [websocketMessage]);

  const handlechoice = (event) => {
    ReactGA.event({
      category: 'clicked on a switch',
      action: 'Clicked',
      label: event.target.value,
      nonInteraction:false,
      value:event.target.value
    })
    setdata([]);
    setisloading(true);
    if (event.target.value === "topLiked") {
      updateSelectCollection(selectCollection?'collection_index':'asset_index',"1",selectCollection?"collection_index_like":"asset_index_like")
    } else {
      updateSelectCollection(selectCollection?'collection_index':'asset_index',"0",selectCollection?'collection_index_dislike':'asset_index_dislike');
    }
    setselectChoice(true);

  };

  useMemo(() => {
    setdata([]);
  }, [selectCollection, selectchoice, activeInterval, activeChain]);

  const handleInterval = (event) => {
    setisloading(true);
    setdata([]);
    updateInterval(event.target.value);
  };
  const renderViewAllBtn = () => {
    return (
      <button
        className={` sliderbgcolor px-4 border-0 font-14`}
        style={{
          height: "45px",
          minWidth:'150px',
          width:"100%",
          color: mode === "lightMode" ? "black" : "white",
          backgroundColor: mode === "lightMode" ? "" : "#1b1b1b",
        }}
        onClick={() => {
          trackButtonClick('USER INTERACTION','Click','user click on view all button on homepage');
          websocketMessage[1] = "50";
          navigate("ViewAllCollection", {state:{
            websocketMessage,
            activeChain: activeChain,
            chainlist,
            intervalList
          }});
        }}
      >
        View all
      </button>
    );
  };
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      borderBottom: "0px solid #ccc",
      color: state.isSelected ? "#fff" : "#333", // Selected text color
      background: mode === "lightMode" ? "" : "#1b1b1b",
      padding: "10px",
    }),
    control: (provided) => ({
      ...provided,
      border: mode === "lightMode" ? "1px solid #ccc" : "0px",
      borderRadius: "4px",
      cursor: "pointer",
      height: "45px",
      position: "relative",
      bottom: "1px",
      margin: "4px",
      backgroundColor: mode === "lightMode" ? "#f6f6f6" : "#1b1b1b",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: mode === "lightMode" ? "#333" : "white", // Selected text color
    }),
    indicatorSeparator: () => ({ display: "none" }), // Remove separator
    dropdownIndicator: (provided) => ({
      ...provided,
      color: mode === "lightMode" ? "black" : "white", // Dropdown icon color
      fontSize: "10px",
      transition: "transform 0.7s", // Add transition for smooth rotation
      transform: isOpen ? "rotate(180deg)" : "rotate(0deg)",
    }),
    menuList: (base) => ({
      ...base,
      padding: 0,
      "::-webkit-scrollbar": {
        width: "4px",
        height: "0px",
      },
      "::-webkit-scrollbar-track": {
        background: "#f1f1f1",
      },
      "::-webkit-scrollbar-thumb": {
        background: "#888",
      },
      "::-webkit-scrollbar-thumb:hover": {
        background: "#555",
      },
    }),
    tickIcon: {
      marginRight: "5px",
    },
  };
  const updatesortingFilter=(val)=>{
    if(val!==undefined){
      setisloading(true)
      setdata([]);
      const filterData=intervalList.find((o)=>o.value===val);
      if(filterData){
        updateInterval(filterData?.id);
      }
    }
  }

  //custom css for shimmer effect
  const shimmerStyle={width:'10em',height:'36px',borderRadius:"50px"}
  return (
    <div className="container-fluid">
      <div className="mx-0 row">
        <div className="col order-1 px-0 align-self-center ">
            <div className={`d-flex m-1 my-2`}>
                <div className="d-flex justify-content-between align-items-center">
                        <span
                          className={`pointer ${
                            selectCollection
                              ? mode === "lightMode"
                                ? "underlineblackthemMode"
                                : "underlinewhitethemode"
                              : "slidertab"
                          } mr-2 `}
                          onClick={() => {
                            ReactGA.event({
                              category: 'clicked',
                              action: 'Click the collection tab',
                              label: 'collection',
                              nonInteraction:false,
                              value:'collection'
                            })
                            setisloading(true);
                            setdata([]);
                            updateSelectCollection('collection_index',selectchoice?"1":"0",selectchoice?'collection_index_like':'collection_index_dislike');
                            setselectCollection(true);
                          }}
                        >
                          Collections
                        </span>
                        <span
                          className={`pointer mx-2 ${
                            selectCollection
                              ? "slidertab"
                              : mode === "lightMode"
                              ? "underlineblackthemMode"
                              : "underlinewhitethemode"
                          } `}
                          onClick={(e) => {
                            ReactGA.event({
                              category: 'clicked',
                              action: 'Click the asset tab on homepage',
                              label: 'collection',
                              nonInteraction:false,
                              value:'collection'
                            })
                            setisloading(true);
                            updateSelectCollection('asset_index',selectchoice?"1":"0",selectchoice?'asset_index_like':'asset_index_dislike');
                            setselectCollection(false);
                            setdata([]);
                          }}
                        >
                          Assets
                        </span>
                </div>
            </div>
        </div>
        <div className="col px-0 rounded-14 order-2 align-self-center">
                <ToggleButtonGroup
                  color="primary"
                  value={alignment}
                  exclusive
                  onChange={handlechoice}
                  aria-label="Platform"
                  className="tabSliderContainer m-1"
                  style={{
                    borderRadius: "12px",
                    maxHeight: "48px",
                    paddingLeft: "0",
                    paddingRight: "0",
                    backgroundColor: mode !== "lightMode" && "#1b1b1b",
                  }}
                >
                  <ToggleButton
                    value="topLiked"
                    className={`min-w150 tabsliderbuttoninActive ${
                      !selectchoice
                        ? mode === "lightMode"
                          ? "text-dark"
                          : "text-white"
                        : 'tabsliderbutton'
                    }    text-capitalize`}
                  >
                    Top Liked
                  </ToggleButton>
                  <ToggleButton
                    value="topDisliked"
                    className={`min-w150 tabsliderbuttoninActive ${
                      !selectchoice
                        ? mode === "lightMode"
                          ? "tabsliderbutton"
                          : "text-white"
                        : mode === "lightMode"
                        ? "text-dark"
                        : "text-white"
                    } py-2  text-capitalize`}
                  >
                    Top Disliked
                  </ToggleButton>
                </ToggleButtonGroup>
          </div>
        <div className="col  rounded-14 order-md-3 order-5 px-0 align-self-center">
        {windowWidth >= 1024 ? (
                  <div className="d-flex m-1 align-items-center">
                    <ToggleButtonGroup
                      color="primary"
                      value={alignment}
                      exclusive
                      onChange={handleInterval}
                      aria-label="Platform"
                      className="mx-2 min-w150 tabSliderContainer "
                      style={{
                        borderRadius: "12px",
                        maxHeight: "48px",
                        backgroundColor: mode !== "lightMode" && "#1b1b1b",
                      }}
                    >
                      {intervalList.map((elem) => (
                        <ToggleButton
                          key={elem?.id}
                          value={elem?.id}
                          className={` tabsliderbuttoninActive px-4 ${
                            elem.id == activeInterval
                              ? mode === "lightMode"
                                ? "bg-white text-dark"
                                : "tabsliderbutton"
                              : mode === "lightMode"
                              ? "text-dark"
                              : "text-white"
                          }  p-2 text-capitalize`}
                        >
                          {elem?.label}
                        </ToggleButton>
                      ))}
                    </ToggleButtonGroup>
                  </div>
                ): <div className="m-1" style={{paddingLeft:windowWidth<992?"0":'2em'}}>
                <CustomSelect
                options={intervalList}
                updatevalue={updatesortingFilter}
                mode={mode}
              />
                </div>}
        </div>
        <div className="col-md col-6 px-0 col  order-4 align-self-center">
            <div className="m-1">
                    <Select
                      options={chainlist}
                      value={chainlist.find((option) => option.key === activeChain)}
                      onChange={(selectedOption) => {
                        setisloading(true);
                        setdata([]);
                        updateblockChain(selectedOption?.key);

                      }}
                      isSearchable={false}
                      isClearable={false}
                      getOptionLabel={(option) => (
                        <div className="scrollbar border-0 d-flex justify-content-between align-items-center">
                          <span>
                            <img
                              src={`data:image/jpg;base64,${option?.image_url}`}
                              alt={option?.name}
                              style={{ width: "20px", marginRight: "10px" }}
                            />
                            {option?.name}
                          </span>
                        </div>
                      )}
                      getOptionValue={(option) => option.key}
                      styles={customStyles}
                      className="font-14 border-0 scrollbar"
                      onMenuOpen={() => setIsOpen(true)}
                      onMenuClose={() => setIsOpen(false)}
                      // Customizing the Option component to display a tick mark if the option is selected
                      components={{
                        Option: ({ children, isSelected, ...rest }) => (
                          <div
                            className={`d-flex justify-content-between align-items-center px-2 py-3 ${
                              isSelected ? "selected" : ""
                            }`}
                            style={{
                              backgroundColor:
                                mode === "lightMode" ? "#f6f6f6" : "#1b1b1b",
                            }}
                            {...rest}
                            onClick={() => {
                              rest.selectOption(rest.data);
                            }}
                          >
                            {children}
                            {isSelected && (
                              <FaCheck
                                size={15}
                                className="font-weight-bold"
                                color={mode === "lightMode" ? "black" : "white"}
                              />
                            )}
                          </div>
                        ),
                      }}
                    />
          </div>
        </div>
        <div className="col px-0 order-md-last order-md-5 order-3 align-self-center">{ renderViewAllBtn()}</div>
      </div>
      <div className="row py-2 mx-0">
        <div className="col-12 d-flex  pr-4 pl-1 ">
          <TableContainer
            component={Paper}
            className="scrollbar d-flex"
            style={{ boxShadow: "none",backgroundColor:mode==="lightMode"?"white":"#121212" }}
          >
            <Table
              sx={{ minWidth: 650 }}
              aria-label="simple table"
              style={{ borderWidth: "0px" ,marginRight:"15px"}}
              className={`${
                mode === "lightMode"
                  ? "bg-white text-dark"
                  : "bg-dark text-white"
              }`}
            >
              <TableHead>
                <TableRow>
                  <TableCell
                    className={`py-1 px-0 text-center ${
                      mode === "lightMode" ? "text-dark" : "table-header-color"
                    }`}
                  >
                    #
                  </TableCell>
                  <TableCell
                    className={`py-1 pl-0 ${
                      mode === "lightMode" ? "text-dark" : "table-header-color"
                    }`}
                    sx={{ 
                      minWidth:250,
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis'
                    }}
                  >
                    Collection
                  </TableCell>
                  <TableCell
                    align="center"
                    className={`py-1 ${
                      mode === "lightMode" ? "text-dark" : "table-header-color"
                    }`}
                  >
                    {selectchoice?"Like":"Dislike"}
                  </TableCell>
                  <TableCell
                    align="center"
                    className={`py-1 ${
                      mode === "lightMode" ? "text-dark" : "table-header-color"
                    }`}
                    sx={{ 
                      minWidth: { xs: 100, sm: 150, md: 200 },
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis'
                    }}
                  >
                    {selectchoice?"Percent Change Likes":"percent Changes Dislike"}
                  </TableCell>
                  <TableCell
                    align="center"
                    className={`py-1 ${
                      mode === "lightMode" ? "text-dark" : "table-header-color"
                    }`}
                    sx={{ 
                      minWidth: { xs: 100, sm: 150, md: 200 },
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis'
                    }}
                  >
                    Floor Price
                  </TableCell>
                  <TableCell
                    align="center"
                    className={`py-1 ${
                      mode === "lightMode" ? "text-dark" : "table-header-color"
                    }`}
                  >
                    Volume
                  </TableCell>
                  <TableCell
                    align="center"
                    className={`py-1 ${
                      mode === "lightMode" ? "text-dark" : "table-header-color"
                    }`}
                    sx={{ 
                      minWidth: { xs: 100, sm: 150, md: 200 },
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis'
                    }}
                  >
                    Percent Change Volume
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody className="pl-4">
                {(loading?[...Array(5).keys()]:data).slice(0, 5).map((row, index) => (
                    <TableRow
                      key={index}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                         verticalAlign:'middle' 
                      }}
                    >
                      <TableCell
                        component="th"
                        scope="row"
                        className={`py-4 pr hideBorder text-center tablerowsData ${
                          mode === "lightMode" ? "text-dark" : "text-white"
                        }`}
                      >
                        {!loading&&index + 1}
                      </TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        className="pointer hideBorder pl-0 d-flex align-items-center"
                        onClick={() => {
                          handleClick(row);
                        }}
                      >
                        <span>
                         {loading?<CustomShimmer style={{width:'62px',height:'62px',borderRadius: "1rem"}}/>:<img
                            src={row.imageUrl !== "null" ? row.imageUrl : Userpng}
                            alt="..."
                            style={{width:'62px',height:'62px',borderRadius: "1rem"}}
                          />}
                        </span>
                        <span
                          className={`hideborder tablerowsData ${
                            mode === "lightMode" ? "text-dark" : "text-white"
                          }`}
                        >
                          {loading?<CustomShimmer style={{...shimmerStyle,width:'256px'}}/>:row.name !== undefined
                            ? row.name.length < 15
                              ? row.name
                              : row.name.slice(0, 15) + " ..."
                            : ""}
                        </span>
                      </TableCell>
                      <TableCell
                        align="center"
                        className={`tablerowsData hideBorder ${
                          mode === "lightMode" ? "text-dark" : "text-white"
                        }`}
                      >
                        {loading?<CustomShimmer style={shimmerStyle}/>:selectchoice?row?.like:row?.dislike}
                      </TableCell>
                      <TableCell
                        align="center"
                        className={`tablerowsData hideBorder  ${
                          mode === "lightMode" ? "text-dark" : "text-white"
                        }`}
                      >
                        {loading?<CustomShimmer style={shimmerStyle}/>:selectchoice?row?.percentChangeLikes:row?.percentChangeDislikes}
                      </TableCell>
                      <TableCell
                        align="center"
                        className={`tablerowsData hideBorder  ${
                          mode === "lightMode" ? "text-dark" : "text-white"
                        }`}
                      >
                        {loading?<CustomShimmer style={shimmerStyle}/>:Number(row?.floorPrice).toFixed(2)} 
                      </TableCell>
                      <TableCell
                        align="center"
                        className={`hideBorder tablerowsData ${
                          mode === "lightMode" ? "text-dark" : "text-white"
                        }`}
                      >
                        {loading?<CustomShimmer style={shimmerStyle}/>:Number(row?.volume).toFixed(2)} 
                      </TableCell>
                      <TableCell
                        align="center"
                        className={`hideBorder tablerowsData ${
                          mode === "lightMode" ? "text-dark" : "text-white"
                        }`}
                      >
                        {loading?<CustomShimmer style={shimmerStyle}/>:Number(row?.percentChangeVolumes).toFixed(2)} 
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
            <Table
              sx={{ minWidth: 650 }}
              aria-label="simple table"
              className={`${
                mode === "lightMode"
                  ? "bg-white text-dark"
                  : "bg-dark text-white"
              }`}
            >
              <TableHead >
                <TableRow>
                  <TableCell
                    className={`py-1 px-0 text-center  ${
                      mode === "lightMode" ? "text-dark" : "table-header-color"
                    }`}
                  >
                    #
                  </TableCell>
                  <TableCell
                    className={`py-1 pl-0 ${
                      mode === "lightMode" ? "text-dark" : "table-header-color"
                    }`}
                  >
                    Collection
                  </TableCell>
                  <TableCell
                    align="center"
                    className={`py-1 ${
                      mode === "lightMode" ? "text-dark" : "table-header-color"
                    }`}
                  >
                  {selectchoice?'Like':'Dislike'}
                  </TableCell>
                  <TableCell
                    align="center"
                    className={`py-1 ${
                      mode === "lightMode" ? "text-dark" : "table-header-color"
                    }`}
                    sx={{ 
                      minWidth: { xs: 100, sm: 150, md: 200 },
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis'
                    }}
                  >
                  {selectchoice?"Percent Change Likes":"percent Changes Dislike"}
                  </TableCell>
                  <TableCell
                    align="center"
                    className={`py-1 ${
                      mode === "lightMode" ? "text-dark" : "table-header-color"
                    }`}
                    sx={{ 
                      minWidth: { xs: 100, sm: 150, md: 200 },
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis'
                    }}
                  >
                    Floor Price
                  </TableCell>
                  <TableCell
                    align="center"
                    className={`py-1 ${
                      mode === "lightMode" ? "text-dark" : "table-header-color"
                    }`}
                  >
                    Volume
                  </TableCell>
                  <TableCell
                    align="center"
                    className={`py-1 ${
                      mode === "lightMode" ? "text-dark" : "table-header-color"
                    }`}
                    sx={{ 
                      minWidth: { xs: 100, sm: 150, md: 200 },
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis'
                    }}
                  >
                    Percent Change Volume
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody  >
                {(loading?[...Array(10).keys()]:data).slice(5, 10).map((row, index) => (
                    <TableRow
                      key={index}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                        verticalAlign: 'middle' 
                      }}
                    >
                      <TableCell
                        component="th"
                        scope="row"
                        className={`hideBorder py-4 text-center tablerowsData ${
                          mode === "lightMode" ? "text-dark" : "text-white"
                        }`}
                      >
                        {loading?<CustomShimmer/>:index + 6}
                      </TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        className={`hideBorder pointer pl-0 d-flex align-items-center ${
                          mode === "lightMode" ? "text-dark" : "text-white"
                        }`}
                        onClick={() => {
                          handleClick(row);
                        }}
                        sx={{ 
                          minWidth:250,
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis'
                        }}
                      >
                        <span>
                         {loading?<CustomShimmer style={{width:'62px',height:'62px',borderRadius: "1rem"}}/>:<img
                            src={row.imageUrl !== "null" ? row.imageUrl : Userpng}
                            alt="..."
                            style={{width:'62px',height:'62px',borderRadius: "1rem"}}
                          />}
                        </span>
                        
                        <span
                          className={`tablerowsData ${
                            mode === "lightMode" ? "text-dark" : "text-white"
                          }`}
                        >
                          {loading?<CustomShimmer style={{...shimmerStyle,width:'256px'}}/>:row.name !== undefined
                            ? row.name.length < 15
                              ? row.name
                              : row.name.slice(0, 15) + " ..."
                            : ""}
                        </span>
                      </TableCell>
                      <TableCell
                        align="center"
                        className={`hideBorder tablerowsData ${mode === "lightMode" ? "text-dark" : "text-white"}`}
                      >{loading?<CustomShimmer style={shimmerStyle}/>:selectchoice?row?.like:row?.dislike}</TableCell>
                      <TableCell
                        align="center"
                        className={`hideBorder tablerowsData ${mode === "lightMode" ? "text-dark" : "text-white"}`}
                      >{loading?<CustomShimmer style={shimmerStyle}/>:selectchoice?row?.percentChangeLikes:row?.percentChangeDislikes}
                      </TableCell>
                      <TableCell
                        align="center"
                        className={`hideBorder tablerowsData ${
                          mode === "lightMode" ? "text-dark" : "text-white"
                        }`}
                      >
                        {loading?<CustomShimmer style={shimmerStyle}/>:Number(row?.floorPrice).toFixed(2)} 
                      </TableCell>
                      <TableCell
                        align="center"
                        className={`hideBorder tablerowsData ${
                          mode === "lightMode" ? "text-dark" : "text-white"
                        }`}
                      >
                        {loading?<CustomShimmer style={shimmerStyle}/>:Number(row?.volume).toFixed(2)} 
                      </TableCell>
                      <TableCell
                        align="center"
                        className={`hideBorder tablerowsData ${
                          mode === "lightMode" ? "text-dark" : "text-white"
                        }`}
                      >
                        {loading?<CustomShimmer style={shimmerStyle}/>:Number(row?.percentChangeVolumes).toFixed(2)} 
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        <div className="col">
          {!loading && data.length === 0 && (
            <h5 className="text-center">No Data Found</h5>
          )}
        </div>
      </div>
    </div>
  );
}
