import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import Styles from "./Details.module.css";
import { FaAngleDown, FaAngleUp } from "react-icons/fa6";
import { LineChart, Line, XAxis, Tooltip, ResponsiveContainer } from 'recharts';
const DetailsItemScreen = () => {
  const [windowWidth, setwindowWidth] = useState(window.innerWidth);
  const mode = useSelector((state) => state.themeType.themeType);
  const [highListVisible,sethighlightVisible]=useState(false);
  const [currentRightTab,setcurrentRightTab]=useState(1);
  const data = [
    { date: '07/12', apy: 5.87 },
    { date: '07/13', apy: 8.87 },
    { date: '07/14', apy: 7.87 },
    { date: '07/15', apy: 10.05 },
    { date: '07/16', apy: 9.05 },
    { date: '07/16', apy: 12.05 },
    { date: '07/16', apy: 10.05 },
    { date: '07/16', apy: 3.05 },
    ]
    const initialList = [
      {
        id: 1,
        label: "What’s JRN Web3 DeFi?",
        desc: "JRN Web3 DeFi is an all-in-one platform to help you make the most of decentralized finance. Use this one-stop hub to manage investments in different DeFi protocols. You can navigate liquidity pools on protocols such as AAVE, Yearn, and Pancake easily.",
        isOpen: false,
      },
      {
        id: 2,
        label: "What protocols do we support?",
        desc: "We only bring you the DeFi protocols with the best returns and safest records.",
        isOpen: false,
      },
      {
        id: 3,
        label: "What are the protocol types?",
        desc: `Stablecoins refers to the investment products of stablecoins you can invest in, such as USDT, USDC, DAI, etc.
  Bonus refers to the investment products you can invest in and receive extra rewards distributed by projects on JRN Web3 DeFi.
  (Please note that JRN Wallet and its affiliates (“JRN”) have no control on reward distribution. All information displayed by JRN is provided by the Third Party and not representations by JRN. Actual APY, incentives and other service terms may vary at the Third Party’s discretion.)
  Single Crypto refers to the investment products you can invest in with a single token. These products are available in staking, lending, and borrowing protocols with the advantage of being free from impermanent losses.
  Multiple Crypto refers to the investment products you need to invest in with multiple tokens. These products are available in liquidity pools and liquidity mining protocols. Due to price fluctuations of the underlying assets in the pools, investing in these products may incur impermanent losses, further causing losses on your assets.
  Vault is a yield aggregator. After you deposit your tokens, Vault will adopt automated strategies to capture the best earnings path and yield the highest return for you.`,
        isOpen: false,
      },
      {
        id: 4,
        label: "What are the advantages of JRN Web3 DeFi?",
        desc: "No need to switch between protocols or make multiple transactions, as you can do everything in one go via JRN Web3 DeFi. Just deposit or claim with any available tokens. JRN Web3 DeFi makes DeFi a cakewalk for you. You can also easily track your DeFi positions across DeFi platforms. Manage the investment whenever you want.",
        isOpen: false,
      },
      {
        id: 5,
        label: "How secure is JRN Web3 DeFi?",
        desc: "JRN Web3 DeFi smart contract has been audited by several parties. We'll never access your assets without your permission. You own your assets all along your investment journey.",
        isOpen: false,
      },
      {
        id: 6,
        label: "What do you need to pay to use JRN Web3 DeFi?",
        desc: "No extra fees. You only need to pay gas fees to complete the transaction on different networks. JRN Web3 DeFi can save gas fees for you as you enjoy staking tokens in the liquidity pool.",
        isOpen: false,
      },
      {
        id: 7,
        label: "How do I manage my investment portfolio?",
        desc: "In JRN Web3 DeFi, you can redeem your assets and claim rewards in the DeFi dashboard anytime. The dashboard also helps you track the current status of your portfolio.",
        isOpen: false,
      },
      {
        id: 8,
        label: "How does JRN Web3 DeFi manage the risk?",
        desc: "Although JRN Web3 DeFi tries its best to keep your assets safe, there are risks when you interact with third-party smart contracts. All DeFi protocols involve risks. JRN Web3 DeFi doesn't take responsibility for contract vulnerabilities, hacking incidents, or extreme fluctuations.",
        isOpen: false,
      },
      {
        id: 9,
        label: "What are the potential risks of DeFi?",
        desc: `Here are the risks that might occur to DeFi users:
  Technical Risks: where smart contracts could be hacked, or bugs could be exploited.
  Liquidity Risks: where protocols like Compound could run out of liquidity. 
  Admin Key Risks: where the master private key for the protocol could be compromised.
  
  It'd be helpful if you did your research before participating in any DeFi protocols. JRN Web3 DeFi isn't responsible for any token loss caused by those reasons.`,
        isOpen: false,
      },
    ];
    const [optionList, setoptionList] = useState(initialList);
  useEffect(() => {
    const handleResize = () => {
      setwindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const renderFirstSection = () => {
    return (
      <div className={`mt-5`}>
        <div className="d-flex align-items-center">
          <div className="">
            <img
              style={{}}
              src="https://www.JRN.com/cdn/wallet/logo/USDT-991ffed9-e495-4d1b-80c2-a4c5f96ce22d.png?x-oss-process=image%2Fresize%2Cw_80%2Ch_80&x-oss-process=image/format,webp/ignore-error,1"
              alt="..."
              className={`${Styles.headerImg}`}
            />
            <img
              src="https://www.JRN.com/cdn/invest/platform/invest_wepiggy.png?x-oss-process=image%2Fresize%2Cw_40%2Ch_40&x-oss-process=image/format,webp/ignore-error,1"
              className={`${Styles.headerImg2}`}
              alt="..."
            />
          </div>
          <div className="ml-4">
            <p className="font-20 font-weight-bold mb-0">USDT</p>
            <small>Wepiggy</small>
          </div>
        </div>
      </div>
    );
  };

  const renderSectionSection = () => {
    const intervalList = [
      { id: 1, label: "1w", value: "1w" },
      { id: 2, label: "1m", value: "1m" },
      { id: 3, label: "3m", value: "3m" },
      { id: 4, label: "1y", value: "1y" },
    ];
    return (
      <div className="mt-2">
        <div className="d-flex justify-content-between align-items-center">
          <span className="font-44 font-weight-bold">
            21.42% <span className="font-20">APY</span>
          </span>
          <div className="d-flex align-items-center">
            {intervalList.map((elem, index) => (
              <span
                key={index}
                className="mx-1 rounded font-12"
                style={{
                  background: mode === "lightMode" ? "#f2f2f2" : "white",
                  color: mode === "lightMode" ? "black" : "white",
                  padding:'4px 8px'
                }}
              >
                {elem.label}
              </span>
            ))}
          </div>
        </div>

        <div style={{ width: '100%', height: 400 }}>
            <ResponsiveContainer>
                <LineChart
                data={data}
                margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
                >
                <XAxis dataKey="date"  fontSize={10} style={{}} tick={{fill:"#afb6c4"}} tickLine={false} padding={{ left: 15, right: 15 }}/>
                <Tooltip />
                <Line type="monotone" dataKey="apy" strokeWidth={2} dot={false} stroke="#31bd65" activeDot={{ r: 8 }} />
                </LineChart>
            </ResponsiveContainer>
    </div>
      </div>
    );
  };

  const renderThirdSection=(obj)=>{
    const {label,badge,list,IsbadgeVisible}=obj
    return (
        <div className={`${mode==='lightMode'?"bg-white text-black":'bg-black text-white'}}`} style={{paddingTop:windowWidth>768?'80px':'48px'}}>
            <div className="d-flex align-items-center" style={{borderBottom:'1px solid #ebebeb'}}>
                <h3 className={`font-24 font-weight-bold`}>{label}</h3>
              {IsbadgeVisible&&  <span className={`${Styles.infosubTitle} ml-2`}>{badge}</span>}
            </div>
            <div className="row">
                {list!==undefined&& list?.map((elem,index)=><div key={index} className="col-md-3 col-6 p-3">
                    <p className="font-weight-bold font-16">{elem?.label}</p>
                    <span className="d-flex align-items-center">{elem?.isimage&& <img src={elem?.imageUrl} style={{width:'20px',height:'20px'}}/>}  {elem?.desc} </span>
                </div>)}
              
            </div>


        </div>
    )
  }

  const renderRightCard=()=>{
    return( 
    <div className={`${Styles.rightCard} scrollbar`}>
      <div className="d-flex align-items-center">
        {["Invest","Redeem"].map((elem,index)=><span key={index} className={`${Styles.rightCardTab}`} onClick={()=>{setcurrentRightTab(index+1)}} style={{borderBottom:index===(currentRightTab-1)&&"3px solid black",color:index!==(currentRightTab-1)?'#929292':'black'}}>{elem}</span>)}
      </div>
      <div className={`${Styles.rightInnerCard} my-2`}>
        <div className="d-flex justify-content-between align-items-center"  >
          <span className="font-36 fontweight-500" style={{color:'#bdbdbd'}}>0.00</span>
          <span className="bg-white p-2"><img alt="..." className="mx-2" src="https://www.JRN.com/cdn/wallet/logo/USDT-991ffed9-e495-4d1b-80c2-a4c5f96ce22d.png?x-oss-process=image%2Fresize%2Cw_80%2Ch_80&x-oss-process=image/format,webp/ignore-error,1" width={'20px'} height={'20px'}/>USDT</span>
        </div>
        <p className="font-14" style={{color:'#bdbdbd'}}>$0</p>
      </div>
      <button className="btn btn-block bg-black text-white text-center" style={{borderRadius:'44px',padding:'18px 24px'}}>Connect wallet</button>
    </div>)
  }

  const renderFourthSection=()=>{
    return (
      <div className={`${Styles.fourthSection}`}>
        <h1 className={`${Styles.fourthHeadear}`}>Security evaluation</h1>
        <div className={`${Styles.fourthinnerContainer}`}>
          <img src="https://www.JRN.com/cdn/invest/coin/score_b_0414.png?x-oss-process=image/format,webp/ignore-error,1" alt="..." width={'54px'} height={'54px'}/>
          <span className="font-40 font-weight-bold" style={{color:'#FFB905'}}>65.17</span>
          <span className="font-16 font-weight-bold ml-3 mt-3" style={{color:'#929292'}}>/100</span>
          <small className="rounded text-white px-2 mx-2 mt-3" style={{backgroundColor:'#FFB905'}}>Medium risk</small>
        </div>
        <div className="d-flex justify-content-between pointer" onClick={()=>{sethighlightVisible(!highListVisible)}}>
          <div> 
            <p className="font-18 mb-2 fontweight-500 text-black">Highlights & Alerts</p>
            <p className="font-14 mb-2 w-75" style={{color:'#6E6E6E'}}>Security scores are provided by third-party websites. They only apply to the corresponding investments.</p>
            <p className="font-16  text-black fontweight-500">Certik</p>
          </div>
        {highListVisible?  <FaAngleUp size={20} color={mode==='lightMode'?'black':'white'}/>:<FaAngleDown size={20} color={mode==='lightMode'?'black':'white'}/>}
        </div>
          {highListVisible&& <div>
            <span className="font-16  py-5 font-weight-bold text-danger">2 alert</span>
            <p className="font-14 mb-2 fontweight-500 text-black">Further security audit verification required</p>
            </div>}
       
        
      </div>
    )
  }
  const renderFifthSection=()=>{
    return (
      <div >
        <h1 className="font-24 font-weight-bold" style={{padding:'36px 0'}}>FAQs</h1>
        <div className="d-flex flex-column align-items-center">
                {optionList.map((elem, index) => (
                  <div
                    key={index}
                    className={`w-100 py-5`}
                    style={{
                      borderBottom: "1px solid #ebebeb",
                      borderTop: "1px solid #ebebeb",
                    }}
                    onClick={() => {
                      const filterdata = optionList.map((item) => {
                        if (item.id === elem.id) {
                          item.isOpen = !item.isOpen;
                        }
                        return item;
                      });
                      console.log(filterdata);
                      setoptionList(filterdata);
                    }}
                  >
                    <div
                      className="d-flex justify-content-between align-items-center"
                      style={{ borderTop: "1px solid #" }}
                    >
                      <p>{elem.label}</p>
                      {elem.isOpen ? (
                        <FaAngleDown size={20} color="black" />
                      ) : (
                        <FaAngleUp size={20} color="black" />
                      )}
                    </div>
                    {elem.isOpen ? <p>{elem.desc}</p> : null}
                  </div>
                ))}
        </div>
      </div>
    )
  }
  return (
    <section
      style={{
        padding: windowWidth < 1024 ? "0 32px" : "0 145px",
        minHeight: "70vh",
      }}
      className={`${mode==='lightMode'?"bg-white text-black":'bg-black text-white'}`}
    >
      <div className="row">
        <div className="col">
          {renderFirstSection()}
          {renderSectionSection()}
          {renderThirdSection({label:'Info',IsbadgeVisible:true,badge:'Utilization rate 84.93%',list:[
            {id:1,label:'Earn',imageUrl:'https://www.JRN.com/cdn/wallet/logo/USDT-991ffed9-e495-4d1b-80c2-a4c5f96ce22d.png?x-oss-process=image%2Fresize%2Cw_40%2Ch_40&x-oss-process=image/format,webp/ignore-error,1',isimage:true,desc:'USDT+WPC'},
            {id:1,label:'TVL',imageUrl:'https://www.JRN.com/cdn/wallet/logo/USDT-991ffed9-e495-4d1b-80c2-a4c5f96ce22d.png?x-oss-process=image%2Fresize%2Cw_40%2Ch_40&x-oss-process=image/format,webp/ignore-error,1',isimage:false,desc:'$171.58K'},
            {id:1,label:'Protocol',imageUrl:'https://www.JRN.com/cdn/wallet/logo/USDT-991ffed9-e495-4d1b-80c2-a4c5f96ce22d.png?x-oss-process=image%2Fresize%2Cw_40%2Ch_40&x-oss-process=image/format,webp/ignore-error,1',isimage:true,desc:'WePiggy'},
            {id:1,label:'Network',imageUrl:'https://www.JRN.com/cdn/wallet/logo/USDT-991ffed9-e495-4d1b-80c2-a4c5f96ce22d.png?x-oss-process=image%2Fresize%2Cw_40%2Ch_40&x-oss-process=image/format,webp/ignore-error,1',isimage:true,desc:'OKTC'}
          ]})}
          {renderThirdSection({label:'ROI Performance',IsbadgeVisible:false,badge:'Utilization rate 84.93%',list:[
            {id:1,label:'1 Day',imageUrl:'https://www.JRN.com/cdn/wallet/logo/USDT-991ffed9-e495-4d1b-80c2-a4c5f96ce22d.png?x-oss-process=image%2Fresize%2Cw_40%2Ch_40&x-oss-process=image/format,webp/ignore-error,1',isimage:false,desc:'0.05%'},
            {id:1,label:'1 Week',imageUrl:'https://www.JRN.com/cdn/wallet/logo/USDT-991ffed9-e495-4d1b-80c2-a4c5f96ce22d.png?x-oss-process=image%2Fresize%2Cw_40%2Ch_40&x-oss-process=image/format,webp/ignore-error,1',isimage:false,desc:'0.41%'},
            {id:1,label:'1 Month',imageUrl:'https://www.JRN.com/cdn/wallet/logo/USDT-991ffed9-e495-4d1b-80c2-a4c5f96ce22d.png?x-oss-process=image%2Fresize%2Cw_40%2Ch_40&x-oss-process=image/format,webp/ignore-error,1',isimage:false,desc:'1.76%'},
            {id:1,label:'1 Year',imageUrl:'https://www.JRN.com/cdn/wallet/logo/USDT-991ffed9-e495-4d1b-80c2-a4c5f96ce22d.png?x-oss-process=image%2Fresize%2Cw_40%2Ch_40&x-oss-process=image/format,webp/ignore-error,1',isimage:false,desc:'21.42%'}
          ]})}
          {renderThirdSection({label:'About',IsbadgeVisible:false,badge:'Utilization rate 84.93%',list:[
            {id:1,label:'Crypto',imageUrl:'https://www.JRN.com/cdn/wallet/logo/USDT-991ffed9-e495-4d1b-80c2-a4c5f96ce22d.png?x-oss-process=image%2Fresize%2Cw_40%2Ch_40&x-oss-process=image/format,webp/ignore-error,1',isimage:true,desc:'USDT+WPCT'},
            {id:2,label:'Price',imageUrl:'https://www.JRN.com/cdn/wallet/logo/USDT-991ffed9-e495-4d1b-80c2-a4c5f96ce22d.png?x-oss-process=image%2Fresize%2Cw_40%2Ch_40&x-oss-process=image/format,webp/ignore-error,1',isimage:false,desc:'$1'},
            {id:3,label:'Market Cap',imageUrl:'https://www.JRN.com/cdn/wallet/logo/USDT-991ffed9-e495-4d1b-80c2-a4c5f96ce22d.png?x-oss-process=image%2Fresize%2Cw_40%2Ch_40&x-oss-process=image/format,webp/ignore-error,1',isimage:false,desc:'$113.25B'},
            {id:4,label:'Contract',imageUrl:'https://www.JRN.com/cdn/wallet/logo/USDT-991ffed9-e495-4d1b-80c2-a4c5f96ce22d.png?x-oss-process=image%2Fresize%2Cw_40%2Ch_40&x-oss-process=image/format,webp/ignore-error,1',isimage:false,desc:'x382b...5c50'},
            {id:5,label:'Crypto',imageUrl:'https://www.JRN.com/cdn/wallet/logo/USDT-991ffed9-e495-4d1b-80c2-a4c5f96ce22d.png?x-oss-process=image%2Fresize%2Cw_40%2Ch_40&x-oss-process=image/format,webp/ignore-error,1',isimage:true,desc:'USDT+WPCT'},
            {id:6,label:'Price',imageUrl:'https://www.JRN.com/cdn/wallet/logo/USDT-991ffed9-e495-4d1b-80c2-a4c5f96ce22d.png?x-oss-process=image%2Fresize%2Cw_40%2Ch_40&x-oss-process=image/format,webp/ignore-error,1',isimage:false,desc:'$1'},
            {id:7,label:'Market Cap',imageUrl:'https://www.JRN.com/cdn/wallet/logo/USDT-991ffed9-e495-4d1b-80c2-a4c5f96ce22d.png?x-oss-process=image%2Fresize%2Cw_40%2Ch_40&x-oss-process=image/format,webp/ignore-error,1',isimage:false,desc:'$113.25B'},
            {id:8,label:'Contract',imageUrl:'https://www.JRN.com/cdn/wallet/logo/USDT-991ffed9-e495-4d1b-80c2-a4c5f96ce22d.png?x-oss-process=image%2Fresize%2Cw_40%2Ch_40&x-oss-process=image/format,webp/ignore-error,1',isimage:false,desc:'x382b...5c50'}
          ]})}
          {renderFourthSection()}
          {renderFifthSection()}
        </div>
        {windowWidth > 1024 && <div className="col-4">
          {renderRightCard()}
          </div>}
      </div>
    </section>
  );
};

export default DetailsItemScreen;
