import React from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";

export default function CustomTabs(props) {
  const { updateTab, activeTab, tablist ,mode} = props;
  // const [value, setValue] = React.useState(activeTab);

  const handleChange = (event, newValue) => {
    //   setValue(newValue);
    updateTab(newValue);
  };
  return (
    <Box sx={{ width: "100%", typography: "body1" }}>
      <TabContext value={activeTab}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList onChange={handleChange} aria-label="lab API tabs example">
            {tablist.map((elem, index) => {
              return <Tab key={index} label={elem.label} value={elem.value} className={`${mode==="lightMode"?"text-dark":"text-white"}`} />;
            })}
          </TabList>
        </Box>
        {props.children}
      </TabContext>
    </Box>
  );
}
