import { Network, Alchemy } from 'alchemy-sdk';
import { REACT_APP_ALCHEMYAPIKEY } from '../../../../config/API/Api';

const settings = {
    apiKey: REACT_APP_ALCHEMYAPIKEY,
    network: Network.ETH_MAINNET,
};

export const CustomAlchemy = new Alchemy(settings);

