import React, { useState } from "react";
import "./Signup.css";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { useSelector } from "react-redux";
import loader from "../../assets/loader.gif";
import { trace } from "firebase/performance";
import { sendPerfomance } from "../..";
import { useNavigate } from "react-router-dom";
import { REACT_APP_EMAIL_BASE_URL } from "../../config/API/Api";

export default function Signup() {
  const theme = useSelector((state) => state.themeType.themeType);
  const [username, setusername] = useState("");
  const [password, setpassword] = useState("");
  const [otp, setotp] = useState(0);
  const [showotp, setshowotp] = useState(false);
  const [signup, setsignup] = useState(false);
  const [isdisable, setisdisable] = useState(true);
  const [isloading, setisloading] = useState(false);
  const [SessionKey,setsessionKey]=useState('');
  const navigate=useNavigate();

  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

  //=>>>>>>>>>>>>>>>>>>>>>REQUESTED API FOR REGISTER===>>>>>>>
  const requestedAPIregisterUser = async () => {
    const url =
    REACT_APP_EMAIL_BASE_URL+'emailregister';
    setisloading(true);
    try {
      const t=trace(sendPerfomance,'EMAIL_REGISTER');
      t.start();
      await axios
        .post(url, { email: username })
        .then((res) => {
          console.log(res);
          if (res.status === 200) {
            setshowotp(true);
            setisloading(false);
            toast.success('Please verify yourself.');
            setsessionKey(res.data?.Session);
          }
        })
        .catch((err) => {
          toast.warning(err.response.data?.message);
          setisloading(false);
        }).finally(()=>{
          t.stop();
        });
    } catch (error) {
      console.log(error);
      setisloading(false);
    }
  };
  //=>>>>>>>>>>>>>>>>>>>>>REQUESTED API FOR VALIDATE USER ===>>>>>>>
  const requestedAPIvalidateUser = async () => {
    const url =
      REACT_APP_EMAIL_BASE_URL+'confirmemailregister';
    setisloading(true);
    try {
      const t=trace(sendPerfomance,'CONFIRM_EMAIL_REGISTER');
      t.start();
      await axios
        .post(url, { email: username, token:SessionKey, code :otp})
        .then((res) => {
          console.log(res);
          if(res.status===200){
              setusername("");
              setotp('');
              setisloading(false);
              toast.success(res.data?.message);
              navigate('/');
          }
        })
        .catch((err) => {
          toast.warning(err.response.data?.message);
          setisloading(false);
        }).finally(()=>{
          t.stop();
        });
    } catch (error) {
      console.log(error);
      setisloading(false);
    }
  };

  const handleVerify = (e) => {
    e.preventDefault();
    requestedAPIregisterUser();
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("helo you pressesed send button");
   if(otp.length===6){
    requestedAPIvalidateUser()
   }else{
    toast.warn("Invalid Otp ! It must be 6 digits");
   }
  };

  return (
    <div
      className="mainLogin pt-5 "
      style={{
        maxHeight: "100vh",
        minHeight: "85vh",
        height: "auto",
        backgroundColor: "rgb(189 222 229)",
        display:'flex',
        justifyContent:'center',
        alignItems:'center'
      }}
    >
      <ToastContainer
        theme={theme === "lightMode" ? "light" : "dark"}
        position="top-center"
      />
      <form
        className="login-form  w-25 mx-auto bg-white rounded  "
        onSubmit={handleSubmit}
      >
        <div className="border-bottom border-secondary p-3 ">
        <h2>Create an account</h2>
        </div>
        <div className="mb-3 px-3 py-1">
          <label htmlFor="username" className="form-label">
            Username
          </label>
          <input
            type="text"
            className="form-control "
            id="username"
            value={username}
            onChange={(e) => {
              setusername(e.target.value);
              if (emailRegex.test(e.target.value)) {
                setisdisable(false);
              } else {
                setisdisable(true);
              }
            }}
          />
        </div>
        {showotp && (
          <div className="mb-3 p-3">
            <label htmlFor="otp" className="form-label">
              OTP
            </label>
            <input
              type="number"
              minLength={6}
              maxLength={6}
              className="form-control "
              id="otp"
              value={otp}
              onChange={(e) => {
                setotp(e.target.value);
              }}
            />
          </div>
        )}
        <div className="d-flex align-items-center px-3 pb-2">
          {showotp ? (
            <button
              className={`btn ${isdisable ? "btn-secondary" : "btn-primary"}`}
              disabled={isdisable}
              onClick={handleSubmit}
            >
              {isloading ? (
                <img src={loader} alt="..." width={"24px"} height={"24px"} />
              ) : (
                "Submit"
              )}
            </button>
          ) : (
            <button
              className={`btn ${isdisable ? "btn-secondary" : "btn-primary"}`}
              disabled={isdisable}
              onClick={handleVerify}
            >
              {isloading ? (
                <img src={loader} alt="..." width={"24px"} height={"24px"} />
              ) : (
                "Verify"
              )}
            </button>
          )}
        </div>
      </form>
    </div>
  );
}
