import React from "react";
import Nodatafound from '../assets/No_data_found.jpg'

const NoDataFound = ({ mode }) => {
  return (
    <div className="d-flex flex-column justify-content-center align-items-center">
      <img src={Nodatafound} alt="..." width={'100px'} height={"100px"} />
      <p className={` ${mode === "lightMode" ? "text-black" : "text-white"}`}>
        No records found
      </p>
    </div>
  );
};

export default NoDataFound;
