import React, { useState } from "react";
import "./bidcollection.css";
import { useSelector } from "react-redux";
import CustomAccoedan from '../../commonComponent/CustomAccoedan'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { trace } from "firebase/performance";
import { sendPerfomance } from "../..";

export default function Bidcollection() {
  const theme = useSelector((state) => state.themeType.themeType);
  const [projectData,setprojectData]=useState([])
  const [isloading,setisloading]=useState(true);
 const [viewMoredata,setviewmoredata]=useState(false);
 const navigate=useNavigate();

  const faqQuestion=[
    {
      title:"What is Bybit's GrabPic program?",
      description:"Bybit's GrabPic supports the launches of low-priced, high-quality NFT projects."
    },
    {
      title:"What are the differences between Bybit's GrabPic and NFT launch programs on other platforms?",
      description:"1. GrabPic provides NFT projects with a variety of features, with which you can burn NFTs or enable holders to merge and upgrade their NFTs directly on Bybit. 2. GrabPic only features low-priced NFTs with a unit price between 0.1 USDT and 10 USDT. 3. All NFT items launched through Bybit will undergo audits to ensure the security of NFT trades and withdrawals to a trader’s on-chain wallet."
    },
    {
      title:"How to get better informed on the NFT launches on the GrabPic?",
      description:"Subscribe to the Upcoming section on Bybit’s NFT Marketplace to receive updates on the GrabPic NFT launches. Then deposit funds into your Spot Account to get ready for the launches!"
    },
    {
      title:"",
      description:""
    },
  ]

  const bannerSection = () => {
    return (
      <div className="container py-5">
        <div className="row justify-content-between">
          <div className="col-12 col-md-5 py-5 ">
            <div className="d-flex">
              <img
                src="https://www.bybit.com/nft/static/img/logo-icon.8502b7a9.svg"
                width={"24px"}
                height={"24px"}
                alt="..."
              />
              <h4>GrabPic</h4>
            </div>
            <h1
              className={`banner-title ${
                theme === "darkMode" && "banner-title-darkmode"
              }`}
            >
              Grab Future Blue Chip NFTs
            </h1>
            <h3 className="banner-description">
              Debuts of low-priced, high-quality NFTs
            </h3>
          </div>
          <div className="col-12 col-md-5 py-5 secondarcol">
            <div
              className="right head-cards on d-flex flex-column align-items-center"
              data-v-0efe9a0f=""
            >
              <img
                className="anim-pedestal  img-fluid"
                src="https://www.bybit.com/nft/static/img/anim-pedestal.8548aeb5.svg"
                alt=""
                data-v-0efe9a0f=""
              />
              <img
                className="anim-card anim-card01 img-fluid"
                src="https://www.bybit.com/nft/static/img/anim-card01.12dbdaf9.svg"
                alt=""
                data-v-0efe9a0f=""
              />
              <img
                className="anim-card anim-card02 card-2 img-fluid"
                src="https://www.bybit.com/nft/static/img/anim-card02.85df16dd.svg"
                alt=""
                data-v-0efe9a0f=""
              />
              <img
                className="anim-card anim-card03 img-fluid"
                src="https://www.bybit.com/nft/static/img/anim-card03.810fa99c.svg"
                alt=""
                data-v-0efe9a0f=""
              />
              <span
                className="dot dot01"
                data-v-0efe9a0f=""
                style={{
                  background: theme === "lightMode" ? "black" : "white",
                  boxShadow:
                    theme === "lightMode"
                      ? "0 0 3.19012px black"
                      : "0 0 3.19012px white",
                }}
              ></span>
              <span
                className="dot dot02"
                data-v-0efe9a0f=""
                style={{
                  background: theme === "lightMode" ? "black" : "white",
                  boxShadow:
                    theme === "lightMode"
                      ? "0 0 3.19012px black"
                      : "0 0 3.19012px white",
                }}
              ></span>
              <span
                className="dot dot03"
                data-v-0efe9a0f=""
                style={{
                  background: theme === "lightMode" ? "black" : "white",
                  boxShadow:
                    theme === "lightMode"
                      ? "0 0 3.19012px black"
                      : "0 0 3.19012px white",
                }}
              ></span>
              <span
                className="dot dot04"
                data-v-0efe9a0f=""
                style={{
                  background: theme === "lightMode" ? "black" : "white",
                  boxShadow:
                    theme === "lightMode"
                      ? "0 0 3.19012px black"
                      : "0 0 3.19012px white",
                }}
              ></span>
              <span
                className="dot dot05"
                data-v-0efe9a0f=""
                style={{
                  background: theme === "lightMode" ? "black" : "white",
                  boxShadow:
                    theme === "lightMode"
                      ? "0 0 3.19012px black"
                      : "0 0 3.19012px white",
                }}
              ></span>
              <span
                className="dot dot06"
                data-v-0efe9a0f=""
                style={{
                  background: theme === "lightMode" ? "black" : "white",
                  boxShadow:
                    theme === "lightMode"
                      ? "0 0 3.19012px black"
                      : "0 0 3.19012px white",
                }}
              ></span>
              <span
                className="dot dot07"
                data-v-0efe9a0f=""
                style={{
                  background: theme === "lightMode" ? "black" : "white",
                  boxShadow:
                    theme === "lightMode"
                      ? "0 0 3.19012px black"
                      : "0 0 3.19012px white",
                }}
              ></span>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const loader=(index)=>{
    return (
      <div className="col-11 col-md-4" key={index}>
        <Skeleton count={1} height={"45vh"} style={{borderRadius:"12px"}} width={"100%"}/>
      </div>
    )
  }

  const AllGrabicProject = () => {
    return (
      <div className="container">
        <div className="row justify-content-around">
            <div className="col-11 d-flex justify-content-between m-2 p-1 py-3">
                <h2 className="font-weight-bold">All Grabic Project</h2>
              <button className={`btn ${theme==="lightMode"?"btn-warning":"btn-outline-warning"}  rounded`} onClick={()=>{setviewmoredata(!viewMoredata)}}>{viewMoredata?"View Less":"View More"}</button>
            </div>
          {
            isloading?[1,2,3].map((elem,index)=>{
              return (
                loader(index)
                )
              }):
            !viewMoredata?projectData.slice(0,3).map((elem,index)=>{
              return(
               <div key={index} className="col-11 col-md-3 border border-secondary m-2 secondCard pointer" style={{borderRadius:"12px"}}  onClick={()=>{navigate('/bidcollection/item')}}>
               <div className="text-center">
                 <img
                   src={elem?.imageUrl}
                   className="img-fluid mt-2 secondCardimg"
                   alt="..."
                   style={{borderRadius:"15px"}}
                 />
                 <img
                   src={elem?.logo_ImageUrl}
                   className="rounded rounded-circle"
                   width={"64px"}
                   height={"64px"}
                   alt="..."
                   style={{position:"relative",bottom:"2.5rem"}}
                 />
               <h3 className="text-center " style={{fontSize:"20px",position:'relative',bottom:"14px"}}>{elem?.title}</h3>
               </div>
               <div>
                 <div className="d-flex justify-content-between">
                   <span className="text-muted">Floor Price</span>
                 <p >
                 &#8377; {elem?.FloorPrice}  <span className="text-muted">{elem?.FloorPrice} </span>
                   </p>
                 </div>
   
                 <div className="d-flex justify-content-between">
                   <span className="text-muted">Highest Traded Price ($)</span>
                 <p >
                 &#8377; {elem?.highest_trade_price}<span className="text-muted">($ {elem?.highest_trade_price})</span>
                   </p>
                 </div>
   
                 <div className="d-flex justify-content-between">
                   <span className="text-muted">Quantity issued</span>
                 <p >{elem?.qunatity_issued} K</p>
                 </div>
   
                 <div className="d-flex justify-content-between">
                   <span className="text-muted">Total Transactions</span>
                 <p >{elem?.total_transcation} K</p>
                 </div>
               </div>
             </div>
              )
               }):projectData.map((elem,index)=>{
           return(
            <div key={index} className="col-11 col-md-3 border border-secondary m-2 secondCard pointer" style={{borderRadius:"12px"}} onClick={()=>{navigate('/bidcollection/item')}}>
            <div className="text-center">
              <img
                src={elem?.imageUrl}
                className="img-fluid mt-2 secondCardimg"
                alt="..."
                style={{borderRadius:"15px"}}
              />
              <img
                src={elem?.logo_ImageUrl}
                className="rounded rounded-circle"
                width={"64px"}
                height={"64px"}
                alt="..."
                style={{position:"relative",bottom:"2.5rem"}}
              />
            <h3 className="text-center " style={{fontSize:"20px",position:'relative',bottom:"14px"}}>{elem?.title}</h3>
            </div>
            <div>
              <div className="d-flex justify-content-between">
                <span className="text-muted">Floor Price</span>
              <p >
              &#8377; {elem?.FloorPrice}  <span className="text-muted">{elem?.FloorPrice} </span>
                </p>
              </div>

              <div className="d-flex justify-content-between">
                <span className="text-muted">Highest Traded Price ($)</span>
              <p >
              &#8377; {elem?.highest_trade_price}<span className="text-muted">($ {elem?.highest_trade_price})</span>
                </p>
              </div>

              <div className="d-flex justify-content-between">
                <span className="text-muted">Quantity issued</span>
              <p >{elem?.qunatity_issued} K</p>
              </div>

              <div className="d-flex justify-content-between">
                <span className="text-muted">Total Transactions</span>
              <p >{elem?.total_transcation} K</p>
              </div>
            </div>
          </div>
           )
            })
          }
         
       

        </div>
      </div>
    );
  };

  const faqThirdScreen=()=>{
    return (
      <div className="container">
        <div className="row">
          <div className="col col-sm-6 d-flex flex-column justify-content-center align-items-center">
            <h1>FAQ</h1>
            <p className={`${theme==="lightMode"?"text-dark":"text-secondary"}`}>Please contact customer service if you have any other questions regarding Bybit's GrabPic program.</p>
          </div>
          <div className="col col-sm-6">
            <CustomAccoedan  theme={theme} data={faqQuestion}/>
          </div>
        </div>
      </div>
    )
  }

  //........request api for grabic project card..........
  const requestApigrabicproject=async()=>{
    setisloading(true)
    const t=trace(sendPerfomance,'MockUrl');
    t.start();
    try {
      await axios.get('https://run.mocky.io/v3/ce423f02-4f39-4261-99e7-80f58fadb10f').then((res)=>{
        if(res.status===200){
          setisloading(false);
          console.log(res.data)
          setprojectData(res.data);
        }
      }).catch((err)=>{
        toast.error('Data not found');
        console.log(err);
      })
    } catch (error) {
      console.log(error);
      setisloading(false);
    }
    t.stop();
  }
  useEffect(()=>{
    requestApigrabicproject();
  },[])
  return (
    <div
      className={`${
        theme === "lightMode" ? "bg-light text-dark" : "bg-black text-white"
      }`}
    >
      <ToastContainer/>
      <section className="firstSection py-5">{bannerSection()}</section>
      <section className="secondSection ">{AllGrabicProject()}</section>
    </div>
  );
}