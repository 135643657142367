import React, {  useMemo, useState } from "react";
import { FaArrowLeft } from "react-icons/fa";
import { LuRefreshCcw, LuLayoutGrid } from "react-icons/lu";
import { useSelector } from "react-redux";
import { TfiLayoutGrid3 } from "react-icons/tfi";
import { FiFilter } from "react-icons/fi";
import { FaChevronDown } from "react-icons/fa";
import InfiniteScroll from "react-infinite-scroll-component";
import { trace } from "firebase/performance";
import { sendPerfomance } from "../..";
import { tracer } from "../../tracing";
import Axios from "../../config/API/Api";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Switch from "@mui/material/Switch";
import RenderAssetItem from "./RenderAssetItem";
import Loader from "../../commonComponent/Loader";
import CustomSelect from "../../commonComponent/CustomSelect";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";



export default function SearchAssetTabScreen(props) {
  const { updateitem, search, blockchainList ,windowWidth} = props;
  const mode = useSelector((state) => state.themeType.themeType);
  const [data, setdata] = useState(props.data === undefined ? [] : props.data);
  const [activeChain, setactiveChain] = useState(
    props.activechain === undefined ? {} : props.blockchainList[0]
  );
  const [currentpage, setcurrentPage] = useState(1);
  const [isloading, setisloading] = useState(false);
  const [showfilter, setshowfilter] = useState(true);
  const [ispropertiesLoading,setispropertiesLoading]=useState(true);
  const [propertiesData, setpropertiesData] = useState([]);
  const [selectedFilter,setselectedFilter]=useState([]);
  const [filterStatus,setfilterStatus]=useState('none');
  const [showModal,setshowmodal]=useState(false);
  const volumeList = [
    { name: "none", key: 1,value:"none",label:"none"},
    { name: "Volume", key: 2,value:"volume",label:"volume" },
    { name: "Like", key: 3,value:"like",label:'like' },
    { name: "Dislike", key: 4,value:"dislike",label:'dislike' },
  ];
  const handleFilterSection = () => {
    const updatesortingFilter=(val)=>{
      setfilterStatus(val);
    }
    return (
      <section className="my-2" style={{position:'sticky',backgroundColor:mode==="lightMode"?"white":'black',zIndex:1,top:'50px'}}>
        <div className="row">
          <div className="col d-flex align-items-center">
            <button
              className={`btn font-14 filterItem`}
              style={{backgroundColor:mode==="lightMode"?"white":'#191c1f',color:mode==="lightMode"?"black":'white'}}
              onClick={() => {
                if(windowWidth<=1024){
                  setshowmodal(true);
                }else{
                  setshowfilter(!showfilter);
                }
              }}
            >
              {showfilter && windowWidth>1024 ? (
                <FaArrowLeft
                  size={14}
                  style={{ position: "relative", top: "1px" }}
                />
              ) : (
                <FiFilter
                  size={14}
                  style={{ position: "relative", top: "1px" }}
                />
              )}
              {windowWidth>1024?" Filters":""} 
            </button>
            <div className="filterItem px-2 py-1 ml-2 d-flex justify-content-center pointer">
              <LuRefreshCcw size={20} />
            </div>
          </div>
          <div className="d-flex mr-3 ">
            {/* <div className={`form-group mt-2`}>
              <select
                className={`form-control filterItem pointer  ${
                  mode === "lightMode"
                    ? "bg-white text-dark"
                    : "bg-dark text-white"
                }`}
                id="exampleFormControlSelect1"
                value={filterStatus}
                onChange={(e) => {
                  setfilterStatus(e.target.value);
                }}
                style={{
                  fontSize: "14px",
                  lineHeight: "132%",
                  maxWidth: "170px",
                }}
              >
                {volumeList.map((elem, index) => {
                  return (
                    <option
                      className={`${
                        mode === "lightMode"
                          ? "bg-white text-dark"
                          : "bg-dark text-white"
                      }`}
                      key={index}
                      value={elem?.key}
                    >
                      <img
                        width={"24px"}
                        height={"24px"}
                        src={"../../assets/user.png"}
                        alt="..."
                      />
                      {elem?.name}
                    </option>
                  );
                })}
              </select>
            </div> */}
            <CustomSelect
            options={volumeList}
            updatevalue={updatesortingFilter}
            />


            <span className="d-flex align-items-center ml-2" >
              <LuLayoutGrid size={20} className="p-0" />
              <TfiLayoutGrid3 size={18} className="ml-2" />
            </span>
          </div>
        </div>
      </section>
    );
  };
  const renderblockchainSection = () => {
    return (
      <Accordion
        className={`${
          mode === "lightMode" ? "bg-light text-dark" : "bg-dark text-white border-white border-right"
        }`}
      >
        <AccordionSummary
          expandIcon={
            <FaChevronDown
              size={16}
              color={mode === "lightMode" ? "black" : "white"}
            />
          }
          aria-controls="panel1-content"
          id="panel1-header"
          className={`${
            mode === "lightMode"
              ? "border-bottom border-secondary"
              : "border-bottom border-white"
          }`}
        >
          <img
            className="mr-2"
            src={`data:image/jpg;base64,${activeChain?.image_url}`}
            width={"20px"}
            height={"20px"}
          />
          {activeChain?.key==='all'?'All Chains':activeChain?.key}
        </AccordionSummary>
        <AccordionDetails className="list-group ">
          {blockchainList.map((elem, index) => (
            <div
              className={`border-0 ${
                mode === "lightMode"
                  ? "bg-light text-dark"
                  : "bg-dark text-white"
              } py-2 list-group-item list-group-item-action pointer`}
              key={index}
              onClick={() => {
                setactiveChain(elem);
              }}
            >
              <img
                className="mr-2"
                src={`data:image/jpg;base64,${elem?.image_url}`}
                width={"20px"}
                height={"20px"}
              />
              {elem.name}
            </div>
          ))}
        </AccordionDetails>
      </Accordion>
    );
  };

  const handlefilterCheck=(elem,item,e)=>{
    if(e.target.checked){
      const existfilter=selectedFilter.find((obj)=>obj.key===elem.traitType);
      if(existfilter===undefined){
        setselectedFilter([...selectedFilter,{key:elem.traitType,values:[item.key]}])
      }else{
        setselectedFilter((previousData)=>{
       const finaldata=   previousData.map((object)=>{
            if(object.key===elem.traitType){
             object.values.push(item.key);
            }
            return object
          })
          return finaldata;
        })
      }
    }else{
      setselectedFilter((previousData)=>{
      const finaldata=  previousData.map((object)=>{
          if(object.key===elem.traitType){
            object.values=object.values.filter((i)=>(i!==item.key))
          }
          return object;
        })
      return finaldata;
      })
    }
    setpropertiesData((previousData)=>{
       previousData.map((obj)=>{
        if(obj.traitType===elem.traitType){
            obj.trait_values.map((object)=>{
              if(object.key===item.key){
              object.isChecked=!object.isChecked
              }
            })
        }
      })
      return previousData;
    })
  }
  const updatePropertiesdata=(val)=>{
    setpropertiesData(val);
  }

  const LeftSection = (obj) => {
    const label = { inputProps: { "aria-label": "Switch demo" } };
    return (
      <div className={obj}>
        {renderblockchainSection()}
        <div className={`d-flex justify-content-between align-items-center p-4 ${mode==="lightMode"?"bg-light text-dark":'bg-black text-white border-right border-white border-bottom'}`}>
          <span className="font-18 font-weight-bold">Verified</span>
          <Switch {...label} defaultChecked />
        </div>
        <div style={{maxHeight:"350px"}} className="overflow-auto scrollbar">
        {
          ispropertiesLoading?
          <div className="d-flex justify-content-center">
            <Loader  size={50}/>
          </div>:
          propertiesData.map((elem, index) => (
            <Accordion key={index} className={`${mode==="lightMode"?"bg-light text-dark":'bg-black text-white border-bottom border-right border-white'}`}>
              <AccordionSummary
                expandIcon={
                  <FaChevronDown
                    size={16}
                    color={mode === "lightMode" ? "black" : "white"}
                  />
                }
                aria-controls="panel3-content"
                id="panel3-header"
              >
                {elem?.traitType}
              </AccordionSummary>
              <AccordionDetails>
                {
                  elem?.trait_values.map((item,i)=>(
                    <div key={i} className="my-2 px-2 d-flex justify-content-between my-2">
                      <span >{item?.key}</span>
                      <span className="font-16">
                        {item?.value}
                        <input className="form-input ml-2 pointer" type="checkbox" style={{position:'relative',top:"2px"}} checked={item.isChecked} onChange={(e)=>{handlefilterCheck(elem,item,e)}}/>
                      </span>
  
                    </div>
                  ))
                }
              </AccordionDetails>
            </Accordion>
          ))
        }
        </div>
      </div>
    );
  };
  const EachCollectionhandleBuynow = (v) => {
  };
 
  const updateSelectedfilter=(val)=>{
    setselectedFilter(val);
  }
  const handlefilterData=(arr)=>{
  const filterdata=arr.filter(item=>{
      return item.values.length>0
    })
  return filterdata;
  }

  const searchassetsapi = async (page, isnext) => {
    const url = `/search/asset`;
    let params = {
      search: search,
      size: "10",
      page: String(page),
      blockchain: activeChain?.key,
    };
    if(selectedFilter.length>0){
      params.filters=handlefilterData(selectedFilter);
    }
    if(filterStatus!==""&&filterStatus!=='none'){
      params.sort_by=filterStatus;
      params.sort_order='desc';
    }
    try {
      const t = trace(sendPerfomance, "Search_collection_api");
      const span = tracer.startSpan("SearchassetApi");
      t.start();
      setisloading(true);
      await Axios.post(url, params)
        .then((res) => {
          if (res.status === 200) {
            span.setAttributes({ method: "POST" });
            span.addEvent("api_response", {
              status: res.status,
              url,
              data: res.data,
            });
            t.getAttributes({
              status: res.status,
              url,
              data: res.data,
              payload: params,
            });
            if (isnext) {
              setdata([...data, ...res.data.rows]);
            } else {
              setdata(res.data.rows);
            }
            updateitem(res.data?.count)
          }
        })
        .catch((err) => {
          console.log(err);
          span.setStatus({ code: "ERROR", message: err?.message });
        })
        .finally(() => {
          t.stop();
          span.end();
          setisloading(false);
        });
    } catch (error) {
      console.log(error);
    }
  };
  useMemo(() => {
    setdata([]);
    setcurrentPage(1);
    if (search?.length > 2) {
      searchassetsapi(currentpage, false);
    }
  }, [search, activeChain,selectedFilter,filterStatus]);


  const collectionPropertiesApi = async () => {
    // const url = "https://run.mocky.io/v3/9a798d42-6df1-4385-8242-993b19c5c431";
    const url = "/asset/properties";
    const data={search}
    try {
      const t = trace(sendPerfomance, "ASSETS_Properties_filter_Url");
      const span = tracer.startSpan("ASSETS_Properties_filter_api");
      t.start();
      setispropertiesLoading(true)
      await Axios
        .post(url,data)
        .then((res) => {
          span.setAttribute("method", "GET");
          span.addEvent("api_response", {
            status: res.status,
            data: res.data,
            url,
          });
          if (res.status === 200) {
            res.data.map((elem) => {
              elem.trait_values.forEach((obj, index) => {
                obj.isChecked = false;
              });
            });
            console.log(
              "collection properties url data is=========>>>>>>>",
              res.data
            );
            setpropertiesData(res.data);
          }
        })
        .catch((err) => {
          console.log(err);
          span.setStatus({ code: "ERROR", message: err?.message });
        })
        .finally(() => {
          t.stop();
          span.end();
          setispropertiesLoading(false);
        });
    } catch (error) {
      console.log("error", error);
    }
  };
  useMemo(() => {
    collectionPropertiesApi();
  }, [search]);
  const handleFilterPopup=() => {
    const closeBtn = (
      <button
        className={`close ${mode==="lightMode"?"text-dark":"text-white"}`}
        onClick={() => {
          setshowmodal(false);
        }}
        type="button"
      >
        &times;
      </button>
    );
      return (
        <Modal
        size="lg"
        isOpen={showModal}
        toggle={() => {
          setshowmodal(!showModal);
        }}
        className={`${windowWidth<768?'popupTag':'w-100'}`}
        centered={windowWidth>768?true:false}
        
      >
        <ModalHeader
          toggle={() => {
            setshowmodal(false);
          }}
          close={closeBtn}
          title="Filters"
          className={`font-weight-bold ${
            mode === "lightMode" ? "bg-light text-dark" : "bg-dark text-white"
          }`}
        >
          Filters
        </ModalHeader>
        <ModalBody className='p-1' style={{backgroundColor:mode === "lightMode" ? "white" : "black",color:mode === "lightMode" ? "black" : "white"}}>
          {LeftSection('col-12 p-0')}
        </ModalBody>
      </Modal>
      );
    };
  return (
    <section >
      {handleFilterSection()}
      <InfiniteScroll
        dataLength={data.length}
        next={() => {
          //   handlefetchMoreData();
          if (data.length % 10 === 0) {
            setcurrentPage(currentpage + 1);
            searchassetsapi(currentpage + 1, true);
          }
        }}
        hasMore={true}
        style={{ overflowX: "hidden", marginLeft: showfilter ? "" : "1em" }}
      >
        <div className="row">
          {showfilter&&windowWidth>1024 && LeftSection('col-3')}
          <RenderAssetItem
           datalist={data===undefined?[]:data}
           isloading={isloading}
           EachCollectionhandleBuynow={EachCollectionhandleBuynow}
           showfilter={showfilter}
           currentpage={1}
           selectedFilter={selectedFilter}
           updateSelectedfilter={updateSelectedfilter}
           updatePropertiesdata={updatePropertiesdata}
           propertiesData={propertiesData}
          />
        </div>
      </InfiniteScroll>
      {windowWidth<=1024&& handleFilterPopup()}
    </section>
  );
}
